import React, { useCallback } from 'react';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SwitchInput({
  label,
  value,
  onChange,
  name,
  disabled,
}) {
  const handleChange = useCallback(
    (value) => {
      onChange && onChange(value, name);
    },
    [name, onChange]
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Switch.Group as="div" className="flex items-center">
        <Switch.Label as="span" className="mr-3">
          <span className="text-sm font-medium text-gray-900"> {label} </span>
        </Switch.Label>
        <Switch
          onClick={(e) => e.stopPropagation()}
          checked={value}
          onChange={handleChange}
          className={classNames(
            value ? 'bg-blue-600' : 'bg-gray-500',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          )}
          disabled={disabled}
        >
          <span
            aria-hidden="true"
            className={classNames(
              value ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}
