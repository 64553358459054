import React from 'react';
import Api from '../../api/Api';
import useSWR from 'swr';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

const fetcher = (url) => Api.get(url).then((res) => res.data);

export const ParcelThumbComp = ({
  parcelData,
  isPdfRenderMode,
  isHfull = true,
  onClick,
  clickable,
  isActive = false,
  isIn3_1 = false,
  fixedHeight = null,
}) => {
  const {
    superficie,
    zonage_plu,
    zonage_pinel,
    zone_anru,
    display_parcelle_id,
    zac,
  } = parcelData?.data?.attributes;

  if (!parcelData) {
    return <></>;
  }

  return (
    <div
      onClick={() => onClick && onClick(display_parcelle_id)}
      style={fixedHeight ? { height: fixedHeight || '100%' } : {}}
      className={classNames(
        '  w-full flex justify-center flex-col px-4 py-1 sm:py-2 border-1 border-gray-200 shadow-lg font-bold',
        {
          'bg-blue-200': !isActive,
          'h-full': isHfull && !fixedHeight,
          'cursor-pointer hover:bg-blue-300': clickable,
          'bg-blue-300': isActive,
        }
      )}
    >
      <h3
        className={classNames(
          { 'text-sm': isPdfRenderMode },
          { 'text-tiny 2xl:text-sm': !isPdfRenderMode }
        )}
      >
        Parcelle {display_parcelle_id} ({superficie} m²)
      </h3>
      <div className="flex justify-between flex-wrap gap-1">
        <span
          className={classNames('text-orange-600', {
            'text-tiny 2xl:text-sm': !isPdfRenderMode,
            'text-sm': isPdfRenderMode && isHfull,
            'text-xs': !isHfull && isPdfRenderMode,
          })}
        >
          Zone PLU : {zonage_plu ? zonage_plu : 'N/A'}
        </span>
        <span
          className={classNames('text-orange-600', {
            'text-tiny 2xl:text-sm': !isPdfRenderMode,
            'text-sm': isPdfRenderMode && isHfull,
            'text-xs': !isHfull && isPdfRenderMode,
          })}
        >
          Zone Pinel : {zonage_pinel ? zonage_pinel.replace('_', ' ') : 'N/A'}
        </span>
        <span
          className={classNames('text-orange-600', {
            'text-tiny 2xl:text-sm': !isPdfRenderMode,
            'text-sm': isPdfRenderMode && isHfull,
            'text-xs': !isHfull && isPdfRenderMode,
          })}
        >
          {isIn3_1 ? 'ANRU (300m)' : 'Zone ANRU'} :{' '}
          {zone_anru === true ? 'Oui' : 'Non'}
        </span>
        <span
          className={classNames('text-orange-600', {
            'text-tiny 2xl:text-sm': !isPdfRenderMode,
            'text-sm': isPdfRenderMode && isHfull,
            'text-xs': !isHfull && isPdfRenderMode,
          })}
        >
          ZAC : {zac === true ? 'Oui' : 'Non'}
        </span>
        <span
          className={classNames('text-orange-600 font-light leading-tight', {
            'text-tiny 2xl:text-sm': !isPdfRenderMode,
            'text-sm': isPdfRenderMode && isHfull,
            'text-xs': !isHfull && isPdfRenderMode,
            'font-semibold': isIn3_1,
            hidden: isEmpty(parcelData.included) && !isIn3_1,
          })}
        >
          {isIn3_1 ? '' : 'Propriétaire : '}
          {parcelData.included[0]?.attributes
            ? `Personne morale (${parcelData.included[0]?.attributes.denomination.toLowerCase()})`
            : 'Personne physique'}
        </span>
      </div>
    </div>
  );
};

const ParcelThumb = ({ id, isPdfRenderMode }) => {
  const { data: parcelData, error } = useSWR(
    `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${id}`,
    fetcher
  );

  if (!parcelData) {
    return <div>Loading parcel ....</div>;
  }
  if (error) {
    return <div>Parcel loading failed....</div>;
  }

  return (
    <ParcelThumbComp
      parcelData={parcelData}
      isPdfRenderMode={isPdfRenderMode}
    />
  );
};

export default ParcelThumb;
