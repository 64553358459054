import { LayerGroup, MapContainer } from 'react-leaflet';
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import NewRatingLayer from '../Layers/NewRatingLayer';
import Api, { apiV5 } from '../../../../api/Api';
import useSWR from 'swr';
import LoadingSection from '../../../MarketSurveys/sections/LoadingSection';
import FieldBuildingToolLayer from './FieldBuildingToolLayer';
import SidebarSearchAddressHelper from './Legends/SidebarSearchAddressHelper';
import NewRatingHelpModal from '../../../modals/NewRatingHelpModal';
import { titleLayersEdm } from './tileLayer';
import { FluxControl, TitleLayerSelecter, fluxData } from './MapDataContainer';
import { useMediaQuery } from 'react-responsive';
import { getNbImagesTile } from '../Layers';
import { useDebounce } from 'react-use';
import L from 'leaflet';

const fetcher = (url) => Api.get(url).then((res) => res.data);

const MapNewRating = React.forwardRef(
  (
    {
      setCityCode,
      setSelectedAddress,
      selectedAddress,
      addressCoordinates,
      nearestParcels,
      setNearestParcels,
      setSearchedAddress,
      refFielBuilding,
    },
    ref
  ) => {
    const [currentTileLayer, setCurrentTileLayer] = useState();
    const [infos, setInfos] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
    const { data } = useSWR('api/v1/bbox_map', fetcher);
    const [nbErrorTile, setNbErrorTile] = useState(0);
    const [nbTiles, setNbTiles] = useState(0);
    const [ready, setReady] = useState(false);
    const [currentKeyTile, setCurrentKeyTile] = useState('satellite');
    const [selectedFlux, setSelectedFlux] = useState([]);
    const [selectedFluxForRatinglayer, setSelectedFluxForRatinglayer] =
      useState([]);
    const [communeFitBound, setCommuneFitBound] = useState(null);
    const [centerCoord, setCenterCoord] = useState(null);
    const mapRef = useRef(null);
    const [showZonageFlux, setShowZonageFlux] = useState(true);

    useImperativeHandle(ref, () => ({
      activeZonagePlu(args = {}) {
        setSelectedFlux(['flux_plu']);
        setSelectedFluxForRatinglayer(['flux_plu']);

        const { commune } = args;
        if (commune) {
          setCommuneFitBound(true);
        }
      },
      disableZonage() {
        setSelectedFlux([]);
        setSelectedFluxForRatinglayer([]);
        setCommuneFitBound(null);
      },
      notClickOnMap() {
        setCenterCoord(null);
      },
    }));

    const handleChangeTileTileLayer = useCallback(
      (key) => {
        setCurrentTileLayer(<></>);
        setCurrentKeyTile(key);
        setShowZonageFlux(false);
        setTimeout(() => {
          if (key === 'satellite') {
            const tile = titleLayersEdm[key].layer({
              onError: () => {
                setNbErrorTile((c) => c + 1);
              },
            });
            setCurrentTileLayer(tile);
          } else {
            setCurrentTileLayer(titleLayersEdm[key].layer);
          }
          setShowZonageFlux(true);
        }, 200);
      },
      [titleLayersEdm]
    );

    useEffect(() => {
      if (mapRef.current) {
        const map = mapRef.current;
        map.on('zoomend', () => {
          setReady(true);
          setNbTiles(getNbImagesTile(map));
        });
        map.on('moveend', () => {
          setReady(true);
          setNbTiles(getNbImagesTile(map));
        });
      }
      return () => {
        mapRef.current?.off('zoomend');
        mapRef.current?.off('moveend');
      };
    }, []);

    useEffect(() => {
      if (ready && nbTiles > 0) {
        const percent = Math.ceil((nbErrorTile * 100) / nbTiles);

        if (!isNaN(percent) && percent >= 80) {
          handleChangeTileTileLayer('lumiere');
          setNbErrorTile(0);
        }
      }
    }, [nbTiles, nbErrorTile, ready]);

    useEffect(() => {
      const tile = titleLayersEdm.satellite.layer({
        onError: () => {
          setNbErrorTile((c) => c + 1);
        },
      });
      setCurrentTileLayer(tile);
      const intl = setInterval(() => {
        setNbErrorTile(0);
      }, 5000);
      return () => {
        clearInterval(intl);
      };
    }, []);

    const bounds = useMemo(() => {
      if (data) {
        const { xmin, xmax, ymin, ymax } = data;
        return [
          [ymin, xmin],
          [ymax, xmax],
        ];
      }
      return null;
    }, [data]);

    useDebounce(
      () => {
        if (mapRef.current) {
          const map = mapRef.current;
          const fitBoundByCommune = (nearestParcels) => {
            const field = nearestParcels.features[0];
            const featureGroup = L.featureGroup();
            apiV5
              .get(
                `${process.env.BASE_URL_API}/v5/eval_parcel/zones/commune_parcelle/${field.properties.parcelle_id}`
              )
              .then((res) => {
                if (res.status === 200) {
                  const communeData = res.data;
                  L.geoJSON(communeData).addTo(featureGroup);
                  if (featureGroup.getBounds().isValid()) {
                    map.fitBounds(featureGroup.getBounds());
                  }
                }
              })
              .catch((e) => {
                L.geoJSON(field).addTo(featureGroup);
                if (featureGroup.getBounds().isValid()) {
                  map.setView(featureGroup.getBounds().getCenter(), 15);
                }
                console.log(e);
              });
          };

          if (selectedFlux.length && nearestParcels && !centerCoord) {
            if (communeFitBound) {
              fitBoundByCommune(nearestParcels);
            } else {
              const featureGroup = L.featureGroup();
              L.geoJSON(nearestParcels).addTo(featureGroup);
              if (featureGroup.getBounds().isValid()) {
                map.setView(featureGroup.getBounds().getCenter(), 15);
              }
            }
          }
        }
      },
      1000,
      [nearestParcels, selectedFlux, communeFitBound, centerCoord]
    );

    const handleChangeFluxTile = useCallback((values) => {
      setShowZonageFlux(true);
      setSelectedFlux([]);
      setSelectedFluxForRatinglayer(values);
      setTimeout(() => {
        setSelectedFlux(values);
      }, 500);
    }, []);

    const handleClickOnMap = useCallback((ev) => {
      setCenterCoord(ev.latlng);
    }, []);

    return (
      <div className="w-full h-full relative">
        {bounds ? (
          <>
            <FluxControl
              onChange={handleChangeFluxTile}
              top={10}
              right={240}
              values={selectedFluxForRatinglayer}
            />
            <TitleLayerSelecter
              value={currentKeyTile}
              onChange={handleChangeTileTileLayer}
              personnalizedTop={isMobile ? 208 : 216}
            />
            <MapContainer
              bounds={bounds}
              zoom={6}
              ref={mapRef}
              className="h-full z-10"
            >
              <LayerGroup>
                <NewRatingHelpModal />
                <SidebarSearchAddressHelper />
                <FieldBuildingToolLayer
                  onClickOnMap={handleClickOnMap}
                  ref={refFielBuilding}
                  onChangeInfo={(data) => setInfos(data)}
                  setCityCode={setCityCode}
                  setSelectedAddress={setSelectedAddress}
                  setSearchedAddress={setSearchedAddress}
                  selectedAddress={selectedAddress}
                  nearestParcels={nearestParcels}
                  setNearestParcels={setNearestParcels}
                  addressCoordinates={addressCoordinates}
                  isActive
                />
                {nearestParcels && (
                  <NewRatingLayer
                    infos={infos}
                    setCityCode={setCityCode}
                    setSelectedAddress={setSelectedAddress}
                    nearestParcels={nearestParcels.features}
                    disabledFitBound={!!selectedFluxForRatinglayer.length}
                    centerCoord={centerCoord}
                  />
                )}
              </LayerGroup>
              {currentTileLayer}
              {showZonageFlux && (
                <>
                  {fluxData.map(({ key, render }) => {
                    if (selectedFlux.includes(key)) {
                      return <Fragment key={key}>{render}</Fragment>;
                    }
                    return <Fragment key={key} />;
                  })}
                </>
              )}
            </MapContainer>
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <LoadingSection />
          </div>
        )}
      </div>
    );
  }
);

export default memo(MapNewRating);
