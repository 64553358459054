import React, { memo, useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import SectionTitle from './SectionTitle';
import useSWR from 'swr';
import { getFetcher } from '../../../api/fetcher';
import LoadingSection from './LoadingSection';
import _ from 'lodash';
// import LegendSection from "./LegendSection";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MsLineChart = ({ title, data }) => {
  const options = useMemo(
    () => ({
      responsive: true,
      default: {
        borderColor: 'rgb(53, 162, 235)',
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
          text: title,
        },
      },
    }),
    [title]
  );

  if (!data) return <LoadingSection />;

  return (
    <div className={'flex flex-col'}>
      <SectionTitle title={title} />
      <Line options={options} data={data} />
    </div>
  );
};
export default memo(MsLineChart);
