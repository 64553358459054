import React from 'react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import SearchCommunesBy from '../search-communes-by/SearchCommunesBy';
import { useSelector } from 'react-redux';
import FormulaireEpci from '../formulaire-epci-communes/FormulaireEpci';
import { EPCI, ISOCHRONE, NAME } from '../../../constants/constants';
import FormulaireIsochroneCommunes from '../formulaire-isochrone-communes/FormulaireIsoChroneCommunes';
import StickyHeader from '../sticky-header/StickyHeader';
import FormulaireCommuneByName from '../formulaire-commune-by-name/FormulaireCommuneByName';

const FormulaireVotreTerritoire = () => {
  const { searchCommunesBy, returnSearchCommunesBy } = useSelector(
    (state) => state.ciblageCreate
  );

  return (
    <div id="formulaire-territoire" className="w-full">
      <StickyHeader container="formulaire-territoire">
        <div className="flex justify-between items-center px-4 py-6 cursor-pointer">
          <h2 className="text-base xl:text-xl font-semibold">
            Etape 1 / 2 : VOTRE TERRITOIRE
          </h2>
          <div className="flex justify-between">
            <ChevronUpIcon className="w-6 h-6 font-semibold" />
          </div>
        </div>
      </StickyHeader>
      <div>
        {(!searchCommunesBy || returnSearchCommunesBy) && <SearchCommunesBy />}
        {searchCommunesBy?.code === EPCI && !returnSearchCommunesBy && (
          <FormulaireEpci />
        )}
        {searchCommunesBy?.code === ISOCHRONE && !returnSearchCommunesBy && (
          <FormulaireIsochroneCommunes />
        )}
        {searchCommunesBy?.code === NAME && !returnSearchCommunesBy && (
          <FormulaireCommuneByName />
        )}
      </div>
    </div>
  );
};

export default FormulaireVotreTerritoire;
