import _, {map, filter, find} from "lodash";
import rating from "../containers/rating/Rating";

export const getSpecificIncludedRelationship = (
    data,
    type,
    matchingTypeRelationshipTypeData
) => {
    if (matchingTypeRelationshipTypeData) {
        return _.filter(data.included, (includeObject) => {
            return (
                includeObject.type === type &&
                matchingTypeRelationshipTypeData.find(
                    (el) => el.id === includeObject.id
                )
            );
        })
            .map(({attributes}) => {
                return attributes.name;
            })
            .join(" ,");
    }
};

export const getAllIncludedResourcesForAGivenType = (data, type) => {
    return _.filter(data.included, (includeObject) => {
        return includeObject.type === type;
    }).map((includedResource) => includedResource.attributes);
};

export const deserializeAttributesForMSGiven = (market_survey) => {
    let rating = find(market_survey.included, includeObject => {return includeObject.type === "rating"}, {})
    // console.log(`market_survey`, market_survey)
    // console.log(`rating`, rating)

    return {
        related_rating: rating.attributes,
        market_survey: market_survey.data.attributes,
        real_estate_programs: _.map(_.filter(market_survey.included, includeObject => {
            return includeObject.type === "real_estate_program"
        }), program => program.attributes),
    }
};
