import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import reducers from './reducers';
import LogRocket from 'logrocket';
import persistStore from 'redux-persist/es/persistStore';

const middleware = [promise, thunk, LogRocket.reduxMiddleware()];

// if (process.env.NODE_ENV === 'development') {
//   middleware.push(logger);
// }

let store = applyMiddleware(...middleware)(createStore)(reducers);

if (process.env.NODE_ENV === 'development') {
  const composeEnhancer =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist: 'redux-form',
      })) ||
    compose;

  store = composeEnhancer(applyMiddleware(...middleware))(createStore)(
    reducers
  );
}

persistStore(store);

export default store;
