import React, {useEffect, useState} from 'react';
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";

const Accordion = ({
                       children,
                       title,
                       setOnClickAccordion,
                       onClickAccordion,
                       quickCheck,
                       forceOpen,
                       setStateForAccordion,
                       stateForAccordion,
                   }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(forceOpen)
    }, [forceOpen]);

    return (
        <div className='w-full'>
            <div
                className='p-2 flex w-full items-center justify-between relative border-b-1 border-gray-500 cursor-pointer'
                onClick={() => setOpen(!open)}>
                <p className='ml-3 text-sm font-black '>{title}</p>
                {!open && <ChevronDownIcon className={'w-3 h-3 '}/>}
                {open && <ChevronUpIcon className={'w-3 h-3'}/>}
            </div>
            {open && (
                <>
                    <div
                        style={{zIndex: 10, top: 0}}
                        className={`${!quickCheck ? 'bg-gray-200' : ''}`}
                    >
                        <div className='overflow-y'>
                            {children}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Accordion;
