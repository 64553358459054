import { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  othersSliceActions,
  getHasAccessAllStudies,
  checkCanActivateTrial,
} from '../store/slices/others';

const loginEndpoint = process.env.BASE_URL + '/api/v1/login';
const logOutEndpoint = process.env.BASE_URL + '/api/v1/logout';

const getSessionAuthHeader = () => {
  const token = localStorage.getItem('token');
  return JSON.parse(token);
};

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isCapemUser, setIsCapemUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasRightAccess, setHasRightAccess] = useState(false);
  const [needCguAcceptation, setNeedCguAcceptation] = useState(false);
  const [dateGguAcceptation, setDateGguAcceptation] = useState(null);
  const [canActivateTrial, setCanActivateTrial] = useState(true);

  const [sessionAuthHeader, setSessionAuthHeader] = useState(
    getSessionAuthHeader()
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const saveSessionAuthHeader = (sessionAuthHeader) => {
    localStorage.setItem('token', JSON.stringify(sessionAuthHeader));
    setSessionAuthHeader(sessionAuthHeader);
  };

  const deleteSessionAuthHeader = () => {
    localStorage.removeItem('token');
  };

  const logIn = (credential) => {
    return axios.post(loginEndpoint, { user: credential }).then((res) => {
      const returnnedUrl =
        res.request.responseURL.split('?')[1] &&
        new URLSearchParams(res.request.responseURL.split('?')[1]);

      if (returnnedUrl && returnnedUrl.get('r')) {
        const value = returnnedUrl.get('r');
        return {
          error: true,
          message:
            value === 'c'
              ? "Veuillez d'abord confirmer votre email <br /> (pensez à vérifier vos courriers indésirables)."
              : value === 'b'
              ? 'Nous travaillons actuellement à résoudre un problème avec votre compte.<br /> Merci de nous contacter à support@evalparcel.com'
              : "L'email ou le mot de passe que vous avez saisi est incorrect",
        };
      } else {
        const data = res?.data || {};
        const { user, authentication_token } = data;
        if (user && authentication_token) {
          setUser({
            ...user,
            authentication_token: authentication_token,
          });
          setIsAdmin(user.role === 'admin');
          saveSessionAuthHeader({
            token: authentication_token,
            email: user.email,
            first_name: user.first_name,
            role: user.role,
          });
          return { error: false, message: '' };
        }
        return {
          error: true,
          message:
            "L'email ou le mot de passe que vous avez saisi est incorrect",
        };
      }
    });
  };

  const logOut = (callback) => {
    return axios
      .delete(logOutEndpoint)
      .then((res) => res.data)
      .then(() => {
        localStorage.removeItem('openModal');
        deleteSessionAuthHeader();
        setUser(null);
      })
      .then(() => {
        callback && callback();
      });
  };

  const checkSession = async ({ isShare = false }) => {
    if (
      sessionAuthHeader &&
      sessionAuthHeader.email &&
      sessionAuthHeader.token
    ) {
      return axios
        .get('/api/v1/me', {
          headers: {
            'X-USER-EMAIL': sessionAuthHeader.email,
            'X-USER-TOKEN': sessionAuthHeader.token,
          },
        })
        .then(async (res) => {
          if (res.status !== 200) {
            if (!isShare) {
              logOut(() => {
                window.location.assign('/users/sign_in');
              });
            }
          } else {
            const isCapemUser =
              res.data?.data?.attributes.organization?.trim() === 'capem';
            const isAdmin = res.data?.data?.attributes.role === 'admin';

            const {
              payload: {
                full_access_after_quick_check: hasAllAccess,
                date_consent_cgu_org,
                need_to_sign_cgv,
              },
            } = await dispatch(getHasAccessAllStudies());

            const { payload: trialAvailable } = await dispatch(
              checkCanActivateTrial()
            );

            setCanActivateTrial(trialAvailable);

            // form api check_right_access
            setNeedCguAcceptation(need_to_sign_cgv);
            setDateGguAcceptation(date_consent_cgu_org);
            setHasRightAccess(hasAllAccess);

            setUser(res.data?.data?.attributes);
            setIsAdmin(isAdmin);
            setIsCapemUser(isCapemUser);
            dispatch(othersSliceActions.setCapemUser(isCapemUser));

            return {
              isAdmin,
              isCapemUser,
              hasAllAccess,
              user: res.data?.data?.attributes,
            };
          }
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
    }
    return null;
  };

  return {
    setUser,
    user,
    logIn,
    logOut,
    sessionAuthHeader,
    checkSession,
    isAdmin,
    isCapemUser,
    hasRightAccess,
    needCguAcceptation,
    dateGguAcceptation,
    canActivateTrial,
  };
}
