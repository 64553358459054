import React from 'react';

const MessageCantCreateAccount = () => {
  return (
    <div>
      <h1 className="text-xl font-semibold text-center">
        Oups !
      </h1>
      <p className="mt-6 text-center">
        L’accès à EvalparceL est réservé aux abonnés CAPEM <br/>et Adhérents CECIM
      </p>
      <p className="text-sm mt-6 text-center">
        Pour en savoir plus,
        <br /> Prenons le temps d’un échange !
      </p>
      <div className="flex justify-center mt-6">
        <a
          href="https://calendly.com/e-attract_py/30min-1"
          className="inline-block text-white py-3 px-6 bg-blue-500 hover:bg-blue-700 rounded-lg"
        >
          Prendre RDV
        </a>
      </div>
      <p className="mt-6 text-center">
        Ou nous contacter à :<br />
        <a
          href="mailto:support@evalparcel.com"
          className="hover:underline text-blue-500"
        >
          support@evalparcel.com
        </a>
      </p>
    </div>
  );
};

export default MessageCantCreateAccount;
