import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const SelectInput = ({
  onChange,
  value,
  label,
  placeholder,
  onBlur,
  options = [],
  keyValue,
  keyLabel,
  name,
  required,
  error,
}) => {
  const [currentValue, setCurrentValue] = useState(null);

  const handleChange = (e) => {
    const _currentValue =
      (keyValue
        ? options.find((item) => item[keyValue] == e.target.value)
        : e.target.value !== placeholder && e.target.value) || null;

    setCurrentValue(_currentValue);

    onChange &&
      onChange({
        target: {
          name,
          value: _currentValue,
        },
      });
  };

  useEffect(() => {
    if (required && !value) {
      const _currentValue = options[0] || null;
      setCurrentValue(_currentValue);
      onChange &&
        onChange({
          target: {
            name,
            value: _currentValue,
          },
        });
    } else {
      setCurrentValue(value || null);
    }
  }, [value]);

  return (
    <div className="w-full flex flex-col">
      <label className="font-semibold">{label}</label>
      <select
        className={classNames(
          'w-full border-1 rounded-md px-4 py-2 border-gray-500 focus:outline-none',
          { 'opacity-50': !currentValue }
        )}
        onBlur={onBlur}
        value={
          (keyValue && currentValue && currentValue[keyValue]) ||
          currentValue ||
          ''
        }
        onChange={handleChange}
        name={name}
      >
        {!required && (
          <option value={null} className="italic text-gray-500">
            {placeholder || '...'}
          </option>
        )}
        {options.map((el, index) => (
          <option key={index} value={el[keyValue] || el}>
            {el[keyLabel] || el}
          </option>
        ))}
      </select>
      {error && <span className="text-xs text-red-600 mt-1">{error}</span>}
    </div>
  );
};

export default SelectInput;
