import {GeoJSON, useMap} from "react-leaflet";
import React, {useEffect} from "react";
import {useSelectedParcels} from "../../../../hooks/useSelectedParcels";
import L from "leaflet";
import IconAdd from "../../../../../../assets/images/icons/plus.png";
import IconRemove from "../../../../../../assets/images/icons/close.png";

const GeoJsonParcel = ({parcelsData}) => {
    const map = useMap()

    if (!map) return
    const {fieldIds} = useSelectedParcels()
    useEffect(() => {
        const geojsonLayer = L.geoJSON(parcelsData.features)
        if (geojsonLayer && geojsonLayer.getBounds().isValid()) {
            map.fitBounds(geojsonLayer.getBounds())
        }
    }, [parcelsData.features])

    const geoJsonStyle = (parcelData) => {
        return {
            fillColor: fieldIds.includes(parcelData.properties.parcelle_id) ? '#0050E3' : '#63b3ed',
            weight: 2,
            opacity: fieldIds.includes(parcelData.properties.parcelle_id) ? '0.9' : '0.3',
            color: fieldIds.includes(parcelData.properties.parcelle_id) ? '#000' : '#000',
            dashArray: '1',
            fillOpacity: 0.3
        };
    }


    return (
        <>
            {parcelsData?.features.map((parcelData, index) => {
                return (
                    <GeoJSON
                        key={`${parcelData.id}__${index}`}
                        data={parcelsData}
                        pathOptions={geoJsonStyle(parcelData)}
                    />
                )
            })}
        </>)
}


export default GeoJsonParcel