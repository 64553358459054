import { GeoJSON, Marker, Tooltip, useMap } from 'react-leaflet';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import L from 'leaflet';
import polylabel from 'polylabel';
import { useSelectedParcels } from '../../../../hooks/useSelectedParcels';
import IconAdd from '../../../../../../assets/images/icons/plus.png';
import IconRemove from '../../../../../../assets/images/icons/close.png';
import _ from 'lodash';
import Api from '../../../../api/Api';
import { useParcel } from '../../../../_services/parcel-service';

let AddIcon = new L.icon({
  iconUrl: IconAdd,
  iconSize: [21.3, 21.3],
  // shadowUrl: iconShadow,
});

let RemoveIcon = new L.icon({
  iconUrl: IconRemove,
  iconSize: [21.3, 21.3],
  // shadowUrl: iconShadow,
});
const NewRatingLayer = ({
  nearestParcels,
  setCityCode = () => {
    return true;
  },
  disabledFitBound = false,
  centerCoord,
}) => {
  const map = useMap();

  const { selectedParcelsIds, setSelectedParcelsIds, setMainParcelID } =
    useSelectedParcels();
  const [parcelsCollection, setParcelsCollection] = useState(nearestParcels);

  useEffect(() => {
    setParcelsCollection(nearestParcels);
  }, [nearestParcels]);

  //init
  useLayoutEffect(() => {
    setMainParcelID([]);
    setSelectedParcelsIds([]);
  }, []);

  const { parcelData } = useParcel(selectedParcelsIds[0]);

  useEffect(() => {
    if (parcelData) {
      Api.get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/zones/communes/${parcelData.data.attributes.commune_national_code}`
      ).then((res) => {
        setCityCode(res.data.data.attributes.national_code);
        return;
      });
    }
  }, [parcelData]);

  useEffect(() => {
    if (parcelsCollection?.length) {
      const currentZoom = map._zoom;

      if (currentZoom <= 16 && !disabledFitBound) {
        const geojsonLayer = L.geoJSON(parcelsCollection);
        if (geojsonLayer && geojsonLayer.getBounds().isValid()) {
          const containerEl = document.getElementById('new-rating-container');
          if (containerEl) {
            const paddingValues = [
              containerEl.clientWidth,
              containerEl.clientHeight,
            ].map((value) => (10 * value) / 100);
            setTimeout(() => {
              map.fitBounds(geojsonLayer.getBounds(), {
                paddingTopLeft: [paddingValues[1], paddingValues[0]],
                paddingBottomRight: [paddingValues[1], paddingValues[0]],
              });
            }, 500);
          }
        }
      } else {
        if (centerCoord) map.setView(centerCoord, currentZoom); // set just center and keep current zoom
      }
    }
  }, [parcelsCollection, centerCoord]);

  const geoJsonStyle = (parcel) => {
    return {
      fillColor: selectedParcelsIds.includes(parcel.properties.parcelle_id)
        ? '#0050E3'
        : '#63b3ed',
      weight: 2,
      opacity: 1,
      color: '#005080',
      dashArray: 0.8,
      fillOpacity: selectedParcelsIds.includes(parcel.properties.parcelle_id)
        ? '0.6'
        : '0.3',
    };
  };

  const handleSelect = (props) => {
    let updated_selected_parcels;
    if (selectedParcelsIds.includes(props.properties.parcelle_id)) {
      updated_selected_parcels = _.remove(selectedParcelsIds, (parcel_id) => {
        return parcel_id !== props.properties.parcelle_id;
      });
    } else {
      updated_selected_parcels = selectedParcelsIds.concat(
        props.properties.parcelle_id
      );
    }
    setSelectedParcelsIds(_.uniq(updated_selected_parcels));

    Api.get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${props.properties.parcelle_id}/geo`
    ).then((res) => {
      if (selectedParcelsIds.length === 0) {
        setMainParcelID(res.data.parcel.properties.parcelle_id);
      }
      setParcelsCollection(
        _.uniqBy(
          _.concat(parcelsCollection, res.data.touching_parcels.features),
          (feature) => feature.properties.parcelle_id
        )
      );
    });
  };

  return (
    <>
      {parcelsCollection &&
        parcelsCollection.map((nearestParcel, index) => {
          return (
            <GeoJSON
              key={`${nearestParcel.id}__${index}`}
              data={nearestParcel}
              pathOptions={geoJsonStyle(nearestParcel)}
              eventHandlers={{
                click: (e) => {
                  handleSelect(nearestParcel);
                  L.DomEvent.stopPropagation(e);
                },
                mouseover: (e) => e.target.openPopup(),
                mouseleave: (e) => e.target.closePopup(),
              }}
            >
              {nearestParcel.properties.zonage_plu &&
                nearestParcel.properties.parcelle_id && (
                  <Tooltip direction={'top'} offset={[0, 0]}>
                    <div>
                      <h2>
                        <span className="font-bold">Zonage PLU</span>:{' '}
                        {nearestParcel.properties.zonage_plu}
                      </h2>
                      <h2>
                        <span className="font-bold text-blue-500">
                          Parcelle:
                        </span>{' '}
                        {nearestParcel.properties.parcelle_id.slice(-6)}
                      </h2>
                    </div>
                  </Tooltip>
                )}
              <Marker
                key={`marker_quick_check__${index}`}
                position={polylabel(
                  nearestParcel.geometry.coordinates,
                  1.0
                ).reverse()}
                eventHandlers={{
                  click: (e) => {
                    L.DomEvent.stopPropagation(e);
                    handleSelect(nearestParcel);
                  },
                }}
                icon={
                  selectedParcelsIds.includes(
                    nearestParcel.properties.parcelle_id
                  )
                    ? RemoveIcon
                    : AddIcon
                }
              />
            </GeoJSON>
          );
        })}
    </>
  );
};

export default NewRatingLayer;
