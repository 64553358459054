import * as L from 'leaflet';

export const closePopup = (ref) => {
  ref.current.closePopup();
};

export const setFitBound = (map, layers) => {
  const geojsonLayer = L.geoJSON(layers);
  if (geojsonLayer && geojsonLayer.getBounds().isValid()) {
    map.fitBounds(geojsonLayer.getBounds());
  }
};

export const removeAllLayers = (layers, map) => {
  layers.forEach((layer) => {
    map.removeLayer(layer);
  });
};

export const showGeoJsonInMap = ({
  map,
  layers,
  selectedLayers,
  onEachlayer,
  fillColorDefault = '#0050E3',
  borderColorDefault = '#0050E3',
  fillColorSelected = '#ff375f',
  borderColorSelected = '#ff375f',
}) => {
  let _geoJsons = [];
  (layers || []).forEach((feature) => {
    const selected = selectedLayers && selectedLayers(feature);
    const geoJsonLayer = L.geoJSON(feature, {
      style: {
        fillColor: selected ? fillColorSelected : fillColorDefault,
        color: selected ? borderColorSelected : borderColorDefault,
        fillOpacity: selected ? 0.5 : 0.3,
      },
      onEachFeature: (_feature, layer) =>
        onEachlayer && onEachlayer(layer, feature),
    }).addTo(map);
    selected ? geoJsonLayer.bringToFront() : geoJsonLayer.bringToBack();
    _geoJsons.push(geoJsonLayer);
  });
  return _geoJsons;
};
