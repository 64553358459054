import { ExclamationCircleIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useOffsetTop from '../../hooks/useOffsetTop';
import { useAuth } from '../../hooks/useAuth';

const DemandeRendezVous = () => {
  const { linkCalendly, canActivateTrial } = useSelector(
    (state) => state.others
  );
  const { checkSession } = useAuth();
  const paddingTop = useOffsetTop();

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <div
      style={{
        paddingTop: `${paddingTop}px`,
      }}
      className="w-ful h-screen flex flex-col items-center justify-center"
    >
      <ExclamationCircleIcon className="w-20 h-20 text-blue-500" />
      <div className="px-8 text-center text-blue-900">
        <h1 className="font-bold text-2xl">
          Envie de tester les Études de Marché ?
        </h1>
        <p className="mt-4">
          Nous vous offrons 30 jours d'essai avec le code CECIM24.
        </p>
        <div className="flex justify-center mt-4">
          {canActivateTrial ? (
            <a
              href="/activate-trial?code=CECIM24"
              className="gradient-button px-8 py-4 bg-blue-500 rounded-md inline-block text-white hover:bg-blue-700"
            >
              Activer ici votre période d'essai
            </a>
          ) : (
            <a
              href={linkCalendly}
              className="px-8 py-4 bg-blue-500 rounded-md inline-block text-white hover:bg-blue-700"
              target="__blank"
            >
              J'accède à l'Étude de Marché et au Ciblage
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemandeRendezVous;
