import React from 'react';

const LinksBottom = ({}) => {
    let color = {color: '#092c4b'};

    return (
        <div className="flex flex-col items-center  flex-nowrap">
            {/*<Link*/}
            {/*  className="transition duration-500 transform hover:scale-125 cursor-pointer"*/}
            {/*  to={'/users/sign_in'}*/}
            {/*  // style={color}*/}
            {/*>*/}
            {/*  Se connecter*/}
            {/*</Link>*/}
            {/*<Link*/}
            {/*  className="transition duration-500 transform hover:scale-125 cursor-pointer"*/}
            {/*  to={'/users/sign_up'}*/}
            {/*  // style={color}*/}
            {/*>*/}
            {/*  S'inscrire*/}
            {/*</Link>*/}
            {/* <Link to={'/users/password/new'} style={color}>
        Vous avez oublié votre mot de passe ?
      </Link>
      <Link to={'/users/confirmation/new'} style={color}>
        Vous n'avez pas reçu les informations de confirmation ?
      </Link> */}
        </div>
    );
};

export default LinksBottom;
