import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ciblageCreateActions } from '../../slices/ciblageCreate';

export const getAddressesByKeyword = createAsyncThunk(
  'getAddressesByKeyword',
  (keyword, { dispatch }) => {
    dispatch(ciblageCreateActions.setLoading({ isochrone: true }));
    dispatch(ciblageCreateActions.setCommunes([]));
    dispatch(ciblageCreateActions.setValueTerritoireSelectedCommunes([]));
    return axios
      .get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/bano_proxy/search?q=${keyword}`
      )
      .then(({ data: { features } }) => {
        dispatch(
          ciblageCreateActions.setAddresses(features.map((item) => item.properties))
        );
        dispatch(ciblageCreateActions.setLoading(false));
        return features.map((item) => item.properties);
      })
      .catch((e) => {
        console.log(e);
        dispatch(ciblageCreateActions.setLoading(null));
        return [];
      });
  }
);
