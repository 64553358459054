import React, { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import polylabel from 'polylabel';
import L from 'leaflet';
import CurrentField from '../../../../../../assets/images/icons/terrain.svg';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

export const CurrentFieldIcon = new L.icon({
  iconUrl: CurrentField,
  iconSize: [35, 42],
  iconAnchor: [17, 45],
  popupAnchor: [0, -40],
});

const PopupContent = ({ urlStreetView }) => {
  return (
    <div data-marker="land-icon">
      <h1 className="font-semibold text-lg">Terrain évalue</h1>
      <div style={{ width: 300 }} className="mt-2">
        <iframe
          width="100%"
          height="250px"
          loading="lazy"
          allowFullScreen
          src={urlStreetView}
        />
      </div>
    </div>
  );
};

const MarkerPolylabelLayer = ({ onReadyData, onClose }) => {
  const map = useMap();
  const { geoJsonFields: fieldsGeoData } = useSelector((state) => state.others);

  useEffect(() => {
    if (fieldsGeoData) {
      const geoJson = L.geoJSON(fieldsGeoData);

      onReadyData && onReadyData(fieldsGeoData);

      map.on('zoom', (event) => {
        const currentZoom = event.target._zoom;
        if (currentZoom >= 17) {
          geoJson.addTo(map);
        } else {
          map.removeLayer(geoJson);
        }
      });

      //add to map the marker
      fieldsGeoData.features.forEach((item) => {
        const marker = L.marker(
          polylabel(item?.geometry.coordinates, 1.0).reverse(),
          {
            icon: CurrentFieldIcon,
          }
        )
          .setZIndexOffset(10000)
          .addTo(map);
        const popup = L.popup({
          closeButton: true,
        }).setContent(
          ReactDOMServer.renderToString(
            <PopupContent
              urlStreetView={item.properties.google_street_view_embedded_url}
            />
          )
        );
        marker.bindPopup(popup);
        popup.on('remove', () => {
          onClose && onClose();
        });
      });
    }
  }, [fieldsGeoData, map, onClose]);

  return <></>;
};

export default memo(MarkerPolylabelLayer);
