import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PINEL_OPTIONS } from '../../../constants/constants';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';
import SelectMultiple from '../../atoms/select-multiple/SelectMultiple';
import RangeInput from '../../molecules/range-input/RangeInput';
import SliderInput from '../../molecules/slider-input/SliderInput';
import { ThreeButtonsSwitch } from '../filter-marche-immobilier/FilterMarcheImmobilier';
import {
  POPULATION_RANGE_STEP,
  POPULATION_RANGE_MIN,
  POPULATION_RANGE_MAX,
  POPULATION_GROWTH_STEP,
  POPULATION_GROWTH_MIN,
  POPULATION_GROWTH_MAX,
  PERCENT_COLLECTIVE_HOUSING_STEP,
  PERCENT_COLLECTIVE_HOUSING_MIN,
  PERCENT_COLLECTIVE_HOUSING_MAX,
} from '../../../constants/ciblageDefaultValues';
import classNames from 'classnames';

export const ContainerMt6 = ({ children }) => {
  return <div className="mt-6">{children}</div>;
};

export default function FilterTerritoire() {
  const {
    filtersCibler: { isEnabledTerritoire, territoire },
    filtersCibler,
  } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const handleChange = (key) => (value) => {
    if (['changedMayor', 'isActionHeartOfTheCity'].includes(key)) {
      dispatch(
        ciblageCreateActions.setFilterCibler({
          ...filtersCibler,
          isEnabledTerritoire: true,
          territoire: { ...territoire, [key]: value },
        })
      );
    } else {
      dispatch(
        ciblageCreateActions.setFilterCiblerTerritoire({
          ...(territoire || {}),
          [key]: value,
        })
      );
    }
  };

  const handleClickElement = () => {
    if (!isEnabledTerritoire) {
      dispatch(
        ciblageCreateActions.setFilterCibler({
          ...filtersCibler,
          isEnabledTerritoire: true,
        })
      );
    }
  };

  return (
    <div
      className={classNames('filter-territoire relative', {
        'opacity-50': !isEnabledTerritoire,
      })}
    >
      <label>Zone(s) Pinel</label>
      <SelectMultiple
        options={PINEL_OPTIONS}
        placeholder="Sélectionner..."
        keyLabel="label"
        keyValue="value"
        onChange={handleChange('zonesPinel')}
        value={territoire?.zonesPinel || []}
        disabled={!isEnabledTerritoire}
        onClick={handleClickElement}
      />
      <ContainerMt6>
        <label className="mb-2">La population de la commune en 2018</label>
        <RangeInput
          step={POPULATION_RANGE_STEP}
          min={POPULATION_RANGE_MIN}
          max={POPULATION_RANGE_MAX}
          value={territoire?.populationRange}
          onChange={handleChange('populationRange')}
          disabled={!isEnabledTerritoire}
          onClick={handleClickElement}
        />
      </ContainerMt6>
      <ContainerMt6>
        <label>Evolution annuelle de la population de 2013 à 2018</label>
        <div className="flex items-center">
          <div className="flex-grow">
            <SliderInput
              value={territoire?.evolutionPopulation}
              min={POPULATION_GROWTH_MIN}
              max={POPULATION_GROWTH_MAX}
              step={POPULATION_GROWTH_STEP}
              onChange={handleChange('evolutionPopulation')}
              disabled={!isEnabledTerritoire}
              onClick={handleClickElement}
            />
          </div>
          <span className="">% (min)</span>
        </div>
      </ContainerMt6>
      <ContainerMt6>
        <label>% de logement collectif minimum</label>
        <div className="flex items-center">
          <div className="flex-grow">
            <SliderInput
              value={territoire?.logCollectif}
              min={PERCENT_COLLECTIVE_HOUSING_MIN}
              max={PERCENT_COLLECTIVE_HOUSING_MAX}
              step={PERCENT_COLLECTIVE_HOUSING_STEP}
              onChange={handleChange('logCollectif')}
              disabled={!isEnabledTerritoire}
              onClick={handleClickElement}
            />
          </div>
          <span className="">% (min)</span>
        </div>
      </ContainerMt6>
      <ContainerMt6>
        <label>Changement de maire lors des dernières élections</label>
        <ThreeButtonsSwitch
          value={territoire?.changedMayor}
          onChange={handleChange('changedMayor')}
          disabled={!isEnabledTerritoire}
        />
      </ContainerMt6>
      <ContainerMt6>
        <label> Action coeur de ville</label>
        <ThreeButtonsSwitch
          value={territoire?.isActionHeartOfTheCity}
          onChange={handleChange('isActionHeartOfTheCity')}
          disabled={!isEnabledTerritoire}
        />
      </ContainerMt6>
    </div>
  );
}
