import React from 'react';
import Logo from '../../../assets/images/map.jpeg';

const Header = ({logo}) => {
    return (
        <>
            <img
                src={logo ? logo : Logo}
                className='rounded-lg'
                style={{
                    width: '100%',
                    height: 'auto',
                }}
            ></img>
        </>
    );
};
export default Header;
