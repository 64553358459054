import { useCallback, useRef } from 'react';
import { MAP_PROGRAMME, SELECT_PROGRAM_MAP } from '../_helpers/constant';
import { usePrograms } from './usePrograms';
import { useSelector } from 'react-redux';
import {
  centerMapEDM,
  centerMapProgrammeByCommune,
  centerMapProgrammeWithIsochrone,
} from '../components/molecules/DataVisualisations/Layers/MarketSurveysLayer';

const useCenterMap = ({ dataType, programMapRef, needIsochroneVoiture }) => {
  const { communesParcelles } = useSelector((state) => state.others);
  const { isochroneCarFeature } = useSelector((state) => state.marketSurvey);
  const { activePrograms } = usePrograms();
  const mapRef = useRef(null);

  const handleCenterMap = useCallback(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      if (
        dataType === MAP_PROGRAMME &&
        programMapRef.current &&
        !needIsochroneVoiture
      ) {
        const programsDataCarto = programMapRef.current.getProgrammesCarto();
        centerMapEDM(programsDataCarto, activePrograms, communesParcelles, map);
      } else if (needIsochroneVoiture) {
        centerMapProgrammeWithIsochrone(
          isochroneCarFeature || communesParcelles,
          map
        );
      } else if (dataType === SELECT_PROGRAM_MAP && communesParcelles?.length) {
        centerMapProgrammeByCommune(communesParcelles, map);
      }
    }
  }, [
    programMapRef,
    activePrograms,
    communesParcelles,
    needIsochroneVoiture,
    isochroneCarFeature,
  ]);

  return { mapRef, handleCenterMap };
};

export default useCenterMap;
