import React, { useMemo } from 'react';
import classNames from 'classnames';
import RowSpanningPart3Table from './RowSpanningPart3Table';
import { isArray } from 'lodash';

const MergedRowTable = ({ isPdfRenderMode, data }) => {
  const { name, date, table_data } = useMemo(
    () => (!isArray(data) ? data : {}),
    [data]
  );

  return (
    <div className={'col-span-2 flex flex-col'}>
      <div>
        {name && (
          <h3
            dangerouslySetInnerHTML={{ __html: name }}
            className={classNames(
              { 'text-lg text-semibold': isPdfRenderMode },
              'py-3 text-base xl:text-lg leading-3'
            )}
          />
        )}
        {date && <h4>{date}</h4>}
      </div>
      {(!!table_data?.length || !!data?.length) && (
        <div
          className={classNames(
            { 'text-base': isPdfRenderMode },
            'w-full flex-grow'
          )}
        >
          <RowSpanningPart3Table
            isPdfRenderMode={isPdfRenderMode}
            origData={table_data || data}
          />
        </div>
      )}
    </div>
  );
};

export default MergedRowTable;
