import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import React, { useEffect, useState } from 'react';
import ParcelThumb from './ParcelThumb';
import { SwiperContainer } from '../MarketSurveys/sections/CoverMarketSurveySection';
import { SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';

const SelectedParcelsContainer = () => {
  const { selectedParcelsIds } = useSelectedParcels();
  const [swiper, setSwiper] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0);
    }
  }, [selectedParcelsIds, swiper]);

  return (
    <>
      {isMobile ? (
        <SwiperContainer setSwiper={setSwiper} quickCheck>
          {selectedParcelsIds.map((fieldId) => (
            <SwiperSlide key={fieldId}>
              <ParcelThumb id={fieldId} />
            </SwiperSlide>
          ))}
        </SwiperContainer>
      ) : (
        <div className="hidden md:block">
          {selectedParcelsIds.map((fieldId) => (
            <ParcelThumb id={fieldId} key={fieldId} />
          ))}
        </div>
      )}
    </>
  );
};

export default SelectedParcelsContainer;
