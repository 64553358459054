import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STEP_1, STEP_2 } from '../../../constants/ciblageDefaultValues';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';
import FormulaireVotreTerrain from '../formulaire-votre-terrain/FormulaireVotreTerrain';
import FormulaireVotreTerritoire from '../formulaire-votre-territoire/FormulaireVotreTerritoire';
import NbCommunesParcels from '../nb-communes-parcels/NbCommunesParcels';
import moment from 'moment';

const steps = [STEP_1, STEP_2];

const fixWidthNextBtnContainer = () => {
  const containerForm = document.getElementById(
    'formulaire-create-ciblage-container'
  );
  const btnContainer = document.getElementById(
    'formulaire-create-ciblage-next-btn-container'
  );
  const bodyForm = document.getElementById('formulaire-create-body');
  if (containerForm && btnContainer && bodyForm) {
    btnContainer.style.width = `${containerForm.clientWidth}px`;
    bodyForm.style.paddingBottom = `${btnContainer.clientHeight + 24}px`;
  }
};

const fixScroll = () => {
  const container = document.getElementById(
    'formulaire-create-ciblage-container'
  );
  if (container) {
    container.scroll({ top: 0 });
  }
};

const MouseLoading = ({ loading }) => {
  useEffect(() => {
    if (loading) {
      document.body.className = 'waiting';
    } else {
      document.body.className = '';
    }
  }, [loading]);

  return null;
};

export default function TunnelFormulaire({ onSave }) {
  const {
    activeBtnNext,
    loading,
    step,
    valueTerritoire: { selectedCommunes },
    nbParcel,
    showBoxResultNb,
    createdAt,
  } = useSelector((state) => state.ciblageCreate);
  const disabledNextBtn = useMemo(
    () =>
      !activeBtnNext ||
      loading?.save ||
      !nbParcel ||
      ((nbParcel > 1000 ||
        nbParcel <= 0 ||
        nbParcel === 'Affinez les critères') &&
        step.current === STEP_2),
    [activeBtnNext, loading, nbParcel, step]
  );

  const dispatch = useDispatch();

  const handleClickNextBtn = async () => {
    if (step.next) {
      dispatch(
        ciblageCreateActions.setStep({
          current: step.next,
          next: steps[steps.indexOf(step.next) + 1] || null,
        })
      );
    } else {
      onSave && onSave();
    }
  };

  useEffect(() => {
    if (!createdAt) {
      dispatch(ciblageCreateActions.setCreatedAt(new Date()));
    } else if (
      createdAt &&
      (typeof createdAt === 'string' || createdAt instanceof String)
    ) {
      const duration = moment
        .duration(moment().diff(moment(new Date(createdAt))))
        .asDays();
      if (duration >= 2) {
        dispatch(ciblageCreateActions.reset());
      }
    }
  }, [createdAt]);

  useEffect(() => {
    fixWidthNextBtnContainer();
    window.addEventListener('resize', fixWidthNextBtnContainer);
    if (step.current === STEP_1) {
      dispatch(
        ciblageCreateActions.setActiveBtnNext(
          selectedCommunes && !!selectedCommunes.length
        )
      );
    }
    return () => {
      window.removeEventListener('resize', fixWidthNextBtnContainer);
    };
  }, [selectedCommunes]);

  useEffect(() => {
    fixScroll();
    window.addEventListener('resize', fixScroll);
    return () => {
      window.removeEventListener('resize', fixScroll);
    };
  }, [step]);

  return (
    <>
      <MouseLoading loading={loading} />
      <div
        id="formulaire-create-ciblage-container"
        className="w-full h-full overflow-auto relative"
      >
        {loading && <div className="fixed w-full h-full top-0 left-0 z-50" />}
        <div id="formulaire-create-body">
          {step.current === STEP_1 && <FormulaireVotreTerritoire />}
          {step.current === STEP_2 && <FormulaireVotreTerrain />}
        </div>
        <div
          id="formulaire-create-ciblage-next-btn-container"
          className="bottom-0 left-0 fixed w-full bg-white z-40"
        >
          {showBoxResultNb && <NbCommunesParcels />}
          <div className="shadow-next-container border-t-1 border-gray-300 px-4 py-6">
            <button
              disabled={disabledNextBtn}
              onClick={handleClickNextBtn}
              className={classNames(
                'w-full  text-white py-4 rounded-md ',
                {
                  'cursor-not-allowed bg-blue-300': disabledNextBtn,
                },
                {
                  'bg-blue-500 cursor-pointer hover:bg-blue-700':
                    !disabledNextBtn,
                }
              )}
            >
              {step.next ? 'Suivant' : 'Valider et évaluer les terrains'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
