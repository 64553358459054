import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { MapContainer, useMapEvent } from 'react-leaflet';
import Layers from '../Layers';
import ProgramPopup from '../Layers/ProgramPopup';
import { useBlock3_1 } from '../../../MarketSurveys/sections/MsTableNewProgrammes';
import useChangeOnMsMap from '../../../../hooks/useChangeOnMSMap';

const MyProgramPopup = memo(
  ({
    currentProgrammePopupData,
    loadingProgramme,
    onClosePopup,
    readOnlyMode,
    noCluster,
  }) => {
    return (
      <>
        {currentProgrammePopupData && !loadingProgramme && (
          <ProgramPopup
            data={currentProgrammePopupData}
            readOnlyMode={readOnlyMode}
            onClose={onClosePopup}
            noCluster={noCluster}
          />
        )}
      </>
    );
  }
);

const MapChangeLayer = ({ name }) => {
  const { handleMapMetadata } = useChangeOnMsMap();
  const [touchedMap, setTouchedMap] = useState(false);

  useMapEvent('moveend', (ev) => {
    if (touchedMap && name?.length) {
      handleMapMetadata({ name, map: ev.target });
    }
  });

  useMapEvent('mouseover', () => {
    setTouchedMap(true);
  });

  return <></>;
};

const MapData = forwardRef(
  (
    {
      noCluster,
      isMobility,
      enableZonageLayer,
      activeFlux,
      readOnlyMode,
      dataType,
      items,
      isScrollWheelZoom = true,
      dataURL = null,
      zoomLevel,
      noNeedMarker,
      needIsochrone,
      parcelId,
      onLoadMap,
      isSelectedProgramsMap,
      programmesNeufs,
      needIsochroneVoiture,
      eductionMap,
      currentTileLayer,
      isPdfMode,
      onlySelected,
      loadingProgramme,
      onChangeCurrentProgrammePopupDate,
      data,
      onChangeTitleLayer,
      minHeight = 400,
      programMapRef,
      name,
      mapRef,
    },
    ref
  ) => {
    const [currentProgrammePopupData, setCurrentProgrammePopupData] =
      useState(null);
    const [legend, setLegend] = useState(null);
    const { currentProgrammeClicked, setCurrentTranIdClick } = useBlock3_1(
      (state) => state
    );

    useEffect(() => {
      noCluster && setCurrentProgrammePopupData(currentProgrammeClicked);
    }, [currentProgrammeClicked, noCluster]);

    const handleChangeCurrentProgrammePopupDate = useCallback((data) => {
      setCurrentProgrammePopupData(data);
      onChangeCurrentProgrammePopupDate &&
        onChangeCurrentProgrammePopupDate(data);
    }, []);

    useImperativeHandle(ref, () => ({
      closePopupProgramme() {
        setCurrentProgrammePopupData(null);
      },
      openPopupProgramme(data) {
        setCurrentProgrammePopupData(data);
      },
    }));

    const handleClosePopup = useCallback(() => {
      setCurrentProgrammePopupData(null);
      noCluster && setCurrentTranIdClick(null);
    }, [noCluster]);

    const handleShowLegend = (legend) => {
      setLegend(legend);
    };

    return (
      <MapContainer
        ref={mapRef}
        zoomControl={false}
        style={{ height: '100%', minHeight }}
        scrollWheelZoom={isScrollWheelZoom}
      >
        {legend && (
          <div
            className="absolute w-auto  bg-white  p-2 shadow-lg rounded-md"
            style={{ zIndex: 1000, top: 8, left: 8 }}
          >
            {legend}
          </div>
        )}
        <MyProgramPopup
          loadingProgramme={loadingProgramme}
          currentProgrammePopupData={currentProgrammePopupData}
          onClosePopup={handleClosePopup}
          readOnlyMode={readOnlyMode}
          noCluster={noCluster}
        />
        <MapChangeLayer name={name} />
        <Layers
          name={name}
          isMobility={isMobility}
          enableZonageLayer={enableZonageLayer}
          activeFlux={activeFlux}
          data={data}
          noCluster={noCluster}
          onChangeCurrentProgrammePopupDate={
            handleChangeCurrentProgrammePopupDate
          }
          isPdfMode={isPdfMode}
          currentProgrammePopupData={currentProgrammePopupData}
          isSelectedProgramsMap={isSelectedProgramsMap}
          onLoadLegendMarketSurvey={handleShowLegend}
          noNeedMarker={noNeedMarker}
          zoomLevel={zoomLevel}
          readOnlyMode={readOnlyMode}
          dataType={dataType}
          isScrollWheelZoom={isScrollWheelZoom}
          dataUrl={dataURL}
          onChangeTitleLayer={onChangeTitleLayer}
          items={items}
          needIsochrone={needIsochrone}
          parcelId={parcelId}
          onLoadMap={onLoadMap}
          programmesNeufs={programmesNeufs}
          needIsochroneVoiture={needIsochroneVoiture}
          eductionMap={eductionMap}
          currentTileLayer={currentTileLayer}
          onlySelected={onlySelected}
          programMapRef={programMapRef}
        />
      </MapContainer>
    );
  }
);

export default memo(MapData);
