import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import { CurrentFieldIcon } from './MarkerPolylabelLayer';
import { useMetadataMs } from '../../../../hooks/useChangeOnMSMap';
import useCenterByMetadata from '../../../../_helpers/map-helpers/useCenterByMetadata';

const ParcelLayer = ({ isPdfMode, name }) => {
  const { geoJsonFields } = useSelector((state) => state.others);
  const { parcelData } = useSelector((state) => state.programme);
  const map = useMap();
  const [coordinates, setcoordinates] = useState(null);
  const [fieldsLayer, setFieldsLayer] = useState(null);

  const metadata = useMetadataMs((state) => state.metadata);

  const centerByMetadata = useCenterByMetadata({
    metadata,
    map,
    isPdfMode,
    name,
  });

  useEffect(() => {
    const getAddress = async (address) => {
      const { data } = await axios(
        `https://api-adresse.data.gouv.fr/search/?q=${address}`
      );
      if (data?.features[0]?.geometry?.coordinates) {
        setcoordinates(data?.features[0]?.geometry?.coordinates);
      }
    };
    if (parcelData && !isPdfMode) {
      getAddress(`${parcelData.address} ${parcelData.town}`);
    }
  }, [parcelData, isPdfMode]);

  useEffect(() => {
    if (geoJsonFields) {
      if (fieldsLayer) {
        map.removeLayer(fieldsLayer);
      }
      const featureGroup = L.featureGroup();

      L.geoJSON(geoJsonFields, {
        style: { fillOpacity: 0.3, color: '#0040a6', fillColor: '#0360f5' },
        onEachFeature: function (feature, layer) {
          if (!isPdfMode) {
            layer.bindPopup(/* HTML */ `<p class="font-semibold">
              Parcelle ${feature.properties.display_parcelle_id}
              (${feature.properties.superficie} m²)
            </p>`);
          }
        },
      }).addTo(featureGroup);

      if (featureGroup.getBounds().isValid()) {
        featureGroup.addTo(map);
        setFieldsLayer(featureGroup);
        if (!centerByMetadata) {
          map.fitBounds(featureGroup.getBounds());


        setTimeout(() => {
          try {
            // Ensure map and its properties are valid before setting zoom
            if (map && map._loaded && map.getCenter()) {
              map.setZoom(15);
            } else {
              console.warn("MyComponent: map is not fully initialized");
            }
          } catch (error) {
            console.warn("MyComponent: error setting zoom", error);
          }
        }, 2000);
        }
      }
    }
  }, [geoJsonFields, map, centerByMetadata]);

  useEffect(() => {
    var markers = [];
    const handleZoomEnd = (ev, _map) => {
      if (geoJsonFields) {
        const currentZoomLevel = (_map ? _map : ev?.target)._zoom;
        if (currentZoomLevel <= 15) {
          geoJsonFields.features.forEach((item) => {
            const geoJson = L.geoJSON(item).getBounds().getCenter();
            const marker = L.marker(geoJson, {
              icon: CurrentFieldIcon,
            })
              .setZIndexOffset(10000)
              .addTo(map);
            markers.push(marker);
          });
        } else {
          markers.forEach((item) => {
            map.removeLayer(item);
          });
          markers = [];
        }
      }
    };
    if (isPdfMode) {
      handleZoomEnd(null, map);
    }
    map.on('zoomend', handleZoomEnd);
    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [geoJsonFields, map, isPdfMode]);

  return (
    <>
      {coordinates && (
        <div
          className="absolute top-0 left-0 bg-white shadow-lg"
          style={{ width: 230, height: 230, zIndex: 4000 }}
        >
          {!isPdfMode && (
            <iframe
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${coordinates[1]},${coordinates[0]}`}
            />
          )}
        </div>
      )}
    </>
  );
};

export default memo(ParcelLayer);
