import React from "react";
import ConceptNoteSectionTitle from "./ConceptNoteSectionTitle";
import ConceptNoteSectionBody from "./ConceptNoteSectionBody";
import useSWR from "swr";
import Api from "../../../../api/Api";

const ConceptNoteSection = ({title, url}) => {
    const fetcher = url => Api.get(url).then(res => res.data)
    const {data, error} = useSWR(url, fetcher)

    if (!data) {
        return <div>Loading... </div>
    }

    if (error) {
        return <div>Loading failed...</div>
    }


    return (
        <div className={'w-1/2 p-2'}>
            <ConceptNoteSectionTitle title={title}/>
            <ConceptNoteSectionBody points={data}/>
        </div>);
}

export default ConceptNoteSection