import { createAsyncThunk } from '@reduxjs/toolkit';
import { ciblageCreateActions } from '../../slices/ciblageCreate';
import axios from 'axios';
import { getNbParcel } from './parcel';

export const getCommunesByCatAndCode = createAsyncThunk(
  'ciblable/getCommunesByCatAndCode',
  async ({ category, code }, { dispatch }) => {
    dispatch(ciblageCreateActions.setValueTerritoireSelectedCommunes([]));
    dispatch(ciblageCreateActions.setCommunes([]));
    dispatch(ciblageCreateActions.setNbParcel(null));
    dispatch(ciblageCreateActions.setValueTerritoireCommune(null));
    if (code) {
      dispatch(ciblageCreateActions.setShowResultNb(true));
      dispatch(ciblageCreateActions.setLoading({ map: true }));
      try {
        const { data } = await axios.get(
          `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/zones?category=${category}&national_code=${code}`
        );
        if (data.features) {
          dispatch(ciblageCreateActions.setCommunes(data.features));
        } else {
          dispatch(ciblageCreateActions.setCommunes([data]));
          dispatch(
            ciblageCreateActions.setValueTerritoireSelectedCommunes([data])
          );
          dispatch(
            ciblageCreateActions.setLoading({ map: true, parcel: true })
          );
          dispatch(
            ciblageCreateActions.setValueTerritoireCommune({
              label: data.properties.name,
              code: data.properties.national_code,
            })
          );
          await dispatch(
            getNbParcel({
              selectedCommunes: [data],
            })
          );
        }

        dispatch(ciblageCreateActions.setLoading(null));
        return data.features || [];
      } catch (e) {
        console.log(e);
        dispatch(ciblageCreateActions.setCommunes([]));
        dispatch(ciblageCreateActions.setLoading(null));
        return [];
      }
    } else {
      dispatch(ciblageCreateActions.setShowResultNb(false));
      return [];
    }
  }
);

export const selectCommune =
  ({ id, checked }) =>
  async (dispatch, getState) => {
    const {
      communes,
      cibler,
      valueTerritoire: { selectedCommunes },
    } = getState().ciblageCreate;

    let currentSelectedCommunes = selectedCommunes || [];
    if (checked) {
      currentSelectedCommunes = [
        ...currentSelectedCommunes,
        communes.find((item) => item?.id === id),
      ];
    } else {
      currentSelectedCommunes = currentSelectedCommunes.filter(
        (item) => item?.id !== id
      );
    }
    dispatch(ciblageCreateActions.setCibler(cibler ? cibler : false));
    dispatch(
      ciblageCreateActions.setValueTerritoireSelectedCommunes(
        currentSelectedCommunes
      )
    );
    dispatch(ciblageCreateActions.setLoading({ parcel: true }));
    await dispatch(getNbParcel({ selectedCommunes: currentSelectedCommunes }));
    dispatch(ciblageCreateActions.setLoading(null));
  };

export const selectOrUnSelectAllCommune = () => async (dispatch, getState) => {
  const {
    communes,
    valueTerritoire: { selectedCommunes },
  } = getState().ciblageCreate;

  let canSelectAll = false;
  if (communes && selectedCommunes && communes.length) {
    canSelectAll = communes.length !== selectedCommunes.length;
  }
  dispatch(ciblageCreateActions.setCibler(false));
  dispatch(
    ciblageCreateActions.setValueTerritoireSelectedCommunes(
      canSelectAll ? communes : []
    )
  );
  if (canSelectAll) {
    dispatch(ciblageCreateActions.setLoading({ parcel: true }));
    await dispatch(getNbParcel({ selectedCommunes: communes }));
    dispatch(ciblageCreateActions.setLoading(null));
  } else {
    dispatch(ciblageCreateActions.setNbParcel(null));
  }
};

export const getCommunesByIsochrone = createAsyncThunk(
  'getIsochroneMunicipalities',
  async (_, { dispatch, getState }) => {
    const {
      isoChroneFilterValue: {
        isoChroneAddress,
        transportDuration,
        transportMode,
      },
    } = getState().ciblageCreate;

    dispatch(ciblageCreateActions.setNbParcel(0));
    dispatch(ciblageCreateActions.setValueTerritoireSelectedCommunes([]));
    dispatch(ciblageCreateActions.setCommunes([]));

    if (isoChroneAddress) {
      dispatch(ciblageCreateActions.setShowResultNb(true));
      dispatch(ciblageCreateActions.setLoading({ map: true }));
      const {
        data: { features },
      } = await axios.get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/zones_by_isochrone?transport_mode=${
          transportMode.value
        }&transport_time_max=${transportDuration * 60}&lon=${
          isoChroneAddress.lon
        }&lat=${isoChroneAddress.lat}`
      );
      dispatch(ciblageCreateActions.setCommunes(features));
      dispatch(ciblageCreateActions.setLoading(null));
    }
  }
);

export const getCommunesNameAndCodeBykeyword = createAsyncThunk(
  'getCommunesNameAndCodeBykeyword',
  (keyword, { dispatch }) => {
    dispatch(ciblageCreateActions.setLoading({ communes: true }));
    return axios
      .get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/nom_zones_ciblages?q=${keyword.trim()}`
      )
      .then(({ data }) => {
        const mappedData = ((data.length && data) || [])
          .filter((item) => item[2] === 'communes')
          .map((item) => ({
            code: item[1],
            label: `${item[0]} (${item[1].slice(0, 2)})`,
          }));
        dispatch(ciblageCreateActions.setCommunesNameAndCode(mappedData));
        dispatch(ciblageCreateActions.setLoading(null));
        return mappedData;
      })
      .catch((e) => {
        console.log(e);
        dispatch(ciblageCreateActions.setCommunesNameAndCode([]));
        dispatch(ciblageCreateActions.setLoading(null));
        return [];
      });
  }
);

export const clearCommuneAutocomplete = () => (dispatch) => {
  dispatch(ciblageCreateActions.setCommunes([]));
  dispatch(ciblageCreateActions.setValueTerritoireCommune(null));
  dispatch(ciblageCreateActions.setValueTerritoireSelectedCommunes([]));
  dispatch(ciblageCreateActions.setNbParcel(0));
};
