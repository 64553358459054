import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BottomNavbar from '../../components/BottomNavbar';
import Navbar from '../../components/molecules/Navbar';
import { ciblageCreateActions } from '../../store/slices/ciblageCreate';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

const desktopOnlyFunc = (callback) => {
  if (screen.width >= 1024) {
    callback(false);
  } else {
    callback(true);
  }
};

export default function CiblageLayout({ children, desktopOnly }) {
  const [paddingTop, setPaddingTop] = useState(0);
  const [hideContent, setHideContent] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const fixPaddingTop = () => {
      const navbar = document.getElementById('navbar');
      const underNavbar = document.getElementById('bottom-navbar');
      if (navbar && underNavbar) {
        setPaddingTop(navbar.clientHeight + underNavbar.clientHeight);
      }
    };
    const eventFuncsCallBack = () => {
      fixPaddingTop();
      desktopOnly &&
        desktopOnlyFunc((value) => {
          setHideContent(value);
        });
    };
    setTimeout(() => {
      eventFuncsCallBack();
    }, 100);
    window.addEventListener('resize', eventFuncsCallBack);
    return () => {
      window.removeEventListener('resize', eventFuncsCallBack);
    };
  }, [desktopOnly]);

  const handleClickNewCiblage = () => {
    if (location.pathname !== '/ciblage/new')
      dispatch(ciblageCreateActions.reset());
  };

  return (
    <>
      <Navbar>
        <BottomNavbar
          listCategory={[
            { id: 1, url: '/ciblage', name: 'Accueil ciblage' },
            {
              id: 2,
              url: '/ciblage/list',
              name: 'Parcelles ciblées',
            },
            {
              id: 3,
              url: '/ciblage/new',
              name: 'Nouveau ciblage',
              onClick: handleClickNewCiblage,
            },
          ]}
        />
      </Navbar>
      <div
        style={{ paddingTop: `${paddingTop}px` }}
        className="w-full h-screen"
      >
        {hideContent ? (
          <div className="h-full flex flex-col items-center justify-center">
            <ExclamationCircleIcon className="w-10 h-10 text-blue-500" />
            <div className="px-8 text-center text-blue-900">
              <p className="font-bold">Votre écran est trop petit.</p>
              <p>
                Afin de garantir une expérience utilisateur optimale nous vous
                invitons à effectuer le ciblage sur un écran de largeur
                supérieure.{' '}
              </p>
            </div>
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </>
  );
}
