import React from "react";

const ConceptNoteCommentSection = ({big}) => {
    return <>
        <div className={'border-b-2 border-blue-900'}>
            <h3>Vos commentaires</h3>
        </div>
        <div className={`${big ? 'p-20' : 'p-8'} bg-white  mt-3 border-1 border-black`}/>
    </>
}

export default ConceptNoteCommentSection
