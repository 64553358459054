import React, { createRef, useEffect, useMemo, useRef, memo } from 'react';
import { useSelectedParcels } from '../../../../hooks/useSelectedParcels';
import _, { uniqueId } from 'lodash';
import MarkerPolylabelLayer from './MarkerPolylabelLayer';
import useSWR from 'swr';
import { getFetcher } from '../../../../api/fetcher';
import LoadingSection from '../../../MarketSurveys/sections/LoadingSection';
import { Marker, Popup, useMap } from 'react-leaflet';
import MsFeaturesCollectionLayer from './MarketSurvey/MsFeaturesCollectionLayer';
import L from 'leaflet';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import { poiEquipementIcons, poiIcons } from '../../../icons';
import BasicLegendLayer from '../Map/Legends/BasicLegendLayer';
import styled from 'styled-components';
import { XIcon } from '@heroicons/react/outline';
import { closePopup } from '../../../../_helpers/_map-helper';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { fitBoundWithPadding } from './MsPOILayer';
import useCenterByMetadata from '../../../../_helpers/map-helpers/useCenterByMetadata';
import { useMetadataMs } from '../../../../hooks/useChangeOnMSMap';

const NearestShopsAndPublicEquipmentPOIPopup = styled(Popup)`
  background-color: white;
  border-radius: 0;
  width: 250px;
  height: auto;
  border-radius: 10px;
  .leaflet-popup-content-wrapper {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;

const NearestShopsAndPublicEquipmentPoiMarker = memo(
  ({ geometry, properties }) => {
    const ref = useRef(null);

    return (
      <Marker
        ref={ref}
        position={transformCoordinates(geometry.coordinates)}
        icon={
          poiEquipementIcons[properties.amenity]
            ? L.divIcon({
                html: /*html*/ `
        <div style="background-color: ${
          poiEquipementIcons[properties.amenity].color
        }" class="w-8 h-8 equipement-icon rounded-full flex items-center justify-center">
          <img class="w-6 h-auto" src="${
            poiEquipementIcons[properties.amenity].url
          }"/>
        </div>`,
              })
            : poiIcons(properties.amenity)
        }
      >
        <NearestShopsAndPublicEquipmentPOIPopup>
          <div className={'absolute right-0 top-0'}>
            <button onClick={() => closePopup(ref)} className={'p-2'}>
              <XIcon className={'w-5 h-5 text-blue-800 hover:text-blue-900'} />
            </button>
          </div>
          <div>
            <h1 className="text-base 2xl:text-lg font-semibold">
              {properties.name}
            </h1>
            {/* <div className="mt-2">
            <iframe
              width="100%"
              height="250px"
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${transformCoordinates(
                geometry.coordinates
              ).join(',')}`}
            />
          </div> */}
          </div>
        </NearestShopsAndPublicEquipmentPOIPopup>
      </Marker>
    );
  }
);

const OSMLayer = memo(() => {
  const { osmDataCarto } = useSelector((state) => state.marketSurvey);

  return osmDataCarto.map((item, i) =>
    item.data.features.map((el, j) => {
      const ref = createRef();
      return (
        <Marker
          key={i + j}
          ref={ref}
          position={transformCoordinates(el.geometry.coordinates)}
          icon={L.divIcon({
            html: /*html*/ `
            <div style="background-color: ${
              poiEquipementIcons[item.icon].color
            }" class="w-8 h-8 equipement-icon rounded-full flex items-center justify-center">
              <img class="w-6 h-auto" src="${
                poiEquipementIcons[item.icon].url
              }"/>
            </div>`,
          })}
        >
          <NearestShopsAndPublicEquipmentPOIPopup>
            <div className={'absolute right-0 top-0'}>
              <button onClick={() => closePopup(ref)} className="p-2">
                <XIcon
                  className={'w-5 h-5 text-blue-800 hover:text-blue-900'}
                />
              </button>
            </div>
            <div>
              <h1 className="text-base 2xl:text-lg font-semibold">
                {el.properties.name || el.properties.amenity}
              </h1>
              {/* <div className="mt-2">
                <iframe
                  width="100%"
                  height="250px"
                  loading="lazy"
                  allowFullScreen
                  src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${transformCoordinates(
                    el.geometry.coordinates
                  ).join(',')}`}
                />
              </div> */}
            </div>
          </NearestShopsAndPublicEquipmentPOIPopup>
        </Marker>
      );
    })
  );
});

const NearestShopsAndPublicEquipmentPOILayer = ({
  urlMap,
  dataArray,
  isPdfMode = false,
  name,
}) => {
  const map = useMap();
  const { fieldIds } = useSelectedParcels();
  const res = !dataArray && useSWR(urlMap, getFetcher);

  const data = useMemo(
    () => (dataArray ? dataArray : res?.data || null),
    [res, dataArray]
  );

  const metadata = useMetadataMs((state) => state.metadata);

  const centerByMetadata = useCenterByMetadata({
    metadata,
    map,
    isPdfMode,
    name,
  });

  const { osmDataCarto, accessiblityIsochoneData, errorAccessiblityIsochone } =
    useSelector((state) => state.marketSurvey);

  useEffect(() => {
    if (accessiblityIsochoneData?.length && !centerByMetadata) {
      // get Isocrone Polygon
      const isochroneFeature =
        accessiblityIsochoneData.length &&
        accessiblityIsochoneData.find((item) => item.parcelId === fieldIds[0])
          ?.data.features[0];

      const equipementPublicFeatures = data?.features || [];

      // get all Point OSM
      let osmFeature = [];
      (osmDataCarto || [])
        .map((item) => item.data)
        .forEach((item) => {
          if (item.features.length) {
            item.features.forEach((el) => {
              osmFeature.push(el);
            });
          }
        });

      const markerGroup = L.featureGroup();

      // add in markergroup all marker in isochrone
      const markerInsideIsochrone = [...equipementPublicFeatures, ...osmFeature]
        .map((item) => {
          if (isochroneFeature && turf.inside(item, isochroneFeature)) {
            return item;
          }
        })
        .filter((item) => item);

      if (
        !markerInsideIsochrone.length &&
        (equipementPublicFeatures.length || osmFeature.length)
      ) {
        [...equipementPublicFeatures, ...osmFeature].forEach((item) => {
          L.marker(transformCoordinates(item.geometry.coordinates)).addTo(
            markerGroup
          );
        });
      } else {
        L.geoJSON(isochroneFeature).addTo(markerGroup);
      }

      fitBoundWithPadding(map, markerGroup, isPdfMode ? 25 : 10);
    }
  }, [
    data,
    accessiblityIsochoneData,
    osmDataCarto,
    fieldIds,
    map,
    isPdfMode,
    centerByMetadata,
  ]);

  useEffect(() => {
    if (
      errorAccessiblityIsochone &&
      data?.features?.length &&
      !centerByMetadata
    ) {
      const featureGroup = L.featureGroup();
      data.features.forEach((item) => {
        L.marker(transformCoordinates(item.geometry.coordinates)).addTo(
          featureGroup
        );
      });

      fitBoundWithPadding(map, featureGroup, isPdfMode ? 25 : 10);
    }
  }, [errorAccessiblityIsochone, data, isPdfMode, map, centerByMetadata]);

  if (!data) return <LoadingSection />;

  return (
    <>
      {data &&
        data.features.map(({ geometry, properties }) => {
          return (
            <NearestShopsAndPublicEquipmentPoiMarker
              key={uniqueId('ms__marker__')}
              geometry={geometry}
              properties={properties}
            />
          );
        })}
      <MsFeaturesCollectionLayer data={data} />
      <MarkerPolylabelLayer fieldIds={fieldIds} />
      <BasicLegendLayer
        needIsochrone={errorAccessiblityIsochone ? false : true}
        data={data.legend}
        otherData={osmDataCarto}
      />
      <OSMLayer />
    </>
  );
};

export default memo(NearestShopsAndPublicEquipmentPOILayer);
