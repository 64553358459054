import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCommuneAutocomplete,
  getCommunesByCatAndCode,
  getCommunesNameAndCodeBykeyword,
} from '../../../store/actions/ciblage/commune';
import CustomAutocomplete from '../../molecules/custom-autocomplete/CustomAutocomplete';
import SelectedSearchBy from '../selelected-search-by/SelectedSearchBy';

const FormulaireCommuneByName = () => {
  const {
    communesNameAndCode,
    valueTerritoire: { commune },
  } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const handleChangeInputValueAutocomplete = useCallback((keyword) => {
    dispatch(getCommunesNameAndCodeBykeyword(keyword));
  }, []);

  const handleChangeCommune = useCallback((value) => {
    if (value) {
      dispatch(
        getCommunesByCatAndCode({ category: 'communes', code: value.code })
      );
    } else {
      dispatch(clearCommuneAutocomplete());
    }
  }, []);

  return (
    <>
      <SelectedSearchBy />
      <div className=" mx-4">
        <div className="mt-4">
          <label>Renseignez le nom ou code postal de la commune</label>
          <CustomAutocomplete
            placeholder=""
            keyLabel="label"
            keyValue="code"
            onChange={handleChangeCommune}
            onChangeInputValue={handleChangeInputValueAutocomplete}
            options={communesNameAndCode}
            value={commune}
          />
        </div>
      </div>
    </>
  );
};

export default FormulaireCommuneByName;
