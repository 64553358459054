import React, { useEffect, useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../../../assets/images/logo.svg';
import Dropdown from '../Dropdown';
import { useAuth } from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationUser } from '../../store/slices/others';
import { isArray } from 'lodash';

const handleActiveTabs = (args) => {
  return !!(isArray(args) ? args : [args]).filter(
    (el) => window.location.pathname.indexOf(el.trim()) >= 0
  ).length;
};

const Navbar = ({ children }) => {
  const [showSoon, setShowSoon] = useState(false);
  const { currentUserOrganizations } = useSelector((state) => state.others);
  const dispatch = useDispatch();

  let { logOut, isAdmin, user } = useAuth();
  const history = useHistory();

  const items = useMemo(
    () => [
      {
        name: 'Mon compte',
        onClickCallback: () => history.push('/mon-compte'),
        isFeatureAvailable: true,
      },
      {
        name: 'Gestion des utilisateurs',
        onClickCallback: () => history.push('/admin-user'),
        isFeatureAvailable: true,
        hide: !currentUserOrganizations?.length,
      },
      {
        name: 'Se déconnecter',
        onClickCallback: () =>
          logOut(() => {
            history.push('/users/sign_in');
          }),
        isFeatureAvailable: true,
      },
    ],
    [currentUserOrganizations]
  );

  useEffect(() => {
    if (user) {
      dispatch(getOrganizationUser());
    }
  }, [user]);

  return (
    <>
      <nav
        id={'navbar'}
        className="bg-white grid grid-cols-12 items-center justify-between fixed w-full z-40 print:hidden pt-2 md:pt-0"
      >
        <div className="flex items-center col-span-12 md:col-span-2">
          <Link className="ml-2 md:ml-4 p-1 2xl:p-2 flex items-center" to={'/'}>
            <img className="w-32 2xl:w-48" src={Logo} alt={'evalparcel icon'} />
          </Link>
        </div>
        <div className="relative self-stretch items-center text-xs xl:text-base flex  md:ml-3 text-white md:col-start-3 md:col-span-7 col-span-12">
          <Link
            to={'/ratings'}
            className={`m-0 transition transform duration-200 self-stretch py-2 px-6 2xl:py-3 text-xs 2xl:text-base flex items-center ${
              user && handleActiveTabs(['ratings', 'market-survey'])
                ? 'text-white bg-blue-darken'
                : 'hide text-black'
            }`}
          >
            Quick Checks & Études
          </Link>
          {isAdmin ? (
            <>
              <Link
                to="/ciblage"
                className={`m-0 transition transform duration-200 self-stretch p-5 flex items-center ${
                  handleActiveTabs('/ciblage')
                    ? 'text-white bg-blue-darken'
                    : 'hide text-black'
                }`}
              >
                Ciblage
              </Link>
            </>
          ) : (
            <>
              <span
                onMouseEnter={() => setShowSoon(true)}
                onMouseLeave={() => setShowSoon(false)}
                className={`m-0 transition transform duration-200 self-stretch p-5 flex items-center text-black`}
              >
                Ciblage
              </span>
              {showSoon && !isAdmin && (
                <div className="w-2/4 text-center left-0 z-50 rounded-lg p-3 absolute top-4 right-10 bg-white shadow-lg text-blue-600">
                  Bientôt disponible
                </div>
              )}
            </>
          )}
          {/* hr style */}
          <hr
            className="border-2 border-gray-100"
            style={{
              width: 1,
              height: '50%',
              display: 'flex',
            }}
          />
        </div>
        <div className="items-center top-1 md:top-0 right-0 absolute md:relative col-start-11 col-span-3 flex gap-x-2 md:gap-x-6">
          <div className="md:mr-6 rounded-full relative flex items-center justify-center">
            <Dropdown
              items={items}
              noUser={!user}
              title={user?.first_name || 'Invité'}
              icon="ri-arrow-down-s-line"
            />
          </div>
        </div>
      </nav>
      {children}
    </>
  );
};

export default Navbar;
