import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api/Api';
import { ciblageCreateActions } from '../../slices/ciblageCreate';
import moment from 'moment';
import { buildParamsByJson } from './parcel';

export const saveCiblage = createAsyncThunk(
  'saveCiblage',
  async (_, { dispatch, getState }) => {
    const {
      filtersTerrainJson,
      filtersCiblerJson,
      valueTerritoire: { selectedCommunes, epci, commune },
      isoChroneFilterValue,
      nbParcel,
    } = getState().ciblageCreate;

    const filters = {
      ...filtersCiblerJson,
      ...filtersTerrainJson,
      ...(epci ? { national_code: epci.code } : {}),
      communes_national_codes: selectedCommunes.map(
        (item) => item.properties.national_code
      ),
    };

    const body = {
      api_url_params: buildParamsByJson(filters),
      evaluate_date: new Date(),
      address: epci
        ? epci.label
        : isoChroneFilterValue?.isoChroneAddress?.label
        ? isoChroneFilterValue?.isoChroneAddress?.label
        : commune
        ? commune.label
        : '...',
      search_type: epci
        ? 'epci'
        : isoChroneFilterValue?.isoChroneAddress
        ? 'isochrone'
        : 'commune',
      query_params: JSON.stringify(filters),
      nb_results: nbParcel,
      name: `ciblage-${moment(new Date()).format('DDMMYYYY')}`,
    };

    dispatch(ciblageCreateActions.setLoading({ map: true, save: true }));

    return Api.post('/targets', body)
      .then(({ data }) => {
        dispatch(ciblageCreateActions.setLoading(null));
        dispatch(ciblageCreateActions.reset());
        return data;
      })
      .catch((e) => {
        console.log(e);
        dispatch(ciblageCreateActions.setLoading(null));
        return null;
      });
  }
);
