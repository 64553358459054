import React, {useEffect} from 'react';

const Loader = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    });

    return (
        <div className='loading-overlay'>
            <div>
                <h1 className='text-2xl font-semibold text-center text-white'>
                    EvalparceL
                </h1>
                <h1 className='text-sm font-semibold text-center text-white'>
                    Chargement...
                </h1>
                <div className='loading'>
                    {new Array(5).fill().map(() => (
                        <div className='dot'/>
                    ))}
                </div>
            </div>
        </div>
    );
};
// const mapStateToProps = state => {
//   return {
//     isOpen: state.loading
//   }
// }
export default Loader;
