import React, { useEffect, useRef } from 'react';
import { TileLayer } from 'react-leaflet';

const SatelliteLayer = ({ onError }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && onError) {
      ref.current.on('tileerror', onError);
    }
  }, [onError]);

  return (
    <>
      <TileLayer
        attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
        url="https://wmts.geopf.fr/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileRow={y}&TileCol={x}"
        maxZoom={20}
        maxNativeZoom={19}
      />
      <TileLayer
        ref={ref}
        attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
        url="https://wmts.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&STYLE=PCI vecteur&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
        maxZoom={20}
        maxNativeZoom={19}
      />
    </>
  );
};

export const titleLayersEdm = {
  classique: {
    name: 'Classique',
    layer: (
      <>
        <TileLayer
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
          url="https://wmts.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&STYLE=PCI vecteur&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
          maxZoom={20}
          maxNativeZoom={19}
        />
      </>
    ),
  },
  satellite: {
    name: 'Satellite',
    layer: (props) => <SatelliteLayer onError={props?.onError} />,
  },
  nuit: {
    name: 'Nuit',
    layer: (
      <>
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
        />
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
          url="https://wmts.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&STYLE=PCI vecteur&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
          maxZoom={20}
          maxNativeZoom={19}
        />
      </>
    ),
  },
  lumiere: {
    name: 'Lumière',
    layer: (
      <>
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
          url="https://wmts.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&STYLE=PCI vecteur&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
          maxZoom={20}
          maxNativeZoom={19}
        />
      </>
    ),
  },
};
