import { isArray } from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  ArrowNarrowUpIcon,
  SwitchVerticalIcon,
  ArrowNarrowDownIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';

/*
interface Column {
  label?: string;
  key?: string;
  sortable?: boolean;
  render?: (rowData: any) => ReactNode;
}
*/

export default function Table({
  classnameActive,
  columns,
  data = [],
  onRowClick,
  selectedRows,
  onChangeSorting,
  sorting,
  loading,
  classNameTh,
  classNameTd,
  selectedData = [],
  unselectedData = [],
  idKeyname,
  maxSelectedOnTop = 3,
  clickedTr,
  separated = false,
}) {
  // const [topHeight, setTopHeight] = useState(0);
  const handleClickThSortable = (key, value) => {
    onChangeSorting &&
      onChangeSorting({
        key,
        value: !value ? 'desc' : value === 'desc' ? 'asc' : 'desc',
      });
  };
  const theadRef = useRef(null);
  const [selectedTop, setSelectedTop] = useState(0);
  const [maxHeightTbody, setMaxHeightTbody] = useState(0);
  const containerRef = useRef(null);
  const notSelectedBodyRef = useRef(null);
  const tbodySelectedRef = useRef(null);
  const [rowData, setRowData] = useState({ selected: [], unselected: [] });

  useEffect(() => {
    if (separated) {
      setRowData({ selected: selectedData, unselected: unselectedData });
    }
  }, [unselectedData, selectedData]);

  useEffect(() => {
    const fixMaxHeight = () => {
      if (tbodySelectedRef.current) {
        if (tbodySelectedRef.current.children.length >= maxSelectedOnTop) {
          let maxHeight = 0;
          for (let i = 0; i < maxSelectedOnTop; i++) {
            const el = tbodySelectedRef.current.children[i];
            maxHeight = maxHeight + el.clientHeight;
          }
          setMaxHeightTbody(maxHeight);
        }
      }
    };

    const fixHeightUnselectedBodyHeight = () => {
      if (
        tbodySelectedRef.current &&
        theadRef.current &&
        containerRef.current &&
        notSelectedBodyRef.current
      ) {
        const containerHeight = containerRef.current.clientHeight;
        const headerHeight = theadRef.current.clientHeight;
        const selectedBodyHeight = tbodySelectedRef.current.clientHeight;
        notSelectedBodyRef.current.style.maxHeight = `${
          containerHeight - headerHeight - selectedBodyHeight - 8
        }px`;
      }
    };

    fixMaxHeight();
    fixHeightUnselectedBodyHeight();
    window.addEventListener('resize', fixMaxHeight);
    window.addEventListener('resize', fixHeightUnselectedBodyHeight);

    return () => {
      window.removeEventListener('resize', fixMaxHeight);
      window.removeEventListener('resize', fixHeightUnselectedBodyHeight);
    };
  }, [unselectedData, selectedData, data]);

  useEffect(() => {
    if (data.length && !separated) {
      setRowData({
        selected: data.filter((item) => selectedRows && selectedRows(item)),
        unselected: data.filter(
          (item) => !(selectedRows && selectedRows(item))
        ),
      });
    }
  }, [data]);

  useEffect(() => {
    if (theadRef.current) {
      setSelectedTop(theadRef.current.clientHeight);
    }
  }, []);

  return (
    <div ref={containerRef} className="h-full">
      <table className="w-full my-table">
        <thead ref={theadRef} id="thead" className="sticky top-0 py-1">
          <tr>
            {((isArray(columns) && columns) || []).map(
              ({ key, sortable, label }, index) => (
                <th
                  key={index}
                  onClick={() =>
                    sortable &&
                    !loading &&
                    handleClickThSortable(
                      key,
                      sorting?.key === key ? sorting?.value || null : null
                    )
                  }
                  className={classNames(
                    {
                      'hover:bg-gray-300 cursor-pointer': sortable,
                      'bg-gray-300': sortable && sorting && sorting.key === key,
                    },
                    classNameTh,
                    ' bg-white'
                  )}
                >
                  <div className="flex items-center th-content">
                    <span>{label || ''}</span>
                    {sortable && sorting && sorting.key === key ? (
                      sorting.value === 'desc' ? (
                        <span className="ml-3">
                          <ArrowNarrowUpIcon className="h-5 w-5" />
                        </span>
                      ) : (
                        <span className="ml-3">
                          <ArrowNarrowDownIcon className="h-5 w-5" />
                        </span>
                      )
                    ) : sortable ? (
                      <span className="ml-3">
                        <SwitchVerticalIcon className="h-5 w-5" />
                      </span>
                    ) : null}
                  </div>
                </th>
              )
            )}
          </tr>
        </thead>
        {loading ? (
          <div className="font-semibold py-8 text-center">
            Chargement en cours...
          </div>
        ) : (
          <>
            <tbody
              style={{
                maxHeight:
                  rowData.unselected.length === 0
                    ? '100%'
                    : maxHeightTbody || '100%',
                top: `${selectedTop}px`,
              }}
              className="w-full sticky"
              ref={tbodySelectedRef}
            >
              {rowData.selected.map((item, index) => (
                <tr
                  key={index}
                  id={(idKeyname && `tr_${item[idKeyname]}`) || ''}
                  onClick={() => onRowClick && onRowClick(item)}
                  className={classNames(
                    'cursor-pointer',
                    `${classnameActive ? classnameActive : 'bg-blue-200'}`
                  )}
                >
                  {((isArray(columns) && columns) || []).map(
                    ({ key, render }, i) => (
                      <td className={classNames(classNameTd)} key={i}>
                        {render ? render(item) : item[key]}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
            <tbody
              style={{ maxHeight: '400px' }}
              className={classNames('overflow-y-auto w-full', {
                'mt-2 border-1 border-gray-500': !!rowData?.unselected?.length,
              })}
              ref={notSelectedBodyRef}
            >
              {rowData.unselected.map((item, index) => (
                <tr
                  key={index}
                  id={(idKeyname && `tr_${item[idKeyname]}`) || ''}
                  onClick={() => onRowClick && onRowClick(item)}
                  className={classNames('cursor-pointer hover:bg-blue-200', {
                    'bg-blue-400 bg-opacity-50':
                      ((idKeyname && `tr_${item[idKeyname]}`) || '') ==
                      `tr_${clickedTr}`,
                  })}
                >
                  {((isArray(columns) && columns) || []).map(
                    ({ key, render }, i) => (
                      <td className={classNames(classNameTd)} key={i}>
                        {render ? render(item) : item[key]}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
}

const Thead = ({
  widths = [],
  myRef,
  className,
  onClickSortable,
  columns,
  sorting,
  myStyle,
  withBgTh = false,
}) => {
  return (
    <thead style={myStyle} ref={myRef} className={classNames(className)}>
      <tr>
        {((isArray(columns) && columns) || []).map(
          ({ key, sortable, label }, index) => {
            const width = widths[index];

            return (
              <th
                style={{ width: width || 'auto' }}
                key={index}
                onClick={() =>
                  sortable &&
                  onClickSortable(
                    key,
                    sorting?.key === key ? sorting?.value || null : null
                  )
                }
                className={classNames('text-xs px-2', {
                  'hover:bg-gray-300 cursor-pointer': sortable && !withBgTh,
                  'bg-gray-300':
                    sortable && sorting && sorting.key === key && !withBgTh,
                  'bg-white': !withBgTh,
                  'bg-gray-300 hover:bg-gray-400': withBgTh,
                  'bg-gray-400':
                    sortable && sorting && sorting.key === key && withBgTh,
                })}
              >
                <div className="flex items-center th-content">
                  <span>{label || ''}</span>
                  {sortable && sorting && sorting.key === key ? (
                    sorting.value === 'desc' ? (
                      <span className="ml-3">
                        <ArrowNarrowUpIcon className="h-5 w-5" />
                      </span>
                    ) : (
                      <span className="ml-3">
                        <ArrowNarrowDownIcon className="h-5 w-5" />
                      </span>
                    )
                  ) : sortable ? (
                    <span className="ml-3">
                      <SwitchVerticalIcon className="h-5 w-5" />
                    </span>
                  ) : null}
                </div>
              </th>
            );
          }
        )}
      </tr>
    </thead>
  );
};

export function TableV2({
  classnameActive,
  columns,
  data = [],
  onRowClick,
  selectedRows,
  onChangeSorting,
  sorting,
  classNameTd,
  selectedData = [],
  unselectedData = [],
  idKeyname,
  maxSelectedOnTop = 3,
  clickedTr,
  separated = false,
  simpleTable = false,
  onHover = () => {},
  onLeave = () => {},
  withBgTh = false,
}) {
  // const [topHeight, setTopHeight] = useState(0);
  const handleClickThSortable = (key, value) => {
    onChangeSorting &&
      onChangeSorting({
        key,
        value: !value ? 'desc' : value === 'desc' ? 'asc' : 'desc',
      });
  };
  const theadRefSelected = useRef(null);
  const tableNotSelectedRef = useRef(null);
  const containerRef = useRef(null);
  const [maxHeightTbody, setMaxHeightTbody] = useState(0);
  const notSelectedBodyRef = useRef(null);
  const tbodySelectedRef = useRef(null);
  const [rowData, setRowData] = useState({ selected: [], unselected: [] });
  const [theadHeight, setTheadHeight] = useState(0);
  const containerNoSelectRef = useRef(null);
  const [selectedHeight, setSelectheight] = useState(0);
  const [thsWidth, setThsWidth] = useState([]);
  const tab1 = useRef(null);

  useEffect(() => {
    if (separated && !simpleTable) {
      setRowData({ selected: selectedData, unselected: unselectedData });
    }
  }, [unselectedData, selectedData, simpleTable]);

  useEffect(() => {
    const exec = () => {
      if (!simpleTable) {
        const widths = columns.map(({ key }) => {
          const els = document.getElementsByClassName(key);
          if (els.length) {
            return els[0].clientWidth;
          }
          return 0;
        });
        setThsWidth(widths);
        tab1.current.style.width = `${tableNotSelectedRef.current.clientWidth}px`;
      }
    };

    if (columns?.length && rowData?.unselected?.length) {
      setTimeout(() => {
        exec();
      }, 1000);
    }

    window.addEventListener('resize', exec);
    return () => {
      window.removeEventListener('resize', exec);
    };
  }, [rowData?.unselected, simpleTable]);

  useEffect(() => {
    const exec = () => {
      if (!simpleTable) {
        let maxHeightSelectedBody =
          containerNoSelectRef?.current?.clientHeight || 0;

        if (
          tbodySelectedRef.current &&
          tbodySelectedRef.current.children.length >= maxSelectedOnTop
        ) {
          let maxHeight = 0;
          for (let i = 0; i < maxSelectedOnTop; i++) {
            const el = tbodySelectedRef.current.children[i];
            maxHeight = maxHeight + el.clientHeight;
          }
          setMaxHeightTbody(maxHeight);
          maxHeightSelectedBody = maxHeight;
        }

        if (
          theadRefSelected.current &&
          tableNotSelectedRef.current &&
          containerRef.current
        ) {
          const containerHeight = containerRef.current.clientHeight;
          const heightThead = theadRefSelected.current.clientHeight;

          tableNotSelectedRef.current.style.marginTop = `-${heightThead}px`;
          setTheadHeight(heightThead);

          setSelectheight(
            containerHeight -
              maxHeightSelectedBody -
              (tbodySelectedRef.current.children.length >= maxSelectedOnTop
                ? heightThead
                : 0) -
              4
          );
        }
      }
    };

    exec();

    window.addEventListener('resize', exec);
    return () => {
      window.removeEventListener('resize', exec);
    };
  }, [unselectedData, selectedData, data, simpleTable]);

  useLayoutEffect(() => {
    if (!simpleTable) {
      if (data.length && !separated) {
        setRowData({
          selected: data.filter((item) => selectedRows && selectedRows(item)),
          unselected: data.filter(
            (item) => !(selectedRows && selectedRows(item))
          ),
        });
      }
    }
  }, [data, simpleTable]);

  return (
    <div ref={containerRef} className="h-full w-full">
      <div
        ref={containerNoSelectRef}
        style={{
          maxHeight:
            rowData.unselected.length === 0
              ? '100%'
              : maxHeightTbody
              ? maxHeightTbody + theadHeight
              : '100%',
        }}
        className={classNames({
          'overflow-y-scroll': rowData?.unselected?.length,
        })}
      >
        <table ref={tab1} className="w-full">
          <Thead
            widths={thsWidth}
            className="sticky z-10 top-0"
            columns={columns}
            classNameTh={classNameTd}
            myRef={theadRefSelected}
            onClickSortable={handleClickThSortable}
            sorting={sorting}
            withBgTh={withBgTh}
          />
          <tbody
            className={classNames('w-full relative z-0', {
              'border-1 border-t-0 border-gray-300': withBgTh,
            })}
            ref={tbodySelectedRef}
          >
            {(simpleTable ? data : rowData.selected).map((item, index) => {
              const isOdd = index % 2;
              return (
                <tr
                  onMouseOver={() => onHover(item[idKeyname])}
                  onMouseOut={() => {
                    !simpleTable && onHover(null);
                    onLeave(item[idKeyname]);
                  }}
                  key={index}
                  id={(idKeyname && `tr_${item[idKeyname]}`) || ''}
                  onClick={() => onRowClick && onRowClick(item)}
                  className={classNames(
                    `${classnameActive ? classnameActive : ''}`,
                    'cursor-pointer',
                    {
                      'hover:bg-blue-200': simpleTable,
                      'bg-gray-300': isOdd && simpleTable,
                      'bg-blue-200':
                        clickedTr === item[idKeyname] && simpleTable,
                    }
                  )}
                >
                  {((isArray(columns) && columns) || []).map(
                    ({ key, render }) => (
                      <td className={classNames(classNameTd)} key={key}>
                        {render ? render(item) : item[key]}
                      </td>
                    )
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!simpleTable && (
        <div
          style={{ maxHeight: selectedHeight - 20 }}
          className="overflow-y-auto mt-2"
        >
          <table ref={tableNotSelectedRef} className="w-full">
            <Thead
              className="opacity-0"
              columns={columns}
              classNameTh={classNameTd}
              onClickSortable={handleClickThSortable}
              sorting={sorting}
            />
            <tbody
              className={classNames({
                'mt-2 border-1 border-gray-500': !!rowData?.unselected?.length,
              })}
              ref={notSelectedBodyRef}
            >
              {rowData.unselected.map((item, index) => (
                <tr
                  onMouseEnter={() => {
                    onHover(null);
                    setTimeout(() => {
                      onHover(item.tran_id);
                    }, 100);
                  }}
                  onMouseLeave={() => onHover(null)}
                  key={index}
                  id={(idKeyname && `tr_${item[idKeyname]}`) || ''}
                  onClick={() => onRowClick && onRowClick(item)}
                  className={classNames('cursor-pointer hover:bg-blue-200', {
                    'bg-blue-400 bg-opacity-50':
                      ((idKeyname && `tr_${item[idKeyname]}`) || '') ==
                      `tr_${clickedTr}`,
                  })}
                >
                  {((isArray(columns) && columns) || []).map(
                    ({ key, render }) => (
                      <td key={key} className={classNames(classNameTd, key)}>
                        {render ? render(item) : item[key]}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
