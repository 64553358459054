import React from 'react';
import { useSelector } from 'react-redux';
import TableData from './TableData';
import { MsBoxNoLoadData } from './MsBox';
import uniqid from 'uniqid';

const LogementSocial = ({ theme, isPdfRenderMode }) => {
  const { logementSocialData } = useSelector((state) => state.marketSurvey);

  return (
    <div className="w-full h-full mt-4">
      <div
        className={`w-full lg:w-1/2 grid grid-cols-${logementSocialData?.boxes?.length} gap-4`}
      >
        {logementSocialData?.boxes &&
          logementSocialData.boxes.map((item) => (
            <MsBoxNoLoadData
              isPdfRenderMode={isPdfRenderMode}
              key={uniqid()}
              data={item}
            />
          ))}
      </div>
      <div className="w-full mt-4 lg:mt-6 mb-6">
        {logementSocialData?.tableData && (
          <TableData
            theme={theme}
            data={logementSocialData.tableData}
            isPdfRenderMode={isPdfRenderMode}
          />
        )}
      </div>
    </div>
  );
};

export default LogementSocial;
