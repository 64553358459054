import React, { useEffect, useMemo, useState } from 'react';
import Table from '@components/molecules/Table';
import Button from '@components/Button';
import ProjectCard from '@components/projectCard';
import { Link } from 'react-router-dom';
import PexelsLukeWeb from '../../../../assets/images/pexels_lukeweb.jpeg';
import useSWR from 'swr';
import Api from '../../api/Api';
// import { useAuth } from '../../hooks/useAuth';
import { pluralize } from '../../_helpers/wording';
import { useModal } from '../../hooks/useModal';
import OnBoardingModal from '../../components/modals/OnBoardingModal';
import CookieModal from '../../components/modals/CookieModal';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { othersSliceActions } from '../../store/slices/others';

const fetcher = (url) => Api.get(url).then((res) => res.data);

export const orderProjects = (arrayData) => {
  return arrayData.map((item) => ({
    ...item,
    ratings: {
      data: orderBy(
        (item?.ratings?.data || []).map((el) => ({
          attributes: {
            ...el.attributes,
            name_etude: item?.ratings?.included?.find(
              (inclu) =>
                inclu.id === el.relationships.market_survey?.data?.id &&
                inclu.type === 'market_survey'
            )?.attributes?.name,
          },
          id: parseInt(el.id),
        })),
        ['id'],
        ['desc']
      ),
    },
  }));
};

const Rating = () => {
  const { setIsCookieModalOpen } = useModal();
  const { canActivateTrial } = useSelector((state) => state.others);
  const [showNoQC, setShowNoQC] = useState(false);
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('openCookieModal')) {
      setIsCookieModalOpen(false);
      // setRedirect(true);
    } else {
      setIsCookieModalOpen(true);
    }
  }, []);

  useEffect(() => {
    const fix = () => {
      const bodyEl = document.getElementById('ratings');
      const navbar = document.getElementById('navbar');
      const bottomNavBar = document.getElementById('bottom-navbar');
      if (bodyEl && navbar && bottomNavBar) {
        const hHeader = navbar.clientHeight + bottomNavBar.clientHeight;
        setOffset(hHeader);
        const heightBody = window.innerHeight - hHeader;
        bodyEl.style.minHeight = `${heightBody}px`;
      }
    };

    dispatch(othersSliceActions.setMaxErrorNb(1));

    if (window.innerWidth <= 768) {
      setTimeout(() => {
        fix();
      }, 500);
    } else {
      fix();
    }
    window.addEventListener('resize', fix);
    return () => {
      window.addEventListener('resize', fix);
    };
  }, []);

  const { data: ratingsGroupByDateDataFromApi } = useSWR(
    `${process.env.BASE_URL}/ratings/show_last_five.json`,
    fetcher
  );

  const ratingsGroupByDateData = useMemo(
    () =>
      ratingsGroupByDateDataFromApi &&
      orderProjects(ratingsGroupByDateDataFromApi),
    [ratingsGroupByDateDataFromApi]
  );

  const { data: ratingStats } = useSWR(
    `${process.env.BASE_URL}/ratings/stats.json`,
    fetcher
  );

  const { data: lastFiveProjectsDataFromApi } = useSWR(
    '/ratings/show_last_five_projects',
    fetcher
  );

  const lastFiveProjectsData = useMemo(
    () =>
      lastFiveProjectsDataFromApi && orderProjects(lastFiveProjectsDataFromApi),
    [lastFiveProjectsDataFromApi]
  );

  useEffect(() => {
    if (ratingsGroupByDateData && lastFiveProjectsData) {
      setShowNoQC(
        ratingsGroupByDateData?.length === 0 && lastFiveProjectsData?.length
      );
    }
  }, [ratingsGroupByDateData, lastFiveProjectsData]);

  return (
    <>
      {!lastFiveProjectsData && (
        <div className="card">
          <div className="flex items-center justify-between">
            <h3 className="font-extrabold">Dernière étude de marché</h3>
          </div>
          <div>Vos projets sont en cours de chargement...</div>
        </div>
      )}
      <OnBoardingModal />
      <CookieModal />
      <div
        id="ratings"
        className={classNames('bg-blue-primary pb-8')}
        style={{ marginTop: `${offset}px` }}
      >
        <div className="max-w-6xl mx-auto pt-4">
          <div className="w-full grid md:grid-cols-11 col-span-12 gap-6">
            <div className="mx-8 md:mx-0 grid col-span-12 grid-cols-2 md:col-span-3 gap-6 min-content">
              <div
                style={{
                  height: '180px',
                  background: `url(${PexelsLukeWeb})`,
                  backgroundSize: 'cover',
                }}
                className="grid relative col-span-2 items-center text-white shadow-lg rounded-xl p-4"
              >
                <div className="z-10 animate-bounce">
                  <Button to={'/ratings/new'} title="Nouvelle recherche" />
                </div>
              </div>

              {/* Bien analyser une situation ancienne carte */}
              <div className="grid col-span-2 bg-white shadow-lg rounded-xl disabled:opacity-75">
                <div className="content p-4">
                  <p className="font-bold">Bienvenue !</p>
                  <div className="text-sm text-gray-800">
                    <span className="font-bold">Recherchez</span> votre terrain
                    et étudiez son potentiel en 2 étapes :
                    <ul className={'list-disc pl-3'}>
                      <li>
                        <span className="font-bold">"Quick Check"</span> : en un
                        clic retrouvez une synthèse des informations pour
                        décider
                      </li>
                      <li>
                        <span className="font-bold">"Etude de marché"</span> :
                        réalisez une étude personnalisée du terrain pour
                        positionner votre opération dans son environnement de
                        marché - Votre étude prête pour le CE
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-lg rounded-xl p-6 col-span-2 min-content">
                <h2 className="font-extrabold text-blue-500">
                  {ratingStats?.request_count}
                </h2>
                <p className="font-bold mb-0">Études et Quick Checks</p>
                <span className="text-sm text-gray-900">
                  Dont {ratingStats?.uniq_commune_count} sur les 4 dernières
                  semaines
                </span>
              </div>
            </div>
            <div
              className="mx-8 md:mx-0 grid grid-cols-2 col-span-12 md:col-span-8 gap-6 "
              style={{ height: 'min-content' }}
            >
              <div className="col-span-2">
                <div className="col-span-2 bg-white rounded-lg">
                  <div className="p-4">
                    {ratingsGroupByDateData?.length === 0 &&
                    lastFiveProjectsData?.length === 0 ? (
                      <ProjectCard
                        // data={data}
                        title="Dernières évaluations"
                        text="Vous retrouverez ici un accès direct à vos dernières évaluations en fonction de leur état d'avancement."
                        button
                        to={'/ratings/list'}
                        buttonTo={'/ratings/new'}
                        buttonTitle="Lancez votre première recherche"
                      />
                    ) : (
                      <>
                        <div className="flex flex-col">
                          <div className="flex justify-between items-center">
                            <h3>
                              {pluralize(
                                ratingsGroupByDateData?.length,
                                'Derniers Quick Checks',
                                'Derniers Quick Checks'
                              )}
                            </h3>
                            <Link
                              className="text-blue-600 font-normal text-sm"
                              to="/ratings/list"
                            >
                              Voir l'historique de recherche des terrains
                            </Link>
                          </div>
                          {showNoQC && (
                            <p className="mt-6 text-sm">
                              Tous vos Quick Checks sont passés au statut
                              d'étude de marché.
                            </p>
                          )}
                        </div>
                        {ratingsGroupByDateData &&
                          ratingsGroupByDateData.map(
                            (ratingsGroupByDate, index) => {
                              return (
                                <div
                                  key={`__grouped_by_date-${index}`}
                                  className="mt-4 mb-4 border-gray-600 rounded-sm p-2 shadow-sm"
                                >
                                  <span className="text-sm">
                                    {ratingsGroupByDate.date}
                                  </span>
                                  <span className="ml-4 font-extrabold">
                                    {ratingsGroupByDate.ratings.data.length +
                                      ' ' +
                                      pluralize(
                                        ratingsGroupByDate.ratings.data.length,
                                        'Terrain',
                                        'Terrains'
                                      )}
                                  </span>
                                  <Table
                                    key={`last__rating__${index}`}
                                    byDate
                                    type="ratings"
                                    data={ratingsGroupByDate.ratings.data.map(
                                      (rating) => rating.attributes
                                    )}
                                  />
                                </div>
                              );
                            }
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {canActivateTrial && (
                <div class="col-span-2">
                  <div class="grid grid-cols-2 bg-white rounded-lg p-6">
                    <div>
                      <p>
                        Envie de tester les Études de Marché ? Nous vous offrons
                        30 jours d'essai avec le code CECIM24
                      </p>
                    </div>
                    <div class="flex justify-end items-center">
                      <a
                        href="/activate-trial?code=CECIM24"
                        class="gradient-button text-white bg-blue-500 hover:bg-blue-700 py-2 px-4 rounded-md"
                      >
                        Activez ici votre période d'essai
                      </a>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-span-2">
                <div className="col-span-2 bg-white rounded-lg">
                  <div className="p-4">
                    {lastFiveProjectsData?.length === 0 ? (
                      <ProjectCard
                        // data={data}
                        title={pluralize(
                          lastFiveProjectsData.length,
                          'Dernière étude de marché',
                          'Dernières études de marché'
                        )}
                        text="Vous retrouverez ici un accès direct à vos dernières évaluations en fonction de leur état d'avancement."
                      />
                    ) : (
                      <>
                        <div className="flex justify-between items-center">
                          <h3>
                            {pluralize(
                              lastFiveProjectsData?.length,
                              'Dernière étude de marché',
                              'Dernières études de marché'
                            )}
                          </h3>
                          <Link
                            className="text-blue-600 font-normal text-sm"
                            to="/ratings/list"
                          >
                            Voir l'historique de recherche des terrains
                          </Link>
                        </div>
                        {lastFiveProjectsData &&
                          lastFiveProjectsData.map(
                            (ratingsGroupByDate, index) => {
                              return (
                                <div
                                  key={`__grouped_by_date-${index}`}
                                  className="mt-4 mb-4 border-gray-600 rounded-sm p-2 shadow-sm"
                                >
                                  <span className="text-sm">
                                    {ratingsGroupByDate.date}
                                  </span>
                                  <span className="ml-4 font-extrabold">
                                    {ratingsGroupByDate.ratings.data.length +
                                      ' ' +
                                      pluralize(
                                        ratingsGroupByDate.ratings.data.length,
                                        'Terrain',
                                        'Terrains'
                                      )}
                                  </span>
                                  <Table
                                    key={`last__rating__${index}`}
                                    byDate
                                    type="ratings"
                                    data={ratingsGroupByDate?.ratings?.data.map(
                                      (rating) => rating.attributes
                                    )}
                                  />
                                </div>
                              );
                            }
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Rating;
