import { deserializeAttributesForMSGiven } from '../../../_helpers/jsonapi-deserializer';
import React, { useEffect, useMemo, useState } from 'react';
import Icon from '../../../../../assets/images/icon-white.svg';
import {
  BLUE_THEME,
  DARK_BLUE_THEME,
  GRAY_THEME,
  YELLOW_THEME,
} from '../../../_helpers/constant';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const BannerSection = ({
  isPdfRenderMode = false,
  theme = YELLOW_THEME,
  title,
}) => {
  const { marketSurvey } = useSelector((state) => state.programme);
  const [offset, setOffset] = useState(0);
  const data = useMemo(
    () => marketSurvey && deserializeAttributesForMSGiven(marketSurvey),
    [marketSurvey]
  );

  useEffect(() => {
    const exec = () => {
      setOffset(
        document.getElementById('navbar')?.offsetHeight +
          document.getElementById('bottom-navbar')?.offsetHeight +
          document.getElementById('ms-page-header')?.offsetHeight
      );
    };
    exec();
    window.addEventListener('resize', exec);
    return () => {
      window.removeEventListener('resize', exec);
    };
  }, []);

  return (
    <div
      id={'first_section_banner'}
      className={classNames(
        'w-full sticky first_section_banner flex flex-row justify-between items-center section-banner',
        {
          'bg-green-700': theme === YELLOW_THEME,
          'bg-blue-500': theme === BLUE_THEME,
          'bg-blue-900': theme === DARK_BLUE_THEME,
          'bg-gray-600': theme === GRAY_THEME,
        },

        { 'p-2 px-8': isPdfRenderMode },
        { 'px-2 2xl:px-8': !isPdfRenderMode }
      )}
      style={{
        zIndex: 10000,
        top: offset || '',
        height: window.innerWidth >= 1536 || isPdfRenderMode ? '48px' : '28px',
      }}
    >
      <div
        className={classNames(
          'text-yellow-100',
          { 'text-base': isPdfRenderMode },
          { 'text-xs 2xl:text-base': !isPdfRenderMode }
        )}
      >
        {title}
      </div>
      {data?.related_rating && (
        <div
          className={classNames(
            {
              'text-white':
                theme === YELLOW_THEME || BLUE_THEME || DARK_BLUE_THEME,
              'text-gray-100': theme === GRAY_THEME,
            },
            'flex flex-row items-center'
          )}
        >
          <span
            className={classNames(
              { 'text-base': isPdfRenderMode },
              { 'text-tiny 2xl:text-base': !isPdfRenderMode }
            )}
          >
            {data.related_rating.address} {data.related_rating.postcode}{' '}
            {data.related_rating.town}
          </span>

          <img
            src={Icon}
            className={'w-4 h-4 2xl:w-8 2xl:h-8 ml-4'}
            alt={'evalparcel ico'}
          />
        </div>
      )}
    </div>
  );
};

export default BannerSection;
