import React from 'react';
import HomeLogo from '../../../assets/images/home.png';
import Table from '@components/molecules/Table';
import Button from '@components/Button';
import { Link } from 'react-router-dom';

const ProjectCard = ({
  button,
  title,
  buttonTitle,
  data,
  to,
  subtitle,
  buttonTo,
  text,
}) => {
  let fakeData = false;
  return (
    <div className="card">
      <div className="flex items-center justify-between">
        <h3 className="font-extrabold">{title}</h3>
        <span className="text-xs font-extrabold text-blue-500">
          {to && (
            <Link to={to}>
              {subtitle ? subtitle : 'Voir toutes les évaluations'}
            </Link>
          )}
        </span>
      </div>
      {!fakeData ? (
        <>
          <div className="flex items-center p-3">
            <img src={HomeLogo} width={90} alt={'evalparcel logo'} />
            <p className="text-sm text-gray-800">
              {text
                ? text
                : 'Après en avoir créé, vous retrouverez ici vos 5 derniers ciblages.'}
            </p>
          </div>
          {button && (
            <div className="w-2/4">
              <Button to={buttonTo} title={buttonTitle} />
            </div>
          )}
        </>
      ) : (
        <Table data={data} />
      )}
    </div>
  );
};

export default ProjectCard;
