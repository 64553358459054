import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

export default function LitleSelect({
  options = [],
  keyValue,
  keyLabel,
  value,
  onChange,
  name,
}) {
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const listContainerRef = useRef(null);

  const handleToogle = () => {
    setOpen((c) => !c);
  };

  const handleClickInItem = (value) => {
    const currentValue = options.find((item) => item[keyValue] === value);
    onChange &&
      onChange({
        target: {
          name,
          value: currentValue && currentValue[keyValue],
        },
      });
  };

  useEffect(() => {
    if (open) {
      if (containerRef.current && listContainerRef.current) {
        listContainerRef.current.style.top = `${
          containerRef.current.clientHeight + 2
        }px`;
      }
    }
  }, [open]);

  return (
    <div
      onClick={handleToogle}
      ref={listContainerRef}
      className="relative w-auto inline-block"
    >
      <div className="flex items-center bg-white border-1 border-gray-300 rounded-md py-1 px-1">
        <span className="text-xs whitespace-nowrap">
          {value && value[keyLabel]}
        </span>
        <span>
          {open ? (
            <ChevronUpIcon className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" />
          )}
        </span>
      </div>
      <div
        ref={listContainerRef}
        className={classNames(
          'w-auto left-0 absolute z-50 bg-white border-1 border-gray-500 shadow-lg rounded-md overflow-hidden',
          {
            block: open,
            hidden: !open,
          }
        )}
      >
        {options.map((item, key) => (
          <div
            key={key}
            onClick={() => handleClickInItem(item[keyValue])}
            className={classNames(
              'text-xs12 cursor-pointer hover:bg-blue-500 hover:text-white  whitespace-nowrap px-3 py-1 border-gray-500',
              { 'border-b-1': key < options.length - 1 },
              {
                'bg-blue-500 text-white':
                  value && item[keyValue] === value[keyValue],
              }
            )}
          >
            {item[keyLabel]}
          </div>
        ))}
      </div>
    </div>
  );
}
