const onClosePopup = (callback) => {
  const fnCallback = (e) => {
    e.preventDefault();
    callback();
  };
  setTimeout(() => {
    const els = document.getElementsByClassName('leaflet-popup-close-button');
    els[0].addEventListener('click', fnCallback);
  }, 500);
};

export default onClosePopup;
