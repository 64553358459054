import React from "react";

const ConceptNoteSectionBody = ({points}) => {
    return <>
        <ul className={'list-disc m-0'}>
            {points.map((point, index) => {
                return (<li key={`point___${index}`} className={'m-0'}>
                    {point}
                </li>)
            })}
        </ul>

    </>


}


export default ConceptNoteSectionBody