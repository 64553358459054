import Api from '@/api/Api';
import { othersSliceActions } from '../slices/others';

export const getBgLogin = () => async (dispatch) => {
  try {
    const {
      data: { asset },
    } = await Api.get('/v2/organizations/ip_proxy/welcome_image');
    dispatch(othersSliceActions.setBgLogin(asset));
  } catch (e) {
    console.log(e);
    dispatch(othersSliceActions.setBgLogin(''));
  }
};
