import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router';

export const useRedirectUserHasntAccessAllStudies = ({ isShare = false }) => {
  const { hasAccessAllStudies, capemUser } = useSelector(
    (state) => state.others
  );
  const navigation = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isShare) {
      const needAccessRoutes = ['market-survey', 'ciblage'];
      const ifPathNeedsAccess = !!needAccessRoutes.filter(
        (str) => pathname.indexOf(str) >= 0
      ).length;
      if (!hasAccessAllStudies && !capemUser && ifPathNeedsAccess) {
        navigation.push('/demande-rendez-vous');
      }
    }
  }, [hasAccessAllStudies, pathname, isShare]);
};

export function PrivateRoute({ children, ...rest }) {
  let { sessionAuthHeader, checkSession } = useAuth();

  useRedirectUserHasntAccessAllStudies({ isShare: rest?.isShare });

  useEffect(() => {
    checkSession({ isShare: rest?.isShare });
  }, [sessionAuthHeader, rest?.isShare]);

  return (
    <>
      {rest?.isShare ? (
        <Route {...rest} render={() => children} />
      ) : (
        <Route
          {...rest}
          render={({ location }) =>
            sessionAuthHeader?.token ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/users/sign_in',
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
}
