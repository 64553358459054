import { useMemo } from 'react';

const useCenterByMetadata = ({
  metadata,
  isPdfMode = false,
  map,
  name,
  onReady,
}) => {
  const centerByMetadata = useMemo(() => {
    if (metadata && isPdfMode) {
      const mapConfigData =
        metadata?.maps_metadata && metadata?.maps_metadata[name];
      if (mapConfigData) {
        map.setView(mapConfigData.center, mapConfigData.zoom);
        onReady && onReady();
        return true;
      }
      return false;
    }
    return false;
  }, [metadata, map, name, isPdfMode]);

  return centerByMetadata;
};

export default useCenterByMetadata;
