import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import _, { debounce, isEmpty, uniqueId } from 'lodash';
import axios from 'axios';
import Api from '../../../../api/Api';
import { useSelectedParcels } from '../../../../hooks/useSelectedParcels';
import EpciName from '../../../utils/EpciName';
import L from 'leaflet';
import CurrentField from '../../../../../../assets/images/icons/terrain.svg';
import { eventBus } from '../../../../_services/event-bus';
import swal from 'sweetalert';
import styled from 'styled-components';

const StyledPop = styled(Popup)`
  .leaflet-popup-content-wrapper {
    border-radius: 16px;
  }
`;

let CurrentFieldIcon = new L.icon({
  iconUrl: CurrentField,
  iconSize: [35, 42],
  iconAnchor: [17, 45],
  popupAnchor: [0, -40],
});

const FieldBuildingToolLayer = forwardRef(
  (
    {
      setSelectedAddress,
      nearestParcels,
      setNearestParcels,
      addressCoordinates,
      setSearchedAddress,
      setCityCode,
      onClickOnMap,
    },
    ref
  ) => {
    const { selectedParcelsIds, setSelectedParcelsIds } = useSelectedParcels();

    const [coordinates, setCoordinates] = useState(null);
    const [clickDisabled, setClickDisabled] = useState(false);

    const [labelAddress, setLabelAddress] = useState(null);
    const [infoDisplayed, setInfoDisplayed] = useState(false);

    let markerRef = useRef();

    const resetConfirm = (e) => {
      swal(
        "Vous avez selectionné une parcelle, vous devez supprimer les parcelles selectionnées pour commencer la constitution d'un autre terrain",
        {
          buttons: {
            cancel: 'Annuler',
            reset: {
              text: 'Supprimer les parcelles',
              value: 'reset',
            },
          },
          icon: 'warning',
          dangerMode: true,
        }
      ).then((value) => {
        if (value === 'reset') {
          reset();
          e && handleClickOnMap(e);
        }
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        resetReach: () => {
          resetConfirm();
        },
      }),
      []
    );

    const map = useMapEvents({
      click: (e) => {
        if (isEmpty(selectedParcelsIds)) {
          reset();
          handleClickOnMap(e);
        }
        if (markerRef && map._layers[markerRef._leaflet_id]) {
          resetConfirm(e);
        }
        return false;
      },
    });

    if (!map) return null;

    useEffect(() => {
      eventBus.on('newRatingClearSearch', handleReset);

      return () => {
        eventBus.remove('newRatingClearSearch');
      };
    });

    useEffect(() => {
      if (!isEmpty(nearestParcels)) {
        setClickDisabled(true);
      }
    }, [nearestParcels]);

    const handleBuild = () => {
      if (markerRef) {
        markerRef.addTo(map);
      }
      setInfoDisplayed(false);
      if (coordinates) {
        // get info  address
        axios
          .get(
            `https://api-adresse.data.gouv.fr/reverse/?lon=${coordinates.lng}&lat=${coordinates.lat}`
          )
          .then((res) => {
            setCityCode(
              res.data.features[0]
                ? res.data.features[0].properties.citycode
                : null
            );
            if (res.data.features[0]) {
              const { name, postcode, city, label, street } =
                res.data.features[0].properties;
              setLabelAddress(res.data.features[0].properties.label);
              setSearchedAddress(res.data.features[0].properties.label);
              setSelectedAddress({
                address: name,
                postcode: postcode,
                evaluate_date: new Date(),
                town: city,
                parcel_size: 0,
                plu: '',
                name,
                label,
                isStreet: !!street || false,
                city,
              });
            } else {
              setLabelAddress('adresse inconnue');
              setSearchedAddress(null);
              setSelectedAddress(null);
            }

            setInfoDisplayed(true);
          })
          .catch((e) => {
            console.log(e);
          });

        // get parcels
        Api.get(
          `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/by_lon_lat_multiple_selection?lat=${coordinates.lat}&lon=${coordinates.lng}`
        )
          .then(({ data }) => {
            setNearestParcels(data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };

    useEffect(() => {
      if (coordinates) {
        handleBuild();
      }
    }, [coordinates]);

    const handleClickOnMap = useCallback(
      debounce((event) => {
        if (!clickDisabled && !coordinates) {
          setCoordinates(event.latlng);
          onClickOnMap && onClickOnMap(event);
        }
        setClickDisabled(true);
      }, 300),
      []
    );

    const handleReset = () => {
      if (markerRef && map._layers[markerRef._leaflet_id]) {
        if (!isEmpty(selectedParcelsIds)) {
          resetConfirm();
        } else {
          reset();
        }
      }
    };

    const reset = () => {
      try {
        setClickDisabled(false);

        if (!!markerRef) {
          if (map._layers[markerRef._leaflet_id]) {
            map._layers[markerRef._leaflet_id].remove();
          }
          // closePopup(markerRef)
        }

        setSelectedAddress(null);
        setLabelAddress(null);
        setInfoDisplayed(false);
        setNearestParcels(null);
        setSelectedParcelsIds([]);
        setSearchedAddress('');
      } catch (e) {
        throw new Error(e);
      }
    };

    useEffect(() => {
      if (addressCoordinates) {
        setCoordinates(addressCoordinates);
      }
    }, [addressCoordinates]);

    useEffect(() => {
      map.on('popupopen', () => {
        setTimeout(() => {
          map.closePopup();
        }, 2000);
      });
    }, []);

    return (
      <>
        {coordinates && (
          <Marker
            ref={(r) => {
              markerRef = r;
            }}
            eventHandlers={{
              click: (e) => {
                e.target.openPopup();
              },
            }}
            position={coordinates}
            offset={[0, 0]}
            icon={CurrentFieldIcon}
          >
            {labelAddress && infoDisplayed && (
              <StyledPop direction="bottom" opacity={1}>
                <div>
                  <div>
                    <p className={'font-semibold'}>{labelAddress}</p>
                    {!isEmpty(selectedParcelsIds) && (
                      <div>
                        <EpciName pivot_parcelle_id={selectedParcelsIds[0]} />
                        <span className={'text-gray-700 whitespace-nowrap'}>
                          {selectedParcelsIds.length > 1
                            ? 'Le terrain est composé des parcelles suivantes : '
                            : 'Le terrain est composé de la parcelle suivante : '}
                        </span>
                        <ul>
                          {selectedParcelsIds.map((selectedParcelsId) => {
                            return (
                              <li key={uniqueId('selected_parcels')}>
                                {selectedParcelsId.slice(8)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </StyledPop>
            )}
          </Marker>
        )}
      </>
    );
  }
);

export default FieldBuildingToolLayer;
