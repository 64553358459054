import React, { useEffect, useMemo } from 'react';
import CiblageLayout from './CiblageLayout';
import { useAuth } from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getCiblages } from '../../store/actions/ciblage/listCiblage';
import spacingThousands from '../../_helpers/thousandShow';
import moment from 'moment';
import MapCiblage from '../../components/ciblage-v2/map/MapCiblage';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { ciblageResultActions } from '../../store/slices/ciblageResult';

const CiblageItem = ({
  onClick,
  ciblage: { id, address, created_at, nb_results, name },
}) => {
  const handleClick = () => {
    onClick && onClick(id);
  };

  return (
    <div
      className="px-6 py-4  border-b-1 border-gray-500 cursor-pointer hover:bg-gray-200 flex justify-between"
      onClick={handleClick}
    >
      <p>
        Ciblage du {moment(created_at).format('DD/MM/YYYY')} -{' '}
        {nb_results ? spacingThousands(nb_results) : 0} parcelles -{' '}
        <span className="font-medium">{name || address}</span>
      </p>
      <ChevronRightIcon className="h-6 w-6" />
    </div>
  );
};

export default function CiblageList() {
  const { ciblages } = useSelector((state) => state.ciblageResult);
  const { sessionAuthHeader } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const headers = {
      'X-USER-EMAIL': sessionAuthHeader?.email,
      'X-USER-TOKEN': sessionAuthHeader?.token,
    };
    dispatch(getCiblages({ headers }));
  }, []);

  const handleClickCiblage = (id) => {
    dispatch(ciblageResultActions.reset());
    history.push(`/ciblage/${id}`);
  };

  const { nbCiblage, sumParcels } = useMemo(
    () => ({
      nbCiblage: ciblages.length,
      sumParcels: ciblages
        .map((item) => item.nb_results)
        .filter((item) => item)
        .reduce((current, next) => current + next, 0),
    }),
    [ciblages]
  );

  return (
    <CiblageLayout>
      <div className="w-full h-full flex">
        <div className="w-full lg:w-3/4 flex flex-col">
          <div className="p-6 bg-gray-300 border-b-1 border-gray-400">
            <h1 className="font-semibold text-xl">
              Sélectionnez vos parcelles à évaluer
            </h1>

            <p className="font-bold text-base">
              {nbCiblage} ciblages, {spacingThousands(sumParcels)} parcelles
            </p>
          </div>
          <div className="w-full h-full flex-grow overflow-auto">
            {ciblages.map((item) => (
              <CiblageItem
                onClick={handleClickCiblage}
                key={item.id}
                ciblage={item}
              />
            ))}
          </div>
        </div>
        <div className="hidden lg:block w-1/4">
          <MapCiblage />
        </div>
      </div>
    </CiblageLayout>
  );
}
