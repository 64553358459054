import React, {useState} from 'react';
import {Marker, Polygon} from 'react-leaflet';
import IconAdd from '../../../assets/images/icons/plus.png';
import IconRemove from '../../../assets/images/icons/close.png';

let AddIcon = new L.icon({
    iconUrl: IconAdd,
    iconSize: [21.3, 21.3],
    // shadowUrl: iconShadow,
});

let RemoveIcon = new L.icon({
    iconUrl: IconRemove,
    iconSize: [21.3, 21.3],
    // shadowUrl: iconShadow,
});

const HandleForm = ({value, onSelectParcels, handleClick}) => {
    const [color, setColor] = useState();
    const [fill, setFill] = useState();
    const [clicked, setClicked] = useState(false);

    let result = value.geometry.coordinates.map((valuer) =>
        valuer.map((vv) => [vv[1], vv[0]])
    );

    return Object.keys(value).map((f, i) => (
        <div key={i}>
            <Polygon
                pathOptions={{
                    color: `${fill ? fill : 'black'}`,
                    fillColor: `${color ? color : '#BDC6CA'}`,
                }}
                positions={result}
            />
            {handleClick && (
                <Marker
                    position={result[0][0]}
                    eventHandlers={{
                        click: (e) => {
                            if (!clicked && onSelectParcels) {
                                onSelectParcels((prevState) => [...prevState, value]);
                            }
                            if (clicked && onSelectParcels) {
                                onSelectParcels((prevState) =>
                                    prevState.filter(
                                        (v) =>
                                            v.properties?.parcelle_id !== value.properties?.parcelle_id
                                    )
                                );
                            }
                            setClicked(!clicked);
                            if (clicked) {
                                setColor('');
                                setFill('');
                            } else {
                                setColor('#D2E1F8');
                                setFill('#3B619D');
                            }
                        },
                    }}
                    icon={!clicked ? AddIcon : RemoveIcon}
                />
            )}
        </div>
    ));
};

export default HandleForm;
