import React, { useEffect, useMemo, useState } from 'react';
import { useParcel } from '../../_services/parcel-service';
import { Error } from './Error';
import { usePrograms } from '../../hooks/usePrograms';
import { updateMarketSurvey } from '../../services/market-survey';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { SectionHeaderTitle } from './SectionHeaderTitle';
import classnames from 'classnames';
import ahoy from 'ahoy.js';
import { useSelector } from 'react-redux';
import LitleSelect from '../molecules/little-select/LittleSelect';

const zoneOptions = [
  {
    value: 'closest',
    label: '20 programmes les + proches',
  },
  {
    value: 'isochrone',
    label: 'Isochrone Voiture en 10 mn',
  },
  {
    value: 'epci',
    label: 'EPCI',
  },
  {
    value: 'commune',
    label: 'Commune du terrain',
  },
];

const TVAOptions = [
  {
    value: 'toutes_tva',
    label: 'Toutes',
  },
  {
    value: 'tva_reduite',
    label: 'Réduite',
  },
  {
    value: 'tva_normale',
    label: 'Normale',
  },
];

const programmeOptions = [
  {
    value: 'collectif',
    label: 'Collectif',
  },
  {
    value: 'individuel',
    label: 'Individuel',
  },
  {
    value: 'residence',
    label: 'Residence',
  },
];

export const SelectorRange = ({ min, max, onChange, defaultValues, name }) => {
  const [value, setValue] = useState([min, max]);

  useEffect(() => {
    setValue(defaultValues);
  }, []);

  const { minOptions, maxOptions } = useMemo(() => {
    return {
      minOptions: Array(max - min + 1)
        .fill(true)
        .map((_, index) => ({
          label: min + index,
          value: min + index,
        }))
        .filter((item) => item.value < value[1]),
      maxOptions: Array(max - min + 1)
        .fill(true)
        .map((_, index) => ({
          label: min + index,
          value: min + index,
        }))
        .filter((item) => item.value > value[0]),
    };
  }, [value, max, min]);

  const handleChangeMin = (e) => {
    setValue((curr) => {
      const currentValue = [e.target.value, curr[1]];
      onChange && onChange({ target: { name, value: currentValue } });
      return currentValue;
    });
  };

  const handleChangeMax = (e) => {
    setValue((curr) => {
      const currentValue = [curr[0], e.target.value];
      onChange && onChange({ target: { name, value: currentValue } });
      return currentValue;
    });
  };

  return (
    <div className="flex items-center">
      <LitleSelect
        options={minOptions}
        keyLabel="label"
        keyValue="value"
        name="min"
        onChange={handleChangeMin}
        value={minOptions.find((item) => item.value === value[0])}
      />
      <span className="text-xs mx-2">à</span>
      <LitleSelect
        options={maxOptions}
        keyLabel="label"
        keyValue="value"
        name="max"
        onChange={handleChangeMax}
        value={maxOptions.find((item) => item.value === value[1])}
      />
    </div>
  );
};

const FilterProgramme = ({ defaultValues, onChangeFilter }) => {
  const { activePrograms } = usePrograms();

  const [values, setValues] = useState({
    filter_geo: 'closest',
    filter_tva_reduite: 'toutes_tva',
    filter_type_programme: 'collectif',
    mev_year_min: 2020,
    mev_year_max: 2024,
  });

  useEffect(() => {
    if (defaultValues) {
      setValues(defaultValues);
    }
  }, [defaultValues]);

  const handleChange = (e) => {
    const currentValue = { ...values, [e.target.name]: e.target.value };
    setValues(currentValue);
    onChangeFilter && onChangeFilter(currentValue);
  };

  const handleChangeRangeInput = (e) => {
    const currentValue = {
      ...(values || {}),
      mev_year_min: e.target.value[0],
      mev_year_max: e.target.value[1],
    };
    setValues(currentValue);
    onChangeFilter && onChangeFilter(currentValue);
  };

  return (
    <>
      <div className="w-full p-4 bg-blue-300 px-2 xl:px-4 py-3 flex justify-between">
        <div className="flex items-start gap-x-8 2xl:gap-x-10">
          <div>
            <div className="font-semibold text-sm mb-1">Zone</div>
            <LitleSelect
              options={zoneOptions}
              keyLabel="label"
              keyValue="value"
              name="filter_geo"
              onChange={handleChange}
              value={zoneOptions.find(
                (item) => item.value === values?.filter_geo
              )}
            />
          </div>
          <div>
            <div className="font-semibold text-sm mb-1">Tva</div>
            <LitleSelect
              options={TVAOptions}
              keyLabel="label"
              keyValue="value"
              name="filter_tva_reduite"
              onChange={handleChange}
              value={TVAOptions.find(
                (item) => item.value === values?.filter_tva_reduite
              )}
            />
          </div>
          <div>
            <div className="font-semibold text-sm mb-1">Programme</div>
            <LitleSelect
              options={programmeOptions}
              keyLabel="label"
              keyValue="value"
              name="filter_type_programme"
              onChange={handleChange}
              value={programmeOptions.find(
                (item) => item.value === values?.filter_type_programme
              )}
            />
          </div>
          <div className="flex flex-col justify-between">
            <div>
              <div className="font-semibold text-sm mb-1">Période MEV</div>
              <SelectorRange
                min={2020}
                max={2024}
                defaultValues={[values.mev_year_min, values.mev_year_max]}
                name="range"
                onChange={handleChangeRangeInput}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-end">
          <div className="font-semibold text-xs whitespace-nowrap">
            Progs. sélectionnés
          </div>
          <div
            className={classnames(
              'px-4 rounded-md py-1 text-xs mb-1 border-2 border-white',
              {
                'bg-red-400 text-white':
                  activePrograms?.length > 20 || activePrograms?.length < 2,
                'bg-green-400 text-white':
                  activePrograms?.length <= 20 && activePrograms?.length >= 2,
              }
            )}
          >
            {activePrograms?.length ? activePrograms?.length : 0}
          </div>
        </div>
      </div>
    </>
  );
};

const SectionHeader = ({
  title,
  pivot_parcelle_id,
  defaultValuesFilter,
  onChangeFilter,
  onReady,
}) => {
  const { survey_id } = useParams();
  const { activePrograms } = usePrograms();
  const { selectedProgrammes } = useSelector((state) => state.programme);

  const handleUpdateMarketSurvey = () => {
    if (selectedProgrammes.length >= 2) {
      const updateMarketSurveyPayload = {
        real_estate_programs_attributes: [
          ...selectedProgrammes.map((program) => {
            const {
              nom_programme,
              date_livraison,
              nb_logt_mis_en_vente,
              pmmv,
              tran_id,
            } = program.attributes;

            return {
              reference: tran_id,
              name: nom_programme,
              release_date: date_livraison,
              quantity_housing_for_sale: nb_logt_mis_en_vente,
              average_cost_per_square_meter_tax_included: pmmv,
            };
          }),
        ],
      };
      updateMarketSurvey(survey_id, updateMarketSurveyPayload, () => {
        ahoy.track('$rapport_etudes_programmes_mis_a_jour', {
          market_survey_id: survey_id,
        });
        if (selectedProgrammes.length >= 2) {
          window.location.assign(`/market-survey/${survey_id}/detail`);
        }
      });
    } else {
      alert('error !!!');
    }
  };

  const { parcelData, isLoading, isError } = useParcel(pivot_parcelle_id);

  useEffect(() => {
    if (parcelData) {
      onReady && onReady();
    }
  }, [parcelData]);

  if (isLoading) return null;

  if (isError) return <Error error={isError} />;

  const { epci_name } = parcelData.data.attributes;

  return (
    <div
      id={'section_header'}
      className="bg-white hidden lg:block w-full z-99999"
    >
      <div className="w-full flex items-center justify-between py-2 xl:py-4 px-2 xl:px-4 border border-b-1 border-gray-200">
        <SectionHeaderTitle
          displayMenu={true}
          title={title}
          survey_id={survey_id}
          epci_name={epci_name}
        />
        <button
          onClick={handleUpdateMarketSurvey}
          disabled={
            !(activePrograms?.length >= 2) || activePrograms?.length > 20
          }
          type="button"
          className={classnames(
            {
              'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500':
                activePrograms?.length >= 2 && activePrograms?.length <= 20,
            },
            {
              'bg-blue-200 focus:outline-none cursor-not-allowed':
                !(activePrograms?.length >= 2) || activePrograms?.length > 20,
            },
            'inline-flex items-center px-4 py-2 border border-transparent text-xs xl:text-sm font-medium rounded-md shadow-sm text-white '
          )}
        >
          Lancer L'étude de marché
        </button>
      </div>
      <div>
        <h3 className="text-sm xl:text-xl leading-6 text-center text-gray-900 mt-2 px-2 xl:px-4 mb-2">
          <span className="uppercase font-semibold">
            Sélectionnez les programmes pour votre étude
          </span>{' '}
          <span className="text-sm font-normal normal-case">(2 à 20 max)</span>
        </h3>
        <FilterProgramme
          onChangeFilter={onChangeFilter}
          defaultValues={defaultValuesFilter}
        />
      </div>
    </div>
  );
};

export default SectionHeader;
