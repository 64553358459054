import { GeoJSON, Marker, Popup, useMap } from 'react-leaflet';
import LegendLayer from '../Map/Legends/LegendLayer';
import React, { createRef, forwardRef, memo, useEffect } from 'react';
import L from 'leaflet';
import polylabel from 'polylabel';
import Land from '../../../../../../assets/images/icons/terrain.svg';
import BasicLegendLayer from '../Map/Legends/BasicLegendLayer';
import { poiEquipementIcons, poiIcons } from '../../../icons';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import _, { uniqueId } from 'lodash';
import styled from 'styled-components';
import { XIcon } from '@heroicons/react/outline';

const eductionIcons = [
  {
    title: 'Térrain évalué',
    icon: 'home',
  },
  {
    title: 'école primaire publique',
    icon: 'primary_public_school',
  },
  {
    title: 'Collège public',
    icon: 'middle_public_school',
  },
  {
    title: 'Lycée public',
    icon: 'high_public_school',
  },
  {
    title: 'école primaire privée',
    icon: 'primary_private_school',
  },
  {
    title: 'Collège privé',
    icon: 'middle_private_school',
  },
  {
    title: 'Lycée privé',
    icon: 'high_private_school',
  },
  {
    title: 'Université et enseignement supérieur',
    icon: 'university',
  },
];

let LandIcon = new L.icon({
  iconUrl: Land,
  iconSize: [42.6, 42.6],
});

const CustomPop = styled(Popup)`
  background-color: white;
  border-radius: 0;
  width: 300px;
  height: auto;
  border-radius: 10px;
  .leaflet-popup-content {
    margin: 10px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }

  .leaflet-popup-close-button {
    display: none;
  }
`;

const FeaturesCollectionLayer = ({
  data,
  needIsochrone,
  eductionMap,
  setGeoJSONGlobal = () => {
    return true;
  },
}) => {
  const map = useMap();

  useEffect(() => {
    if (data?.features) {
      setGeoJSONGlobal((geoJSONGlobal) => {
        return data.features.concat(geoJSONGlobal);
      });
    }
  }, [data]);

  const geoJsonStyle = (feature) => {
    return {
      fillColor: feature.properties.color || '#63b3ed',
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '1',
      fillOpacity: 0.9,
    };
  };

  const closePopup = (ref) => {
    if (!ref.current) return;
    ref.current.closePopup();
  };

  const FormatPopup = forwardRef(({ feature, metadata = [] }, ref) => {
    return (
      <CustomPop>
        <div className={'absolute right-0 top-0'}>
          <button onClick={() => closePopup(ref)} className={'p-2'}>
            <XIcon className={'w-5 h-5 text-blue-800 hover:text-blue-900'} />
          </button>
        </div>
        <div className={'p-2'}>
          <span className={'text-base font-bold m-0 uppercase block'}>
            {feature.properties.name}
          </span>
          <ul>
            {metadata.map(({ key, title }) => {
              const innerHTML = `${title} : ${
                key !== 'rank' ? '<br/>' : ''
              }<strong>${_.get(feature.properties, key)}</strong> ${
                key !== 'rank' ? feature.properties.unit : ''
              }`;

              return (
                <li
                  key={_.uniqueId('metadata__')}
                  className={'text-sm font-medium'}
                  dangerouslySetInnerHTML={{ __html: innerHTML }}
                ></li>
              );
            })}
          </ul>
          {/* <div className="mt-2">
            <iframe
              width="100%"
              height="250px"
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${transformCoordinates(
                feature.geometry.coordinates
              ).join(',')}`}
            />
          </div> */}
        </div>
      </CustomPop>
    );
  });

  function ConditionalGeoJSON({ feature }, index) {
    const ref = createRef();
    if (feature.geometry.type === 'MultiPolygon') {
      return (
        <GeoJSON
          ref={ref}
          key={`${feature.id}__${index}`}
          pathOptions={geoJsonStyle(feature)}
          data={feature.geometry}
        >
          {data.metadata && (
            <FormatPopup
              ref={ref}
              feature={feature}
              metadata={data.metadata.popup}
            />
          )}
        </GeoJSON>
      );
    }

    return (
      <GeoJSON pathOptions={geoJsonStyle(feature)}>
        {feature.geometry.type === 'Polygon' && (
          <Marker
            ref={ref}
            position={polylabel(feature.geometry.coordinates, 1.0).reverse()}
            icon={LandIcon}
          />
        )}
        {feature.geometry.type === 'Point' && (
          <Marker
            ref={ref}
            position={transformCoordinates(feature.geometry.coordinates)}
            icon={
              poiEquipementIcons[feature.properties.amenity]
                ? L.divIcon({
                    html: /* html */ `<div style="background-color: ${
                      poiEquipementIcons[feature.properties.amenity].color
                    }" class="w-8 h-8 equipement-icon rounded-full flex items-center justify-center">
                      <img src="${
                        poiEquipementIcons[feature.properties.amenity].url
                      }"/>
                  </div>`,
                  })
                : poiIcons(feature.properties.amenity)
            }
          >
            {!!data.metadata && (
              <FormatPopup
                ref={ref}
                feature={feature}
                metadata={data.metadata.popup}
              />
            )}
            {!data.metadata && feature.properties && (
              <FormatPopup ref={ref} feature={feature} />
            )}
          </Marker>
        )}
      </GeoJSON>
    );
  }

  return (
    <>
      {data.features?.map((feature) => {
        return (
          <ConditionalGeoJSON
            key={uniqueId('conditional_geojson--')}
            feature={feature}
          />
        );
      })}

      {data.metadata ? (
        <LegendLayer legendData={data.metadata.legend} map={map} />
      ) : (
        <BasicLegendLayer
          needIsochrone={needIsochrone}
          data={eductionMap ? eductionIcons : data.legend}
        />
      )}
    </>
  );
};

export default memo(FeaturesCollectionLayer);
