import { createSlice } from '@reduxjs/toolkit';
import { PINEL_OPTIONS } from '../../constants/constants';
import {
  PRICE_NEW_RANGE_MIN,
  PRICE_NEW_RANGE_MAX,
  EVOLUTION_PRICE_NEW_MIN,
  PRICE_OLD_RANGE_MIN,
  PRICE_OLD_RANGE_MAX,
  EVOLUTION_PRICE_OLD_MIN,
  POPULATION_RANGE_MIN,
  POPULATION_RANGE_MAX,
  POPULATION_GROWTH_MIN,
  PERCENT_COLLECTIVE_HOUSING_MIN,
  TRANSPORT_DURATION_MAX,
  TRANSPORTMODE,
  STEP_1,
  STEP_2,
  SURFACE_RANGE_MIN,
  SURFACE_RANGE_MAX,
} from '../../constants/ciblageDefaultValues';

export const steps = ['votre-territoire', 'vos-terrains'];

export const transform = (state) => JSON.parse(JSON.stringify(state));

const defaultFilterCiblageValues = {
  isEnabledMarcheImmobilier: false,
  isEnabledTerritoire: false,
  marcherImmobilier: {
    isNewEnabled: false,
    isOldEnabled: false,
    hasNewProjectsSinceFiveYear: null,
    newPriceRange: [PRICE_NEW_RANGE_MIN, PRICE_NEW_RANGE_MAX],
    evolutionNewPrice: EVOLUTION_PRICE_NEW_MIN,
    oldPriceRange: [PRICE_OLD_RANGE_MIN, PRICE_OLD_RANGE_MAX],
    evolutionOldPrice: EVOLUTION_PRICE_OLD_MIN,
  },
  territoire: {
    zonesPinel: PINEL_OPTIONS,
    zonesPinelToParams: PINEL_OPTIONS.map((el) => el.value),
    populationRange: [POPULATION_RANGE_MIN, POPULATION_RANGE_MAX],
    evolutionPopulation: POPULATION_GROWTH_MIN,
    logCollectif: PERCENT_COLLECTIVE_HOUSING_MIN,
    changedMayor: null,
    isActionHeartOfTheCity: null,
  },
};

const defaultTerritoireValues = {
  commune: null,
  epci: null,
  selectedCommunes: [],
};

const defaultFilterIsochroneValues = {
  isoChroneAddress: null,
  transportDuration: 15,
  transportMode: TRANSPORTMODE.find((item) => item.value === 'car'),
};

export const defaultValueTerrain = {
  zonePlu: null,
  zonePluToParams: null,
  surfaceRange: [SURFACE_RANGE_MIN, SURFACE_RANGE_MAX],
  zoneBatie: null,
  zoneANRU: null,
  zac: null,
};

const initialState = {
  loading: false,
  returnSearchCommunesBy: false,
  step: { current: STEP_1, next: STEP_2 },
  searchCommunesBy: null,
  activeBtnNext: false,
  cibler: false,
  communes: [],
  addresses: [],
  epcis: [],
  communesNameAndCode: [],
  valueTerritoire: defaultTerritoireValues,
  valueTerrain: defaultValueTerrain,
  filtersCibler: defaultFilterCiblageValues,
  isoChroneFilterValue: defaultFilterIsochroneValues,
  filtersCiblerJson: null,
  filtersTerrainJson: null,
  nbParcel: null,
  showBoxResultNb: false,
  createdAt: null,
};

// slice
export const ciblageCreateSlice = createSlice({
  name: 'ciblage',
  initialState,
  reducers: {
    setShowResultNb(state, action) {
      state.showBoxResultNb = action.payload;
    },
    setFiltersTerrainJson(state, action) {
      state.filtersTerrainJson = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCommunesNameAndCode(state, action) {
      state.communesNameAndCode = action.payload;
    },
    setNbParcel(state, action) {
      state.nbParcel = action.payload;
    },
    setFilterCibler(state, action) {
      state.filtersCibler = action.payload;
    },
    setFiltersCiblerJson(state, action) {
      state.filtersCiblerJson = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    setSearchCommunes(state, action) {
      state.searchCommunesBy = action.payload;
    },
    setValueTerrain(state, action) {
      state.valueTerrain = {
        ...action.payload,
        zonePluToParams: (action.payload.zonePlu || []).map(
          (item) => item.value
        ),
      };
    },
    setReturnSearchCommunesBy(state, action) {
      state.returnSearchCommunesBy = action.payload;
    },
    setActiveBtnNext(state, action) {
      state.activeBtnNext = action.payload;
    },
    setCommunes(state, action) {
      state.communes = action.payload;
      state.filtersCibler = defaultFilterCiblageValues;
      state.filtersCiblerJson = null;
    },
    setAddresses(state, action) {
      state.addresses = action.payload;
    },
    setEpcis(state, action) {
      state.epcis = action.payload;
    },
    setValueTerritoire(state, action) {
      state.valueTerritoire = action.payload;
    },
    setValueTerritoireEpci(state, action) {
      state.valueTerritoire = {
        ...transform(state.valueTerritoire),
        epci: action.payload,
      };
    },
    setValueTerritoireCommune(state, action) {
      state.valueTerritoire = {
        ...transform(state.valueTerritoire),
        commune: action.payload,
      };
    },
    setValueIsochroneFilter(state, action) {
      state.isoChroneFilterValue = action.payload;
    },
    setValueTerritoireSelectedCommunes(state, action) {
      state.valueTerritoire = {
        ...transform(state.valueTerritoire),
        selectedCommunes: action.payload,
      };
    },
    setCibler(state, action) {
      state.cibler = action.payload;
      state.valueTerritoire = {
        ...transform(state.valueTerritoire),
        selectedCommunes: [],
      };
      state.filtersCibler = defaultFilterCiblageValues;
      state.filtersCiblerJson = null;
      state.nbParcel = null;
    },
    setFilterCiblerMarcherImmobilier(state, action) {
      state.filtersCibler = {
        ...transform(state.filtersCibler),
        marcherImmobilier: action.payload,
      };
    },
    setFilterCiblerTerritoire(state, action) {
      state.filtersCibler = {
        ...transform(state.filtersCibler),
        territoire: {
          ...action.payload,
          zonesPinelToParams: action.payload.zonesPinel.map(
            (item) => item.value
          ),
        },
      };
    },
    setFiltersCiblerIsEnabledMarcheImmobiler(state, action) {
      state.filtersCibler = {
        ...transform(state.filtersCibler),
        isEnabledMarcheImmobilier: action.payload,
      };
    },
    setFiltersCiblerIsEnabledTerritoire(state, action) {
      state.filtersCibler = {
        ...transform(state.filtersCibler),
        isEnabledTerritoire: action.payload,
      };
    },
    setCreatedAt(state, action) {
      state.createdAt = action.payload;
    },
    reset: () => initialState,
  },
});

// export all actions of slice
export const ciblageCreateActions = ciblageCreateSlice.actions;

// export default the reducer of slice
export default ciblageCreateSlice.reducer;

export const setSearchCommunes = (value) => (dispatch, getState) => {
  const { searchCommunesBy } = getState().ciblageCreate;
  if (searchCommunesBy?.code !== value.code) {
    dispatch(ciblageCreateActions.reset());
  }
  dispatch(ciblageCreateActions.setSearchCommunes(value));
  dispatch(ciblageCreateActions.setReturnSearchCommunesBy(false));
};
