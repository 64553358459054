import React, { createRef, memo, useEffect, useMemo, useState } from 'react';
import { useSelectedParcels } from '../../../../hooks/useSelectedParcels';
import _, { forEach, uniq } from 'lodash';
import MarkerPolylabelLayer from './MarkerPolylabelLayer';
// import { HOSPITAL, TOWN_HALL } from '../../../../_helpers/constant';
// import MsPoiMarker from './MarketSurvey/MsPoiMarker';
import LoadingSection from '../../../MarketSurveys/sections/LoadingSection';
import { Marker, Popup, useMap } from 'react-leaflet';
import MsFeaturesCollectionLayer from './MarketSurvey/MsFeaturesCollectionLayer';
import L from 'leaflet';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import { poiEquipementIcons, poiIcons } from '../../../icons';
import LegendLayer from '../Map/Legends/LegendLayer';
import BasicLegendLayer from '../Map/Legends/BasicLegendLayer';
import styled from 'styled-components';
import { XIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { useMetadataMs } from '../../../../hooks/useChangeOnMSMap';
import useCenterByMetadata from '../../../../_helpers/map-helpers/useCenterByMetadata';

const CustomPop = styled(Popup)`
  background-color: white;
  border-radius: 0;
  width: 200px;
  height: auto;
  border-radius: 10px;
  .leaflet-popup-content {
    margin: 10px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }
  leaflet-popup-close-button {
    color: gray;
    right: 10px;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;

const titleIcon = {
  train: 'gare SNCF',
  train_national: 'gare SNCF nationale',
  bus: 'Arrêt de bus',
  metro: 'Station métro / tram',
  airport: 'Aeroport',
};

const zIndexIconType = [
  {
    type: 'train',
    zIndex: 40,
  },
  {
    type: 'train_national',
    zIndex: 30,
  },
  {
    type: 'airport',
    zIndex: 20,
  },
  {
    type: 'metro',
    zIndex: 10,
  },
  {
    type: 'bus',
    zIndex: 0,
  },
];

export const fitBoundWithPadding = (map, featureGroup, percent = 10) => {
  // Check if map and featureGroup are defined
  if (!map) {
    console.warn('fitBoundWithPadding: map is not defined');
    return;
  }

  if (!featureGroup) {
    console.warn('fitBoundWithPadding: featureGroup is not defined');
    return;
  }

  // Check if getBounds returns a valid result
  const bounds = featureGroup?.getBounds();
  if (!bounds?.isValid()) {
    console.warn('fitBoundWithPadding: featureGroup bounds are not valid');
    return;
  }

  // // Check if map.getSize returns valid dimensions
  // const mapSize = map.getSize();
  // if (!mapSize || mapSize.x === undefined || mapSize.y === undefined) {
  //   console.warn('fitBoundWithPadding: map size is not valid');
  //   return;
  // }

  // const { x, y } = mapSize;

  // const paddingValues = [x, y].map((value) => (percent * value) / 100);
  // // Validate paddingValues
  // const isValidPadding = paddingValues.every(
  //   (value) => !isNaN(value) && value >= 0
  // );

  // setTimeout(() => {
  //   try {
  //     if (
  //       bounds.isValid() &&
  //       map._mapPane &&
  //       map._size &&
  //       map._size.x !== undefined &&
  //       map._size.y !== undefined
  //     ) {
  //       const fitBoundsOptions = isValidPadding
  //         ? {
  //             paddingTopLeft: [paddingValues[1], paddingValues[0]],
  //             paddingBottomRight: [paddingValues[1], paddingValues[0]],
  //           }
  //         : {};

  //       //console.log("fitBoundWithPadding: Fitting bounds with padding", bounds, fitBoundsOptions);
  //       console.log(
  //         'fitBoundWithPadding: Fitting bounds without padding',
  //         bounds
  //       );
  //       map.fitBounds(bounds); //, fitBoundsOptions);
  //     } else {
  //       console.warn('fitBoundWithPadding: Attempted to fit invalid bounds');
  //     }
  //   } catch (error) {
  //     console.warn('fitBoundWithPadding: error fitting bounds', error);
  //   }
  // }, 500);

  if (map.fitBounds && typeof map.fitBounds === 'function') {
    map.fitBounds(bounds);
  }
};

const MsPOILayer = ({ needIsochrone, isPdfMode = false, name }) => {
  const map = useMap();
  const [coordinates, setCoordinates] = useState([]);
  const {
    transportCartoData: dataBrut,
    accessiblityIsochoneData,
    errorAccessiblityIsochone,
  } = useSelector((state) => state.marketSurvey);
  const { communesParcelles } = useSelector((state) => state.others);
  const { fieldIds } = useSelectedParcels();
  const metadata = useMetadataMs((state) => state.metadata);

  const closePopup = (ref) => {
    if (!ref.current) return;
    ref.current.closePopup();
  };

  const data = useMemo(() => {
    let dataReturn = [];
    dataBrut.forEach((item) => {
      if (item?.data?.type === 'FeatureCollection') {
        item?.data?.features?.forEach((el) => {
          dataReturn.push({ data: el, type: item?.type });
        });
      } else {
        dataReturn.push(item);
      }
    });
    return dataReturn;
  }, [dataBrut]);

  const legendData = useMemo(
    () => [
      { title: 'Terrain évalué', icon: 'home' },
      ...uniq(dataBrut.map((item) => item.type)).map((key) => ({
        title: titleIcon[key],
        icon: key,
      })),
    ],
    [dataBrut]
  );

  const centerByMetadata = useCenterByMetadata({
    metadata,
    map,
    isPdfMode,
    name,
  });

  useEffect(() => {
    if (accessiblityIsochoneData?.length && !centerByMetadata) {
      const featureGroup = L.featureGroup();
      const isochroneLayer =
        accessiblityIsochoneData.length &&
        accessiblityIsochoneData.find((item) => item.parcelId === fieldIds[0])
          ?.data;

      const coordsIsochroneFeature =
        isochroneLayer?.features?.length && isochroneLayer?.features[0];

      const markerInsideIsochrone = (data || [])
        .map(({ data }) => {
          if (
            data &&
            data.geometry &&
            coordsIsochroneFeature &&
            turf.inside(data, coordsIsochroneFeature)
          ) {
            return data;
          }
        })
        .filter((item) => item);

      // if marker exists and these markers are not int the isochrone layer
      if (!markerInsideIsochrone.length && data.length) {
        (data || []).forEach(({ data }) => {
          if (data && data.geometry) {
            L.marker(transformCoordinates(data.geometry.coordinates)).addTo(
              featureGroup
            );
          }
        });
      } else {
        L.geoJSON(coordsIsochroneFeature).addTo(featureGroup);
      }

      // fitBound
      fitBoundWithPadding(map, featureGroup, isPdfMode ? 25 : 10);
    }
  }, [data, accessiblityIsochoneData, fieldIds, isPdfMode, centerByMetadata]);

  useEffect(() => {
    if (
      errorAccessiblityIsochone &&
      communesParcelles?.length &&
      !centerByMetadata
    ) {
      const featureGroup = L.featureGroup();
      L.geoJson(communesParcelles).addTo(featureGroup);

      // fitBound
      fitBoundWithPadding(map, featureGroup, isPdfMode ? 25 : 10);
    }
  }, [
    errorAccessiblityIsochone,
    communesParcelles,
    isPdfMode,
    map,
    centerByMetadata,
  ]);

  if (!data.length) return <LoadingSection />;

  return (
    <>
      {data &&
        data.map(({ data, type }) => {
          let ref = createRef();
          const coord = transformCoordinates(data.geometry.coordinates);
          return (
            <Marker
              ref={ref}
              key={_.uniqueId('ms__marker__')}
              position={coord}
              icon={
                poiEquipementIcons[type]
                  ? L.divIcon({
                      html: /* html */ `
                      <div style="background-color: ${
                        poiEquipementIcons[type].color
                      }" class="${
                        type === 'bus' && 'border-1 border-black'
                      } w-8 h-8 equipement-icon rounded-full flex items-center justify-center">
                        <img src="${poiEquipementIcons[type].url}"/>
                      </div>
                      `,
                    })
                  : poiIcons(type)
              }
              eventHandlers={{
                add: (e) => {
                  const zIndex = zIndexIconType.find(
                    (item) => item.type === type
                  );
                  if (zIndex) {
                    e.target.setZIndexOffset(zIndex.zIndex);
                  }
                },
              }}
            >
              {data.properties && (
                <CustomPop>
                  <div className={'absolute right-0 top-0'}>
                    <button onClick={() => closePopup(ref)} className={'p-2'}>
                      <XIcon
                        className={'w-5 h-5 text-blue-800 hover:text-blue-900'}
                      />
                    </button>
                  </div>
                  <div>
                    <h1 className="text-base 2xl:text-lg font-semibold">
                      {data.properties.name}
                    </h1>
                    {/* <div className="mt-2">
                      <iframe
                        width="100%"
                        height="250px"
                        loading="lazy"
                        allowFullScreen
                        src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${coord.join(
                          ','
                        )}`}
                      />
                    </div> */}
                  </div>
                </CustomPop>
              )}
            </Marker>
          );
        })}

      <MsFeaturesCollectionLayer
        data={data}
        noNeedLegend
        setCoordinates={setCoordinates}
      />
      {fieldIds && (
        <MarkerPolylabelLayer
          fieldIds={fieldIds}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      )}
      {data?.metadata ? (
        <LegendLayer legendData={data.metadata.legend} />
      ) : (
        <BasicLegendLayer
          needIsochrone={errorAccessiblityIsochone ? false : needIsochrone}
          data={legendData}
        />
      )}
    </>
  );
};

export default memo(MsPOILayer);
