import FeaturesCollectionLayer from './FeaturesCollectionLayer';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelectedParcels } from '../../../../hooks/useSelectedParcels';
import useSWR from 'swr';
import _ from 'lodash';
import { getFetcher } from '../../../../api/fetcher';
import MarkerPolylabelLayer from './MarkerPolylabelLayer';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import LoadingSection from '../../../MarketSurveys/sections/LoadingSection';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import { fitBoundWithPadding } from './MsPOILayer';
import { useMetadataMs } from '../../../../hooks/useChangeOnMSMap';
import useCenterByMetadata from '../../../../_helpers/map-helpers/useCenterByMetadata';

const MSDefaultLayer = ({
  urlMap,
  needIsochrone,
  eductionMap,
  data,
  isPdfMode,
  name,
  isMobility = false,
}) => {
  const map = useMap();
  const [geoJSONGlobal, setGeoJSONGlobal] = useState([]);
  const { accessiblityIsochoneData, errorAccessiblityIsochone } = useSelector(
    (state) => state.marketSurvey
  );
  const { fieldIds } = useSelectedParcels();
  const metadata = useMetadataMs((state) => state.metadata);

  const res = useSWR(!data && urlMap, getFetcher);

  const centerByMetadata = useCenterByMetadata({
    name,
    isPdfMode,
    map,
    metadata,
  });

  const layerData = useMemo(
    () => (data ? data : res?.data || null),
    [data, res]
  );

  useEffect(() => {
    if (!centerByMetadata) {
      if (
        needIsochrone &&
        accessiblityIsochoneData?.length &&
        fieldIds?.length
      ) {
        const featureGroup = L.featureGroup();

        const isochroneFeature =
          accessiblityIsochoneData.length &&
          accessiblityIsochoneData.find((item) => item.parcelId === fieldIds[0])
            ?.data.features[0];

        const markerInIsochrone = (geoJSONGlobal || [])
          .map((item) => {
            if (isochroneFeature && turf.inside(item, isochroneFeature)) {
              return item;
            }
          })
          .filter((item) => item);

        if (!markerInIsochrone.length && geoJSONGlobal?.length) {
          geoJSONGlobal.forEach((item) => {
            L.marker(transformCoordinates(item.geometry.coordinates)).addTo(
              featureGroup
            );
          });
        } else {
          L.geoJSON(isochroneFeature).addTo(featureGroup);
        }
        fitBoundWithPadding(
          map,
          featureGroup,
          !isMobility ? 0 : isPdfMode ? 25 : 5
        );
      } else {
        if (geoJSONGlobal && geoJSONGlobal.length) {
          const geojsonLayer = L.geoJSON(geoJSONGlobal);
          if (geojsonLayer) {
            fitBoundWithPadding(
              map,
              geojsonLayer,
              !isMobility ? 0 : isPdfMode ? 25 : 5
            );
          }
        }
      }
    }
  }, [
    geoJSONGlobal,
    accessiblityIsochoneData,
    fieldIds,
    isPdfMode,
    map,
    centerByMetadata,
    isMobility,
  ]);

  useEffect(() => {
    if (
      errorAccessiblityIsochone &&
      geoJSONGlobal?.length &&
      needIsochrone &&
      !centerByMetadata
    ) {
      const featureGroup = L.featureGroup();
      geoJSONGlobal.forEach((item) => {
        L.marker(transformCoordinates(item.geometry.coordinates)).addTo(
          featureGroup
        );
      });
      fitBoundWithPadding(
        map,
        featureGroup,
        !isMobility ? 0 : isPdfMode ? 25 : 5
      );
    }
  }, [
    errorAccessiblityIsochone,
    geoJSONGlobal,
    needIsochrone,
    isPdfMode,
    map,
    centerByMetadata,
    isMobility,
  ]);

  return (
    <>
      {!layerData && <LoadingSection />}
      {layerData?.type === 'FeatureCollection' && (
        <FeaturesCollectionLayer
          needIsochrone={errorAccessiblityIsochone ? false : needIsochrone}
          data={layerData}
          setGeoJSONGlobal={setGeoJSONGlobal}
          eductionMap={eductionMap}
        />
      )}
      <MarkerPolylabelLayer />
    </>
  );
};

export default memo(MSDefaultLayer);
