export const PRICE_NEW_RANGE_STEP = 1;
export const PRICE_NEW_RANGE_MIN = 1;
export const PRICE_NEW_RANGE_MAX = 20000;

export const EVOLUTION_PRICE_NEW_STEP = 1;
export const EVOLUTION_PRICE_NEW_MIN = -10;
export const EVOLUTION_PRICE_NEW_MAX = 20;

export const PRICE_OLD_RANGE_STEP = 1;
export const PRICE_OLD_RANGE_MIN = 1;
export const PRICE_OLD_RANGE_MAX = 20000;

export const EVOLUTION_PRICE_OLD_STEP = 1;
export const EVOLUTION_PRICE_OLD_MIN = -10;
export const EVOLUTION_PRICE_OLD_MAX = 50;

export const POPULATION_RANGE_STEP = 1;
export const POPULATION_RANGE_MIN = 1;
export const POPULATION_RANGE_MAX = 1000000;

export const POPULATION_GROWTH_STEP = 0.1;
export const POPULATION_GROWTH_MIN = -10;
export const POPULATION_GROWTH_MAX = 20;

export const PERCENT_COLLECTIVE_HOUSING_STEP = 1;
export const PERCENT_COLLECTIVE_HOUSING_MIN = 0;
export const PERCENT_COLLECTIVE_HOUSING_MAX = 100;

export const TRANSPORTMODE = [
  { value: 'walk', label: 'A pied' },
  { value: 'bike', label: 'Vélo' },
  { value: 'transit', label: 'Transport en commun' },
  { value: 'car', label: 'Voiture' },
];

export const TRANSPORT_DURATION_MIN = 0;
export const TRANSPORT_DURATION_MAX = 45;

export const STEP_1 = 'votre-territoire';
export const STEP_2 = 'vos-terrains';

export const SURFACE_RANGE_STEP = 1;
export const SURFACE_RANGE_MIN = 1;
export const SURFACE_RANGE_MAX = 20000;
