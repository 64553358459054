import GeoJsonParcel from "./GeoJsonParcel";
import React from "react";
import {useDataVisualisation} from "../../../../hooks/useDataVisualisation";
import {useSelectedParcels} from "../../../../hooks/useSelectedParcels";
import Api from "../../../../api/Api";
import useSWR from "swr";
import _ from "lodash";
import LoadingSection from "../../../MarketSurveys/sections/LoadingSection";

const SurfaceLayer = () => {
    const {urlMap} = useDataVisualisation()
    const {fieldIds} = useSelectedParcels()


    const fetcher = url => Api.get(url).then(res => res.data)
    const {data, error} = useSWR(urlMap, fetcher)

    const {
        data: parcelsData,
        error: parcelsError
    } = useSWR(() => fieldIds.length > 0 ? `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/carto?parcelle_ids[]=` + _.join(fieldIds, "&parcelle_ids[]=")
        : null, fetcher)


    if (!data && !parcelsData) {
        return <LoadingSection/>
    }

    if (error || parcelsError) {
        return <div> Loading failed ...</div>
    }

    if (parcelsData?.type === "FeatureCollection") {
        return (
            <GeoJsonParcel parcelsData={parcelsData}/>
        )
    }
    return null
}

export default SurfaceLayer;
