export const EPCI = 'epci';
export const ISOCHRONE = 'isochrone';
export const NAME = 'name';

export const PINEL_OPTIONS = [
  { value: 'A', label: 'A' },
  { value: 'ABis', label: 'ABis' },
  { value: 'B1', label: 'B1' },
  { value: 'B2', label: 'B2' },
  { value: 'C', label: 'C' },
];

export const PLU_ZONES = [
  'A',
  'AB',
  'AP',
  'AU',
  'N',
  'NA',
  'NB',
  'NC',
  'ND',
  'NE',
  'NH',
  'NI',
  'NP',
  'UA',
  'UB',
  'UC',
  'UCA',
  'UCB',
  'UD',
  'UE',
  'UL',
  'UI',
  'UP',
  'UT',
  'UV',
  'UY',
  'ZH'
];

export const PLUS_OPTIONS = [
  { value: 'A', label: 'A : zone agricole' },
  { value: 'AB', label: 'AB : sous-section zone A, usage agricole' },
  { value: 'AP', label: "AP : zone d'agriculture protégée" },
  { value: 'AU', label: 'AU : zone à urbaniser' },
  { value: 'N', label: 'N : zone naturelle et forestière' },
  { value: 'NA', label: "NA : future zone d'urbanisation" },
  { value: 'NB', label: "NB : zones d'urbanisation diffuse" },
  { value: 'NC', label: 'NC : zones de richesses naturelles' },
  { value: 'ND', label: 'ND : zone naturelle à protéger' },
  { value: 'NE', label: 'NE : zone naturelle écologique sensible' },
  { value: 'NH', label: 'NH : zone naturelle constructible sous conditions' },
  { value: 'NI', label: 'NI : campings, équipements sportifs et loisirs' },
  { value: 'NP', label: "NP : protection des captages d'eau potable" },
  { value: 'UA', label: 'UA : zone urbaine mixte' },
  { value: 'UB', label: "UB : zone d'extention urbaine" },
  { value: 'UC', label: 'UC : zone urbaine mixte' },
  { value: 'UCA', label: "UCA : zone d'intérêt paysager" },
  { value: 'UCB', label: "UCB : zone d'habitat individuel isolé ou groupé" },
  { value: 'UD', label: 'UD : zone urbaine de faible densité' },
  {
    value: 'UE',
    label: 'UE : zone urbaine commerciale, artisanale et industrielle',
  },
  {
    value: 'UI',
    label: 'UI : zone urbaine dont la vocation principale est l’activité industrielle et artisanale.',
  },
  {
    value: 'UL',
    label: 'UL : zone qui accueille des constructions et équipements de loisirs liés aux activités touristiques',
  },
  { value: 'UP', label: 'UP : zone portuaire, fluviale' },
  { value: 'UT', label: 'UT : zone destinée à l’hébergement touristique et hôtelier ainsi qu’aux commerces et aux infrastructures de loisirs qui les accompagnent.' },
  { value: 'UY', label: 'UY : pour activités artisanales, industrielles, commerciales et de services.' },

  { value: 'ZH', label: 'ZH : zone humide' },
  { value: 'Autre', label: 'Autre : Sélectionnez uniquement cette case pour voir tous les autres zonages non référencés ci-dessus'}

];

export const STATUTS = [
  {
    code: 0,
    color: 'blue-400',
    label: 'Ciblé',
  },
  {
    code: 1,
    color: 'orange-400',
    label: 'Quick Check',
  },
  {
    code: 2,
    color: 'green-400',
    label: 'Éval. Complète',
  },
  {
    code: 3,
    color: 'red-400',
    label: 'Étude',
  },
  {
    code: 4,
    color: 'gray-400',
    label: 'Abandonné',
  },
];

export const DB_NAME_INDEXED_DB = 'evalparcel';
