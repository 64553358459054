import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ciblageCreateActions } from '../../slices/ciblageCreate';
import Api from '@/api/Api';
import { ciblageResultActions } from '../../slices/ciblageResult';
import { isArray } from 'lodash';
import { buildNewParamsOnEdit } from './updateCiblage';
import { PLUS_OPTIONS } from '../../../constants/constants';

const equivsOrder = {
  commune: 'order_by_commune',
  superficie: 'order_by_surface',
  zone_anru: 'order_by_anru',
  zonage_plu: 'order_by_plu',
};

const filtersTerrainEquiv = {
  zonePlu: 'zonage_plus',
  surfaceRange: ['surface_min', 'surface_max'],
  zoneBatie: 'zone_batie',
  zoneANRU: 'zone_anru',
  zac: 'zac',
};

const getNbParcelApi = async (params) => {
  let nbParcel = 0;
  try {
    const {
      data: { number_of_parcelles_ciblees },
    } = await axios.post(
      `${process.env.BASE_URL_API}/v5/eval_parcel/ciblage/count_parcels`,
      params
    );
    nbParcel = number_of_parcelles_ciblees;
  } catch (e) {
    console.log(e);
    nbParcel = 0;
  }
  return nbParcel;
};

export const getNbParcel = createAsyncThunk(
  'getNbParcel',
  async ({ filtersCiblerJson, selectedCommunes }, { dispatch, getState }) => {
    const {
      valueTerritoire: { epci },
    } = getState().ciblageCreate;

    if (selectedCommunes?.length) {
      const params = {
        ...(filtersCiblerJson || {}),
        ...(epci ? { national_code: epci.code } : {}),
        communes_national_codes: selectedCommunes.map(
          (item) => item.properties.national_code
        ),
      };
      try {
        const nbParcel = await getNbParcelApi(params);
        dispatch(ciblageCreateActions.setNbParcel(nbParcel));
      } catch (e) {
        console.log(e);
        dispatch(ciblageCreateActions.setNbParcel(null));
      }
    }
  }
);

export const buildParamsByJson = (json, partiel = false) => {
  let params = partiel ? '' : '?';
  const objectEntries = Object.entries(json);
  objectEntries.forEach(([key, value], i) => {
    if (isArray(value)) {
      value.forEach((item, index) => {
        if (item !== null) {
          params =
            params +
            `${key}[]=${item}` +
            `${
              i === objectEntries.length - 1 && index === value.length - 1
                ? ''
                : '&'
            }`;
        }
      });
    } else {
      if (value !== null) {
        params =
          params +
          `${key}=${value}` +
          `${i === objectEntries.length - 1 ? '' : '&'}`;
      }
    }
  });
  return params;
};

export const getCommunesParcels = (communesCodes) => async (dispatch) => {
  const {
    data: { data },
  } = await axios.get(
    `${process.env.BASE_URL_API}/v5/eval_parcel/ciblage/communes_names`,
    { params: { communes_national_codes: communesCodes } }
  );
  dispatch(ciblageResultActions.setCommunesParcels(data));
  dispatch(
    ciblageResultActions.setSelectedCommunes(
      data.map(({ attributes: { national_code } }) => national_code)
    )
  );
};

export const buildDataFiltersTerrain = (params, filtersTerrain) => {
  let json = { ...filtersTerrain };
  Object.entries(filtersTerrainEquiv).forEach(([key, value]) => {
    json = {
      ...json,
      [key]: isArray(value)
        ? value.map((el) => params[el] || null).filter((item) => !!item).length
          ? value.map((el) => params[el])
          : null
        : key === 'zonePlu'
        ? (params[value] || []).map((el) => ({
            value: el,
            label: PLUS_OPTIONS.find((item) => item.value === el).label,
          }))
        : typeof params[value] === 'undefined'
        ? null
        : params[value],
    };
  });
  return json;
};

export const getCiblageParcels = createAsyncThunk(
  'getCiblageParcelsByidCiblage',
  async (arg, { dispatch, getState }) => {
    const {
      ciblage,
      currentPage,
      sortingParcels,
      communesParcels,
      selectedCommunes,
      filtersTerrain,
    } = getState().ciblageResult;

    if (!arg?.noLoading) {
      dispatch(ciblageResultActions.setParcels([]));
      !arg?.noNeedUpdateNbParcel &&
        dispatch(ciblageResultActions.setNbParcel(0));
      dispatch(ciblageResultActions.setLoading({ parcels: true }));
    }

    // get ciblage
    let _ciblage = null;
    if (arg?.id) {
      const { data } = await Api.get(
        `${process.env.BASE_URL}/targets/${arg.id}`
      );
      _ciblage = data;
      dispatch(ciblageResultActions.setCiblage(_ciblage));
    } else {
      _ciblage = ciblage;
    }

    // add page in params
    let params = { ...JSON.parse(_ciblage.query_params), page: currentPage };

    // add sorting params
    if (sortingParcels) {
      params = {
        ...params,
        [equivsOrder[sortingParcels.key]]: sortingParcels.value,
      };
    }

    // get communes parcels
    if (!communesParcels?.length && !selectedCommunes?.length) {
      dispatch(getCommunesParcels(params.communes_national_codes));
    } else {
      params = {
        ...params,
        communes_national_codes: arg?.communesNationalCodes || selectedCommunes,
      };
    }

    dispatch(
      ciblageResultActions.setFiltersTerrain(
        buildDataFiltersTerrain(params, filtersTerrain)
      )
    );

    if (params.zonage_plus?.length === PLUS_OPTIONS.length) {
      params = { ...params, zonage_plus: [] };
    }

    let results = null;

    try {
      const {
        data: { features: parcels },
      } = await Api.get(`${process.env.BASE_URL}/parcels`, {
        params: {
          api_url: `${
            process.env.BASE_URL_API
          }/v5/eval_parcel/ciblage/parcels${buildParamsByJson(params)}`,
          target_id: arg?.id || _ciblage.id,
        },
      });

      dispatch(ciblageResultActions.setParcels(parcels));

      delete params.page;

      dispatch(ciblageResultActions.setAllFiltersJson(params));

      const {
        data: { number_of_parcelles_ciblees: nbParcel },
      } = await axios.post(
        `${process.env.BASE_URL_API}/v5/eval_parcel/ciblage/count_parcels`,
        params
      );

      dispatch(ciblageResultActions.setNbPage(Math.ceil(nbParcel / 25)));
      dispatch(ciblageResultActions.setNbParcel(nbParcel));
      dispatch(ciblageResultActions.setLoading(null));
      results = parcels;
    } catch (e) {
      console.log(e);
      dispatch(ciblageResultActions.setNbPage(0));
      dispatch(ciblageResultActions.setNbParcel(0));
      dispatch(ciblageResultActions.setLoading(null));
    }

    return results;
  }
);

export const evaluateParcels = createAsyncThunk(
  'evaluateParcels',
  async (_, { dispatch, getState }) => {
    const { parcels, checkedParcels } = getState().ciblageResult;

    const data = {
      ratings: parcels
        .filter((item) => checkedParcels.includes(item.parcelle_id))
        .map(({ parcelle_id, properties }) => ({
          address: '',
          evaluate_date: new Date(),
          town: '',
          parcel_size: properties.superficie,
          plu: properties.zonage_plu ?? 'N/A',
          pivot_parcelle_id: parcelle_id,
          land: {
            parcels_ids: [parcelle_id],
          },
        })),
    };

    dispatch(ciblageResultActions.setLoading({ evaluate: true }));

    return Api.post('/ratings', data)
      .then(({ data }) => {
        dispatch(ciblageResultActions.setLoading(null));
        return data;
      })
      .catch((e) => {
        dispatch(ciblageResultActions.setLoading(null));
        console.log(e);
        return null;
      });
  }
);

export const getNbParcelBeforeEdit = createAsyncThunk(
  'getNbParcelBeforeEdit',
  async (params, { getState, dispatch }) => {
    const {
      ciblage: { query_params },
    } = getState().ciblageResult;
    const newParams = buildNewParamsOnEdit(JSON.parse(query_params), {
      ...params,
      zonePlu:
        params.zonePlu.length === PLUS_OPTIONS.length
          ? []
          : params.zonePlu.map((item) => item.value),
    });

    dispatch(ciblageResultActions.setLoading({ nbParcelOnEdit: true }));
    const nbParcel = await getNbParcelApi(newParams);
    dispatch(ciblageResultActions.setLoading(null));
    dispatch(ciblageResultActions.setNbPageOnEdit(nbParcel));
  }
);
