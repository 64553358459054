import { useEffect, useState } from 'react';

export default function useDebounce(
  callback,
  deps,
  delay = 500,
  runInFirst = false
) {
  const [first, setFirst] = useState(false);

  useEffect(() => {
    const fn = setTimeout(() => {
      if (first || runInFirst) {
        callback && callback();
      }
      setFirst(true);
    }, delay);
    return () => clearTimeout(fn);
  }, deps);
  return null;
}
