import useSWR from 'swr';
import { getFetcher } from '../../../api/fetcher';
import React from 'react';
import classNames from 'classnames';

const TravelTimeItem = ({ title, unit, dataURL, isPdfMode, data: array }) => {
  const res = !array && useSWR(dataURL, getFetcher);

  const data = array ? array : res?.data || null;

  if (!data) return <span className={'animate-pulse bg-gray-400'} />;

  const { metric, name } = data;

  if (title.length !== 0) {
    return (
      <li
        className={classNames({
          'text-base': isPdfMode,
          'text-xs 2xl:text-base': !isPdfMode,
        })}
      >
        {name && `${name} - `}
        {metric} {unit || ''}
      </li>
    );
  }

  return (
    <li
      className={classNames({
        'text-base': isPdfMode,
        'text-xs 2xl:text-base': !isPdfMode,
      })}
    >
      {name && `${name} - `}
      {metric && metric.length !== 0 ? `${metric} ${unit || ''}` : 'N.C.'}
    </li>
  );
};

export default TravelTimeItem;
