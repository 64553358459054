import React, { memo } from 'react';
import { uniqueId } from 'lodash';
import MsBox from './MsBox';
import classnames from 'classnames';
import LoadingSection from './LoadingSection';

const MsBoxesContainer = ({ isPdfRenderMode = false, boxes = [] }) => {
  if (!boxes.length) return <LoadingSection />;

  return (
    <div
      className={classnames(
        {
          'sm:grid-cols-4': boxes.length > 3,
          'sm:grid-cols-2': boxes.length < 3,
          'grid-rows-3 gap-4': isPdfRenderMode,
          'sm:grid-rows-2 gap-4': !isPdfRenderMode,
        },
        'grid grid-cols-3 '
      )}
    >
      {boxes.map((item, index) => {
        if (!item) {
          return <div key={index}></div>;
        }
        return (
          <MsBox
            limitHeightBox={boxes.length === 3}
            key={uniqueId(`box__`)}
            isPdfRenderMode={isPdfRenderMode}
            url={item.url || null}
            dataItem={item}
          />
        );
      })}
    </div>
  );
};

export default memo(MsBoxesContainer);
