import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const SectionTitle = ({ title }) => {
  const { isPdfMode } = useSelector((state) => state.others);

  if (!title) return null;

  return (
    <div>
      <h4
        className={classNames(
          'font-semibold pt-3 pb-2',
          { 'text-xl': isPdfMode },
          { 'text:xs 2xl:text-lg': !isPdfMode }
        )}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  );
};
SectionTitle.propTypes = { title: PropTypes.string || null };
export default SectionTitle;
