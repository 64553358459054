import Api, { addAutorization, params } from '../../api/Api';
import axios from 'axios';

const CancelToken = axios.CancelToken;

export let cancel;

export const API_TEST_GET_SUCCESSFUL = 'parcelApi/API_TEST_GET_SUCCESSFUL';
export const API_TEST_GET_FAILED = 'parcelApi/API_TEST_GET_FAILEDL';
export const GET_PARCEL_ID_SUCCESSFUL = 'parcelApi/GET_PARCEL_ID_SUCCESSFUL';
export const GET_PARCEL_ON_MAP_SUCCESSFUL =
  'parcelApi/GET_PARCEL_ON_MAP_SUCCESSFUL';
export const GET_PARCEL_DATA_SUCCESSFUL =
  'parcelApi/GET_PARCEL_DATA_SUCCESSFUL';
export const GET_INSEE_SUCCESSFUL = 'parcelApi/GET_INSEE_SUCCESSFUL';
export const GET_QPV_SUCCESSFUL = 'parcelApi/GET_PARCEL_QPV_SUCCESSFUL';
export const GET_EOLIENNE_SUCCESSFUL =
  'parcelApi/GET_PARCEL_EOLIENNE_SUCCESSFUL';
export const GET_SNCF_SUCCESSFUL = 'parcelApi/GET_PARCEL_SNCF_SUCCESSFUL';
export const GET_PARCEL_RISK_SUCCESSFUL =
  'parcelApi/GET_PARCEL_RISK_SUCCESSFUL';
export const GET_PARCEL_EXPOSITION_SUCCESSFUL =
  'parcelApi/GET_PARCEL_EXPOSITION_SUCCESSFUL';
export const GET_PARCEL_ELECTRINE_SUCCESSFUL =
  'parcelApi/GET_PARCEL_ELECTRINE_SUCCESSFUL';
export const GET_PARCEL_BATIMENT_SUCCESSFUL =
  'parcelApi/GET_PARCEL_BATIMENT_SUCCESSFUL';
export const GET_PARCEL_HOUSING_STATS_SUCCESSFUL =
  'parcelApi/GET_PARCEL_HOUSING_STATS_SUCCESSFUL';
export const GET_PARCEL_HOUSING_SUCCESSFUL =
  'parcelApi/GET_PARCEL_HOUSING_SUCCESSFUL';
export const GET_PARCEL_COLLECTIVE_SUCCESSFUL =
  'parcelApi/GET_PARCEL_COLLECTIVE_SUCCESSFUL';
export const GET_PARCEL_AOC_SUCCESSFUL = 'parcelApi/GET_PARCEL_AOC_SUCCESSFUL';
export const GET_PARCEL_TOURISM_SUCCESSFUL =
  'parcelApi/GET_PARCEL_TOURISM_SUCCESSFUL';
export const GET_PARCEL_TOWN_HEART_SUCCESSFUL =
  'parcelApi/GET_PARCEL_TOWN_HEART_SUCCESSFUL';
export const GET_PARCEL_ZUS_SUCCESSFUL = 'parcelApi/GET_PARCEL_ZUS_SUCCESSFUL';
export const GET_PARCEL_POPULATION_SUCCESSFUL =
  'parcelApi/GET_PARCEL_POPULATION_SUCCESSFUL';
export const GET_MY_RATINGS_SUCCESSFULL =
  'parcelApi/GET_MY_RATINGS_SUCCESSFULL';
export const GET_MY_TARGETS_SUCCESSFULL =
  'parcelApi/GET_MY_TARGETS_SUCCESSFULL';
export const GET_JOURNEY_SUCCESSFUL = 'parcelApi/GET_JOURNEY_SUCCESSFUL';
export const GET_PC_DATA_SUCCESSFUL = 'parcelApi/GET_PC_DATA_SUCCESSFUL';
export const GET_LOGEMENTS_DISPONIBLES_SUCCESSFUL =
  'parcelApi/GET_LOGEMENTS_DISPONIBLES_SUCCESSFUL';
export const GET_ANRU_SUCCESSFUL = 'parcelApi/GET_ANRU_SUCCESSFUL';
export const GET_PLU_SUCCESSFUL = 'parcelApi/GET_PLU_SUCCESSFUL';
export const GET_EXPANDED_CONSTRUCTION_DATA_SUCCESSFUL =
  'parcelApi/GET_EXPANDED_CONSTRUCTION_DATA_SUCCESSFUL';
export const GET_PERSON_SUCCESSFUL = 'parcelApi/GET_PERSON_SUCCESSFUL';
export const GET_MY_PROJECTS_SUCCESSFULL =
  'parcelApi/GET_MY_PROJECTS_SUCCESSFULL';
export const GET_RATING_ID_SUCCESSFUL = 'parcelApi/GET_RATING_ID_SUCCESSFUL';
export const GET_SIZE_LAND_SUCCESSFUL = 'parcelApi/GET_SIZE_LAND_SUCCESSFUL';
export const GET_MAYOR_INFORMATION_SUCCESSFUL =
  'parcelApi/GET_MAYOR_INFORMATION_SUCCESSFUL';
export const GET_HOUSING_SHORTCOMING_SUCCESSFULL =
  'parcelApi/GET_HOUSING_SHORTCOMING_SUCCESSFULL';
export const GET_PINEL_SUCCESSFULL = 'parcelApi/GET_PINEL_SUCCESSFULL';
export const GET_CHANGING_MAYOR_SUCCESSFULL =
  'parcelApi/GET_CHANGING_MAYOR_SUCCESSFULL';
export const GET_MY_RATING_SUCCESSFULL = 'parcelApi/GET_MY_RATING_SUCCESSFULL';
export const DELETE_PARCEL_ID_SUCCESFULL =
  'parcelApi/DELETE_PARCEL_ID_SUCCESFULL';
export const GET_EXPANDED_STATS_NEW_LOGEMENT =
  'parcelApi/GET_EXPANDED_STATS_NEW_LOGEMENT';
export const GET_PARCEL_RISK_CARTOGRAPHY_SUCCESSFUL =
  'parcelApi/GET_PARCEL_RISK_CARTOGRAPHY_SUCCESSFUL';
export const GET_PARCEL_COLLECTIVE_CARTOGRAPHY_SUCCESSFUL =
  'parcelApi/GET_PARCEL_COLLECTIVE_CARTOGRAPHY_SUCCESSFUL';
export const GET_PARCEL_GEOMETRY_SUCCESSFUL =
  'parcelApi/GET_PARCEL_GEOMETRY_SUCCESSFUL';
export const GET_PARCEL_ID_REMOVE_SUCCESSFUL =
  'parcelApi/GET_PARCEL_ID_REMOVE_SUCCESSFUL';
export const RESET_PARCEL_GEOMETRY = 'parcelApi/RESET_PARCEL_GEOMETRY';
export const RESET_RATING_SUCCESSFULL = 'parcelApi/RESET_RATING_SUCCESSFULL';
export const IS_LOADING = 'parcelApi/IS_LOADING';
export const ISNT_LOADING = 'parcelApi/ISNT_LOADING';
export const STOP_MY_RATING_SUCCESSFULL =
  'parcelApi/STOP_MY_RATING_SUCCESSFULL';
export const GET_MY_PROJECT_SUCCESSFULL =
  'parcelApi/GET_MY_PROJECT_SUCCESSFULL';

const initialState = {
  ratings: null,
  targets: {
    autocomplete: null,
    geometry_parcels: [],
  },
  rating: {
    autocomplete: null,
    parcelId: null,
    dataParcel: null,
    insee: null,
    parcelMap: null,
    inseeCode: null,
    pinel: { data: null, cartography: null },
    mayor: null,
    collective: { data: null, cartography: null },
    mayor_changed: null,
    pc: { data: null, cartography: null },
    plu: null,
    qpv: {
      data: null,
      cartography: null,
    },
    anru: { data: null, cartography: null },
    sncf: { data: null, cartography: null },
    logement: {
      data: null,
      cartography: null,
    },
    // test: {data: {titre_box: "test", metric: 1, type_box: "BoxSimple1", bas_de_box: 'test', axe_analyse: "Marché Immobilier", sous_axe: "Logement Neuf"}},
    // test2: {data: {titre_box: "test2", metric: 1, type_box: "BoxSimple1", bas_de_box: 'test', axe_analyse: "Marché Immobilier", sous_axe: "Logement Neuf"}},
    // test3: {data: {titre_box: "test3", metric: 1, type_box: "BoxSimple1", bas_de_box: 'test', axe_analyse: "Marché Immobilier", sous_axe: "Logement Neuf"}},
    // test4: {data: {titre_box: "test4", metric: 1, type_box: "BoxSimple1", bas_de_box: 'test', axe_analyse: "Marché Immobilier", sous_axe: "Logement Neuf"}},
    // test5: {data: {titre_box: "test5", metric: 1, type_box: "BoxSimple1", bas_de_box: 'test', axe_analyse: "Marché Immobilier", sous_axe: "Logement Neuf"}},
    eolienne: {
      data: null,
      cartography: null,
    },
    journey: null,
    geometry_parcels: [],
    rating_id: null,
    construction: { data: null, cartography: null },
    population: { data: null, cartography: null },
    logements_disponibles: null,
  },
  my_rating: null,
  target: {
    parcel: null,
    data: null,
    subdata: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case API_TEST_GET_SUCCESSFUL:
      return {
        target: {
          ...state.target,
          autocomplete: action.data,
        },
        rating: {
          ...state.rating,
          autocomplete: action.data,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_AOC_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          aoc: action.aoc,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_EXPANDED_STATS_NEW_LOGEMENT:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          logement: {
            data: action.logement_data,
            cartography: action.logement_carto,
          },
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_LOGEMENTS_DISPONIBLES_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          logements_disponibles: {
            data: action.logements_disponibles,
          },
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_MY_PROJECT_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
        my_project: action.project,
      };

    case GET_PARCEL_GEOMETRY_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          geometry_parcels: action.geometryParcels,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
        my_project: state.my_project,
      };

    case RESET_PARCEL_GEOMETRY:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          geometry_parcels: [],
        },
        targets: {
          ...state.ratings,
          geometry_parcels: [],
        },
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_COLLECTIVE_CARTOGRAPHY_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          collective: {
            data: state.rating.collective.data,
            cartography: action.collective_cartography,
          },
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_HOUSING_STATS_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          housing_stats: action.housingStats,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_TOWN_HEART_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          TownHeart: action.TownHeart,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_RISK_CARTOGRAPHY_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          risk_cartography: action.risk_cartography,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_ELECTRINE_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          electric: action.electric,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_ZUS_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          zus: action.zus,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_HOUSING_SHORTCOMING_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          housing: action.housing,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_BATIMENT_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          batiment: action.batiment,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_COLLECTIVE_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          collective: {
            data: action.collective,
            cartography: state.rating.collective.cartography,
          },
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case DELETE_PARCEL_ID_SUCCESFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          rating_id: null,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PINEL_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          pinel: action.pinel,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_MY_RATING_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: action.rating,
      };

    case RESET_RATING_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: null,
      };

    case GET_CHANGING_MAYOR_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          mayor_changed: action.mayor_changed,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_SIZE_LAND_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          size: action.size,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_MAYOR_INFORMATION_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          mayor: action.mayor,
        },
        targets: state.ratings,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_ID_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          parcelId: action.parcelId,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_JOURNEY_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          journey: action.journey,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_MY_PROJECTS_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
        },
        projects: action.projects,
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_POPULATION_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          population: {
            data: action.population_data,
            cartography: action.population_carto,
          },
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PC_DATA_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          pc: { data: action.pc_data, cartography: action.pc_carto },
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_ANRU_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          anru: {
            data: action.anru_data,
            cartography: action.anru_carto,
          },
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PLU_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          plu: action.plu,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_DATA_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          dataParcel: action.dataParcel,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_INSEE_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          insee: action.insee,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_RATING_ID_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          rating_id: action.rating_id,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_ID_REMOVE_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          parcelId: null,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PARCEL_RISK_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          risk: action.risk,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_EXPANDED_CONSTRUCTION_DATA_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          construction: {
            data: action.construction_data,
            cartography: action.construction_carto,
          },
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_EOLIENNE_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          eolienne: {
            data: action.eolienne_data,
            cartography: action.eolienne_carto,
          },
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_QPV_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          qpv: {
            data: action.qpv_data,
            cartography: action.qpv_carto,
          },
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_SNCF_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          sncf: { data: action.sncf_data, cartography: action.sncf_carto },
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_PERSON_SUCCESSFUL:
      return {
        target: { ...state.target },
        rating: {
          ...state.rating,
          person: action.person,
        },
        targets: state.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
      };

    case GET_MY_RATINGS_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: { ...state.rating },
        ratings: action.ratings,
        targets: action.targets,
        my_rating: state.my_rating,
        projects: state.projects,
      };

    case GET_MY_TARGETS_SUCCESSFULL:
      return {
        target: { ...state.target },
        rating: { ...state.rating },
        targets: action.targets,
        ratings: state.ratings,
        my_rating: state.my_rating,
        projects: state.projects,
      };

    case API_TEST_GET_FAILED:
      return {};

    default:
      return state;
  }
};

let config = {
  baseURL: process.env.API_GEO_URL,
  headers: {
    Accept: 'application/json',
  },
};

let geoApi = axios.create(config);

export const getAutocomplete = (value) => async () => {
  let parsedValue = value.replace(/\s/g, '+');
  return geoApi.get(parsedValue, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

// export const isLoading = () => async (dispatch) => {
//   return dispatch({
//     type: IS_LOADING,
//   });
// };

// export const isntLoading = () => async (dispatch) => {
//   return dispatch({
//     type: ISNT_LOADING,
//   });
// };

export const getExpandedStatsNewLogement =
  (inseeCode, data) => async (dispatch) => {
    return axios
      .get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${inseeCode}/prix_moyen_tva_normale/carto`,
        {
          params: params,
        }
      )
      .then((res) => {
        let dataSpec = [data].concat([res.data]);
        dispatch({
          type: GET_EXPANDED_STATS_NEW_LOGEMENT,
          logement_data: data,
          logement_carto: res.data,
        });
      });
  };

export const getStatsNewLogement = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${inseeCode}/prix_moyen_tva_normale`,
      {
        params: params,
      }
    )
    .then((res) => {
      let data = res.data;
      dispatch(getExpandedStatsNewLogement(inseeCode, data));
    });
};

export const getMultipleLatLngForTargetsParcels =
  (parcels) => async (dispatch) => {
    const geometryParcels = [];
    for await (let v of parcels) {
      await axios
        .get(`${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${v}/geo`, {
          params: params,
        })
        .then((res) => {
          geometryParcels.push(res.data.parcel?.geometry?.coordinates[0]);
        });
    }
    dispatch({
      type: GET_PARCEL_GEOMETRY_SUCCESSFUL,
      geometryParcels: geometryParcels,
    });
  };

export const resetGeometry = () => async (dispatch) => {
  return dispatch({
    type: RESET_PARCEL_GEOMETRY,
  });
};

export const getExpandedDataSpecificParcel = (id, data) => async (dispatch) => {
  return axios
    .get(`${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${id}/geo`, {
      params: params,
    })
    .then((res) => {
      let dataSpec = [data].concat([res.data]);
      const expandedData = {
        data: dataSpec[0].data,
        parcels: dataSpec[1].parcel,
        touching_parcels: dataSpec[1].touching_parcels,
      };
      dispatch({
        type: GET_PARCEL_DATA_SUCCESSFUL,
        dataParcel: expandedData,
      });
    });
};

export const getDataSpecificParcel = (id) => async (dispatch) => {
  return axios
    .get(`${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${id}/`, {
      params: params,
    })
    .then((res) => {
      let data = res.data;
      dispatch(getExpandedDataSpecificParcel(id, data));
    });
};

export const postRating = (data) => async (dispatch) => {
  return Api.post('/ratings.json', data, {
    params: params,
  });
};

// export const updateRating = (id, data) => async (dispatch) => {
//     return Api.put(`/ratings/${id}`, data, {
//         params: params,
//     }).then((res) => {
//         // console.log(res);
//     });
// };

export const getExpandedPC = (inseeCode, data) => async (dispatch) => {
  return Api.get(
    `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/nombre_programmes_commercialises/carto`,
    {
      params: params,
    }
  ).then((res) => {
    dispatch({
      type: GET_PC_DATA_SUCCESSFUL,
      pc_data: data,
      pc_carto: res.data,
    });
  });
};

export const getChangingMayor = (inseeCode) => async (dispatch) => {
  return Api.get(
    `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/changing_mayor`,
    {
      params: params,
    }
  ).then((res) => {
    dispatch({
      type: GET_CHANGING_MAYOR_SUCCESSFULL,
      mayor_changed: res.data,
    });
  });
};

export const getPC = (inseeCode) => async (dispatch) => {
  return Api.get(
    `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/nombre_programmes_commercialises`,
    {
      params: params,
    }
  ).then((res) => {
    dispatch(getExpandedPC(inseeCode, res.data));
  });
};

export const getParcelOnMap = (number) => async (dispatch) => {
  const number = 69387;
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/zones_ciblages?category=communes&national_code=${number}`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_ID_SUCCESSFUL,
        parcelMap: res.data,
      });
    });
};

export const deleteParcelId = () => async (dispatch) => {
  dispatch({
    type: DELETE_PARCEL_ID_SUCCESFULL,
  });
};

export const getHousingShortComing = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/social_housing/${inseeCode}/social_housing_shortcoming`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_HOUSING_SHORTCOMING_SUCCESSFULL,
        housing: res.data,
      });
    });
};

export const getSizeLand =
  (selectedParcels, baseParcel) => async (dispatch) => {
    let dataParsed = selectedParcels.map((v) => 'parcelle_ids[]=' + v + '&');
    dataParsed.unshift('parcelle_ids[]=' + baseParcel + '&');
    let url = `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/taille_terrain?`;
    let constructUrl = url + dataParsed.join('').slice(0, -1);

    return axios.get(constructUrl, { params: params }).then((res) => {
      dispatch({
        type: GET_SIZE_LAND_SUCCESSFUL,
        size: res.data,
      });
    });
  };

export const getMayorInformation = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/mayor`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_MAYOR_INFORMATION_SUCCESSFUL,
        mayor: res.data,
      });
    });
};

export const getLogementsDisponibles = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${inseeCode}/nombre_logements_disponibles`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_LOGEMENTS_DISPONIBLES_SUCCESSFUL,
        logements_disponibles: res.data,
        // dispatch carto request ?
        //dispatch(writeCorrectCartoIci(inseeCode, data));
      });
    });
};

export const getEolienneDetailled = (parcelId, data) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/nuisances/${parcelId}/eoliennes/carto`,
      { params: params }
    )
    .then((res) => {
      let dataSpec = [data].concat([res.data]);
      dispatch({
        type: GET_EOLIENNE_SUCCESSFUL,
        eolienne_data: data,
        eolienne_carto: res.data,
      });
    });
};

export const getJourney = (url) => async (dispatch) => {
  addAutorization(process.env.API_JOURNEY_KEY);
  return Api.get(url)
    .then((res) => {
      dispatch({
        type: GET_JOURNEY_SUCCESSFUL,
        journey: res.data,
      });
    })
    .catch();
};

export const getRating = (id) => async (dispatch) => {
  return axios.get(`/ratings/${id}.json`, { params: params }).then((res) => {
    dispatch({
      type: GET_MY_RATING_SUCCESSFULL,
      rating: res.data,
    });
  });
};

export const getProject = (uuid) => async (dispatch) => {
  return axios.get(`/projects/${uuid}.json`, { params: params }).then((res) => {
    dispatch({
      type: GET_MY_PROJECT_SUCCESSFULL,
      project: res.data,
    });
  });
};

export const resetRating = () => async (dispatch) => {
  return dispatch({
    type: RESET_RATING_SUCCESSFULL,
  });
};

export const getMyRatings = () => async (dispatch) => {
  return axios.get('/ratings.json', { params: params }).then((res) => {
    dispatch({
      type: GET_MY_RATINGS_SUCCESSFULL,
      ratings: res.data,
    });
  });
};

export const getMyProjects = () => async (dispatch) => {
  return axios.get('/projects.json', { params: params }).then((res) => {
    dispatch({
      type: GET_MY_PROJECTS_SUCCESSFULL,
      projects: res.data,
    });
  });
};

export const postProjects = (data) => async (dispatch) => {
  return Api.post('/projects.json', data, {
    params: params,
  }).then((res) => {});
};

export const getMyTargets = () => async (dispatch) => {
  return axios.get('/targets.json', { params: params }).then((res) => {
    dispatch({
      type: GET_MY_TARGETS_SUCCESSFULL,
      targets: res.data,
    });
  });
};

export const stopMyRating = (id) => async (dispatch) => {
  return axios
    .get(`/ratings/stop_rating/${id}.json`, { params: params })
    .then(() => {
      dispatch(getMyRatings());
      // dispatch({
      //   type: STOP_MY_RATING_SUCCESSFULL,
      // });
    });
};

export const getEolienne = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/nuisances/${parcelId}/eoliennes`,
      { params: params }
    )
    .then((res) => {
      dispatch(getEolienneDetailled(parcelId, res.data));
    });
};

export const getQPVDetailled = (inseeCode, data) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/qpv`,
      { params: params }
    )
    .then((res) => {
      let dataSpec = [data].concat([res.data]);
      dispatch({
        type: GET_QPV_SUCCESSFUL,
        qpv_data: res.data,
        qpv_carto: data,
      });
    });
};

export const getQPV = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/qpv/carto`,
      { params: params }
    )
    .then((res) => {
      dispatch(getQPVDetailled(inseeCode, res.data));
    });
};

export const getPinel = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/zone_pinel`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PINEL_SUCCESSFULL,
        pinel: res.data,
      });
    });
};

export const getAnruDetailled =
  (parcels, data, baseParcel) => async (dispatch) => {
    let url = `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/zone_anru/carto?`;
    let constructUrl = url + parcels.join('').slice(0, -1);
    return axios.get(constructUrl, { params: params }).then((res) => {
      let dataSpec = [data].concat([res.data]);
      dispatch({
        type: GET_ANRU_SUCCESSFUL,
        anru_data: data,
        anru_carto: res.data,
      });
    });
  };

export const getPerson = (selectedParcels, baseParcel) => async (dispatch) => {
  let dataParsed = selectedParcels.map((v) => 'parcelle_ids[]=' + v + '&');
  dataParsed.unshift('parcelle_ids[]=' + baseParcel + '&');
  let url = `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/proprietaire_personne_morale?`;
  let constructUrl = url + dataParsed.join('').slice(0, -1);

  return axios.get(constructUrl, { params: params }).then((res) => {
    dispatch({
      type: GET_PERSON_SUCCESSFUL,
      person: res.data,
    });
  });
};

export const getAnru = (selectedParcels, baseParcel) => async (dispatch) => {
  let dataParsed = selectedParcels.map((v) => 'parcelle_ids[]=' + v + '&');
  dataParsed.unshift('parcelle_ids[]=' + baseParcel + '&');
  let url = `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/zone_anru?`;
  let constructUrl = url + dataParsed.join('').slice(0, -1);

  return axios.get(constructUrl, { params: params }).then((res) => {
    dispatch(getAnruDetailled(dataParsed, res.data, baseParcel));
  });
};
export const getPlu = (selectedParcels, baseParcel) => async (dispatch) => {
  let dataParsed = selectedParcels.map((v) => 'parcelle_ids[]=' + v + '&');
  dataParsed.unshift('parcelle_ids[]=' + baseParcel + '&');
  let url = `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/plu?`;
  let constructUrl = url + dataParsed.join('').slice(0, -1);
  return axios.get(constructUrl, { params: params }).then((res) => {
    dispatch({
      type: GET_PLU_SUCCESSFUL,
      plu: res.data,
    });
  });
};

export const getSncfTrajectDetailled = (parcelId, data) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/accessibility/${parcelId}/gare_nationale/carto`,
      { params: params }
    )
    .then((res) => {
      let dataSpec = [data].concat([res.data]);
      dispatch({
        type: GET_SNCF_SUCCESSFUL,
        sncf_data: data,
        sncf_carto: res.data,
      });
    });
};

export const getRisk = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/quick_check/${parcelId}/risks`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_RISK_SUCCESSFUL,
        risk: res.data,
      });
    });
};

export const getRiskCartography = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/nuisances/${parcelId}/installation_a_risque/carto`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_RISK_CARTOGRAPHY_SUCCESSFUL,
        risk_cartography: res.data,
      });
    });
};

export const getExpositionPlan = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/nuisances/${parcelId}/plan_exposition_bruit_aeroports`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_EXPOSITION_SUCCESSFUL,
        expositionPlan: res.data,
      });
    });
};

export const getElectricLine = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/nuisances/${parcelId}/lignes_electriques_haute_tension`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_ELECTRINE_SUCCESSFUL,
        electric: res.data,
      });
    });
};

export const getExpandedConstruction =
  (parcels, data, baseParcel) => async (dispatch) => {
    let url = `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/bati/carto?`;
    let constructUrl = url + parcels.join('').slice(0, -1);
    return Api.get(constructUrl, {
      params: params,
    }).then((res) => {
      dispatch({
        type: GET_EXPANDED_CONSTRUCTION_DATA_SUCCESSFUL,
        construction_data: data,
        construction_carto: res.data,
      });
    });
  };

export const getConstructionBatiment =
  (data, baseParcel) => async (dispatch) => {
    let dataParsed = data.map((v) => 'parcelle_ids[]=' + v + '&');
    dataParsed.unshift('parcelle_ids[]=' + baseParcel + '&');
    let url = `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/bati?`;
    let constructUrl = url + dataParsed.join('').slice(0, -1);

    return Api.get(constructUrl, {
      params: params,
    }).then((res) => {
      dispatch(getExpandedConstruction(dataParsed, res.data, baseParcel));
    });
  };

export const getBatiment = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/quick_check/${parcelId}/bati`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_BATIMENT_SUCCESSFUL,
        batiment: res.data,
      });
    });
};

export const getHousingStat = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/social_housing/${inseeCode}/social_housing_stats`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_HOUSING_STATS_SUCCESSFUL,
        housingStats: res.data,
      });
    });
};

export const getCollectiveStatsCartography =
  (inseeCode) => async (dispatch) => {
    return axios
      .get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/social_housing/${inseeCode}/collective_housing_share`,
        { params: params }
      )
      .then((res) => {
        dispatch({
          type: GET_PARCEL_COLLECTIVE_SUCCESSFUL,
          collective: res.data,
        });
      });
  };

export const getCollectiveStats = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/social_housing/${inseeCode}/collective_housing_share/carto`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_COLLECTIVE_CARTOGRAPHY_SUCCESSFUL,
        collective_cartography: res.data,
      });
    });
};

export const getAOC = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/aop_aoc`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_AOC_SUCCESSFUL,
        aoc: res.data,
      });
    });
};

export const getTourism = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/tourism_capacities`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_TOURISM_SUCCESSFUL,
        tourism: res.data,
      });
    });
};

export const getTownHeart = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/coeur_de_ville`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_TOWN_HEART_SUCCESSFUL,
        TownHeart: res.data,
      });
    });
};

export const getZus = (inseeCode) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/zus`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_ZUS_SUCCESSFUL,
        zus: res.data,
      });
    });
};

export const getExpandedPopulation =
  (inseeCode, data, type, years_range) => async (dispatch) => {
    return Api.get(
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/territoire/${inseeCode}/evolution_population/carto?metric=${
        !type ? 'population' : type
      }&years_range=${years_range ? years_range : '2018-2018'}`,
      {
        params: params,
      }
    ).then((res) => {
      dispatch({
        type: GET_PARCEL_POPULATION_SUCCESSFUL,
        population_data: data,
        population_carto: res.data,
      });
    });
  };

export const getPopulation =
  (inseeCode, type, years_range) => async (dispatch) => {
    return axios
      .get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${inseeCode}/evolution_population`,
        { params: params }
      )
      .then((res) => {
        dispatch(getExpandedPopulation(inseeCode, res.data, type, years_range));
      });
  };

export const getSncfTraject = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/accessibility/${parcelId}/gare_nationale`,
      { params: params }
    )
    .then((res) => {
      dispatch(getSncfTrajectDetailled(parcelId, res.data));
    });
};

export const getParcel = (lat, lng) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/by_lon_lat?lat=${lng}&lon=${lat}`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_PARCEL_ID_SUCCESSFUL,
        parcelId: res.data.properties.parcelle_id,
      });
    });
};

export const removeParcelId = () => async (dispatch) => {
  return dispatch({
    type: GET_PARCEL_ID_REMOVE_SUCCESSFUL,
  });
};

export const getInseeCode = (parcelId) => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${parcelId}/national_codes_communes`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_INSEE_SUCCESSFUL,
        insee: res.data,
      });
    });
};

export const disconnectUser = (data) => async (dispatch) => {
  return Api.delete('/users/sign_out.json', data, { params: params }).then(
    (res) => {
      window.location.href = `${process.env.BASE_URL}/users/sign_in`;
      dispatch({
        type: API_TEST_GET_SUCCESSFUL,
      });
    }
  );
};

export const signUpUser = (data) => async (dispatch) => {
  return Api.post('/users/sign_in.json', data, { params: params }).then(
    (res) => {
      window.location.href = process.env.BASE_URL;
      dispatch({
        type: API_TEST_GET_SUCCESSFUL,
      });
    }
  );
};

export const registerUser = (informations) => async (_dispatch) => {
  return Api.post(`/users.json`, informations, { params: params }).then(() => {
    window.location.href = process.env.BASE_URL + '/users/sign_in';
  });
};

export const registerUserCapem = (informations) => {
  return Api.post(`/api/v1/capem/users/sign_up`, informations).then((res) => {
    window.location.href = process.env.BASE_URL + '/users/sign_in';
  });
};
