import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { usePrograms } from '../../hooks/usePrograms';
import { useDispatch, useSelector } from 'react-redux';
import { TableV2 as Table } from '../molecules/table/Table';
import moment from 'moment';
import { orderBy } from 'lodash';
import Checkbox from 'react-custom-checkbox';
import { CheckIcon } from '@heroicons/react/solid';
import {
  getDetailsProgramme,
  programmeSliceActions,
} from '../../store/slices/programme';
import useDebounce from '../../_helpers/useDebounce';
import {
  disableZoom,
  enableZoom,
} from '../molecules/DataVisualisations/Layers/MarketSurveysLayer';
import programOverSince2019 from '../../../../assets/images/icons/programme-immobilier-termine-2019.svg';
import programInProgress from '../../../../assets/images/icons/programme_en_cours.svg';
import useMsMap from '../../hooks/useMSMap';

/*
interface Column {
  label?: string;
  key?: string;
  sortable?: boolean;
  render?: (rowData: any) => ReactNode;
}
*/

const columnsTable = (handleClickItem, selectedItems) => [
  {
    label: 'Programme',
    key: 'programmeName',
    sortable: true,
    render: (rowData) => (
      <div className="flex items-center gap-x-2">
        <span
          onClick={(e) => e.stopPropagation()}
          className="cursor-pointer checkbox-program"
        >
          <Checkbox
            icon={<CheckIcon className="w-4 h-4" />}
            name="my-input"
            checked={selectedItems?.includes(rowData.tran_id)}
            onChange={() => {
              handleClickItem && handleClickItem(rowData.tran_id);
            }}
            size={24}
            borderColor="#1AA3E8"
            labelStyle={{ marginLeft: 5, userSelect: 'none' }}
          />
        </span>
        <span className="flex items-center gap-x-2">
          <span style={{ minHeight: 24, minWidth: 24, height: 24, width: 24 }}>
            {rowData.date_fin_commercialisation === null ? (
              <img src={programInProgress} className="h-8 w-8" alt="old" />
            ) : (
              <img src={programOverSince2019} className="h-8 w-8" alt="new" />
            )}
          </span>
          <span className="text-xs 2xl:text-sm">{rowData?.programmeName}</span>
        </span>
      </div>
    ),
  },
  {
    label: 'Commune',
    key: 'commune',
    sortable: true,
    render: (rowData) => (
      <div className="text-xs 2xl:text-sm">{rowData.commune}</div>
    ),
  },
  {
    label: 'Promoteur',
    key: 'promoteur',
    sortable: true,
    render: (rowData) => (
      <div className="text-xs 2xl:text-sm">{rowData.promoteur}</div>
    ),
  },
  {
    label: 'Date MEV',
    key: 'dateMev',
    sortable: true,
    render: (rowData) => (
      <div className="whitespace-nowrap text-center text-xs 2xl:text-sm">
        {moment(rowData.dateMev).format('DD-MM-YYYY')}
      </div>
    ),
  },
  {
    label: 'Logt. MEV',
    key: 'NbLogMisEnVente',
    sortable: true,
    render: (rowData) => (
      <div className="text-center text-xs 2xl:text-sm">
        {rowData.NbLogMisEnVente}
      </div>
    ),
  },
  {
    label: 'Prix MEV',
    key: 'pmmv',
    sortable: true,
    render: (rowData) => (
      <div className="text-center text-xs 2xl:text-sm">{rowData.pmmv} €</div>
    ),
  },
  {
    label: 'Rythme Résas',
    key: 'rythme_moyen_resas',
    sortable: true,
    render: (rowData) => (
      <div className="text-center text-xs 2xl:text-sm">
        {rowData.rythme_moyen_resas}
      </div>
    ),
  },
];

export const transformDataProgrammes = (programmes, isFromCarto = false) => {
  return programmes.map(
    ({
      number,
      [isFromCarto ? 'properties' : 'attributes']: {
        nom_programme,
        promoteur,
        nb_logt_mis_en_vente,
        date_debut_commercialisation,
        pmmv,
        avg_price_m2_toutes_tva,
        tran_id,
        date_livraison,
        date_fin_commercialisation,
        commune,
        rythme_moyen_resas,
      },
    }) => ({
      programmeName: nom_programme,
      promoteur,
      dateMev: new Date(date_debut_commercialisation),
      NbLogMisEnVente: nb_logt_mis_en_vente,
      avg_price_m2_toutes_tva,
      pmmv,
      tran_id,
      date_livraison,
      date_fin_commercialisation,
      commune,
      rythme_moyen_resas,
      number,
    })
  );
};

export const setInView = (currentTranId) => {
  const el = document.getElementById(`tr_${currentTranId}`);
  el?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
};

const ProgramsTable = forwardRef(
  (
    {
      loading,
      onChangeSelectedProgrammes,
      setCurrentProgrammePopupData,
      currentProgrammePopupData,
      onRemoveProgram,
      setLoading,
      refMapData,
    },
    ref
  ) => {
    const {
      programmes,
      programmesCarto,
      selectedProgrammes,
      selectedProgrammesCarto,
    } = useSelector((state) => state.programme);
    const {
      programsRefs,
      setPrograms,
      setActivePrograms,
      activePrograms,
      currentTranId,
      setCurrentTranId,
      changedActiveProgrammes,
      closedProgrammeId,
      setDetailsProgrammeContext,
    } = usePrograms();

    const [unselectedProgrammes, setUnselectProgrammes] = useState([]);
    const [sorting, setSorting] = useState({
      key: 'programmeName',
      value: 'asc',
    });
    const [inInitList, setInInitList] = useState(false);
    const [clickFromTable, setClickFromTable] = useState(false);
    const { programmeLayerRef } = usePrograms();
    const [currentIdTr, setCurrentIdTr] = useState(null);
    const [openPopupProgramme, setOpenPopupProgramme] = useState(true);
    const dispatch = useDispatch();
    const { setCurrentHoverTrandId } = useMsMap();

    useImperativeHandle(ref, () => ({
      clickItem(tran_id, map) {
        handleClickCheckBox(tran_id, map);
      },
      close() {
        refMapData.current?.openPopupProgramme(null);
        setCurrentProgrammePopupData(null);
      },
    }));

    useEffect(() => {
      if (currentTranId) {
        const marker = programsRefs.find(
          (item) => item.tran_id === currentTranId
        );

        if (marker) {
          marker.ref.current?.openPopup();
        }

        if (!clickFromTable) {
          setInView(currentTranId);
        }
        setCurrentIdTr(currentTranId);
      } else {
        setCurrentIdTr(null);
      }

      setClickFromTable(false);
    }, [currentTranId, programsRefs]);

    useEffect(() => {
      const execFunc = () => {
        // we update the programs state in context (useProgram)
        setPrograms(programmes);

        const dataBrut = transformDataProgrammes(programmes);

        let ordered = orderBy(
          dataBrut,
          [
            (item) => {
              // If the sorting key is 'pmmv', 'NbLogMisEnVente', or 'rythme_moyen_resas',
              // strip spaces and parse it as a number
              if (['pmmv', 'NbLogMisEnVente', 'rythme_moyen_resas'].includes(sorting.key)) {
                const value = item[sorting.key];
                if (typeof value === 'string') {
                  return Number(value.replace(/\s+/g, ''));
                }
                return value != null ? Number(value) : 0;
              }
              // Otherwise, return the item as is for sorting
              return item[sorting.key];
            },
          ],
          [sorting.value]
        );

        // remove selected programmes from list
        if (activePrograms.length) {
          ordered = ordered.filter(
            (item) => !activePrograms.includes(item.tran_id)
          );
        }

        setUnselectProgrammes(ordered);

        // executed in first mount only, we init the carto and list
        if (programmesCarto && programmes && !inInitList) {
          dispatch(
            programmeSliceActions.setSelectedProgrammes(
              programmes.filter((item) =>
                activePrograms.includes(item.attributes.tran_id)
              )
            )
          );
          dispatch(
            programmeSliceActions.setSelectedProgrammesCarto(
              programmesCarto.features.filter((item) =>
                activePrograms.includes(item.properties.tran_id)
              )
            )
          );
          setInInitList(true);
        }

        dispatch(programmeSliceActions.setFilteredProgrammes(ordered));
      };
      setTimeout(() => {
        execFunc();
      }, 500);
    }, [programmes, sorting, activePrograms, programmesCarto]);

    const handleRowClick = async (rowData) => {
      if (programmeLayerRef.current) {
        programmeLayerRef.current.setCurrentPopup(rowData.tran_id, (data) => {
          setCurrentProgrammePopupData(data);
          setOpenPopupProgramme(true);
        });
      }
      // setDetailsProgrammeContext(null);
      setCurrentTranId(rowData.tran_id);
      setClickFromTable(true);

      // const { payload } = await dispatch(getDetailsProgramme(rowData.tran_id));
      // setDetailsProgrammeContext(payload.attributes);
    };

    useEffect(() => {
      if (!loading && currentProgrammePopupData && openPopupProgramme) {
        refMapData.current?.openPopupProgramme(currentProgrammePopupData);
      }
    }, [loading, currentProgrammePopupData, openPopupProgramme]);

    useEffect(() => {
      if (closedProgrammeId && programsRefs.length) {
        const marker = programsRefs.find(
          (item) => item.tran_id === closedProgrammeId
        );
        if (marker) {
          marker?.ref?.current?.openPopup();
          setCurrentIdTr(closedProgrammeId);
          setInView(currentTranId);
        }
      }
    }, [closedProgrammeId, programsRefs]);

    const handleClickCheckBox = (tran_id, map) => {
      const _currentValue = [
        ...activePrograms.filter((id) => id !== tran_id),
        ...(activePrograms.find((id) => id === tran_id) ? [] : [tran_id]),
      ];

      const removeAction = activePrograms.includes(tran_id);

      removeAction && onRemoveProgram && onRemoveProgram(tran_id);

      const current = currentProgrammePopupData;

      setCurrentProgrammePopupData(current);

      if (!removeAction) {
        setLoading(true);
        disableZoom(map);
        setTimeout(() => {
          setLoading(false);
          enableZoom(map);
        }, 750);
        setOpenPopupProgramme(true);
      }

      if (removeAction && !map) {
        setOpenPopupProgramme(false);
      }

      if (removeAction && map) {
        setOpenPopupProgramme(true);
      }

      setActivePrograms(_currentValue);
      handleChangeSelectedProgrammes(_currentValue);
    };

    const handleChangeSelectedProgrammes = (_currentValue) => {
      if (programmesCarto && programmes?.length) {
        const list = [
          ...selectedProgrammes,
          // I filter to remove the selected programmes
          ...programmes.filter(
            (item) =>
              !selectedProgrammes
                .map((el) => el.attributes.tran_id)
                .includes(item.attributes.tran_id)
          ),
        ].filter((item) => _currentValue.includes(item.attributes.tran_id));

        const arrayCarto = [
          ...selectedProgrammesCarto,
          ...programmesCarto.features,
        ].filter((item) => _currentValue.includes(item.properties.tran_id));

        const carto = arrayCarto.filter(
          (item, i) =>
            arrayCarto.findIndex(
              (el) => item.properties.tran_id === el.properties.tran_id
            ) === i
        );

        dispatch(programmeSliceActions.setSelectedProgrammes(list));
        dispatch(programmeSliceActions.setSelectedProgrammesCarto(carto));

        onChangeSelectedProgrammes && onChangeSelectedProgrammes(list);
      }
    };

    useDebounce(() => {
      onChangeSelectedProgrammes &&
        handleChangeSelectedProgrammes(changedActiveProgrammes);
    }, [changedActiveProgrammes]);

    const handleSortingTable = useCallback((sorting) => {
      setSorting(sorting);
    }, []);

    const handleHover = useCallback((tranId) => {
      setCurrentHoverTrandId(tranId);
    }, []);

    return (
      <Table
        idKeyname="tran_id"
        clickedTr={currentIdTr}
        classnameActive="bg-greenMarker"
        columns={columnsTable(handleClickCheckBox, activePrograms)}
        selectedData={orderBy(
          transformDataProgrammes(selectedProgrammes),
          [(item) => {
            if (['pmmv', 'NbLogMisEnVente', 'rythme_moyen_resas'].includes(sorting.key)) {
              const value = item[sorting.key];
              if (typeof value === 'string') {
                return Number(value.replace(/\s+/g, ''));
              }
              return value != null ? Number(value) : 0;
            }
            return item[sorting.key];
          }],
          [sorting.value]
        )}
        onHover={handleHover}
        unselectedData={unselectedProgrammes}
        onRowClick={handleRowClick}
        sorting={sorting}
        onChangeSorting={handleSortingTable}
        selectedRows={(row) => activePrograms.includes(row.tran_id)}
        classNameTd="p-2"
        separated
        loading={loading}
      />
    );
  }
);

export default ProgramsTable;
