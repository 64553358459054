import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import AdminUserLayout from './AdminUserLayout';
import moment from 'moment';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FilterIcon,
  PencilIcon,
  PlusIcon,
  SwitchVerticalIcon,
} from '@heroicons/react/outline';
import ModalForm, {
  idfDepartementsCodes,
  idfOption,
} from '../../components/admin-user/ModalForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccounts,
  getDepartements,
  getOrganizationUser,
  getUsersOrganization,
  saveUserOrganization,
} from '../../store/slices/others';
import { useAuth } from '../../hooks/useAuth';
import { orderBy, uniqBy } from 'lodash';
import classNames from 'classnames';
import CustomAutocomplete from '../../components/molecules/custom-autocomplete/CustomAutocomplete';
import useDebounce from '../../_helpers/useDebounce';

const FilterAccount = ({ onChange, name }) => {
  const { accounts } = useSelector((state) => state.others);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);

  const handleChange = (value) => {
    setValue(value);
    onChange && onChange(value?.label || null, name);
  };

  const mappedAccounts = useMemo(
    () =>
      accounts.map((item) => ({
        key: item.id,
        label: item.attributes.company_name,
      })),
    [accounts]
  );

  useDebounce(() => {
    dispatch(getAccounts({ keyword: inputValue?.length ? inputValue : null }));
  }, [inputValue]);

  return (
    <div className="flex items-center gap-x-2">
      <label>Société</label>
      <div className="filter-auto w-full flex items-center">
        <CustomAutocomplete
          placeholder="Nom de la société"
          keyLabel="label"
          keyValue="key"
          onChange={handleChange}
          onChangeInputValue={(valueInput) => setInputValue(valueInput)}
          options={mappedAccounts}
          value={value}
        />
      </div>
    </div>
  );
};

export const FilterComp = ({ onFilter }) => {
  const btnFilterRef = useRef();
  const dropDownRef = useRef();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    filter_company_name: null,
  });

  const handleClickBtnFilter = (e) => {
    e.stopPropagation();
    setOpen((state) => !state);
  };

  const handleChangeFilter = (value, name) => {
    setFilters((state) => ({ ...state, [name]: value }));
  };

  useEffect(() => {
    if (btnFilterRef.current && dropDownRef.current) {
      dropDownRef.current.style.top = `${
        btnFilterRef.current.clientHeight + 4
      }px`;
    }
    dispatch(getAccounts());
    const closePopup = () => {
      setOpen(false);
    };
    window.addEventListener('click', closePopup);
    return () => window.removeEventListener('click', closePopup);
  }, []);

  useDebounce(() => {
    onFilter && onFilter(filters);
  }, [filters]);

  return (
    <div className="inline-block relative" onClick={(e) => e.stopPropagation()}>
      <div
        ref={btnFilterRef}
        onClick={handleClickBtnFilter}
        className="flex items-center hover:bg-gray-200 border-1 border-gray-400 rounded-lg px-4 py-2 cursor-pointer"
      >
        <FilterIcon className="w-6 h-6" />
        <span className="ml-2">Filtrer par</span>
      </div>
      <div
        ref={dropDownRef}
        style={{ zIndex: 10, width: 400 }}
        className={classNames(
          'absolute right-0 h-auto bg-white shadow-md p-4 rounded-lg',
          { hidden: !open },
          { block: open }
        )}
      >
        <FilterAccount
          onChange={handleChangeFilter}
          name="filter_company_name"
        />
      </div>
    </div>
  );
};

const useColumns = (props) => [
  {
    label: 'uuid',
    key: 'uuid',
    sortable: false,
    render: function (data) {
      return <span>{data['uuid']}</span>;
    },
  },
  {
    label: 'Email',
    key: 'email',
    sortable: true,
    render: function (data) {
      return <span>{data['email']}</span>;
    },
  },
  {
    label: 'Société',
    key: 'account',
    sortable: true,
    render: function (data) {
      return <span>{data['account'] || '-'}</span>;
    },
  },
  {
    label: 'Nom et Prénom',
    key: 'fullname',
    sortable: true,
    render: function (data) {
      return <span>{data['fullname'] || '-'}</span>;
    },
  },
  {
    label: 'Fonction',
    key: 'fonction',
    sortable: true,
    render: function (data) {
      return <span>{data['fonction'] || '-'}</span>;
    },
  },
  {
    label: 'Date de consentement RGPD',
    key: 'date_consentement_RGPD',
    sortable: true,
    render: function (data) {
      return (
        <span>
          {(data['date_consentement_RGPD'] &&
            moment(data['date_consentement_RGPD']).format('DD-MM-YYYY')) ||
            '-'}
        </span>
      );
    },
  },
  {
    label: 'Date fin accès',
    key: 'date_fin_acces',
    sortable: true,
    render: function (data) {
      return (
        <span>
          {(data['date_consentement_RGPD'] &&
            moment(data['date_fin_acces'])?.format('DD-MM-YYYY')) ||
            '-'}
        </span>
      );
    },
  },
  {
    label: 'Territoires',
    key: 'territoires',
    sortable: true,
    render: function (data) {
      return <span>{data['territoires'] || '-'}</span>;
    },
  },
  {
    label: 'Accès complet',
    key: 'full_access',
    sortable: true,
    render: function (data) {
      return <span>{data['full_access'] ? 'Oui' : 'Non'}</span>;
    },
  },
  {
    label: '',
    key: 'actions',
    sortable: false,
    render: function (user) {
      return (
        <button
          onClick={() =>
            props?.onClickEditBtn &&
            props?.onClickEditBtn(
              props?.users?.find((item) => item.id === user.uuid)
            )
          }
          className="btn-admin-zone small"
        >
          <span className="mr-2">Modifier</span>
          <PencilIcon className="h-4 w-4" />
        </button>
      );
    },
  },
];

const ListUserTable = forwardRef(({ title, orgId, onClickEditBtn }, ref) => {
  const [sortBy, setSortBy] = useState({ key: '', value: '' });
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const { departements } = useSelector((state) => state.others);
  const dispatch = useDispatch();

  const { user: currentUser } = useAuth();

  const getUsers = async (_page, filters = null) => {
    setLoading(true);
    const {
      payload: { users, meta },
    } = await dispatch(getUsersOrganization({ orgId, filters }));
    setUsers((users || []).filter((el) => el.id !== currentUser?.uuid));
    setLoading(false);
  };

  const handleChangeFilters = (filters) => {
    setFilters(filters);
  };

  useImperativeHandle(ref, () => ({
    update: () => {
      getUsers(0, filters);
    },
  }));

  useDebounce(
    () => {
      getUsers(page, filters);
    },
    [filters, page],
    500, // timeout
    true // run in first
  );

  const usersDataTable = useMemo(() => {
    const mappedArray = users.map((user) => {
      let _departements = [];

      const { attributes } = user;

      const defaultDepartement = uniqBy(
        departements.filter((el) =>
          user.attributes.departements?.includes(el.id)
        ),
        'id'
      );

      if (
        defaultDepartement.filter((el) => idfDepartementsCodes.includes(el.id))
          .length === idfDepartementsCodes.length
      ) {
        _departements = [
          ...defaultDepartement.filter(
            (el) => !idfDepartementsCodes.includes(el.id)
          ),
          {
            attributes: {
              name: idfOption.label,
            },
          },
        ];
      } else {
        _departements = defaultDepartement;
      }

      const despartementStr = _departements
        .map((item) => item.attributes.name)
        .sort((a, b) => a.localeCompare(b))
        .join(', ');

      return {
        uuid: attributes.evalparcel_uuid,
        email: attributes.email,
        account: attributes.account_company_name,
        fullname:
          attributes.first_name || attributes.last_name
            ? `${attributes.first_name || ''} ${
                attributes.last_name || ''
              }`.trim()
            : null,
        fonction: attributes.fonction || null,
        date_consentement_RGPD: attributes.date_consent_gdpr_org
          ? new Date(attributes.date_consent_gdpr_org)
          : null,
        date_fin_acces: attributes.end_validity_subscription
          ? new Date(attributes.end_validity_subscription)
          : null,
        territoires: despartementStr.length ? despartementStr : null,
        full_access: attributes.full_access || false,
      };
    });

    // order array
    if (sortBy.key.length && sortBy.value.length) {
      const separatedArray = [
        ...orderBy(
          mappedArray.filter((item) => item[sortBy.key]),
          [sortBy.key],
          [sortBy.value]
        ),
        ...mappedArray.filter((item) => !item[sortBy.key]),
      ];
      return separatedArray;
    }

    return mappedArray;
  }, [users, sortBy, departements]);

  const handleChangeSortBy = (key) => {
    setSortBy((state) => ({
      key,
      value:
        state.key === key ? (state.value === 'desc' ? 'asc' : 'desc') : 'desc',
    }));
  };

  const columns = useColumns({ onClickEditBtn, users });

  return (
    <div className="first:mt-8 mt-16">
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-xl">{title}</h3>
        <div>
          <button
            onClick={() => setCollapsed((prev) => !prev)}
            className="btn-admin-zone"
          >
            {collapsed ? 'Agrandir' : 'Réduire'}
          </button>
          <FilterComp onFilter={handleChangeFilters} />
        </div>
      </div>
      {!collapsed && (
        <div
          className="table-list-users-container mt-4"
          style={{ maxHeight: '400px', overflowY: 'auto' }}
        >
          {!loading ? (
            <table className="w-full table-list-users">
              <thead>
                <tr>
                  {columns.map(({ key, label, sortable }) => (
                    <th
                      className={classNames({
                        'cursor-pointer hover:bg-gray-200': sortable,
                      })}
                      key={key}
                      onClick={() => {
                        sortable && handleChangeSortBy(key);
                      }}
                    >
                      <div className="flex items-center gap-x-2">
                        <span
                          className={classNames('block', {
                            'text-blue-500': sortBy.key === key,
                          })}
                        >
                          {label}
                        </span>
                        <span className="block w-6">
                          {sortable && (
                            <>
                              {sortBy.key === key ? (
                                sortBy.value === 'desc' ? (
                                  <ChevronUpIcon className="h-6 w-6" />
                                ) : (
                                  <ChevronDownIcon className="h-6 w-6" />
                                )
                              ) : (
                                <SwitchVerticalIcon className="h-6 w-6" />
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {usersDataTable.map((el) => (
                  <tr key={el.uuid}>
                    {columns.map(({ key, render }) => (
                      <td key={key}>{render(el)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex w-full justify-center mt-8 font-bold text-xl">
              Chargement cours...
            </div>
          )}
        </div>
      )}
    </div>
  );
});

const AdminUser = () => {
  const { currentUserOrganizations, departements } = useSelector(
    (state) => state.others
  );
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { sessionAuthHeader } = useAuth();
  const [userToEdit, setUserToEdit] = useState(null);
  const [iduserToEdit, setIdUserToEdit] = useState(null);
  const [currentIdOrganizationOnEdit, setCurrentIdOrganizationOnEdit] =
    useState(null);

  const childRefs = React.useMemo(
    () =>
      (currentUserOrganizations || []).map(({ id }) => ({
        ref: React.createRef(),
        id,
      })),
    [currentUserOrganizations]
  );

  const handleSave = async (data, id, orgId) => {
    // save the change
    await dispatch(
      saveUserOrganization({
        data,
        headers: {
          'X-USER-EMAIL': sessionAuthHeader?.email,
          'X-USER-TOKEN': sessionAuthHeader?.token,
        },
        id,
      })
    );

    // get the comp table ref of organization
    const currentRef = childRefs.find(
      (el) => parseInt(el.id) === parseInt(orgId || currentIdOrganizationOnEdit)
    );

    // update the list of current organization
    currentRef && currentRef.ref.current?.update();

    setOpenModal(false);
  };

  const handleClickEdit = (ordId) => (user) => {
    const relations = user?.relationships;
    const account = relations?.account || null;
    const adhesion = user.attributes.adhesion;
    const _departements = uniqBy(
      departements.filter(({ attributes: { national_code } }) => {
        return user?.attributes?.departements?.includes(national_code);
      }),
      'id'
    );

    const departementsValue = _departements.map(({ attributes }) => ({
      value: attributes.national_code,
      label: attributes.name,
    }));

    const departementsValueMapped = [
      ...departementsValue.filter(
        (el) => !idfDepartementsCodes.includes(el.value)
      ),

      // we change IDF if all IDF departements are choosen
      ...(departementsValue.filter((el) =>
        idfDepartementsCodes.includes(el.value)
      ).length === idfDepartementsCodes.length
        ? [idfOption]
        : departementsValue.filter((el) =>
            idfDepartementsCodes.includes(el.value)
          )),
    ];

    const _user = {
      nom: user.attributes.last_name,
      prenom: user.attributes.first_name,
      date_echeance: new Date(user.attributes.end_validity_subscription),
      date_consent_gdpr_org: new Date(user.attributes.date_consent_gdpr_org),
      paying_client_org: user.attributes.active_paying_user
        ? { value: 'true', lable: 'Oui' }
        : { value: 'false', lable: 'Non' },
      adhesion:
        adhesion === 'classique' || adhesion === 'premium' ? adhesion : 'non',
      tel: user.attributes.phone_number,
      departements: departementsValueMapped,
      account_id: account?.data?.id || null,
      fonction: user.attributes.fonction || '',
      blocked_at: user.attributes.blocked_at
        ? new Date(user.attributes.blocked_at)
        : null,
      full_access: user.attributes.full_access ? 'Oui' : 'Non',
    };
    setUserToEdit(_user);
    setIdUserToEdit(user.id);
    setOpenModal(true);
    setCurrentIdOrganizationOnEdit(ordId);
  };

  useEffect(() => {
    const getData = async () => {
      dispatch(getDepartements());
      const { payload: orgs } = await dispatch(getOrganizationUser());
      if (orgs.length === 0) {
        window.location.assign('/');
      }
    };
    getData();
  }, []);

  return (
    <AdminUserLayout>
      <ModalForm
        open={openModal}
        onSave={handleSave}
        onClose={() => setOpenModal(false)}
        user={userToEdit}
        id={iduserToEdit}
      />
      <div className="w-full admin-zone bg-red">
        <div className="flex justify-between items-center mt-10">
          <div>
            <h1 className="font-poppins font-bold uppercase text-3xl">
              GESTION DES UTILISATEURS
            </h1>
            {currentUserOrganizations?.length > 1 ? (
              <p className="font-poppins text-sm mt-2">
                Vous avez {currentUserOrganizations.length} organisations
                actives rattachées.
              </p>
            ) : (
              <p className="font-poppins text-sm mt-2">
                Vous avez {currentUserOrganizations.length} organisation active
                rattachée.
              </p>
            )}
          </div>
          <button
            onClick={() => {
              setOpenModal(true);
              setUserToEdit(null);
              setIdUserToEdit(null);
            }}
            className="btn-admin-zone"
          >
            <span className="mr-2">Créer un utilisateur</span>
            <PlusIcon className="h-6 w-6" />
          </button>
        </div>
        <h2 className="font-poppins uppercase font-semibold text-2xl mt-16">
          Organisations
        </h2>
        <div className="w-full">
          {(currentUserOrganizations || []).map(
            ({ attributes: { name, id } }) => (
              <ListUserTable
                ref={
                  childRefs.find((el) => parseInt(el.id) === parseInt(id)).ref
                }
                key={id}
                title={name}
                orgId={id}
                onClickEditBtn={handleClickEdit(id)}
              />
            )
          )}
        </div>
      </div>
    </AdminUserLayout>
  );
};

export default AdminUser;
