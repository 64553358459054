import React from 'react';
import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

export default function Pagination({ pageCount, currentPage, onChange }) {
  const handlePageClick = (event) => {
    onChange && onChange(event.selected);
  };

  return (
    <ReactPaginate
      className="flex flex-row items-center pagination"
      pageLinkClassName="page-pagination hover:bg-blue-300 hover:text-white cursor-pointer border-t-1 border-b-1 border-l-1 py-2 px-4 border-blue-400 text-lg"
      pageClassName="li-page-pagination"
      breakLabel="..."
      breakLinkClassName="cursor-pointer break-pagination border-t-1 border-b-1 border-l-1 py-2 px-4 border-blue-400 text-lg"
      nextLabel={<ChevronRightIcon className="h-8 w-8" />}
      nextClassName="next-pagination m-2"
      activeLinkClassName="bg-blue-400 text-white"
      previousClassName="prev-pagination m-2"
      onPageChange={handlePageClick}
      pageRangeDisplayed={8}
      pageCount={pageCount}
      previousLabel={<ChevronLeftIcon className="h-8 w-8" />}
      renderOnZeroPageCount={null}
      forcePage={currentPage}
    />
  );
}
