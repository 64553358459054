import React, {useEffect, useState} from "react";
import MapDataContainer from "./Map/MapDataContainer";
import {useSelectedParcels} from "../../../hooks/useSelectedParcels";

const DataVisualisationContainer = ({parcels}) => {
    const [mapHeight, setMapHeight] = useState(300)
    const {fieldIds} = useSelectedParcels()

    useEffect(() => {
        setMapHeight(document.getElementById('navbar').clientHeight + document.getElementById('bottom-navbar').clientHeight + document.getElementById('banner-alert')?.clientHeight)
    }, [mapHeight, fieldIds])

    return (
        <div className="hidden md:flex col-span-12 md:col-span-1 relative flex flex-col h-full fixed">
            <div className={'h-full'} style={{height: `calc(100vh - ${mapHeight}px )`}}>
                <MapDataContainer/>
            </div>
        </div>
    )
}

export default DataVisualisationContainer;
