import { createAsyncThunk } from '@reduxjs/toolkit';
import { ciblageCreateActions } from '../../slices/ciblageCreate';
import axios from 'axios';
import { buildParamsByJson, getNbParcel } from './parcel';
import {
  EVOLUTION_PRICE_NEW_MIN,
  EVOLUTION_PRICE_OLD_MIN,
  PERCENT_COLLECTIVE_HOUSING_MIN,
  POPULATION_GROWTH_MIN,
} from '../../../constants/ciblageDefaultValues';

const equivsMarcheImmo = {
  new: [
    ['hasNewProjectsSinceFiveYear', 'neuf_cinq_ans'],
    ['newPriceRange', 'prix_neuf_min', 'prix_neuf_max'],
    ['evolutionNewPrice', 'evolution_prix_neuf'],
  ],
  old: [
    ['oldPriceRange', 'prix_ancien_min', 'prix_ancien_max'],
    ['evolutionOldPrice', 'evolution_prix_ancien'],
  ],
};
const equivsTerritoire = [
  ['zonesPinelToParams', 'zonage_pinels'],
  ['populationRange', 'population_min', 'population_max'],
  ['evolutionPopulation', 'evolution_pop_percent'],
  ['logCollectif', 'part_logement_collectif'],
  ['isActionHeartOfTheCity', 'coeur_de_ville'],
  ['changedMayor', 'changement_maire'],
];

const noNeedKeys = {
  evolutionNewPrice: EVOLUTION_PRICE_NEW_MIN,
  evolutionOldPrice: EVOLUTION_PRICE_OLD_MIN,
  evolutionPopulation: POPULATION_GROWTH_MIN,
  logCollectif: PERCENT_COLLECTIVE_HOUSING_MIN,
};

export const buildJsonFilterCiblage = (json, array, equiv) => {
  let currentJson = json;
  array.forEach((el) => {
    if (el.length === 2) {
      if (
        equiv[el[0]] !== null &&
        !(
          Object.keys(noNeedKeys).includes(el[0]) &&
          equiv[el[0]] == noNeedKeys[el[0]]
        )
      )
        currentJson[el[1]] = equiv[el[0]];
    } else if (el.length === 3) {
      if (equiv[el[0]][0] !== null && equiv[el[0]][1] !== null) {
        currentJson[el[1]] = equiv[el[0]][0];
        currentJson[el[2]] = equiv[el[0]][1];
      }
    }
  });
  return currentJson;
};

export const getCommuneNationalCodesByFilters = createAsyncThunk(
  'getCommunesByFilters',
  async (_, { dispatch, getState }) => {
    const {
      communes,
      filtersCibler: {
        isEnabledMarcheImmobilier,
        isEnabledTerritoire,
        marcherImmobilier,
        territoire,
      },
      isoChroneFilterValue,
      valueTerritoire: { epci },
    } = getState().ciblageCreate;

    const { isoChroneAddress, transportDuration, transportMode } =
      isoChroneFilterValue;

    let filtersJson = {};

    if (isEnabledMarcheImmobilier) {
      if (marcherImmobilier.isNewEnabled) {
        filtersJson = {
          ...filtersJson,
          ...buildJsonFilterCiblage(
            filtersJson,
            equivsMarcheImmo.new,
            marcherImmobilier
          ),
        };
      }
      if (marcherImmobilier.isOldEnabled) {
        filtersJson = {
          ...filtersJson,
          ...buildJsonFilterCiblage(
            filtersJson,
            equivsMarcheImmo.old,
            marcherImmobilier
          ),
        };
      }
    }

    if (isEnabledTerritoire) {
      filtersJson = {
        ...filtersJson,
        ...buildJsonFilterCiblage(filtersJson, equivsTerritoire, territoire),
      };
    }

    filtersJson = {
      ...filtersJson,
      ...(epci
        ? { national_code: epci.code }
        : isoChroneFilterValue
        ? {
            lon: isoChroneAddress.lon,
            lat: isoChroneAddress.lat,
            transport_mode: transportMode.value,
            transport_time_max: transportDuration * 60,
          }
        : {}),
    };

    dispatch(ciblageCreateActions.setFiltersCiblerJson(filtersJson));

    if (
      (isEnabledMarcheImmobilier &&
        (marcherImmobilier.isNewEnabled || marcherImmobilier.isOldEnabled)) ||
      isEnabledTerritoire
    ) {
      dispatch(
        ciblageCreateActions.setLoading({
          parcel: true,
          map: true,
          commune: true,
        })
      );

      try {
        let data = null;
        try {
          const { data: results } = await axios.post(
            `${process.env.BASE_URL_API}/v5/eval_parcel/ciblage/communes_national_codes`,
            filtersJson
          );
          data = results;
        } catch (e) {
          const { data: results } = await axios.post(
            `${process.env.BASE_URL_API}/v5/eval_parcel/ciblage/communes_national_codes${buildParamsByJson(
              filtersJson
            )}`
          );
          data = results;
        }

        if (data) {
          const currentSelectedCommunes = communes.filter((item) =>
            data.national_codes.includes(item.properties.national_code)
          );
          data &&
            dispatch(
              ciblageCreateActions.setValueTerritoireSelectedCommunes(
                currentSelectedCommunes
              )
            );

          dispatch(
            ciblageCreateActions.setLoading({
              parcel: true,
              map: false,
              commune: false,
            })
          );

          if (currentSelectedCommunes?.length) {
            await dispatch(
              getNbParcel({
                filtersCiblerJson: filtersJson,
                selectedCommunes: currentSelectedCommunes,
              })
            );
          } else {
            dispatch(ciblageCreateActions.setNbParcel(null));
          }

          dispatch(ciblageCreateActions.setLoading(null));
        }
      } catch (e) {
        console.log(e);
        dispatch(ciblageCreateActions.setValueTerritoireSelectedCommunes([]));
        dispatch(ciblageCreateActions.setNbParcel(null));
        dispatch(ciblageCreateActions.setLoading(null));
      }
    } else {
      dispatch(ciblageCreateActions.setValueTerritoireSelectedCommunes([]));
      dispatch(ciblageCreateActions.setNbParcel(null));
    }
  }
);

export const selectCibler = () => (dispatch) => {
  dispatch(ciblageCreateActions.setValueTerritoireSelectedCommunes([]));
  dispatch(ciblageCreateActions.setCibler(true));
};
