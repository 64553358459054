import Api from '../../../../api/Api';
import useSWR from 'swr';
import { Error } from '../../../utils/Error';
import React, { memo, useMemo } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import RowSpanningTable from './RowSpanningTable';
import LoadingSection from '../../../MarketSurveys/sections/LoadingSection';

const fetcher = (url) => Api.get(url).then((res) => res.data);

const DualInputTable = ({ isPdfRenderMode, dataUrl, data }) => {
  const res = !data && dataUrl && useSWR(dataUrl, fetcher);

  const { name, date, table_data } = useMemo(
    () => (!data ? res?.data || {} : data),
    [data, res]
  );

  return (
    <>
      <div className="col-span-2 flex flex-col">
        <h3
          dangerouslySetInnerHTML={{ __html: name }}
          className={classNames(
            { 'text-xl': isPdfRenderMode },
            { 'text-base 2xl:text-lg': !isPdfRenderMode },
            'py-3 leading-3 text-semibold'
          )}
        />
        {date && <h4>{date}</h4>}
        <div className="w-full overflow-x-auto">
          <RowSpanningTable
            isPdfRenderMode={isPdfRenderMode}
            origData={table_data}
          />
        </div>
        {res?.error && <Error error={res.error} />}
        {!res?.data && !data && <LoadingSection />}
      </div>
    </>
  );
};

export default memo(DualInputTable);
