import gare from '../../../../../../../assets/images/icons/gare-tgv.png';
import home from '../../../../../../../assets/images/icons/terrain.svg';
import mairie from '../../../../../../../assets/images/icons/mairie-de-la-commune.svg';
import aeroport from '../../../../../../../assets/images/icons/aeroport.png';
import primary_public_school from '../../../../../../../assets/images/icons/ecole-primaire.svg';
import primary_school from '../../../../../../../assets/images/icons/ecole-primaire-privee.svg';
import middle_school from '../../../../../../../assets/images/icons/college.svg';
import university from '../../../../../../../assets/images/icons/university.svg';
import hospital from '../../../../../../../assets/images/icons/hopital.svg';
import permis_de_construire from '../../../../../../../assets/images/icons/permis-de-construire.svg';
import zone from '../../../../../../../assets/images/icons/zone.svg';
import equipement_sportif from '../../../../../../../assets/images/icons/equipements-et-service-sport.svg';
import monuments_historiques from '../../../../../../../assets/images/icons/monuments-historiques.svg';
import programmes_neufs from '../../../../../../../assets/images/icons/programme-immobilier-neuf.svg';
import programme_immobilier_termine_2019 from '../../../../../../../assets/images/icons/programme-immobilier-termine-2019.svg';
import programme_selectionnee from '../../../../../../../assets/images/icons/programme-selectionne.svg';
import programme_en_cours from '../../../../../../../assets/images/icons/programme_en_cours.svg';
import post_office from '../../../../../../../assets/images/icons/post_office.svg';
import IconAdd from '../../../../../../../assets/images/icons/plus.png';
import IconRemove from '../../../../../../../assets/images/icons/close.png';
import React from 'react';
import {
  CheckCircleIcon,
  CheckIcon,
  InformationCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import gare_nation from '../../../../../../../assets/images/icons/gare_tgv_national.png';
import metro from '../../../../../../../assets/images/icons/metro.svg';
import bus from '../../../../../../../assets/images/icons/bus.png';

const IconSwitch = (icon, title) => {
  switch (icon) {
    case 'gare':
      return (
        <img className="h-6 2xl:h-10 rounded-full" src={gare} alt={title} />
      );
    case 'train':
      return (
        <img className="h-6 2xl:h-10 rounded-full" src={gare} alt={title} />
      );
    case 'metro':
      return (
        <img className="h-6 2xl:h-8 rounded-full" src={metro} alt={title} />
      );
    case 'bus':
      return (
        <img className={'h-6 2xl:h-10 rounded-full'} src={bus} alt={title} />
      );
    case 'train_national':
      return (
        <img
          className="h-6 2xl:h-10 rounded-full"
          src={gare_nation}
          alt={title}
        />
      );
    case 'airport':
      return (
        <img className="h-6 2xl:h-10 rounded-full" src={aeroport} alt={title} />
      );
    case 'home':
      return (
        <img className="h-6 2xl:h-10 rounded-full" src={home} alt={title} />
      );
    case 'mairie':
      return (
        <img className="h-6 2xl:h-10 rounded-full" src={mairie} alt={title} />
      );
    case 'primary_school':
      return (
        <img
          className="h-6 2xl:h-10 rounded-full"
          src={primary_school}
          alt={title}
        />
      );
    case 'school':
      if (title.split(' ').indexOf('publics') !== -1) {
        return (
          <img
            className="h-6 2xl:h-10 rounded-full"
            src={primary_public_school}
            alt={title}
          />
        );
      } else {
        return (
          <img
            className="h-6 2xl:h-10 rounded-full"
            src={primary_school}
            alt={title}
          />
        );
      }
    case 'middle_school':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={middle_school}
          alt={title}
        />
      );
    case 'high_private_school':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={middle_school}
          alt={title}
        />
      );
    case 'high_school':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={university}
          alt={title}
        />
      );
    case 'university':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={university}
          alt={title}
        />
      );
    case 'hospital':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={hospital}
          alt={title}
        />
      );
    case 'sport':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={equipement_sportif}
          alt={title}
        />
      );
    case 'permis_de_construire':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={permis_de_construire}
          alt={title}
        />
      );
    case 'programmes_neufs':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={programmes_neufs}
          alt={title}
        />
      );
    case 'programme_selectionnee':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={programme_selectionnee}
          alt={title}
        />
      );
    case 'programme_termines_2019':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={programme_immobilier_termine_2019}
          alt={title}
        />
      );
    case 'programme_en_cours':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={programme_en_cours}
          alt={title}
        />
      );
    case 'monuments_historiques':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={monuments_historiques}
          alt={title}
        />
      );
    case 'travaux':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={permis_de_construire}
          alt={title}
        />
      );
    case 'post_office':
      return (
        <img
          className={'h-6 2xl:h-10 rounded-full'}
          src={post_office}
          alt={title}
        />
      );
    case 'zone':
      return <img className={'w-2 rounded-full'} src={zone} alt={title} />;
    case 'line':
      return <span className={'w-8 border-b-2 border-orange-500'} />;
    case 'info_icon':
      return (
        <div>
          <InformationCircleIcon
            className={
              'h-6 2xl:h-8 2xl:w-8 text-white rounded-full bg-blue-500 p-1'
            }
          />
        </div>
      );
    case 'add_icon':
      return (
        <div>
          <img className={'w-5 h-5 rounded-full'} src={IconAdd} alt={'+'} />
        </div>
      );
    case 'remove_icon':
      return (
        <div>
          <img className={'w-5 h-5 rounded-full'} src={IconRemove} alt={'-'} />
        </div>
      );
    case 'delete':
      return (
        <div>
          <TrashIcon
            className={
              'h-6 xl:h-8 xl:w-8 text-white rounded-full bg-blue-500 p-1'
            }
          />
        </div>
      );
    case 'check_icon':
      return (
        <div>
          <CheckIcon
            className={
              'h-6 xl:h-8 xl:w-8 text-white rounded-full bg-blue-500 p-1'
            }
          />
        </div>
      );
    default:
      return (
        <img className="h-6 2xl:h-10 rounded-full" src={home} alt={title} />
      );
  }
};

export default IconSwitch;
