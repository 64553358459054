import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MapCiblage from '../../components/ciblage-v2/map/MapCiblage';
import TunnelFormulaire from '../../components/ciblage-v2/tunnel-formulaire/TunnelFormulaire';
import CiblageLayout from './CiblageLayout';
import { saveCiblage } from '../../store/actions/ciblage/saveCiblage';
import { ciblageResultActions } from '../../store/slices/ciblageResult';

export default function CiblageNew() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSave = useCallback(async () => {
    const { payload: data } = await dispatch(saveCiblage());
    if (data) {
      dispatch(ciblageResultActions.reset());
      history.push(`/ciblage/${data.id}`);
    }
  }, []);

  return (
    <CiblageLayout desktopOnly>
      <div className="h-full w-full flex">
        <div className="w-1/3 h-full">
          <TunnelFormulaire onSave={handleSave} />
        </div>
        <div className="flex-grow h-full">
          <MapCiblage />
        </div>
      </div>
    </CiblageLayout>
  );
}
