import React, { useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MarketSurveySummary from './MarketSurveys/sections/MarketSurveySummary';
import _ from 'lodash';
import MSTableSection from './MarketSurveys/sections/MSTableSection';
import BannerSection from './MarketSurveys/sections/BannerSection';
import {
  BLUE_THEME,
  COLLECTIVE_HOUSING,
  CONTENT,
  CSP,
  DARK_BLUE_THEME,
  DEFINITION_BLOCK,
  DEMOGRAPHY_AND_EMPLOYMENT,
  DETAILED_INFORMATION_1,
  DETAILED_INFORMATION_2,
  DETAILED_INFORMATION_3,
  EDUCATION,
  FIELD_ATTRIBUTES,
  FROM_25_TO_39_YO,
  GRAY_THEME,
  LOCAL_AUTHORITY_HOUSING,
  MAP_PROGRAMME,
  MEDIAN_INCOME,
  MERGED_ROW_TABLE,
  MOBILITY,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
  NEIGHBORHOOD,
  NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES,
  NEW_BUYER_TYPE,
  NEW_IN_AREA_CITY_PRICE,
  NEW_ON_SALE,
  NEW_PROGRAMS,
  NEW_RESERVATION_BY_VAT_TYPE,
  OFFER_AVAILABLE,
  OLD_HOUSING_TREND,
  POLITICAL,
  PRICE_CHANGE,
  PRICE_EVOLUTION_2018,
  RENT_PRICE,
  RESERVATION,
  RESUME,
  SINGLE_INPUT_TABLE,
  UNEMPLOYMENT,
  VACANT_HOUSING,
  YELLOW_THEME,
  SENIOR,
  PART_1_DEFS,
  TERRAIN_EMPLACEMENT_PARCELLES,
  COLLECTIVE_HOUSING_MAP_1,
  COLLECTIVE_HOUSING_MAP_2,
  NB_LOG_VACANT_MAP,
  EVO_2014_2020_MAP,
  CHOMAGE_VARIATION_TAUX_MAP,
  CHOMAGE_VARIATION_DIFF_MAP,
  REVENU_MEDIAN_ANNUEL_MAP,
  REVENU_VARIATION_2019_2020,
  POPULATION_NB_MAP,
  POPULATION_VARIATION_MAP,
  POPULATION_CADRE_NB_MAP,
  POPULATION_CADRE_EVO_MAP,
  FROM_25_TO_39_YO_NB_MAP,
  FROM_25_TO_39_YO_VARIATION_MAP,
  SENIOR_NB_MAP,
  SENIOR_VARIATION_MAP,
} from '../_helpers/constant';

import { usePrograms } from '../hooks/usePrograms';
import CoverMarketSurveySection from './MarketSurveys/sections/CoverMarketSurveySection';
import LogementSocial from './MarketSurveys/sections/LogementSocial';
import {
  COLOR_EPCI_COMMUNE,
  COLOR_NEUF_VENTILATION,
} from '../constants/marketSurvey.const';
import { useEDM } from '../_helpers/useGetDataEdm';
import { TitleWithSelect, select_1_4_options } from './MarketSurveyPage';
import { Chart } from 'chart.js';
import ProgramsBlockWithMap from './MarketSurveys/sections/ProgramsBlockWithMap';
import TerrainEmplacementParcelles, {
  NextTerrainEmplacementParcelles,
} from './MarketSurveys/sections/TerrainEmplacementParcelles';
import { getPerPageArray } from '../store/slices/marketSurvey';
import SynopticVisionAndDynamicCommune from './MarketSurveys/sections/SynopticVisionAndDynamicCommune';

Chart.defaults.font.size = 18;

const SectionContainer = ({ children, hide = false }) => {
  return (
    <>
      {!hide && (
        <div
          style={{ height: 1240, maxHeight: 1240 }}
          className="page-container flex-col w-full flex justify-between"
        >
          <div
            style={{
              height: 'calc(1240px - 56px)',
              maxHeight: 'calc(1240px - 56px)',
            }}
            className="pt-12 px-12"
          >
            {children}
          </div>
          <div
            style={{ height: 56 }}
            className="w-full flex items-center justify-center font-bold text-base"
          ></div>
        </div>
      )}
    </>
  );
};

const MarketSurveyPDF = ({ part }) => {
  const { survey_id } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isCapemUser = queryParams.get('icu') === 'true';

  const { isPart1Only, isPart2Only } = useMemo(
    () => ({
      isPart1Only: part === 1,
      isPart2Only: part === 2,
    }),
    [part]
  );

  const {
    states: { fieldIds, msMetaData, dateMs },
    data: {
      data: {
        parcelData,
        resumeProgrammePart1,
        offersDispoDataPart1,
        reservationsPart1,
        reservationsVariationDataPart1,
        programmesSelected2_3Part1,
        programmesNeufDataPart2,
        neufMevDataPart2,
        neufMevLimotropheDataPart2,
        neufPriceByTVADataPart2,
        neufMevLimotrophe12monthDataPart2,
        neufResParTydeAcquereurDataPart2,
        neufResNetteDataPart2,
        ancienAppartementDataPart2,
        marcheLocatifAppartDataPart2,
        collectifHousingDataPart2,
        vacantHousingDataPart2,
        attributesTerrainDataPart3,
        educationDataPart3,
        mobilityDataPart3,
        nearestShopDataPart3,
        synopticVisonAndDynamicCommuneData,
        demographieAndEmploiData,
        revenusMedianeVariationDataPart4,
        chomageTauxVariationDataPart4,
        populationNombreVariationDataPart4,
        populationFocusCadreDataPart4,
        populationFocus25To39DataPart4,
        populationSeniorDataPart4,
        definition,
      },
      infosDetailsSelectedProgrammes,
    },
    refs: {
      linksRefs,
      refs: {
        // marketSurveyRef,
        resumeRef_1,
        resumeRef_2,
        resumeRef_3,
        resumeRef_4,
        resumeRef_5,
        reservation_ref,
        price_change_ref,
        offer_available_ref,
        detailed_information_1_ref,
        detailed_information_2_ref,
        detailed_information_3_ref,
        field_attributes_ref,
        parcelles_emplacement_ref,
        neighborhood_ref,
        csp_ref,
        from_25_to_39_yo_ref,
        education_ref,
        nearest_shops_and_public_equipment_ref,
        new_programs_ref,
        mobility_ref,
        new_on_sale_ref,
        new_in_area_city_price_ref,
        new_buyer_type_ref,
        vacant_housing_ref,
        new_reservation_by_vat_type_ref,
        net_reservations_by_epci_municipalities_ref,
        price_evolution_2018_ref,
        old_housing_trend_ref,
        rent_price_ref,
        local_authority_housing_ref,
        collective_housing_ref,
        political_ref,
        demography_and_employment_ref,
        median_income_ref,
        age_ref,
        unemployment_ref,
        definitionsRef,
        contactRef,
      },
    },
  } = useEDM(survey_id, { isPdf: true, isPart1Only, isPart2Only });

  const { activePrograms } = usePrograms();
  // set page number
  useEffect(() => {
    if (infosDetailsSelectedProgrammes?.length && activePrograms?.length) {
      const pages = document.getElementsByClassName('page-container');
      for (let i = 0; i < pages.length; i++) {
        const currentEl = pages[i];
        if (currentEl.children[1]) {
          currentEl.children[1].textContent = `${i + 1} / ${pages.length}`;
        }
      }
    }
  }, [infosDetailsSelectedProgrammes?.length, activePrograms]);

  const parcelData3_1 = useMemo(() => {
    if (parcelData?.length > 10) {
      return getPerPageArray(24, parcelData.slice(10));
    }
    return [];
  }, [parcelData]);

  if (!fieldIds.length) {
    return <></>;
  }

  return (
    <>
      <SectionContainer>
        <CoverMarketSurveySection
          isPdfRenderMode
          surveyId={survey_id}
          dateMs={dateMs}
          parcelData={parcelData}
          msMetaData={msMetaData}
        />
      </SectionContainer>

      {/* start part 1 */}
      <SectionContainer hide={isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title="1-Analyse concurrentielle - votre sélection de programmes neufs"
          surveyID={survey_id}
          theme={YELLOW_THEME}
        />
        <MarketSurveySummary
          refsArray={linksRefs}
          isPdfRenderMode={true}
          isPart1={true}
          // ref={marketSurveyRef}
          theme={YELLOW_THEME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title="1-Analyse concurrentielle - votre sélection de programmes neufs"
          surveyID={survey_id}
          theme={YELLOW_THEME}
        />
        <MSTableSection
          ref={resumeRef_1}
          isPdfRenderMode
          title="1.1 – LOCALISATION, PRIX MOYEN, DISPONIBILITÉ"
          surveyId={survey_id}
          commentFieldName={RESUME}
          personalizedContent={
            <ProgramsBlockWithMap
              isPdfMode
              contentData={resumeProgrammePart1}
            />
          }
        />
      </SectionContainer>

      {(infosDetailsSelectedProgrammes || []).map((item, i) => (
        <SectionContainer key={i} hide={isPart2Only}>
          <BannerSection
            isPdfRenderMode={true}
            title={
              '1-Analyse concurrentielle - votre sélection de programmes neufs'
            }
            surveyID={survey_id}
            theme={YELLOW_THEME}
          />
          <MSTableSection
            isPdfRenderMode={true}
            ref={detailed_information_3_ref}
            title={i === 0 ? '1.2 – OFFRE DISPONIBLE : PAR PROGRAMME' : null}
            surveyId={survey_id}
            data={[
              {
                type: MERGED_ROW_TABLE,
              },
            ]}
            infosDetailsSelectedProgrammes={item}
            commentFieldName={DETAILED_INFORMATION_3}
            theme={YELLOW_THEME}
          />
        </SectionContainer>
      ))}

      <SectionContainer hide={isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title="1-Analyse concurrentielle - votre sélection de programmes neufs"
          surveyID={survey_id}
          theme={YELLOW_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={offer_available_ref}
          title="1.3 – OFFRE DISPONIBLE : PAR TYPOLOGIE"
          surveyId={survey_id}
          data={offersDispoDataPart1}
          commentFieldName={OFFER_AVAILABLE}
          theme={YELLOW_THEME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title="1-Analyse concurrentielle - votre sélection de programmes neufs"
          surveyID={survey_id}
          theme={YELLOW_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={price_change_ref}
          title={
            <TitleWithSelect
              isPdf
              title="1.4 – RÉSERVATIONS : VARIATION DES PRIX ET RYTHMES / TAUX MOYENS"
              value={msMetaData?.periode_1_4 || select_1_4_options[2]}
            />
          }
          surveyId={survey_id}
          data={reservationsVariationDataPart1}
          commentFieldName={PRICE_CHANGE}
          theme={YELLOW_THEME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title="1-Analyse concurrentielle - votre sélection de programmes neufs"
          surveyID={survey_id}
          theme={YELLOW_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={reservation_ref}
          title="1.5 – RÉSERVATIONS : NOMBRE, SURFACE, PRIX PAR TYPE D’ACHETEURS ET DE LOGEMENTS"
          surveyId={survey_id}
          data={reservationsPart1}
          commentFieldName={RESERVATION}
          theme={YELLOW_THEME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title="1-Analyse concurrentielle - votre sélection de programmes neufs"
          surveyID={survey_id}
          theme={YELLOW_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={detailed_information_2_ref}
          title="1.6 – RÉCAPITULATIF : PAR PROGRAMME"
          surveyId={survey_id}
          data={programmesSelected2_3Part1}
          commentFieldName={DETAILED_INFORMATION_2}
          theme={YELLOW_THEME}
        />
      </SectionContainer>
      {/* end part 1 */}

      {/* start part 2 */}
      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MarketSurveySummary
          refsArray={linksRefs}
          isPdfRenderMode={true}
          ref={resumeRef_2}
          isPart2={true}
          theme={DARK_BLUE_THEME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={new_programs_ref}
          title={
            '2.1 – NEUF : PROGRAMMES EN COURS DE COMMERCIALISATION - PRIX MOYEN'
          }
          data={programmesNeufDataPart2}
          surveyId={survey_id}
          commentFieldName={NEW_PROGRAMS}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={new_on_sale_ref}
          title={
            '2.2 – NEUF : MISES EN VENTE | RÉSERVATIONS | OFFRE DISPONIBLE - COMMUNE & EPCI (SUR 5 ANS)'
          }
          surveyId={survey_id}
          data={neufMevDataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={NEW_ON_SALE}
          coloredTrOptions={COLOR_EPCI_COMMUNE}
          separatedTr={3}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={net_reservations_by_epci_municipalities_ref}
          title={
            '2.3 – NEUF : MISES EN VENTE | RÉSERVATIONS NETTES | COMMUNES LIMITROPHES (SUR 5 ANS)'
          }
          surveyId={survey_id}
          data={neufMevLimotropheDataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={price_evolution_2018_ref}
          title="2.4 – NEUF : ÉVOLUTION DES PRIX PAR TYPE DE TVA - COMMUNES & EPCI (SUR 5 ANS)"
          surveyId={survey_id}
          data={neufPriceByTVADataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={PRICE_EVOLUTION_2018}
          coloredTrOptions={COLOR_EPCI_COMMUNE}
          separatedTr={3}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={new_in_area_city_price_ref}
          title="2.5 – NEUF : ÉVOLUTION DES PRIX - EPCI & COMMUNES LIMITROPHES (SUR 12 MOIS)"
          surveyId={survey_id}
          data={neufMevLimotrophe12monthDataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={NEW_IN_AREA_CITY_PRICE}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isCapemUser}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={new_buyer_type_ref}
          title="2.6 – NEUF : RÉSERVATIONS PAR TYPE D'ACQUÉREURS (UTILISATEURS | INVEST. | VB)"
          surveyId={survey_id}
          data={neufResParTydeAcquereurDataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={NEW_BUYER_TYPE}
          coloredTrOptions={COLOR_NEUF_VENTILATION}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isCapemUser}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={new_reservation_by_vat_type_ref}
          title="2.7 – NEUF : RÉSERVATIONS NETTES | % INVESTISSEURS ET % TVA RÉDUITE"
          surveyId={survey_id}
          data={neufResNetteDataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={NEW_RESERVATION_BY_VAT_TYPE}
          coloredTrOptions={COLOR_EPCI_COMMUNE}
          separatedTr={3}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={old_housing_trend_ref}
          title="2.8 – MARCHÉ ANCIEN : APPARTEMENTS - PRIX MOYEN | NB. TRANSACTIONS - COMMUNE & EPCI (SUR 5 ANS)"
          surveyId={survey_id}
          data={ancienAppartementDataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={OLD_HOUSING_TREND}
          coloredTrOptions={COLOR_EPCI_COMMUNE}
          separatedTr={3}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={rent_price_ref}
          title="2.9 – MARCHÉ LOCATIF : APPARTEMENTS - PRIX MOYEN - COMMUNE & EPCI"
          surveyId={survey_id}
          data={marcheLocatifAppartDataPart2}
          theme={DARK_BLUE_THEME}
          commentFieldName={RENT_PRICE}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={local_authority_housing_ref}
          title="2.10 – LOGEMENT SOCIAL : PARC - ÉVOLUTIONS ET DEMANDES - COMMUNE"
          surveyId={survey_id}
          personalizedContent={
            <LogementSocial isPdfRenderMode={true} theme={DARK_BLUE_THEME} />
          }
          theme={DARK_BLUE_THEME}
          commentFieldName={LOCAL_AUTHORITY_HOUSING}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          ref={collective_housing_ref}
          title="2.11 – LOGEMENTS : COLLECTIFS - NOMBRE ET PARTS - EPCI & COMMUNES LIMITROPHES"
          isPdfRenderMode={true}
          surveyId={survey_id}
          data={collectifHousingDataPart2}
          theme={DARK_BLUE_THEME}
          switchModeMapData={{
            labels: [
              'Nombre de logements collectifs (appartements) en 2021',
              'Evolution 2015 - 2021',
            ],
            namesMap: [COLLECTIVE_HOUSING_MAP_1, COLLECTIVE_HOUSING_MAP_2],
            name: COLLECTIVE_HOUSING,
            currentMap: msMetaData ? msMetaData[COLLECTIVE_HOUSING] : 'first',
          }}
          commentFieldName={COLLECTIVE_HOUSING}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'2 – Logements et Marché Immobilier'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          ref={vacant_housing_ref}
          title="2.12 – LOGEMENTS : VACANTS - NOMBRE ET PARTS - EPCI & COMMUNES LIMITROPHES"
          isPdfRenderMode={true}
          surveyId={survey_id}
          data={vacantHousingDataPart2}
          theme={DARK_BLUE_THEME}
          switchModeMapData={{
            labels: [
              'Nombre de logements vacants en 2021',
              'Evolution 2015 - 2021',
            ],
            namesMap: [NB_LOG_VACANT_MAP, EVO_2014_2020_MAP],
            name: VACANT_HOUSING,
            currentMap: msMetaData ? msMetaData[VACANT_HOUSING] : 'first',
          }}
          commentFieldName={VACANT_HOUSING}
        />
      </SectionContainer>
      {/* end part 2 */}

      {/* start part 3 */}
      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'3-Le terrain et son quartier'}
          surveyID={survey_id}
          theme={BLUE_THEME}
        />
        <MarketSurveySummary
          refsArray={linksRefs}
          isPdfRenderMode={true}
          isPart3={true}
          ref={resumeRef_3}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'3-Le terrain et son quartier'}
          surveyID={survey_id}
          theme={BLUE_THEME}
        />
        <MSTableSection
          ref={parcelles_emplacement_ref}
          title="3.1 - LE TERRAIN : EMPLACEMENT & PARCELLES"
          surveyId={survey_id}
          theme={BLUE_THEME}
          commentFieldName={TERRAIN_EMPLACEMENT_PARCELLES}
          personalizedContent={
            <TerrainEmplacementParcelles parcelData={parcelData} isPdfMode />
          }
        />
      </SectionContainer>

      {parcelData3_1.map((parcelData, index) => (
        <SectionContainer key={index} hide={isPart1Only || isPart2Only}>
          <BannerSection
            isPdfRenderMode={true}
            title={'3-Le terrain et son quartier'}
            surveyID={survey_id}
            theme={BLUE_THEME}
          />
          <MSTableSection
            ref={parcelles_emplacement_ref}
            title={''}
            surveyId={survey_id}
            theme={BLUE_THEME}
            commentFieldName={TERRAIN_EMPLACEMENT_PARCELLES}
            personalizedContent={
              <NextTerrainEmplacementParcelles parcelData={parcelData} />
            }
          />
        </SectionContainer>
      ))}

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'3-Le terrain et son quartier'}
          surveyID={survey_id}
          theme={BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={field_attributes_ref}
          title="3.2 – LE TERRAIN : CARACTERISTIQUES & RISQUES"
          surveyId={survey_id}
          data={attributesTerrainDataPart3}
          commentFieldName={FIELD_ATTRIBUTES}
          theme={BLUE_THEME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'3-Le terrain et son quartier'}
          surveyID={survey_id}
          theme={BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={education_ref}
          title={'3.3 – ECOLES & UNIVERSITES'}
          surveyId={survey_id}
          data={educationDataPart3}
          commentFieldName={EDUCATION}
          theme={BLUE_THEME}
          needIsochrone
          eductionMap
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'3-Le terrain et son quartier'}
          surveyID={survey_id}
          theme={BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={mobility_ref}
          title={'3.4 – TRANSPORTS & MOBILITE'}
          surveyId={survey_id}
          data={mobilityDataPart3}
          commentFieldName={MOBILITY}
          theme={BLUE_THEME}
          needIsochrone
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'3-Le terrain et son quartier'}
          surveyID={survey_id}
          theme={BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={nearest_shops_and_public_equipment_ref}
          title="3.5 – EQUIPEMENTS PUBLICS & SERVICES ESSENTIELS"
          surveyId={survey_id}
          data={nearestShopDataPart3}
          commentFieldName={NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT}
          theme={BLUE_THEME}
          needIsochrone
        />
      </SectionContainer>
      {/* end part 3 */}

      {/* start part 4 */}
      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={GRAY_THEME}
        />
        <MarketSurveySummary
          refsArray={linksRefs}
          isPdfRenderMode={true}
          isPart4={true}
          ref={resumeRef_4}
          theme={GRAY_THEME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={GRAY_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={political_ref}
          title="4.1 – UNE VISION SYNOPTIQUE DE LA COMMUNE ET DE SA DYNAMIQUE"
          surveyId={survey_id}
          theme={DARK_BLUE_THEME}
          commentFieldName={POLITICAL}
          personalizedContent={
            <SynopticVisionAndDynamicCommune
              data={synopticVisonAndDynamicCommuneData}
            />
          }
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={GRAY_THEME}
        />
        <MSTableSection
          isPdfRenderMode
          ref={demography_and_employment_ref}
          title="4.2 – DÉMOGRAPHIE ET EMPLOI : DYNAMIQUE COMPARÉE DES COMMUNES LIMITROPHES"
          surveyId={survey_id}
          data={demographieAndEmploiData}
          theme={GRAY_THEME}
          personnalisedGrid={{ containerClass: 'grid grid-cols-12' }}
          commentFieldName={DEMOGRAPHY_AND_EMPLOYMENT}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={GRAY_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={median_income_ref}
          title="4.3 – REVENUS : MÉDIANE ET VARIATION PAR UNITÉ DE CONSOMMATION"
          surveyId={survey_id}
          data={revenusMedianeVariationDataPart4}
          theme={GRAY_THEME}
          switchModeMapData={{
            labels: [
              'Revenu médian annuel disponible en 2021 (Insee 2024)',
              'Variation 2020-2021',
            ],
            name: MEDIAN_INCOME,
            namesMap: [REVENU_MEDIAN_ANNUEL_MAP, REVENU_VARIATION_2019_2020],
            currentMap: msMetaData ? msMetaData[MEDIAN_INCOME] : 'first',
          }}
          commentFieldName={MEDIAN_INCOME}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={GRAY_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={unemployment_ref}
          title={'4.4 – CHÔMAGE : TAUX ET VARIATION'}
          surveyId={survey_id}
          data={chomageTauxVariationDataPart4}
          theme={GRAY_THEME}
          switchModeMapData={{
            labels: [
              'Taux de chômage 2021 (Insee 2024)',
              'Différence 2021-2015',
            ],
            name: UNEMPLOYMENT,
            namesMap: [CHOMAGE_VARIATION_TAUX_MAP, CHOMAGE_VARIATION_DIFF_MAP],
            currentMap: msMetaData ? msMetaData[UNEMPLOYMENT] : 'first',
          }}
          commentFieldName={UNEMPLOYMENT}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={neighborhood_ref}
          title={'4.5 – POPULATION : NOMBRE ET VARIATION'}
          surveyId={survey_id}
          data={populationNombreVariationDataPart4}
          switchModeMapData={{
            labels: ['Population en 2021', 'Evolution 2019 - 2021'],
            name: NEIGHBORHOOD,
            currentMap: msMetaData ? msMetaData[NEIGHBORHOOD] : 'first',
            namesMap: [POPULATION_NB_MAP, POPULATION_VARIATION_MAP],
          }}
          theme={BLUE_THEME}
          commentFieldName={NEIGHBORHOOD}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={csp_ref}
          title={'4.6 – POPULATION : FOCUS - CADRES'}
          surveyId={survey_id}
          data={populationFocusCadreDataPart4}
          switchModeMapData={{
            labels: [
              'Part des cadres dans la population en 2021 (Insee 2024)',
              'Evolution 2015 - 2021',
            ],
            name: CSP,
            currentMap: msMetaData ? msMetaData[CSP] : 'first',
            namesMap: [POPULATION_CADRE_NB_MAP, POPULATION_CADRE_EVO_MAP],
          }}
          theme={DARK_BLUE_THEME}
          commentFieldName={CSP}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={from_25_to_39_yo_ref}
          title={'4.7 – POPULATION : FOCUS - PERSONNES DE 25 À 39 ANS'}
          surveyId={survey_id}
          data={populationFocus25To39DataPart4}
          theme={DARK_BLUE_THEME}
          switchModeMapData={{
            labels: [
              'Part des 25-39 ans et plus dans la population en 2021 (Insee 2024)',
              'Evolution 2015 - 2021',
            ],
            name: FROM_25_TO_39_YO,
            currentMap: msMetaData ? msMetaData[FROM_25_TO_39_YO] : 'first',
            namesMap: [FROM_25_TO_39_YO_NB_MAP, FROM_25_TO_39_YO_VARIATION_MAP],
          }}
          commentFieldName={FROM_25_TO_39_YO}
        />
      </SectionContainer>

      <SectionContainer hide={isPart1Only || isPart2Only}>
        <BannerSection
          isPdfRenderMode={true}
          title={'4- La commune dans son territoire'}
          surveyID={survey_id}
          theme={GRAY_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={age_ref}
          title={'4.8 – POPULATION : FOCUS - SENIORS (+ DE 65 ANS)'}
          surveyId={survey_id}
          data={populationSeniorDataPart4}
          theme={GRAY_THEME}
          switchModeMapData={{
            labels: [
              'Part des 65 ans et plus dans la population en 2021 (Insee 2024)',
              'Variation 2015-2021',
            ],
            name: SENIOR,
            currentMap: msMetaData ? msMetaData[SENIOR] : 'first',
            namesMap: [SENIOR_NB_MAP, SENIOR_VARIATION_MAP],
          }}
          commentFieldName={SENIOR}
        />
      </SectionContainer>
      {/* end part 4 */}

      <SectionContainer>
        <BannerSection
          isPdfRenderMode={true}
          title={'5 - Sources et définitions'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MarketSurveySummary
          refsArray={linksRefs}
          isPdfRenderMode={true}
          isPart5={true}
          ref={resumeRef_5}
          theme={DARK_BLUE_THEME}
        />
      </SectionContainer>

      <SectionContainer>
        <BannerSection
          isPdfRenderMode={true}
          title={'5 - Sources et définitions'}
          surveyID={survey_id}
          theme={DARK_BLUE_THEME}
        />
        <MSTableSection
          isPdfRenderMode={true}
          ref={definitionsRef}
          title="SOURCES ET DEFINITIONS"
          surveyId={survey_id}
          data={
            isPart1Only
              ? [{ type: DEFINITION_BLOCK, data: PART_1_DEFS }]
              : definition
          }
          theme={DARK_BLUE_THEME}
          commentFieldName={''}
          isSourceAndDefBlock
        />
      </SectionContainer>
    </>
  );
};

export default MarketSurveyPDF;
