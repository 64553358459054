import axios from 'axios';
import {params} from '../../api/Api';

export const GET_ARRAY_DATA_MARKET_SUCCESSFULL =
    'parcelApi/GET_ARRAY_DATA_MARKET_SUCCESSFULL';
export const GET_ARRAY_MARKET_SUCCESSFULL =
    'parcelApi/GET_ARRAY_MARKET_SUCCESSFULL';
export const GET_ARRAY_DATA_MARKET_FAILED =
    'parcelApi/GET_ARRAY_DATA_MARKET_FAILED';
const initialState = {
    tables: null,
    tables_data: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ARRAY_DATA_MARKET_SUCCESSFULL:
            return {
                ...state,
                tables_data: action.tables_data,
            };
        case GET_ARRAY_DATA_MARKET_FAILED:
            return {
                ...state,
                tables_data: null,
            };
        case GET_ARRAY_MARKET_SUCCESSFULL:
            return {
                ...state,
                tables: action.tables,
            };
        default:
            return state;
    }
};

export const getArrayMarket = (parcelId, type) => async (dispatch) => {
    return axios
        .get(
            `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${parcelId}/tableaux_menu`,
            {params: params}
        )
        .then((res) => {
            dispatch({
                type: GET_ARRAY_MARKET_SUCCESSFULL,
                tables: res.data,
            });
        });
};

export const getDataArrayMarket = (url) => async (dispatch) => {
    return axios
        .get(
            url,
            {params: params}
        )
        .then((res) => {
            dispatch({
                type: GET_ARRAY_DATA_MARKET_SUCCESSFULL,
                tables_data: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: GET_ARRAY_DATA_MARKET_FAILED,
            });
        });
};
