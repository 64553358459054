import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { Fragment } from 'react';

export default function Modal({
  open,
  onClose,
  children,
  maxWidth = 'max-w-md',
  maxWidthPx,
  fullHeightScreen,
  noNeedCloseInDialog,
  noNeedBtnClose,
  paddingZero,
  otherChildren,
}) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        style={{ zIndex: 1000 }}
        onClose={noNeedCloseInDialog ? () => {} : onClose}
      >
        {fullHeightScreen && !noNeedBtnClose && (
          <div
            onClick={onClose && onClose}
            style={{ top: '12px', right: '12px', zIndex: 10000 }}
            className="fixed cursor-pointer text-white hover:text-red-500"
          >
            <XCircleIcon className="h-10 w-10" />
          </div>
        )}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                style={{ maxWidth: maxWidthPx || 'auto' }}
                className={classNames(
                  'w-full relative transform bg-white text-left align-middle shadow-xl transition-all',
                  maxWidth,
                  { 'h-screen max-h-screen ': fullHeightScreen },
                  { 'rounded-lg': !fullHeightScreen }
                )}
              >
                {!fullHeightScreen && !noNeedBtnClose && (
                  <div
                    onClick={() => {
                      onClose && onClose();
                    }}
                    style={{ top: '12px', right: '12px' }}
                    className="absolute cursor-pointer hover:text-red-500 z-50"
                  >
                    <XCircleIcon className="h-6 w-6" />
                  </div>
                )}
                <div className="overflow-auto">{otherChildren}</div>

                <div
                  className={classNames(
                    'overflow-y-auto overflow-x-hidden max-h-screen',
                    { 'p-4': !fullHeightScreen && !paddingZero },
                    { 'p-0': fullHeightScreen || paddingZero }
                  )}
                >
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
