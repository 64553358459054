import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    LayersControl,
    MapContainer,
    Marker,
    Polygon,
    Polyline,
    Popup,
    TileLayer,
    Tooltip,
    useMap,
} from 'react-leaflet';

import Dropdown from './Dropdown';
import Button from './Button';
import HandleForm from '@components/HandleForm';
import {ConstructionIcon, DefaultIcon, EolienneIcon, fillBlueOptions, LandIcon, limeOptions, RiskIcon,} from './icons';
import IsMayorChange from '@components/molecules/IsMayorChange';
import MunicipalityAveragePrice from '@components/molecules/MunicipalityAveragePrice';

const {BaseLayer} = LayersControl;

function HandleReZoom() {
    const map = useMap();
    setInterval(() => {
        map.invalidateSize();
    }, 1000);
    return null;
}

const Map = ({
                 width,
                 showDropdown,
                 height,
                 center,
                 boxCartographyProperties,
                 theme,
                 maximumZoom,
                 zoom,
                 polygon,
                 polygons,
                 touchingParcels,
                 showMarkerParcelOnMap,
                 onSelectParcels,
                 handleClick,
                 flyTo,
                 zoomForRenderPdf,
                 paddingTopLeft,
             }) => {
    const [maxZoom, setMaxZoom] = useState();
    const [FlyTo, setFlyTo] = useState();

    const HandleSetMaxZoom = () => {
        const map = useMap();
        map.setView([center[1], center[0]], 18);
        map.invalidateSize();
        return null;
    };

    useEffect(() => {
        if (center) setMaxZoom(<HandleSetMaxZoom/>);
    }, [center]);

    useEffect(() => {
        if (flyTo && !maximumZoom) {
            setFlyTo(<HandleFlyTo paddingTopLeft={paddingTopLeft} myBounds={flyTo}/>);
        } else if (maximumZoom && flyTo) {
            return setFlyTo(<HandleZoomBlockForPdf myBounds={flyTo}/>);
        }
    }, [flyTo, maximumZoom]);

    const HandleZoomBlockForPdf = ({myBounds}) => {
        const map = useMap();
        if (myBounds) {
            map.setView(myBounds[0][0], 17);
            map.invalidateSize();
        }
        return null;
    };

    const HandleFlyTo = ({myBounds, paddingTopLeft}) => {
        const map = useMap();
        if(myBounds.isValid()) {
            if(paddingTopLeft) {
                map.fitBounds(myBounds, {paddingTopLeft: paddingTopLeft});
            } else {
                map.fitBounds(myBounds);
            }
        }
        

        map.invalidateSize();
        return null;
    };

    let map = [
        {
            name: 'light',
            url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
        },
        {
            name: 'world',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        },
        {
            name: 'dark',
            url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
        },
    ];

    const [url, setUrl] = useState(map[1].url);
    return (
        <>
            {showDropdown && (
                <div
                    className="absolute mt-3 flex items-center mr-3 p-3 justify-around"
                    style={{zIndex: 9999999999999, right: 50, width: 400}}
                >
                    <Dropdown
                        setUrl={() => setUrl(map[0].url)}
                        title={"Centres d'intérêt"}
                        icon="ri-creative-commons-sa-line"
                    />
                    <Dropdown
                        setUrl={() => setUrl(map[0].url)}
                        title={'Fonds de carte'}
                        icon="ri-map-2-line"
                    />
                </div>
            )}

            <div style={{height: height ? height : '85.33vh', width: width}}>
                <MapContainer
                    style={{height: '100%'}}
                    center={center ? center : [48.8534, 2.3488]}
                    zoom={zoomForRenderPdf ? zoomForRenderPdf : zoom ? zoom : 6}
                >
                    <LayersControl>
                        <BaseLayer checked name="Classique">
                            <TileLayer
                                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </BaseLayer>
                        <BaseLayer  name="Satellite">
                            <TileLayer
                                attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
                                url="https://wmts.geopf.fr/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileRow={y}&TileCol={x}"
maxZoom={20}
maxNativeZoom={19}
                            />
                        </BaseLayer>
                        <BaseLayer name="Lumière">
                            <TileLayer
                                attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
                                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                            />
                        </BaseLayer>
                        <BaseLayer name="Nuit">
                            <TileLayer
                                attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
                                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                            />
                        </BaseLayer>
                        <BaseLayer name="Cadastre (superposé)">
                            <TileLayer
                                attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
                                          url="https://wmts.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&STYLE=PCI vecteur&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
          maxZoom={20}
          maxNativeZoom={19}
                            />
                        </BaseLayer>
                        {maxZoom}
                        {FlyTo}
                    </LayersControl>

                    {touchingParcels?.features.map((v, i) => (
                        <HandleForm
                            handleClick={handleClick}
                            key={i}
                            value={v}
                            onSelectParcels={onSelectParcels}
                        />
                    ))
                    }
                    {boxCartographyProperties &&
                    boxCartographyProperties?.type === 'simple' &&
                    !boxCartographyProperties?.markers ? (
                        <Polygon
                            pathOptions={{
                                color: boxCartographyProperties?.color
                                    ? boxCartographyProperties.color
                                    : 'purple',
                                fillColor: boxCartographyProperties?.color
                                    ? boxCartographyProperties.color
                                    : 'purple',
                            }}
                            positions={boxCartographyProperties.coordinates}
                        />
                    ) : boxCartographyProperties?.type === 'multiple' ? (
                        <>
                            {boxCartographyProperties.coordinates.map((v) => (
                                <>
                                    <Polygon
                                        pathOptions={{color: v.color, fillColor: v.color}}
                                        positions={v.coordinates[0].map((v) => v.slice().reverse())}
                                    >
                                        <Tooltip sticky>
                                            <>
                                                <p className="my-1 text-base">
                                                    Nom de la commune : {v.properties.name}
                                                </p>
                                                <p className="my-1 text-base">
                                                    Rang : {v.properties.rank}
                                                </p>
                                                <p className="my-1 text-base">
                                                    {v.properties.metric + '<br/>' + v.properties.unit}
                                                </p>
                                            </>
                                        </Tooltip>
                                    </Polygon>
                                </>
                            ))}
                        </>
                    ) : boxCartographyProperties?.type === 'sncf' ? (
                        <>
                            {
                                <Polyline
                                    pathOptions={limeOptions}
                                    positions={boxCartographyProperties?.linePath}
                                />
                            }
                            {
                                <>
                                    <Marker
                                        icon={LandIcon}
                                        position={boxCartographyProperties?.source}
                                    >
                                        <Popup/>
                                    </Marker>
                                </>
                            }
                            {
                                <Marker
                                    icon={DefaultIcon}
                                    position={boxCartographyProperties?.target}
                                >
                                    <Popup/>
                                </Marker>
                            }
                        </>
                    ) : boxCartographyProperties?.type === 'simple' && boxCartographyProperties?.markers ? (
                        <>
                            <Polygon
                                pathOptions={{
                                    color:
                                        boxCartographyProperties?.icon_type === 'construction'
                                            ? '#20CA98'
                                            : 'purple',
                                    fillColor:
                                        boxCartographyProperties?.icon_type === 'construction'
                                            ? '#20CA98'
                                            : 'purple',
                                }}
                                positions={boxCartographyProperties.coordinates}
                            />
                            {boxCartographyProperties?.poi &&
                                boxCartographyProperties.poi.map((v, i) => (
                                    <Marker
                                        key={i}
                                        icon={
                                            boxCartographyProperties?.icon_type === 'construction'
                                                ? ConstructionIcon
                                                : RiskIcon
                                        }
                                        position={[v.geometry[0], v.geometry[1]]}
                                    >
                                        <Popup>
                                            {boxCartographyProperties?.icon_type !==
                                            'construction' ? (
                                                <>
                                                    <div>
                                                        <p className="my-0">Marseille</p>
                                                        <p className="my-0">Métropole</p>
                                                        <p className="my-0">CJM34</p>
                                                        <p className="my-0">465 m2</p>
                                                        <p className="my-0">R456</p>
                                                    </div>
                                                    <div className="flex justify-center">
                                                        <Button title="Évaluer"/>
                                                    </div>
                                                </>
                                            ) : (
                                                <div>
                                                    <p className="my-0">{v.data?.name}</p>
                                                </div>
                                            )}
                                        </Popup>
                                    </Marker>
                                ))}
                        </>
                    ) : (
                        boxCartographyProperties?.markers && (
                            <>
                                {boxCartographyProperties?.poi &&
                                    boxCartographyProperties.poi.map((v, i) => (
                                        <Marker
                                            key={i}
                                            icon={
                                                boxCartographyProperties?.icon_type === 'construction'
                                                    ? ConstructionIcon
                                                    : boxCartographyProperties?.icon_type === 'eolienne'
                                                        ? EolienneIcon
                                                        : RiskIcon
                                            }
                                            position={
                                                boxCartographyProperties?.icon_type === 'construction'
                                                    ? [v[0], v[1]]
                                                    : [v.geometry[0], v.geometry[1]]
                                            }
                                        >
                                            <Popup>
                                                {boxCartographyProperties?.icon_type !==
                                                'construction' &&
                                                boxCartographyProperties?.icon_type !== 'eolienne' ? (
                                                    <>
                                                        <div>
                                                            <p className="my-0">Marseille</p>
                                                            <p className="my-0">Métropole</p>
                                                            <p className="my-0">CJM34</p>
                                                            <p className="my-0">465 m2</p>
                                                            <p className="my-0">R456</p>
                                                        </div>
                                                        <div className="flex justify-center">
                                                            <Button title="Évaluer"/>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div>
                                                        <p className="my-0">{v.data?.name}</p>
                                                    </div>
                                                )}
                                            </Popup>
                                        </Marker>
                                    ))}
                            </>
                        )
                    )}
                    {polygons?.length > 0 && (
                        polygons.map((v,i) => (
                            <Polygon key={`polygon_${i}`} pathOptions={{fillColor: v.color }} positions={v.position}>
                                <Popup>
                                    <div>
                                        <p className={'text-base font-bold pb-2'}>{v.properties.name}</p>
                                        { v.properties.mayor_name && <p className={'text-sm pb-2'}>{`Maire : ${v.properties.mayor_name}`}</p> }
                                        <IsMayorChange className={'text-sm pb-2'} national_code={v.properties.national_code} />
                                        { v.properties.population && <p className={'text-sm pb-2'}>{`Population en 2017 : ${v.properties.population}`}</p> }
                                        <MunicipalityAveragePrice className={'text-sm pb-2'} national_code={v.properties.national_code} />
                                    </div>
                                </Popup>
                            </Polygon>

                        ))
                    )}
                    {polygon?.length > 0 && (
                        <>
                            <Polygon pathOptions={fillBlueOptions} positions={polygon}/>
                            {showMarkerParcelOnMap && !boxCartographyProperties?.source && (
                                <Marker
                                    icon={LandIcon}
                                    position={[polygon[0][0][0], polygon[0][0][1]]}
                                />
                            )}
                        </>
                    )}
                    {height && <HandleReZoom/>}
                </MapContainer>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        autocomplete: state.parcelApi.rating.autocomplete,
        parcelId: state.parcelApi.rating.parcelId,
        dataParcel: state.parcelApi.rating.dataParcel,
        parcelMap: state.parcelApi.rating.parcelMap,
    };
};

export default connect(mapStateToProps, null)(Map);
