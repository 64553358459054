import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const handleActiveTabs = (args) => {
  return args === window.location.pathname ? true : false;
};

const BottomNavbar = ({ listCategory }) => {
  const [offset, setOffset] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    const fix = () => {
      setTimeout(() => {
        setOffset(document.getElementById('navbar')?.offsetHeight);
      }, 100);
    };
    fix();
    window.addEventListener('resize', fix);
    return () => {
      window.removeEventListener('resize', fix);
    };
  });

  return (
    <nav
      id={'bottom-navbar'}
      className="bg-blue-darken flex text-white justify-between fixed w-full print:hidden z-30"
      style={{ top: `${offset}px` }}
    >
      <div className="ml-8 xl:ml-12">
        <ul
          style={{ margin: 0 }}
          className="pl-0 2xl:pl-8 flex 2xl:mx-2 items-center"
        >
          {listCategory?.map((value, i) => (
            <Link
              key={i}
              to={value.url}
              className={`transition duration-75 transform ${
                user && handleActiveTabs(value.url) ? 'bottomDynamic' : 'hide'
              }`}
              onClick={value.onClick}
            >
              <li className="md:mx-2 mb-0 p-2 2xl:p-4 text-xs 2xl:text-base">
                <button>{value.name}</button>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="text-right mr-4 hidden lg:flex flex-col justify-center text-xs 2xl:text-base">
        <div>N'hésitez pas à nous envoyer vos retours et attentes sur : </div>
        <a href="mailto:equipe@evalparcel.com" className="hover:underline">
          equipe@evalparcel.com
        </a>
      </div>
    </nav>
  );
};

export default BottomNavbar;
