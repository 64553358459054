import React, {forwardRef} from 'react';
import {Popup} from "react-leaflet";
import styled from 'styled-components'
import {DateTime} from "luxon";
import {formattingCurrency} from "../../../../../_helpers/formatting-helper";
import {XIcon} from "@heroicons/react/outline";
import {closePopup} from "../../../../../_helpers/_map-helper";

const StyledPop = styled(Popup)`
  background-color: white;
  border-radius: 0;
  width: 350px;
  height: auto;
  border-radius: 10px;
 .leaflet-popup-content-wrapper {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;


const ProgramPopup = forwardRef(({
                                     tran_id,
                                     title = "Information manquante",
                                     subtitle,
                                     address = "",
                                     housing_to_sale_count,
                                     free_to_buy_housing_count,
                                     average_price_init_per_square_meter,
                                     progress,
                                     release_date,
                                     is_selling_blocks,
                                     vat_type
                                 }, ref) => {


    return (
        <StyledPop id={`popup-program-${tran_id}`}>
            <div className={'absolute right-0 top-0'}>
                <button onClick={() => closePopup(ref)} className={'p-2'}><XIcon className={'w-5 h-5 text-blue-800 hover:text-blue-900'}/></button>
            </div>
            <div>
                <h2 className={"test-sm font-medium"}>{title}</h2>
                <h3 className={"text-sm font-bold"}>Promoteur : {subtitle}</h3>
                <h4 className={"text-sm font-medium"}>{address}</h4>

                <ul>
                    <li className={"text-sm font-medium"}>État d&apos;avancement : {progress}</li>
                    <li className={"text-sm font-medium"}>Date de début de com.
                        : {DateTime.fromISO(release_date).toFormat('MM/yyyy')}</li>
                    {is_selling_blocks &&
                        <li className={"text-sm font-medium"}>Vente en bloc: {is_selling_blocks ? "Oui" : "Non"}</li>}
                    {vat_type && <li className={"text-sm font-medium"}>TVA : {vat_type}</li>}
                </ul>

                <ul>
                    <li className={"text-sm font-medium"}>Nb. de logements mis en vente : {housing_to_sale_count}</li>
                    {free_to_buy_housing_count && <li>Nb. de logements disponible: {free_to_buy_housing_count}</li>}
                </ul>

                <ul>
                    <li className={"text-sm font-medium"}>Prix moyen au m2 des mises en vente
                        : {formattingCurrency(average_price_init_per_square_meter)}</li>
                </ul>
            </div>
        </StyledPop>
    )
})

export default ProgramPopup
