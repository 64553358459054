import React from 'react';
import classnames from 'classnames';

const MsBoxSimple1 = ({
  isPdfRenderMode,
  data,
  limitHeightBox,
  onDownloadLinkClick,
  isPlu,
}) => {
  const {
    titre_box,
    bas_de_box,
    metric_commune,
    metric,
    download_link,
    download_link_text,
  } = data;

  return (
    <div
      className={classnames(
        { 'h-auto': !isPdfRenderMode && !limitHeightBox },
        { 'h-44': limitHeightBox && !isPdfRenderMode },
        'px-4 py-2 bg-white shadow rounded-lg overflow-hidden border-2 border-gray-300 col-span-1'
      )}
    >
      <div className="flex flex-col h-full justify-between gap-y-4">
        <div
          className={classnames('font-bold', {
            'text-base': isPdfRenderMode,
            'text-xs 2xl:text-base': !isPdfRenderMode,
          })}
        >
          {titre_box}
        </div>
        <div
          className={classnames(
            {
              'text-sm':
                isPdfRenderMode &&
                (metric_commune || metric || '--').length >= 100,
              'text-xl': isPdfRenderMode,
              'text-xs 2xl:text-xl': !isPdfRenderMode,
            },
            'font-semibold'
          )}
        >
          {metric_commune || metric || '--'}
        </div>
        <div
          className={classnames(
            {
              'text-xs xl:text-sm': !isPdfRenderMode,
              'text-sm': isPdfRenderMode,
            },
            'font-medium text-gray-500 flex justify-between flex-nowrap items-end gap-x-4'
          )}
        >
          <span>{bas_de_box?.trim()?.length ? bas_de_box : '--'}</span>
          {download_link && download_link_text && !isPdfRenderMode && (
            <a
              href={download_link}
              onClick={(e) => {
                if (isPlu) {
                  e.preventDefault();
                  onDownloadLinkClick();
                }
              }}
              className="text-tiny xl:text-xs inline-block overflow-ellipsis cursor-pointer text-blue-500 hover:underline"
              download={download_link_text}
              target="_blank"
              rel="noopener noreferrer"
            >
              {download_link_text}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default MsBoxSimple1;
