import { useSelectedParcels } from '../../../../../hooks/useSelectedParcels';
import useSWR from 'swr';
import React, { useEffect, useState } from 'react';
// import MarkerCollectionLayer from "../MarkerCollectionLayer";
// import MarketSurveyLegendLayer from "../../Map/Legends/MarketSurveyLegendLayer";
// import BuildingPermitCollectionLayer from "../BuildingPermitCollectionLayer";
import MarkerPolylabelLayer from '../MarkerPolylabelLayer';
import { Error } from '../../../../utils/Error';
// import Loading from "../../../../utils/Loading";
import { getFetcher } from '../../../../../api/fetcher';
import MSProgramCollection from './MSProgramCollection';
import LoadingSection from '../../../../MarketSurveys/sections/LoadingSection';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

const MSProgramsHighlightLayer = ({ dataURL }) => {
  const map = useMap();
  const { fieldIds } = useSelectedParcels();
  const [fieldsData, setFieldsData] = useState(null);

  const [geoJSONGlobal, setGeoJSONGlobal] = useState([]);

  useEffect(() => {
    if (geoJSONGlobal.length && fieldsData?.features.length) {
      const fieldsGeoJson = fieldsData?.features;
      const geojsonLayer = L.geoJSON([...geoJSONGlobal, ...fieldsGeoJson]);
      if (geojsonLayer && geojsonLayer.getBounds().isValid()) {
        map.fitBounds(geojsonLayer.getBounds());
      }
    }
  }, [geoJSONGlobal, fieldsData]);

  const { data, error } = useSWR(dataURL, getFetcher);

  useEffect(() => {
    console.log();
  }, [dataURL, data]);

  if (!data) return <LoadingSection />;
  if (error) return <Error error={error} />;

  return (
    <>
      <MSProgramCollection
        dataURL={dataURL}
        setGeoJSONGlobal={setGeoJSONGlobal}
      />
      <MarkerPolylabelLayer
        fieldIds={fieldIds}
        onReadyData={(data) => setFieldsData(data)}
      />
    </>
  );
};

export default MSProgramsHighlightLayer;
