import React from 'react';
import classNames from 'classnames';

const RadioButtonGroup = ({
  name,
  onChange,
  options = [],
  keyValue,
  keyLabel,
  handleBlur,
  value,
  inLine = false,
}) => {
  return (
    <div
      className={classNames(
        'w-full flex ',
        { 'flex-col gap-2': !inLine },
        { 'justify-between gap-x-4': inLine }
      )}
    >
      {options.map((item, index) => (
        <label
          key={index}
          className="flex items-center gap-x-1 text-xs text-gray-700 cursor-pointer"
        >
          <input
            onBlur={handleBlur}
            type="radio"
            value={item[keyValue]}
            checked={value === item[keyValue]}
            name={name}
            onChange={onChange}
          />
          <span>{item[keyLabel]}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
