import React from 'react';
import { useSelector } from 'react-redux';
import GeoJsons from './GeoJsons';

export default function ParcelsGeoJson() {
  const { parcels, checkedParcels } = useSelector(
    (state) => state.ciblageResult
  );

  const filteredParcels = parcels
    ? parcels.filter((item) => checkedParcels.includes(item.parcelle_id))
    : [];

  return <GeoJsons layers={filteredParcels} />;
}
