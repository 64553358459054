import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deserializeAttributesForMSGiven } from '../../_helpers/jsonapi-deserializer';
import _ from 'lodash';
import ProgramsTable from '../Programs/ProgramsTable';
import ProgramsMap from '../Programs/ProgramsMap';
import SectionHeader from '../utils/SectionHeader';
import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import { usePrograms } from '../../hooks/usePrograms';
import {
  getCurrentSurvey,
  getPermitContruire,
  getProgrammes,
  programmeSliceActions,
} from '../../store/slices/programme';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommunesParcelles,
  getGeoJsonFields,
  othersSliceActions,
} from '../../store/slices/others';
import {
  MarketSurveyActions,
  updateMarketSurveyMeta,
} from '../../store/slices/marketSurvey';
import { DEFAULT_FILTER_PROGRAMME } from '../../_helpers/constant';
import { InformationCircleIcon } from '@heroicons/react/solid';

const MarketSurveyPage = () => {
  const { survey_id } = useParams();
  const { fieldIds, setFieldIds } = useSelectedParcels();
  const { setActivePrograms, activePrograms, tableProgramRef } = usePrograms(); // determine the  displayed element cumulative height
  const [relatedRating, setRelatedRating] = useState(null);
  const [parcellePivotId, setParcelePivotId] = useState(null);
  const [paddingTop, setPaddingTop] = useState(0);
  const [loading, setLoading] = useState(true);
  const [msMetaData, setMsMetaData] = useState(null);
  const [defaultValuesFilter, setDefaultValuesFilter] = useState(null);
  const [filterAndSelectedProgrammes, setFilterAndSelectedProgrammes] =
    useState(null);
  const dispatch = useDispatch();
  const { marketSurvey: data } = useSelector((state) => state.programme);
  const [currentPopupProgrammeData, setCurrentPopupProgrammeData] =
    useState(null);
  const refMapData = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const tableContainerRef = useRef(null);
  const rightSideRef = useRef(null);

  const handleRemoveProgram = (tran_id) => {
    // refresh list of programs
    getProgrammesFunc(fieldIds, {
      ...msMetaData?.filterProgramme,
      bonus_tran_ids: msMetaData?.filterProgramme?.bonus_tran_ids?.filter(
        (item) => item !== tran_id
      ),
    });
  };

  useEffect(() => {
    dispatch(getCurrentSurvey(survey_id));
  }, [survey_id]);

  useEffect(() => {
    if (data) {
      const msMetaDataLocal = data.data.attributes.meta;
      let activeProgramsLocal = [];

      setMsMetaData(msMetaDataLocal);

      const { related_rating, real_estate_programs } = !data.related_rating
        ? deserializeAttributesForMSGiven(data)
        : data;

      setRelatedRating(related_rating);

      // set active programmes ids
      if (real_estate_programs && real_estate_programs.length) {
        activeProgramsLocal = _.map(real_estate_programs, (item) => {
          return parseInt(item.reference);
        });
        setActivePrograms(activeProgramsLocal);
      }

      // set pivot parcelle id
      if (related_rating) {
        setParcelePivotId(related_rating.pivot_parcelle_id);
      }

      if (related_rating?.targets_parcels) {
        const parcelIds = _.map(
          related_rating?.targets_parcels,
          (target_parcel) => {
            return target_parcel.parcel_id;
          }
        );

        if (parcelIds?.length) {
          dispatch(getGeoJsonFields(parcelIds));
          dispatch(getCommunesParcelles(parcelIds));

          setFieldIds(parcelIds);

          const temp =
            msMetaDataLocal?.filterProgramme || DEFAULT_FILTER_PROGRAMME;
          const filter = {
            ...temp,
            ...(activeProgramsLocal.length
              ? { bonus_tran_ids: activeProgramsLocal }
              : {}),
          };

          setDefaultValuesFilter(filter);
          getProgrammesFunc(parcelIds, filter);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const fixPaddingTop = () => {
      const navbar = document.getElementById('navbar');
      const underNavbar = document.getElementById('bottom-navbar');
      if (navbar && underNavbar) {
        setPaddingTop(navbar.clientHeight + underNavbar.clientHeight);
      }
    };

    fixPaddingTop();

    const reset = () => {
      dispatch(programmeSliceActions.reset());
      dispatch(othersSliceActions.reset());
      dispatch(MarketSurveyActions.reset());
    };

    //reset data before All
    reset();

    setTimeout(() => {
      dispatch(othersSliceActions.setMaxErrorNb(5));
    }, 1000);

    window.addEventListener('resize', fixPaddingTop);
    return () => {
      // reset on unmounted
      reset();

      window.removeEventListener('resize', fixPaddingTop);
    };
  }, []);

  const getProgrammesFunc = async (_fieldIds = [], _filters = null) => {
    if (_fieldIds.length) {
      setLoading(true);
      await dispatch(
        getProgrammes({ parcelle_ids: _fieldIds, ...(_filters || {}) })
      );
      await dispatch(
        getPermitContruire({
          parcel_ids: _fieldIds,
          filter_geo: _filters?.filter_geo || 'isochrone',
        })
      );
      setLoading(false);
    }
  };

  const handleChangeFilterAndSelectedProgrammes = useCallback(
    (type) => (values) => {
      const _filterAndSelectedProgrammes = {
        ...(filterAndSelectedProgrammes || {}),
        ...(type === 'filter'
          ? { filterProgramme: values }
          : { selectedProgrammes: values }),
      };
      const { selectedProgrammes, filterProgramme } =
        _filterAndSelectedProgrammes;

      setFilterAndSelectedProgrammes(_filterAndSelectedProgrammes);

      if (type === 'filter' && refMapData.current) {
        refMapData.current.closePopupProgramme();
        setCurrentPopupProgrammeData(null);
      }

      const currentFilter = {
        ...(filterProgramme ||
          msMetaData?.filterProgramme ||
          DEFAULT_FILTER_PROGRAMME),
        bonus_tran_ids:
          selectedProgrammes?.map((item) => item.attributes.tran_id) ||
          activePrograms,
      };

      type === 'filter' && getProgrammesFunc(fieldIds, currentFilter);

      setMsMetaData({
        ...(msMetaData || {}),
        filterProgramme: currentFilter,
      });

      // update meta data
      dispatch(
        updateMarketSurveyMeta({
          survey_id,
          meta: {
            ...(msMetaData || {}),
            filterProgramme: currentFilter,
          },
        })
      );
    },
    [activePrograms, msMetaData, filterAndSelectedProgrammes, fieldIds]
  );

  useEffect(() => {
    if (isReady && tableContainerRef.current && rightSideRef.current) {
      const fixHeighttableContainer = () => {
        const sectionHeader = document.getElementById('section_header');
        if (sectionHeader) {
          tableContainerRef.current.style.height = `${
            rightSideRef.current.clientHeight - sectionHeader.clientHeight
          }px`;
        }
      };
      setTimeout(() => {
        fixHeighttableContainer();
      }, 100);

      window.addEventListener('resize', fixHeighttableContainer);
    }
  }, [isReady]);

  const handleChangeCurrentPopupData = useCallback((data) => {
    setCurrentPopupProgrammeData(data);
  }, []);

  return (
    <div
      style={{
        marginTop: paddingTop,
        height: `calc(100vh - ${paddingTop}px)`,
      }}
      className="flex w-full flex-col sm:flex-row"
    >
      <div ref={rightSideRef} className="hidden lg:block w-1/2 flex-col">
        <SectionHeader
          onReady={() => setIsReady(true)}
          offsetTop={paddingTop}
          title={'Étude de marché'}
          address={`${relatedRating?.address || ''} ${
            relatedRating?.postcode
          } ${relatedRating?.town}`}
          pivot_parcelle_id={parcellePivotId}
          defaultValuesFilter={defaultValuesFilter}
          onChangeFilter={handleChangeFilterAndSelectedProgrammes('filter')}
        />
        {isReady && (
          <div ref={tableContainerRef} className="pt-2">
            <ProgramsTable
              loading={loading}
              refMapData={refMapData}
              setLoading={setLoading}
              onRemoveProgram={handleRemoveProgram}
              handleRemoveProgram
              currentProgrammePopupData={currentPopupProgrammeData}
              setCurrentProgrammePopupData={setCurrentPopupProgrammeData}
              onChangeSelectedProgrammes={handleChangeFilterAndSelectedProgrammes(
                'programme-selected'
              )}
              ref={tableProgramRef}
            />
          </div>
        )}
      </div>
      <div className="hidden h-full w-1/2 bg-blue-200 lg:block">
        <ProgramsMap
          refMapData={refMapData}
          isScrollWheelZoom
          loading={loading}
          readOnlyMode={false}
          onChangeCurrentProgrammePopupDate={handleChangeCurrentPopupData}
        />
      </div>

      <div
        style={{
          marginTop: paddingTop,
          height: `calc(100vh - ${paddingTop}px)`,
        }}
        className="w-full flex lg:hidden items-center flex-col justify-center px-5"
      >
        <InformationCircleIcon className="text-blue-500 w-20 h-20" />
        <p className="text-center">
          Pour garantir une expérience optimale, veuillez créer votre rapport
          d'études sur un plus grand écran.
        </p>
      </div>
    </div>
  );
};

export default MarketSurveyPage;
