import React, { Fragment, memo, useEffect, useState } from 'react';
import { LayerGroup, useMap, ZoomControl, WMSTileLayer } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import SwitchLayerContainer from './Layers/SwitchLayerContainer';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import * as L from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { MarketSurveySlice } from '../../../store/slices/marketSurvey';
import {
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI,
  PARCEL_LAYER,
  POI_MAP,
} from '../../../_helpers/constant';
import { titleLayersEdm } from './Map/tileLayer';
import swal from 'sweetalert';
import { fluxData } from './Map/MapDataContainer';
import 'leaflet.snogylop';

export const getNbImagesTile = (map) => {
  // Récupérer les limites de la carte après le zoom
  var bounds = map.getBounds();

  // Calculer le niveau de zoom actuel
  var currentZoom = map.getZoom();

  // Calculer les coordonnées des tuiles aux coins de la carte
  var topLeft = map.project(bounds.getNorthWest(), currentZoom);
  var bottomRight = map.project(bounds.getSouthEast(), currentZoom);

  var k = 0;

  // Parcourir chaque tuile dans les limites de la carte
  for (
    var x = Math.floor(topLeft.x / 256);
    x <= Math.floor(bottomRight.x / 256);
    x++
  ) {
    for (
      var y = Math.floor(topLeft.y / 256);
      y <= Math.floor(bottomRight.y / 256);
      y++
    ) {
      k++;
    }
  }
  return k++;
};

// TODO
//import PoiTransitPathLayer from "./Layers/PoiTransitPathLayer";

const GestureHandlingSetter = () => {
  const map = useMap();
  if (!map) return null;
  map.gestureHandling.enable();
  map.addHandler('gestureHandling', GestureHandling);
  return null;
};

const IsochroneLayer = ({ parcelId, mode }) => {
  const map = useMap();
  const {
    accessiblityIsochoneData,
    isochroneCarFeature,
    errorCarIsochrone,
    errorAccessiblityIsochone,
    showedMsgErrorIsochrone,
  } = useSelector((state) => state.marketSurvey);
  const { isPdfMode } = useSelector((state) => state.marketSurvey);
  const { communesParcelles } = useSelector((state) => state.others);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mode === 'pied' && accessiblityIsochoneData?.length && parcelId) {
      const currentData = accessiblityIsochoneData.find(
        (item) => item.parcelId === parcelId
      )?.data;
      if (currentData) {
        L.geoJSON(currentData, {
          invert: true,
          style: { fillOpacity: 0.2, color: '#009900', fillColor: '#484848' },
        }).addTo(map);
      }
    }
    if (mode === 'car' && isochroneCarFeature) {
      L.geoJSON(isochroneCarFeature, {
        invert: true,
        style: { fillOpacity: 0.2, color: '#2B6CB0', fillColor: '#484848' },
      }).addTo(map);
    }
  }, [accessiblityIsochoneData, parcelId, isochroneCarFeature]);

  useEffect(() => {
    if (errorCarIsochrone && mode === 'car' && communesParcelles?.length) {
      L.geoJSON(communesParcelles, {
        style: { fillOpacity: 0, color: '#2B6CB0' },
      }).addTo(map);
    }
  }, [errorCarIsochrone, communesParcelles]);

  useEffect(() => {
    if (
      (errorAccessiblityIsochone || errorCarIsochrone) &&
      !showedMsgErrorIsochrone &&
      !isPdfMode
    ) {
      dispatch(MarketSurveySlice.actions.setShowedMsgErrorIsochrone(true));
      swal({ icon: 'info', text: 'Erreur serveur, Isochrone' });
    }
  }, [
    errorCarIsochrone,
    errorAccessiblityIsochone,
    showedMsgErrorIsochrone,
    isPdfMode,
  ]);

  return null;
};

const Layers = ({
  name,
  isMobility,
  noCluster,
  enableZonageLayer,
  readOnlyMode,
  dataType,
  isScrollWheelZoom,
  dataUrl,
  items = null,
  noNeedMarker,
  onLoadLegendMarketSurvey,
  needIsochrone,
  parcelId,
  onLoadMap,
  programmesNeufs,
  needIsochroneVoiture,
  eductionMap,
  currentTileLayer,
  isPdfMode,
  onlySelected,
  onChangeCurrentProgrammePopupDate,
  currentProgrammePopupData,
  data,
  onChangeTitleLayer,
  activeFlux = [],
  programMapRef,
}) => {
  const map = useMap();
  const [currentTileLayerState, setCurrentTileLayer] = useState(
    titleLayersEdm.lumiere.layer // default tileLayer
  );
  const [showWMSTile, setShowWMSTile] = useState(true);

  useEffect(() => {
    setShowWMSTile(false);
    const timeout = setTimeout(() => {
      setShowWMSTile(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, [currentTileLayerState, activeFlux]);

  useEffect(() => {
    const loadMapTimeout = setTimeout(() => {
      onLoadMap && onLoadMap(map);
    }, 500);

    return () => {
      clearTimeout(loadMapTimeout);
    };
  }, []);

  useEffect(() => {
    if (
      dataType === POI_MAP ||
      dataType === NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI
    ) {
      setCurrentTileLayer(titleLayersEdm.classique.layer);
    }
  }, [dataType]);

  useEffect(() => {
    let timeout = null;
    if (currentTileLayer) {
      setCurrentTileLayer(<></>);
      timeout = setTimeout(() => {
        if (currentTileLayer === 'satellite') {
          const tile = titleLayersEdm[currentTileLayer].layer();
          setCurrentTileLayer(tile);
        } else {
          setCurrentTileLayer(titleLayersEdm[currentTileLayer].layer);
        }
        onChangeTitleLayer && onChangeTitleLayer(currentTileLayer);
      }, 200);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [currentTileLayer, onChangeTitleLayer]);

  return (
    <>
      {currentTileLayerState}
      {(dataType === PARCEL_LAYER || enableZonageLayer) && showWMSTile && (
        <>
          {fluxData.map(({ key, render }) => {
            if (activeFlux.includes(key)) {
              return <Fragment key={key}>{render}</Fragment>;
            }
            return <Fragment key={key} />;
          })}
        </>
      )}
      <ZoomControl position={'topright'} />
      <LayerGroup>
        {!isScrollWheelZoom && <GestureHandlingSetter />}
        <SwitchLayerContainer
          isMobility={isMobility}
          name={name}
          noCluster={noCluster}
          data={data}
          currentProgrammePopupData={currentProgrammePopupData}
          onLoadLegendMarketSurvey={onLoadLegendMarketSurvey}
          readOnlyMode={readOnlyMode}
          dataType={dataType}
          dataUrl={dataUrl}
          items={items}
          isPdfMode={isPdfMode}
          noNeedMarker={noNeedMarker}
          needIsochrone={needIsochrone}
          needIsochroneVoiture={needIsochroneVoiture}
          programmesNeufs={programmesNeufs}
          eductionMap={eductionMap}
          onlySelected={onlySelected}
          programMapRef={programMapRef}
          onChangeCurrentProgrammePopupDate={onChangeCurrentProgrammePopupDate}
        />
        {needIsochrone && <IsochroneLayer parcelId={parcelId} mode="pied" />}
        {needIsochroneVoiture && <IsochroneLayer mode="car" />}
      </LayerGroup>
    </>
  );
};

export default memo(Layers);
