import React, { useState, useCallback } from 'react';
import { MapContainer, LayersControl, Popup } from 'react-leaflet';
import Tilelayers from './TileLayers';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import CommunesGeoJson from './CommunesGeoJson';
import onClosePopup from '../../../_helpers/map-helpers/onClosePopup';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ParcelsGeoJson from './ParcelsGeoJson';
import { TitleLayerSelecter } from '../../molecules/DataVisualisations/Map/MapDataContainer';
import { titleLayersEdm } from '../../molecules/DataVisualisations/Map/tileLayer';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
});

const center = [46.483229, 2.660239];

const LoadingMap = () => {
  return (
    <div
      style={{ zIndex: 100000 }}
      className="absolute h-full w-full top-0 left-0 bg-black opacity-50 flex items-center justify-center"
    >
      <ReactLoading
        type="spinningBubbles"
        color="white"
        height={'15%'}
        width={'15%'}
        className="flex justify-center items-center"
      />
    </div>
  );
};

const LegendsCommunes = () => {
  return (
    <div
      style={{ zIndex: 99999 }}
      className="absolute bottom-1 left-1 rounded-md p-2 bg-white"
    >
      <div className="flex items-center flex-nowrap">
        <span className="block w-9 h-6 bg-red-500" />
        <span className="text-xs whitespace-nowrap ml-2">
          Commune(s) sélectionnée(s)
        </span>
      </div>
      <div className="flex items-center flex-nowrap mt-2">
        <span className="block w-9 h-6 bg-blue-500" />
        <span className="text-xs whitespace-nowrap ml-2">
          Commune(s) non sélectionnée(s)
        </span>
      </div>
    </div>
  );
};

export function MapCiblage() {
  const [currentTileLayer, setCurrentTileLayer] = useState(
    titleLayersEdm.satellite.layer
  );
  const { loading, communes, returnSearchCommunesBy } = useSelector(
    (state) => state.ciblageCreate
  );
  const [popup, setPopup] = useState(null);
  const { pathname } = useLocation();
  const params = useParams();

  const handleChangeTitleLayer = (key) => {
    setCurrentTileLayer(titleLayersEdm[key].layer);
  };

  const handleShowpopup = useCallback((e, children) => {
    setPopup({ position: e.latlng, children });
    onClosePopup(() => setPopup(null));
  }, []);

  return (
    <div className="w-full h-full relative">
      {loading?.map && <LoadingMap />}
      {!!communes?.length &&
        pathname === '/ciblage/new' &&
        !returnSearchCommunesBy && <LegendsCommunes />}
      <MapContainer
        center={center}
        zoom={6}
        className="w-full h-full"
        scrollWheelZoom
      >
        {currentTileLayer}
        <TitleLayerSelecter ciblage onChange={handleChangeTitleLayer} />
        {popup && <Popup position={popup.position}>{popup.children}</Popup>}

        {/* show only on page create targets */}
        {pathname === '/ciblage/new' && (
          <CommunesGeoJson onShowPopup={handleShowpopup} />
        )}

        {/* show only in parcel */}
        {pathname === `/ciblage/${params.id}` && <ParcelsGeoJson />}
      </MapContainer>
    </div>
  );
}

export default MapCiblage;
