import React, { useEffect, useState } from 'react';
import { Range } from 'rc-slider';
import useDebounce from '../../../_helpers/useDebounce';
import { isEqual } from 'lodash';

export default function RangeInput({
  min,
  max,
  step = 1,
  disabled,
  onChange,
  value,
  onClick,
}) {
  const [valueRange, setValueRange] = useState(null);

  const handleChangeInput = (type) => (e) => {
    setValueRange(
      type === 'min'
        ? [e.target.value, value ? value[1] : max]
        : [value ? value[0] : min, e.target.value]
    );
  };

  const handleChangeRange = (value) => {
    setValueRange(value);
  };

  useDebounce(
    () => {
      if (!isEqual(value, valueRange)) {
        onChange && onChange(valueRange);
      }
    },
    [valueRange],
    500
  );

  useEffect(() => {
    if (value && value.length) {
      setValueRange(value);
    }
  }, [value]);

  return (
    <div
      className="w-full flex items-center"
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(e);
      }}
    >
      <input
        style={{ width: 70 }}
        value={valueRange ? valueRange[0] : min}
        className="text-sm border-1 border-gray-300 rounded-md mr-2 p-2"
        onChange={handleChangeInput('min')}
        disabled={disabled}
      />
      <Range
        onChange={handleChangeRange}
        value={valueRange || [min, max]}
        min={min}
        max={max}
        step={step}
        className="w-full flex-grow mx-2"
        allowCross={false}
        disabled={disabled}
        trackStyle={[
          {
            backgroundColor: !disabled ? '#1BA3EF' : '#CCC',
            borderColor: !disabled ? '#1BA3EF' : '#CCC',
          },
        ]}
        handleStyle={[
          {
            backgroundColor: !disabled ? '#1BA3EF' : '#CCC',
            borderColor: !disabled ? '#1BA3EF' : ' #CCC',
            width: '1.5rem',
            height: '1.5rem',
            marginTop: '-10px',
          },
          {
            backgroundColor: !disabled ? '#1BA3EF' : '#CCC',
            borderColor: !disabled ? '#1BA3EF' : '#CCC',
            width: '1.5rem',
            height: '1.5rem',
            marginTop: '-10px',
          },
        ]}
        railStyle={{ backgroundColor: '#EBEBEB' }}
      />
      <input
        style={{ width: 70 }}
        value={valueRange ? valueRange[1] : max}
        className="text-sm border-1 border-gray-300 rounded-md ml-2 p-2"
        onChange={handleChangeInput('max')}
        disabled={disabled}
      />
    </div>
  );
}
