import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../containers/home/Home';
import Login from '../containers/auth/Login';
// import Register from '../containers/auth/Register';
import Target from '../containers/ciblage-v2/Target';
import Study from '../containers/study/Study';
import Rating from '../containers/rating/Rating';
import BottomNavbar from './BottomNavbar';
import ForgetPassword from '../containers/auth/ForgetPassword';
import Navbar from '../components/molecules/Navbar';
import Api from '../../src/api/Api';
// import NewTarget from '../containers/target/NewTarget';
// import NewTargetResults from '../containers/target/NewTargetResults';
import NewRating from '../containers/rating/NewRating';
// import TargetList from '../containers/target/TargetList';
import ListRating from '../containers/rating/ListRating';
import DetailledRating from '../containers/rating/DetailledRating';
import ProjectShowPdf from '../containers/project/ProjectShowPdf';
import Loader from '../components/Loader';
import ResetLinkConfirmation from '../containers/auth/ResetLinkConfirmation';
import LogRocket from 'logrocket';
import QuickCheckModal from './modals/QuickCheckModal';
import ConceptNote from '../containers/project/ConceptNote/ConceptNote';
import ReactTooltip from 'react-tooltip';
import { PrivateRoute } from './molecules/PrivateRoute';
import { useAuth } from '../hooks/useAuth';
import { useModal } from '../hooks/useModal';
import { AdminRoute } from './molecules/AdminRoute';
import MarketSurveys from './MarketSurveys';
import MarketSurveyPage from './MarketSurveyPage';
// import MarketSurveyProvider from '../providers/marketSurveyProvider';
// import ProgramsProvider from '../providers/programsProvider';
import { SWRConfig } from 'swr';
import MarketSurveyPDF from './MarketSurveyPDF';
import SharingModal from './modals/SharingModal';
import ExportPDFModal from './modals/ExportPDFModal';
import ScrollToTop from './utils/ScrollToTop';
import UserSettings from './UserSettings';
// import { Redirect } from 'react-router-dom';
import CiblageNew from '../containers/ciblage-v2/CiblageNew';
import CiblageList from '../containers/ciblage-v2/CiblageList';
import RegisterCapem from '../containers/auth/RegisterCapem';
import CiblageListParcels from '../containers/ciblage-v2/CiblageListParcels';
import AdminUser from '../containers/admin-user/AdminUser';
import DemandeRendezVous from '../containers/demande-rendez-vous/DemandeRendezVous';
import CguModal from './modals/CguModal';
import FormUserInformations from '../containers/auth/FormUserInformations';
import ActivateTrialPage from '../containers/activate-trial/ActivateTrial';
import ErrorHandler from './error-handler/ErrorHandler';

const App = ({ loading }) => {
  const { user, sessionAuthHeader, logOut, isAdmin } = useAuth();
  const {
    isQuickCheckModalOpened,
    setIsQuickCheckModalOpened,
    currentRatingID,
    isSharingModalOpened,
    isExportPDFModalOpened,
  } = useModal();

  // rename to getCrsfToken();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      LogRocket.init('e-attract/evalparcel', {
        dom: {
          textSanitizer: false,
          inputSanitizer: false,
        },
      });

      Rollbar.configure({
        transform: function (obj) {
          obj.sessionURL = LogRocket.sessionURL;
          return obj;
        },
      });
    }
    Api.defaults.headers.common['X-CSRF-TOKEN'] =
      document.querySelector('[name=csrf-token]').content;

    if (sessionAuthHeader) {
      Api.defaults.headers.common['X-USER-EMAIL'] = sessionAuthHeader.email;
      Api.defaults.headers.common['X-USER-TOKEN'] = sessionAuthHeader.token;
    }
  }, []);

  return (
    <Router>
      {loading && <Loader />}
      {isQuickCheckModalOpened && <QuickCheckModal />}
      {isSharingModalOpened && <SharingModal />}
      {isExportPDFModalOpened && <ExportPDFModal />}
      <CguModal />
      <ErrorHandler />
      <ReactTooltip
        id="tooltip-global"
        place="left"
        type="light"
        effect="float"
        multiline={true}
        className="z-99999 w-80 md:w-96"
      />
      <div className="flex flex-col mx-auto">
        <Switch>
          <PrivateRoute path="/" exact>
            <Navbar />
            <Home />
            {/*<Redirect to={'/ratings/new'} />*/}
          </PrivateRoute>
          <Route
            path="/users/sign_in"
            exact
            render={() => {
              return <Login />;
            }}
          />
          <Route
            path="/users/password/new"
            exact
            render={() => {
              return <ForgetPassword />;
            }}
          />
          <Route
            path="/users/confirmation/new"
            exact
            render={() => {
              return <ResetLinkConfirmation />;
            }}
          />
          <Route
            path="/users/sign_up"
            exact
            render={() => {
              return <FormUserInformations />;
            }}
          />
          <Route
            path="/capem/users/sign_up"
            exact
            render={() => {
              return <RegisterCapem />;
            }}
          />
          <Route
            path="/users/new"
            exact
            render={() => {
              return <ForgetPassword />;
            }}
          />
          <PrivateRoute path={'/mon-compte'} exact>
            <Navbar>
              <BottomNavbar
                listCategory={
                  [
                    // {
                    //     id: 1, url: '/v1/targets', name: 'Accueil ciblage'
                    // },
                    // {
                    //     id: 2, url: '/v1/targets/list', name: 'Parcelles ciblées'
                    // },
                    // {
                    //     id: 3, url: '/v1/targets/new', name: 'Nouveau ciblage'
                    // }
                  ]
                }
              />
            </Navbar>
            <UserSettings />
          </PrivateRoute>

          {/* ciblage V2 routes */}
          <AdminRoute path="/ciblage" exact>
            <Navbar isAdmin={isAdmin}>
              <BottomNavbar
                listCategory={[
                  { id: 1, url: '/ciblage', name: 'Accueil ciblage' },
                  {
                    id: 2,
                    url: '/ciblage/list',
                    name: 'Parcelles ciblées',
                  },
                  { id: 3, url: '/ciblage/new', name: 'Nouveau ciblage' },
                ]}
              />
            </Navbar>
            <Target />
          </AdminRoute>
          <AdminRoute path="/ciblage/list" exact>
            <CiblageList />
          </AdminRoute>
          <AdminRoute path="/ciblage/new">
            <CiblageNew />
          </AdminRoute>
          <AdminRoute path="/ciblage/:id">
            <CiblageListParcels />
          </AdminRoute>
          <AdminRoute path="/admin-user">
            <AdminUser />
          </AdminRoute>
          <PrivateRoute path="/ratings" exact>
            <Navbar>
              <BottomNavbar
                listCategory={[
                  { id: 1, url: '/ratings', name: 'Accueil' },
                  {
                    id: 2,
                    url: '/ratings/list',
                    name: 'Mes terrains',
                  },
                  { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
                ]}
              />
            </Navbar>
            <Rating />
          </PrivateRoute>
          <PrivateRoute path="/ratings/new" exact>
            <Navbar>
              <BottomNavbar
                listCategory={[
                  { id: 1, url: '/ratings', name: 'Accueil' },
                  {
                    id: 2,
                    url: '/ratings/list',
                    name: 'Mes terrains',
                  },
                  { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
                ]}
              />
            </Navbar>
            <NewRating />
          </PrivateRoute>
          <PrivateRoute path={'/ratings/list'} exact>
            <Navbar>
              <BottomNavbar
                listCategory={[
                  { id: 1, url: '/ratings', name: 'Accueil' },
                  {
                    id: 2,
                    url: '/ratings/list',
                    name: 'Mes terrains',
                  },
                  { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
                ]}
              />
            </Navbar>
            <ListRating />
          </PrivateRoute>
          <PrivateRoute path={'/ratings/list/:id'} exact>
            <Navbar>
              <BottomNavbar
                listCategory={[
                  { id: 1, url: '/ratings', name: 'Accueil' },
                  {
                    id: 2,
                    url: '/ratings/list',
                    name: 'Mes terrains',
                  },
                  { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
                ]}
              />
            </Navbar>
            <ListRating />
          </PrivateRoute>
          <PrivateRoute path={'/market-survey/:survey_id'} exact>
            <Navbar>
              <BottomNavbar
                listCategory={[
                  { id: 1, url: '/ratings', name: 'Accueil' },
                  {
                    id: 2,
                    url: '/ratings/list',
                    name: 'Mes terrains',
                  },
                  { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
                ]}
              />
            </Navbar>
            <MarketSurveys />
          </PrivateRoute>
          <Route path={'/market-survey/:survey_id/detail/pdf'} exact>
            <MarketSurveyPDF />
          </Route>
          <Route path={'/market-survey/:survey_id/detail/pdf/part_1'} exact>
            <MarketSurveyPDF part={1} />
          </Route>
          <Route path={'/market-survey/:survey_id/detail/pdf/part_2'} exact>
            <MarketSurveyPDF part={2} />
          </Route>
          <PrivateRoute path={'/market-survey/:survey_id/detail'} exact>
            <Navbar>
              <BottomNavbar
                listCategory={[
                  { id: 1, url: '/ratings', name: 'Accueil' },
                  {
                    id: 2,
                    url: '/ratings/list',
                    name: 'Mes terrains',
                  },
                  { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
                ]}
              />
            </Navbar>
            <SWRConfig value={{ revalidateOnFocus: false }}>
              <ScrollToTop />
              <MarketSurveyPage />
            </SWRConfig>
          </PrivateRoute>
          <Router path="/no-access" exact>
            <Navbar />
            <BottomNavbar
              listCategory={[
                { id: 1, url: '/no-access', name: 'Accueil' },
                {
                  id: 2,
                  url: '/no-access',
                  name: 'Mes terrains',
                },
              ]}
            />
          </Router>
          <PrivateRoute
            path="/partage/rapport/:token_survey/etude"
            isShare
            exact
          >
            <Navbar>
              <BottomNavbar
                listCategory={[
                  {
                    id: 1,
                    url: '/ratings',
                    name: 'Accueil',
                  },
                  {
                    id: 2,
                    url: '/ratings/list',
                    name: 'Mes terrains',
                  },
                  {
                    id: 3,
                    url: '/ratings/new',
                    name: 'Nouvelle recherche',
                  },
                ]}
              />
            </Navbar>
            <SWRConfig value={{ revalidateOnFocus: false }}>
              <ScrollToTop />
              <MarketSurveyPage isShare />
            </SWRConfig>
          </PrivateRoute>
          <PrivateRoute path="/studies" exact>
            <Navbar />
            <BottomNavbar
              listCategory={[
                { id: 1, url: '/studies', name: 'Accueil' },
                {
                  id: 2,
                  url: '/studies',
                  name: 'Mes terrains',
                },
              ]}
            />
            <Study />
          </PrivateRoute>
          <PrivateRoute path={'/projects/:uuid'} exact>
            <ProjectShowPdf />
          </PrivateRoute>
          <Route path={'/demande-rendez-vous'} exact>
            <Navbar />
            <BottomNavbar
              listCategory={[
                { id: 1, url: '/ratings', name: 'Accueil' },
                {
                  id: 2,
                  url: '/ratings/list',
                  name: 'Mes terrains',
                },
                { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
              ]}
            />
            <DemandeRendezVous />
          </Route>
          <PrivateRoute path={'/activate-trial'} exact>
            <Navbar />
            <BottomNavbar
              listCategory={[
                { id: 1, url: '/ratings', name: 'Accueil' },
                {
                  id: 2,
                  url: '/ratings/list',
                  name: 'Mes terrains',
                },
                { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
              ]}
            />
            <ActivateTrialPage />
          </PrivateRoute>
          <Route path={'/ratings/:id'} exact>
            <Navbar />
            <BottomNavbar
              listCategory={[
                { id: 1, url: '/ratings', name: 'Accueil' },
                {
                  id: 2,
                  url: '/ratings/list',
                  name: 'Mes terrains',
                },
                { id: 3, url: '/ratings/new', name: 'Nouvelle recherche' },
              ]}
            />

            <DetailledRating />
          </Route>
          <Route path={'/ratings/quick_check/:uuid/pdf'} exact>
            <ConceptNote />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
export default App;
