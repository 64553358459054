import React from 'react';
import MapDataContainer from '../../molecules/DataVisualisations/Map/MapDataContainer';
import SectionTitle from './SectionTitle';
import classNames from 'classnames';

const MsMapContainer = ({
  isPdfRenderMode = false,
  title,
  url,
  dataType,
  items,
  needIsochrone,
  parcelId,
  isSelectedProgramsMap,
  needIsochroneVoiture,
  eductionMap,
  data,
  minHeightContent,
  hFull = false,
  defaultTileLayer,
  nameMap,
  isMobility,
}) => {
  return (
    <div
      className={classNames('flex flex-col', { 'h-full': hFull })}
      style={
        isPdfRenderMode ? { height: `100%` } : { minHeight: minHeightContent }
      }
    >
      <SectionTitle title={title} />
      <MapDataContainer
        isMobility={isMobility}
        name={nameMap}
        data={data}
        defaultTileLayer={defaultTileLayer}
        isSelectedProgramsMap={isSelectedProgramsMap}
        dataURL={url}
        dataType={dataType}
        isScrollWheelZoom={false}
        items={items}
        needIsochrone={needIsochrone}
        needIsochroneVoiture={needIsochroneVoiture}
        parcelId={parcelId}
        eductionMap={eductionMap}
        minHeight={minHeightContent}
        hFull={hFull}
      />
    </div>
  );
};

export default MsMapContainer;
