import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMsContentMaxHeight } from '../../../hooks/useMaxHeight';
import { useSelector } from 'react-redux';

const SummaryContent = ({ isPdfRenderMode, refItem, disabled = false }) => {
  const handleClickItem = (ref) => {
    if (ref?.current && !disabled) {
      const spacing = [
        document.getElementById('bottom-navbar')?.clientHeight || 0,
        document.getElementById('navbar')?.clientHeight || 0,
        document.getElementById('ms-page-header')?.clientHeight || 0,
        document.querySelector('.section-banner')?.clientHeight || 0,
      ].reduce((prev, curr) => prev + curr, 0);

      window.scroll({
        top: ref.current?.offsetTop - spacing - (isPdfRenderMode ? 48 : 0),
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <h3
        className={classNames(
          {
            'text-xl': isPdfRenderMode,
            'text-sm 2xl:text-xl': !isPdfRenderMode,
          },
          'cursor-pointer hover:underline font-semibold'
        )}
        onClick={() => handleClickItem(refItem?.summary?.ref)}
      >
        {refItem?.summary?.label}
      </h3>
      <ul className={'list-disc pl-10'}>
        {refItem?.content?.map((item, index) => (
          <li
            key={index}
            className={classNames(
              {
                'text-base': isPdfRenderMode,
              },
              {
                'text-xs 2xl:text-base': !isPdfRenderMode,
              },
              'cursor-pointer hover:underline'
            )}
            onClick={() => handleClickItem(item.ref)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </>
  );
};

const MarketSurveySummary = forwardRef(
  (
    {
      // isPdfRenderMode = false,
      isPart1 = false,
      isPart2 = false,
      isPart3 = false,
      isPart4 = false,
      isPart5 = false,
      // theme,
      refsArray,
    },
    ref
  ) => {
    const maxHeight = useMsContentMaxHeight();
    // const { isCapemUser } = useAuth();
    const [offset, setOffset] = useState(0);
    const { isPdfMode: isPdfRenderMode } = useSelector((state) => state.others);

    useEffect(() => {
      const fixOffset = () => {
        setOffset(
          !isPdfRenderMode
            ? document.getElementById('navbar')?.offsetHeight +
                document.getElementById('bottom-navbar')?.offsetHeight +
                document.getElementById('ms-page-header')?.offsetHeight
            : document.getElementById('first_section_banner')?.offsetHeight
        );
      };
      fixOffset();
      window.addEventListener('resize', fixOffset);
      return () => {
        window.removeEventListener('resize', fixOffset);
      };
    }, []);

    return (
      <div
        ref={ref}
        className={classNames('w-full', {
          'px-8': !isPdfRenderMode,
        })}
        style={
          isPdfRenderMode
            ? {
                height: 'calc(1240px - 56px - 48px)',
                maxHeight: 'calc(1240px - 56px - 48px)',
              }
            : {
                top: `${offset}px`,
                minHeight: `calc(100vh - ${maxHeight}px)`,
                paddingTop: isPdfRenderMode ? `${maxHeight}px` : '0px',
              }
        }
      >
        <div className="flex flex-row flex-wrap px-8 py-16 text-lg">
          <div className="w-1/2">
            <div
              className={classNames('p-4', {
                'border border-green-700 border-2 rounded-lg': isPart1,
                'opacity-25': refsArray?.length && refsArray[0].isHidden,
              })}
            >
              <SummaryContent
                isPdfRenderMode={isPdfRenderMode}
                refItem={refsArray?.length && refsArray[0]}
                disabled={refsArray?.length && refsArray[0].isHidden}
              />
            </div>
            <div
              className={classNames('p-4', {
                'border border-blue-500 border-2 rounded-lg': isPart2,
                'opacity-25': refsArray?.length && refsArray[1].isHidden,
              })}
            >
              <SummaryContent
                isPdfRenderMode={isPdfRenderMode}
                refItem={refsArray?.length && refsArray[1]}
                disabled={refsArray?.length && refsArray[1].isHidden}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div
              className={classNames('p-4', {
                'border border-blue-800 border-2 rounded-lg': isPart3,
                'opacity-25': refsArray?.length && refsArray[2].isHidden,
              })}
            >
              <SummaryContent
                isPdfRenderMode={isPdfRenderMode}
                refItem={refsArray?.length && refsArray[2]}
                disabled={refsArray?.length && refsArray[2].isHidden}
              />
            </div>
            <div
              className={classNames('p-4', {
                'border border-gray-600 border-2 rounded-lg': isPart4,
                'opacity-25': refsArray?.length && refsArray[3].isHidden,
              })}
            >
              <SummaryContent
                isPdfRenderMode={isPdfRenderMode}
                refItem={refsArray?.length && refsArray[3]}
                disabled={refsArray?.length && refsArray[3].isHidden}
              />
            </div>
            <div
              className={classNames('p-4', {
                'border border-blue-800 border-2 rounded-lg': isPart5,
                'opacity-25': refsArray?.length && refsArray[4].isHidden,
              })}
            >
              <SummaryContent
                isPdfRenderMode={isPdfRenderMode}
                refItem={refsArray?.length && refsArray[4]}
                disabled={refsArray?.length && refsArray[4].isHidden}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default MarketSurveySummary;
