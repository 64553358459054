import React, { useEffect, useState } from 'react';
import { useModal } from '../../hooks/useModal';
import { useHistory } from 'react-router-dom';
import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import swal from 'sweetalert';
import {
  createMarketSurvey,
  getMarketSurvey,
} from '../../services/market-survey';
import { updateRating } from '../../_services/rating-service';
import { softDeleteRating } from '../../store/slices/marketSurvey';

const handleDate = (date) => {
  let myDate = new Date(date);
  let parsedDate = myDate.toLocaleDateString();
  return parsedDate;
};

const Table = ({
  data,
  limit,
  view,
  type,
  // byDate,
  hasCheckbox,
  setCheckboxData,
  firstArray,
  onDeleted,
}) => {
  const [dataParsed, setDataParsed] = useState(data);

  useEffect(() => {
    setDataParsed(data);
  }, [data]);

  useEffect(() => {
    if (limit) {
      let copy = data.slice().reverse();
      let mynew = copy.filter((v, i) => i < limit);
      setDataParsed(mynew);
    }
  }, [limit]);

  const updateCheckboxesAsRadioInput = (data, event) => {
    document
      .querySelectorAll('input[type=checkbox]')
      .forEach((el) => (el.checked = false));

    event.target.checked = true;
    setCheckboxData(data);
  };

  const {
    setIsQuickCheckModalOpened,
    setCurrentRatingID,
    // currentRatingID,
    setRatingIds,
  } = useModal();
  const { setFieldIds } = useSelectedParcels();

  const inputCheckboxRefs = [];

  const handleDeleteProject = (id, isQC, data) => {
    swal('Voulez-vous vraiment supprimer ce terrain ?', {
      buttons: {
        cancel: 'Annuler',
        reset: {
          text: 'Supprimer',
          value: true,
        },
      },
      icon: 'warning',
      dangerMode: true,
    }).then(async (confirm) => {
      if (confirm) {
        const res = await softDeleteRating(id, isQC);
        if (res.status === 204 || res.status === 200) {
          swal('', 'Supprimé !', 'success');
          onDeleted && onDeleted(data);
        } else {
          swal('', 'Il y a eu une erreur lors de la suppression.', 'error');
        }
      }
    });
  };

  const handleAccessToMarketSurvey = (
    current_rating_id,
    market_survey_id = null
  ) => {
    // console.log(' handleAccessToMarketSurvey clicked ')
    // console.log(!!market_survey_id)

    !!market_survey_id
      ? getMarketSurvey(market_survey_id, () => {
          setIsQuickCheckModalOpened(false);
          window.location.assign(`/market-survey/${market_survey_id}/detail`);
        })
      : updateRating(current_rating_id, { land: { status: 3 } }).then(() => {
          createMarketSurvey(
            { rating_id: current_rating_id, comments: {} },
            () => {
              setIsQuickCheckModalOpened(false);
              window.location.assign(`/market-survey/${id}`);
            }
          );
        });
  };

  useEffect(() => {
    firstArray && inputCheckboxRefs[0] && inputCheckboxRefs[0].click();
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div className="-my-2 overflow-x-auto ">
        <div className={'py-2 align-middle inline-block min-w-full '}>
          <div
            className={`${
              view ? 'bg-white rounded-2xl p-4' : null
            } shadow overflow-hidden border-b border-gray-200 sm:rounded-lg`}
          >
            <table
              className={`${
                !view && 'bg-white'
              } min-w-full divide-y divide-gray-200 table-auto`}
            >
              <thead className="bg-gray-50 text-large">
                <tr>
                  {type === 'ratings_projects' && (
                    <th
                      scope="col"
                      className="w-1/3 px-3 text-left text-xs font-bold text-gray-900 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                  )}
                  <th
                    scope="col"
                    className="w-1/3 px-3 text-left text-xs font-bold text-gray-900 uppercase tracking-wider"
                  >
                    Commune
                  </th>
                  <th
                    scope="col"
                    className="w-1/3 px-3 text-left text-xs font-bold text-gray-900 uppercase tracking-wider"
                  >
                    Adresse
                  </th>
                  {(type === 'ratings' || type === 'ratings_projects') && (
                    <th
                      scope="col"
                      className="w-1/5 px-3 text-left text-xs font-bold text-gray-900 uppercase tracking-wider"
                    >
                      Statut
                    </th>
                  )}
                  <th scope="col" className="relative px-3 w-1/5">
                    <span className="sr-only">Voir</span>
                  </th>
                </tr>
              </thead>
              <tbody className={'divide-y divide-gray-200'}>
                {dataParsed?.map((data, index) =>
                  type === 'ratings' ? (
                    <tr key={`${data.quick_check_uuid}___${index}`}>
                      <td className="w-1/3 whitespace-nowrap text-sm font-medium px-3">
                        <div className="flex items-center">
                          {hasCheckbox && (
                            <input
                              type="checkbox"
                              onClick={(e) =>
                                updateCheckboxesAsRadioInput(
                                  data.pivot_parcelle_id,
                                  e
                                )
                              }
                              className="mr-3 h-4 w-4 cursor-pointer"
                            />
                          )}
                          {data.town}
                        </div>
                      </td>
                      <td className="w-1/3 whitespace-nowrap text-sm font-medium px-3">
                        <div className="text-sm text-black">{data.address}</div>
                      </td>
                      {(type === 'ratings' || type === 'ratings_projects') && (
                        <td
                          key={`${data.quick_check_uuid}___${index}`}
                          className="w-1/5 whitespace-nowrap text-sm font-medium px-3"
                        >
                          {data.status === 2 ? (
                            <div className="text-xs text-center p-1 justify-center  flex item-center rounded-lg bg-blue-400 text-white">
                              Éval Détaillée
                            </div>
                          ) : data.status === 3 ? (
                            <div className="text-xs text-center p-1 justify-center  flex item-center rounded-lg bg-green-400 text-white">
                              Étude
                            </div>
                          ) : data.status === 1 ? (
                            <div className="text-xs text-center p-1 justify-center  flex item-center rounded-lg bg-orange-400 text-white">
                              Quick check
                            </div>
                          ) : (
                            data.status === 4 && (
                              <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-red-400 text-white">
                                Abandonné
                              </div>
                            )
                          )}
                        </td>
                      )}

                      <td className="w-1/5 whitespace-nowrap text-sm font-medium px-3">
                        <div className="mx-4 py-1 flex ">
                          {(type === 'ratings' ||
                            type === 'ratings_projects') &&
                            data.status === 3 && (
                              <button
                                type="button"
                                onClick={() => {
                                  setCurrentRatingID(data.id);
                                  setRatingIds([data.id]);
                                  handleAccessToMarketSurvey(
                                    dataParsed.id,
                                    data.market_survey_id
                                  );
                                }}
                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                              >
                                Voir
                              </button>
                            )}
                          {data.status === 1 && (
                            <button
                              type="button"
                              onClick={() => {
                                setCurrentRatingID(data.id);
                                setRatingIds([data.id]);
                                setIsQuickCheckModalOpened(true);
                              }}
                              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                            >
                              Voir
                            </button>
                          )}
                          {data.status === 2 && (
                            <button
                              type="button"
                              onClick={() =>
                                handleAccessToMarketSurvey(
                                  dataParsed.id,
                                  data.market_survey_id
                                )
                              }
                              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
                            >
                              Voir
                            </button>
                          )}
                          {data.status === 4 && (
                            <button
                              type="button"
                              onClick={() => {
                                setCurrentRatingID(data.id);
                                setRatingIds([data.id]);
                                setIsQuickCheckModalOpened(true);
                              }}
                              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Voir
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : type === 'projects' ? (
                    <tr
                      key={data.quick_check_uuid}
                      className="border-b-1 border-t-1 border-gray-300"
                    >
                      <td className="w-1/3 whitespace-nowrap text-sm font-medium px-3">
                        <div className="flex items-center">
                          {hasCheckbox && (
                            <input
                              type="checkbox"
                              onClick={(e) =>
                                updateCheckboxesAsRadioInput(
                                  data.pivot_parcelle_id,
                                  e
                                )
                              }
                              className="mr-3 h-4 w-4 cursor-pointer"
                            />
                          )}
                          {data.town}
                        </div>
                      </td>
                      <td className=" w-1/3 whitespace-nowrap text-sm font-medium px-3">
                        <div className="text-sm text-black">{data.address}</div>
                      </td>
                      {(type === 'ratings' || type === 'ratings_projects') && (
                        <td className=" w-1/5 whitespace-nowrap text-sm font-medium px-3">
                          {data.status === 2 ? (
                            <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-blue-400 text-white">
                              Éval Détaillée
                            </div>
                          ) : data.status === 3 ? (
                            <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-green-400 text-white">
                              Étude
                            </div>
                          ) : data.status === 1 ? (
                            <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-orange-400 text-white">
                              Quick check
                            </div>
                          ) : (
                            data.status === 4 && (
                              <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-red-400 text-white">
                                Abandonné
                              </div>
                            )
                          )}
                        </td>
                      )}

                      <td className="w-1/5 whitespace-nowrap text-sm font-medium px-3">
                        <div className="mx-4 py-1">
                          {(type !== 'ratings' ||
                            type === 'ratings_projects') &&
                            data.status === 3 && (
                              <button
                                type="button"
                                onClick={() => {
                                  setCurrentRatingID(data.id);
                                  setRatingIds([data.id]);
                                  handleAccessToMarketSurvey(
                                    dataParsed.id,
                                    data.market_survey_id
                                  );
                                }}
                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                              >
                                Voir
                              </button>
                            )}
                          {data.status === 1 && (
                            <button
                              type="button"
                              onClick={() => {
                                setCurrentRatingID(data.id);
                                setRatingIds([data.id]);
                                setIsQuickCheckModalOpened(true);
                              }}
                              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Voir
                            </button>
                          )}
                          {data.status === 2 && (
                            <button
                              type="button"
                              onClick={() => {
                                handleAccessToMarketSurvey(
                                  dataParsed.id,
                                  data.market_survey_id
                                );
                              }}
                              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Voir
                            </button>
                          )}
                          {data.status === 4 && (
                            <button
                              type="button"
                              onClick={() => {
                                setCurrentRatingID(data.id);
                                setRatingIds([data.id]);
                                setIsQuickCheckModalOpened(true);
                              }}
                              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Voir
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    type === 'ratings_projects' && (
                      <tr
                        key={data.quick_check_uuid}
                        className="border-b-1 border-t-1 border-gray-300"
                      >
                        <td className="whitespace-nowrap text-sm font-medium px-3">
                          <div className="flex items-center">
                            {hasCheckbox && (
                              <input
                                type="checkbox"
                                onClick={(e) =>
                                  updateCheckboxesAsRadioInput(data, e)
                                }
                                className="mr-3 h-4 w-4 cursor-pointer"
                                defaultChecked={firstArray && index === 0}
                                ref={(ref) => (inputCheckboxRefs[index] = ref)}
                              />
                            )}
                            {data.name_etude
                              ? data.name_etude
                              : data.status === 3
                              ? 'Étude de marché'
                              : 'Quick Check'}
                          </div>
                        </td>
                        <td className="whitespace-nowrap text-sm font-medium p-3">
                          {data.town}
                        </td>
                        <td className="whitespace-nowrap text-sm font-medium p-3">
                          {data.address}
                        </td>
                        {(type === 'ratings' ||
                          type === 'ratings_projects') && (
                          <td className="whitespace-nowrap text-sm font-medium px-3">
                            {data.status === 2 ? (
                              <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-blue-400 text-white">
                                Éval Détaillée
                              </div>
                            ) : data.status === 3 ? (
                              <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-green-400 text-white">
                                Étude
                              </div>
                            ) : data.status === 1 ? (
                              <div className="text-xs text-center p-1 justify-center  flex item-center rounded-lg bg-orange-400 text-white">
                                Quick check
                              </div>
                            ) : (
                              data.status === 4 && (
                                <div className="text-xs text-center p-1 justify-center flex item-center rounded-lg bg-red-400 text-white">
                                  Abandonné
                                </div>
                              )
                            )}
                          </td>
                        )}

                        <td className="whitespace-nowrap text-sm font-medium px-3">
                          <div className="flex gap-x-4">
                            <>
                              {(type !== 'ratings' ||
                                type === 'ratings_projects') &&
                                data.status === 3 && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setCurrentRatingID(data.id);
                                      setRatingIds([data.id]);
                                      handleAccessToMarketSurvey(
                                        data.id,
                                        data.market_survey_id
                                      );
                                    }}
                                    className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                                  >
                                    Voir
                                  </button>
                                )}
                              {data.status === 1 && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCurrentRatingID(data.id);
                                    setRatingIds([data.id]);
                                    setFieldIds(data.targets_parcels[0]);
                                    setIsQuickCheckModalOpened(true);
                                  }}
                                  className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Voir
                                </button>
                              )}
                              {data.status === 2 && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleAccessToMarketSurvey(
                                      data.id,
                                      data.market_survey_id
                                    )
                                  }
                                  className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Voir
                                </button>
                              )}
                              {data.status === 4 && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCurrentRatingID(data.id);
                                    setRatingIds([data.id]);
                                    setIsQuickCheckModalOpened(true);
                                  }}
                                  className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Voir
                                </button>
                              )}
                            </>
                            <button
                              onClick={() =>
                                handleDeleteProject(
                                  data.status === 4 || data.status === 1
                                    ? data.id
                                    : data.market_survey_id,
                                  data.status === 4 || data.status === 1,
                                  data
                                )
                              }
                              className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800"
                            >
                              Supprimer
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
