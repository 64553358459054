import {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import {
  BUBBLE_CHART,
  CONTENT,
  DEFINITION_BLOCK,
  DEMOGRAPHY_AND_EMPLOYMENT,
  DUAL_INPUT_TABLE,
  ECOLE_UNIVERSITY_MAP,
  EDUCATION_SECTION,
  HOSPITAL,
  LINE_CHART,
  LIST_BOXES,
  MAP,
  MAP_PROGRAMME,
  MARCHE_LOC_APPART,
  MEV_LIM_MAP,
  MOBILITY,
  MOBILITY_MAP,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_MAP,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI,
  NEUF_MEV_LIM_MAP,
  POI_MAP,
  PROGRAMS_NEUFS_MAP,
  SINGLE_INPUT_TABLE,
  TABLE_SWITCH,
  TOWN_HALL,
  VERTICAL_BAR_CHART,
} from './constant';
import { useModal } from '../hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectedParcels } from '../hooks/useSelectedParcels';
import { usePrograms } from '../hooks/usePrograms';
import moment from 'moment';
import {
  getCurrentSurvey,
  getCurrentSurveyByToken,
  getPermitContruire,
  getProgrammesEDM,
  programmeSliceActions,
} from '../store/slices/programme';
import {
  MarketSurveyActions,
  getAccessibilityIsochoneData,
  getDataFromUrl,
  getDataFromUrls,
  getDataLogementSocial,
  getInfoDetailsSelectedProgrammes,
  getIsochroneCarFeature,
  getOsmDataCarto,
  getProgrammesNeufs,
  getTransportCarto,
  updateMarketSurveyName,
} from '../store/slices/marketSurvey';
import {
  getCommunesParcelles,
  getGeoJsonFields,
  othersSliceActions,
} from '../store/slices/others';
import _ from 'lodash';
import { useMetadataMs } from '../hooks/useChangeOnMSMap';

const getDataWithSection = async (data) => {
  return await Promise.all(
    data.map(async (item) => {
      return {
        ...item,
        dataSection: await Promise.all(
          item.dataSection.map(async (el) => {
            return {
              title: el.title,
              data: await getDataFromUrl(el.dataURL),
            };
          })
        ),
      };
    })
  );
};

const defautValue1_4 = [
  {
    externalData: null,
    type: SINGLE_INPUT_TABLE,
  },
  // {
  //   externalData: null,
  //   type: SINGLE_INPUT_TABLE,
  // },
];

const useGetDataEdm = ({ isPdf }) => {
  const [data, setData] = useState({
    parcelData: [],
    resumeProgrammePart1: [],
    definition: [
      {
        data: null,
        type: DEFINITION_BLOCK,
      },
    ],
    offersDispoDataPart1: [
      // {
      //   externalData: null,
      //   type: SINGLE_INPUT_TABLE,
      // },
      {
        externalData: null,
        type: SINGLE_INPUT_TABLE,
      },
    ],
    reservationsPart1: [{ data: null, type: DUAL_INPUT_TABLE }],
    reservationsVariationDataPart1: defautValue1_4,
    programmesSelected2_3Part1: [
      {
        externalData: null,
        type: SINGLE_INPUT_TABLE,
      },
    ],
    programmesNeufDataPart2: [
      {
        type: MAP_PROGRAMME,
        defaultTileLayer: 'classique',
      },
      {
        data: [],
        type: CONTENT,
        title: '',
        isNeufProgrammeLoc: true,
      },
    ],
    neufMevDataPart2: [
      {
        externalData: null,
        type: SINGLE_INPUT_TABLE,
      },
      {
        title: 'Focus sur le nombre de réservations nettes',
        data: null,
        type: VERTICAL_BAR_CHART,
      },
    ],
    neufMevLimotropheDataPart2: [
      {
        data: null,
        type: MAP,
      },
      {
        title: '',
        externalData: null,
        type: SINGLE_INPUT_TABLE,
      },
    ],
    neufPriceByTVADataPart2: [
      {
        title: '',
        externalData: null,
        type: SINGLE_INPUT_TABLE,
      },
      {
        data: null,
        type: LINE_CHART,
        title: 'Évolution du prix moyen / m² toutes TVA en graphe',
      },
    ],
    neufMevLimotrophe12monthDataPart2: [
      {
        type: MAP,
        data: null,
      },
      {
        externalData: null,
        type: SINGLE_INPUT_TABLE,
        title: 'Communes limitrophes au terrain évalué',
      },
    ],
    neufResParTydeAcquereurDataPart2: [
      {
        externalData: null,
        type: SINGLE_INPUT_TABLE,
        title: 'Nombre de ventes dans la commune du terrain évalué',
      },
      {
        type: VERTICAL_BAR_CHART,
        title: 'Évolution en histogramme',
        data: null,
      },
    ],
    neufResNetteDataPart2: [
      {
        type: SINGLE_INPUT_TABLE,
        title: 'Nombre de ventes dans la commune du terrain évalué',
        externalData: null,
      },
      {
        data: null,
        type: LINE_CHART,
        title: 'Focus sur % Investisseurs',
      },
    ],
    ancienAppartementDataPart2: [
      {
        externalData: null,
        type: SINGLE_INPUT_TABLE,
      },
      {
        data: null,
        type: LINE_CHART,
        title: 'Focus Prix moyen en €/m²',
      },
    ],
    marcheLocatifAppartDataPart2: [
      {
        data: null,
        type: MAP,
      },
      {
        type: LIST_BOXES,
        boxes: [],
      },
    ],
    collectifHousingDataPart2: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
    vacantHousingDataPart2: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
    attributesTerrainDataPart3: [{ type: LIST_BOXES, boxes: [] }],
    educationDataPart3: [
      {
        data: null,
        type: MAP,
        defaultTileLayer: 'classique',
      },
      {
        type: EDUCATION_SECTION,
        data: [],
      },
    ],
    mobilityDataPart3: [
      {
        type: POI_MAP,
        defaultTileLayer: 'classique',
      },
      {
        data: [],
        type: MOBILITY,
      },
    ],
    nearestShopDataPart3: [
      {
        data: null,
        type: NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI,
        items: [HOSPITAL, TOWN_HALL],
        defaultTileLayer: 'classique',
      },
      {
        data: null,
        type: NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
      },
    ],
    synopticVisonAndDynamicCommuneData: {
      boxes: null,
      graphData: null,
    },
    demographieAndEmploiData: [
      {
        data: null,
        type: BUBBLE_CHART,
        title:
          'Variation de la population et emploi de l’EPCI et des communes limitrophes au terrain',
        personnalisedSpan: 'col-span-8 2xl:col-span-6',
      },
      {
        type: TABLE_SWITCH,
        section: DEMOGRAPHY_AND_EMPLOYMENT,
        personnalisedSpan: 'col-span-4 2xl:col-span-6',
      },
    ],
    revenusMedianeVariationDataPart4: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
    chomageTauxVariationDataPart4: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
    populationNombreVariationDataPart4: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
    populationFocusCadreDataPart4: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
    populationFocus25To39DataPart4: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
    populationSeniorDataPart4: [
      {
        dataTable: null,
      },
      {
        dataTable: null,
      },
    ],
  });

  const getParcelData = useCallback((fieldIds) => {
    return getDataFromUrls(
      fieldIds.map(
        (id) => `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${id}`
      )
    ).then((data) => {
      setData((state) => ({ ...state, parcelData: data }));
      return data;
    });
  }, []);

  const getDefinition = useCallback((fieldIds) => {
    getDataFromUrl(
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/definitions?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`
    ).then((data) => {
      setData((state) => ({
        ...state,
        definition: [
          {
            data,
            type: DEFINITION_BLOCK,
          },
        ],
      }));
    });
  }, []);

  const getResumeProgrammePart1 = useCallback((activePrograms) => {
    getDataFromUrl(
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/resume_localisation_programmes_selectionnes?tran_ids[]=${_.join(
        activePrograms,
        '&tran_ids[]='
      )}`
    ).then((resumeProgrammePart1) => {
      setData((state) => ({ ...state, resumeProgrammePart1 }));
    });
  }, []);

  const getOffersDispoDataPart1 = useCallback((activePrograms) => {
    getDataFromUrls([
      // `${
      //   process.env.BASE_URL_API
      // }/v4/eval_parcel/rapport_etude/etat_avancement_offre_disponible_programmes_selectionnes?tran_ids[]=${_.join(
      //   activePrograms,
      //   '&tran_ids[]='
      // )}`,
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/offre_disponible_programmes_selectionnes?tran_ids[]=${_.join(
        activePrograms,
        '&tran_ids[]='
      )}`,
    ]).then((offersDispoDataPart1) => {
      setData((state) => ({
        ...state,
        offersDispoDataPart1: (offersDispoDataPart1 || []).map((item) => ({
          externalData: item,
          type: SINGLE_INPUT_TABLE,
        })),
      }));
    });
  }, []);

  const getReservationsPart1 = useCallback((activePrograms) => {
    getDataFromUrl(
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/reservations_nombre_surface_prix_par_typologie?tran_ids[]=${_.join(
        activePrograms,
        '&tran_ids[]='
      )}`
    ).then((reservationsPart1) => {
      setData((state) => ({
        ...state,
        reservationsPart1: [
          { data: reservationsPart1, type: DUAL_INPUT_TABLE },
        ],
      }));
    });
  }, []);

  const getReservationsVariationPart1 = useCallback(
    (activePrograms, periode = null) => {
      setData((state) => ({
        ...state,
        reservationsVariationDataPart1: defautValue1_4,
      }));
      getDataFromUrls([
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/rapport_etude/variation_prix_programmes_selectionnes?tran_ids[]=${_.join(
          activePrograms,
          '&tran_ids[]='
        )}${periode ? `&number_of_months=${periode}` : ''}`,
        // `${
        //   process.env.BASE_URL_API
        // }/v4/eval_parcel/rapport_etude/rythme_reservations_programmes_selectionnes?tran_ids[]=${_.join(
        //   activePrograms,
        //   '&tran_ids[]='
        // )}`,
      ]).then((data) => {
        setData((state) => ({
          ...state,
          reservationsVariationDataPart1: (data || []).map((item) => ({
            externalData: item,
            type: SINGLE_INPUT_TABLE,
            hightLightColumn: 'période',
          })),
        }));
      });
    },
    []
  );

  const getProgrammesSelected2_3Part1 = useCallback(
    (activePrograms, fieldIds) => {
      return getDataFromUrl(
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/rapport_etude/information_detaillee_programmes_selectionnes_part_2?tran_ids[]=${_.join(
          activePrograms,
          '&tran_ids[]='
        )}&parcelle_ids[]=${_.join(fieldIds, '&parcelle_ids[]=')}`
      ).then((data) => {
        setData((state) => ({
          ...state,
          programmesSelected2_3Part1: [
            { externalData: data, type: SINGLE_INPUT_TABLE },
          ],
        }));
        return true;
      });
    },
    []
  );

  const getProgrammesNeufsDataPart2 = useCallback((fieldIds) => {
    getDataFromUrl(
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/resume_epci_programmes?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`
    ).then((contentData) => {
      setData((state) => ({
        ...state,
        programmesNeufDataPart2: [
          {
            type: MAP_PROGRAMME,
            defaultTileLayer: 'classique',
            nameMap: PROGRAMS_NEUFS_MAP,
          },
          {
            data: contentData,
            type: CONTENT,
            title: '',
            isNeufProgrammeLoc: true,
          },
        ],
      }));
    });
  }, []);

  const getNeufMevDataPart2 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/offre_mev_reservations_epci_tableau?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/nombre_reservations_nettes_graph?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        neufMevDataPart2: [
          {
            externalData: data[0],
            type: SINGLE_INPUT_TABLE,
          },
          {
            title: 'Focus sur le nombre de réservations nettes',
            data: data[1],
            type: VERTICAL_BAR_CHART,
          },
        ],
      }));
    });
  }, []);

  const getNeufMevLimotropheDataPart2 = useCallback(
    (communeNationalCode, fieldIds) => {
      getDataFromUrls([
        `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${communeNationalCode}/nombre_logements_reserves/carto`,
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/rapport_etude/reservations_communes_limitrophes?parcelle_ids[]=${_.join(
          fieldIds,
          '&parcelle_ids[]='
        )}`,
      ]).then((data) => {
        setData((state) => ({
          ...state,
          neufMevLimotropheDataPart2: [
            {
              data: data[0],
              type: MAP,
              nameMap: MEV_LIM_MAP,
            },
            {
              title: '',
              externalData: data[1],
              type: SINGLE_INPUT_TABLE,
            },
          ],
        }));
      });
    },
    []
  );

  const getNeufPriceByTVADataPart2 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/prix_moyen_depuis_2018?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/prix_moyen_graphe?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        neufPriceByTVADataPart2: [
          {
            title: '',
            externalData: data[0],
            type: SINGLE_INPUT_TABLE,
          },
          {
            data: data[1],
            type: LINE_CHART,
            title: 'Évolution du prix moyen / m² toutes TVA en graphe',
          },
        ],
      }));
    });
  }, []);

  const getNeufMevLimotrophe12monthDataPart2 = useCallback(
    (communeNationalCode, fieldIds) => {
      getDataFromUrls([
        `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${communeNationalCode}/prix_moyen_toutes_tva/carto`,
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/rapport_etude/prix_moyen_communes_limitrophes?parcelle_ids[]=${_.join(
          fieldIds,
          '&parcelle_ids[]='
        )}`,
      ]).then((data) => {
        setData((state) => ({
          ...state,
          neufMevLimotrophe12monthDataPart2: [
            {
              type: MAP,
              data: data[0],
              nameMap: NEUF_MEV_LIM_MAP,
            },
            {
              externalData: data[1],
              type: SINGLE_INPUT_TABLE,
              title: 'Communes limitrophes au terrain évalué',
            },
          ],
        }));
      });
    },
    []
  );

  const getNeufResParTydeAcquereurDataPart2 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/nombre_veb_commune?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/nombre_ventes_commune_graph?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        neufResParTydeAcquereurDataPart2: [
          {
            externalData: data[0],
            type: SINGLE_INPUT_TABLE,
            title: 'Nombre de ventes dans la commune du terrain évalué',
          },
          {
            type: VERTICAL_BAR_CHART,
            title: 'Évolution en histogramme',
            data: data[1],
          },
        ],
      }));
    });
  }, []);

  const getNeufResNetteDataPart2 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/reservations_type_acheteurs?parcelle_ids[]=${fieldIds[0]}&transport_time_max=600`,
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/nombre_veb_logements_sociaux_graph?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        neufResNetteDataPart2: [
          {
            type: SINGLE_INPUT_TABLE,
            title: 'Nombre de ventes dans la commune du terrain évalué',
            externalData: data[0],
          },
          {
            data: data[1],
            type: LINE_CHART,
            title: 'Focus sur % Investisseurs',
          },
        ],
      }));
    });
  }, []);

  const getAncienAppartementDataPart2 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/logements_anciens_vendus?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}&transport_time_max=600`,
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/prix_moyen_ancien_graphe?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        ancienAppartementDataPart2: [
          {
            externalData: data[0],
            type: SINGLE_INPUT_TABLE,
          },
          {
            data: data[1],
            type: LINE_CHART,
            title: 'Focus Prix moyen en €/m²',
          },
        ],
      }));
    });
  }, []);

  const getMarcheLocatifAppartDataPart2 = useCallback(
    async (communeNationalCode) => {
      const mapData = await getDataFromUrl(
        `${process.env.BASE_URL_API}/v4/eval_parcel/marche_locatif/${communeNationalCode}/prix_moyen_locatif_mensuel/carto`
      );
      const boxes = await getDataFromUrls([
        `${process.env.BASE_URL_API}/v4/eval_parcel/marche_locatif/${communeNationalCode}/prix_moyen_locatif_mensuel`,
        `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${communeNationalCode}/encadrement_loyers`,
        `${process.env.BASE_URL_API}/v4/eval_parcel/marche_locatif/${communeNationalCode}/zone_tendue`,
      ]);

      setData((state) => ({
        ...state,
        marcheLocatifAppartDataPart2: [
          {
            data: mapData,
            type: MAP,
            nameMap: MARCHE_LOC_APPART,
          },
          {
            type: LIST_BOXES,
            boxes: boxes,
          },
        ],
      }));

      return true;
    },
    []
  );

  const getCollectifHousingDataPart2 = useCallback(async (fieldIds) => {
    getDataFromUrls([
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/logements_collectifs_carto`,
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/logements_collectifs_evolution_carto`,
    ]).then((mapData) => {
      setData((state) => ({
        ...state,
        collectifHousingDataPart2: [
          {
            dataTable: mapData[0],
          },
          {
            dataTable: mapData[1],
          },
        ],
      }));
    });
  }, []);

  const getVacantHousingDataPart2 = useCallback(async (fieldIds) => {
    getDataFromUrls([
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/logements_vacants_carto`,
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/logements_vacants_evolution_carto`,
    ]).then((mapData) => {
      setData((state) => ({
        ...state,
        vacantHousingDataPart2: [
          {
            dataTable: mapData[0],
          },
          {
            dataTable: mapData[1],
          },
        ],
      }));
    });
  }, []);

  const getAttributesTerrainDataPart3 = useCallback(
    (communeNationalCode, fieldIds) => {
      const boxesUrls = [
        {
          url: `${
            process.env.BASE_URL_API
          }/v4/eval_parcel/parcels/terrain/taille_terrain?parcelle_ids[]=${_.join(
            fieldIds,
            '&parcelle_ids[]='
          )}`,
        },
        {
          url: `${
            process.env.BASE_URL_API
          }/v4/eval_parcel/parcels/terrain/plu?parcelle_ids[]=${_.join(
            fieldIds,
            '&parcelle_ids[]='
          )}`,
          isPlu: true,
        },
        {
          url: `${
            process.env.BASE_URL_API
          }/v4/eval_parcel/parcels/terrain/proprietaire_personne_morale?parcelle_ids[]=${_.join(
            fieldIds,
            '&parcelle_ids[]='
          )}`,
        },
        {
          url: `${
            process.env.BASE_URL_API
          }/v4/eval_parcel/parcels/terrain/bati?parcelle_ids[]=${_.join(
            fieldIds,
            '&parcelle_ids[]='
          )}`,
        },
        {
          url: `${
            process.env.BASE_URL_API
          }/v4/eval_parcel/parcels/terrain/zone_anru?parcelle_ids[]=${_.join(
            fieldIds,
            '&parcelle_ids[]='
          )}`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${fieldIds[0]}/qpv`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/zus`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/zone_pinel`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/nuisances/${fieldIds[0]}/zppa`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/nuisances/${fieldIds[0]}/lignes_electriques_haute_tension`,
        },
        {
          url: `${
            process.env.BASE_URL_API
          }/v4/eval_parcel/parcels/terrain/zone_activite_economique?parcelle_ids[]=${_.join(
            fieldIds,
            '&parcelle_ids[]='
          )}`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/quick_check/${fieldIds[0]}/risks`,
        },
      ];
      return getDataFromUrls(
        boxesUrls.map((item) => ({ ...item, v2: true }))
      ).then((boxes) => {
        setData((state) => ({
          ...state,
          attributesTerrainDataPart3: [{ type: LIST_BOXES, boxes }],
        }));
        return true;
      });
    },
    []
  );

  const getEducationDataPart3 = useCallback(async (fieldIds) => {
    const eduData = [
      {
        title: 'Ecoles publiques les plus proches en voiture',
        dataSection: [
          {
            title: 'Primaire',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_closest_primary_school_public`,
          },
          {
            title: 'Collège',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_closest_middle_school_public`,
          },
          {
            title: 'Lycée',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_closest_high_school_public`,
          },
        ],
      },
      {
        title: 'Ecoles privées les plus proches en voiture',
        dataSection: [
          {
            title: 'Primaire',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_closest_primary_school_private`,
          },
          {
            title: 'Collège',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_closest_middle_school_private`,
          },
          {
            title: 'Lycée',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_closest_high_school_private`,
          },
        ],
      },
      {
        title: 'Etablissement d’enseignement supérieur le plus proche',
        dataSection: [
          {
            title: '',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_closest_university`,
          },
        ],
      },
    ];
    const eductionSectionData = await getDataWithSection(eduData);
    const mapData = await getDataFromUrl(
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/education_carto`
    );

    setData((state) => ({
      ...state,
      educationDataPart3: [
        {
          data: mapData,
          type: MAP,
          defaultTileLayer: 'classique',
          nameMap: ECOLE_UNIVERSITY_MAP,
          isMobility: true,
        },
        {
          type: EDUCATION_SECTION,
          data: eductionSectionData,
        },
      ],
    }));
  }, []);

  const getMobilityDataPart3 = useCallback(async (fieldIds) => {
    const mobilityData = [
      {
        title: 'Accès à la gare SNCF la plus proche en voiture',
        dataSection: [
          {
            title: '',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/transport_closest_gare_sncf`,
          },
        ],
      },
      {
        title: 'Accès à la gare SNCF Nationale (TGV) la plus proche en voiture',
        dataSection: [
          {
            title: '',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/transport_closest_gare_sncf_nationale`,
          },
        ],
      },
      {
        title: 'Accès à l’Aéroport le plus proche en voiture',
        dataSection: [
          {
            title: '',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/transport_closest_airport`,
          },
        ],
      },
      {
        title: 'Nombre de stations métro / tram à 10 minutes à pied',
        dataSection: [
          {
            title: '',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/transport_closest_metro_station`,
          },
        ],
      },
      {
        title: 'Nombre d’arrêts de bus à 10 minutes à pied',
        dataSection: [
          {
            title: '',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/transport_closest_bus_station`,
          },
        ],
      },
    ];

    const _mobilityData = await getDataWithSection(mobilityData);

    setData((state) => ({
      ...state,
      mobilityDataPart3: [
        {
          type: POI_MAP,
          defaultTileLayer: 'classique',
          nameMap: MOBILITY_MAP,
        },
        {
          data: _mobilityData,
          type: MOBILITY,
        },
      ],
    }));
  }, []);

  const getNearestShopDataPart3 = useCallback(async (fieldIds) => {
    const nearestShopArray = [
      {
        title: 'Accès aux équipements publics les plus proches du terrain',
        dataSection: [
          {
            title: 'Bureau de Poste',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/accessibility/${fieldIds[0]}/post_office`,
          },
          {
            title: 'Mairie',
            dataURL: `${process.env.BASE_URL_API}/v4/eval_parcel/accessibility/${fieldIds[0]}/town_hall`,
          },
        ],
      },
    ];

    const _nearestShopData = await getDataWithSection(nearestShopArray);
    const mapData = await getDataFromUrl(
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/equipements_publics_carto`
    );

    setData((state) => ({
      ...state,
      nearestShopDataPart3: [
        {
          data: mapData,
          type: NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI,
          items: [HOSPITAL, TOWN_HALL],
          defaultTileLayer: 'classique',
          nameMap: NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_MAP,
        },
        {
          data: _nearestShopData,
          type: NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
        },
      ],
    }));
  }, []);

  const getSynopticVisonAndDynamicCommuneData = useCallback(
    async (communeNationalCode, fieldIds) => {
      // TODO add the new boxes URLs
      const boxes = [
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/changing_mayor`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/mayor`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/charte_de_construction_du_territoire`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/coeur_de_ville`,
        },
        {
          url: `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/territoires_industrie`,
        },
      ];

      const graphData = await getDataFromUrl(
        `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${communeNationalCode}/pcs_commune_graphe`,
        5 // version of API
      );

      const boxesData = await getDataFromUrls(boxes);

      setData((state) => ({
        ...state,
        synopticVisonAndDynamicCommuneData: {
          boxes: boxesData,
          graphData: graphData,
        },
      }));
    },
    []
  );

  const getDemographieAndEmploiData = useCallback((fieldIds) => {
    getDataFromUrl(
      `${
        process.env.BASE_URL_API
      }/v4/eval_parcel/rapport_etude/demographie_emploi_bubble_chart?parcelle_ids[]=${_.join(
        fieldIds,
        '&parcelle_ids[]='
      )}`
    ).then((bubbleChartData) => {
      setData((state) => ({
        ...state,
        demographieAndEmploiData: [
          {
            data: bubbleChartData,
            type: BUBBLE_CHART,
            title:
              'Variation de la population et emploi de l’EPCI et des communes limitrophes au terrain',
            personnalisedSpan: 'col-span-12 xl:col-span-8 2xl:col-span-7',
          },
          {
            type: TABLE_SWITCH,
            section: DEMOGRAPHY_AND_EMPLOYMENT,
            personnalisedSpan: 'col-span-6 xl:col-span-4 2xl:col-span-5',
          },
        ],
      }));
    });
  }, []);

  const getRevenusMedianeVariationDataPart4 = useCallback(
    (communeNationalCode) => {
      getDataFromUrls([
        `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/revenu_median_annuel/carto`,
        `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${communeNationalCode}/evolution_revenu_median_annuel/carto`,
      ]).then((data) => {
        setData((state) => ({
          ...state,
          revenusMedianeVariationDataPart4: [
            {
              dataTable: data[0],
            },
            {
              dataTable: data[1],
            },
          ],
        }));
      });
    },
    []
  );

  const getChomageTauxVariationDataPart4 = useCallback(
    (communeNationalCode) => {
      getDataFromUrls([
        `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/taux_chomage/carto`,
        `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${communeNationalCode}/taux_chomage_evolution/carto`,
      ]).then((data) => {
        setData((state) => ({
          ...state,
          chomageTauxVariationDataPart4: [
            {
              dataTable: data[0],
            },
            {
              dataTable: data[1],
            },
          ],
        }));
      });
    },
    []
  );

  const getPopulationNombreVariationDataPart4 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/habitants_carto`,
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/habitants_evolution_carto`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        populationNombreVariationDataPart4: [
          {
            dataTable: data[0],
          },
          {
            dataTable: data[1],
          },
        ],
      }));
    });
  }, []);

  const getPopulationFocusCadreDataPart4 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/cadres_carto`,
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/cadres_evolution_carto`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        populationFocusCadreDataPart4: [
          {
            dataTable: data[0],
          },
          {
            dataTable: data[1],
          },
        ],
      }));
    });
  }, []);

  const getpopulationFocus25To39DataPart4 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/jeunes_25_39_carto`,
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/jeunes_25_39_evolution_carto`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        populationFocus25To39DataPart4: [
          {
            dataTable: data[0],
          },
          {
            dataTable: data[1],
          },
        ],
      }));
    });
  }, []);

  const getpopulationSeniorDataPart4 = useCallback((fieldIds) => {
    getDataFromUrls([
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/senior_65p_carto`,
      `${process.env.BASE_URL_API}/v4/eval_parcel/rapport_etude/${fieldIds[0]}/senior_65p_evolution_carto`,
    ]).then((data) => {
      setData((state) => ({
        ...state,
        populationSeniorDataPart4: [
          {
            dataTable: data[0],
          },
          {
            dataTable: data[1],
          },
        ],
      }));
    });
  }, []);

  return {
    data,
    getParcelData,

    // part 1 methodes
    getOffersDispoDataPart1,
    getResumeProgrammePart1,
    getReservationsPart1,
    getReservationsVariationPart1,
    getProgrammesSelected2_3Part1,

    // part 2 methodes
    getProgrammesNeufsDataPart2,
    getNeufMevDataPart2,
    getNeufMevLimotropheDataPart2,
    getNeufPriceByTVADataPart2,
    getNeufMevLimotrophe12monthDataPart2,
    getNeufResParTydeAcquereurDataPart2,
    getNeufResNetteDataPart2,
    getAncienAppartementDataPart2,
    getMarcheLocatifAppartDataPart2,
    getCollectifHousingDataPart2,
    getVacantHousingDataPart2,
    getAttributesTerrainDataPart3,
    getEducationDataPart3,
    getMobilityDataPart3,
    getNearestShopDataPart3,
    getSynopticVisonAndDynamicCommuneData,
    getDemographieAndEmploiData,
    getRevenusMedianeVariationDataPart4,
    getChomageTauxVariationDataPart4,
    getPopulationNombreVariationDataPart4,
    getPopulationFocusCadreDataPart4,
    getpopulationFocus25To39DataPart4,
    getpopulationSeniorDataPart4,
    getDefinition,
  };
};

const useEDMLinksRef = ({
  isCapemUser = false,
  isPart1Only = false,
  isPart2Only = false,
  isPdfMode = false,
}) => {
  const marketSurveyRef = useRef(null);
  const resumeRef_1 = useRef(null);
  const resumeRef_2 = useRef(null);
  const resumeRef_3 = useRef(null);
  const resumeRef_4 = useRef(null);
  const resumeRef_5 = useRef(null);
  const reservation_ref = useRef(null);
  const price_change_ref = useRef(null);
  const offer_available_ref = useRef(null);
  const detailed_information_1_ref = useRef(null);
  const detailed_information_2_ref = useRef(null);
  const detailed_information_3_ref = useRef(null);
  const field_attributes_ref = useRef(null);
  const neighborhood_ref = useRef(null);
  const csp_ref = useRef(null);
  const from_25_to_39_yo_ref = useRef(null);
  const parcelles_emplacement_ref = useRef(null);
  const education_ref = useRef(null);
  const nearest_shops_and_public_equipment_ref = useRef(null);
  const new_programs_ref = useRef(null);
  const mobility_ref = useRef(null);
  const new_on_sale_ref = useRef(null);
  const new_in_area_city_price_ref = useRef(null);
  const new_buyer_type_ref = useRef(null);
  const vacant_housing_ref = useRef(null);
  const new_reservation_by_vat_type_ref = useRef(null);
  const net_reservations_by_epci_municipalities_ref = useRef(null);
  const price_evolution_2018_ref = useRef(null);
  const old_housing_trend_ref = useRef(null);
  const rent_price_ref = useRef(null);
  const local_authority_housing_ref = useRef(null);
  const collective_housing_ref = useRef(null);
  const political_ref = useRef(null);
  const demography_and_employment_ref = useRef(null);
  const median_income_ref = useRef(null);
  const age_ref = useRef(null);
  const unemployment_ref = useRef(null);
  const definitionsRef = useRef(null);
  const contactRef = useRef(null);

  const linksRefs = useMemo(
    () => [
      {
        part: 1,
        isHidden: isPart2Only,
        summary: {
          ref: marketSurveyRef,
          link: 'marketSurveyRef',
          label:
            '1 – Analyse concurentielle - votre sélection de programmes neufs',
        },
        content: [
          {
            ref: resumeRef_1,
            link: 'resumeRef_1',
            label: '1.1 – Localisation, prix moyen, disponibilité',
          },
          {
            ref: detailed_information_3_ref,
            link: 'detailed_information_3_ref',
            label: '1.2 – Offre disponible : par programme',
          },
          {
            ref: offer_available_ref,
            link: 'offer_available_ref',
            label: '1.3 – Offre disponible : par typologie',
          },
          {
            ref: price_change_ref,
            link: 'price_change_ref',
            label:
              '1.4 – Réservations : variation des prix et rythmes / taux moyens',
          },
          {
            ref: reservation_ref,
            link: 'reservation_ref',
            label:
              '1.5 – Réservations : nombre, surface, prix par type d’acheteurs et de logements',
          },
          {
            ref: detailed_information_2_ref,
            link: 'detailed_information_2_ref',
            label: '1.6 – Récapitulatif : par programme',
          },
        ].filter((item) => item),
      },
      {
        isHidden: isPart1Only,
        part: 2,
        summary: {
          ref: resumeRef_2,
          link: 'resumeRef_2',
          label: '2 – Logements et Marché Immobilier',
        },
        content: [
          {
            ref: new_programs_ref,
            link: 'new_programs_ref',
            label:
              '2.1 – Neuf : Programmes en cours de commercialisation - prix moyen',
          },
          {
            ref: new_on_sale_ref,
            link: 'new_on_sale_ref',
            label:
              '2.2 – Neuf : Mises en vente | Réservations | Offre disponible - Commune & EPCI',
          },
          {
            ref: net_reservations_by_epci_municipalities_ref,
            link: 'net_reservations_by_epci_municipalities_ref',
            label:
              '2.3 – Neuf : Mises en vente | Réservations nettes -  Communes limitrophes',
          },
          {
            ref: price_evolution_2018_ref,
            link: 'price_evolution_2018_ref',
            label:
              '2.4 – Neuf : Évolution des prix par type de TVA - Commune & EPCI',
          },
          {
            ref: new_in_area_city_price_ref,
            link: 'new_in_area_city_price_ref',
            label:
              '2.5 – Neuf : Évolution des prix - EPCI & Communes limitrophes',
          },
          {
            ref: new_buyer_type_ref,
            link: 'new_buyer_type_ref',
            label:
              "2.6 – Neuf : Réservations par type d'acquéreurs (Utilisateurs | Invest. | VB)",
            disabled: isCapemUser,
          },
          {
            ref: new_reservation_by_vat_type_ref,
            link: 'new_reservation_by_vat_type_ref',
            label:
              '2.7 – Neuf : Réservations - Part Investisseur  | Part TVA Réduite',
            disabled: isCapemUser,
          },
          {
            ref: old_housing_trend_ref,
            link: 'old_housing_trend_ref',
            label:
              '2.8 – Marché Ancien : Appartements - Prix moyen | Nb. transactions - Commune & EPCI',
          },
          {
            ref: rent_price_ref,
            link: 'rent_price_ref',
            label:
              '2.9 – Marché Locatif : Appartements - Prix moyen - Commune & EPCI',
          },
          {
            ref: local_authority_housing_ref,
            link: 'local_authority_housing_ref',
            label:
              '2.10 – Logement Social : Parc - Évolutions et Demandes - Commune',
          },
          {
            ref: collective_housing_ref,
            link: 'collective_housing_ref',
            label:
              '2.11 – Logements : collectif - nombre et parts - EPCI & Communes limitrophes',
          },
          {
            ref: vacant_housing_ref,
            link: 'vacant_housing_ref',
            label:
              '2.12 – Logements : vacants - nombre et parts - EPCI & Communes limitrophes',
          },
        ],
      },
      {
        isHidden: isPart1Only || isPart2Only,
        part: 3,
        summary: {
          ref: resumeRef_3,
          link: 'resumeRef_3',
          label: ' 3 – Le terrain et son quartier',
        },
        content: [
          {
            ref: parcelles_emplacement_ref,
            link: 'parcelles_emplacement_ref',
            label: '3.1 – Le terrain : emplacement & parcelles',
          },
          {
            ref: field_attributes_ref,
            link: 'field_attributes_ref',
            label: '3.2 – Terrain : caractéristique & risques',
          },
          {
            ref: education_ref,
            link: 'education_ref',
            label: '3.3 – Ecoles et universités',
          },
          {
            ref: mobility_ref,
            link: 'mobility_ref',
            label: '3.4 – Transports & mobilité',
          },
          {
            ref: nearest_shops_and_public_equipment_ref,
            link: 'nearest_shops_and_public_equipment_ref',
            label: '3.5 – Equipements public & services essentiels',
          },
        ],
      },
      {
        part: 4,
        isHidden: isPart1Only || isPart2Only,
        summary: {
          ref: resumeRef_4,
          link: 'resumeRef_4',
          label: '4 – La commune dans son territoire',
        },
        content: [
          {
            ref: political_ref,
            link: 'political_ref',
            label:
              '4.1 – Une vision synoptique de la commune et de sa dynamique',
          },
          {
            ref: demography_and_employment_ref,
            link: 'demography_and_employment_ref',
            label:
              '4.2 – Démographie et emplois : Dynamique comparée des communes limitrophes',
          },
          {
            ref: median_income_ref,
            link: 'median_income_ref',
            label:
              '4.3 – Revenus: Médiane et Variation par Unité de Consommation',
          },
          {
            ref: unemployment_ref,
            link: 'unemployment_ref',
            label: '4.4 – Chômage : Taux et variation',
          },
          {
            ref: neighborhood_ref,
            link: 'neighborhood_ref',
            label: '4.5 – Population : Nombre et variation',
          },
          {
            ref: csp_ref,
            link: 'csp_ref',
            label: '4.6 – Population : Focus - Cadres',
          },
          {
            ref: from_25_to_39_yo_ref,
            link: 'from_25_to_39_yo_ref',
            label: '4.7 – Population : Focus - Personnes de 25 à 39 ans',
          },
          {
            ref: age_ref,
            link: 'age_ref',
            label: '4.8 – Population : Focus - Seniors ( + de 65 ans)',
          },
        ],
      },
      {
        part: 5,
        summary: {
          ref: definitionsRef,
          link: 'resumeRef_5',
          label: '5 - Sources et définitions',
        },
        content: [],
      },
    ],
    [isPart1Only, isPart2Only]
  );

  return {
    linksRefs,
    refs: {
      marketSurveyRef,
      resumeRef_1,
      resumeRef_2,
      resumeRef_3,
      resumeRef_4,
      resumeRef_5,
      reservation_ref,
      parcelles_emplacement_ref,
      price_change_ref,
      offer_available_ref,
      detailed_information_1_ref,
      detailed_information_2_ref,
      detailed_information_3_ref,
      field_attributes_ref,
      neighborhood_ref,
      csp_ref,
      from_25_to_39_yo_ref,
      education_ref,
      nearest_shops_and_public_equipment_ref,
      new_programs_ref,
      mobility_ref,
      new_on_sale_ref,
      new_in_area_city_price_ref,
      new_buyer_type_ref,
      vacant_housing_ref,
      new_reservation_by_vat_type_ref,
      net_reservations_by_epci_municipalities_ref,
      price_evolution_2018_ref,
      old_housing_trend_ref,
      rent_price_ref,
      local_authority_housing_ref,
      collective_housing_ref,
      political_ref,
      demography_and_employment_ref,
      median_income_ref,
      age_ref,
      unemployment_ref,
      definitionsRef,
      contactRef,
    },
  };
};

export const useEDM = (
  surveyIdOrTokenSurvey,
  {
    isPdf = false,
    isPart1Only = false,
    isPart2Only = false,
    isCapemUser = false,
    isShare = false,
  }
) => {
  // states
  const [surveyId, setSurveyId] = useState(null);
  const [fieldIds, setFieldIdsLocal] = useState([]);
  const [dateMs, setDateMs] = useState('');
  const [infosDetailsSelectedProgrammes, setInfosDetailsSelectedProgrammes] =
    useState([]);
  const { setMetadata, metadata } = useMetadataMs((state) => state);

  // redux hooks
  const { marketSurvey } = useSelector((state) => state.programme);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(othersSliceActions.setMaxErrorNb(5));
  }, []);

  const handleUpdateMSName = useCallback(
    (name) => {
      if (name && surveyId) {
        updateMarketSurveyName(surveyId, name);
      }
    },
    [surveyId]
  );

  // custom hooks
  const { linksRefs, refs } = useEDMLinksRef({
    isCapemUser,
    isPart1Only,
    isPart2Only,
    isPdfMode: isPdf,
  });
  const { setActivePrograms, setPrograms } = usePrograms();
  const { setCurrentRatingID, setMsId } = useModal();
  const { setFieldIds } = useSelectedParcels();
  const {
    data,
    getParcelData,
    getResumeProgrammePart1,
    getOffersDispoDataPart1,
    getReservationsPart1,
    getReservationsVariationPart1,
    getProgrammesSelected2_3Part1,
    getProgrammesNeufsDataPart2,
    getNeufMevDataPart2,
    getNeufMevLimotropheDataPart2,
    getNeufPriceByTVADataPart2,
    getNeufMevLimotrophe12monthDataPart2,
    getNeufResParTydeAcquereurDataPart2,
    getNeufResNetteDataPart2,
    getAncienAppartementDataPart2,
    getMarcheLocatifAppartDataPart2,
    getCollectifHousingDataPart2,
    getVacantHousingDataPart2,
    getAttributesTerrainDataPart3,
    getEducationDataPart3,
    getMobilityDataPart3,
    getNearestShopDataPart3,
    getSynopticVisonAndDynamicCommuneData,
    getDemographieAndEmploiData,
    getRevenusMedianeVariationDataPart4,
    getChomageTauxVariationDataPart4,
    getPopulationNombreVariationDataPart4,
    getPopulationFocusCadreDataPart4,
    getpopulationFocus25To39DataPart4,
    getpopulationSeniorDataPart4,
    getDefinition,
  } = useGetDataEdm({ isPdf });

  // effect hooks
  useEffect(() => {
    if (!isShare) {
      setSurveyId(surveyIdOrTokenSurvey);
      dispatch(getCurrentSurvey(surveyIdOrTokenSurvey));
      setMsId(surveyIdOrTokenSurvey);
    } else {
      dispatch(getCurrentSurveyByToken(surveyIdOrTokenSurvey));
    }
  }, [surveyIdOrTokenSurvey, isShare]);

  useEffect(() => {
    const getInfoDetailsSelectedProgrammesFunc = async (activePrograms) => {
      const { payload } = await dispatch(
        getInfoDetailsSelectedProgrammes({ activePrograms, isPdf })
      );
      setInfosDetailsSelectedProgrammes(payload);
    };

    const execFunc = async (marketSurvey) => {
      if (marketSurvey) {
        dispatch(othersSliceActions.setIsPdfMode(isPdf));

        if (marketSurvey.error) {
          alert(marketSurvey.error);
          return;
        }

        const { data, included, id } = marketSurvey;

        const currentDateMs =
          data.attributes.updated_at || data.attributes.created_at;
        moment.locale('fr');

        setDateMs(moment(currentDateMs).format('DD MMM YYYY'));

        const _metadata = data.attributes.meta;

        if (_metadata) {
          setMetadata({ ..._metadata, name: data?.attributes?.name || null });
        }

        const related_rating = included.filter(
          (item) => item.type === 'rating'
        );

        let fieldIds = [];
        let activePrograms = [];
        let communeNationalCode = null;

        if (
          related_rating.length &&
          related_rating[0].attributes.targets_parcels?.length
        ) {
          setCurrentRatingID(related_rating[0]?.id);
          const currentParcelIds = _.uniq(
            related_rating[0].attributes.targets_parcels.map(
              (item) => item.parcel_id
            )
          );
          setFieldIds(currentParcelIds);
          setFieldIdsLocal(currentParcelIds);
          fieldIds = currentParcelIds;
          const parcelData = await getParcelData(fieldIds);
          if (parcelData?.length) {
            communeNationalCode =
              parcelData[0]?.data?.attributes?.commune_national_code;
          }
        }

        const real_estate_programs = included.filter(
          (item) => item.type === 'real_estate_program'
        );

        if (real_estate_programs?.length) {
          activePrograms = real_estate_programs.map((item) =>
            parseInt(item.attributes.reference)
          );
          setActivePrograms(activePrograms);
        } else {
          window.location.assign(`/market-survey/${data.attributes.id}`);
          return;
        }

        // get all DATA
        if (activePrograms.length && fieldIds.length && communeNationalCode) {
          dispatch(
            getPermitContruire({
              parcel_ids: fieldIds,
              filter_geo: _metadata.filterProgramme.filter_geo,
            })
          );
          dispatch(getCommunesParcelles(fieldIds));
          dispatch(getGeoJsonFields(fieldIds));
          dispatch(
            getProgrammesEDM({
              parcel_ids: fieldIds,
              metadata: _metadata,
              activePrograms,
            })
          ).then(async () => {
            getResumeProgrammePart1(activePrograms);
            getOffersDispoDataPart1(activePrograms);
            getReservationsPart1(activePrograms);
            getReservationsVariationPart1(
              activePrograms,
              _metadata?.periode_1_4?.value || null
            );
            getProgrammesSelected2_3Part1(activePrograms, fieldIds);
            getInfoDetailsSelectedProgrammesFunc(activePrograms);

            dispatch(getIsochroneCarFeature(fieldIds[0]));
            dispatch(getProgrammesNeufs(fieldIds));
            getProgrammesNeufsDataPart2(fieldIds);
            getNeufMevDataPart2(fieldIds);
            getNeufPriceByTVADataPart2(fieldIds);
            getNeufResParTydeAcquereurDataPart2(fieldIds);
            getNeufResNetteDataPart2(fieldIds);
            getAncienAppartementDataPart2(fieldIds);
            getCollectifHousingDataPart2(fieldIds);
            getVacantHousingDataPart2(fieldIds);
            getNeufMevLimotropheDataPart2(communeNationalCode, fieldIds);
            getNeufMevLimotrophe12monthDataPart2(communeNationalCode, fieldIds);

            getMarcheLocatifAppartDataPart2(communeNationalCode);
            getEducationDataPart3(fieldIds);
            getMobilityDataPart3(fieldIds);
            getNearestShopDataPart3(fieldIds);
            dispatch(getDataLogementSocial(fieldIds[0]));
            dispatch(getTransportCarto(fieldIds[0]));
            dispatch(getOsmDataCarto(fieldIds[0]));
            dispatch(getAccessibilityIsochoneData(fieldIds[0]));
            getAttributesTerrainDataPart3(communeNationalCode, fieldIds);

            getDemographieAndEmploiData(fieldIds);
            getPopulationNombreVariationDataPart4(fieldIds);
            getPopulationFocusCadreDataPart4(fieldIds);
            getpopulationFocus25To39DataPart4(fieldIds);
            getpopulationSeniorDataPart4(fieldIds);
            getRevenusMedianeVariationDataPart4(communeNationalCode);
            getChomageTauxVariationDataPart4(communeNationalCode);
            getDefinition(fieldIds);
            getSynopticVisonAndDynamicCommuneData(
              communeNationalCode,
              fieldIds
            ); // TODO remove fieldIds
          });
        }
      }
    };

    execFunc(marketSurvey);
  }, [marketSurvey]);

  useEffect(() => {
    return () => {
      setPrograms([]);
      dispatch(programmeSliceActions.reset());
      dispatch(othersSliceActions.reset());
      dispatch(MarketSurveyActions.reset());
    };
  }, []);

  return {
    states: {
      fieldIds,
      msMetaData: metadata,
      dateMs,
      setMsMetaData: setMetadata,
    },
    methodes: { getReservationsVariationPart1, handleUpdateMSName },
    data: {
      data,
      infosDetailsSelectedProgrammes,
    },
    refs: {
      linksRefs,
      refs,
    },
  };
};

export default useGetDataEdm;
