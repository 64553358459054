import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BannerAlert from '@components/BannerAlert';
import Table from '@components/molecules/Table';
import Button from '@components/Button';
import HomeLogo from '../../../../assets/images/home.png';
import useResponsive from '../../hooks/UseResponsive';
import Api from '../../api/Api';
import useSWR, { mutate } from 'swr';
import { useAuth } from '../../hooks/useAuth';
import MapDataContainer from '../../components/molecules/DataVisualisations/Map/MapDataContainer';
import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import { orderProjects } from './Rating';
import { useDispatch } from 'react-redux';
import { othersSliceActions } from '../../store/slices/others';

const ListRating = () => {
  const { sessionAuthHeader } = useAuth();
  const isMobileMode = useResponsive();
  const { setFieldIds, fieldIds } = useSelectedParcels();
  const [openNotice, setOpenNotice] = useState(true);
  const [maxHeight, setMaxHeight] = useState(300);
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();

  const fetcher = useCallback(
    (url) =>
      Api.get(url, {
        headers: {
          'X-USER-EMAIL': sessionAuthHeader?.email,
          'X-USER-TOKEN': sessionAuthHeader?.token,
        },
      }).then((res) => res.data),
    [sessionAuthHeader]
  );

  const { data: ratingsGroupByDateDataFromApi, error } = useSWR(
    `${process.env.BASE_URL}/ratings.json`,
    fetcher
  );

  const handleCheckbox = useCallback((data) => {
    setFieldIds(
      data.targets_parcels.map((target_parcel) => target_parcel.parcel_id)
    );
  }, []);

  const handleDeleted = useCallback((data) => {
    mutate(`${process.env.BASE_URL}/ratings.json`);
    if (
      data.targets_parcels
        .map((target_parcel) => target_parcel.parcel_id)
        .filter((item) => fieldIds.includes(item)).length
    ) {
      setFieldIds([]);
    }
  }, []);

  useEffect(() => {
    const exec = () => {
      setOffset(
        document.getElementById('navbar')?.offsetHeight +
          document.getElementById('bottom-navbar')?.offsetHeight
      );
      setMaxHeight(
        document.getElementById('navbar')?.clientHeight +
          document.getElementById('bottom-navbar')?.clientHeight
      );
    };
    exec();

    dispatch(othersSliceActions.setMaxErrorNb(1));

    window.addEventListener('resize', exec);

    return () => {
      window.removeEventListener('resize', exec);
    };
  }, []);

  const ratingsGroupByDateData = useMemo(
    () =>
      ratingsGroupByDateDataFromApi &&
      orderProjects(ratingsGroupByDateDataFromApi),
    [ratingsGroupByDateDataFromApi]
  );

  if (!ratingsGroupByDateData) {
    return <div>Loading...</div>;
  }

  if (ratingsGroupByDateData.error || error) {
    return <div>Loading failed...</div>;
  }

  return (
    <div
      style={{ paddingTop: `${offset}px` }}
      className="h-full bg-blue-primary"
    >
      <div className="grid grid-cols-12">
        <div
          id="leftColumn"
          style={{
            height: `${
              !isMobileMode ? `calc( 100vh - ${maxHeight}px)` : 'unset'
            }`,
          }}
          className="md:col-span-8 col-span-12 md:overflow-y-auto bg-white"
        >
          {openNotice && (
            <BannerAlert setOpenNotice={setOpenNotice} color={{ green: true }}>
              Vous avez {ratingsGroupByDateData?.length} terrains à évaluer
            </BannerAlert>
          )}
          <div className="p-4">
            <div className="flex items-center">
              <h3 className="font-semibold">Terrain(s) à évaluer</h3>
              <i className="ml-4 text-lg text-gray-500 cursor-pointer ri-information-fill" />
            </div>
            <div className="flex">
              <span className="text-sm font-semibold">
                Retrouvez l'historique des Quick Check et Études de Marché que
                vous avez réalisés.
              </span>
            </div>
            {ratingsGroupByDateData.map((ratingsGroupByDate, index) => {
              return (
                <div
                  key={`__grouped_by_date-${index}`}
                  className="mt-4 mb-4 border-gray-600 rounded-sm p-2 shadow-sm"
                >
                  <span className="text-sm">{ratingsGroupByDate.date}</span>
                  <span className="ml-4 font-extrabold">
                    {ratingsGroupByDate.ratings.data.length} Terrains
                  </span>
                  <Table
                    hasCheckbox
                    byDate
                    setCheckboxData={handleCheckbox}
                    type="ratings_projects"
                    data={ratingsGroupByDate.ratings.data.map(
                      (rating) => rating.attributes
                    )}
                    firstArray={index === 0}
                    onDeleted={handleDeleted}
                  />
                </div>
              );
            })}
            {ratingsGroupByDateData.length === 0 && (
              <div className="bg-white shadow-lg p-4">
                <div className="flex items-center">
                  <img src={HomeLogo} width={90} alt={'eval parcel logo'} />
                  <div>
                    <p className="mt-1 text-sm text-gray-400">
                      Vous n'avez actuellement aucune évaluation, cliquez sur le
                      bouton pour démarrer une nouvelle évaluation terrain.
                      Cliquez sur le bouton pour démarer une nouvelle
                      évaluation.
                    </p>
                  </div>
                </div>
                <div className="flex justify-start w-2/4">
                  <Button
                    to={'/ratings/new'}
                    title="Démarrer une nouvelle évaluation"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="hidden md:block md:col-span-4 col-span-12">
          <MapDataContainer hFull dataType={'surface'} />
        </div>
      </div>
    </div>
  );
};

export default ListRating;
