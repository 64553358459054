import Api from "./Api";

export function authInterceptor() {
    Api.interceptors.request.use(request => {
        // add auth header with jwt if account is logged in and request is to the api url
        // console.log(request)

        const token = JSON.parse(localStorage.getItem('token'))
        // console.log(JSON.parse(token).email)
        if (!!token) {

            // request.headers.common.Authorization = `Bearer ${account.token}`;
            request.headers["X-USER-EMAIL"] = token.email
            request.headers["X-USER-TOKEN"] = token.token

        }
        // console.log(request)
        // const account = accountService.accountValue;
        // const isLoggedIn = account?.token;
        //
        // if (isLoggedIn) {

        // }

        return request;
    });
}