import React, {
  createRef,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Marker, Tooltip, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import SelectedProgram from '../../../../../../assets/images/icons/programme-selectionne.svg';
import ProgramOverSince2019 from '../../../../../../assets/images/icons/programme-immobilier-termine-2019.svg';
import ProgramInProgress from '../../../../../../assets/images/icons/programme_en_cours.svg';
import BuildingPermit from '../../../../../../assets/images/icons/permis-de-construire.svg';
import { usePrograms } from '../../../../hooks/usePrograms';
import { DateTime } from 'luxon';
import _, { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { distance as distancePoint } from '@turf/turf';
import classNames from 'classnames';
import { useDebounce } from 'react-use';
import uniqid from 'uniqid';

import styled from 'styled-components';
import useMsMap from '../../../../hooks/useMSMap';
import { useBlock3_1 } from '../../../MarketSurveys/sections/MsTableNewProgrammes';
import { setInView } from '../../../Programs/ProgramsTable';

const StyledTooltip = styled(Tooltip)`
  padding: 2px !important;
  background: rgba(255, 255, 255, 0.75) !important;
`;

function troncateStr(str) {
  return str.slice(0, 10) + '...';
}

const numberMarker = (num, trandId) =>
  L.divIcon({
    className: `icon_number_container_${trandId}`,
    iconSize: [32, 32],
    html: /* HTML */ `<div
      id="icon_number_${trandId}"
      style="transition: transform 0.25s;"
      class="w-8 h-8 rounded-full bg-greenMarker border-1 border-white flex items-center text-sm justify-center font-semibold text-white"
    >
      ${num}
    </div>`,
  });

let ProgramInProgressIcon = new L.icon({
  iconUrl: ProgramInProgress,
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

let ProgramOverSince2019Icon = new L.icon({
  iconUrl: ProgramOverSince2019,
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

let SelectedProgramIcon = new L.icon({
  iconUrl: SelectedProgram,
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

let BuildingPermitIcon = new L.icon({
  iconUrl: BuildingPermit,
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

const markerIcon = (
  { tran_id, date_fin_commercialisation, date_livraison, date_fondation },
  activePrograms
) => {
  if (
    _.indexOf(
      _.map(activePrograms, (activeProgram) => {
        return parseInt(activeProgram);
      }),
      tran_id
    ) !== -1
  ) {
    return SelectedProgramIcon;
  }

  if (date_fin_commercialisation === null) {
    return ProgramInProgressIcon;
  }

  if (
    date_livraison &&
    DateTime.fromISO(date_livraison) >= DateTime.fromISO('2019-01-01')
  ) {
    return ProgramOverSince2019Icon;
  }

  if (date_fondation && DateTime.fromISO(date_fondation) >= DateTime.now()) {
    return BuildingPermitIcon;
  }

  return ProgramOverSince2019Icon;
};

const MyMarker = ({
  isPdfMode,
  programItem,
  handleSelectProgram,
  activePrograms,
  onAddActiveProgramme,
  markersFaibleDistance,
  isProgrammeMap,
  onHoverInTable,
  noCluster = false,
}) => {
  const [troncate, setTroncate] = useState(false);
  const { currentHoverTrandId } = useMsMap();
  const defaultZindex = useRef(null);
  const { currentTranId } = usePrograms();

  useEffect(() => {
    if (!noCluster) {
      const currentMarker = programItem.ref.current || {};
      if (
        currentHoverTrandId &&
        currentHoverTrandId === programItem.properties.tran_id &&
        currentTranId !== currentHoverTrandId
      ) {
        onHoverInTable && onHoverInTable(currentMarker, true);
      } else if (currentHoverTrandId === null) {
        onHoverInTable && onHoverInTable(currentMarker, false);
      }
    }
  }, [currentHoverTrandId, currentTranId, noCluster]);

  useDebounce(
    () => {
      if (programItem && !noCluster) {
        const currentMarker = programItem.ref.current || {};
        defaultZindex.current = currentMarker._zIndex;
        if (currentMarker._icon) {
          currentMarker._icon.style.transition = `height 0.25s, width 0.25s`;
          if (
            currentHoverTrandId &&
            currentHoverTrandId === programItem.properties.tran_id &&
            currentTranId !== currentHoverTrandId
          ) {
            setTimeout(() => {
              if (currentMarker._icon) {
                currentMarker._icon.style.zIndex = 10000;
                currentMarker._icon.style.width = '48px';
                currentMarker._icon.style.height = 'auto';
              }
            }, 100);
          } else {
            currentMarker._icon.style.width = '30px';
            currentMarker._icon.style.height = 'auto';
            currentMarker._icon.style.zIndex = defaultZindex.current;
            onHoverInTable && onHoverInTable(currentMarker, false);
          }
        }
      }
    },
    200,
    [currentHoverTrandId, currentTranId, noCluster]
  );

  const trandIdString = programItem?.properties?.tran_id?.toString();
  const trandId =
    trandIdString.indexOf('99999') === 0
      ? trandIdString.replace('99999', '')
      : trandIdString;

  useEffect(() => {
    if (markersFaibleDistance?.length) {
      setTroncate(
        markersFaibleDistance
          .map((item) => item.properties.tran_id)
          .includes(programItem.properties.tran_id)
      );
    }
  }, [markersFaibleDistance]);

  return (
    <Marker
      zIndexOffset={
        activePrograms.includes(programItem.properties.tran_id) ? 4000 : 1000
      }
      id={programItem.properties.tran_id}
      ref={programItem.ref}
      position={transformCoordinates(programItem.geometry.coordinates)}
      icon={
        programItem.number
          ? numberMarker(programItem.number, programItem.properties.tran_id)
          : markerIcon(programItem.properties, activePrograms)
      }
      eventHandlers={{
        click: () => {
          handleSelectProgram &&
            handleSelectProgram(
              programItem.properties.tran_id,
              programItem.ref,
              programItem
            );
        },
        add: () => {
          if (activePrograms.includes(programItem.properties.tran_id)) {
            onAddActiveProgramme && onAddActiveProgramme(programItem);
          }
        },
      }}
    >
      {isPdfMode && (
        <>
          {activePrograms.includes(programItem.properties.tran_id) && (
            <StyledTooltip placement="bottom" opacity={1} permanent>
              <div className="text-xs">
                <div>
                  <span>
                    {troncate
                      ? troncateStr(programItem.properties.nom_programme)
                      : programItem.properties.nom_programme}
                  </span>{' '}
                  (<span>{trandId}</span>),
                </div>
                <div>
                  <span className="font-semibold">Commune</span>:{' '}
                  {troncate
                    ? troncateStr(programItem.properties.commune)
                    : programItem.properties.commune}
                </div>
              </div>
            </StyledTooltip>
          )}
        </>
      )}
      {!isPdfMode && !isProgrammeMap && (
        <Tooltip direction="bottom" offset={[0, 0]} opacity={1}>
          <div className="text-xs12">
            <div>
              <span>{programItem.properties.nom_programme}</span> (
              <span>{trandId}</span>)
            </div>
            <div>
              <span className="font-semibold">Commune</span>:{' '}
              {programItem.properties.commune}
            </div>
          </div>
        </Tooltip>
      )}
    </Marker>
  );
};

const getSuperposedPoints = (points, distance = 0.05, values = []) => {
  if (!points.length) {
    return values;
  }
  const currentValues = values;

  const [first, ...rest] = points;

  const temp = [
    first,
    ...rest.filter(
      (item) =>
        distancePoint(
          transformCoordinates(item.geometry.coordinates),
          transformCoordinates(points[0].geometry.coordinates)
        ) <= distance
    ),
  ];

  if (temp.length >= 2) {
    temp.forEach((item) => {
      currentValues.push(item);
    });
  }

  const currentPoints = points.filter(
    (item) =>
      !temp.map((el) => el.properties.tran_id).includes(item.properties.tran_id)
  );

  return getSuperposedPoints(currentPoints, distance, currentValues);
};

const getGroupBySuperposedPoints = (points) => {
  return groupBy(points, (value) =>
    Math.round(
      distancePoint([0, 0], transformCoordinates(value.geometry.coordinates))
    )
  );
};

const iconCreateFunction = (activePrograms) => (cluster) => {
  const markers = cluster.getAllChildMarkers();

  const inside =
    markers.filter((item) => activePrograms.includes(parseInt(item.options.id)))
      .length >= 1;

  const html = /* html */ `<div class="${classNames('custom-cluster-marker', {
    'custom-cluster-marker-has-selelected-program': inside,
  })}">
    <span class="label">${markers.length}</span>
    <span class="deco"></span>
  </div>`;

  return L.divIcon({
    html: html,
    className: classNames('mycluster', {
      'have-selected-icon-cluster': inside,
    }),
    iconSize: L.point(32, 32),
  });
};

const ClusterGroup = ({
  activePrograms,
  group,
  readOnlyMode,
  handleSelectProgram,
  isPdfMode,
  isProgrammeMap,
  onHoverMarkerInCluster,
}) => {
  return (
    <MarkerClusterGroup
      iconCreateFunction={iconCreateFunction(activePrograms)}
      maxClusterRadius={30}
    >
      {group.map((programItem) => {
        return (
          <MyMarker
            key={uniqid()}
            isProgrammeMap={isProgrammeMap}
            programItem={programItem}
            isPdfMode={isPdfMode}
            handleSelectProgram={handleSelectProgram}
            activePrograms={activePrograms}
            readOnlyMode={readOnlyMode}
            onHoverInTable={onHoverMarkerInCluster}
          />
        );
      })}
    </MarkerClusterGroup>
  );
};

const MarkerCollectionLayer = forwardRef(
  (
    {
      noCluster = false,
      readOnlyMode,
      setProgrammesDataCarto,
      programmesNeufs,
      isPdfMode,
      onlySelected,
      onChangeCurrentProgrammePopupDate,
      currentProgrammePopupData,
      isProgrammeMap,
    },
    ref
  ) => {
    const { activePrograms, setProgramsRefs, setCurrentTranId } = usePrograms();
    const { programmesNeufs: programmesNeufsData } = useSelector(
      (state) => state.marketSurvey
    );
    const [superposedMarkers, setSuperposedMarkers] = useState([]);
    const [groupedSuperposedMarkers, setGroupedSuperposedMarkers] = useState(
      []
    );
    const { setCurrentTranIdClick } = useBlock3_1((state) => state);

    const [markersData, setMarkersData] = useState([]);
    const map = useMap();

    useImperativeHandle(ref, () => ({
      setCurrentPopup(tran_id, callback) {
        onChangeCurrentProgrammePopupDate &&
          onChangeCurrentProgrammePopupDate(null);
        setTimeout(() => {
          const currentData = markersData.find(
            (item) => item.properties.tran_id === tran_id
          );
          onChangeCurrentProgrammePopupDate &&
            onChangeCurrentProgrammePopupDate(currentData);
          callback && callback(currentData);
        }, 500);
      },
      closePopup() {
        onChangeCurrentProgrammePopupDate &&
          onChangeCurrentProgrammePopupDate(null);
      },
    }));

    const {
      programmesCarto: programGeoData,
      selectedProgrammesCarto,
      onlySelelectedProgrammes,
    } = useSelector((state) => state.programme);

    useEffect(() => {
      const _programmesNeufs =
        programmesNeufs &&
        programmesNeufsData &&
        programmesNeufsData.length &&
        !onlySelected;

      let currentData =
        noCluster && onlySelected
          ? onlySelelectedProgrammes
          : [
              // selected programmes
              ...(selectedProgrammesCarto.length
                ? selectedProgrammesCarto
                : (programGeoData?.features || []).filter((item) =>
                    activePrograms.includes(item.properties.tran_id)
                  )),

              // unselected programmes OR programmes
              ...(programGeoData?.features || []).filter(
                (item) => !activePrograms.includes(item.properties.tran_id)
              ),
            ];

      if (_programmesNeufs) {
        currentData = programmesNeufsData;
      }

      currentData = currentData.map((item) => ({
        ...item,
        ref: createRef(),
      }));

      const currentRefs = currentData.map((item) => {
        // Check if item and necessary nested properties exist
        const tran_id = item?.properties?.tran_id;
        const ref = item?.ref;
        const coordinates = item?.geometry?.coordinates;

        if (tran_id && ref && coordinates) {
          return { tran_id, ref, coordinates };
        }

        // Optionally, handle the case where the properties do not exist
        // This might involve returning a default value, logging an error, etc.
        console.error('Missing data:', item);
        return null;
      });

      // only selected programmes
      if (onlySelected && !noCluster) {
        currentData = currentData.filter((item) =>
          activePrograms.includes(item.properties.tran_id)
        );
      }

      setProgramsRefs(currentRefs.filter((ref) => ref !== null));
      setMarkersData(
        currentData.filter((feature) => feature?.geometry !== null)
      );
      setProgrammesDataCarto && setProgrammesDataCarto(currentData);
    }, [
      selectedProgrammesCarto,
      programGeoData,
      activePrograms,
      programmesNeufsData,
      programmesNeufs,
      noCluster,
      onlySelelectedProgrammes,
    ]);

    const handleSelectProgram = async (id, _arg2, programme) => {
      onChangeCurrentProgrammePopupDate &&
        onChangeCurrentProgrammePopupDate(null);
      setTimeout(() => {
        onChangeCurrentProgrammePopupDate &&
          onChangeCurrentProgrammePopupDate(programme);
      }, 500);
      setCurrentTranId(id);
      noCluster && setCurrentTranIdClick(id);
      noCluster && setInView(id);
    };

    useDebounce(
      () => {
        if (!noCluster) {
          const superposedPoints = getSuperposedPoints(markersData);
          setSuperposedMarkers(superposedPoints);
          setGroupedSuperposedMarkers([]);
          setTimeout(() => {
            setGroupedSuperposedMarkers(
              Object.values(getGroupBySuperposedPoints(superposedPoints))
            );
          }, 1000);
        }
      },
      1000,
      [markersData, activePrograms, noCluster]
    );

    const tempMarker = useRef([]);
    const handleOnHoverMarkerInCluster = (marker, isHover) => {
      if (isHover) {
        const newMarker = L.marker(marker.getLatLng(), {
          icon: marker.getIcon(),
        });
        map.addLayer(newMarker);
        tempMarker.current.push(newMarker);
        newMarker._icon.style.transition = 'width 0.25s';
        setTimeout(() => {
          if (newMarker._icon) {
            newMarker._icon.style.width = '48px';
            newMarker._icon.style.height = 'auto';
            newMarker._icon.style.zIndex = 10000;
            newMarker._icon.style.marginLeft = '-24px';
            newMarker._icon.style.marginTop = '-60px';
          }
        }, 100);
      } else {
        if (tempMarker.current) {
          tempMarker.current.forEach((el) => {
            map.removeLayer(el);
          });
        }
      }
    };

    return (
      <>
        {isPdfMode || noCluster ? (
          <>
            {markersData.map((programItem, index) => (
              <MyMarker
                key={index}
                programItem={programItem}
                isPdfMode={isPdfMode}
                handleSelectProgram={handleSelectProgram}
                activePrograms={activePrograms}
                readOnlyMode={readOnlyMode}
                noCluster={noCluster}
                markersFaibleDistance={getSuperposedPoints(
                  markersData.filter((item) =>
                    activePrograms.includes(item.properties.tran_id)
                  ),
                  1
                )}
              />
            ))}
          </>
        ) : (
          <>
            {groupedSuperposedMarkers.map((group) => {
              return (
                <ClusterGroup
                  key={uniqid()}
                  activePrograms={activePrograms}
                  group={group}
                  handleSelectProgram={handleSelectProgram}
                  isProgrammeMap={isProgrammeMap}
                  readOnlyMode={readOnlyMode}
                  isPdfMode={isPdfMode}
                  onHoverMarkerInCluster={handleOnHoverMarkerInCluster}
                />
              );
            })}
            {markersData
              .filter(
                (item) =>
                  !superposedMarkers
                    .filter(
                      (el) =>
                        el.properties.tran_id !==
                        currentProgrammePopupData?.properties.tran_id
                    )
                    .map((el) => el.properties.tran_id)
                    .includes(item.properties.tran_id)
              )
              .map((programItem, index) => (
                <MyMarker
                  key={index}
                  programItem={programItem}
                  isPdfMode={isPdfMode}
                  handleSelectProgram={handleSelectProgram}
                  activePrograms={activePrograms}
                  readOnlyMode={readOnlyMode}
                  isProgrammeMap={isProgrammeMap}
                />
              ))}
          </>
        )}
      </>
    );
  }
);

export default memo(MarkerCollectionLayer);
