import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import classNames from 'classnames';

export const getCoordFromUrlStreet = (googleStreetUrl) => {
  const urlParams = new URLSearchParams(googleStreetUrl);
  if (urlParams.get('cbll')) {
    const coord = urlParams.get('cbll').split(',');
    if (coord.length) {
      return coord;
    }
    return coord;
  }
  return null;
};

const toAlignRight = ['pmmv', 'pmr', 'rythme_moyen_resas'];

const keys = [
  'mev',
  'nbres',
  'div',
  'surf',
  'pmmv',
  'pmr',
  'rythme_moyen_resas',
];
const keysTab = [
  {
    key: '_t1',
    label: 'T1',
  },
  {
    key: '_t2',
    label: 'T2',
  },
  {
    key: '_t3',
    label: 'T3',
  },
  {
    key: '_t4',
    label: 'T4',
  },
  {
    key: '_t5plus',
    label: 'T5+',
  },
  {
    key: '',
    label: 'Total',
  },
];

const TableProgrammeDetail = ({ onClose, programme = null }) => {
  return (
    <>
      {programme && (
        <div className="w-full h-auto bg-white rounded-md overflow-hidden relative shadow-lg">
          <div className="w-full relative">
            <div
              onClick={() => onClose && onClose()}
              style={{ top: '3px', right: '3px' }}
              className="absolute cursor-pointer hover:text-red-500"
            >
              <XIcon className="h-4 w-4 text-black" />
            </div>
            <table className="w-full">
              <thead>
                <tr
                  style={{ fontSize: 11 }}
                  className="bg-blue-300 rounded-t-md py-2 text-black"
                >
                  <th className="px-3 py-1 text-center "></th>
                  <th className="px-3 py-1 text-center">Logt. MEV</th>
                  <th className="px-3 py-1 text-center">Résas</th>
                  <th className="px-3 py-1 text-center">Résas / Logt. MEV</th>
                  <th className="px-3 py-1 text-center">Surface</th>
                  <th className="px-3 py-1 text-right">Prix* MEV</th>
                  <th className="px-3 py-1 text-right">Prix* Résas</th>
                  <th className="px-3 py-1 text-right pr-8">Rythme* Résas</th>
                </tr>
              </thead>
              <tbody>
                {keysTab.map((index) => {
                  const isTotal = index.label === 'Total';

                  return (
                    <tr
                      key={index.key}
                      className={classNames('border-b-black', {
                        'border-b-1 ': !isTotal,
                        'border-b-2 border-t-2': isTotal,
                      })}
                    >
                      <td className="font-bold text-center text-xs12 px-2 py-1.5">
                        {index.label}
                      </td>

                      {keys.map((key) => {
                        if (key === 'div') {
                          let result = (
                            (parseFloat(programme[`nbres${index.key}`]) /
                              parseFloat(programme[`mev${index.key}`])) *
                            100
                          ).toFixed(2);

                          return (
                            <td
                              className="text-center text-xs12 px-2 py-1.5"
                              key={key}
                            >
                              {isNaN(result) ? '-' : `${result} %`}
                            </td>
                          );
                        }

                        return (
                          <td
                            className={classNames('text-xs12 px-2 py-1.5', {
                              'text-center': !toAlignRight.includes(key),
                              'text-right': toAlignRight.includes(key),
                              'pr-8': key === 'rythme_moyen_resas',
                            })}
                            key={key}
                          >
                            {programme[`${key}${index.key}`] || '-'}{' '}
                            {key === 'surf' &&
                              programme[`${key}${index.key}`] &&
                              'm²'}
                            {['pmmv', 'pmr'].includes(key) &&
                              programme[`${key}${index.key}`] &&
                              '€'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="py-2 px-3 flex justify-between items-baseline">
              <span className="text-xs10">
                <span className="font-semibold">
                  Durée d'écoulement (prévisionnelle ou constatée)
                </span>
                :{' '}
                <span className="font-bold">
                  {programme.duree_ecoulement} mois
                </span>
              </span>
              <span className="text-xs10">*moyen</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableProgrammeDetail;
