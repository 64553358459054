import {Marker, Polyline, Popup, useMap} from "react-leaflet";
import LegendLayer from "../Map/Legends/LegendLayer";
import React, {createRef} from "react";
import L from "leaflet";
import useSWR from "swr";
import Api from "../../../../api/Api";
//import LandIcon from '../../../../../assets/images/icons/terrain.png';
/*let LandIcon = new L.icon({
    iconUrl: IconAdd,
    iconSize: [21.3, 21.3],
    // shadowUrl: iconShadow,
});

*/
import _ from "lodash";
import {poiIcons} from "../../../icons";
import styled from "styled-components";
import {XIcon} from "@heroicons/react/outline";

// delete L.Icon.Default.prototype._getIconUrl()
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// })


const CustomPop = styled(Popup)`
  background-color: white;
  border-radius: 0;
  width: 400px;
  height: auto;
  border-radius: 10px;
 .leaflet-popup-content{
    margin: 10px;
 }
 .leaflet-popup-content-wrapper {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }
  leaflet-popup-close-button{
    color: gray;
    right: 10px;
  }
  .leaflet-popup-close-button{
    display: none
  }
  
`;


const PoiPathLayer = ({data}) => {
    const map = useMap()
    if (!map) return null;

    const fetcher = url => Api.get(url, {}).then(res => res.data)
    const {data: journey_link, error} = useSWR(data.properties.journey_link, fetcher)

    if (!journey_link) {
        return <div>Loading path...</div>
    }

    /* if (journey_link) {

     }*/

    if (error || (journey_link && !journey_link.data)) {
        return <div>Failed to load path...</div>
    }


    const transformCoordinates = (point) => {
        if (point.length >= 2) {
            return L.Projection.SphericalMercator.unproject(L.point(point[0], point[1]));
        }
        return point
    }

    const markers = _.filter(_.filter(journey_link.data.routes[0].features, (feature) => {
        return feature.type === 'Feature'
    }), (feature) => {
        return feature.geometry.type === "Point"
    })


    const path = _.map(_.find(_.find(journey_link.data.routes[0].features, (feature) => feature.type === 'Feature'), (geometry) => {
        return geometry.type === 'LineString'
    }).coordinates, transformCoordinates)

    if (path) {
        map.fitBounds(path);
    }


    const closePopup = (ref) => {
        if (!ref.current || !map) return;
        ref.current.closePopup();
    }


    const geoJsonStyle = () => {
        return {
            fillColor: '#63b3ed',
            weight: 4,
            opacity: 1,
            color: '#ed8936',
            dashArray: '1',
            fillOpacity: 0.9,
            smoothFactor: 1
        };
    }
    return (
        <>
            {markers?.map((marker, index) => {
                const ref = createRef()

                return (
                    <Marker
                        ref={ref}
                        key={`marker__${index}`}
                        position={transformCoordinates(marker.geometry.coordinates)}
                        icon={poiIcons(marker.properties.targetId || marker.properties.sourceId)}>
                        <CustomPop>
                            <div className={"absolute right-0 top-0"}>
                                <button onClick={() => closePopup(ref)} className={"p-2"}>
                                    <XIcon className={"w-5 h-5 text-blue-800 hover:text-blue-900"}/></button>
                            </div>
                        </CustomPop>
                    </Marker>
                )
            })}
            <Polyline
                pathOptions={geoJsonStyle()}
                positions={path}
            />
            {data.properties && <LegendLayer legendData={data.properties.legend}/>}
        </>
    )
}

export default PoiPathLayer
