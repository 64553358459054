import React, { useMemo } from 'react';
import final from '../../../../assets/images/final.mp4';
import logo from '../../../../assets/images/logo.svg';
import capemLogo from '../../../../assets/images/capem_logo.gif';
import classNames from 'classnames';
import bg1 from '../../../../assets/images/paris_aerial2.jpg';

export function isImageUrl(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

const Svg = () => {
  return (
    <svg
      className="w-full"
      fill="white"
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
    >
      <path d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z" />
      <path d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z" />
      <path d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z" />
    </svg>
  );
};

const AuthContainer = ({
  children,
  hideText,
  showCapemLogo,
  mediaUrl,
  typeMedia = 'video',
  login = false,
  registration,
}) => {
  const background = useMemo(
    () =>
      (login ? (mediaUrl === 'paris_aerial2.jpg' ? bg1 : final) : mediaUrl) ||
      final,
    [mediaUrl, login]
  );

  return (
    <div className="relative w-full min-h-screen flex justify-center">
      <div className="absolute top-0 left-0 w-full h-full  hidden sm:block overflow-hidden">
        {!!background?.length && (
          <>
            {isImageUrl(mediaUrl) || typeMedia === 'img' ? (
              <img
                src={background}
                className="h-full w-full transform scale-125 object-cover object-center"
                alt="bg"
              />
            ) : (
              <video
                className="h-full w-full object-cover object-center"
                loop
                autoPlay
                muted
              >
                <source src={background} type="video/mp4" />
              </video>
            )}
          </>
        )}
      </div>
      <div className="absolute z-10 w-full left bottom-0 hidden sm:block">
        <Svg />
      </div>
      <div
        style={{ maxWidth: '600px' }}
        className={classNames(
          'relative z-20 w-auto flex flex-col items-center ',
          {
            'justify-center sm:justify-end': hideText,
          },
          {
            'justify-evenly sm:justify-between pt-28 xs:pt-0':
              !hideText || showCapemLogo,
          }
        )}
      >
        {!hideText && (
          <div
            className={classNames(
              'mb-10 flex flex-col items-center',
              { 'sm:mt-12': registration },
              { 'sm:mt-24': !registration }
            )}
          >
            <img width={324} src={logo} />
            <h2 className="text-center lg-text-left text-blue-900 sm:whitespace-nowrap text-2xl sm:text-4xl ">
              Analyser, Décider, Convaincre
            </h2>
          </div>
        )}
        {showCapemLogo && (
          <div className="sm:mt-28 my-6 sm:mb-10 flex flex-col items-center">
            <img width={324} src={logo} />
            <div className="py-2 px-4 bg-gray-300 sm:bg-transparent">
              <img width={250} src={capemLogo} />
            </div>
          </div>
        )}
        <div className="w-full sm:mb-8 flex items-center flex-col px-4 sm:px-0">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
