import React from 'react';
import Button from '../Button';
import Header from '../Header';
import { ArrowLeftIcon, XIcon } from '@heroicons/react/outline';

const Card = (props) => {
  const {
    // size,
    // backgroundColor,
    // mode,
    // ws,
    position,
    zIndex,
    rounded,
    margin,
    padding,
    shadow,
    header,
    theme,
    left,
    dontShowButton,
    title,
    logo,
    children,
    setOpen,
    itemsCenter,
    arrowLeft,
    arrowLeftTopPosition,
    cancelAction,
    onClose,
  } = props;

  return (
    <div
      className={`w-full md:w-96 flex flex-col ${
        rounded ? 'rounded-xl' : 'rounded-lg'
      } ${margin ? 'mt-6 mb-6 md:mb-0 mr-0' : 'mt-0'}
      ${itemsCenter ? itemsCenter : 'items-center'}
      ${padding && 'md:p-8'}
      ${theme === 'light' ? 'bg-white' : 'bg-blue-darken'}
      ${shadow ? 'shadow-2xl' : 'shadow-none'}`}
      style={{
        position: position ? position : 'initial',
        left: !left ? 'unset' : left,
        zIndex: zIndex ? zIndex : 'initial',
      }}
    >
      <div
        onClick={() => onClose && onClose()}
        className="absolute z-50 right-1 top-1 md:hidden"
      >
        <XIcon className="w-6 h-6" />
      </div>
      {arrowLeft && (
        <div
          className="arrow-left"
          style={{
            top: arrowLeftTopPosition ? arrowLeftTopPosition : '50%',
          }}
        ></div>
      )}
      {cancelAction && (
        <div
          className="w-full text-blue-400 flex justify-between items-center"
          onClick={cancelAction}
        >
          <ArrowLeftIcon className={'w-3 h-3 cursor-pointer p-0'} />
          <p className="text-sm cursor-pointer my-0">Annuler</p>
        </div>
      )}
      {header && (
        <div className="rounded-lg">
          <Header logo={logo} />
        </div>
      )}
      {!header && (
        <>
          <h4 className="mt-4 md:mt-0 self-start font-bold ml-4 md:ml-0">
            {title ? title : 'Ciblage'}
          </h4>
          {children ? (
            children
          ) : (
            <p>
              Duis aliquet diam nibh, ac tempor elit facilisis et. Quisque eu
              arcu vitae elit condimentum vulputate. Aliquam erat volutpat. Sed
              non faucibus nulla, a maximus orci.{' '}
              <i className="ri-admin-line" />
            </p>
          )}
        </>
      )}
      {!dontShowButton && (
        <Button
          title="Nouveau Ciblage"
          onClick={setOpen}
          type="button"
          className="my-6"
        />
      )}
    </div>
  );
};

export default Card;
