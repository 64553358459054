import { useSelectedParcels } from '../../../../hooks/useSelectedParcels';
import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import MarkerCollectionLayer from './MarkerCollectionLayer';
import MarketSurveyLegendLayer from '../Map/Legends/MarketSurveyLegendLayer';
import BuildingPermitCollectionLayer from './BuildingPermitCollectionLayer';
import MarkerPolylabelLayer from './MarkerPolylabelLayer';
import { usePrograms } from '../../../../hooks/usePrograms';
import { useMap } from 'react-leaflet';
import _, { indexOf, isEmpty } from 'lodash';
import classnames from 'classnames';
import { updateMarketSurvey } from '../../../../services/market-survey';
import { useHistory, useParams } from 'react-router-dom';
import L from 'leaflet';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { useMetadataMs } from '../../../../hooks/useChangeOnMSMap';
import { fitBoundWithPadding } from './MsPOILayer';
import useCenterByMetadata from '../../../../_helpers/map-helpers/useCenterByMetadata';

export const disableZoom = (map) => {
  if (map) {
    map.touchZoom?.disable();
    map.doubleClickZoom?.disable();
    map.scrollWheelZoom?.disable();
    map.boxZoom?.disable();
    map.keyboard?.disable();
  }
};

export const enableZoom = (map) => {
  if (map) {
    map.touchZoom?.enable();
    map.doubleClickZoom?.enable();
    map.scrollWheelZoom?.enable();
    map.boxZoom?.enable();
    map.keyboard?.enable();
  }
};

export const centerMapEDM = (
  programmesDataCarto,
  activePrograms,
  communesParcelles,
  map,
  callback
) => {
  if (programmesDataCarto?.length && activePrograms?.length) {
    const selectedProgrammes = programmesDataCarto.filter((item) =>
      activePrograms.includes(item.properties.tran_id)
    );

    const markersGroup = L.featureGroup();
    selectedProgrammes.forEach((item) => {
      L.marker(transformCoordinates(item.geometry.coordinates)).addTo(
        markersGroup
      );
    });

    communesParcelles.forEach((item) => {
      L.geoJSON(item).addTo(markersGroup);
    });

    fitBoundWithPadding(map, markersGroup, 10);

    callback && callback();
  }
};

export const centerMapProgrammeWithIsochrone = (
  isochroneCarFeature,
  map,
  callback
) => {
  const featureGroup = L.featureGroup();
  L.geoJSON(isochroneCarFeature).addTo(featureGroup);
  if (featureGroup.getBounds().isValid()) {
    map.fitBounds(featureGroup.getBounds());
    callback && callback();
  }
};

export const centerMapProgrammeByCommune = (_communesParcelles, map) => {
  if (_communesParcelles.length) {
    const featureGroup = L.featureGroup();
    _communesParcelles.forEach((item) => {
      L.geoJSON(item).addTo(featureGroup);
    });
    if (featureGroup.getBounds().isValid()) {
      map.fitBounds(featureGroup.getBounds());
    }
  }
};

const MarketSurveysLayer = forwardRef(
  (
    {
      name,
      readOnlyMode,
      onLoadLegendMarketSurvey,
      isProgrammeMap,
      programmesNeufs,
      needIsochroneVoiture,
      isPdfMode,
      onlySelected,
      onChangeCurrentProgrammePopupDate,
      currentProgrammePopupData,
      noCluster,
    },
    ref
  ) => {
    const { communesParcelles } = useSelector((state) => state.others);
    const [showMap, setShowMap] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const { isochroneCarFeature } = useSelector((state) => state.marketSurvey);

    const map = useMap();
    const history = useHistory();
    const { survey_id } = useParams();
    const metadata = useMetadataMs((state) => state.metadata);
    const [centerredByMetadata, setCenterredByMetaData] = useState(false);

    const centerByMetadata = useCenterByMetadata({
      metadata,
      map,
      isPdfMode,
      name,
      onReady: () => {
        setCenterredByMetaData(true);
      },
    });

    const { activePrograms, programmeLayerRef } = usePrograms();
    const [programmesDataCarto, setProgrammesDataCarto] = useState(null);

    const { programs } = usePrograms();
    const { fieldIds } = useSelectedParcels();

    useEffect(() => {
      if (programmesDataCarto?.length && centerredByMetadata && isPdfMode) {
        setShowMap(true);
      }
    }, [programmesDataCarto, isPdfMode, centerredByMetadata]);

    useEffect(() => {
      if (communesParcelles.length && !isPdfMode) {
        map.on('popupopen', function (e) {
          disableZoom(map);
          setTimeout(() => {
            var px = map.project(e.target._popup._latlng);
            px.y -= e.target._popup._container.clientHeight / 1.5;
            const details = document.getElementById('details-popup');
            if (details) {
              if (window.getComputedStyle(details).display === 'none') {
                setTimeout(() => {
                  map.panTo(map.unproject(px), { animate: isProgrammeMap });
                  setTimeout(() => {
                    enableZoom(map);
                  }, 500);
                }, 500);
              } else {
                setTimeout(() => {
                  enableZoom(map);
                }, 500);
              }
            }
          }, 500);
        });
      }
    }, [map, communesParcelles, isPdfMode]);

    // CENTER MAP EFFECTS ///////////////////////////////////////////////////
    useEffect(() => {
      if (isProgrammeMap && !needIsochroneVoiture && !centerByMetadata) {
        centerMapProgrammeByCommune(communesParcelles, map);
      }
    }, [
      communesParcelles,
      isProgrammeMap,
      map,
      needIsochroneVoiture,
      centerByMetadata,
    ]);

    useEffect(() => {
      if (
        needIsochroneVoiture &&
        (isochroneCarFeature || communesParcelles) &&
        !centerByMetadata
      ) {
        centerMapProgrammeWithIsochrone(
          isochroneCarFeature || communesParcelles,
          map,
          () => setShowMap(true)
        );
      }
    }, [
      needIsochroneVoiture,
      isochroneCarFeature,
      communesParcelles,
      map,
      centerByMetadata,
    ]);

    useEffect(() => {
      if (
        communesParcelles.length &&
        programmesDataCarto &&
        !isProgrammeMap &&
        activePrograms?.length &&
        !needIsochroneVoiture &&
        !centerByMetadata
      ) {
        centerMapEDM(
          programmesDataCarto,
          activePrograms,
          communesParcelles,
          map,
          () => setShowMap(true)
        );
      }
    }, [
      programmesDataCarto,
      activePrograms,
      map,
      communesParcelles,
      centerByMetadata,
    ]);
    ///////////////////////////////////////////////////////////////////////

    useImperativeHandle(ref, () => ({
      getProgrammesCarto() {
        return programmesDataCarto;
      },
    }));

    useEffect(() => {
      if (activePrograms) {
        setIsDisabled(activePrograms.length < 2);
      }
    }, [activePrograms]);

    const handleUpdateMarketSurvey = () => {
      const selectedPrograms = _.filter(programs, (program) => {
        return activePrograms.includes(program.attributes.tran_id);
      });
      const updateMarketSurveyPayload = {
        real_estate_programs_attributes: [
          ..._.map(selectedPrograms, (program) => {
            const {
              nom_programme,
              date_livraison,
              nb_logt_mis_en_vente,
              pmmv,
              tran_id,
            } = program.attributes;

            return {
              reference: tran_id,
              name: nom_programme,
              release_date: date_livraison,
              quantity_housing_for_sale: nb_logt_mis_en_vente,
              average_cost_per_square_meter_tax_included: pmmv,
            };
          }),
        ],
      };

      updateMarketSurvey(survey_id, updateMarketSurveyPayload, () => {
        history.push(`/market-survey/${survey_id}/detail`);
      });
    };

    return (
      <>
        <BuildingPermitCollectionLayer />
        <MarkerCollectionLayer
          noCluster={noCluster}
          isProgrammeMap={isProgrammeMap}
          currentProgrammePopupData={currentProgrammePopupData}
          onChangeCurrentProgrammePopupDate={onChangeCurrentProgrammePopupDate}
          ref={programmeLayerRef}
          isPdfMode={isPdfMode}
          readOnlyMode={readOnlyMode}
          programmesNeufs={programmesNeufs}
          setProgrammesDataCarto={setProgrammesDataCarto}
          onlySelected={onlySelected}
        />
        {!isEmpty(fieldIds) && <MarkerPolylabelLayer />}
        <MarketSurveyLegendLayer
          noCluster={noCluster}
          onlySelected={onlySelected}
          programmesNeufs={programmesNeufs}
          onLoadLegendMarketSurvey={onLoadLegendMarketSurvey}
          indexStudiesParcel={0}
          isPdfMode={isPdfMode}
        />
        {!isProgrammeMap && !showMap && (
          <div
            style={{ zIndex: 1000000 }}
            className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-25 flex justify-center items-center"
          >
            <ReactLoading
              type="bubbles"
              color="#4299E1"
              width={200}
              height={200}
            />
          </div>
        )}
        {!window.location.pathname.includes('/detail') && (
          <button
            onClick={handleUpdateMarketSurvey}
            disabled={isDisabled}
            type="button"
            style={{ zIndex: 999999 }}
            className={classnames(
              {
                'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500':
                  !isDisabled,
              },
              {
                'bg-blue-200 focus:outline-none cursor-not-allowed': isDisabled,
              },
              'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white fixed bottom-1 left-1 md:hidden'
            )}
          >
            Lancer L'étude de marché
          </button>
        )}
      </>
    );
  }
);

export default memo(MarketSurveysLayer);
