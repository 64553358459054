import React, { useEffect, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import MenuMS from '../../utils/MenuMS';
import { useAuth } from '../../../hooks/useAuth';
import {
  copySharedSurvey,
  exportToPdfSharedSurvey,
} from '../../../store/slices/marketSurvey';
import swal from 'sweetalert';
import { ArrowUpIcon } from '@heroicons/react/outline';

const MarketSurveyPageHeader = ({
  surveyId,
  refs,
  dateMs,
  marketSurvey,
  handleUpdateMSName,
  isShare = false,
  tokenSurvey,
}) => {
  const [offset, setOffset] = useState(0);
  const { user } = useAuth();

  const {
    setIsQuickCheckModalOpened,
    setIsSharingModalOpened,
    setIsExportPDFModalOpened,
  } = useModal();

  const handleClickSharing = () => {
    setIsSharingModalOpened(true);

    // window.open(`<a href='https://balder-pi.vercel.app/api/render/pdf?url=${process.env.BASE_URL}/market-survey/${surveyId}/pdf'>telecharger le rapport d'étude</a>`)
  };

  const handleClickExportPDF = async () => {
    if (!isShare) {
      setIsExportPDFModalOpened(true);
    } else {
      const exportData = await exportToPdfSharedSurvey(
        tokenSurvey,
        user || null
      );
      if (exportData.error) {
        swal(exportData.error, {
          buttons: {
            cancel: 'Annuler',
            confirmButtonText: {
              text: 'Créer mon compte',
              value: true,
            },
          },
          icon: 'info',
        }).then(async (confirm) => {
          if (confirm) {
            window.location.assign('/users/sign_up');
          }
        });
      } else {
        swal('Vous allez recevoir une copie PDF de ce rapport !', {
          icon: 'info',
        });
      }
    }
  };

  const handleCLickCopy = async () => {
    const copyReturn = await copySharedSurvey(tokenSurvey, user || null);
    if (copyReturn && copyReturn.error) {
      swal(copyReturn.error, {
        buttons: {
          cancel: 'Annuler',
          confirmButtonText: {
            text: 'Créer mon compte',
            value: true,
          },
        },
        icon: 'info',
      }).then(async (confirm) => {
        if (confirm) {
          window.location.assign('/users/sign_up');
        }
      });
    } else {
      swal('Copie enregistrée, retrouvez cette étude dans la page Mes terrains !', {
        icon: 'info',
      });
    }
  };

  const handleClickTitle = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const fixOffset = () => {
      setOffset(
        document.getElementById('navbar')?.offsetHeight +
          document.getElementById('bottom-navbar')?.offsetHeight
      );
    };
    fixOffset();
    window.addEventListener('resize', fixOffset);
    return () => {
      window.removeEventListener('resize', fixOffset);
    };
  }, []);

  return (
    <div
      id={'ms-page-header'}
      className="w-full flex flex-row fixed bg-white z-30 print:hidden"
      style={{ top: `${offset}px` }}
    >
      <div className="py-2 pr-2 xl:pr-8 sm:py-2 lg:flex lg:justify-between items-center border border-b-1 border-gray-200 w-full gap-x-4">
        <div className="flex flex-row items-center gap-x-6">
          <MenuMS name={marketSurvey?.name || 'Étude de marché'} refs={refs} />
          {/* <div className="flex flex-nowrap items-baseline gap-x-5">
            <h1 style={{ wordBreak: 'break-word' }} onClick={handleClickTitle}>
              <EditableTextfield
                maxlength={50}
                onSave={handleUpdateMSName}
                value={marketSurvey?.name || 'Étude de marché'}
                classFont="text-lg 2xl:text-2xl uppercase font-bold hover:underline cursor-pointer"
                titleButton="Modifier le nom de votre étude"
              />
            </h1>
          </div> */}
          <div className="inline-block">
            <div className="flex gap-x-4 items-center">
              <span
                onClick={handleClickTitle}
                className="inline-block cursor-pointer bg-gray-500 p-1 rounded-md hover:bg-gray-600"
              >
                <ArrowUpIcon className="w-4 h-4 text-white" />
              </span>
              <span className="text-xs 2xl:text-sm">
                Actualisée le : {dateMs}
              </span>
            </div>
          </div>
        </div>

        <div className="relative z-0 inline-flex shadow-sm rounded-md">
          {user && !isShare ? (
            <>
              <button
                type="button"
                onClick={() => setIsQuickCheckModalOpened(true)}
                className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-r-2  border-blue-300 bg-blue-700 text-xs 2xl:text-sm font-medium text-blue-100 hover:bg-blue-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-800 focus:border-blue-800"
              >
                Ouvrir Quick Check
              </button>
              <button
                type="button"
                onClick={() =>
                  window.location.assign(`/market-survey/${surveyId}`)
                }
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-r-2 border-blue-300 bg-blue-700 text-xs 2xl:text-sm font-medium text-blue-100 hover:bg-blue-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-800 focus:border-blue-800"
              >
                Selection des programmes neufs
              </button>
              <button
                type="button"
                onClick={handleClickSharing}
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-r-2 border-blue-300 bg-blue-700 text-xs 2xl:text-sm font-medium text-blue-100 hover:bg-blue-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-800 focus:border-blue-800"
              >
                Partager
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={handleCLickCopy}
              className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-r-2  border-blue-300 bg-blue-700 text-xs 2xl:text-sm font-medium text-blue-100 hover:bg-blue-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-800 focus:border-blue-800"
            >
              Enregister une copie
            </button>
          )}
          <button
            type="button"
            onClick={handleClickExportPDF}
            className="-ml-px rounded-r-md relative inline-flex items-center px-4 py-2 border border-blue-300 bg-blue-700 text-xs 2xl:text-sm font-medium text-blue-100 hover:bg-blue-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-800 focus:border-blue-800"
          >
            Exporter au format PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketSurveyPageHeader;
