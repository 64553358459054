import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Api from '../../api/Api';
import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import { useModal } from '../../hooks/useModal';
import AxesContainer from '../molecules/AxesContainer';
import SelectedParcelsContainer from '../Parcels/SelectedParcelsContainer';
import StreetViewPlayer from '../Parcels/StreetViewPlayer';
import MapQuickCheck from '../molecules/DataVisualisations/Map/MapQuickCheck';
import IconAdd from '../../../../assets/images/icons/plus.png';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useHistory, useLocation } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import {
  createMarketSurvey,
  getMarketSurvey,
} from '../../services/market-survey';
import { updateRating } from '../../_services/rating-service';
import LoadingModal from './LoadingModal';
import classNames from 'classnames';
import ahoy from 'ahoy.js';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { othersSliceActions } from '../../store/slices/others';

const SwitchStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-lg sm:ml-3 bg-orange-600 text-white">
          Quick check
        </span>
      );
    case 2:
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-sm sm:ml-3 bg-blue-400 text-white">
          Éval Détaillée
        </span>
      );
    case 3:
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-sm sm:ml-3 bg-green-400 text-white">
          Étude
        </span>
      );
    case 4:
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-sm sm:ml-3 bg-red-400 text-white">
          Abandonné
        </span>
      );
    default:
      return null;
  }
};

const QuickCheckModal = () => {
  const {
    isQuickCheckModalOpened,
    setIsQuickCheckModalOpened,
    currentRatingID,
    setCurrentRatingID,
    ratingIds,
  } = useModal();
  const {
    selectedParcelsIds,
    mainParcelID,
    setSelectedParcelsIds,
    setMainParcelID,
    setFieldIds,
  } = useSelectedParcels();
  const history = useHistory();
  let location = useLocation();

  const [epciName, setEpciName] = useState('');
  const [currentRatingIndex, setCurrentRatingIndex] = useState(0);
  const [status, setStatus] = useState(null);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  const { hasAccessAllStudies } = useSelector((state) => state.others);

  const handleAccessToMarketSurvey = (market_survey_id = null) => {
    !!market_survey_id
      ? getMarketSurvey(market_survey_id, (ms) => {
          setIsQuickCheckModalOpened(false);
          window.location.assign(`/market-survey/${ms.id}`);
        })
      : updateRating(currentRatingID, {
          land: { status: 3, parcels_ids: selectedParcelsIds },
        }).then(() => {
          createMarketSurvey(
            { rating_id: currentRatingID, comments: {} },
            (id) => {
              setIsQuickCheckModalOpened(false);
              ahoy.track('$lancement_rapport_etude_depuis_qc', {
                market_survey_id: id,
              });
              window.location.assign(`/market-survey/${id}`);
            }
          );
        });
  };

  const handleEvaluateLater = (market_survey_id) => {
    setIsQuickCheckModalOpened(false);
    updateRating(currentRatingID, {
      land: {
        parcels_ids: selectedParcelsIds,
        pivot_parcelle_id: mainParcelID,
      },
    }).then(() => {
      if (
        market_survey_id &&
        location.pathname !== `/market-survey/${market_survey_id}/detail`
      ) {
        history.push(`/market-survey/${market_survey_id}`);
        return false;
      }
      return false;
    });
  };

  const previousRating = () => {
    setCurrentRatingIndex(currentRatingIndex - 1);
    setSelectedParcelsIds([]);
  };

  const nextRating = () => {
    setCurrentRatingIndex(currentRatingIndex + 1);
    setSelectedParcelsIds([]);
  };

  useEffect(() => {
    if (!isEmpty(ratingIds)) {
      setCurrentRatingID(ratingIds[currentRatingIndex]);
    }
  }, [currentRatingIndex]);

  const giveUpRating = () => {
    Api.get(`/ratings/stop_rating/${currentRatingID}.json`).then(() => {
      setIsQuickCheckModalOpened(false);
    });
  };

  const getMainParcelInfo = (id) => {
    return Api.get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${id}`
    ).then((res) => res.data);
  };

  const restoreRating = () => {
    Api.put(`/ratings/${currentRatingID}/restore`).then((res) => {
      setStatus(res.data.data.attributes.status);

      if (res.data.data.attributes.status === 1) {
        setIsQuickCheckModalOpened(true);
      } else {
        setIsQuickCheckModalOpened(false);
      }
    });
  };

  useLayoutEffect(() => {
    dispatch(othersSliceActions.setMaxErrorNb(5));
  }, []);

  useEffect(() => {
    const getCurrentRating = async () => {
      const { data } = await Api.get(`/ratings/${currentRatingID}`);
      setMainParcelID(data.pivot_parcel_id);
      const mainParcelId = data.pivot_parcel_id;
      if (mainParcelId.length) {
        getMainParcelInfo(mainParcelId).then(({ data }) => {
          if (data) {
            setEpciName(data.attributes.epci_name);
          }
        });
      }
      setData(data);
      console.log(data);
      setSelectedParcelsIds(data.targets_parcels || []);
      setFieldIds(data.targets_parcels || []);
      setStatus(data.status);
    };

    currentRatingID && getCurrentRating();
    return () => {
      setSelectedParcelsIds([]);
    };
  }, [currentRatingID]);

  if (!data) {
    return <LoadingModal />;
  }

  const {
    address,
    plu,
    town,
    // pdf_url,
    // targets_parcels,
    // uuid,
    market_survey_id,
  } = data;

  const BottomBtn = () => {
    return (
      <>
        {status !== 4 && (
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md hover:bg-gray-500 shadow-sm px-4 py-2 text-xs font-medium text-gray-900 sm:mt-0 sm:col-start-1 sm:text-xs"
            onClick={() => giveUpRating(currentRatingID)}
          >
            Abandonner l'étude
          </button>
        )}
        <>
          {hasAccessAllStudies ? (
            <>
              {status !== 4 && (
                <button
                  type="button"
                  disabled={selectedParcelsIds.length === 0}
                  className={classNames(
                    {
                      'bg-blue-100 text-blue-400 cursor-not-allowed':
                        selectedParcelsIds.length === 0,
                      'bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500':
                        selectedParcelsIds.length !== 0,
                    },
                    'mt-3 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2  text-xs font-medium text-gray-100 sm:mt-0 sm:col-start-1 sm:text-xs'
                  )}
                  onClick={() => handleEvaluateLater(market_survey_id)}
                >
                  {status === 3 ? "Retourner à l'Étude" : 'Étudier plus tard'}
                </button>
              )}
              {status !== 2 && status !== 3 && status !== 4 && (
                <button
                  type="button"
                  disabled={selectedParcelsIds.length === 0}
                  onClick={() => handleAccessToMarketSurvey(market_survey_id)}
                  className={classNames(
                    {
                      'bg-blue-100 text-blue-400 cursor-not-allowed':
                        selectedParcelsIds.length === 0,
                      'bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500':
                        selectedParcelsIds.length !== 0,
                    },
                    'mt-3 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2  text-xs font-medium text-gray-100 sm:mt-0 sm:col-start-1 sm:text-xs'
                  )}
                >
                  Lancer l'Étude
                </button>
              )}
              {status === 4 && (
                <button
                  type="button"
                  className="mt-3 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-400 text-xs font-medium text-gray-100 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-xs"
                  onClick={() => restoreRating()}
                >
                  Relancer l'étude
                </button>
              )}
            </>
          ) : (
            <Link
              onClick={(e) => {
                e.preventDefault();
                setIsQuickCheckModalOpened(false);
                history.push('/demande-rendez-vous');
              }}
              className="mt-3 w-full whitespace-nowrap inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-400 text-xs font-medium text-gray-100 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-xs"
            >
              Lancer l'etude
            </Link>
          )}
        </>
      </>
    );
  };

  return (
    <Transition.Root show={isQuickCheckModalOpened} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0"
        onClose={setIsQuickCheckModalOpened}
        style={{ zIndex: 1001 }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block relative qk-modal bg-white rounded-lg overscroll-hidden shadow-xl  transform transition-all align-middle sm:max-w-6xl sm:w-full sm:p-6">
              {ratingIds.length >= 2 && currentRatingIndex !== 0 && (
                <div
                  style={{ zIndex: 2000, top: '50%', left: '-0.5rem' }}
                  className="block absolute"
                >
                  <button
                    type="button"
                    className="bg-gray-300 rounded-full p-4 text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
                    onClick={() => previousRating()}
                  >
                    <span className="sr-only">Previous rating</span>
                    <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
                  </button>
                </div>
              )}
              {ratingIds.length >= 2 &&
                currentRatingIndex < ratingIds.length - 1 && (
                  <div
                    style={{ zIndex: 2000, top: '50%', right: '-0.5rem' }}
                    className="block absolute"
                  >
                    <button
                      type="button"
                      className="bg-gray-300 rounded-full p-4 text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
                      onClick={() => nextRating()}
                    >
                      <span className="sr-only">Next rating</span>
                      <ChevronRightIcon
                        className="h-8 w-8"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                )}
              <div
                style={{ zIndex: 2000 }}
                className="hidden sm:block absolute top-0 right-0 pt-4 pr-4"
              >
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setIsQuickCheckModalOpened(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex h-full flex-col-reverse md:grid grid-cols-3 gap-x-4 overflow-hidden pb-20 md:pb-0">
                {/* content */}
                <div className="col-span-2 flex-grow  overflow-auto  flex flex-col mt-4 md:mt-0 px-2 md:px-0">
                  <div className={'sticky top-0 bg-white z-50'}>
                    <div className={'flex flex-row justify-between'}>
                      <Dialog.Title
                        as="h3"
                        className="hidden md:block text-lg leading-6 font-medium text-gray-900"
                      >
                        Quick check
                      </Dialog.Title>
                      <div className="text-sm md:text-base">
                        Statut : {SwitchStatus(status)}
                      </div>
                    </div>
                    <div className={'flex flex-row justify-between'}>
                      <div className="flex flex-col text-left">
                        <div className={'flex flex-row sm:flex-col'}>
                          <span className="text-sm md:text-base leading-4">
                            {data.address}
                          </span>
                          {data.postcode && data.town && (
                            <span className="leading-none text-sm md:text-base sm:leading-4 ml-2 sm:ml-0">{`${
                              data.postcode || ''
                            } ${data.town}`}</span>
                          )}
                        </div>
                        <span className={'text-xs font-semibold'}>
                          EPCI : {epciName}
                        </span>
                      </div>
                      <div className="hidden md:flex items-center">
                        <i className="mr-2 text-gray-800 text-base cursor-pointer ri-information-line ml-1" />
                        <p className="text-xs flex items-center">
                          Ajouter des parcelles
                          <img
                            className="ml-2"
                            src={IconAdd}
                            width={20}
                            height={27.5}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 flex-grow h-0 overflow-auto">
                    <SelectedParcelsContainer />
                    <AxesContainer is_quick_check parcel_id={mainParcelID} />
                  </div>
                  <div className="hidden md:flex flex-row gap-3 bg-white pt-6">
                    <BottomBtn />
                  </div>
                </div>

                {/* map */}
                <div className="col-span-1 flex flex-col md:grid grid-cols-1 gap-y-4">
                  <div className="col-span-1 row-span-1 h-40 md:h-auto">
                    <MapQuickCheck />
                  </div>
                  <div className="col-span-1 row-span-1 h-40 md:h-auto">
                    <StreetViewPlayer address={`${address} ${town}`} />
                  </div>
                </div>
              </div>
              <div className="btn-bottom-qk flex gap-x-1 md:hidden bottom-0 left-0 absolute h-20 bg-white p-2">
                <BottomBtn />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default QuickCheckModal;
