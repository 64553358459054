import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import inArray from '../../../_helpers/inArray';
import PopupContentCommune from '../popup-content-commune/PopupContentCommune';
import GeoJsons from './GeoJsons';

export default function CommunesGeoJson({ onShowPopup }) {
  const {
    communes,
    valueTerritoire: { selectedCommunes },
    returnSearchCommunesBy,
  } = useSelector((state) => state.ciblageCreate);

  const handleOnEachlayer = useCallback(
    (layer, feature) => {
      layer.on('click', (e) => {
        onShowPopup &&
          onShowPopup(e, <PopupContentCommune feature={feature} />);
      });
    },
    [onShowPopup]
  );

  return (
    <GeoJsons
      layers={returnSearchCommunesBy ? [] : communes}
      selectedLayers={(feature) => inArray(selectedCommunes, feature.id, 'id')}
      onEachlayer={handleOnEachlayer}
    />
  );
}
