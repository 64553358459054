import React, { useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';
import Api from '../../../api/Api';
import useSWR from 'swr';
import { useSelectedParcels } from '../../../hooks/useSelectedParcels';
import _ from 'lodash';
import { useDataVisualisation } from '../../../hooks/useDataVisualisation';
import ahoy from 'ahoy.js';
import classNames from 'classnames';
import { useModal } from '../../../hooks/useModal';

import LoadingBox from '../../utils/LoadingBoxSimple';

ahoy.configure({
  visitsUrl: '/steps/visits',
  eventsUrl: '/steps/events',
});

const fetcher = (url) =>
  Api.get(url).then((res) => {
    switch (res.status) {
      case 404:
        alert(`Error in URL ${url}`);
        break;
      case 200:
        return res.data;
    }
  });

const BoxSimple3 = ({ url, print_mode = false, isEDM = false, data }) => {
  const { isQuickCheckModalOpened } = useModal();

  const { selectedParcelsIds } = useSelectedParcels();
  const { urlMap, setUrlMap, setTabIndexSelected } = useDataVisualisation();

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!isEDM)
      setQuery(
        `${url}?parcelle_ids[]=${_.join(
          selectedParcelsIds,
          '&parcelle_ids[]='
        )}`
      );
  }, [selectedParcelsIds]);

  const style = {
    // width: '210px',
    height: `${print_mode ? '110px' : '120px'}`,
  };

  const resSWR =
    !isEDM &&
    useSWR(() => query, fetcher, {
      revalidateIfStale: true,
    });

  const _data = isEDM ? data : resSWR?.data;

  if (resSWR?.error) {
    return <div>Loading failed...</div>;
  }

  if (!_data) {
    return <LoadingBox printMode={print_mode} boxSize={'simple'} />;
  }

  const {
    bas_de_box,
    information,
    titre_box,
    metric_commune,
    metric,
    carto_link,
    // year,
  } = _data;

  return (
    <div
      style={style}
      className={classNames(
        'flex flex-col py-3 px-4 bg-white shadow rounded-lg overflow-hidden sm:p-2 sm:px-3 justify-between border-gray-200 border-2',
        {
          'inline-block mx-1 py-1': print_mode,
          'hover:bg-blue-400 cursor-pointer':
            !!carto_link && !isQuickCheckModalOpened,
          'bg-blue-600': carto_link === urlMap,
        }
      )}
      onClick={(event) => {
        event.preventDefault();
        if (!isQuickCheckModalOpened) {
          ahoy.track('$click', {
            titre_box: titre_box,
            type_box: 'BoxSimple3',
          });
          carto_link && setUrlMap(carto_link);
          carto_link && setTabIndexSelected(0);
        }

        return;
      }}
    >
      <div className={'flex flex-row  justify-between'}>
        <dt className="text-xs font-medium text-orange-600">{titre_box}</dt>
        {!print_mode && (
          <dt
            className="text-base font-medium text-gray-600"
            data-for="tooltip-global"
            data-tip={information}
            onMouseEnter={() => {
              ReactTooltip.rebuild();
            }}
          >
            <InformationCircleIcon className={'w-5'} />
          </dt>
        )}
      </div>
      <div className={'flex flex-row justify-between'}>
        <div className="">
          <dd
            className={`${
              print_mode ? 'text-sm' : 'text-lg'
            } font-semibold text-gray-900`}
          >
            {metric_commune || metric}
          </dd>
        </div>
      </div>
      <dt className={`text-xs font-medium text-gray-500`}>{bas_de_box}</dt>
    </div>
  );
};

export default BoxSimple3;
