import React, { forwardRef } from 'react';
import { Popup } from 'react-leaflet';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { XIcon } from '@heroicons/react/outline';
import { closePopup } from '../../../../../_helpers/_map-helper';
// import { getCoordFromUrlStreet } from '../../../../MarketSurveys/sections/TableProgrammeDetails';

const StyledPop = styled(Popup)`
  background-color: white;
  border-radius: 0;

  height: auto;
  border-radius: 10px;

  .leaflet-popup-content {
    min-width: 600px !important;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 20px;
    height: auto;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;

const BuildingPermitPopupReadOnly = forwardRef(
  (
    {
      date_obtention,
      deposant,
      nb_lgt_tot_crees,
      nb_studios_et_t1,
      nombre_t2,
      nombre_t3,
      nombre_t4,
      nombre_t5,
      numero_pc,
      unit,
      // etat_dau,
      // google_street_view_url,
      coord,
      onClose,
    },
    ref
  ) => {
    return (
      <StyledPop onClose={onClose} id={`popup-program-${numero_pc}`}>
        <div className={'absolute right-0 top-0'}>
          <button onClick={() => closePopup(ref)} className={'p-2'}>
            <XIcon className={'w-5 h-5 text-blue-800 hover:text-blue-900'} />
          </button>
        </div>
        <div className="flex gap-x-4 pt-4">
          <div className="w-1/2">
            <h3 className={'text-sm font-bold'}>
              Numéro du permis : {numero_pc}
            </h3>
            <h4 className={'text-sm font-medium'}>
              Promoteur : {deposant || 'N.C'}
            </h4>
            <h4 className={'text-sm font-medium'}>
              Date d'obtention :{' '}
              {DateTime.fromISO(date_obtention).toFormat('MM/yyyy')}
            </h4>
            <ul>
              <li className={'text-sm font-medium'}>
                Nombre de logements crées: {nb_lgt_tot_crees} {unit}
              </li>

              <li className={'text-sm font-medium'}>
                Nombre de T1: {nb_studios_et_t1}
              </li>
              <li className={'text-sm font-medium'}>
                Nombre de T2: {nombre_t2}
              </li>
              <li className={'text-sm font-medium'}>
                Nombre de T3: {nombre_t3}
              </li>
              <li className={'text-sm font-medium'}>
                Nombre de T4: {nombre_t4}
              </li>
              <li className={'text-sm font-medium'}>
                Nombre de T5: {nombre_t5}
              </li>
            </ul>
          </div>
          <div className="w-1/2">
            {coord && (
              <iframe
                width="100%"
                height="250px"
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${coord.join(
                  ','
                )}`}
              />
            )}
          </div>
        </div>
      </StyledPop>
    );
  }
);

export default BuildingPermitPopupReadOnly;
