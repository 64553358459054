import React from 'react';
import messageIcon from '../../../../assets/images/message.png';

const SuccessMessageRegistration = ({ messageType, messages }) => {
  return (
    <div className="px-6 flex justify-center items-center flex-col">
      <h1 className="text-xl font-semibold text-center">
        Votre compte EvalparceL
      </h1>
      <p className="text-center mt-8">{messages[messageType]}</p>
      <hr className="mt-8 w-1/2 block" />
      <div className="text-center mt-8">
        <p>
          <strong>Nous vous avons envoyé par email un lien</strong>
          <br />
          permettant d'activer votre compte !
        </p>
        <p className="mt-6">
          (Pensez à regarder dans vos courriers indésirables ) <br/>Ce lien est
          valable 48 H
        </p>
      </div>
      <hr className="mt-8 w-1/2" />
      <div className="mt-8">
        <p className="mt-6 text-center">
          Pour toutes questions vous pouvez nous joindre directement:
        </p>
        <div className="flex items-center justify-center">
          <img className="w-8 h-auto" src={messageIcon} alt="message" />
          <p>Chat en bas à droite de votre écran</p>
        </div>
        <p className="mt-6 text-center">
          ou par email:
          <br />
          <a
            href="mailto:support@evalparcel.com"
            className="hover:underline text-blue-500"
          >
            support@evalparcel.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default SuccessMessageRegistration;
