import L from 'leaflet';
import { BUS, GARE, METRO, POST_OFFICE, TRAIN } from '../../_helpers/constant';
import restoImg from '../../../../assets/images/icons/poi/resto.png';
import coiffureImg from '../../../../assets/images/icons/poi/coiffure.png';
import crecheImg from '../../../../assets/images/icons/poi/creche.png';
import doctorImg from '../../../../assets/images/icons/poi/doctor.png';
import FastFoodImg from '../../../../assets/images/icons/poi/fast_food.png';
import pharmacieImg from '../../../../assets/images/icons/poi/pharmacie.png';
import boulangerieImg from '../../../../assets/images/icons/poi/boulangerie.png';
import supermarketImg from '../../../../assets/images/icons/poi/supermarket.png';
import sportImg from '../../../../assets/images/icons/poi/sport.png';

// new
import primaryIcon from '../../../../assets/images/icons/poi/primaire.png';
import collegeIcon from '../../../../assets/images/icons/poi/college.png';
import lycee from '../../../../assets/images/icons/poi/high_school.svg';
import universityIcon from '../../../../assets/images/icons/poi/university.svg';
import marieIcon from '../../../../assets/images/icons/poi/mairie.svg';
import airport from '../../../../assets/images/icons/poi/aeroport.svg';
import trainNationalIcon from '../../../../assets/images/icons/poi/gare_sncf_nationale.svg';
import metroICon from '../../../../assets/images/icons/poi/metro_tram.svg';
import trainIcon from '../../../../assets/images/icons/poi/gare_sncf.svg';
import busIcon from '../../../../assets/images/icons/poi/bus.png';
import pharmacieIcon from '../../../../assets/images/icons/poi/pharmacie.png';
import docteurIcon from '../../../../assets/images/icons/poi/docteur.svg';
import boulangerieIcon from '../../../../assets/images/icons/poi/boulangerie.svg';
import supermarcheIcon from '../../../../assets/images/icons/poi/supermarche.svg';
import fastfoodIcon from '../../../../assets/images/icons/poi/fast_food.svg';
import restoIcon from '../../../../assets/images/icons/poi/restaurant.svg';
import crecheIcon from '../../../../assets/images/icons/poi/creche.svg';
import coiffureIcon from '../../../../assets/images/icons/poi/coiffeur.svg';
import sportIcon from '../../../../assets/images/icons/poi/salle_de_sport.png';
import post_office from '../../../../assets/images/icons/poi/post_office.svg'

L.Marker.prototype.options.icon = DefaultIcon;

export const DefaultIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/terrain.svg'),
  iconSize: [35, 42],
  iconAnchor: [17, 45],
  popupAnchor: [0, -40],
});
export const LandIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/terrain.png'),
  iconSize: [35, 42],
  iconAnchor: [17, 45],
  popupAnchor: [0, -40],
});

export const gareNational = new L.icon({
  iconUrl: require('../../../../assets/images/icons/gare_tgv_national.png'),
  iconSize: [35, 42],
  iconAnchor: [17, 45],
  popupAnchor: [0, -40],
});

export const ConstructionIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/construire.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const RiskIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/risque.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const EolienneIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/eolienne.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const TownHallIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/mairie-de-la-commune.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const AirportIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/aeroport.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const PrimarySchoolIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/ecole-primaire-privee.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const MiddleSchoolIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/college.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const UniversityIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/university.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const PrimaryPublicSchoolIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/ecole-primaire-publique.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const MiddlePublicSchoolIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/college_public.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const HighPublicSchoolIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/lycee_public.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const GareTGVIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/gare-tgv.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const HospitalIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/hopital.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const SportIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/equipements-et-service-sport.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const PermisConstruireIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/permis-de-construire.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const ProgrammeIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/programme-immobilier-neuf.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const MonumentHistoriqueIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/monuments-historiques.svg'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const BusIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/bus.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});
export const PostIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/post_office.svg'),
  iconAnchor: [18, 43],
  iconSize: [40, 40],
  popupAnchor: [0, -40],
});

export const MetroIcon = new L.icon({
  iconUrl: require('../../../../assets/images/icons/metro.svg'),
  iconAnchor: [18, 43],
  iconSize: [40, 40],
  popupAnchor: [0, -40],
});

// new
export const Resto = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/resto.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const Coiffure = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/coiffure.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const Boulangerie = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/boulangerie.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const Pharmacie = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/pharmacie.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const Docteur = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/doctor.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const SalleSport = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/sport.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const SuperMarket = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/supermarket.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const FastFood = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/fast_food.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const Creche = new L.icon({
  iconUrl: require('../../../../assets/images/icons/poi/creche.png'),
  iconAnchor: [15, 43],
  iconSize: [30, 40],
  popupAnchor: [0, -40],
});

export const limeOptions = { color: '#16c894' };

export const fillBlueOptions = { fillColor: 'blue' };

export const poiIconsObject = {
  pharmacie: Pharmacie,
  boulangerie: Boulangerie,
  supermarket: SuperMarket,
  fast_food: FastFood,
  resto: Resto,
  creche: Creche,
  salle_sport: SalleSport,
  docteur: Docteur,
  coiffure: Coiffure,
};

export const poiIconsImg = {
  pharmacie: pharmacieIcon,
  boulangerie: boulangerieImg,
  supermarket: supermarketImg,
  fast_food: FastFoodImg,
  resto: restoImg,
  creche: crecheImg,
  salle_sport: sportImg,
  docteur: doctorImg,
  coiffure: coiffureImg,
};

export function poiIcons(iconID) {
  switch (iconID) {
    case 'gare':
      return GareTGVIcon;
    case 'mairie':
      return TownHallIcon;
    case 'airport':
      return AirportIcon;
    case 'home':
      return LandIcon;
    case 'primary_private_school':
      return PrimarySchoolIcon;
    case 'middle_private_school':
      return PrimarySchoolIcon;
    case 'high_private_school':
      return PrimarySchoolIcon;
    case 'primary_public_school':
      return PrimaryPublicSchoolIcon;
    case 'middle_public_school':
      return PrimaryPublicSchoolIcon;
    case 'high_public_school':
      return PrimaryPublicSchoolIcon;
    case 'university':
      return UniversityIcon;
    case 'hospital':
      return HospitalIcon;
    case 'sport':
      return SportIcon;
    case 'permis_de_construire':
      return PermisConstruireIcon;
    case 'monuments_historiques':
      return MonumentHistoriqueIcon;
    case 'programmes_neufs':
      return ProgrammeIcon;
    case 'education':
      return PrimarySchoolIcon;
    case 'train_national':
      return gareNational;
    case METRO:
      return MetroIcon;
    case GARE:
      return GareTGVIcon;
    case TRAIN:
      return GareTGVIcon;
    case BUS:
      return BusIcon;
    case POST_OFFICE:
      return PostIcon;
    default:
      return DefaultIcon;
  }
}

export const poiEquipementIcons = {
  // education
  primary_private_school: {
    color: 'green',
    url: primaryIcon,
  },
  middle_private_school: {
    color: 'green',
    url: collegeIcon,
  },
  high_private_school: {
    color: 'green',
    url: lycee,
  },
  primary_public_school: {
    color: 'blue',
    url: primaryIcon,
  },
  middle_public_school: {
    color: 'blue',
    url: collegeIcon,
  },
  high_public_school: {
    color: 'blue',
    url: lycee,
  },
  university: {
    color: '#000000',
    url: universityIcon,
  },

  // office
  mairie: {
    color: '#000000',
    url: marieIcon,
  },
  post_office: {
    color: '#000000',
    url: post_office,
  },

  // transport
  airport: {
    url: airport,
    color: '#006400',
  },
  train_national: {
    url: trainNationalIcon,
    color: '#000080',
  },
  metro: {
    url: metroICon,
    color: '#FFA500',
  },
  gare: {
    url: trainIcon,
    color: '#00BFFF',
  },
  train: {
    url: trainIcon,
    color: '#00BFFF',
  },
  bus: {
    url: busIcon,
    color: 'white',
  },

  // santé
  pharmacie: {
    url: pharmacieIcon,
    color: '#006400',
  },
  docteur: {
    url: docteurIcon,
    color: '#006400',
  },

  // commerce
  boulangerie: {
    url: boulangerieIcon,
    color: '#000080',
  },
  supermarket: {
    url: supermarcheIcon,
    color: '#000080',
  },
  fast_food: {
    url: fastfoodIcon,
    color: '#000080',
  },
  resto: {
    url: restoIcon,
    color: '#000080',
  },

  // services
  creche: {
    url: crecheIcon,
    color: '#000080',
  },
  coiffure: {
    url: coiffureIcon,
    color: '#000080',
  },

  // sport and culture
  salle_sport: {
    url: sportIcon,
    color: '#FFA500',
  },
};
