import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUserLoggedIn } from '../_services/user-services';
import { useAuth } from '../hooks/useAuth';
import LoadingSection from './MarketSurveys/sections/LoadingSection';
import { Error } from './utils/Error';
import Api from '../api/Api';
import { CheckIcon } from '@heroicons/react/outline';
import UpdatePasswordModal from './modals/UpdatePasswordModal';
import * as yup from 'yup';
import { Formik } from 'formik';
import classNames from 'classnames';

const formUserSettingSchema = yup.object({
  first_name: yup.string().required('Champ obligatoire.'),
  last_name: yup.string().required('Champ obligatoire.'),
  phone_number: yup.string().required('Champ obligatoire.'),
});

const UserSettings = () => {
  let { checkSession, sessionAuthHeader } = useAuth();
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user, isLoading, isError } = useUserLoggedIn(sessionAuthHeader);

  const closeModalPasswordUpdate = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openModalPasswordUpdate = () => {
    setIsOpen(true);
  };

  const handleSubmitFormik = useCallback((values) => {
    Api.put('api/v1/me', values).then((res) => {
      if (res.status === 200) {
        setIsUpdated(true);
      } else {
        console.error(
          'Une erreur est survenue lors de la mise à jour du profil'
        );
      }
    });
  }, []);

  const initialValues = useMemo(
    () =>
      user
        ? {
            first_name: user.first_name,
            last_name: user.last_name,
            phone_number: user.phone_number,
          }
        : null,
    [user]
  );

  useEffect(() => {
    checkSession();
    const funcExec = () => {
      setOffset(
        document.getElementById('navbar')?.offsetHeight +
          document.getElementById('bottom-navbar')?.offsetHeight
      );
    };
    funcExec();
    window.addEventListener('resize', funcExec);
    return () => {
      window.removeEventListener('resize', funcExec);
    };
  }, []);

  if (isLoading && !initialValues) return <LoadingSection />;
  if (isError) return <Error error={isError} />;

  return (
    <div
      className="relative z-10 w-full"
      style={{ paddingTop: `${offset}px`, height: `calc(100vh - ${offset}px)` }}
    >
      <main className="relative">
        <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <Formik
                initialValues={initialValues}
                validationSchema={formUserSettingSchema}
                onSubmit={handleSubmitFormik}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="divide-y divide-gray-200 lg:col-span-9"
                  >
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                      <div>
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                          Mon compte
                        </h2>
                      </div>
                      <div className="mt-6 grid grid-cols-12 gap-6">
                        <div className="col-span-12 sm:col-span-6">
                          <div className="flex flex-col">
                            <label
                              htmlFor="first_name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Prénom*
                            </label>
                            <input
                              id="first_name"
                              name="first_name"
                              disabled={isUpdated}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.first_name}
                              className="mt-1 block w-full border-1 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                            {errors.first_name && touched.first_name && (
                              <span className="text-red-500 text-xxs">
                                {errors.first_name}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                          <div className="flex flex-col">
                            <label
                              htmlFor="last_name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Nom*
                            </label>
                            <input
                              id="last_name"
                              name="last_name"
                              disabled={isUpdated}
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="mt-1 block w-full border-1 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                            {errors.last_name && touched.last_name && (
                              <span className="text-red-500 text-xxs">
                                {errors.last_name}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                          <label
                            htmlFor="url"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            value={user.email}
                            disabled={true}
                            className="mt-1 opacity-50 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm cursor-not-allowed"
                          />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                          <div className="flex flex-col">
                            <label
                              htmlFor="phone_number"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Numéro de téléphone
                            </label>
                            <input
                              id="phone_number"
                              name="phone_number"
                              disabled={isUpdated}
                              value={values.phone_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="mt-1 block w-full border-1 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                            {errors.phone_number && touched.phone_number && (
                              <span className="text-red-500 text-xxs">
                                {errors.phone_number}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="inset-0 flex items-center justify-end col-span-9 space-x-2.5 mt-10">
                        <div className="inset-0 flex items-center justify-start col-span-9 mt-2">
                          <span
                            onClick={openModalPasswordUpdate}
                            className="cursor-pointer focus:outline-none rounded-md bg-white border-1 border-blue-500 bg-opacity-20 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-500 hover:text-blue-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                          >
                            Mettre à jour votre mot de passe
                          </span>
                        </div>
                        {!isUpdated && (
                          <div
                            className={classNames('self-end', {
                              'opacity-50 cursor-not-allowed': !isValid,
                            })}
                          >
                            <button
                              type="submit"
                              disabled={!isValid || isSubmitting}
                              className="rounded-md  bg-blue-500 py-2 px-4 text-blue-100"
                            >
                              Valider
                            </button>
                          </div>
                        )}
                        {isUpdated && (
                          <div>
                            <span className="text-sm inline-flex flex-row items-center gap-x-2 text-green-500">
                              <CheckIcon className="w-10 h-10 text-green-500" />
                              Vos informations ont bien été modifiées
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </main>
      <UpdatePasswordModal
        isOpen={isOpen}
        closeModalPasswordUpdate={closeModalPasswordUpdate}
      />
    </div>
  );
};

export default UserSettings;
