import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import Loading from './Loading';
import { Error } from './Error';
import Api from '../../api/Api';
import {
  deserializeAttributesForMSGiven,
  getAllIncludedResourcesForAGivenType,
} from '../../_helpers/jsonapi-deserializer';
import { useAuth } from '../../hooks/useAuth';
import EpciName from './EpciName';
import MenuMS from './MenuMS';
import { linksRefs } from '../MarketSurveyPage';

export function SectionHeaderTitle({ title, survey_id, refs, displayMenu }) {
  const [relatedRating, setRelatedRating] = useState(null);

  let { sessionAuthHeader } = useAuth();
  const fetcher = (url) =>
    Api.get(url, {
      headers: {
        'X-USER-EMAIL': sessionAuthHeader?.email,
        'X-USER-TOKEN': sessionAuthHeader?.token,
      },
    }).then((res) => res.data);

  const { data, error } = useSWR(`api/v1/market_surveys/${survey_id}`, fetcher);

  useEffect(() => {
    if (data) {
      const { related_rating } = !data.related_rating
        ? deserializeAttributesForMSGiven(data)
        : data;
      setRelatedRating(related_rating);
    }
  }, [data]);
  if (error) return <Error error={error} />;
  if (!data) return <Loading />;

  if (relatedRating) {
    return (
      <>
        <div className="max-w-xl flex flex-row justify-center items-center print:hidden">
          {!displayMenu && <MenuMS refs={refs} />}
          <div className={'ml-2 xl:ml-3'}>
            <h2 className="text-sm font-extrabold text-gray-900 sm:tracking-tight xl:text-lg">
              {relatedRating.title}
            </h2>
            <p className="text-base text-blue-900">{`${relatedRating.address} ${relatedRating.postcode} ${relatedRating.town}`}</p>
            <EpciName pivot_parcelle_id={relatedRating.pivot_parcelle_id} />
          </div>
        </div>
      </>
    );
  }

  return null;
}
