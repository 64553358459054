import React, {useEffect} from "react";
import useSWR from "swr";
import axios from "axios";
import {usePrograms} from "../../hooks/usePrograms";
import {transformCoordinates} from "../../_helpers/coordinate";

const StreetViewPlayer = ({address}) => {

    const fetcher = url => axios.get(url).then(res => res.data.features[0].geometry.coordinates)
    const {data: coordinates, error} = useSWR(`https://api-adresse.data.gouv.fr/search/?q=${address}`, fetcher)
    const {setCurrentCenterCoordinates} = usePrograms()

    // useEffect(() => {
    //     if (coordinates) {
    //         // setCurrentCenterCoordinates(transformCoordinates(coordinates))
    //     }
    // }, [coordinates])

    if (!coordinates) {
        return <div>Loading Coordinates ... </div>
    }
    if (error) {
        return <div>Loading failed ... </div>
    }


    return (
        <iframe
            width="100%"
            height="100%"
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${coordinates[1]},${coordinates[0]}`}
        />
    )
}


export default StreetViewPlayer