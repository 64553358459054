export const IS_LOADING = 'parcelApi/IS_LOADING';
export const ISNT_LOADING = 'parcelApi/ISNT_LOADING';

export const isLoading = () => async (dispatch) => {
    return dispatch({
        type: IS_LOADING,
    });
};

export const isntLoading = () => async (dispatch) => {
    return dispatch({
        type: ISNT_LOADING,
    });
};

const initialState = {
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                loading: true,
            };
        case ISNT_LOADING:
            return {
                loading: false,
            };

        default:
            return state;
    }
};
