import React, {useEffect} from "react";
import {useParams, withRouter} from "react-router-dom";
import Api from "../../../api/Api";
import useSWR from "swr";
import Logo from '../../../../../assets/images/logo.svg'
import {useDataVisualisation} from "../../../hooks/useDataVisualisation";
import ConceptNoteSection from "./components/ConceptNoteSection";
import ConceptNoteBoxes from "./components/ConceptNoteBoxes";
import ConceptNoteCommentSection from "./components/ConceptNoteCommentSection";
import ConceptNoteSectionContainer from "./components/ConceptNoteSectionContainer";
import ConceptNoteMap from "./visualisations/maps/ConceptNoteMap";
import _ from "lodash";
import {useSelectedParcels} from "../../../hooks/useSelectedParcels";
import {pluralize} from "../../../_helpers/wording";

const {DateTime} = require("luxon");


// address: "Rue Kageneck 67000 Strasbourg"
// created_at: "2021-11-13T18:55:57.375Z"
// evaluate_date: "2021-11-13T18:55:57.289Z"
// id: 101
// land_id: 91
// parcel_size: 1028
// pivot_parcelle_id: "67173000190042"
// plu: "Zone destinée à la préservation des terres agricoles dont la constructibilité est très limitée"
// quick_check_uuid: "3bac4e97-761a-4980-945e-459eae741da0"
// town: "Strasbourg"
// updated_at: "2021-11-13T18:55:57.375Z"
// user_id: 3


const ConceptNote = () => {
    const {uuid} = useParams()
    const {setUrlMap} = useDataVisualisation()
    const {setFieldIds, fieldIds} = useSelectedParcels()

    const fetcher = url => Api.get(url).then(res => res.data)
    const {data, error} = useSWR(`/ratings/quick_check/${uuid}/pdf.json`, fetcher)

    const {
        data: parcelsData,
        error: parcels_error
    } = useSWR(() => `${process.env.BASE_URL_API}/v4/eval_parcel/quick_check_pdf/data_intro?parcelle_ids[]=${_.join(_.map(data.targets_parcels, (target_parcel) => {
        return target_parcel.parcel_id
    }), "&parcelle_ids[]=")}`, fetcher)

    const {
        data: timeData,
        error: timeError
    } = useSWR(() => `${process.env.BASE_URL_API}/v4/eval_parcel/quick_check_pdf/date_donnees?parcelle_ids[]=${_.join(_.map(data.targets_parcels, (target_parcel) => {
        return target_parcel.parcel_id
    }), "&parcelle_ids[]=")}`, fetcher)

    useEffect(() => {
        if (data && data.pivot_parcelle_id) {
            setUrlMap(`${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/carto?parcelle_ids[]=` + data.pivot_parcelle_id)
            setFieldIds(_.reduce(data.targets_parcels, (result, parcel) => {
                return _.concat(result, parcel.parcel_id)
            }, []))
        }

    }, [data])

    if (!data || !parcelsData || !timeData) {
        return <div>Loading...</div>
    }

    if (error || parcels_error || timeError) {
        return <div>Loading failed...</div>
    }
    const report_date = DateTime.now().setLocale('fr').toLocaleString(DateTime.DATE_FULL)
    const {date} = timeData

    const {
        surface,
        zonage_plu,
        proprietaire,
        zone_pinel,
        epci_name,
        maire,
        charte_territoriale,
        limitation_loyer
    } = parcelsData


    const {town, address, pivot_parcelle_id, plu, postcode, targets_parcels} = data


    return (
        <div className={'m-4 text-xs f-none'} style={{width: '18cm'}}>
            <div className={''}>
                <div className={'flex flex-row items-center'}>
                    <div className={'text-white w-4/5 flex flex-col justify-center px-2'}
                         style={{backgroundColor: '#2a4365'}}>
                        <h2 className={'text-xl'}>Quick Check</h2>
                        <h3 className={'text-xl'}>{`${address}`}</h3><br/>
                        <h3 className={'text-xl'}>{`${postcode || ''} ${town || ''}`}</h3>


                        <span>EPCI : {epci_name}</span>
                    </div>
                    <div className={'w-1/5 p-3'}>
                        <img src={Logo} alt={'evalparcel logo'}/>
                    </div>
                </div>
                <ConceptNoteSectionContainer>
                    <div className={'my-2 '}>
                        <div className={'w-1/2 text-xs pr-2 inline-block'}>
                            <div>{`${pluralize(fieldIds.count, "Parcelle", "Parcelles")} : ${fieldIds.join(", ")}`}</div>
                            <div>Surface du terrain : {surface}</div>
                            <div>Zone PLU : {zonage_plu}</div>
                            <div>Propriétaire : {proprietaire}</div>
                        </div>
                        <div className={'w-1/2 text-xs pl-2 inline-block'}>
                            <div>Zone Pinel : {zone_pinel}</div>
                            <div>Maire : {maire} </div>
                            <div>Existence d'une charte territoriale : {charte_territoriale}</div>
                            <div>Encadrement des Loyers : {limitation_loyer}</div>
                        </div>
                    </div>
                </ConceptNoteSectionContainer>
                <ConceptNoteSectionContainer>
                    <div className={''}>
                        <div className="w-1/2 h-64 pr-2 inline-block">
                            <ConceptNoteMap theme={'satellite'} zoom={18}/>
                        </div>
                        <div className="w-1/2 h-64 pl-2 inline-block">
                            <ConceptNoteMap theme={'satellite'} zoom={15}/>
                        </div>
                    </div>
                </ConceptNoteSectionContainer>
                <ConceptNoteSectionContainer>
                    <div>
                        <h3 className={'text-base'}>Synthèse Marché immobilier - Logements neufs collectifs</h3>
                    </div>
                    <h5 className={'text-xs'}>Date des données arrêtées : {date}, Date de la note de synthèse
                        : {report_date} </h5>
                </ConceptNoteSectionContainer>

                <div className="flex flex-row flex-wrap">
                    <ConceptNoteSection title={'Offre disponible'}
                                        url={`${process.env.BASE_URL_API}/v4/eval_parcel/quick_check_pdf/texte_synthese_offre?parcelle_ids[]=${pivot_parcelle_id}`}/>

                    <ConceptNoteSection title={'Prix'}
                                        url={`${process.env.BASE_URL_API}/v4/eval_parcel/quick_check_pdf/texte_synthese_prix?parcelle_ids[]=${pivot_parcelle_id}`}/>

                    <ConceptNoteSection title={'Mise en vente'}
                                        url={`${process.env.BASE_URL_API}/v4/eval_parcel/quick_check_pdf/texte_synthese_mises_en_vente?parcelle_ids[]=${pivot_parcelle_id}`}/>

                    <ConceptNoteSection title={'Réservations'}
                                        url={`${process.env.BASE_URL_API}/v4/eval_parcel/quick_check_pdf/texte_synthese_reservations?parcelle_ids[]=${pivot_parcelle_id}`}/>
                </div>
                <ConceptNoteSectionContainer>
                    <ConceptNoteCommentSection/>
                </ConceptNoteSectionContainer>
            </div>
            <div>
                <ConceptNoteSectionContainer>
                    <ConceptNoteBoxes mainParcelID={pivot_parcelle_id}/>
                </ConceptNoteSectionContainer>
                <ConceptNoteSectionContainer>
                    <ConceptNoteCommentSection big={false}/>
                </ConceptNoteSectionContainer>
            </div>
            <div
                className={'justify-center flex flex-col text-center justify-evenly break-before-always break-after-avoid'}
                style={{height: '26cm'}}>

                <div className={'w-2/3 mx-auto'}>
                    <img src={Logo} alt="" className={'w-full'}/>

                </div>
                <a href={'www.evalparcel.com'} className={'text-3xl text-gray-800'}>
                    www.evalparcel.com
                </a>

                <div>
                    <p className="text-lg">Nous sommes à votre disposition : <a
                        href={'mailto:equipe@evalparcel.com'}>equipe@evalparcel.com</a></p>
                    <a className="text-lg" href={'tel:0478929396'}>Tel : 04 78 92 93 96</a>
                </div>

                <div>
                    <p>EvalparceL</p>
                    <p>EvalparceL, une solution Metaxu Immo SAS.</p>
                    <p>61 avenue de la République - 69 002 LYON</p>
                    <p>CGU – Cliquez ici <a href="https://www.evalparcel.com/mentions-legales">https://www.evalparcel.com/mentions-legales</a>
                    </p>
                </div>
            </div>
        </div>
    )
}


export default withRouter(ConceptNote)
