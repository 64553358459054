import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '@/api/Api';
import { apiV5 } from '../../api/Api';
import _, { orderBy } from 'lodash';
import {
  CSP,
  FROM_25_TO_39_YO,
  NEIGHBORHOOD,
  COLLECTIVE_HOUSING,
  VACANT_HOUSING,
  MEDIAN_INCOME,
  SENIOR,
  UNEMPLOYMENT,
  DEMOGRAPHY_AND_EMPLOYMENT,
} from '../../_helpers/constant';
import axios from 'axios';

const initialState = {
  loading: true,
  accessiblityIsochoneData: [],
  neighborhoodTableData: null,
  transportCartoData: [],
  logementSocialData: null,
  osmDataCarto: [],
  programmesNeufs: null,
  isochroneCarFeature: null,
  errorCarIsochrone: false,
  errorAccessiblityIsochone: false,
  showedMsgErrorIsochrone: false,
};

export const getPerPageArray = (per, arrayInput = []) => {
  let array = [];
  for (let i = 0; i < arrayInput.length; i += per) {
    array.push(
      arrayInput.slice(
        i,
        i + per > arrayInput.length ? arrayInput.length : i + per
      )
    );
  }
  return array;
};

export const MarketSurveySlice = createSlice({
  name: 'marketSurvey',
  initialState,
  reducers: {
    setErrorCarIsochrone(state, { payload }) {
      state.errorCarIsochrone = payload;
    },
    setErrorAccessiblityIsochone(state, { payload }) {
      state.errorAccessiblityIsochone = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setShowedMsgErrorIsochrone(state, { payload }) {
      state.showedMsgErrorIsochrone = payload;
    },
    setAccessiblityIsochoneData(state, { payload }) {
      state.accessiblityIsochoneData = payload;
    },
    setNeighborhoodTableData(state, { payload }) {
      state.neighborhoodTableData = payload;
    },
    setLogementSocialData(state, { payload }) {
      state.logementSocialData = payload;
    },
    setTransportCarto(state, { payload }) {
      state.transportCartoData = payload;
    },
    setOSMDataCarto(state, { payload }) {
      state.osmDataCarto = payload;
    },
    setProgrammesNeufs(state, { payload }) {
      state.programmesNeufs = payload;
    },
    setIsochroneCarFeature(state, { payload }) {
      state.isochroneCarFeature = payload;
    },
    reset: () => initialState,
  },
});

export const MarketSurveyActions = MarketSurveySlice.actions;

export default MarketSurveySlice.reducer;

export const softDeleteRating = async (id, isQc) => {
  try {
    let req = null;
    if (!isQc) {
      req = await Api.delete(`/api/v1/market_surveys/${id}`);
    } else {
      req = await Api.delete(`/ratings/${id}`);
    }

    return req;
  } catch (e) {
    return e.response;
  }
};

export const updateMarketSurveyMeta = createAsyncThunk(
  'updateMarketSurveyMeta',
  async ({ survey_id, meta }, { dispatch }) => {
    try {
      dispatch(MarketSurveyActions.setLoading(true));
      await Api.put(`/api/v1/market_surveys/${survey_id}/meta`, {
        meta,
      });
      dispatch(MarketSurveyActions.setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(MarketSurveyActions.setLoading(false));
    }
  }
);

export const getInfoDetailsSelectedProgrammes = createAsyncThunk(
  'getInfoDetailsSelectedProgrammes',
  async ({ activePrograms, isPdf }) => {
    try {
      const { data: dataBrut } = await axios.get(
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/rapport_etude/information_detaillee_programmes_selectionnes_part_3/affichage_groupe?tran_ids[]=${_.join(
          activePrograms,
          '&tran_ids[]='
        )}`
      );

      const data = {
        ...dataBrut,
        table_data: orderBy(dataBrut.table_data, ['program_name'], ['asc']).map(
          (item, i) => ({ ...item, number: i + 1 })
        ),
      };

      if (isPdf) {
        const tableData = data.table_data;
        const array = getPerPageArray(4, tableData);
        return array.map((item, index) => ({
          ...data,
          name: `${data.name} (${index + 1}/${array.length})`,
          table_data: item,
        }));
      }
      return data;
    } catch (e) {
      return null;
    }
  }
);

export const getIsochroneCarFeature = createAsyncThunk(
  'getIsochroneCarFeature',
  async (parcelId, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await apiV5.get(
        `${process.env.BASE_URL_API}/v5/eval_parcel/accessibility/${parcelId}/isochrone?transport_mode=car&transport_duration=600`
      );
      dispatch(MarketSurveyActions.setIsochroneCarFeature(data));
      // setTimeout(() => {
      //   dispatch(MarketSurveyActions.setIsochroneCarFeature(null));
      //   dispatch(MarketSurveyActions.setErrorCarIsochrone(true));
      // }, 1000);
    } catch (e) {
      dispatch(MarketSurveyActions.setIsochroneCarFeature(null));
      dispatch(MarketSurveyActions.setErrorCarIsochrone(true));
      console.log(e);
    }
  }
);

export const getProgrammesNeufs = createAsyncThunk(
  'getProgrammesNeufs',
  async (parcellsIds, { dispatch }) => {
    try {
      const { data } = await axios.get(
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/rapport_etude/programmes_epci_carto?parcelle_ids[]=${_.join(
          parcellsIds,
          '&parcelle_ids[]='
        )}`
      );

      dispatch(MarketSurveyActions.setProgrammesNeufs(data.features || []));
    } catch (e) {
      console.log(e);
    }
  }
);

export const getOsmDataCarto = createAsyncThunk(
  'getOsmDataCarto',
  async (parcelId, { dispatch }) => {
    try {
      const _data = [
        {
          icon: 'docteur',
          title: 'Médecin',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_doctor`,
        },
        {
          icon: 'pharmacie',
          title: 'Pharmacie',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_pharmacy`,
        },
        {
          icon: 'supermarket',
          title: 'Supermarché',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_supermarket`,
        },
        {
          icon: 'boulangerie',
          title: 'Boulangerie',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_bakery`,
        },
        {
          icon: 'fast_food',
          title: 'Fast food',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_fast_food`,
        },
        {
          icon: 'resto',
          title: 'Restaurant',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_restaurant`,
        },
        {
          icon: 'creche',
          title: 'Crèche',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_childcare`,
        },
        {
          icon: 'coiffure',
          title: 'Coiffure',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_hairdresser`,
        },
        {
          icon: 'salle_sport',
          title: 'Salle de sport',
          url: `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/osm_sport_center`,
        },
      ];

      const getData = async (url) => {
        const { data } = await apiV5.get(url);
        return data;
      };

      const data = await Promise.all(
        _data.map(async (item) => ({
          ...item,
          data: await getData(item.url),
          type: 'osm',
        }))
      );

      dispatch(MarketSurveyActions.setOSMDataCarto(data));
    } catch (e) {
      console.log(e);
    }
  }
);

export const getTableDateInSwitchMapModule = createAsyncThunk(
  'getTableDateInSwitchMapModule',
  async ({ parcelId, section }) => {
    try {
      if (parcelId && section) {
        const url = {
          [DEMOGRAPHY_AND_EMPLOYMENT]: `${process.env.BASE_URL_API}/v5/eval_parcel/demographie_emploi_tableau/${parcelId}/year/2021`,
          [NEIGHBORHOOD]: `${process.env.BASE_URL_API}/v5/eval_parcel/habitants_tableau/${parcelId}/year/2021`,
          [CSP]: `${process.env.BASE_URL_API}/v5/eval_parcel/cadres_tableau/${parcelId}/year/2021`,
          [FROM_25_TO_39_YO]: `${process.env.BASE_URL_API}/v5/eval_parcel/jeunes_25_39_tableau/${parcelId}/year/2021`,
          [COLLECTIVE_HOUSING]: `${process.env.BASE_URL_API}/v5/eval_parcel/logement_collectif_tableau/${parcelId}/year/2021`,
          [VACANT_HOUSING]: `${process.env.BASE_URL_API}/v5/eval_parcel/logement_vacant_tableau/${parcelId}/year/2021`,
          [MEDIAN_INCOME]: `${process.env.BASE_URL_API}/v5/eval_parcel/revenu_median_tableau/${parcelId}/year/2021`,
          [SENIOR]: `${process.env.BASE_URL_API}/v5/eval_parcel/senior_65p_tableau/${parcelId}/year/2021`,
          [UNEMPLOYMENT]: `${process.env.BASE_URL_API}/v5/eval_parcel/chomage_tableau/${parcelId}/year/2021`,
        };
        const { data } = await apiV5.get(url[section]);
        return data;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }
);

export const getAccessibilityIsochoneData = createAsyncThunk(
  'getAccessibilityIsochone',
  async (parcelId, { dispatch, getState }) => {
    try {
      const { accessiblityIsochoneData } = getState().marketSurvey;
      const isExist = accessiblityIsochoneData.find(
        (item) => item.parcelId === parcelId
      );
      if (parcelId && !isExist) {
        const {
          data: { data },
        } = await apiV5.get(
          `${process.env.BASE_URL_API}/v5/eval_parcel/accessibility/${parcelId}/isochrone`
        );

        const currentValue = [...accessiblityIsochoneData, { parcelId, data }];
        dispatch(MarketSurveyActions.setAccessiblityIsochoneData(currentValue));
        return currentValue;
      }
      return accessiblityIsochoneData;
      // setTimeout(() => {
      //   dispatch(MarketSurveyActions.setAccessiblityIsochoneData(null));
      //   dispatch(MarketSurveyActions.setErrorAccessiblityIsochone(true));
      // }, 1000);
    } catch (e) {
      dispatch(MarketSurveyActions.setAccessiblityIsochoneData(null));
      dispatch(MarketSurveyActions.setErrorAccessiblityIsochone(true));
      console.log(e);
      return null;
    }
  }
);

export const getTransportCarto = createAsyncThunk(
  'getTransportCarto',
  async (parcelId, { dispatch }) => {
    try {
      const { data } = await apiV5.get(
        `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/${parcelId}/transport_carto`
      );
      dispatch(MarketSurveyActions.setTransportCarto(data));
    } catch (e) {
      console.log(e);
    }
  }
);

export const getDataLogementSocial = createAsyncThunk(
  'getDataLogementSocial',
  async (parcelId, { dispatch }) => {
    try {
      const { data: tableData } = await apiV5.get(
        `${process.env.BASE_URL_API}/v5/eval_parcel/logement_social_tableau/${parcelId}/year/2021`
      );
      const {
        data: { data: parcel },
      } = await axios.get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${parcelId}`
      );

      const postcode = parcel.attributes.commune_national_code;
      const { data: box1 } = await axios.get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/social_housing/${postcode}/social_housing_shortcoming`
      );
      const { data: box2 } = await axios.get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/social_housing/${postcode}/social_housing_stats`
      );
      dispatch(
        MarketSurveyActions.setLogementSocialData({
          tableData,
          boxes: [box1, box2],
        })
      );
    } catch (e) {
      console.log(e);
    }
  }
);

export const getDataFromUrls = async (urls = []) => {
  try {
    const data = await Promise.all(
      urls?.map(async (url) => {
        if (!url) {
          return null;
        }

        let _url = url;
        if (typeof url !== 'string') {
          _url = url?.url;
        }
        try {
          const { data } = await axios.get(_url);
          return { ...data, ...(typeof url !== 'string' ? url : {}) };
        } catch (e) {
          console.log(e, url);
          return null;
        }
      })
    );
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getDataFromUrl = async (url, version = 4) => {
  try {
    const { data } =
      version === 4 ? await axios.get(url) : await apiV5.get(url);
    return data;
  } catch (e) {
    console.log(url, e);
    return null;
  }
};

export const updateMarketSurveyName = async (id = null, name = null) => {
  if (name && name.length && id) {
    const req = await Api.put(`/api/v1/market_surveys/${id}/name`, { name });
    console.log(req);
  }
};

export const copySharedSurvey = async (tokenSurvey, hasUser) => {
  try {
    if (hasUser) {
      await Api.post(
        `/v3/market_surveys/${tokenSurvey}/sharee/duplicate_study`
      );
      return {};
    } else {
      const req = await fetch(
        `${process.env.BASE_URL}/v3/market_surveys/${tokenSurvey}/sharee/duplicate_study`,
        { method: 'POST' }
      );
      const data = await req.json();
      return data;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const exportToPdfSharedSurvey = async (tokenSurvey, hasUser) => {
  try {
    if (hasUser) {
      await Api.post(`/v3/market_surveys/${tokenSurvey}/sharee/export_pdf`);
      return {};
    } else {
      const req = await fetch(
        `${process.env.BASE_URL}/v3/market_surveys/${tokenSurvey}/sharee/export_pdf`,
        { method: 'POST' }
      );
      const data = await req.json();
      return data;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};
