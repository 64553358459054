import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
// import 'remixicon/fonts/remixicon.css';
// import '@/stylesheets/page.css';
// import './home.css';

const Home = (props) => {
        const history = useHistory()

        history.push('/ratings')

        return null

        // lié à la carte 1199 qui prévoit le remplacement de la page d'accueil par la page d'étude.

        // const {
        //     ratings,
        //     targets,
        //     projects,
        // } = props;
        //
        // const [open, setOpen] = useState(true);
        // const [redirect, setRedirect] = useState(false);
        // const {setIsOnBoardingModalOpen, isOnBoardingModalOpen} = useModal()
        //
        // const [offset, setOffset] = useState(0)
        // useEffect(() => {
        //     setOffset(document.getElementById('navbar')?.offsetHeight )
        // })
        //
        //
        // // useLayoutEffect(() => {
        // //     getMyRatings();
        // // getMyTargets();
        // // getMyProjects();
        // // }, [getMyRatings, getMyTargets, getMyProjects]);
        //
        // // const handleOpen = () => {
        // //     setOpen(!open);
        // //     localStorage.setItem('openModal', true);
        // //     window.location.pathname = '/ratings';
        // // };
        // let {checkSession} = useAuth();
        //
        // useEffect(() => {
        //         checkSession()
        //     }, []
        // )
        //
        //
        // useEffect(() => {
        //     if (localStorage.getItem('openModal')) {
        //         setOpen(false);
        //         setRedirect(true);
        //
        //     }
        //     setIsOnBoardingModalOpen(true)
        // }, []);
        //
        // return (
        //     <>
        //         <OnBoardingModal/>
        //         <div className="bg-blue-primary flex">
        //             <div className="container mx-auto" style={{paddingTop: `${offset}px`}}>
        //                 <div className="w-full grid grid-cols-12 md:grid-cols-6 lg:grid-cols-11 col-span-12 gap-6">
        //                     <div
        //                         className="mx-8 md:mx-0 grid col-span-12 lg:grid-cols-2 lg:col-span-4 gap-6 mt-4 md:mt-8 mb-8 min-content">
        //                         <div className="bg-white shadow-lg rounded-xl p-6">
        //                             <h2 className="font-extrabold text-blue-500">
        //                                 {targets?.length > 0 ? targets.length : 0}
        //                             </h2>
        //                             <p className="font-bold mb-0">Ciblages</p>
        //                             <span className="text-sm text-gray-500">
        //               {targets?.length > 0 ? targets?.length : 0} parcelles ciblées</span>
        //                             <div className="flex justify-center mt-4">
        //                                 <Button to={'/targets/new'} title="Nouveau Ciblage"/>
        //                             </div>
        //                         </div>
        //                         <div className="bg-white shadow-lg rounded-xl p-6">
        //                             <h2 className="font-extrabold text-blue-500">
        //                                 {ratings?.length > 0 ? ratings.length : 0}
        //                             </h2>
        //                             <p className="font-bold mb-0">Évaluations</p>
        //                             <span className="text-sm text-gray-500">
        //               {ratings?.length > 0 ? ratings.length : 0} terrains {' '}évalués</span>
        //                             <div className="flex justify-center mt-4">
        //                                 <Button to={'/ratings/new'} title="Nouvelle étude"/>
        //                             </div>
        //                         </div>
        //                         <div
        //                             className="opacity-75 cursor-not-allowed hover:opacity-25 transform transition duration-500  text-white bg-blue-darken grid col-span-2 bg-white shadow-lg rounded-xl p-8">
        //                             <p className="mb-1 mt-0">Commander, Sélectionner</p>
        //                             <p className="text-xs font-bold mt-0 mb-0">
        //                                 Mon étude de marché
        //                             </p>
        //                             <div
        //                                 className={
        //                                     'flex bg-white mt-5 outline items-center ring-1 ring-gray-400 transition duration-300	transform outline-none rounded-sm shadow-sm w-full justify-between'
        //                                 }
        //                             >
        //                                 <input
        //                                     className={'outline-none p-3 w-full'}
        //                                     placeholder="Marché immobilier neuf..."
        //                                 />
        //                                 <i className="text-lg cursor-pointer ri-close-line transform duration-500 ease-in-out hover:scale-150 transition p-3"/>
        //                             </div>
        //                             <div
        //                                 className={
        //                                     'flex bg-white mt-5 outline items-center ring-1 ring-gray-400 transition duration-300	transform outline-none rounded-sm shadow-sm w-full justify-between'
        //                                 }
        //                             >
        //                                 <input
        //                                     className={'outline-none p-3 w-full'}
        //                                     placeholder="Indiquez votre territoire d'étude..."
        //                                 />
        //                                 <i className="text-lg cursor-pointer bg-blue-600 ri-search-line  p-3"/>
        //                             </div>
        //                         </div>
        //                         <div
        //                             className="opacity-75 cursor-not-allowed hover:opacity-25 transform transition duration-500 grid col-span-2 bg-white shadow-lg rounded-xl">
        //                             <div
        //                                 className="content-header rounded-b-none rounded-t-lg w-full"
        //                                 style={{
        //                                     background: `url(https://images.unsplash.com/photo-1474405273094-b668656721eb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2232&q=80)`,
        //                                     backgroundSize: 'cover',
        //                                     height: 200,
        //                                 }}
        //                             />
        //                             <div className="content p-4">
        //                                 <p className="font-bold my-1">
        //                                     Réalisez de bons ciblages
        //                                 </p>
        //                                 <span className="text-sm text-gray-400">
        //                 Cette Vidéo de 45 secondes vous présente des éléments
        //                 pour vous aider à réaliser vos premiers ciblages de
        //                 parcelles.
        //               </span>
        //                             </div>
        //                         </div>
        //                         <div
        //                             style={{
        //                                 background:
        //                                     'url(https://images.unsplash.com/photo-1422393462206-207b0fbd8d6b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80)',
        //                                 backgroundSize: 'cover',
        //                             }}
        //                             className="grid relative card_after opacity-75 cursor-not-allowed hover:opacity-25 transform transition duration-500  col-span-2 text-white shadow-lg rounded-xl p-6"
        //                         >
        //                             <div className="z-50">
        //                                 <h3 className="text-2xl w-min text-center p-3 border-b-1 border-white font-normal">
        //                                     Bien vivre en périurbain
        //                                 </h3>
        //                                 <p className="font-bold">
        //                                     Lorem ipsum dolor sit amet, consectetur adipisicing
        //                                     elit, sed do eiusmod tempor incididunt ut labore et
        //                                     dolore
        //                                 </p>
        //                             </div>
        //                             <div className="w-2/4">
        //                                 <Button title="Aperçu"/>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div
        //                         className="mx-8 md:mx-0 grid col-span-12 grid-cols-1 md:grid-cols-2 md:col-span-7 gap-6 mt-8 mb-8"
        //                         style={{height: 'min-content'}}
        //                     >
        //                         <div className="col-span-2" style={{height: 'min-content'}}>
        //                             {projects?.length > 0 ? (
        //                                 <div className="bg-white shadow-lg p-4 rounded-lg">
        //                                     <div className="flex justify-between">
        //                                         <h3>{pluralize(projects.length, "Dernière étude de marché", "Dernières études de marché")}</h3>
        //                                     </div>
        //                                     <Table type="projects" view limit={5} data={projects}/>
        //                                 </div>
        //                             ) : (
        //                                 <ProjectCard
        //                                     subtitle=""
        //                                     title="Derniers projets"
        //                                     text="Retrouvez ici vos 5 derniers projets. Un terrain avec un statut « projet » est un terrain sur lequel vous rentrez dans une phase d’engagement de la promesse de vente"
        //                                 />
        //                             )}
        //                         </div>
        //                         <div className="col-span-2">
        //                             {ratings?.length > 0 ? (
        //                                 <div className="bg-white shadow-lg p-4 rounded-lg">
        //                                     <div className="flex justify-between">
        //                                         <h3>Dernières évaluations</h3>
        //                                         <Link
        //                                             className="text-blue-600 font-semibold text-sm"
        //                                             to="/ratings/list"
        //                                         >
        //                                             Voir toutes les évaluations
        //                                         </Link>
        //                                     </div>
        //                                     <Table view limit={5} data={ratings} type="ratings"/>
        //                                 </div>
        //                             ) : (
        //                                 <ProjectCard
        //                                     subtitle="Voir toute les évaluations"
        //                                     to={'/ratings/new'}
        //                                     title="Dernières évaluations"
        //                                     button
        //                                     buttonTitle="Lancer votre première évaluation"
        //                                 />
        //                             )}
        //                         </div>
        //                         <div className="col-span-2">
        //                             {targets?.length > 0 ? (
        //                                 <div className="bg-white shadow-lg p-4 rounded-lg">
        //                                     <div className="flex justify-between">
        //                                         <h3>Derniers ciblages</h3>
        //                                         <Link
        //                                             className="text-blue-600 font-semibold text-sm"
        //                                             to="/ratings/list"
        //                                         >
        //                                             Voir tous les ciblages
        //                                         </Link>
        //                                     </div>
        //                                     <Table view limit={5} data={targets}/>
        //                                 </div>
        //                             ) : (
        //                                 <ProjectCard
        //                                     subtitle="Voir tous les ciblages"
        //                                     to={'/targets/list'}
        //                                     title="Derniers ciblages"
        //                                     button
        //                                     buttonTo={'/targets/new'}
        //                                     buttonTitle="Lancer votre premier ciblage"
        //                                 />
        //                             )}
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </>
        // )
    }
;

export default Home