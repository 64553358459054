import React, { useLayoutEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useRedirectUserHasntAccessAllStudies } from './PrivateRoute';
import { useHistory } from 'react-router-dom';

export function AdminRoute({ children, ...rest }) {
  const { checkSession } = useAuth();
  const history = useHistory();

  useRedirectUserHasntAccessAllStudies(false);

  useLayoutEffect(() => {
    const checkMySession = async () => {
      const currentSession = await checkSession();
      if (currentSession) {
        const { isAdmin, isCapemUser, hasAllAccess } = currentSession;
        if (!isAdmin && !isCapemUser && !hasAllAccess) {
          history.push('/demande-rendez-vous');
        }
      } else {
        history.push('/demande-rendez-vous');
      }
    };
    checkMySession();
  }, []);

  return <Route {...rest}>{children}</Route>;
}
