import React from 'react';
import terrainIcon from '../../../../../../../assets/images/icons/terrain.svg';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const CamaieuLegendLayer = ({ data }) => {
  const { legend_title, low_extremity, high_extremity } = data;
  const { isPdfMode } = useSelector((state) => state.others);

  return (
    <div className={'max-w-sm'}>
      <h3 className={'text-sm'}>{legend_title}</h3>
      <div>
        <div
          className={classNames('flex flex-row', {
            'text-sm': isPdfMode,
            'text-xs': isPdfMode,
          })}
        >
          <div className={'p-2'}>{low_extremity?.title}</div>
          {data.colors?.map((color, index) => {
            return (
              <div
                key={`color__${color}__${index}`}
                className={'w-4 h-4 my-2'}
                style={{ backgroundColor: color }}
              />
            );
          })}
          <div className={'p-2'}>{high_extremity?.title}</div>
        </div>
        <div className={'flex flex-row'}>
          <img src={terrainIcon} className={'w-5'} alt={'land icon'} />
          <div
            className={classNames('p-2', {
              'text-sm': isPdfMode,
              'text-xs': isPdfMode,
            })}
          >
            Terrain évalué
          </div>
        </div>
      </div>
    </div>
  );
};

export default CamaieuLegendLayer;
