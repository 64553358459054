import React, {createContext, useState} from "react";

export const selectedParcelsContext = createContext(null)

const SelectedParcelsProvider = ({children}) => {
    const [mainParcelID, setMainParcelID] = useState([])
    const [fieldIds, setFieldIds] = useState([])
    const [selectedParcelsIds, setSelectedParcelsIds] = useState([])

    return <selectedParcelsContext.Provider
        value={{selectedParcelsIds, setSelectedParcelsIds, fieldIds, setFieldIds, mainParcelID, setMainParcelID}}>
        {children}
    </selectedParcelsContext.Provider>
}


export default SelectedParcelsProvider