import Icon from "../../../../../../assets/images/icon.svg";
import React from "react";

function ConceptNoteSectionTitle({title}) {
    return <div className={"flex flex-row items-center"}>
        <img src={Icon} alt={"evalparcel icon"} style={{width: '0.7cm'}}/>
        <h3 className={"text-base font-medium"}>{title}</h3>
    </div>;
}

export default ConceptNoteSectionTitle