import React, {createContext, useState} from "react";

export const modalsContext = createContext(null)

const ModalsProvider = ({children}) => {
    const [isQuickCheckModalOpened, setIsQuickCheckModalOpened] = useState(false)
    const [isOnBoardingModalOpen, setIsOnBoardingModalOpen] = useState(false)
    const [isCookieModalOpen, setIsCookieModalOpen] = useState(false)
    const [isSharingModalOpened, setIsSharingModalOpened] = useState(false)
    const [isExportPDFModalOpened, setIsExportPDFModalOpened] = useState(false)

    const [toggleTabs, setToggleTabs] = useState(false)
    const [currentRatingID, setCurrentRatingID] = useState("")
    const [ratingIds, setRatingIds] = useState([])


    const [msId, setMsId] = useState(null)
    


    return <modalsContext.Provider value={{
        isQuickCheckModalOpened,
        setIsQuickCheckModalOpened,
        currentRatingID,
        setCurrentRatingID,
        ratingIds,
        setRatingIds,
        setIsOnBoardingModalOpen,
        isOnBoardingModalOpen,
        toggleTabs,
        setToggleTabs,
        isSharingModalOpened,
        setIsSharingModalOpened,
        isCookieModalOpen,
        setIsCookieModalOpen,
        msId, setMsId, isExportPDFModalOpened, setIsExportPDFModalOpened,
    }}>
        {children}
    </modalsContext.Provider>


}

export default ModalsProvider
