import React from "react";
import Api from "../../../../api/Api";
import useSWR from "swr";
import BoxesContainer from "../../../../components/BoxesContainer";

const ConceptNoteBoxes = ({mainParcelID}) => {

    const fetcher = url => Api.get(url).then(res => res.data)

    const url = `${process.env.BASE_URL_API}/v4/eval_parcel/quick_check/${mainParcelID}/boxes/simplified`
    const {data, error} = useSWR(url, fetcher)

    if (!data) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Loading failed...</div>
    }
    return <div className={'break-before-always'}>
        {data?.map(({axe_name, boxes}, index) => {
            return (
                <div key={`${axe_name}__${index}`}>
                    <div className={'border-b-2 border-blue-900 mb-2'}>
                        <h2 className={'text-lg m-0 font-medium text-gray-900'}>{axe_name}</h2>
                    </div>
                    <BoxesContainer print_mode={true} boxes={boxes}/>
                </div>
            )
        })}
    </div>
}

export default ConceptNoteBoxes
