import { useDispatch } from 'react-redux';
import { create } from 'zustand';
import { updateMarketSurveyMeta } from '../store/slices/marketSurvey';

export const useMetadataMs = create((set) => ({
  metadata: null,
  surveyId: null,
  isShare: false,
  setMetadata: (data) => {
    set(() => ({ metadata: data }));
  },
  setSurveyId: (id) => {
    set(() => ({ surveyId: id }));
  },
  setIsShare: (value) => {
    set(() => ({ isShare: value }));
  },
}));

const useChangeOnMsMap = () => {
  const { metadata, surveyId, isShare, setMetadata } = useMetadataMs(
    (state) => state
  );
  const dispatch = useDispatch();

  const handleMapMetadata = (ev) => {
    if (metadata && surveyId && !isShare) {
      const currentMapData = {
        name: ev.name,
        center: ev.map.getCenter(),
        zoom: ev.map._zoom,
      };
      const currentMetadata = {
        ...metadata,
        maps_metadata: {
          ...(metadata.maps_metadata || {}),
          [ev.name]: currentMapData,
        },
      };
      setMetadata(currentMetadata);
      dispatch(
        updateMarketSurveyMeta({
          survey_id: surveyId,
          meta: currentMetadata,
        })
      );
    }
  };

  return { handleMapMetadata };
};

export default useChangeOnMsMap;
