import React, { memo, useEffect, useRef, useState } from 'react';
import IconSwitch from './IconSwitch';
import { useMap } from 'react-leaflet';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { poiEquipementIcons } from '../../../../icons';
import { useSelector } from 'react-redux';

const BasicLegendLayer = ({ data, needIsochrone, otherData }) => {
  const map = useMap();
  const [open, setOpen] = useState(true);
  const toogleBtnRef = useRef(null);
  const { isPdfMode } = useSelector((state) => state.others);

  const handleToogleDrowdown = () => {
    setOpen((curr) => !curr);
  };

  const isLg = useMediaQuery({ query: '(max-width: 1280px)' });

  useEffect(() => {
    let timeoutFunc = null;
    if (isLg) {
      timeoutFunc = setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
    return () => {
      timeoutFunc && clearTimeout(timeoutFunc);
    };
  }, [isLg]);

  useEffect(() => {
    var openPopup = false;
    var closed = false;

    map.on('movestart', () => {
      !openPopup && !closed && setOpen(false);
    });
    map.on('moveend', () => {
      !openPopup && !closed && setOpen(true);
    });
    map.on('zoomstart', () => {
      !openPopup && !closed && setOpen(false);
    });
    map.on('zoomend', () => {
      !openPopup && !closed && setOpen(true);
    });
    map.on('popupopen', () => {
      setTimeout(() => {
        setOpen(false);
        openPopup = true;
      }, 500);
    });
    map.on('popupclose', () => {
      setTimeout(() => {
        setOpen(true);
        openPopup = false;
      }, 500);
    });
    if (toogleBtnRef.current) {
      toogleBtnRef.current.addEventListener('click', () => {
        closed = !closed;
      });
    }
  }, [map]);

  return (
    <>
      {data ? (
        <div
          style={{ zIndex: 1000 }}
          className={'bg-white absolute ml-3 mt-3 rounded-lg p-2'}
        >
          <div
            ref={toogleBtnRef}
            onClick={handleToogleDrowdown}
            className={classNames(
              'p-2 hover:bg-gray-200 rounded-md text-sm font-semibold flex justify-between items-center gap-x-8',
              { 'border-b-1 border-gray-500': open }
            )}
          >
            <span>Légende</span>
            {open ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </div>
          {open && (
            <div className={classNames('w-full mt-2')}>
              {needIsochrone && (
                <div className="flex gap-x-2 items-center">
                  <span
                    style={{ borderColor: '#009900' }}
                    className="inline-block w-8 border-b-2"
                  ></span>
                  <span
                    className={classNames('p-0 sm:p-2 ', {
                      'text-sm': isPdfMode,
                      'text-xs': !isPdfMode,
                    })}
                  >
                    15 minutes à pieds
                  </span>
                </div>
              )}
              {[...data, ...(otherData || [])].map(({ icon, title }, index) => {
                const object = poiEquipementIcons[icon];
                return (
                  <div
                    key={`key__${index}`}
                    className={'flex flex-row items-center'}
                  >
                    {object ? (
                      <div
                        style={{
                          backgroundColor: object.color,
                        }}
                        className={classNames(
                          'w-6 2xl:w-8 h-6 2xl:h-8 rounded-full flex items-center justify-center',
                          { 'border-1 border-black': icon === 'bus' }
                        )}
                      >
                        <img className="h-4 2xl:h-5 w-auto" src={object.url} />
                      </div>
                    ) : (
                      IconSwitch(icon, title)
                    )}
                    <div
                      className={classNames('p-0 sm:p-2 ', {
                        'text-sm': isPdfMode,
                        'text-xs': !isPdfMode,
                      })}
                    >
                      {title}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{ zIndex: 1000 }}
          className={'bg-white absolute ml-3 mt-3 rounded-lg p-0 sm:p-2'}
        >
          <div className={'flex flex-row'}>
            {IconSwitch('terrain', 'terrain evalue')}
            <div className={'p-0 sm:p-2 text-xs'}>Terrain évalué</div>
          </div>
          {needIsochrone && (
            <div className="flex gap-x-2 items-center">
              <span className="inline-block w-8 border-b-2 border-blue-500"></span>
              <span className="p-0 sm:p-2 text-xs">15 minutes à pieds</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default memo(BasicLegendLayer);
