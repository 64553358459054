import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommune } from '../../../store/actions/ciblage/commune';
import spacingThousands from '../../../_helpers/thousandShow';
import SwitchInput from '../../atoms/switch-input/SwitchInput';
import inArray from '../../../_helpers/inArray';

export const PINEL_OPTIONS = [
  { value: 'A', code: 1 },
  { value: 'ABis', code: 2 },
  { value: 'B1', code: 3 },
  { value: 'B2', code: 4 },
  { value: 'C', code: 5 },
];

const PopupContentCommune = ({ feature }) => {
  const {
    valueTerritoire: { epci, selectedCommunes, commune },
    cibler,
  } = useSelector((state) => state.ciblageCreate);
  const {
    properties: {
      name,
      zonage_pinel,
      avg_price_m2_cecim_after_2020,
      population_2018,
      evolution_pop_2013_2018,
      part_logement_collectif,
      surface_commune_meter_square,
      pourcentage_surface_artificialisee,
      mayor_name,
      maire_nouvellement_elu,
      number_of_programs_started_5_years,
    },
  } = useMemo(() => feature, [feature]);
  const dispatch = useDispatch();

  const handleChangeSwitchInput = useCallback(
    (checked) => {
      dispatch(selectCommune({ id: feature.id, checked }));
    },
    [feature]
  );

  const value = useMemo(
    () => inArray(selectedCommunes || [], feature.id, 'id'),
    [selectedCommunes, feature]
  );

  return (
    <>
      <div className="border-b-1 pb-2">
        <h2 className="text-lg font-semibold">{name}</h2>
        {epci && <span className="text-sm mt-2 block">EPCI: {epci.label}</span>}
      </div>
      <div className="mt-2 border-b-1 pb-2">
        <div className="text-sm font-semibold">
          Zone PINEL:{' '}
          <span className="font-normal">
            {PINEL_OPTIONS.find((item) => item.code === parseInt(zonage_pinel))
              ?.value || 'Non'}
          </span>
        </div>
        <div className="text-sm font-semibold">
          Programmes depuis 5 ans:{' '}
          <span className="font-normal">
            {number_of_programs_started_5_years || 'Non'}
          </span>
        </div>
        <div className="text-sm font-semibold">
          Prix moyen résa Neuf Ttes TVA:{' '}
          <span className="font-normal">
            {avg_price_m2_cecim_after_2020}€/m²
          </span>
        </div>
      </div>
      <div className="mt-2 border-b-1 pb-2">
        <div className="text-sm font-semibold">
          {spacingThousands(population_2018)} habitants |{' '}
          {evolution_pop_2013_2018 > 0 ? '+' : ''}
          {evolution_pop_2013_2018}%
        </div>
        <div className="text-sm font-semibold">
          {Math.ceil(part_logement_collectif)} % de de logements collectifs
        </div>
        <div className="text-sm font-semibold">
          {spacingThousands(
            Math.floor((surface_commune_meter_square || 0) / 1000000)
          )}{' '}
          km² | {pourcentage_surface_artificialisee}% - part de surface
          artificialisée 2009-2020
        </div>
      </div>
      <div className="mt-2 pb-2">
        <div className="text-sm font-semibold">Maire: {mayor_name}</div>
        <div className="text-sm font-semibold">
          Nouvellement élu: {maire_nouvellement_elu ? 'Oui' : 'Non'}
        </div>
      </div>
      {!cibler && !commune && (
        <div className="border-t-1">
          <div className="flex justify-center items-center mt-2 bg-gray-400 p-2 rounded-md">
            <label className="text-base mr-2">Sélection</label>
            <SwitchInput value={value} onChange={handleChangeSwitchInput} />
          </div>
        </div>
      )}
    </>
  );
};

export default PopupContentCommune;
