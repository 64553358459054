import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrograms } from '../../../hooks/usePrograms';
import { TableV2 } from '../../molecules/table/Table';
import { transformDataProgrammes } from '../../Programs/ProgramsTable';
import moment from 'moment';
import { orderBy } from 'lodash';
import { create } from 'zustand';
import { programmeSliceActions } from '../../../store/slices/programme';
import classNames from 'classnames';

export const useBlock3_1 = create((set) => ({
  currentTranIdClicked: null,
  hoverTranId: null,
  currentProgrammeClicked: null,
  setCurrentTranIdClick: (tranId) => {
    set(() => ({ currentTranIdClicked: tranId }));
  },
  setHoverTrandId: (tranId) => {
    set(() => ({ hoverTranId: tranId }));
  },
  setCurrentProgrammeClicked: (data) => {
    set(() => ({ currentProgrammeClicked: data }));
  },
}));

const columnsTable = (isPdfMode = false) => [
  {
    label: 'Programme',
    key: 'programmeName',
    sortable: true,
    render: (rowData) => (
      <div
        className={classNames('flex items-center gap-x-3', {
          'text-xs 2xl:text-sm': !isPdfMode,
          'text-xs': isPdfMode,
        })}
      >
        <div
          style={{ minHeight: 32, minWidth: 32 }}
          className="w-8 h-8 bg-greenMarker flex items-center justify-center rounded-full text-white font-semibold"
        >
          {rowData.number}
        </div>
        <div>{rowData.programmeName}</div>
      </div>
    ),
  },
  {
    label: 'Commune',
    key: 'commune',
    sortable: true,
    render: (rowData) => (
      <div
        className={classNames({
          'text-xs 2xl:text-sm': !isPdfMode,
          'text-xs': isPdfMode,
        })}
      >
        {rowData.commune}
      </div>
    ),
  },
  {
    label: 'Promoteur',
    key: 'promoteur',
    sortable: true,
    render: (rowData) => (
      <div
        className={classNames({
          'text-xs 2xl:text-sm': !isPdfMode,
          'text-xs': isPdfMode,
        })}
      >
        {rowData.promoteur}
      </div>
    ),
  },
  {
    label: 'Date MEV',
    key: 'dateMev',
    sortable: true,
    render: (rowData) => (
      <div
        className={classNames('text-center whitespace-nowrap', {
          'text-xs 2xl:text-sm': !isPdfMode,
          'text-xs': isPdfMode,
        })}
      >
        {moment(rowData.dateMev).format('DD-MM-YYYY')}
      </div>
    ),
  },
  {
    label: 'Logt. MEV',
    key: 'NbLogMisEnVente',
    sortable: true,
    render: (rowData) => (
      <div
        className={classNames('text-center', {
          'text-xs 2xl:text-sm': !isPdfMode,
          'text-xs': isPdfMode,
        })}
      >
        {rowData.NbLogMisEnVente}
      </div>
    ),
  },
  {
    label: 'Prix MEV',
    key: 'pmmv',
    sortable: true,
    render: (rowData) => (
      <div
        className={classNames('text-center', {
          'text-xs 2xl:text-sm': !isPdfMode,
          'text-xs': isPdfMode,
        })}
      >
        {rowData.pmmv} €
      </div>
    ),
  },
  {
    label: 'Rythme Résas',
    key: 'rythme_moyen_resas',
    sortable: true,
    render: (rowData) => (
      <div
        className={classNames('text-center', {
          'text-xs 2xl:text-sm': !isPdfMode,
          'text-xs': isPdfMode,
        })}
      >
        {rowData.rythme_moyen_resas}
      </div>
    ),
  },
];

const MsTableNewProgrammes = ({ isPdfMode }) => {
  const { activePrograms } = usePrograms();
  const { programmesCarto } = useSelector((state) => state.programme);
  const [tableData, setTableData] = useState([]);
  const [sorting, setSorting] = useState({
    key: 'programmeName',
    value: 'asc',
  });
  const {
    setCurrentTranIdClick,
    currentTranIdClicked,
    setCurrentProgrammeClicked,
  } = useBlock3_1((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (programmesCarto?.features?.length && activePrograms?.length) {
      const featuresMappedAndSelected = orderBy(
        programmesCarto.features.filter((item) =>
          activePrograms.includes(item.properties.tran_id)
        ),
        ['properties.nom_programme', ['asc']]
      ).map((item, i) => ({ number: i + 1, ...item }));
      setTableData(transformDataProgrammes(featuresMappedAndSelected, true));
      dispatch(
        programmeSliceActions.setOnlySelelectedProgrammes(
          featuresMappedAndSelected
        )
      );
    }
  }, [programmesCarto, activePrograms]);

  const handleClickOnRow = useCallback(
    (row) => {
      if (programmesCarto) {
        const features = programmesCarto.features;
        setCurrentProgrammeClicked(null);
        setCurrentTranIdClick(null);
        setTimeout(() => {
          setCurrentProgrammeClicked(
            features.find((item) => item.properties.tran_id === row.tran_id)
          );
          setCurrentTranIdClick(row.tran_id);
        }, 100);
      }
    },
    [programmesCarto]
  );

  const handleHoverTr = useCallback((id) => {
    if (id) {
      const elementIcon = document.getElementById(`icon_number_${id}`);
      const eleContainer = document.querySelector(
        `.icon_number_container_${id}`
      );
      if (elementIcon && eleContainer) {
        elementIcon.style.transform = 'scale(1.5)';
        eleContainer.style.zIndex = 5000;
      }
    }
  }, []);

  const handleLeave = useCallback(
    (id) => {
      if (id) {
        const elementIcon = document.getElementById(`icon_number_${id}`);
        const eleContainer = document.querySelector(
          `.icon_number_container_${id}`
        );
        if (elementIcon && eleContainer) {
          elementIcon.style.transform = 'scale(1)';
          if (id !== currentTranIdClicked) {
            eleContainer.style.zIndex = 3000;
          }
        }
      }
    },
    [currentTranIdClicked]
  );

  const handleSorting = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  return (
    <TableV2
      clickedTr={currentTranIdClicked}
      onChangeSorting={handleSorting}
      sorting={sorting}
      idKeyname="tran_id"
      onHover={handleHoverTr}
      onLeave={handleLeave}
      columns={columnsTable(isPdfMode)}
      data={orderBy(
        tableData,
        [(item) => {
          if (['pmmv', 'NbLogMisEnVente', 'rythme_moyen_resas'].includes(sorting.key)) {
            return Number(item[sorting.key]);
          }
          return item[sorting.key];
        }],
        [sorting.value]
      )}
      simpleTable
      onRowClick={handleClickOnRow}
      classNameTd={isPdfMode ? 'px-2 py-1' : 'p-2'}
      withBgTh
    />
  );
};

export default memo(MsTableNewProgrammes);
