import React, { memo } from 'react';
import {
  MAP_PROGRAMME,
  SELECTED_PROGRAMS_MAP,
} from '../../../_helpers/constant';
import { Contents } from './MSTableSection';
import MsTableNewProgrammes from './MsTableNewProgrammes';
import LoadingSection from './LoadingSection';
import { usePrograms } from '../../../hooks/usePrograms';
import classNames from 'classnames';

function PropramsBlockWithMap({ isPdfMode, contentData }) {
  const { activePrograms } = usePrograms();

  return (
    <div className="flex-grow h-full grid gap-x-5 grid-cols-2">
      <div className="h-full flex justify-center">
        <Contents
          isPdfRenderMode={isPdfMode}
          data={[
            {
              type: MAP_PROGRAMME,
              onlySelected: true,
              defaultTileLayer: 'classique',
              hFull: true,
              noCluster: true,
              nameMap: SELECTED_PROGRAMS_MAP,
            },
          ]}
        />
      </div>
      {!!contentData?.length ? (
        <div
          className={classNames('h-full flex flex-col', {
            'space-y-8': !isPdfMode,
            'space-y-4': isPdfMode,
          })}
        >
          <div className="flex items-center justify-between flex-nowrap gap-x-4">
            <ul className="list-discv space-y-3 border-r-1 border-black pr-12">
              {contentData.map((p, index) => {
                return (
                  <li className="text-xs 2xl:text-sm" key={`part_${index}`}>
                    <div dangerouslySetInnerHTML={{ __html: p }}></div>
                  </li>
                );
              })}
            </ul>
            <div className="flex flex-col items-end bg-blue-200 px-4 py-2 rounded-md">
              <div className="font-semibold text-xs whitespace-nowrap">
                Progs. sélectionnés
              </div>
              <div className="px-4 rounded-md py-1 text-xs mb-1 border-2 border-white bg-green-400 text-white">
                {activePrograms?.length}
              </div>
            </div>
          </div>

          <div className="overflow-auto h-0 flex-grow">
            <MsTableNewProgrammes isPdfMode={isPdfMode} />
          </div>
        </div>
      ) : (
        <LoadingSection />
      )}
    </div>
  );
}

export default memo(PropramsBlockWithMap);
