export const COLOR_EPCI_COMMUNE = [
  {
    keyword: 'epci',
    bgColorClass: 'bg-tr-vert',
  },
  {
    keyword: 'commune',
    bgColorClass: 'bg-tr-blue',
  },
];

export const COLOR_NEUF_VENTILATION = [
  {
    keyword: 'utilisateurs',
    bgColorClass: 'bg-tr-vert',
  },
  {
    keyword: 'invest. privés',
    bgColorClass: 'bg-tr-blue',
  },
  {
    keyword: 'ventes en blocs',
    bgColorClass: 'bg-tr-orange',
  },
];
