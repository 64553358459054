import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '@/api/Api';
import { apiV5 } from '../../api/Api';
import { isArray } from 'lodash';
import axios from 'axios';
import { buildParamsByJson } from '../actions/ciblage/parcel';
import _ from 'lodash';

export const axiosFormData = axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const initialState = {
  bgLogin: '',
  currentUserOrganizations: [],
  departements: [],
  hasAccessAllStudies: true,
  needCguAcceptation: false,
  dateGguAcceptation: false,
  linkCalendly: process.env.URL_CALENDLY,
  cecimUserPayant: false,
  capemUser: false,
  accounts: [],
  geoJsonFields: null,
  communesParcelles: [],
  isPdfMode: false,
  canActivateTrial: false,
  maxErrorNb: 0,
  isReady: false,
};

export const othersSlice = createSlice({
  name: 'others',
  initialState,
  reducers: {
    setMaxErrorNb(state, { payload }) {
      state.maxErrorNb = payload;
      state.isReady = true;
    },
    setBgLogin(state, { payload }) {
      state.bgLogin = payload;
    },
    setCanActivateTrial(state, { payload }) {
      state.canActivateTrial = payload;
    },
    setIsPdfMode(state, { payload }) {
      state.isPdfMode = payload;
    },
    setCommunesParcelles(state, { payload }) {
      state.communesParcelles = payload;
    },
    setGeoJsonFiels(state, { payload }) {
      state.geoJsonFields = payload;
    },
    setCurrentUserOrganization(state, { payload }) {
      state.currentUserOrganizations = payload;
    },
    setDepartements(state, { payload }) {
      state.departements = payload;
    },
    setHasAccessAllStudies(state, { payload }) {
      state.hasAccessAllStudies = payload;
    },
    setCapemUser(state, { payload }) {
      state.capemUser = payload;
    },
    setCecimUserPayant(state, { payload }) {
      state.cecimUserPayant = payload;
    },
    setNeedCguAcceptation(state, { payload }) {
      state.needCguAcceptation = payload;
    },
    setDateGguAcceptation(state, { payload }) {
      state.dateGguAcceptation = payload;
    },
    setAccounts(state, { payload }) {
      state.accounts = payload;
    },
    reset: () => initialState,
  },
});

export const othersSliceActions = othersSlice.actions;

export default othersSlice.reducer;

export const getOrganizationUser = createAsyncThunk(
  'getOrganizationUser',
  async (_, { dispatch }) => {
    try {
      const resp = await Api.get('/v2/backoffice/check_my_organizations');
      dispatch(
        othersSliceActions.setCurrentUserOrganization(resp?.data?.data || [])
      );
      return resp?.data?.data || [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }
);

export const checkCanActivateTrial = createAsyncThunk(
  'checkCanActivateTrial',
  async (_, { dispatch }) => {
    try {
      const {
        data: { trial_available },
      } = await Api.post('/v3/users/check_trial_activation');
      dispatch(othersSliceActions.setCanActivateTrial(trial_available));
      return trial_available;
    } catch (e) {
      console.log(e);
      return e.response;
    }
  }
);

export const getCommunesParcelles = createAsyncThunk(
  'getCommunesParcelles',
  async (fieldIds = [], { dispatch }) => {
    try {
      const communes = await Promise.all(
        fieldIds.map(async (id) => {
          const { data } = await apiV5.get(
            `${process.env.BASE_URL_API}/v5/eval_parcel/zones/commune_parcelle/${id}`
          );
          return data;
        })
      );
      dispatch(othersSliceActions.setCommunesParcelles(communes));
      return communes;
    } catch (e) {
      console.log(e);
      return [];
    }
  }
);

export const getGeoJsonFields = createAsyncThunk(
  'getGeoJsonFields',
  async (fieldIds, { dispatch }) => {
    try {
      const { data } = await axios.get(
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/parcels/terrain/carto?parcelle_ids[]=${_.join(
          fieldIds,
          '&parcelle_ids[]='
        )}`
      );
      dispatch(othersSliceActions.setGeoJsonFiels(data));
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
);

export const saveAccount = createAsyncThunk(
  'saveAccount',
  async ({ company_name, default_end_validity_subscription, id }) => {
    try {
      if (!id) {
        const { data } = await Api.post('/v2/backoffice/accounts/create', {
          company_name,
          default_end_validity_subscription,
          name: company_name,
        });
        return data.data;
      } else {
        const { data } = await Api.put(`/v2/backoffice/accounts/${id}/update`, {
          company_name,
          default_end_validity_subscription,
          name: company_name,
        });
        console.log(data);
        return data.data;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }
);

export const getAccounts = createAsyncThunk(
  'getAccounts',
  async (arg, { dispatch }) => {
    try {
      const { data } = await Api.get(
        `/v2/backoffice/accounts${
          arg?.keyword?.length ? `/search?q=${arg.keyword}` : ''
        }`
      );
      dispatch(othersSliceActions.setAccounts(data?.data || []));
      return data?.data || [];
    } catch (e) {
      console.log(e);
      return null;
    }
  }
);

export const updateDateConstent = createAsyncThunk(
  'updateDateConstent',
  async (date) => {
    try {
      await Api.put('/api/v1/me', { date_consent_cgu_org: date });
    } catch (e) {
      console.log(e);
    }
  }
);

export const saveUserOrganization = createAsyncThunk(
  'saveUserOrganization',
  async ({ data, headers, id }) => {
    const formData = new FormData();
    const decomposed = Object.entries(data);
    for (var i = 0; i < decomposed.length; i++) {
      const [key, value] = decomposed[i];
      if (value) {
        if (isArray(value)) {
          for (var k = 0; k < value.length; k++) {
            formData.append(`${key}[]`, value[k]);
          }
        } else {
          formData.append(key, value);
        }
      }
    }
    try {
      if (id) {
        await axiosFormData.put(`/v2/backoffice/users/${id}/update`, formData, {
          headers,
        });
        return true;
      } else {
        const { data } = await axiosFormData.post(
          '/v2/backoffice/users/create',
          formData,
          { headers }
        );
        if (data?.data) {
          return data.data;
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  }
);

export const getUsersOrganization = createAsyncThunk(
  'getUsersOrganization',
  async ({ orgId, filters }) => {
    try {
      const filtersParams = filters ? buildParamsByJson(filters, true) : '';

      const {
        data: { data: users, meta },
      } = await Api.get(
        `/v2/backoffice/users?org_id=${orgId}&pagination=false&${filtersParams}`
      );
      return { users, meta };
    } catch (e) {
      console.log(e);
      return [];
    }
  }
);

export const getDepartements = createAsyncThunk(
  'getDepartements',
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/backoffice/departements`
      );
      dispatch(othersSliceActions.setDepartements(data));
      return data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }
);

export const getCodesCommuneByDepartement = createAsyncThunk(
  'getCodesCommuneByDepartement',
  async (national_code) => {
    try {
      const { data: codes } = await axios.get(
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/backoffice/departements/${parseInt(
          national_code
        )}/communes/codes_insee`
      );
      return codes;
    } catch (e) {
      console.log(e);
      return [];
    }
  }
);

export const checkIfMailExist = async (mail) => {
  try {
    const { data } = await Api.get(`/api/v1/user_exists?email=${mail}`);
    return data?.exists;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getHasAccessAllStudies = createAsyncThunk(
  'getHasAccessAllStudies',
  async (_, { dispatch }) => {
    try {
      const {
        data: {
          full_access_after_quick_check,
          date_consent_cgu_org,
          need_to_sign_cgv,
        },
      } = await Api.get('/api/v1/check_access_rights');

      dispatch(
        othersSliceActions.setHasAccessAllStudies(full_access_after_quick_check)
      );
      dispatch(othersSliceActions.setDateGguAcceptation(date_consent_cgu_org));
      dispatch(othersSliceActions.setNeedCguAcceptation(need_to_sign_cgv));

      return {
        full_access_after_quick_check,
        date_consent_cgu_org,
        need_to_sign_cgv,
      };
    } catch (e) {
      console.log(e);
      dispatch(othersSliceActions.setHasAccessAllStudies(false));
      return false;
    }
  }
);

export const registerUserV3 = (informations) => {
  return Api.post(`/v3/users/create`, informations).then((res) => {
    return res;
  });
};

export const checkTrialCode = (trialCode) => {
  return Api.post(`/v3/users/check_trial_code`, {
    trial_code: trialCode,
  })
    .then((res) => res)
    .catch((error) => {
      return error.response;
    });
};

export const getUrlResetPassword = (email) => {
  return Api.post('/v3/users/reinit_password', { email })
    .then((res) => res)
    .catch((e) => {
      console.log(e);
      return e.response;
    });
};

export const saveLead = (data) => {
  return Api.post('/v3/leads/create', data)
    .then((res) => res)
    .catch((e) => {
      console.log(e);
      return e.response;
    });
};

export const checkCanCreateUser = (data) => {
  return Api.post('/v3/users/check_demo_creation', data)
    .then((res) => res)
    .catch((e) => {
      console.log(e);
      return e.response;
    });
};

export const activateTrial = (data) => {
  return Api.put('/v3/users/activate_trial', data)
    .then((res) => res)
    .catch((e) => {
      console.log(e);
      return e.response;
    });
};
