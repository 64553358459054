import React, {useEffect, useState} from 'react';
import Map from '@components/Map';
import {connect} from 'react-redux';
import Axe from '@components/Axe';

import {
    deleteParcelId,
    getAnru,
    getAOC,
    getBatiment,
    getChangingMayor,
    getCollectiveStats,
    getCollectiveStatsCartography,
    getConstructionBatiment,
    getDataSpecificParcel,
    getElectricLine,
    getEolienne,
    getHousingShortComing,
    getHousingStat,
    getInseeCode,
    getMayorInformation,
    getMultipleLatLngForTargetsParcels,
    getPC,
    getPerson,
    getPinel,
    getPlu,
    getPopulation,
    getQPV,
    getRisk,
    getRiskCartography,
    getSizeLand,
    getSncfTraject,
    getStatsNewLogement,
    getTourism,
    getTownHeart,
    getZus,
    resetGeometry,
} from '@/store/actions/parcel';
import {getProject} from '@/store/actions/project';

import {withRouter} from 'react-router-dom';

const ProjectShowPdf = ({
                            getProject,
                            my_project,
                            location,
                            person,
                            getCollectiveStatsCartography,
                            DetailledRating,
                            dataParcel,
                            getSncfTraject,
                            getQPV,
                            logement_stats,
                            getEolienne,
                            getChangingMayor,
                            getRating,
                            insee,
                            sncf,
                            eolienne,
                            qpv,
                            match,
                            getDataSpecificParcel,
                            getJourney,
                            risk,
                            journey,
                            getRiskCartography,
                            getCollectiveStats,
                            getMultipleLatLngForTargetsParcels,
                            postProjects,
                            housingStats,
                            getHousingStat,
                            getRisk,
                            getZus,
                            zus,
                            collective,
                            getPopulation,
                            getTownHeart,
                            getTourism,
                            getAOC,
                            getPC,
                            parcelApi_rating,
                            resetGeometry,
                            getAnru,
                            getPlu,
                            deleteParcelId,
                            getConstructionBatiment,
                            construction,
                            plu,
                            anru,
                            pc,
                            getPinel,
                            population,
                            getPerson,
                            getBatiment,
                            getSizeLand,
                            getHousingShortComing,
                            getMayorInformation,
                            size,
                            mayor,
                            housing,
                            stopMyRating,
                            getInseeCode,
                            pinel,
                            collective_cartography,
                            getElectricLine,
                            geometry_parcels,
                            getStatsNewLogement,
                            electric,
                            mayor_changed,
                            resetRating,
                            TownHeart,
                            risk_cartography,
                        }) => {
    // set polygon on map
    const [polygon, setPolygon] = useState();
    // set go to (fitBounds) on map
    const [goTo, setGoTo] = useState();

    // get projects information
    useEffect(() => {
        if (match?.params?.uuid) {
            getProject(match?.params?.uuid);
        }
    }, [match]);

    const handleSelectedParcels = () => {
        let [removed, ...newArray] = my_project?.targets_parcels;
        return newArray;
    };

    // set axes
    const [axes, setAxes] = useState([]);
    const [stateForAxes, setStateForAxes] = useState(false);

    // REFACTOR REMOVE USE PROVIDER /CONTEXT
    useEffect(() => {
        let globalObject = Object.keys(parcelApi_rating)
            .map((key) => {
                return {
                    axe: parcelApi_rating[key]?.data
                        ? parcelApi_rating[key]?.data?.axe_analyse
                        : parcelApi_rating[key]?.axe_analyse,
                    sub: parcelApi_rating[key]?.data?.sous_axe
                        ? parcelApi_rating[key]?.data?.sous_axe
                        : parcelApi_rating[key]?.sous_axe,
                };
            })
            .filter((v) => v.axe && v.sub);
        let uniqAxesAndSubAxesArray = globalObject.reduce(function (
                access,
                current
            ) {
                let findIndex = access.findIndex(function (item) {
                    return item.axe === current.axe;
                });

                if (findIndex == -1) {
                    let obj = Object.assign(
                        {},
                        {
                            axe: current.axe,
                            sub: [current.sub],
                        }
                    );
                    access.push(obj);
                } else {
                    access[findIndex].sub.push(current.sub);
                    [...new Set(access[findIndex].sub)];
                }

                return access;
            },
            []);
        if (
            uniqAxesAndSubAxesArray.some((value) => value.axe === 'Marché Immobilier')
        ) {
            let marketOnly = uniqAxesAndSubAxesArray.filter(
                (value) => value.axe === 'Marché Immobilier'
            );
            let arrayExcludeMarket = uniqAxesAndSubAxesArray.filter(
                (value) => value.axe !== 'Marché Immobilier'
            );
            arrayExcludeMarket.unshift(marketOnly[0]);
            setAxes(arrayExcludeMarket);
        }
    }, [parcelApi_rating]);

    // get geometry parcels
    useEffect(() => {
        if (my_project?.targets_parcels) {
            getMultipleLatLngForTargetsParcels(my_project?.targets_parcels);
            getAnru(handleSelectedParcels(), my_project?.targets_parcels[0]);
            getPlu(handleSelectedParcels(), my_project?.targets_parcels[0]);
            getConstructionBatiment(
                handleSelectedParcels(),
                my_project?.targets_parcels[0]
            );
            getPerson(handleSelectedParcels(), my_project?.targets_parcels[0]);
            getSizeLand(handleSelectedParcels(), my_project?.targets_parcels[0]);
            getBatiment(my_project?.targets_parcels[0]);
            getElectricLine(my_project?.targets_parcels[0]);
            getBatiment(my_project?.targets_parcels[0]);
            getRisk(my_project?.targets_parcels[0]);
            getRiskCartography(my_project?.targets_parcels[0]);
            getSncfTraject(my_project?.targets_parcels[0]);
            getEolienne(my_project?.targets_parcels[0]);
            getDataSpecificParcel(my_project?.targets_parcels[0]);
        }
    }, [my_project]);

    useEffect(() => {
        if (my_project?.targets_parcels)
            getInseeCode(my_project.targets_parcels[0]);
    }, [my_project]);

    useEffect(() => {
        if (insee) {
            getQPV(insee?.national_code_2021);
            getCollectiveStats(insee?.national_code_2021);
            getHousingStat(insee?.national_code_2021);
            getZus(insee?.national_code_2021);
            getCollectiveStatsCartography(insee?.national_code_2021);
            getStatsNewLogement(insee?.national_code_2021);
            getPopulation(insee?.national_code_2021);
            getTownHeart(insee?.national_code_2021);
            getTourism(insee?.national_code_2021);
            getAOC(insee?.national_code_2021);
            getPC(insee?.national_code_2021);
            getHousingShortComing(insee?.national_code_2021);
            getMayorInformation(insee?.national_code_2021);
            getPinel(insee?.national_code_2021);
            getChangingMayor(insee?.national_code_2021);
        }
    }, [insee]);

    // if geometry_parcels exist , setPolygon
    useEffect(() => {
        if (geometry_parcels?.length > 0) {
            let newSet = [...new Set(geometry_parcels)];
            let reverseData = newSet.map((table) =>
                table.map((v) => v.slice().reverse())
            );
            setPolygon(reverseData);
            setGoTo(reverseData);
        }
    }, [geometry_parcels, setPolygon]);

    const handleOrderCard = (subAxe) => {
        let arrayOfResultSameSubAxe = [
            ...new Set(
                Object.keys(parcelApi_rating)
                    .map((dynamicKey) => {
                        return (
                            (parcelApi_rating[dynamicKey]?.data?.sous_axe
                                ? parcelApi_rating[dynamicKey]?.data?.sous_axe
                                : parcelApi_rating[dynamicKey]?.sous_axe) === subAxe &&
                            parcelApi_rating[dynamicKey]
                        );
                    })
                    .filter((v) => v)
            ),
        ];

        // create dynamic key for pass in handleSwitchThemeCard
        arrayOfResultSameSubAxe.forEach(function (item) {
            if (item.data) {
                item.data?.metric ? item.data?.metric : item.data?.metric_commune;
                item.data['args2'] = item.data?.metric_epci && item.data?.metric_epci;
                item.data['args4'] =
                    item.data?.metric_epci_2 && item.data?.metric_epci_2;
                item.data['args3'] =
                    item.data?.metric_commune_2 && item.data?.metric_commune_2;
                item.data['geo'] = item?.cartography?.zone_geographique;
                item.data['legend'] = item?.cartography?.metadata?.legend
                    ? item?.cartography?.metadata?.legend
                    : item?.cartography?.properties?.legend;
            } else {
                item['args1'] = item?.metric_commune
                    ? item.metric_commune
                    : item?.metric;
                item['args2'] = item?.metric_epci && item.metric_epci;
                item['args4'] = item?.metric_epci_2 && item.metric_epci_2;
                item['args3'] = item?.metric_commune_2 && item.metric_commune_2;
                item['geo'] = item?.cartography?.zone_geographique;
                item['legend'] = item?.cartography?.metadata?.legend
                    ? item?.cartography?.metadata?.legend
                    : item?.cartography?.properties?.legend;
            }
        });

        return arrayOfResultSameSubAxe.map((boxCard) =>
            handleSwitchThemeCard(
                boxCard.data?.type_box ? boxCard.data?.type_box : boxCard.type_box,
                boxCard?.data ? boxCard?.data : boxCard
            )
        );
    };

    const handleSwitchThemeCard = (expr, args) => {
        switch (expr) {
            case 'BoxComp1':
                return (
                    <div className="col-span-12 md:col-span-1 px-5 mb-2">
                        <div
                            className={`${
                                args?.handleClick
                                    ? 'cursor-pointer'
                                    : !args?.information && 'hover:opacity-75 cursor-not-allowed'
                            }  flex flex-col justify-between text-xs transition duration-500 ease-in-out transform hover:-translate-y-1 text-gray-600 p-3 bg-white rounded-lg h-full`}
                        >
                            <div className="flex justify-between">
                                <p className="mt-0 text-orange-600" style={{wordBreak: 'break-word'}}>
                                    {args?.titre_box}
                                </p>
                                <i className="text-lg text-gray-500 text-base cursor-pointer ri-information-fill ml-1"></i>
                            </div>
                            <div className="flex flex-col justify-around text-black">
                                <h3 className="font-extrabold">
                                    {args && (
                                        <div className="flex justify-between">
                                            <p className="my-0 text-2xl">
                                                {args?.args1}
                                                <p className="my-0 text-sm">Commune</p>
                                            </p>
                                            <p className="my-0 text-2xl">
                                                {args?.args2}
                                                <p className="my-0 text-sm">EPCI</p>
                                            </p>
                                        </div>
                                    )}
                                </h3>
                                <span className=" text-gray-600 text-xs">
                  {args?.bas_de_box}
                </span>
                            </div>
                        </div>
                    </div>
                );
            case 'BoxComp2':
                return (
                    <div className="col-span-12 md:col-span-2 px-5 mb-2">
                        <div
                            className={`${
                                args?.handleClick
                                    ? 'cursor-pointer'
                                    : !args?.information && 'hover:opacity-75 cursor-not-allowed	'
                            }  flex flex-col justify-between text-xs transition duration-500 ease-in-out transform hover:-translate-y-1 text-gray-600 p-3 bg-white rounded-lg h-full`}
                        >
                            <div className="flex justify-between">
                                <p className="mt-0 text-orange-600" style={{wordBreak: 'break-word'}}>
                                    {args?.titre_box}
                                </p>
                                <div className="has-tooltip">
                                    <i className="text-lg text-gray-500 text-base cursor-pointer ri-information-fill ml-1"></i>
                                </div>
                            </div>
                            <div className="flex flex-col justify-around text-black">
                                <h3 className="font-extrabold">
                                    {args && (
                                        <div className="flex justify-between">
                                            <p className="my-0 text-2xl">
                                                {args?.args1}{' '}
                                                <p className="my-0 text-sm">
                                                    Commune
                                                    <span
                                                        className={`${
                                                            parseInt(args?.args3, 10) > 0
                                                                ? 'text-green-400'
                                                                : 'text-red-600'
                                                        } ml-4`}
                                                    >
                            {args?.args3}
                          </span>
                                                </p>
                                            </p>
                                            <p className="my-0 text-2xl">
                                                {args?.args2}
                                                <p className="my-0 text-sm">
                                                    EPCI
                                                    <span
                                                        className={`${
                                                            parseInt(args?.args4, 10) > 0
                                                                ? 'text-green-400'
                                                                : 'text-red-600'
                                                        } ml-4`}
                                                    >
                            {args?.args4}
                          </span>
                                                </p>
                                            </p>
                                        </div>
                                    )}
                                </h3>
                                <span className="text-gray-600 text-xs">
                  {args?.bas_de_box}
                </span>
                            </div>
                        </div>
                    </div>
                );
            case 'BoxSimple1':
                return (
                    <div className="col-span-12 md:col-span-1 px-5 mb-2">
                        <div
                            className={`${
                                args?.handleClick
                                    ? 'cursor-pointer'
                                    : !args?.information && 'hover:opacity-75 cursor-not-allowed'
                            }  flex flex-col justify-between text-xs transition duration-500 ease-in-out transform hover:-translate-y-1 text-gray-600 p-3 bg-white rounded-lg h-full`}
                        >
                            <div className="flex justify-between">
                                <p className="mt-0 text-orange-600" style={{wordBreak: 'break-word'}}>
                                    {args?.titre_box}
                                </p>
                                <i className="text-lg text-gray-500 text-base cursor-pointer ri-information-fill ml-1"></i>
                            </div>
                            <div className="flex text-black items-end">
                                <h3
                                    className={`${
                                        args?.args1?.length > 20 ? 'text-xs' : ''
                                    } font-extrabold`}
                                >
                                    {args?.args1 ? (args?.args1 ? args.args1 : null) : null}
                                </h3>
                            </div>
                            <h3 className="font-bold text-gray-600 text-xs">
                                {!args?.bas_de_box ? args?.args2 : args.bas_de_box}
                            </h3>
                        </div>
                    </div>
                );
            case 'BoxSimple0':
                return (
                    <div className="col-span-12 md:col-span-1 px-5 mb-2">
                        <div
                            className={`${
                                args?.handleClick
                                    ? 'cursor-pointer'
                                    : !args?.information && 'hover:opacity-75 cursor-not-allowed'
                            }  flex flex-col justify-between text-xs transition duration-500 ease-in-out transform hover:-translate-y-1 text-gray-600 p-3 bg-white rounded-lg h-full`}
                        >
                            <div className="flex justify-between">
                                <p className="mt-0 text-orange-600" style={{wordBreak: 'break-word'}}>
                                    {args?.titre_box}
                                </p>
                                <i className="text-lg text-gray-500 text-base cursor-pointer ri-information-fill ml-1"></i>
                            </div>
                            <div className="flex text-black items-end">
                                <h3 className="font-extrabold">{args?.args1}</h3>
                            </div>
                        </div>
                    </div>
                );
            case 'BoxSimple2':
            default:
        }
    };

    // when component is unmount, reset geometry_parcels
    useEffect(() => {
        return () => {
            resetGeometry();
        };
    }, []);

    return (
        <>
            <a href="/app/assets/config">
                <img className="my-4 w-48 mx-auto" src="../logo.webp"></img>
            </a>
            <div className="text-white bg-blue-darken p-3 w-56">
                <span>QuickCheck</span>
            </div>
            <div className="p-6">
                <h3>{my_project?.address}</h3>
                {my_project?.targets_parcels?.map((v) => (
                    <p>Parcelle {v}</p>
                ))}
                <h3>Introduction : </h3>
                <p> {my_project?.text_introduction}</p>
                <h3>Conclusion : </h3>
                <p> {my_project?.text_conclusion}</p>
            </div>
            <Map
                maximumZoom
                flyTo={goTo ? goTo : null}
                polygon={polygon}
                theme="light"
            />
            <div className="p-5 bg-gray-200">
                {axes &&
                    axes.map((v) => (
                        <Axe
                            quickCheck={true}
                            stateForAxes={stateForAxes}
                            setStateForAxes={setStateForAxes}
                            forceOpen
                            v={v}
                            handleOrderCard={handleOrderCard}
                        />
                    ))}
            </div>
            <img className="my-4 w-32 absolute right-2" src="../logo.webp"></img>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        my_project: state.project.my_project,
        geometry_parcels: state.parcelApi.rating.geometry_parcels,
        insee: state.parcelApi.rating.insee,
        parcelApi_rating: state.parcelApi.rating,
    };
};
export default withRouter(
    connect(mapStateToProps, {
        getProject,
        getSncfTraject,
        getQPV,
        getZus,
        getInseeCode,
        getPopulation,
        getTownHeart,
        getTourism,
        getAOC,
        getCollectiveStatsCartography,
        getElectricLine,
        getHousingStat,
        getEolienne,
        getDataSpecificParcel,
        getRisk,
        getRiskCartography,
        getCollectiveStats,
        getPC,
        deleteParcelId,
        getBatiment,
        getPlu,
        getAnru,
        getConstructionBatiment,
        getPerson,
        getSizeLand,
        getHousingShortComing,
        getStatsNewLogement,
        getMayorInformation,
        getMultipleLatLngForTargetsParcels,
        resetGeometry,
        getPinel,
        getChangingMayor,
    })(ProjectShowPdf)
);
