import Api from "../api/Api";
import useSWR from "swr";
import {getFetcher} from "../api/fetcher";

export const createMarketSurvey = (data, callback) => {

    Api.post('api/v1/market_surveys/', {
        market_survey: {
            ...data
        }
    })
        .then(res => {
            callback(res.data.data.id)
        })
}

export const getMarketSurvey = (market_survey_id, callback) => {
    Api.get(`api/v1/market_surveys/${market_survey_id}`)
        .then(r => r.data.data)
        .then(() => callback())
}

export const useMarketSurvey = (market_survey_id) => {
    const {data, error} = useSWR(`api/v1/market_surveys/${market_survey_id}`, getFetcher)

    if (error) {
        console.error(`failed to fetch Market Survey ${market_survey_id}`, error)
    }


    return {
        ms: data,
        isError: error,
        isLoading: !error && !data
    }
}

export const updateMarketSurvey = (id, data, callback) => {
    Api.put(`api/v1/market_surveys/${id}`,
        {
            market_survey: {
                ...data
            }
        })
        .then(callback)
}

export const updateMSComments = (survey_id, fieldName, fieldValue) => {
    Api.put(`/api/v1/market_surveys/${survey_id}/comments`,
        {
            field_name: fieldName,
            field_value: fieldValue
        }
    ).then(res => res.data)
}