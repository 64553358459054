import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { buildParamsByJson } from '../actions/ciblage/parcel';
import Api, { apiV5 } from '../../api/Api';
import axios from 'axios';

const initialState = {
  programmes: [],
  selectedProgrammes: [],
  programmesCarto: null,
  selectedProgrammesCarto: [],
  filteredProgrammes: [],
  openDetailProgramme: false,
  currentViewStreetCoord: null,
  filterProgramme: {
    filter_geo: 'closest',
    filter_tva_reduite: 'toutes_tva',
    filter_type_programme: 'collectif',
  },
  buildingPermitGeoData: null,
  marketSurvey: null,
  parcelData: null,
  onlySelelectedProgrammes: [],
};

export const programmeSlice = createSlice({
  name: 'programme',
  initialState,
  reducers: {
    setOnlySelelectedProgrammes(state, { payload }) {
      state.onlySelelectedProgrammes = payload;
    },
    setParcelData(state, { payload }) {
      state.parcelData = payload;
    },
    setMarketSurvey(state, { payload }) {
      state.marketSurvey = payload;
    },
    setProgrammes(state, { payload }) {
      state.programmes = payload;
    },
    setProgrammesCarto(state, { payload }) {
      state.programmesCarto = payload;
    },
    setSelectedProgrammesCarto(state, { payload }) {
      state.selectedProgrammesCarto = payload;
    },
    setSelectedProgrammes(state, { payload }) {
      state.selectedProgrammes = payload;
    },
    setBuildingPermitGeoData(state, { payload }) {
      state.buildingPermitGeoData = payload;
    },
    setCurrentViewStreetCoord(state, { payload }) {
      state.currentViewStreetCoord = payload;
    },
    setFilteredProgrammes(state, { payload }) {
      state.filteredProgrammes = payload;
    },
    setFilterProgramme(state, { payload }) {
      state.filterProgramme = payload;
    },
    setOpenDetailProgramme(state, { payload }) {
      state.openDetailProgramme = payload;
    },
    reset: () => initialState,
  },
});

export const programmeSliceActions = programmeSlice.actions;

export default programmeSlice.reducer;

export const getProgrammesEDM = createAsyncThunk(
  'getProgrammesEDM',
  async ({ parcel_ids, metadata, activePrograms }, { dispatch }) => {
    try {
      const filters = {
        ...metadata.filterProgramme,
        bonus_tran_ids:
          activePrograms || metadata.filterProgramme.bonus_tran_ids,
        parcelle_ids: parcel_ids,
      };

      const { data } = await apiV5.get(
        `${
          process.env.BASE_URL_API
        }/v5/eval_parcel/rapport_etude/liste_programmes/carto${buildParamsByJson(
          filters
        )}`
      );

      dispatch(programmeSliceActions.setProgrammesCarto(data));
    } catch (e) {
      console.error(e); // It's better to log an error for debugging
      return null;
    }
  }
);

export const getPermitContruire = createAsyncThunk(
  'getPermitContruire',
  async ({ parcel_ids, filter_geo }, { dispatch }) => {
    dispatch(programmeSliceActions.setBuildingPermitGeoData(null));
    try {
      const { data } = await axios.get(
        `${
          process.env.BASE_URL_API
        }/v4/eval_parcel/rapport_etude/permis_construire_liste_programmes/carto${buildParamsByJson(
          { parcelle_ids: parcel_ids, ...(filter_geo ? { filter_geo } : {}) }
        )}`
      );

      dispatch(programmeSliceActions.setBuildingPermitGeoData(data));

      return data;
    } catch (e) {
      console.log(e);
      dispatch(programmeSliceActions.setBuildingPermitGeoData(null));
      return null;
    }
  }
);

export const getCurrentSurvey = createAsyncThunk(
  'getCurrentSurvey',
  async (survey_id, { dispatch }) => {
    try {
      const { data } = await Api.get(`/api/v1/market_surveys/${survey_id}`);
      dispatch(programmeSliceActions.setMarketSurvey(data));
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
);

export const getCurrentSurveyByToken = createAsyncThunk(
  'getCurrentSurveyByToken',
  async (surveyToken, { dispatch }) => {
    try {
      const req = await fetch(
        `${process.env.BASE_URL}/v3/market_surveys/${surveyToken}`
      );
      const data = await req.json();
      dispatch(programmeSliceActions.setMarketSurvey(data));
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
);

export const getProgrammes = createAsyncThunk(
  'getProgrammes',
  async (params, { dispatch }) => {
    try {
      dispatch(programmeSliceActions.setProgrammes([]));

      const {
        data: { data },
      } = await apiV5.get(
        `${
          process.env.BASE_URL_API
        }/v5/eval_parcel/rapport_etude/liste_programmes${buildParamsByJson(
          params
        )}`
      );

      const { data: cartoData } = await apiV5.get(
        `${
          process.env.BASE_URL_API
        }/v5/eval_parcel/rapport_etude/liste_programmes/carto${buildParamsByJson(
          params
        )}`
      );

      dispatch(programmeSliceActions.setProgrammesCarto(cartoData));

      dispatch(programmeSliceActions.setProgrammes(data));

      return data;
    } catch (e) {
      console.log(e);
      dispatch(programmeSliceActions.setProgrammes([]));
      return null;
    }
  }
);

export const getDetailsProgramme = createAsyncThunk(
  'getProgrammes',
  async (tran_id) => {
    try {
      const {
        data: { data },
      } = await apiV5.get(
        `${process.env.BASE_URL_API}/v5/eval_parcel/rapport_etude/details_programme/${tran_id}`
      );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
);
