import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {isLoading, isntLoading} from '@/store/actions/loader';
import {useHistory, useParams, withRouter} from 'react-router-dom';
// list of actions from rating redux reducer
import {
    deleteParcelId,
    getAnru,
    getAOC,
    getBatiment,
    getChangingMayor,
    getCollectiveStats,
    getCollectiveStatsCartography,
    getConstructionBatiment,
    getDataSpecificParcel,
    getElectricLine,
    getEolienne,
    getHousingShortComing,
    getHousingStat,
    getInseeCode,
    getJourney,
    getLogementsDisponibles,
    getMayorInformation,
    getMultipleLatLngForTargetsParcels,
    getPC,
    getPerson,
    getPinel,
    getPlu,
    getPopulation,
    getQPV,
    getRating,
    getRisk,
    getRiskCartography,
    getSizeLand,
    getSncfTraject,
    getStatsNewLogement,
    getTourism,
    getTownHeart,
    getZus,
    postProjects,
    postRating,
    resetGeometry,
    resetRating,
    signUpUser,
    stopMyRating,
} from '@/store/actions/parcel';

// import 'remixicon/fonts/remixicon.css';
// import '@/stylesheets/page.css';
import Button from '@components/Button';
import BannerAlert from '@components/BannerAlert';
import DataVisualisationContainer from "../../components/molecules/DataVisualisations/DataVisualisationContainer";
import AxesContainer from "../../components/molecules/AxesContainer";
import SelectedParcelsContainer from "../../components/Parcels/SelectedParcelsContainer";
import {useModal} from "../../hooks/useModal";
import Api from "../../api/Api";
import useSWR from "swr";
import useResponsive from "../../hooks/UseResponsive";
import {useSelectedParcels} from "../../hooks/useSelectedParcels";
import {createMarketSurvey, getMarketSurvey, updateMarketSurvey} from "../../services/market-survey";
import {log} from "logrocket";
import {updateRating} from "../../_services/rating-service";
import {callback} from "chart.js/helpers";


const handleSwitchMethodToUseInMap = (titre_box) => {
    switch (titre_box) {
        case 'Accès Gare Nationale':
            return handleSncf;
        case "Nombre d'habitants":
            return handlePopulationCarto;
        case 'Date de dernière construction du/des bâtiment(s)':
            return handleConstructionCarto;
        case 'Nombre de programmes commercialisés':
            return handleProgramsCarto;
        case 'Nombre de logements collectifs':
            return handleCollectiveCarto;
        case 'Zone ANRU':
            return handleAnruCarto;
        case 'Présence Éoliennes':
            return handleEolienneCarto;
        case 'Risques naturelles':
            return handleRiskCarto;
        default:
            return null;
    }
};


const DetailledRating = (props) => {
        // properties use in detailled rating
        const {
            location,
            dataParcel,
            insee,
            stopMyRating,
            my_rating,
        } = props;


        const history = useHistory();
        let EmailInputRef = useRef();
        const {setFieldIds, setMainParcelID} = useSelectedParcels()

        const [maxHeight, setMaxHeight] = useState(300)
        const [openNotice, setOpenNotice] = useState(true)
        const [openModalMobile, setOpenModalMobile] = useState(false);
        const [setUpEmail, handleSetUpEmail] = useState(false);
        const [arrayEmail, setArrayEmail] = useState([]);

        const [offset, setOffset] = useState(0)
        useEffect(() => {
            setOffset(document.getElementById('navbar')?.offsetHeight + document.getElementById('bottom-navbar')?.offsetHeight)
        })


        const [projectInformations, setProjectsInformations] = useState({
            title: 'Mon projet',
            text_introduction: null,
            text_conclusion: null,
            emails_to_notify: null,
        });

        const isMobileMode = useResponsive();
        let {id} = useParams();

        const {currentRatingID, setCurrentRatingID, setIsQuickCheckModalOpened, setRatingIds} = useModal()
        const {mainParcelID, setSelectedParcelsIds} = useSelectedParcels()

        const [epciName, setEpciName] = useState("")

        useEffect(() => {
            setCurrentRatingID(id)
            setRatingIds([id])
            setSelectedParcelsIds([])
        }, [])

        const getMainParcelInfo = async (id) => {
            return Api.get(`${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${id}`)
                .then((res) => res.data)
        }

        useEffect(() => {
            if (mainParcelID.length > 0) {
                getMainParcelInfo(mainParcelID)
                    .then(({data}) => {
                        if (data) {
                            setEpciName(data.attributes.epci_name)
                        }
                    })
            }
            return () => {
            };
        }, [mainParcelID])


        const setRatingStatusToProject = () => {
            updateRating(currentRatingID, {land: {status: 3}})
                .then(() => {
                    createMarketSurvey({rating_id: currentRatingID, comments: {}}, (id) => {
                        history.push(`/market-survey/${id}`)
                    })
                })
        }

        const handleTime = (e) => {
            return Math.round(
                Object.keys(e)
                    .map((key) => e[key].properties.travelTime)
                    .filter((v) => v) / 60
            );
        };
        const handleOpenModalMobile = () => {
            setOpenModalMobile(true);
        };


        const handleChangeInformationsProjects = (e) => {
            setProjectsInformations((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        };

        // handle change email informations for projects
        const handleChangeEmail = (event) => {
            handleChangeInformationsProjects(event);
        };
        const handleClick = () => {
            // create data payload
            let parsedEmails = arrayEmail.join();
            let result = `{${parsedEmails}}`;
            let data = {
                project: {
                    title: projectInformations?.title,
                    text_introduction: projectInformations?.text_introduction,
                    text_conclusion: projectInformations?.text_conclusion,
                    emails_to_notify: result,
                    rating_id: my_rating.rating_id,
                },
            };
            // handle submit data
            handleSubmit(data);
            // redirect to home when project is created
            setRedirectHome(true);
        };


        const handleSwitchMethodToUseInMap = (titre_box) => {
            switch (titre_box) {
                case 'Accès Gare Nationale':
                    return handleSncf;
                case "Nombre d'habitants":
                    return handlePopulationCarto;
                case 'Date de dernière construction du/des bâtiment(s)':
                    return handleConstructionCarto;
                case 'Nombre de programmes commercialisés':
                    return handleProgramsCarto;
                case 'Nombre de logements collectifs':
                    return handleCollectiveCarto;
                case 'Zone ANRU':
                    return handleAnruCarto;
                case 'Présence Éoliennes':
                    return handleEolienneCarto;
                case 'Risques naturelles':
                    return handleRiskCarto;
                default:
                    return null;
            }
        };

        const handleSwitchStatus = () => {
            if (data?.status) {
                // parse int to string to pass to switch case (https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Statements/switch)
                // my_rating?.status.toString();
                switch (data?.status.toString()) {
                    case '1':
                        return (
                            <span
                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-sm ml-3 bg-orange-400 text-white">Quick check</span>
                        );
                    case '2':
                        return (
                            <span
                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-sm ml-3 bg-blue-400 text-white">Éval Détaillée</span>
                        );
                    case '3':
                        return (
                            <span
                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-sm ml-3 bg-green-400 text-white">Étude</span>
                        );
                    case '4':
                        return (
                            <span
                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-sm ml-3 bg-red-400 text-white">Abandonné</span>
                        );
                    default:
                        return null;
                }
            }
        };

        const giveUpRating = () => {
            Api.get(`/ratings/stop_rating/${currentRatingID}.json`)
                .then(() => {
                    history.push('/ratings/list')
                })
            // stopMyRating(currentRatingID);
        };

        // handle Email add person
        // if project?.informations exist and setUpEmail is true
        // setArrayEmail , and setProjectInformations to null after this.
        // return setUpEmail to false after
        // purge the email input too
        useEffect(() => {
            if (projectInformations?.emails_to_notify && setUpEmail) {
                setArrayEmail((prevState) => [
                    ...prevState,
                    projectInformations?.emails_to_notify,
                ]);
                setProjectsInformations((prevState) => ({
                    ...prevState,
                    emails_to_notify: null,
                }));
                handleSetUpEmail(false);
                EmailInputRef.current.value = '';
            }
        }, [projectInformations, setUpEmail, setArrayEmail, handleSetUpEmail]);


        useEffect(() => {
            setMaxHeight(document.getElementById('navbar')?.clientHeight + document.getElementById('bottom-navbar')?.clientHeight + document.getElementById('banner-alert')?.clientHeight + 70)
        })


        const handleAccessToMarketSurvey = (market_survey_id = null) => {
            !!market_survey_id
                ? getMarketSurvey(market_survey_id, (ms) => {
                    history.push(`/market-survey/${ms.id}`)
                })
                : updateRating(currentRatingID, {land: {status: 3}})
                    .then(() => {
                        createMarketSurvey({rating_id: currentRatingID, comments: {}}, (id) => {
                            history.push(`/market-survey/${id}`)
                        })
                    })
        }

        const fetcher = url => Api.get(url).then(res => res.data)
        const {
            data,
            error
        } = useSWR(() => currentRatingID ? `${process.env.BASE_URL}/ratings/${currentRatingID}.json` : null, fetcher)

        useEffect(() => {
            if (data && data.targets_parcels) {
                setFieldIds(targets_parcels)
                setMainParcelID(pivot_parcel_id)
            }
        }, [data])

        if (!data) {
            return <div>Loading...</div>
        }

        if (error) {
            return <div>Loading failed...</div>
        }

        const {pivot_parcel_id, targets_parcels, market_survey_id} = data

        return (
            <div style={{paddingTop: `${offset}px`}}>
                {openNotice && (
                    <BannerAlert setOpenNotice={setOpenNotice} color={{green: true}}>Vous commencez maintenant
                        l'évaluation complète</BannerAlert>
                )}
                <div className={'flex flex-col'}>
                    <div className=" grid grid-cols-12 md:grid-cols-1 lg:grid-cols-2 h-full overflow-hidden">
                        <div
                            style={{height: `calc( 100vh - ${maxHeight}px)`}}
                            className=" col-span-12 md:col-span-1 bg-white flex flex-col justify-between overflow-scroll">
                            <div className="bg-gray-50 col-span-6 relative h-full flex flex-col justify-between"
                                 style={{boxShadow: '0 -10px 10px 0 rgba(0, 0, 0, 0.1)'}}>
                                <div>
                                    <div className={'sticky top-0 z-10 bg-white'}>
                                        <div className="flex w-full justify-between items-center">
                                            <div className="flex mt-4 items-center">
                                                <h3 className="ml-4">Étude</h3>
                                                <div className="ml-3">
                                                    {data.status && handleSwitchStatus()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-between mb-3 items-end">
                                            <div className="flex flex-col ml-4">
                                                <span className="leading-4">{data.address}</span>
                                                <span className="leading-4">{`${data.postcode || ''} ${data.town}`}</span>
                                                <span className={'text-xs font-semibold'}>EPCI : {epciName}</span>
                                            </div>


                                            <button
                                                type="button"
                                                onClick={() => handleAccessToMarketSurvey(market_survey_id)}
                                                className="inline-flex items-center px-5 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                                Accès à mon étude de marché
                                            </button>
                                            <span
                                                className="cursor-pointer mr-3 my-0 text-blue-700 text-xs flex items-center underline"
                                                onClick={() => setIsQuickCheckModalOpened(true)}>Voir le quick check</span>
                                        </div>
                                    </div>


                                    {data.status === 3 && data.pdf_url && (
                                        <a
                                            href={`https://balder-pi.vercel.app/api/render/pdf?url=${data?.pdf_url}`}
                                            className="ml-4 my- text-xs text-blue-800 hover:scale-110 cursor-pointer"
                                            target={`_blank`}
                                        >
                                            Votre note de synthèse
                                        </a>
                                    )}

                                    {targets_parcels && (
                                        <>
                                            <SelectedParcelsContainer/>
                                            <AxesContainer
                                                parcel_id={targets_parcels[0]}
                                                insee_code={insee?.national_code_2021}
                                                is_quick_check={false}
                                            />
                                        </>
                                    )}
                                    {isMobileMode && (
                                        <div className="flex justify-center mb-4">
                                            <div className="w-2/4">
                                                <Button
                                                    handleClick={handleOpenModalMobile}
                                                    title="Voir la carte"
                                                    type="button"
                                                    theme="primary"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    // ref={second}
                                    className="fixed bottom-0 flex flex-row bg-white w-2/4 justify-center "
                                >
                                    <div className="mx-4 my-2">
                                        {data?.status !== 3 && data?.status !== 4 ? (
                                            <Button
                                                handleClick={giveUpRating}
                                                theme="light"
                                                title="Abandonner ce terrain"
                                            />
                                        ) : (
                                            data?.status === 3 && (
                                                <Button
                                                    handleClick={giveUpRating}
                                                    type="button"
                                                    theme="light"
                                                    title="Abandonner le projet"
                                                />
                                            )
                                        )}
                                    </div>
                                    <div className="col-span-5 col-start-7 mx-4 my-2">
                                        {data?.status !== 3 && data?.status !== 4 ? (
                                            <Button
                                                type="button"
                                                handleClick={() => setRatingStatusToProject()}
                                                theme="primary"
                                                title="Lancer l'étude de marché"
                                            />
                                        ) : (
                                            data?.status === 3 && (
                                                <>
                                                    <button
                                                        onClick={() => handleAccessToMarketSurvey(market_survey_id)}
                                                        className="text-white text-sm font-poppins storybook-button storybook-button--primary p-4 border-2"
                                                    >
                                                        Accès à mon étude de marché
                                                    </button>
                                                </>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataVisualisationContainer parcels={dataParcel}/>
                    </div>
                </div>
            </div>
        );
    }
;

// mapping redux state to become props for component
const mapStateToProps = (state) => {
    return {
        journey: state.parcelApi.rating.journey,
        insee: state.parcelApi.rating.insee,
        sncf: state.parcelApi.rating.sncf,
        qpv: state.parcelApi.rating.qpv,
        eolienne: state.parcelApi.rating.eolienne,
        dataParcel: state.parcelApi.rating.dataParcel,
        construction: state.parcelApi.rating.construction,
        plu: state.parcelApi.rating.plu,
        anru: state.parcelApi.rating.anru,
        pc: state.parcelApi.rating.pc,
        population: state.parcelApi.rating.population,
        rating_id: state.parcelApi.rating.rating_id,
        zus: state.parcelApi.rating.zus,
        person: state.parcelApi.rating.person,
        mayor: state.parcelApi.rating.mayor,
        size: state.parcelApi.rating.size,
        housing: state.parcelApi.rating.housing,
        pinel: state.parcelApi.rating.pinel,
        housingStats: state.parcelApi.rating.housing_stats,
        electric: state.parcelApi.rating.electric,
        mayor_changed: state.parcelApi.rating.mayor_changed,
        risk: state.parcelApi.rating.risk,
        parcelApi_rating: state.parcelApi.rating,
        my_rating: state.parcelApi.my_rating,
        TownHeart: state.parcelApi.rating.TownHeart,
        collective: state.parcelApi.rating.collective,
        logement: state.parcelApi.rating.logement,
        logements_disponibles: state.parcelApi.rating.logements_disponibles,
        batiment: state.parcelApi.rating.batiment,
        risk_cartography: state.parcelApi.rating.risk_cartography,
        collective_cartography: state.parcelApi.rating.collective_cartography,
        geometry_parcels: state.parcelApi.rating.geometry_parcels,
        tablesData: state.table_rating,
    };
};

// mapping redux actions to become props function dispatch for component
export default withRouter(
    connect(mapStateToProps, {
        signUpUser,
        getSncfTraject,
        getQPV,
        getZus,
        getInseeCode,
        getPopulation,
        getTownHeart,
        getTourism,
        getAOC,
        getCollectiveStatsCartography,
        getJourney,
        getElectricLine,
        getHousingStat,
        getEolienne,
        getDataSpecificParcel,
        resetRating,
        getRisk,
        getRiskCartography,
        getCollectiveStats,
        stopMyRating,
        postRating,
        getRating,
        getPC,
        deleteParcelId,
        getBatiment,
        getPlu,
        getAnru,
        getConstructionBatiment,
        getPerson,
        postProjects,
        getSizeLand,
        getHousingShortComing,
        getStatsNewLogement,
        getLogementsDisponibles,
        getMayorInformation,
        getMultipleLatLngForTargetsParcels,
        resetGeometry,
        isLoading,
        isntLoading,
        getPinel,
        getChangingMayor,
    })(DetailledRating)
);
