import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import SwitchInput from '../../atoms/switch-input/SwitchInput';
import { useDispatch, useSelector } from 'react-redux';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';
import cx from 'classnames';
import FilterMarcheImmobiler from '../filter-marche-immobilier/FilterMarcheImmobilier';
import FilterTerritoire from '../filter-territoire/FilterTerritoire';
import useDebounce from '../../../_helpers/useDebounce';
import { getCommuneNationalCodesByFilters } from '../../../store/actions/ciblage/cibler';

const CustomAccordionItem = ({
  enabled,
  onChange,
  label,
  name,
  children,
  onClickHead,
  expanded,
}) => {
  return (
    <AccordionItem uuid={name} dangerouslySetExpanded={expanded}>
      <AccordionItemHeading onClick={() => onClickHead && onClickHead(name)}>
        <AccordionItemButton>
          <span className="flex items-center justify-between w-full">
            <span
              className={cx('opacity-50', {
                'font-semibold opacity-100': enabled,
              })}
            >
              {label}
            </span>
            <SwitchInput value={enabled} name={name} onChange={onChange} />
          </span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>{children}</AccordionItemPanel>
    </AccordionItem>
  );
};

export default function FormulaireCibler() {
  const { filtersCibler } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();
  const [expandAccordion, setExpandAccordion] = useState({
    setFiltersCiblerIsEnabledMarcheImmobiler: false,
    setFiltersCiblerIsEnabledTerritoire: false,
  });

  const handleChange = useCallback(
    (value, name) => {
      dispatch(ciblageCreateActions[name](value));
      setExpandAccordion((state) => ({
        ...state,
        [name]: expandAccordion[name] || value,
      }));
    },
    [expandAccordion]
  );

  const handleClickHeadAccordionItem = (name) => {
    setExpandAccordion((state) => ({ ...state, [name]: !state[name] }));
  };

  useDebounce(
    () => {
      dispatch(getCommuneNationalCodesByFilters());
    },
    [filtersCibler],
    0
  );

  return (
    <div className="cibler-inputs">
      <h2 className="font-semibold">
        Définissez vos critères de CIBLAGE / Commune(s)
      </h2>
      <Accordion
        allowZeroExpanded
        className="mt-4 border-1 border-gray-500 rounded-md"
      >
        <CustomAccordionItem
          name="setFiltersCiblerIsEnabledMarcheImmobiler"
          onChange={handleChange}
          label=" Marché Immobilier"
          enabled={filtersCibler.isEnabledMarcheImmobilier}
          onClickHead={handleClickHeadAccordionItem}
          expanded={expandAccordion.setFiltersCiblerIsEnabledMarcheImmobiler}
        >
          <FilterMarcheImmobiler />
        </CustomAccordionItem>
        <CustomAccordionItem
          name="setFiltersCiblerIsEnabledTerritoire"
          onChange={handleChange}
          label="Territoire"
          enabled={filtersCibler.isEnabledTerritoire}
          onClickHead={handleClickHeadAccordionItem}
          expanded={expandAccordion.setFiltersCiblerIsEnabledTerritoire}
        >
          <FilterTerritoire />
        </CustomAccordionItem>
      </Accordion>
    </div>
  );
}
