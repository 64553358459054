import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getMyTargets } from '@/store/actions/parcel';
import { LineChart } from 'react-chartkick';
import 'chartkick/chart.js';
import Background from '../../../../assets/images/background.svg';
import Button from '@components/Button';
import MapLogo from '../../../../assets/images/map.jpeg';
import ProjectCard from '@components/projectCard';
import useSWR from 'swr';
import Api from '../../api/Api';
import { useAuth } from '../../hooks/useAuth';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { ciblageCreateActions } from '../../store/slices/ciblageCreate';

const Target = (props) => {
  const {
    primary,
    label,
    getMyTargets,
    apiTest,
    size,
    mode,
    backgroundColor,
    targets,
  } = props;

  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setOffset(
      document.getElementById('navbar')?.offsetHeight +
        document.getElementById('bottom-navbar')?.offsetHeight
    );
  });
  const dispatch = useDispatch();

  const history = useHistory();

  Chartkick.options = {
    colors: ['#F8A85A', '#2A9DE7'],
  };

  const fetcher = (url) => Api.get(url).then((res) => res.data);

  const { data: targetsData, errors } = useSWR(
    `${process.env.BASE_URL}/targets/show_last_five.json`,
    fetcher
  );

  const { data: targetStats, errors: statsError } = useSWR(
    `${process.env.BASE_URL}/targets/stats.json`,
    fetcher
  );

  const { data: targetsGraphData, errors: targetsGraphDataErrors } = useSWR(
    `${process.env.BASE_URL}/targets/graph.json`,
    fetcher
  );

  const handleClickNewCiblage = () => {
    dispatch(ciblageCreateActions.reset());
  };

  if (!targetsData || !targetStats || !targetsGraphData) {
    return <div>Loading...</div>;
  }

  if (targetsData.error || statsError || targetsGraphDataErrors) {
    return <div>Loading failed...</div>;
  }

  const goToTarget = (targetId) => {
    history.push(`/ciblage/${targetId}`);
  };

  return (
    <div
      className="bg-blue-primary h-full"
      style={{ paddingTop: `${offset}px` }}
    >
      <div className="container mx-auto">
        <div className="w-full grid md:grid-cols-11 col-span-12 gap-6">
          <div className="grid grid-cols-2 col-span-12 md:col-span-4 gap-6 mt-8 mb-8 min-content">
            <div className="bg-white shadow-lg rounded-xl p-6 col-span-2">
              <h2 className="text-lg font-extrabold text-blue-500">
                {targetStats.request_count}
              </h2>
              <p className="font-bold mb-0">Ciblages</p>
              <span className="text-sm text-gray-500">
                Sur les 4 dernières semaines, vous avez ciblé{' '}
                {targetStats.nb_result_last_4_weeks} parcelles sur{' '}
                {targetStats.nb_communes_targeted_last_4_weeks} communes en
                France
              </span>
            </div>
            <div
              style={{
                height: '180px',
                background: `url(${MapLogo})`,
                backgroundSize: 'cover',
              }}
              className="relative card_after grid col-span-2  items-center text-white shadow-lg rounded-xl p-4"
            >
              <div className="z-50">
                <Button
                  handleClick={handleClickNewCiblage}
                  to="/ciblage/new"
                  title="Nouveau ciblage"
                />
              </div>
            </div>
            <div className="opacity-75 cursor-not-allowed hover:opacity-25 transform transition duration-500  grid col-span-2 bg-white shadow-lg rounded-xl">
              <div
                className="content-header rounded-t-lg w-full"
                style={{
                  background: `url(https://images.unsplash.com/photo-1474405273094-b668656721eb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2232&q=80)`,
                  backgroundSize: 'cover',
                  height: 200,
                }}
              ></div>
              <div className="content p-4">
                <p className="font-bold">Réalisez de bons ciblages</p>
                <span className="text-sm text-gray-400">
                  Cette Vidéo de 45 secondes vous présente des éléments pour
                  vous aider à réaliser vos premiers ciblages de parcelles.
                </span>
              </div>
            </div>
          </div>
          <div
            className="grid col-span-12 grid-cols-2 md:col-span-7 gap-6 mt-8 mb-8  "
            style={{ height: 'min-content' }}
          >
            <div className="col-span-2" style={{ height: 'min-content' }}>
              <div
                style={{
                  backgroundImage: `url(${Background}), linear-gradient(to bottom, #1d3e6c, #335f9c 100%)`,
                }}
                className="w-full mt-5 p-5 shadow-2xl rounded-lg lg:prose-xl flex flex-col items-center"
              >
                {/* <Table /> */}
                <LineChart data={targetsGraphData} legend={true} />
              </div>
            </div>
            <div className="col-span-2">
              {targetsData?.length > 0 ? (
                <div className="bg-white shadow-lg p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <h2>Derniers ciblages</h2>
                  </div>
                  {targetsData &&
                    targetsData.map((target, index) => {
                      return (
                        <div
                          key={`target-${index}`}
                          onClick={() => goToTarget(target.id)}
                          className="p-4 border-1 border-gray-400 flex justify-between items-center cursor-pointer hover:bg-gray-100 transition-colors duration-200"
                        >
                          <div className="text-sm">
                            Ciblage du{' '}
                            {new Date(target.evaluate_date).toLocaleDateString(
                              'fr-FR'
                            )}{' '}
                            - {target.nb_results} parcelles -{' '}
                            <span className="font-medium">
                              {target.name || target.address}
                            </span>
                          </div>
                          <ChevronRightIcon className="w-6 h-6" />
                        </div>
                      );
                    })}

                  <div className="mt-4 flex justify-center">
                    <Button
                      to={'/ciblage/list'}
                      title="Voir tous les ciblages"
                    />
                  </div>
                </div>
              ) : (
                <ProjectCard
                  subtitle="Voir tous les ciblages"
                  to={'/ciblage/new'}
                  title="Derniers ciblages"
                  button
                  buttonTitle="Lancer votre premier ciblage"
                />
              )}
            </div>
            {/* <Table /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    apiTest: state.apiTest,
    targets: state.parcelApi.targets,
  };
};

export default connect(mapStateToProps, { getMyTargets, getMyTargets })(Target);
