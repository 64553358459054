export const SINGLE_INPUT_TABLE = 'single_input_table';
export const DUAL_INPUT_TABLE = 'dual_input_table';
export const MERGED_ROW_TABLE = 'MERGED_ROW_TABLE';
export const LIST_BOXES = 'list_boxes';
export const EDUCATION_SECTION = 'education_section';
export const MOBILITY = 'mobilite';
export const WIP = 'wip';
export const MAP_PROGRAMME = 'marketSurvey';

export const VERTICAL_BAR_CHART = 'vertical_bar_chart';
export const LINE_CHART = 'line_chart';
export const BUBBLE_CHART = 'bubble_chart';

export const MAP = 'map';
export const MS_SHOW_SELECTED_PROGRAM = 'ms_show_selected_program';
export const CONTENT = 'content';
export const POI_MAP = 'poi_map';
export const NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI =
  'nearest_shops_and_public_equipment_poi';

export const PROJECT_NAME = 'project_name';
export const RESUME = 'resume';
export const RESERVATION = 'reservation';
export const PRICE_CHANGE = 'price_change';
export const OFFER_AVAILABLE = 'offer_available';
export const DETAILED_INFORMATION_1 = 'detailed_information_1';
export const DETAILED_INFORMATION_2 = 'detailed_information_2';
export const DETAILED_INFORMATION_3 = 'detailed_information_3';
export const FIELD_ATTRIBUTES = 'field_attributes';
export const NEIGHBORHOOD = 'neighborhood';
export const CSP = 'csp';
export const FROM_25_TO_39_YO = 'from_25_to_39_yo';
export const EDUCATION = 'education';
export const NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT =
  'nearest_shops_and_public_equipment';
export const NEW_PROGRAMS = 'new_programs';
export const NEW_ON_SALE = 'new_on_sale';
export const NEW_BOOKING_BY_COUNTRY = 'new_booking_by_country';
export const NEW_PRICE_CHANGE = 'new_price_change';
export const NEW_IN_AREA_CITY_PRICE = 'new_in_area_city_price';
export const NEW_BUYER_TYPE = 'new_buyer_type';
export const VACANT_HOUSING = 'vacant_housing';
export const NEW_RESERVATION_BY_VAT_TYPE = 'new_reservation_by_vat_type';
export const NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES =
  'net_reservations_by_epci_municipalities';
export const PRICE_EVOLUTION_2018 = 'price_evolution_2018';
export const OLD_HOUSING_TREND = 'old_housing_trend';
export const RENT_PRICE = 'rent_price';
export const LOCAL_AUTHORITY_HOUSING = 'local_authority_housing';
export const TERRAIN_EMPLACEMENT_PARCELLES = 'TERRAIN_EMPLACEMENT_PARCELLES';
export const PARCEL_LAYER = 'parcel_layer';
export const COLLECTIVE_HOUSING = 'collective_housing';
export const POLITICAL = 'political';
export const DEMOGRAPHY_AND_EMPLOYMENT = 'demography_and_employment';
export const MEDIAN_INCOME = 'median_income';
export const AGE = 'age';
export const SENIOR = 'senior';
export const UNEMPLOYMENT = 'unemployment';
export const DEFINITION_BLOCK = 'definition_block';
export const TABLE_SWITCH = 'table_switch';
export const SELECT_PROGRAM_MAP = 'selelect_program_map';
export const YELLOW_THEME = 'yellow_theme';
export const BLUE_THEME = 'blue_theme';
export const DARK_BLUE_THEME = 'dark_blue_theme';
export const GRAY_THEME = 'gray_theme';
export const DEFAULT_DATATYPE = 'default';
export const TOWN_HALL = 'town_hall';
export const HOSPITAL = 'hospital';
export const DEFAULT_FILTER_PROGRAMME = {
  filter_geo: 'closest',
  filter_tva_reduite: 'toutes_tva',
  filter_type_programme: 'collectif',
  mev_year_min: 2020,
  mev_year_max: 2024,
};

// MAPS
export const EDM_COVER_MAP = 'edm_cover_map';
export const SELECTED_PROGRAMS_MAP = 'selecterd_programs_map';
export const PROGRAMS_NEUFS_MAP = 'programs_neufs_map';
export const MEV_LIM_MAP = 'mev_lim_map';
export const NEUF_MEV_LIM_MAP = 'neuf_mev_lim_map';
export const MARCHE_LOC_APPART = 'marche_loc_appart';
export const COLLECTIVE_HOUSING_MAP_1 = 'collective_housing_map_1';
export const COLLECTIVE_HOUSING_MAP_2 = 'collective_housing_map_2';
export const NB_LOG_VACANT_MAP = 'nb_log_vacant_map';
export const EVO_2014_2020_MAP = 'evo_2014_2020_map';
export const TERRAIN_EMPLACEMENT_PARCELLES_MAP =
  'terrain_emplacement_parcelles_map';
export const ECOLE_UNIVERSITY_MAP = 'ecole_university_map';
export const MOBILITY_MAP = 'mobility_map';
export const NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_MAP =
  'nearest_shops_and_public_equipement_map';
export const CHOMAGE_VARIATION_TAUX_MAP = 'chomage_variation_taux_map';
export const CHOMAGE_VARIATION_DIFF_MAP = 'chomage_variation_diff_map';
export const REVENU_MEDIAN_ANNUEL_MAP = 'revenu_median_annuel_map';
export const REVENU_VARIATION_2019_2020 = 'revenu_variation_2019_2020';
export const POPULATION_NB_MAP = 'population_nb_map';
export const POPULATION_VARIATION_MAP = 'population_variation_map';
export const POPULATION_CADRE_NB_MAP = 'population_cadre_nb_map';
export const POPULATION_CADRE_EVO_MAP = 'population_cadre_evo_map';
export const FROM_25_TO_39_YO_NB_MAP = 'from_25_to_39_yo_nb_map';
export const FROM_25_TO_39_YO_VARIATION_MAP = 'from_25_to_39_yo_variation_map';
export const SENIOR_NB_MAP = 'senior_nb_map';
export const SENIOR_VARIATION_MAP = 'senior_variation_map';

// ICONS
export const METRO = 'metro';
export const BUS = 'bus';
export const TRAIN = 'train';
export const GARE = 'gare';
export const POST_OFFICE = 'post_office';

// header table liste programmes in EDM
export const HEADERS_PROGRAM_TABLE_EDM = [
  {
    name: 'Programme',
    order: 1,
    type: 'table_header',
    rowSpan: 1,
    colSpan: 1,
    myKey: 'nom_programme',
  },
  {
    name: 'Promoteur',
    order: 2,
    type: 'table_header',
    rowSpan: 1,
    colSpan: 1,
    myKey: 'promoteur',
  },
  {
    name: "État d'avancement",
    order: 3,
    type: 'table_header',
    rowSpan: 1,
    colSpan: 1,
    myKey: 'etat_avancement',
  },
  {
    name: 'Date Commercialisation',
    order: 4,
    type: 'table_header',
    rowSpan: 1,
    colSpan: 1,
    myKey: 'date_debut_commercialisation',
  },
  {
    name: 'Date Livraison',
    order: 5,
    type: 'table_header',
    rowSpan: 1,
    colSpan: 1,
    myKey: 'date_livraison',
  },
  {
    name: 'MEV',
    order: 6,
    type: 'table_header',
    rowSpan: 1,
    colSpan: 1,
    myKey: 'nb_logt_mis_en_vente',
  },
  {
    name: 'Dispo',
    order: 7,
    type: 'table_header',
    rowSpan: 1,
    colSpan: 1,
    myKey: 'nombre_logements_disponibles',
  },
];

export const PART_1_DEFS = [
  {
    name: 'Nbre RES (lots)',
    information: 'Réservations nettes sur la période sélectionnée',
  },
  {
    name: 'Surf Moy. RES (m²)',
    information:
      'Moyenne pondérée par le nombre de lots réservés de chacune des typologies',
  },
  {
    name: 'Prix Moy RES (€/m²)',
    information:
      'Moyenne pondérée par le nombre de lots réservés * surface moyenne de chacune des typologies',
  },
  {
    name: 'Rythme Mensuel RES (lost/mois)',
    information:
      "La rythme est renseigné uniquement s'il y a des stocks disponibles en début de période",
  },
  {
    name: 'Taux écoulement mensuel (%)',
    information:
      "Le taux est renseigné uniquement s'il y a des stocks disponibles en début de période",
  },
  {
    name: 'Mbre MeV (lots)',
    information: 'MeV nettes sur la période sélectionnée',
  },
  {
    name: 'Stock disponible en fin de période (lots)',
    information:
      'Stock disponible fin de période = Stock initial - RES nettes + Mev nettes',
  },
  {
    name: "Durée Prévisonnelle d'écoulement (Mois)",
    information:
      'Stock disponible fin de période = Stock initial - RES nettes + Mev nettes',
  },
];
