import useSWR from "swr";
import {getFetcher} from "../api/fetcher";

export const useParcel = (id) => {

    const {
        data,
        error
    } = useSWR(() => id ? `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${id}` : null, getFetcher)

    return {
        parcelData: data,
        isLoading: !data && !error,
        isError: error
    }
}
