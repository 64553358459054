import React, { useCallback, useContext, useEffect, useState } from 'react';
import { updateMSComments } from '../../../services/market-survey';
import _, { debounce } from 'lodash';
import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { marketSurveyContext } from '../../../providers/marketSurveyProvider';

const CommentMarketSurvey = ({ survey_id, commentFieldName }) => {
  const [isSaved, setIsSaved] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const { isShareStudy } = useContext(marketSurveyContext);

  const { marketSurvey } = useSelector((state) => state.programme);

  const [fieldValue, setFieldValue] = useState('');

  useEffect(() => {
    if (marketSurvey) {
      setFieldValue(
        marketSurvey.data.attributes.comments[commentFieldName] || ''
      );
    }
  }, [marketSurvey]);

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      updateMSComments(survey_id, commentFieldName, nextValue);
      setIsTyping(false);
    }, 300),
    []
  );

  const handleChange = ({ target: { value } }) => {
    setIsTyping(true);
    setFieldValue(value);
    debouncedSave(value);
    setIsSaved(true);
    setTimeout(() => {
      setIsSaved(false);
    }, 3000);
  };

  return (
    <div className={'flex h-full flex-row flex-nowrap items-center'}>
      <div className={'w-full h-full flex flex-col'}>
        <label htmlFor={commentFieldName} className="sr-only">
          {commentFieldName}
        </label>
        <textarea
          disabled={isShareStudy}
          onChange={handleChange}
          style={{ minHeight: 96 }}
          name={commentFieldName}
          id={commentFieldName}
          className={classNames(
            {
              'border-green-400 focus:ring-green-500 focus:border-green-500':
                isSaved && !isTyping,
            },
            {
              'border-blue-300 focus:ring-blue-500 focus:border-blue-500 ':
                !isSaved,
            },
            'shadow-sm block w-full flex-grow sm:text-base rounded-sm'
          )}
          value={fieldValue}
          placeholder={'Insérez vos commentaires'}
        />
      </div>
      <div className={'flex flex-col justify-center h-full'}>
        <div>
          {isTyping && (
            <RefreshIcon
              className={'ml-4 w-5 h-5 text-blue-800 animate-spin'}
            />
          )}
        </div>
        <div>
          {isSaved && !isTyping && (
            <CheckCircleIcon className={'ml-4 w-5 h-5 text-green-500'} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentMarketSurvey;
