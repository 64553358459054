import Api, { apiV5 } from './Api';

export const getFetcher = async (url) => {
  const res = await Api.get(url);

  if (!res) {
    console.log('not responding');
    return null;
  }
  if (res?.status === 404) return true;
  if (res?.status !== 200) {
    const error = new Error(
      `code: ${res.status} An error occurred while fetching the data.`
    );
    // Attach extra info to the error object.
    error.info = await res.data;
    error.status = res.status;
    throw error;
  }

  return res.data;
};

export const getFetcherV5 = async (url) => {
  try {
    const { data } = await apiV5.get(url);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};
