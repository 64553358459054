import React, { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/solid';
import { usePopper } from 'react-popper';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

const MenuItem = ({ content, summary, close }) => {
  const handleOnClickLink = (ref) => {
    if (ref?.current) {
      const spacing = [
        document.getElementById('bottom-navbar')?.clientHeight || 0,
        document.getElementById('navbar')?.clientHeight || 0,
        document.getElementById('ms-page-header')?.clientHeight || 0,
        document.querySelector('.section-banner')?.clientHeight || 0,
      ].reduce((prev, curr) => prev + curr, 0);

      window.scroll({
        top: ref.current?.offsetTop - spacing,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div>
      <span
        className={
          'hover:bg-blue-700 hover:text-blue-200 block text-blue-900 cursor-pointer text-sm 2xl:text-lg font-bold'
        }
        onClick={() => {
          handleOnClickLink(summary.ref);
          close();
        }}
      >
        {summary.label}
      </span>
      <ul className={'pl-4 xl:pl-5 list-disc list-inside'}>
        {content
          .filter((item) => !item.disabled)
          .map(({ label, ref }) => {
            return (
              <p
                key={uniqueId('link__menu__')}
                className={
                  'hover:bg-blue-700 hover:text-blue-200 text-blue-900 cursor-pointer text-xs 2xl:text-sm'
                }
                onClick={() => {
                  ref && handleOnClickLink(ref);
                  close();
                }}
              >
                {label}
              </p>
            );
          })}
      </ul>
    </div>
  );
};

const PopoverMS = ({ refs, name }) => {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'popper', options: { placement: 'top', strategy: 'fixed' } },
    ],
  });

  return (
    <Popover
      as="div"
      className="inline-block text-left left-2 top-2 z-99999 print:hidden"
    >
      {({ open }) => (
        <>
          <Popover.Button className="flex group items-center focus:outline-none bg-blue-700 hover:bg-blue-800">
            <div className="py-2 cursor-pointer px-6 text-0.5xl font-bold text-white border-r-2 border-white">
              {name}
            </div>
            <div className="py-2 px-4 cursor-pointer focus:outline-none text-white">
              {open ? (
                <ChevronUpIcon className="w-8 h-8" />
              ) : (
                <ChevronDownIcon className="w-8 h-8" />
              )}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              style={{ left: 0 }}
              className={classNames(
                'fixed top-12 z-99999 max-w-screen-2xl overflow-y-auto'
              )}
              ref={setPopperElement}
              {...attributes.popper}
            >
              {({ close }) => (
                <div className="relative border-2 border-blue-500 flex gap-x-8 bg-white p-4 xl:p-7">
                  <div className="flex flex-col gap-y-8">
                    {refs.slice(0, 2).map(({ summary, content }, index) => {
                      return (
                        <MenuItem
                          summary={summary}
                          content={content}
                          key={index}
                          close={close}
                        />
                      );
                    })}
                  </div>
                  <div className="flex flex-col gap-y-8">
                    {refs.slice(2, 5).map(({ summary, content }, index) => {
                      return (
                        <MenuItem
                          summary={summary}
                          content={content}
                          key={index}
                          close={close}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
export default PopoverMS;
