import { DownloadIcon } from '@heroicons/react/solid';
import React, { memo } from 'react';
import { useMaxHeight } from '../../../hooks/useMaxHeight';
import { ExportPdfForm } from '../../modals/ExportPDFModal';

const MarketSurveyMobile = ({ surveyId }) => {
  const offsetTop = useMaxHeight(false, true);

  return (
    <div
      style={{ marginTop: offsetTop, height: `calc(100vh - ${offsetTop}px)` }}
      className="flex flex-col space-y-4 items-center justify-center p-8"
    >
      <div className="flex flex-col items-center space-y-4">
        <DownloadIcon className="w-10 h-10 text-blue-300 " />
        <p className="text-sm text-center text-blue-800">
          Pour garantir une expérience optimale, consultez votre rapport d'étude
          sur un plus grand écran ou recevez le directement sur votre mail au
          format PDF.
        </p>
      </div>
      <ExportPdfForm msId={surveyId} isOnMobile />
    </div>
  );
};

export default memo(MarketSurveyMobile);
