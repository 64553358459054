import React, { memo } from 'react';
import MapDataContainer from '../molecules/DataVisualisations/Map/MapDataContainer';
import { SELECT_PROGRAM_MAP } from '../../_helpers/constant';

const ProgramsMap = ({
  isScrollWheelZoom,
  readOnlyMode,
  noNeedMarker,
  loading,
  refMapData,
  onChangeCurrentProgrammePopupDate,
}) => {
  return (
    <div className="w-full relative h-full">
      <MapDataContainer
        defaultTileLayer="classique"
        onChangeCurrentProgrammePopupDate={onChangeCurrentProgrammePopupDate}
        refMapData={refMapData}
        isSelectedProgramsMap={true}
        noNeedMarker={noNeedMarker}
        isScrollWheelZoom={isScrollWheelZoom}
        readOnlyMode={readOnlyMode}
        dataType={SELECT_PROGRAM_MAP}
        loadingProgramme={loading}
        hFull
        enableZonageLayer
      />
    </div>
  );
};

export default memo(ProgramsMap);
