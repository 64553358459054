import React, { memo, useEffect, useState } from 'react';
import MapDataContainer from '../../molecules/DataVisualisations/Map/MapDataContainer';
import {
  PARCEL_LAYER,
  TERRAIN_EMPLACEMENT_PARCELLES_MAP,
} from '../../../_helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import { deserializeAttributesForMSGiven } from '../../../_helpers/jsonapi-deserializer';
import { ParcelThumbComp } from '../../Parcels/ParcelThumb';
import { uniq } from 'lodash';
import { DownloadIcon } from '@heroicons/react/outline';
import { ModalDownloadLink } from './CoverMarketSurveySection';
import { programmeSliceActions } from '../../../store/slices/programme';
import { apiV5 } from '../../../api/Api';

export const NextTerrainEmplacementParcelles = memo(({ parcelData }) => {
  return (
    <div
      style={{ gridTemplateRows: `repeat(8, minmax(0, 1fr))` }}
      className="grid-cols-3 gap-4 h-full grid pt-8"
    >
      {parcelData.map((item, key) => (
        <div key={key} className="row-span-1 col-span-1 w-full">
          <ParcelThumbComp isIn3_1 parcelData={item} isPdfRenderMode isHfull />
        </div>
      ))}
    </div>
  );
});

const TerrainEmplacementParcelles = ({ parcelData, isPdfMode }) => {
  const { marketSurvey } = useSelector((state) => state.programme);
  const [openModal, setOpenModal] = useState(false);
  const [zonage123, setZonage123] = useState('');
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const {
    address,
    postCode,
    town,
    epciName,
    surface,
    zonageABC,
    commune_national_code,
  } = data;

  useEffect(() => {
    if (marketSurvey && parcelData?.length) {
      let _data = {};
      const { related_rating } = !marketSurvey.real_estate_programs
        ? deserializeAttributesForMSGiven(marketSurvey)
        : marketSurvey;
      if (related_rating) {
        const zonageABC = uniq(
          parcelData.map((item) => item.data?.attributes.zonage_pinel)
        );
        _data = {
          commune_national_code:
            parcelData[0].data.attributes.commune_national_code,
          zonageABC,
          address: related_rating.address,
          postCode: related_rating.postcode,
          town: related_rating.town,
          epciName: parcelData.find(
            (item) => item.data.id === related_rating.pivot_parcelle_id
          )?.data.attributes.epci_name,
          surface: parcelData.reduce((prev, current) => {
            return prev + parseFloat(current.data.attributes.superficie);
          }, 0),
        };
        dispatch(programmeSliceActions.setParcelData(_data));
      }
      setData(_data);
    }
  }, [parcelData, marketSurvey]);

  useEffect(() => {
    const getZonage123 = (commune_national_code) => {
      apiV5
        .get(
          `${process.env.BASE_URL_API}/v5/eval_parcel/parcels/terrain/zonage_123?national_code=${commune_national_code}`
        )
        .then(({ data }) => {
          setZonage123(data?.metric_commune);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    if (commune_national_code) {
      getZonage123(commune_national_code);
    }
  }, [commune_national_code]);

  const handleClickOnDowload = () => {
    setOpenModal((state) => !state);
  };

  return (
    <div className="grid grid-cols-2 gap-x-5 pt-4 h-full flex-grow">
      <ModalDownloadLink open={openModal} onClose={() => setOpenModal(false)} />
      <div className="flex h-full flex-col">
        <div className="grid grid-cols-3 gap-x-4 items-start">
          <div>
            <div className="text-sm 2xl:text-base font-semibold">{address}</div>
            <div className="text-sm 2xl:text-base font-semibold">
              {postCode} {town}
            </div>
            <div className="text-xs text-gray-700">EPCI : {epciName}</div>
          </div>
          <div>
            <div className="text-base 2xl:text-lg font-semibold">
              TERRAIN : {surface} m²
            </div>
            <div className="text-base 2xl:text-lg font-semibold">
              ZONAGE ABC : {zonageABC?.join(', ')}
            </div>
            <div className="text-base 2xl:text-lg font-semibold">
              ZONAGE 123 : {zonage123}
            </div>
          </div>
          {!isPdfMode && (
            <div>
              <h3 className="text-base 2xl:text-lg font-semibold">
                DOCUMENTS D'URBANISME
              </h3>
              <div className="flex items-center gap-x-4 mt-2">
                <p className="text-sm 2xl:text-base">Télécharger</p>
                <span onClick={handleClickOnDowload}>
                  <DownloadIcon className="text-blue-500 hover:text-blue-700 cursor-pointer h-6 w-6" />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="mt-6 flex-grow flex flex-col">
          <p className="font-semibold">
            {parcelData?.length > 1 ? (
              <>Parcelles sélectionnées : {parcelData?.length}</>
            ) : (
              <>Parcelle sélectionnée : {parcelData?.length}</>
            )}
          </p>
          <div className="mt-2 flex-grow h-0 overflow-auto">
            {(isPdfMode ? parcelData?.slice(0, 10) : parcelData).map(
              (item, key) => (
                <ParcelThumbComp
                  isIn3_1
                  key={key}
                  parcelData={item}
                  isPdfRenderMode={isPdfMode}
                  isHfull={false}
                  fixedHeight={isPdfMode ? 72 : null}
                />
              )
            )}
          </div>
        </div>
      </div>
      <div className="h-full">
        <MapDataContainer
          name={TERRAIN_EMPLACEMENT_PARCELLES_MAP}
          hFull
          dataType={PARCEL_LAYER}
        />
      </div>
    </div>
  );
};

export default memo(TerrainEmplacementParcelles);
