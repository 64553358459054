import React, { useRef, useEffect, useState } from 'react';
import Button from '@components/Button';
import AuthContainer from './AuthContainer';
import LinksBottom from './LinksBottom';
import * as yup from 'yup';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as ismail from 'ismail';
import { getFetcher } from '../../api/fetcher';
import useDebounce from '../../_helpers/useDebounce';
import { registerUserCapem } from '../../store/actions/parcel';
import parisBackground from '../../../../assets/images/paris_aerial2.jpg';

const schemaRegistrationForm = yup.object().shape({
  email: yup
    .string()
    .transform((value) => value && value.trim())
    .email("Ce n'est pas une adresse email valide.")
    .required('Champ obligatoire.'),
  password: yup
    .string()
    .min(8, 'Minimum 8 caractères')
    .max(64, 'Maximum 64 caractères')
    .required('Champ obligatoire.'),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Les mots de passe ne correspondent pas.'
    )
    .required('Champ obligatoire.'),
  consent: yup.boolean().not([false], 'Champ obligatoire.'),
});

const MyField = ({ touched, errors, name, type = 'text', error }) => {
  return (
    <div className="flex flex-col mb-4">
      <Field
        name={name}
        type={type}
        className="border-2 border-gray-400 p-2 rounded-sm"
      />
      {touched[name] && errors[name] && (
        <span className="text-xs text-red-500 block mt-1">{errors[name]}</span>
      )}
      {error && (
        <span className="text-xs text-red-500 block mt-1">{error}</span>
      )}
    </div>
  );
};

const color = { color: '#092c4b' };

export const GetFormikContext = ({ onChange }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    onChange && onChange(values);
  }, [values]);
  return null;
};

const checkEmail = async (email = '') => {
  const { is_in_organization } = await getFetcher(
    `/api/v1/users/email/in_organization?organization=capem&email=${email.trim()}`
  );
  const { exists } = await getFetcher(
    `/api/v1/user_exists?email=${encodeURIComponent(email.trim())}`
  );
  return {
    error: exists || !is_in_organization,
    message:
      exists && is_in_organization
        ? 'Un compte existe déjà avec cette adresse.'
        : !is_in_organization
        ? "Vous devez utiliser votre adresse email d'abonné."
        : '',
  };
};

const Register = () => {
  const formik = useRef(null);
  const [email, setEmail] = useState({ error: null, value: '' });

  const handleSubmit = async (values) => {
    const { error, message } = await checkEmail(values.email);
    if (!error) {
      registerUserCapem({ user: values });
    } else {
      setEmail({
        error: message,
        value: values.email,
      });
    }
  };

  const handleChange = ({ email }) => {
    setEmail((state) => ({ ...state, value: email }));
  };

  useDebounce(async () => {
    if (ismail(email.value)) {
      const { error, message } = await checkEmail(email.value);
      setEmail({ ...email, error: error ? message : null });
    }
  }, [email.value]);

  return (
    <AuthContainer
      hideText
      showCapemLogo
      mediaUrl={parisBackground}
      typeMedia="img"
    >
      <Formik
        innerRef={formik}
        initialValues={{
          email: '',
          password: '',
          password_confirmation: '',
          consent: false,
        }}
        validationSchema={schemaRegistrationForm}
        onSubmit={handleSubmit}
      >
        {({ touched, errors }) => (
          <Form>
            <div className="z-50 w-full base_card_auth  shadow-2xl bg-white p-6 rounded-lg flex flex-col">
              <h3 className="text-center" style={color}>
                Créer un compte EvalparceL
              </h3>
              <span className="mb-2 text-gray-700">
                Votre email d'abonné CAPEM
              </span>
              <MyField
                name="email"
                touched={touched}
                errors={errors}
                error={email.error}
              />
              <span className="mb-2 text-gray-700">Mot de passe</span>
              <MyField
                name="password"
                type="password"
                touched={touched}
                errors={errors}
              />
              <span className="mb-2 text-gray-700">
                Confirmation du mot de passe
              </span>
              <MyField
                name="password_confirmation"
                type="password"
                touched={touched}
                errors={errors}
              />
              <div className="mt-2">
                <label>
                  <Field name="consent" type="checkbox" />
                  <span className="ml-2 text-gray-700">
                    J'accepte les{' '}
                    <a
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      href="https://www.evalparcel.com/politique-de-confidentialite-et-rgpd"
                    >
                      CGU et la politique de confidentialité
                    </a>
                  </span>
                </label>
                {!!errors.consent && (
                  <div className="text-xs text-red-500 mt-1">
                    {errors.consent}
                  </div>
                )}
              </div>
              <GetFormikContext onChange={handleChange} />
              <div className="flex flex-col mt-6 items-center">
                <div className="w-2/4">
                  <Button title="S'inscrire" type="submit">
                    S'inscrire
                  </Button>
                </div>
                <LinksBottom />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default Register;
