import React, { memo, useEffect, useRef } from 'react';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import BuildingPermit from '../../../../../../assets/images/icons/permis-de-construire.svg';
// import useSWR from 'swr';
// import { getFetcher } from '../../../../api/fetcher';
// import Loading from '../../../utils/Loading';
import _, { uniqueId } from 'lodash';
import BuildingPermitPopupReadOnly from './MarketSurvey/BuildingPermitPopupReadOnly';
import { useSelector } from 'react-redux';
// import { getPermitContruire } from '../../../../store/slices/programme';

export const BuildingPermitIcon = new L.icon({
  iconUrl: BuildingPermit,
  iconSize: [30, 40],
  iconAnchor: [15, 43],
  popupAnchor: [0, -40],
});

function BuildingPermitMarker({ item, onClose }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.setZIndexOffset(1000);
    }
  }, [ref]);

  return (
    <Marker
      ref={ref}
      position={transformCoordinates(item.geometry.coordinates)}
      icon={BuildingPermitIcon}
    >
      <BuildingPermitPopupReadOnly
        ref={ref}
        {...item.properties}
        coord={transformCoordinates(item.geometry.coordinates)}
        onClose={onClose}
      />
    </Marker>
  );
}

const BuildingPermitCollectionLayer = ({ onClose }) => {
  const { buildingPermitGeoData } = useSelector((state) => state.programme);

  return (
    <>
      {buildingPermitGeoData?.features?.map((item) => {
        return (
          <BuildingPermitMarker
            key={uniqueId('building_permit_marker--')}
            item={item}
            onClose={onClose}
          />
        );
      })}
    </>
  );
};

export default memo(BuildingPermitCollectionLayer);
