import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import TableListParcels from '../../components/ciblage-v2/table-list-parcels/TableListParcels';
import {
  evaluateParcels,
  getCiblageParcels,
} from '../../store/actions/ciblage/parcel';
import MapCiblage from '../../components/ciblage-v2/map/MapCiblage';
import CiblageLayout from './CiblageLayout';
import { useModal } from '../../hooks/useModal';
import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import HeaderListParcels from '../../components/ciblage-v2/header-list-parcels/HeaderListPacels';

export default function CiblageListParcels() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { setMainParcelID } = useSelectedParcels();
  const { setIsQuickCheckModalOpened, setCurrentRatingID, setRatingIds } =
    useModal();

  const handleClickEvaluate = async () => {
    const { payload: data } = await dispatch(evaluateParcels());
    if (data) {
      setCurrentRatingID(data[0].id);
      setMainParcelID(data[0].pivot_parcelle_id);
      setRatingIds(data.map((r) => r.id));
      setTimeout(() => {
        setIsQuickCheckModalOpened(true);
        history.push('/ratings');
      }, 500);
    }
  };

  useEffect(() => {
    dispatch(getCiblageParcels({ id: params.id }));
  }, []);

  return (
    <CiblageLayout desktopOnly>
      <div className="w-full h-full flex">
        <div className="w-3/4  h-full overflow-y-auto flex flex-col">
          <div className="w-full h-auto">
            <HeaderListParcels />
          </div>
          <div className="flex-grow">
            <TableListParcels onClickEvaluate={handleClickEvaluate} />
          </div>
        </div>
        <div className="w-1/4 h-full">
          <MapCiblage />
        </div>
      </div>
    </CiblageLayout>
  );
}
