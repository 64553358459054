import { GeoJSON, Marker, Popup } from 'react-leaflet';
import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import BasicLegendLayer from '../../Map/Legends/BasicLegendLayer';
import LegendLayer from '../../Map/Legends/LegendLayer';
import { transformCoordinates } from '../../../../../_helpers/coordinate';
import { poiIcons } from '../../../../icons';
import _ from 'lodash';
import polylabel from 'polylabel';
import { useSelectedParcels } from '../../../../../hooks/useSelectedParcels';
import useSWR from 'swr';
import { getFetcher } from '../../../../../api/fetcher';
import LoadingSection from '../../../../MarketSurveys/sections/LoadingSection';
import { Error } from '../../../../utils/Error';
import { XIcon } from '@heroicons/react/outline';

const CustomPop = styled(Popup)`
  background-color: white;
  border-radius: 0;
  width: 400px;
  height: auto;
  border-radius: 10px;
  .leaflet-popup-content {
    margin: 10px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;

const MsFeaturesCollectionLayer = ({
  setCoordinates = () => {},
  noNeedLegend,
  onReadyData,
}) => {
  const { fieldIds } = useSelectedParcels();
  const ref = useRef();

  const closePopup = (ref) => {
    if (!ref.current) return;
    ref.current.closePopup();
  };

  function ConditionalGeoJSON(feature, index) {
    if (feature.geometry.type === 'MultiPolygon') {
      return (
        <GeoJSON
          ref={ref}
          key={`${feature.id}__${index}`}
          data={feature.geometry}
        >
          {data.metadata && (
            <CustomPop feature={feature} metadata={data.metadata.popup}>
              <div className={'absolute right-0 top-0'}>
                <button onClick={() => closePopup(ref)} className={'p-2'}>
                  <XIcon
                    className={'w-5 h-5 text-blue-800 hover:text-blue-900'}
                  />
                </button>
              </div>
            </CustomPop>
          )}
        </GeoJSON>
      );
    }
    return (
      <GeoJSON key={`${feature.id}__${index}`} pathOptions={feature}>
        {feature.geometry.type === 'Point' && (
          <Marker
            key={`marker_quick_check__${index}`}
            position={transformCoordinates(feature.geometry.coordinates)}
            icon={poiIcons(data.category)}
          />
        )}
      </GeoJSON>
    );
  }

  const { data, error } = useSWR(
    `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/carto?parcelle_ids[]=` +
      fieldIds[0],
    getFetcher
  );

  useEffect(() => {
    if (data) {
      _.map(data.features, (item) =>
        setCoordinates((coordinates) => {
          return [polylabel(item?.geometry.coordinates, 1.0).reverse()].concat(
            coordinates
          );
        })
      );
      onReadyData && onReadyData(data);
    }
  }, [data]);

  if (!data) return <LoadingSection />;
  if (error) return <Error error={error} />;

  return (
    <>
      {!noNeedLegend && (
        <>
          {data.features?.map((feature, index) => {
            return ConditionalGeoJSON(feature, index);
          })}

          {data?.metadata ? (
            <LegendLayer legendData={data.metadata.legend} />
          ) : (
            <BasicLegendLayer data={data.legend} />
          )}
        </>
      )}
    </>
  );
};

export default memo(MsFeaturesCollectionLayer);
