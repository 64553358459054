import Api, { addAutorization, params } from '../../api/Api';
import axios from 'axios';

export const GET_MY_RESULT_TARGET_SUCCESSFULL =
  'parcelApi/GET_MY_RESULT_TARGET_SUCCESSFULL';
export const GET_MY_ISOCHRONE_RESULT_TARGET_SUCCESSFULL =
  'parcelApi/GET_MY_ISOCHRONE_RESULT_TARGET_SUCCESSFULL';
export const GET_SAVE_SEARCH_NATIONAL_CODES =
  'parcelApi/GET_SAVE_SEARCH_NATIONAL_CODES';
export const GET_SAVE_SEARCH_ZONE = 'parcelApi/GET_SAVE_SEARCH_ZONE';
export const SET_SEARCH_ISOCHRONE = 'parcelApi/SET_SEARCH_ISOCHRONE';
export const SET_POLYGONS = 'parcelApi/SET_POLYGONS';
export const SET_GO_TO_POLYGON = 'parcelApi/SET_GO_TO_POLYGON';
export const SET_CRITERIA_PARCEL = 'parcelApi/SET_CRITERIA_PARCEL';
export const SET_CRITERIA_REAL_ESTATE = 'parcelApi/SET_CRITERIA_REAL_ESTATE';
export const SET_CRITERIA_TERRITORY = 'parcelApi/SET_CRITERIA_TERRITORY';
export const SET_CURRENT_RESULT_PAGE = 'parcelApi/SET_CURRENT_RESULT_PAGE';
export const SET_RESULT_TARGETS = 'parcelApi/SET_RESULT_TARGETS';
export const SET_TARGET_ADDRESS = 'SET_TARGET_ADDRESS';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_TARGETING_SEARCH_TYPE = 'SET_TARGETING_SEARCH_TYPE';
export const GET_TARGETING_SEARCH_TYPE = 'GET_TARGETING_SEARCH_TYPE';

const initialState = {
  result_targets: null,
  targets_national_codes: [],
  search_zone: null,
  search_isochrone: null,
  polygons: [],
  goToPolygon: null,
  criteria_parcel: null,
  criteria_real_estate: null,
  criteria_territory: null,
  current_result_page: 1,
  target_address: null,
  search_type: null,
  targeting_search_type: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_RESULT_TARGET_SUCCESSFULL:
      return {
        ...state,
        result_targets: action.result_targets,
      };
    case GET_MY_ISOCHRONE_RESULT_TARGET_SUCCESSFULL:
      return {
        ...state,
        result_targets: action.result_targets,
      };
    case GET_SAVE_SEARCH_NATIONAL_CODES:
      return {
        ...state,
        targets_national_codes: action.targets_national_codes,
      };
    case GET_SAVE_SEARCH_ZONE:
      return {
        ...state,
        search_zone: action.search_zone,
      };
    case SET_SEARCH_ISOCHRONE:
      return {
        ...state,
        search_isochrone: action.search_isochrone,
      };
    case SET_POLYGONS:
      return {
        ...state,
        polygons: action.polygons,
      };
    case SET_GO_TO_POLYGON:
      return {
        ...state,
        goToPolygon: action.goToPolygon,
      };
    case SET_CRITERIA_PARCEL:
      return {
        ...state,
        criteria_parcel: action.criteria_parcel,
      };
    case SET_CRITERIA_REAL_ESTATE:
      return {
        ...state,
        criteria_real_estate: action.criteria_real_estate,
      };
    case SET_CRITERIA_TERRITORY:
      return {
        ...state,
        criteria_territory: action.criteria_territory,
      };
    case SET_CURRENT_RESULT_PAGE:
      return {
        ...state,
        current_result_page: action.currentResultPage,
      };
    case SET_RESULT_TARGETS:
      return {
        ...state,
        result_targets: action.result_targets,
      };
    case SET_TARGET_ADDRESS:
      return {
        ...state,
        target_address: action.target_address,
      };
    case SET_SEARCH_TYPE:
      return {
        ...state,
        search_type: action.search_type,
      };
    case SET_TARGETING_SEARCH_TYPE:
      return {
        ...state,
        targeting_search_type: action.targeting_search_type,
      };
    case GET_TARGETING_SEARCH_TYPE:
      return {
        ...state,
        targeting_search_type: state.targeting_search_type,
      };
    default:
      return state;
  }
};

export const getResultTargets = () => async (dispatch, getState) => {
  const nationalCodes = getState().target.targets_national_codes;
  const criteriaParcelParams = getState().target.criteria_parcel;
  const criteriaRealEstateParams = getState().target.criteria_real_estate;
  const criteriaTerritoryParams = getState().target.criteria_territory;
  const page = getState().target.current_result_page;

  const queryParams = {
    communes_national_codes: nationalCodes,
    ...criteriaParcelParams,
    ...criteriaRealEstateParams,
    ...criteriaTerritoryParams,
    page: page,
  };
  queryParams.zonage_pinels = queryParams.zonage_pinels.map((v) => v.value);
  queryParams.zonage_plus = queryParams.zonage_plus.map((v) => v.value);
  return axios
    .post(
      `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/parcels_by_territory`,
      null,
      { params: queryParams }
    )
    .then((res) => {
      dispatch({
        type: GET_MY_RESULT_TARGET_SUCCESSFULL,
        result_targets: res.data,
      });
    });
};

// Works also for isochrones because we just set the relevant communes with the isochrone.
export const countParcelByTerritory = () => async (dispatch, getState) => {
  // console.log(getState().target);
  let myAddress = '';
  if (getState().target.targeting_search_type === 'isochrone') {
    myAddress =
      getState().target.search_isochrone.selectedAddress.properties.label;
  } else {
    myAddress = getState().target.search_zone[0];
  }
  const address = myAddress;
  const searchType = getState().target.targeting_search_type;
  const nationalCodes = getState().target.targets_national_codes;
  const criteriaParcelParams = getState().target.criteria_parcel;
  const criteriaRealEstateParams = getState().target.criteria_real_estate;
  const criteriaTerritoryParams = getState().target.criteria_territory;

  const queryParams = {
    communes_national_codes: nationalCodes,
    ...criteriaParcelParams,
    ...criteriaRealEstateParams,
    ...criteriaTerritoryParams,
  };
  queryParams.zonage_pinels = queryParams.zonage_pinels.map((v) => v.value);
  queryParams.zonage_plus = queryParams.zonage_plus.map((v) => v.value);
  const searchParams = new URLSearchParams(queryParams);
  const response = await axios.post(
    `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/count_parcels_by_territory`,
    null,
    { params: queryParams }
  );
  return { response, queryParams, address, searchType };
};

export const getIsochroneResultTargets = () => async (dispatch) => {
  return axios
    .get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/parcels_by_isochrone?communes_national_codes[]=69091&population_min=20000&population_max=150000&surface_min=500&transport_mode=walk&transport_time_max=600&lon=4.757974090049898&lat=45.58134505552167`,
      { params: params }
    )
    .then((res) => {
      dispatch({
        type: GET_MY_ISOCHRONE_RESULT_TARGET_SUCCESSFULL,
        result_targets: res.data,
      });
    });
};

export const getZonesTarget = (address) => async () => {
  return axios.get(
    `${process.env.BASE_URL_API}/v4/eval_parcel/nom_zones_ciblages?q=${address}`
  );
};

export const getMunicipalities = (category, national_code) => async () => {
  return axios.get(
    `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/zones?category=${category}&national_code=${national_code}`
  );
};

export const getParcelAddress = (parcelle_id) => async () => {
  return axios.get(
    `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${parcelle_id}/adresse`
  );
};

export const getIsochroneMunicipalities =
  (transport_mode, transport_time, lon, lat) => async () => {
    return axios.get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/zones_by_isochrone?transport_mode=${transport_mode}&transport_time_max=${transport_time}&lon=${lon}&lat=${lat}`
    );
  };

export const saveSearchNationalCodes = (nationalCodes) => async (dispatch) => {
  dispatch({
    type: GET_SAVE_SEARCH_NATIONAL_CODES,
    targets_national_codes: nationalCodes,
  });
};

export const saveSearchZone = (searchZone) => async (dispatch) => {
  dispatch({
    type: GET_SAVE_SEARCH_ZONE,
    search_zone: searchZone,
  });
};

export const setSearchIsochrone = (searchIsochrone) => async (dispatch) => {
  dispatch({
    type: SET_SEARCH_ISOCHRONE,
    search_isochrone: searchIsochrone,
  });
};

export const setPolygons = (polygons) => async (dispatch) => {
  dispatch({
    type: SET_POLYGONS,
    polygons: polygons,
  });
};

export const setGoToPolygon = (goToPolygon) => async (dispatch) => {
  dispatch({
    type: SET_GO_TO_POLYGON,
    goToPolygon: goToPolygon,
  });
};

export const setCurrentResultPage = (page) => async (dispatch) => {
  dispatch({
    type: SET_CURRENT_RESULT_PAGE,
    currentResultPage: page,
  });
};

export const setCriteriaParcel = (criteriaParcel) => async (dispatch) => {
  // criteria_parcel params:
  // surface_min: number,
  // surface_max: number,
  // zone_batie: boolean,
  // zone_anru: boolean,
  // zonage_plus: string,
  // zac: boolean,
  dispatch({
    type: SET_CRITERIA_PARCEL,
    criteria_parcel: criteriaParcel,
  });
};

export const setCriteriaRealEstate =
  (criteriaRealEstate) => async (dispatch) => {
    // criteriaRealEstate params:
    // neuf_cinq_ans: boolean,
    // prix_neuf_min: number,
    // prix_neuf_max: number,
    // evolution_prix_neuf: float,
    // prix_ancien_min: number,
    // prix_ancien_max: number,
    // evolution_prix_ancien: float,
    dispatch({
      type: SET_CRITERIA_REAL_ESTATE,
      criteria_real_estate: criteriaRealEstate,
    });
  };

export const setCriteriaTerritory = (criteriaTerritory) => async (dispatch) => {
  // criteriaTerritory params:
  // population_min: number,
  // population_max: number,
  // evolution_pop_percent: float,
  // part_logement_collectif: float,
  // logement_social_min: float,
  // logement_social_max: float,
  // penalite_carence_logement_social: boolean,
  // changement_maire: boolean,
  // zonage_pinel: array,
  // coeur_de_ville: boolean
  dispatch({
    type: SET_CRITERIA_TERRITORY,
    criteria_territory: criteriaTerritory,
  });
};

export const setTargetAddress = (address) => async (dispatch) => {
  dispatch({
    type: SET_TARGET_ADDRESS,
    target_address: address,
  });
};

export const setTargetSearchType = (search_type) => async (dispatch) => {
  dispatch({
    type: SET_SEARCH_TYPE,
    search_type: search_type,
  });
};

export const setResultTargets = (resultTargets) => async (dispatch) => {
  dispatch({
    type: SET_RESULT_TARGETS,
    result_targets: resultTargets,
  });
};

export const setTargetingSearchType =
  (targetingSearchType) => async (dispatch) => {
    dispatch({
      type: SET_TARGETING_SEARCH_TYPE,
      targeting_search_type: targetingSearchType,
    });
  };

// export const targetingSearchType = (targetingSearchType) => async (dispatch) => {
//     dispatch({
//         type: GET_TARGETING_SEARCH_TYPE,
//         targeting_search_type:  targetingSearchType
//     });
// };
