import React, { useEffect, useState } from 'react';
import AuthContainer from './AuthContainer';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBgLogin } from '../../store/actions/others';
import { Link } from 'react-router-dom';

const color = { color: '#092c4b' };

const Login = () => {
  const dispatch = useDispatch();
  const { bgLogin } = useSelector((state) => state.others);
  const { logIn } = useAuth();
  const history = useHistory();
  const [information, setInformation] = useState({
    email: null,
    password: null,
  });
  const [error, setError] = useState({ error: false, message: '' });
  const [defaultEmail, setDefaultEmail] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const redirect_from = params.get('redirect_from');
    if (
      email &&
      redirect_from?.length &&
      redirect_from.trim().toLowerCase() === 'confirmation'
    ) {
      setDefaultEmail(email);
      setInformation((state) => ({ ...state, email }));
    }
  }, []);

  const handleLogin = async () => {
    if (information.email && information.password) {
      const { error, message } = await logIn(information);
      if (error) {
        setError({ error, message });
      } else {
        history.push('/ratings');
      }
    }
  };

  useEffect(() => {
    dispatch(getBgLogin());
  }, []);

  return (
    <AuthContainer mediaUrl={bgLogin} login>
      {!!defaultEmail.length && (
        <div className="mb-4 text-center p-4 w-full bg-blue-500 flex justify-center rounded-md">
          <p className="text-white">
            Votre compte EvalparceL est <br /> bien validé !
          </p>
        </div>
      )}
      <div className="z-50 w-full sm:shadow-2xl bg-white p-6 rounded-lg flex flex-col">
        <h3 className="text-center" style={color}>
          Se connecter à EvalparceL
        </h3>
        {error.error && (
          <div
            className="mt-4 bg-red-500 bg-opacity-75 rounded-md text-white p-4"
            dangerouslySetInnerHTML={{ __html: error.message }}
          ></div>
        )}
        <span className="mb-2 text-gray-700 mt-4">Email</span>
        <input
          onChange={(e) => {
            setError({ error: false, message: '' });
            setInformation((prevState) => ({
              ...prevState,
              email: e.target.value.trim(),
            }));
          }}
          defaultValue={defaultEmail}
          name="email"
          className="mb-4 border-2 border-gray-400 p-2 rounded-sm"
        />
        <span className="mb-2 text-gray-700">Mot de passe</span>
        <input
          onChange={(e) => {
            setError({ error: false, message: '' });
            setInformation((prevState) => ({
              ...prevState,
              password: e.target.value.trim(),
            }));
          }}
          type="password"
          name="password"
          className="border-2 border-gray-400 p-2 rounded-sm"
        />
        <div className="flex flex-col mt-3 items-center">
          <button
            disabled={!information.email || !information.password}
            type="button"
            onClick={() => handleLogin()}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Se connecter
          </button>
        </div>
        <div className="mt-6 flex flex-col items-center">
          <Link
            to="/users/password/new"
            className="hover:underline text-blue-500"
          >
            Mot de passe oublié ?
          </Link>
          <Link
            to="/users/sign_up"
            className="hover:underline text-blue-500 text-xl"
          >
            [ Créer votre compte ]
          </Link>
        </div>
      </div>
    </AuthContainer>
  );
};

export default Login;
