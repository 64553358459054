import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableData from './TableData';
import { getTableDateInSwitchMapModule } from '../../../store/slices/marketSurvey';

const TableSwitchModule = ({
  parcelId,
  theme,
  isPdfRenderMode,
  section,
  onReadyTable,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getTableDateInSwitchMapModule({ parcelId, section })).then(
      (data) => {
        if (data.payload) {
          onReadyTable && onReadyTable(true);
          setData(data.payload);
          setLoading(false);
        }
      }
    );
  }, []);

  return (
    <div className="w-full h-full">
      <TableData
        highlightRow={data ? [data.body.length - 2, data.body.length - 1] : []}
        theme={theme}
        data={data}
        isPdfRenderMode={isPdfRenderMode}
        loading={loading}
        inSwitch
      />
    </div>
  );
};

export default TableSwitchModule;
