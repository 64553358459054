import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import spacingThousands from '../../../_helpers/thousandShow';
import ReactLoading from 'react-loading';
import classNames from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { STEP_2 } from '../../../constants/ciblageDefaultValues';
import Modal from '../../molecules/modal/Modal';
import ListCommunes from '../list-communes/ListCommunes';

export default function NbCommunesParcels() {
  const {
    valueTerritoire: { selectedCommunes },
    nbParcel,
    loading,
    returnSearchCommunesBy,
    step,
    communes,
  } = useSelector((state) => state.ciblageCreate);
  const [openModal, setOpenModal] = useState(false);

  const handleClickNbCommunes = () => {
    setOpenModal(true);
  };

  if (returnSearchCommunesBy) {
    return null;
  }

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <h1 className="text-lg font-semibold">Liste des communes</h1>
        <p className="mt-2">
          {selectedCommunes?.length}/{communes.length} commune(s)
          sélectionnée(s)
        </p>
        <div className="mt-2">
          <ListCommunes orderBySelected readOnly />
        </div>
      </Modal>
      <div className="p-4 flex justify-evenly gap-4 border-t-1 border-gray-500">
        <div
          onClick={handleClickNbCommunes}
          className="flex justify-center text-gray-600 items-center flex-col p-1 xl:p-3 border-2 border-gray-600 rounded-lg cursor-pointer hover:bg-gray-200"
        >
          <div className="w-full text-center text-sm xl:text-lg">
            Commune(s) sélectionnée(s)
          </div>
          <div className="font-semibold  text-sm xl:text-xl">
            {loading?.commune ? (
              <ReactLoading
                type="bubbles"
                color="gray"
                height="30px"
                width="40px"
              />
            ) : (
              selectedCommunes?.length || 0
            )}
          </div>
        </div>
        <div className="flex justify-center text-gray-600 items-center flex-col p-1 xl:p-3 border-2 border-gray-600 rounded-lg">
          <div className="w-full text-center text-sm xl:text-lg">
            Nombre de parcelles est.
          </div>
          <div className="font-semibold text-sm xl:text-xl">
            {loading?.parcel ? (
              <ReactLoading
                type="bubbles"
                color="gray"
                height="30px"
                width="40px"
              />
            ) : (
              <div className="flex items-center">
                {nbParcel > 1000 && step.current === STEP_2 && (
                  <span title="Veuillez affiner votre recherche pour avoir au maximum 1000 parcelles.">
                    <ExclamationCircleIcon className="h-6 w-6 text-red-500" />
                  </span>
                )}
                <span
                  className={classNames({
                    'text-red-500 ml-2':
                      (nbParcel > 1000 ||
                        nbParcel <= 0 ||
                        nbParcel === 'Affinez les critères') &&
                      step.current === STEP_2,
                  })}
                >
                  {nbParcel ? spacingThousands(nbParcel) : 0}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
