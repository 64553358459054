import React from 'react';

const RadioInput = ({
  options = [],
  name,
  onChange,
  onBlur,
  label,
  keyValue,
  keyLabel,
  value,
  error,
}) => {
  const handleChange = (e) => {
    onChange &&
      onChange({
        target: {
          name: name || '',
          value: keyValue
            ? options.find((el) => el[keyValue] === e.target.value)
            : e.target.value,
        },
      });
  };

  return (
    <div className="flex flex-col">
      <label className="font-semibold">{label}</label>
      <div className="flex flex-wrap gap-4 mt-2">
        {options.map((el, index) => (
          <label key={index} className="block">
            <input
              type="radio"
              name={name}
              value={keyValue ? el[keyValue] : el}
              onChange={handleChange}
              onBlur={onBlur}
              checked={
                (keyValue
                  ? value && value[keyValue] === el[keyValue]
                  : value === el) || ''
              }
            />
            <span className="ml-2">{(keyLabel && el[keyLabel]) || el}</span>
          </label>
        ))}
      </div>
      {error && <span className="text-xs text-red-600 mt-1">{error}</span>}
    </div>
  );
};

export default RadioInput;
