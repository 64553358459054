import {MapContainer, LayersControl, TileLayer} from "react-leaflet";
import React from "react";
import FieldBuilderLayer from "../Layers/FieldBuilderLayer";
import Api from "../../../../api/Api";
import useSWR from "swr";
import _ from "lodash";
import {useSelectedParcels} from "../../../../hooks/useSelectedParcels";

const MapQuickCheck = () => {


    return (
        <MapContainer center={[]} className={`flex-grow h-full`}>
            <LayersControl position={"topright"}>
                <LayersControl.BaseLayer checked name="Classique">
                    <TileLayer
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite">
                    <TileLayer
                        attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
                        url="https://wmts.geopf.fr/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileRow={y}&TileCol={x}"
                        maxZoom={20}
                        maxNativeZoom={19}

                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Lumière">
                    <TileLayer
                        attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
                        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Nuit">
                    <TileLayer
                        attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
                        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Cadastre (superposé)">
                    <TileLayer
                        attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
                                  url="https://wmts.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&STYLE=PCI vecteur&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
          maxZoom={20}
          maxNativeZoom={19}
                    />
                </LayersControl.BaseLayer>
            </LayersControl>

            <FieldBuilderLayer/>
        </MapContainer>
    )
}

export default MapQuickCheck
