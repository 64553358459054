import useSWR from 'swr';
import { getFetcher } from '../api/fetcher';
import Api from '../api/Api';

export const useUserLoggedIn = (sessionAuthHeader) => {
  const fetcher = (url) =>
    Api.get(url, {
      headers: {
        'X-USER-EMAIL': sessionAuthHeader?.email,
        'X-USER-TOKEN': sessionAuthHeader?.token,
      },
    }).then((res) => res.data);

  const { data, error } = useSWR(`api/v1/users/me`, fetcher);

  return {
    user: data?.data?.attributes,
    isLoading: !error && !data,
    isError: error,
  };
};
