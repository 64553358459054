import React, { memo, useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import LoadingSection from './LoadingSection';
import SectionTitle from './SectionTitle';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MsVerticalChart = ({ title, data }) => {
  const options = useMemo(
    () => ({
      plugins: {
        title: {
          display: false,
          text: title,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
         // ticks: {
         //   callback: function(value) {
         //     return Number.isInteger(value) ? value : '';
         //   }
        //  }
        },
        y: {
          stacked: true,
          ticks: {
            min: 0,
            stepSize: 1,
            callback: function(value) {
              return Number.isInteger(value) ? value : '';
            }
          }
        },
      },
    }),
    [title]
  );

  if (!data) return <LoadingSection />;

  return (
    <div className={'flex flex-col'}>
      <SectionTitle title={title} />
      <Bar options={options} data={data} type={'bar'} />
    </div>
  );
};

export default memo(MsVerticalChart);
