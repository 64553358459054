import { useParcel } from '../../_services/parcel-service';
import Loading from './Loading';
import { Error } from './Error';
import React from 'react';
import classNames from 'classnames';

const EpciName = ({
  pivot_parcelle_id,
  isCoverEdm,
  isPdf,
  noNeedApiCall,
  isLoading,
  parcelData,
}) => {
  const res = !noNeedApiCall && useParcel(pivot_parcelle_id);

  if (res?.isLoading || isLoading) return <Loading />;
  if (res?.isError) return <Error error={res?.isError} />;
  const { epci_name } = !noNeedApiCall
    ? res?.parcelData.data.attributes
    : parcelData.data.attributes;

  if (isCoverEdm) {
    return (
      <div
        className={classNames(
          'text-gray-600  sm:tracking-tight ',
          { 'text-xs12 2xl:text-sm': !isPdf },
          { 'text-xs': !isPdf }
        )}
      >
        EPCI : {epci_name}
      </div>
    );
  }

  return (
    <h3 className="font-extrabold text-gray-900 text-xs sm:tracking-tight xl:text-base">
      EPCI : {epci_name}
    </h3>
  );
};

export default EpciName;
