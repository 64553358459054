/* This example requires Tailwind CSS v2.0+ */
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  // useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
// import onBoardingPicture from '../../../../assets/images/onboarding-modal.jpg';
// import paris from '../../../../assets/images/paris.png';
// import Button from '../../components/Button';
import { XIcon } from '@heroicons/react/solid';
import { useModal } from '../../hooks/useModal';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import moment from 'moment';

// const DefaultContent = ({ setIsOnBoardingModalOpen, cancelButtonRef }) => {
//   let { isAdmin, isCapemUser } = useAuth();

//   const history = useHistory();

const ContentForUserHasTrial = ({ user, setIsOnBoardingModalOpen }) => {
  return (
    <div className="relative bg-white rounded-lg overflow-hidden max-w-4xl sm:w-full">
      <div className="w-full bg-black text-white p-4 flex justify-between">
        <div></div>
        <h2 className="text-lg font-bold">Votre essai gratuit</h2>
        <div
          className="cursor-pointer"
          onClick={() => setIsOnBoardingModalOpen(false)}
        >
          <XIcon className="text-white hover:text-red-500 h-6 w-6" />
        </div>
      </div>
      <div className="p-6">
        <h3 className="font-bold mt-8">
          Bonjour {user.first_name}, bienvenue sur EvalparceL !
        </h3>
        <p className="mt-8">
          Nous sommes très heureux de vous offrir{' '}
          <span className="inline-block  px-2 mb-2 bg-blue-300">
            {user.demo_days_attributed}
          </span>{' '}
          jours d'essai gratuit
          <br />
          <strong>QUICK CHECK</strong> et <strong>ETUDES</strong> jusqu'au{' '}
          <span className="inline-block px-2 whitespace-nowrap bg-blue-300">
            {moment(new Date())
              .add('days', user.demo_days_remaining - 1)
              .format('DD-MM-YYYY')}
          </span>
          .
        </p>
        <p className="mt-6">
          Il vous reste{' '}
          <span className="inline-block px-2 bg-blue-300">
            {user.demo_days_remaining}
          </span>{' '}
          jours d'essai gratuit.
        </p>
        <p className="mt-8">
          N'hésitez pas à nous contacter pour toute question :
        </p>
        <ul className="list-disc pl-8">
          <li>En utilisant le Chat en bas à droite de l'écran.</li>
          <li>
            par email{' '}
            <a
              href="mailto:support@evalparcel.com"
              className="hover:underline text-blue-500"
              target="_blank"
            >
              support@evalparcel.com
            </a>
          </li>
        </ul>
        <p className="mt-8">Bonne navigation !</p>
      </div>
    </div>
  );
};

export default function OnBoardingModal() {
  const cancelButtonRef = useRef();
  const [isOnBoardingModalOpen, setIsOnBoardingModalOpen] = useState(false);
  const history = useHistory();
  const { user } = useAuth();

  // This effect runs whenever the `user` object changes.
  useEffect(() => {
    // If `user` exists and `user.demo` is true, then show the modal.
    const currentDate = moment(new Date()).format('DD-MM-YYYY');
    const lastOpenedDate = localStorage.getItem('opened_demo') || '';
    if (currentDate !== lastOpenedDate && user?.demo) {
      setIsOnBoardingModalOpen(true);
      localStorage.setItem('opened_demo', currentDate); // save the last date
    }
  }, [user]);

  return (
    user &&
    user.demo && (
      <Transition.Root show={!!user && isOnBoardingModalOpen} as={Fragment}>
        <Dialog
          as="div"
          initialFocus={cancelButtonRef}
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => {
            history.push('/ratings');
            setIsOnBoardingModalOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
                <ContentForUserHasTrial
                  user={user}
                  setIsOnBoardingModalOpen={setIsOnBoardingModalOpen}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  );
}
