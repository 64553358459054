import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../molecules/table/Table';
import moment from 'moment';
import { STATUTS } from '../../../constants/constants';
import classNames from 'classnames';
import Pagination from '../../molecules/pagination/Pagination';
import { ciblageResultActions } from '../../../store/slices/ciblageResult';
import useDebounce from '../../../_helpers/useDebounce';
import { getCiblageParcels } from '../../../store/actions/ciblage/parcel';
import ReactLoading from 'react-loading';
import useSWR from 'swr';
import { getFetcher } from '../../../api/fetcher';

const CommuneTd = ({ parcel_id }) => {
  const { data } = useSWR(
    `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${parcel_id}/adresse`,
    getFetcher
  );
  return <span>{data?.town}</span>;
};

const columsTableParcels = ({ checkedParcels = [] }) => {
  return [
    {
      label: 'Parcel',
      key: 'parcelle_id',
      sortable: false,
      render: ({ parcelle_id }) => {
        return (
          <div className="flex items-center">
            <input
              type="checkbox"
              readOnly
              checked={(checkedParcels || []).includes(parcelle_id)}
            />
            <span className="ml-4">{parcelle_id}</span>
          </div>
        );
      },
    },
    {
      label: 'Taille',
      key: 'superficie',
      sortable: true,
      render: ({ superficie }) => <span>{superficie} m²</span>,
    },
    {
      label: 'ANRU',
      key: 'zone_anru',
      sortable: true,
      render: ({ zone_anru }) => <span>{zone_anru ? 'Oui' : 'Non'}</span>,
    },
    {
      label: 'PLU',
      key: 'zonage_plu',
      sortable: true,
    },
    {
      label: 'COMMUNE',
      key: 'commune',
      sortable: true,
      render: ({ parcelle_id }) => <CommuneTd parcel_id={parcelle_id} />,
    },
    {
      label: 'Date du ciblage',
      key: 'evaluate_date',
      render: ({ evaluate_date }) => (
        <span>{moment(evaluate_date).format('DD-MM-YYYY')}</span>
      ),
    },
    {
      label: 'Statut',
      key: 'statut',
      render: ({ statut }) => {
        const statusOption = STATUTS.find((item) => item.code === statut);
        return (
          <span
            className={classNames(
              'rounded-lg text-white text-sm px-2 py-1',
              `bg-${statusOption.color}`
            )}
          >
            {statusOption.label}
          </span>
        );
      },
    },
  ];
};

const ButtonEvaluer = ({ checkedParcels = [], onClick, loading }) => {
  return (
    <button
      onClick={onClick}
      disabled={!checkedParcels.length}
      className={classNames(
        'px-4 py-2 text-white rounded-lg ',
        {
          'bg-blue-300 cursor-not-allowed': !checkedParcels.length,
        },
        {
          'bg-blue-500 hover:bg-blue-600': checkedParcels.length,
        }
      )}
    >
      {loading
        ? 'Chargement en cours...'
        : `Evaluer ${checkedParcels?.length || 0} parcelle(s) sélectionnée(s)`}
    </button>
  );
};

const LoadingTable = () => {
  return (
    <>
      <div className="absolute top-0 left-0 w-full h-full bg-gray-100"></div>
      <div className="table-loading flex justify-center items-center">
        <ReactLoading
          type="bubbles"
          color="#1BA3EF"
          height="200px"
          width="200px"
          className="flex justify-center items-center"
        />
      </div>
    </>
  );
};

export default function TableListParcels({ onClickEvaluate }) {
  const {
    parcels,
    currentPage,
    nbPage,
    checkedParcels,
    loading,
    sortingParcels,
  } = useSelector((state) => state.ciblageResult);
  const dispatch = useDispatch();

  const handleChangePage = (_currentPage) => {
    dispatch(ciblageResultActions.setCurrentPage(_currentPage + 1));
  };

  const handleSortingTable = useCallback((sorting) => {
    dispatch(ciblageResultActions.setSortingParcels(sorting));
  }, []);

  const handleRowClick = useCallback(
    ({ parcelle_id }) => {
      dispatch(
        ciblageResultActions.setCheckedParcels(
          [
            ...checkedParcels.filter((el) => el !== parcelle_id),
            checkedParcels.find((el) => el === parcelle_id)
              ? null
              : parcelle_id,
          ].filter((el) => !!el)
        )
      );
    },
    [checkedParcels]
  );

  const columns = useMemo(
    () => columsTableParcels({ checkedParcels }),
    [checkedParcels]
  );

  useDebounce(() => {
    dispatch(getCiblageParcels({ noNeedUpdateNbParcel: true }));
  }, [currentPage, sortingParcels]);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex-grow h-0 relative">
        {loading?.parcels && <LoadingTable />}
        {parcels ? (
        <Table
          loading={loading?.parcels}
          columns={columns}
          data={parcels.map(({ parcelle_id, properties }) => ({
            parcelle_id,
            ...properties,
          }))}
          onRowClick={handleRowClick}
          sorting={sortingParcels}
          onChangeSorting={handleSortingTable}
          selectedRows={(row) => checkedParcels.includes(row.parcelle_id)}
        />
      ) : (
       <div
          className="w-full h-full flex items-center justify-center text-lg font-semibold text-gray-500"
        >
          Pas de résultats, veuillez modifier les critères du ciblage.
        </div>
      )}

      </div>
      <div className="flex py-4 px-6 items-center border-t-1 border-gray-500">
        <div className="flex-grow flex justify-center">
          {!loading?.parcels && nbPage > 1 && (
            <Pagination
              currentPage={currentPage - 1}
              pageCount={nbPage}
              onChange={handleChangePage}
            />
          )}
        </div>
        <ButtonEvaluer
          onClick={onClickEvaluate}
          checkedParcels={checkedParcels}
          loading={loading?.evaluate || null}
        />
      </div>
    </div>
  );
}
