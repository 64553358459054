import React from 'react';
import {
  SURFACE_RANGE_MAX,
  SURFACE_RANGE_MIN,
  SURFACE_RANGE_STEP,
} from '../../../constants/ciblageDefaultValues';
import SelectMultiple from '../../atoms/select-multiple/SelectMultiple';
import RangeInput from '../../molecules/range-input/RangeInput';
import { PLUS_OPTIONS } from '../../../constants/constants';
import { ThreeButtonsSwitch } from '../filter-marche-immobilier/FilterMarcheImmobilier';

export default function FilterTerrain({
  valueTerrain,
  onChange,
  noKeys = false,
}) {
  const handleChange = (key) => (value) => {
    onChange && onChange(key, value);
  };

  return (
    <div className="w-full">
      <div>
        <label className="text-sm xl:text-base">Zone(s) PLU</label>
        <SelectMultiple
          options={PLUS_OPTIONS}
          placeholder="Sélectionner..."
          keyLabel="label"
          keyValue="value"
          onChange={handleChange('zonePlu')}
          value={valueTerrain?.zonePlu}
        />
      </div>
      <div className="mt-6">
        <label className="text-sm xl:text-base">
          Surface de la parcelle (m²)
        </label>
        <div className="mt-2">
          <RangeInput
            max={SURFACE_RANGE_MAX}
            min={SURFACE_RANGE_MIN}
            step={SURFACE_RANGE_STEP}
            onChange={handleChange('surfaceRange')}
            value={valueTerrain?.surfaceRange}
          />
        </div>
      </div>
      <h4 className="mt-7 text-blue-500 font-semibold text-lg text-center">
        Plus de critères
      </h4>
      <div className="mt-2">
        <label className="text-sm xl:text-base">Zone Bâtie</label>
        <ThreeButtonsSwitch
          value={valueTerrain?.zoneBatie}
          onChange={handleChange('zoneBatie')}
        />
      </div>
      <div className="mt-4">
        <label className="text-sm xl:text-base">Zone ANRU</label>
        <ThreeButtonsSwitch
          onChange={handleChange('zoneANRU')}
          value={valueTerrain?.zoneANRU}
        />
      </div>
      <div className="mt-4">
        <label className="text-sm xl:text-base">
          Zone d'Amenagement Concertée (ZAC)
        </label>
        <ThreeButtonsSwitch
          onChange={handleChange('zac')}
          value={valueTerrain?.zac}
        />
      </div>
    </div>
  );
}
