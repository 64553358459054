import React, { useEffect, useState } from 'react';
import Navbar from '../../components/molecules/Navbar';
import BottomNavbar from '../../components/BottomNavbar';

export default function AdminUserLayout({ children }) {
  const [paddingTop, setPaddingTop] = useState(0);

  useEffect(() => {
    const fixPaddingTop = () => {
      const navbar = document.getElementById('navbar');
      const underNavbar = document.getElementById('bottom-navbar');
      if ((navbar, underNavbar)) {
        setPaddingTop(navbar.clientHeight + underNavbar.clientHeight);
      }
    };
    fixPaddingTop();
    window.addEventListener('resize', fixPaddingTop);
    return () => {
      window.removeEventListener('resize', fixPaddingTop);
    };
  }, []);

  return (
    <>
      <Navbar>
        <BottomNavbar></BottomNavbar>
      </Navbar>
      <div
        style={{ paddingTop: `${paddingTop}px` }}
        className="w-full h-screen"
      >
        {children}
      </div>
    </>
  );
}
