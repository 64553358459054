import React, { memo, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
// import { getFetcher } from '../../../api/fetcher';
import Loading from '../../utils/Loading';
import { Error } from '../../utils/Error';
import Api from '../../../api/Api';
import MsBoxComp2 from './MsBoxComp2';
import MsBoxSimple1 from './MsBoxSimple1';
import { marketSurveyContext } from '../../../providers/marketSurveyProvider';
import { ModalDownloadLink } from './CoverMarketSurveySection';
import { useMemo } from 'react';

const fetch = (url) => Api.get(url).then((res) => res.data);

const MsBox = ({ isPdfRenderMode, url, limitHeightBox, dataItem }) => {
  const res = !dataItem?.v2 && useSWR(url, fetch);
  const [openModal, setOpenModal] = useState(false);

  const data = useMemo(
    () => (dataItem ? dataItem : res?.data || null),
    [dataItem, res]
  );

  const {
    downloadLinksDocs,
    setLoadingDocs,
    setAltDownloadLinkDoc,
    setDownloadLinksDocs,
  } = useContext(marketSurveyContext);

  useEffect(() => {
    if (url || data) {
      const isplu = url?.indexOf('terrain/plu') >= 0 || data?.isPlu;
      if (data && data.download_link && !downloadLinksDocs && isplu) {
        setDownloadLinksDocs(data.download_link);
        setAltDownloadLinkDoc(data.alternate_download_link);
        setLoadingDocs(false);
      }
    }
  }, [data, url]);

  const isplu = useMemo(
    () => url?.indexOf('terrain/plu') >= 0 || data?.isPlu,
    [url, data]
  );

  if (!data) return <Loading />;

  return (
    <>
      {isplu && (
        <ModalDownloadLink
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
      {data.type_box === 'BoxComp2' ? (
        <MsBoxComp2
          limitHeightBox={limitHeightBox}
          isPdfRenderMode={isPdfRenderMode}
          isPlu={isplu}
          onDownloadLinkClick={() => setOpenModal(true)}
          data={data}
        />
      ) : (
        <MsBoxSimple1
          isPlu={isplu}
          onDownloadLinkClick={() => setOpenModal(true)}
          limitHeightBox={limitHeightBox}
          isPdfRenderMode={isPdfRenderMode}
          data={data}
        />
      )}
    </>
  );
};

export default memo(MsBox);

export const MsBoxNoLoadData = ({ data, isPdfRenderMode, limitHeightBox }) => {
  if (data.type_box === 'BoxComp2') {
    return (
      <MsBoxComp2
        limitHeightBox={limitHeightBox}
        isPdfRenderMode={isPdfRenderMode}
        data={data}
      />
    );
  }
  return (
    <MsBoxSimple1
      limitHeightBox={limitHeightBox}
      isPdfRenderMode={isPdfRenderMode}
      data={data}
    />
  );
};
