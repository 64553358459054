import React, { useCallback, useEffect, useRef, useState } from 'react';
import Api from '../../api/Api';
import { useModal } from '../../hooks/useModal';
import classNames from 'classnames';
import ahoy from 'ahoy.js';
import Modal from '../molecules/modal/Modal';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TagsInput } from 'react-tag-input-component';
import { ShareIcon } from '@heroicons/react/solid';
import { isArray } from 'lodash';

const shareFormSchema = yup.object({
  emails: yup
    .array()
    .min(1, 'Champ obligatoire')
    .of(yup.string().email('Invalides Emails'))
    .required('Champ obligatoire'),
  comment: yup.string(),
});

const initValues = {
  emails: [],
  comment: '',
};

export default function SharingModal() {
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const { msId, isSharingModalOpened, setIsSharingModalOpened } = useModal();
  const refFormik = useRef(null);

  const handleSubmit = useCallback(
    (values, formikHelpers) => {
      if (isArray(values.emails) && msId) {
        setLoading(true);
        Api.post('/market_survey/share', {
          emails: values.emails,
          market_survey_id: msId,
          comment: values.comment,
        })
          .then((res) => {
            if (res?.status === 200) {
              setIsSent(true);
              setLoading(false);
              formikHelpers.resetForm();
              ahoy.track('$partage_rapport_etude_par_email', {
                market_survey_id: msId,
              });
            } else {
              alert('ERROR !!!');
            }
          })
          .catch((e) => {
            alert('ERROR !!!');
            console.log(e);
          });
      }
    },
    [msId]
  );

  useEffect(() => {
    const handleBlur = (e) => {
      const value = e.target.value;
      if (value?.length) {
        refFormik.current.setValues({
          ...refFormik.current.values,
          emails: [...refFormik.current.values.emails, value],
        });
        inputEl.value = '';
      }
    };
    const els = document.getElementsByClassName('input-tag');
    let inputEl = null;
    if (els[0] && refFormik.current) {
      inputEl = els[0];
      inputEl.addEventListener('blur', handleBlur);
    }

    return () => {
      inputEl?.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <Modal
      maxWidth="max-w-lg"
      open={isSharingModalOpened}
      onClose={() => setIsSharingModalOpened(false)}
    >
      <div className="w-full">
        <h1 className="text-xl font-semibold">
          Partager votre étude avec vos collègues
        </h1>
        <p className="text-xs text-gray-800 italic">Champ obligatoire *</p>
        {isSent && (
          <div className="mt-6 rounded-lg bg-green-200 bg-opacity-50 p-4">
            Partagé avec succès.
          </div>
        )}
        <div></div>
        <div className="mt-12">
          <Formik
            innerRef={refFormik}
            validationSchema={shareFormSchema}
            initialValues={initValues}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
              isValid,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col">
                  <label className="text-base text-gray-700">Email *</label>
                  <TagsInput
                    classNames={{
                      input: 'input-tag',
                      tag: 'tag-share-edm',
                    }}
                    value={values.emails}
                    onChange={(value) =>
                      handleChange({ target: { value, name: 'emails' } })
                    }
                    onBlur={handleBlur}
                    disabled={loading}
                    name="emails"
                    placeHolder="Entrez un email"
                  />
                  {errors.emails && touched.emails && (
                    <span className="text-xs text-red-500 mt-1">
                      {errors.emails}
                    </span>
                  )}
                </div>
                <div className="mt-8 flex flex-col">
                  <label className="text-base text-gray-700">
                    Message (facultatif)
                  </label>
                  <textarea
                    name="comment"
                    disabled={loading}
                    onChange={handleChange}
                    value={values.comment}
                    className="h-40 rounded-lg"
                  />
                </div>
                <div className="mt-8">
                  <button
                    type="submit"
                    className={classNames(
                      'flex text-white w-full py-4 px-6 justify-center bg-blue-500 rounded-lg hover:bg-blue-700',
                      { 'opacity-50 cursor-not-allowed': !isValid || loading }
                    )}
                  >
                    <div className="flex items-center gap-x-4">
                      <span>
                        {loading
                          ? 'Envoi en cours...'
                          : 'Partager votre étude de marché'}
                      </span>
                      {!loading && <ShareIcon className="h-6 w-6 text-white" />}
                    </div>
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
