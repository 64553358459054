import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {LockClosedIcon, XIcon} from "@heroicons/react/outline";
import Api from "../../api/Api";
import {useForm} from "react-hook-form";
import {ErrorMessage} from '@hookform/error-message';

const UpdatePasswordModal = ({isOpen, closeModalPasswordUpdate}) => {
    const {
        register,
        getValues,
        formState: {errors},
        handleSubmit
    } = useForm({
        criteriaMode: "all"
    });

    useEffect(() => {
        setIsUpdatedPassword(false)
    }, [isOpen])
    const onSubmit = (data) => updatePassword(data);
    const [isUpdatedPassword, setIsUpdatedPassword] = useState(false)

    function updatePassword(data) {
        if (data.password === data.password_confirmation) {
            Api.put('/api/v1/me/password', {
                password: data.password,
                password_confirmation: data.password_confirmation
            }).then(r => r.data)
        }
        setIsUpdatedPassword(true)
        setTimeout(() => closeModalPasswordUpdate(), 1000)
    }

    return <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto" onClose={closeModalPasswordUpdate}
                style={{zIndex: 99999}}>
            <div
                className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true">&#8203;</span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">

                        <XIcon className={'w-6 h-6 text-blue-800 absolute top-1 right-1 hover:text-blue-darken cursor-pointer'} onClick={closeModalPasswordUpdate}/>
                        <div>
                            <div
                                className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <LockClosedIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    Modifier mon mot de passe
                                </Dialog.Title>

                                <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col justify-start'}>
                                    <label className={'text-xs text-left mt-1'}>Mot de passe*
                                        <input type={'password'} {...register('password', {
                                            required: "Ce champs est requis",
                                            minLength:
                                                {
                                                    value: 6,
                                                    message: "6 caractères minimum"
                                                },
                                        })}
                                               className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="password"
                                            render={({messages}) =>
                                                messages &&
                                                Object.entries(messages).map(([type, message]) => (
                                                    <p className={'text-red-500'} key={type}>{message}</p>
                                                ))
                                            }
                                        />
                                        {/*{errors.password && <span className={'text-red-600'}>Champ de 6 à 20 caractères requis</span>}*/}

                                    </label>
                                    <label className={'text-xs text-left mt-1'}>Confirmation mot de passe*
                                        <input type={'password'} {...register('password_confirmation', {
                                            required: "Ce champs est requis",
                                            minLength:
                                                {
                                                    value: 6,
                                                    message: "6 caractères minimum"
                                                },
                                            validate: (value) => {
                                                const {password} = getValues();
                                                return password === value || "Les deux mots de passe ne correspondent pas";
                                            }
                                        })}
                                               className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                        />
                                        {/*{errors.password_confirmation && <span className={'text-red-600'}>Champ de 6 à 20 caractères requis</span>}*/}
                                        <ErrorMessage
                                            errors={errors}
                                            name="password_confirmation"
                                            render={({messages}) =>
                                                messages &&
                                                Object.entries(messages).map(([type, message]) => (
                                                    <p className={'text-red-500'} key={type}>{message}</p>
                                                ))
                                            }
                                        />
                                    </label>
                                    {!isUpdatedPassword && <button
                                        type="submit"
                                        className={"mt-2 focus:ring-blue-500  bg-blue-600 hover:bg-blue-700 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"}
                                    >
                                        Mettre à jour le mot de passe
                                    </button>}

                                    {isUpdatedPassword && <button
                                        type="submit"
                                        className={"mt-2 focus:ring-green-100 bg-green-600 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"}
                                    >
                                        Votre mot de passe a été mis à jour
                                    </button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>

}


export default UpdatePasswordModal