import React from 'react'
import {useMaxHeight} from "../../../hooks/useMaxHeight";

const LoadingSection = () => {
    const maxHeight = useMaxHeight()


    return <div className={'flex flex-col justify-center animate-pulse loading'}
                style={{minHeight: `calc(100vh - ${maxHeight}px - 200px)`}}>
        <div className={"h-full justify-center flex flex-col"}>
            <div className="flex items-center justify-center space-x-2 animate-pulse">
                <div className="w-8 h-8 bg-blue-400 rounded-full"/>
                <div className="w-8 h-8 bg-blue-400 rounded-full"/>
                <div className="w-8 h-8 bg-blue-400 rounded-full"/>
            </div>
        </div>
    </div>
}

export default LoadingSection