import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';
import { getEpcis } from '../../../store/actions/ciblage/epci';
import { getCommunesByCatAndCode } from '../../../store/actions/ciblage/commune';
import CustomAutocomplete from '../../molecules/custom-autocomplete/CustomAutocomplete';
import FormulaireCibler from '../formulaire-cibler/FormulaireCibler';
import ListCommunes from '../list-communes/ListCommunes';
import SelectAllOrClibler from '../select-all-or-cibler/SelectAllOrClibler';
import SelectedSearchBy from '../selelected-search-by/SelectedSearchBy';

export default function FormulaireEpci() {
  const {
    epcis,
    valueTerritoire: { epci },
    communes,
    cibler,
  } = useSelector((state) => state.ciblageCreate);
  const thereAreCommunes = useMemo(() => !!communes.length, [communes]);

  const dispatch = useDispatch();

  const handleChangeInputValueAutocomplete = (query) => {
    dispatch(getEpcis(query));
  };

  const handleChangeEpci = (value) => {
    dispatch(ciblageCreateActions.setValueTerritoireEpci(value));
    dispatch(
      getCommunesByCatAndCode({ category: 'epci', code: value?.code || null })
    );
  };

  const handleClickEditEpci = () => {
    dispatch(ciblageCreateActions.setCibler(false));
  };

  return (
    <>
      {!cibler && <SelectedSearchBy />}
      <div className="px-4 mt-4">
        <label className="flex justify-between text-sm xl:text-base items-center">
          <span>
            {epci ? 'EPCI – Sélectionné' : 'Renseignez le nom de l’EPCI'}
          </span>
          {cibler && (
            <span
              onClick={handleClickEditEpci}
              className="font-medium hover:font-semibold text-teal-500 cursor-pointer"
            >
              Modifier
            </span>
          )}
        </label>
        <CustomAutocomplete
          placeholder="Exemple Lyon"
          keyLabel="label"
          keyValue="code"
          onChange={handleChangeEpci}
          onChangeInputValue={handleChangeInputValueAutocomplete}
          options={epcis}
          value={epci}
          readOnly={cibler}
        />
        {thereAreCommunes && (
          <div className="text-sm">{communes.length} communes</div>
        )}
      </div>
      {thereAreCommunes && !cibler && (
        <div className="mt-4 px-4">
          <SelectAllOrClibler />
        </div>
      )}
      {!cibler && (
        <div className="px-4 mt-4">
          <ListCommunes />
        </div>
      )}
      {cibler && (
        <div className="px-4 mt-6">
          <FormulaireCibler />
        </div>
      )}
    </>
  );
}
