import React from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";

class LoadingBox extends React.Component {
    render() {
        return <div style={{
            height: `${this.props.printMode ? "110px" : "120px"}`
        }}
                    className={
                        classNames(
                            {
                                "col-span-1": this.props.boxSize === "simple",
                                "col-span-2": this.props.boxSize === "large"
                            },
                            "animate-pulse flex flex-col py-3 px-4 bg-white shadow rounded-lg overflow-hidden sm:p-2 sm:px-3 justify-between border-gray-200 border-2 loading")}>
            <dt className="h-2 w-full bg-orange-200 "/>
            <div className={"flex flex-row justify-between bg-gray-400 h-2 w-full"}/>
        </div>;
    }
}

LoadingBox.propTypes = {printMode: PropTypes.any, boxSize: PropTypes.string};

export default LoadingBox