import axios from 'axios';
import {params} from '../../api/Api';

export const GET_MY_PROJECT_SUCCESSFULL =
    'parcelApi/GET_MY_PROJECT_SUCCESSFULL';
export const GET_MY_PROJECTS_SUCCESSFULL =
    'parcelApi/GET_MY_PROJECTS_SUCCESSFULL';

const initialState = {
    my_project: null,
    projects: null,
};

let config = {
    baseURL: process.env.API_GEO_URL,
    headers: {
        Accept: 'application/json',
    },
};

let geoApi = axios.create(config);

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_PROJECT_SUCCESSFULL:
            return {
                ...state,
                my_project: action.project,
            };
        case GET_MY_PROJECTS_SUCCESSFULL:
            return {
                ...state,
                projects: action.projects,
            };
        default:
            return state;
    }
};

export const getProject = (uuid) => async (dispatch) => {
    return axios.get(`/projects/${uuid}.json`, {params: params}).then((res) => {
        dispatch({
            type: GET_MY_PROJECT_SUCCESSFULL,
            project: res.data,
        });
    });
};

export const getMyProjects = () => async (dispatch) => {
    return axios.get('/projects.json', {params: params}).then((res) => {
        dispatch({
            type: GET_MY_PROJECTS_SUCCESSFULL,
            projects: res.data,
        });
    });
};
