import { ciblageCreateActions } from '../../slices/ciblageCreate';
import { buildJsonFilterCiblage } from './cibler';
import { getNbParcel } from './parcel';

export const equivsKeysFilterTerrain = [
  ['zonePluToParams', 'zonage_plus'],
  ['surfaceRange', 'surface_min', 'surface_max'],
  ['zoneBatie', 'zone_batie'],
  ['zoneANRU', 'zone_anru'],
  ['zac', 'zac'],
];

export const getNbParcelByTerrainFilter = () => async (dispatch, getState) => {
  const {
    valueTerritoire: { selectedCommunes },
    filtersCiblerJson,
    valueTerrain,
  } = getState().ciblageCreate;

  const json = {
    ...filtersCiblerJson,
    ...buildJsonFilterCiblage({}, equivsKeysFilterTerrain, valueTerrain),
  };

  dispatch(ciblageCreateActions.setFiltersTerrainJson(json));

  dispatch(ciblageCreateActions.setLoading({ parcel: true }));
  await dispatch(
    getNbParcel({
      filtersCiblerJson: json,
      selectedCommunes,
    })
  );
  dispatch(ciblageCreateActions.setLoading(null));
};
