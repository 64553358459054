import React, { useEffect, useState } from 'react';
import IconSwitch from './IconSwitch';
import { useMap } from 'react-leaflet';
import * as ReactDOMServer from 'react-dom/server';

const legendItems = [
  {
    icon: 'terrain_evalue',
    label: 'Adresse du terrain évalué',
  },
  {
    icon: 'add_icon',
    label: 'Ajouter une parcelle au terrain',
  },
  {
    icon: 'remove_icon',
    label: 'Retirer une parcelle du terrain',
  },
];

function openLegend() {
  document.querySelector('.msLegendContainer').classList.add('active');
}

const MarketSurveyLegendLayer = () => {
  const map = useMap();

  useEffect(() => {
    const legendItemFactory = (items) => {
      return (
        <div className={'msLegendContainer'}>
          {items.map((item, index) => {
            return (
              <div
                key={`legend-item__${index}`}
                className={'legendItems flex flex-row items-center'}
              >
                {IconSwitch(item.icon, 'terrain evalue')}
                <div className={'p-2 text-xs'}>{item.label}</div>
              </div>
            );
          })}
        </div>
      );
    };

    const legend = L.control({ position: 'topright' });
    legend.onAdd = () => {
      const div = L.DomUtil.create(
        'div',
        'msLegend bg-white rounded-lg p-2 max-w-xs'
      );
      div.innerHTML += ReactDOMServer.renderToStaticMarkup(
        legendItemFactory(legendItems)
      );
      L.DomEvent.disableClickPropagation(div);
      return div;
    };

    legend.addTo(map);

    map.zoomControl.setPosition('topright');

    openLegend();

    return () => legend.remove();
  }, [map]);

  return null;
};

export default MarketSurveyLegendLayer;
