import React from "react";
import {MapContainer, TileLayer} from "react-leaflet";
import FeaturesCollectionLayer
    from "../../../../../components/molecules/DataVisualisations/Layers/FeaturesCollectionLayer";
import Api from "../../../../../api/Api";
import useSWR from "swr";
import {useDataVisualisation} from "../../../../../hooks/useDataVisualisation";
import polylabel from "polylabel";

const ConceptNoteMap = ({theme, zoom}) => {
    const {urlMap} = useDataVisualisation()

    const fetcher = url => Api.get(url).then(res => res.data)
    const {data, error} = useSWR(urlMap, fetcher)

    if (!data) {
        return <div> Map loading ... </div>
    }
    if (error) {
        return <div> Loading failed ...</div>
    }

    return (
        <MapContainer style={{height: '100%'}} zoomControl={false} zoom={zoom} center={polylabel(data.features[0].geometry.coordinates, 1.0).reverse()}>
            {theme === "light" &&
                <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"/>}
            {theme === "satellite" && <TileLayer
                url="https://wmts.geopf.fr/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileRow={y}&TileCol={x}"
maxZoom={20}
maxNativeZoom={19}
            />}

            {/*{data.type === "FeatureCollection" && <FeaturesCollectionLayer data={data} zoom={zoom}/>}*/}
        </MapContainer>
    )

}

export default ConceptNoteMap
