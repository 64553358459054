import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from '../../components/molecules/Card';
import MapNewRating from '../../components/molecules/DataVisualisations/Map/MapNewRating';
import SearchAddress from './SearchAddress';
import NewRatingHelpModal from '../../components/modals/ParcelOffLimits';
import { useMediaQuery } from 'react-responsive';
import Modal from '../../components/molecules/modal/Modal';
import { useDispatch } from 'react-redux';
import { othersSliceActions } from '../../store/slices/others';

const NewRating = () => {
  const [geolocationSearchMode, setGeoLocationSearchMode] = useState(false);
  const [nearestParcels, setNearestParcels] = useState(null);
  const [searchedAddress, setSearchedAddress] = useState(null);
  const [cityCode, setCityCode] = useState(null);
  const [addressCoordinates, setAddressCoordinates] = useState(null);
  const [isNewRatingHelpModalOpen, setIsNewRatingHelpModalOpen] =
    useState(false);
  const [offset, setOffset] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState({
    address: null,
    postcode: null,
    evaluate_date: null,
    town: null,
    parcel_size: null,
    plu: null,
  });
  const [openSelect, setOpenSelect] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const refFielBuilding = useRef(null);
  const dispatch = useDispatch();
  const mapRatingRef = useRef(null);

  const handleReset = () => {
    if (refFielBuilding.current) {
      refFielBuilding.current.resetReach();
    }
  };

  useEffect(() => {
    const fixOffset = () => {
      setOffset(
        document.getElementById('navbar')?.offsetHeight +
          document.getElementById('bottom-navbar')?.offsetHeight
      );
    };

    dispatch(othersSliceActions.setMaxErrorNb(5));

    fixOffset();
    window.addEventListener('resize', fixOffset);

    return () => {
      window.removeEventListener('resize', fixOffset);
    };
  }, []);

  const handleCityEqualsAddressName = useCallback((isSame) => {
    isSame &&
      mapRatingRef.current &&
      mapRatingRef.current.activeZonagePlu({ commune: true });
    !isSame && mapRatingRef.current && mapRatingRef.current.disableZonage();
  }, []);

  const handleOnUseSelectedAdress = useCallback(() => {
    mapRatingRef.current && mapRatingRef.current.notClickOnMap();
  }, []);

  return (
    <div
      id="new-rating-container"
      className="relative w-full"
      style={{ marginTop: `${offset}px`, height: `calc(100vh - ${offset}px)` }}
    >
      {!isMobile ? (
        <div className="card-container-new-search block w-full lg:w-auto px-4 md:px-0">
          <Card
            position="relative"
            padding
            theme="light"
            shadow
            rounded
            title="Nouvelle recherche"
            margin
            dontShowButton
            zIndex={2500}
          >
            <SearchAddress
              onUseSelectAdress={handleOnUseSelectedAdress}
              setCityCode={setCityCode}
              setIsNewRatingHelpModalOpen={setIsNewRatingHelpModalOpen}
              setAddressCoordinates={setAddressCoordinates}
              setNearestParcels={setNearestParcels}
              addressCoordinates={addressCoordinates}
              searchedAddress={searchedAddress}
              setSearchedAddress={setSearchedAddress}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              cityCode={cityCode}
              onReset={handleReset}
              onCityEqualsAddressName={handleCityEqualsAddressName}
              nearestParcels={nearestParcels}
            />
          </Card>
        </div>
      ) : (
        <Modal open={openSelect} onClose={() => setOpenSelect(false)}>
          <h3 className="font-semibold">Nouvelle recherche</h3>
          <SearchAddress
            onUseSelectAdress={handleOnUseSelectedAdress}
            setCityCode={setCityCode}
            setIsNewRatingHelpModalOpen={setIsNewRatingHelpModalOpen}
            setAddressCoordinates={setAddressCoordinates}
            setNearestParcels={setNearestParcels}
            addressCoordinates={addressCoordinates}
            searchedAddress={searchedAddress}
            setSearchedAddress={setSearchedAddress}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            cityCode={cityCode}
            onClose={() => setOpenSelect(false)}
            onReset={handleReset}
            isMobile
            setGeoLocationSearchMode={setGeoLocationSearchMode}
            geolocationSearchMode={geolocationSearchMode}
            onCityEqualsAddressName={handleCityEqualsAddressName}
            nearestParcels={nearestParcels}
          />
        </Modal>
      )}
      {!openSelect && isMobile && (
        <div className="fixed left-1" style={{ zIndex: 20, bottom: 32 }}>
          <button
            onClick={() => setOpenSelect(true)}
            className="bg-blue-600 text-white px-4 py-2 text-xs rounded-md"
          >
            Modifier / Valider
          </button>
        </div>
      )}
      <MapNewRating
        ref={mapRatingRef}
        setCityCode={setCityCode}
        setSearchedAddress={setSearchedAddress}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        addressCoordinates={addressCoordinates}
        nearestParcels={nearestParcels}
        setNearestParcels={setNearestParcels}
        refFielBuilding={refFielBuilding}
      />
      <NewRatingHelpModal
        setIsOpen={setIsNewRatingHelpModalOpen}
        isOpen={isNewRatingHelpModalOpen}
      />
    </div>
  );
};

export default NewRating;
