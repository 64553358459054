import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api/Api';
import { ciblageResultActions } from '../../slices/ciblageResult';
import { buildJsonFilterCiblage } from './cibler';
import { buildParamsByJson, getCiblageParcels } from './parcel';

export const updateCiblage = createAsyncThunk(
  'updateCiblage',
  (body, { dispatch, getState }) => {
    const { ciblage } = getState().ciblageResult;

    return Api.patch(`/targets/${ciblage.id}`, body)
      .then(() => {
        dispatch(ciblageResultActions.setCiblage({ ...ciblage, ...body }));
        return { ...ciblage, ...body };
      })
      .catch((e) => {
        dispatch(ciblageResultActions.setCiblage(ciblage));
        console.log(e);
        return ciblage;
      });
  }
);

const equivsKeysFilterTerrainResult = [
  ['zonePlu', 'zonage_plus'],
  ['surfaceRange', 'surface_min', 'surface_max'],
  ['zoneBatie', 'zone_batie'],
  ['zoneANRU', 'zone_anru'],
  ['zac', 'zac'],
];

export const buildNewParamsOnEdit = (currentParams, newParams) => {
  const keys = [
    'zonage_plus',
    'surface_min',
    'surface_max',
    'zone_batie',
    'zone_anru',
    'zac',
  ];

  let json = {};
  Object.entries(currentParams).forEach(([key, value]) => {
    if (!keys.includes(key)) {
      json[key] = value;
    }
  });

  return buildJsonFilterCiblage(json, equivsKeysFilterTerrainResult, newParams);
};

export const updateCiblageParams = createAsyncThunk(
  'updateCiblageParams',
  async (params, { dispatch, getState }) => {
    const {
      ciblage: { id, query_params },
    } = getState().ciblageResult;

    dispatch(ciblageResultActions.setCheckedParcels([]));
    dispatch(ciblageResultActions.setSelectedCommunes([]));
    dispatch(ciblageResultActions.setCommunesParcels([]));
    dispatch(ciblageResultActions.setCurrentPage(1));

    const queryParams = buildNewParamsOnEdit(JSON.parse(query_params), {
      ...params,
      zonePlu: params.zonePlu.map((item) => item.value),
    });
    try {
      await dispatch(
        updateCiblage({
          query_params: JSON.stringify(queryParams),
          api_url_params: buildParamsByJson(queryParams),
        })
      );
      dispatch(getCiblageParcels({ id }));
    } catch (e) {
      console.log(e);
    }
  }
);
