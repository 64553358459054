import React, {useEffect, useState} from 'react';
import axios from 'axios';

const IsMayorChange = props => {
    const {
        national_code,
        className
    } = props;

    const [mayorChange, setMayorChange] = useState()

    useEffect(() => {
        axios.get(
           `${process.env.BASE_URL_API}/v4/eval_parcel/territoire/${national_code}/changing_mayor`
        ).then(res => {
          if (res.data.metric.toLowerCase() === "non") {
            setMayorChange(true);
          } else if (res.data.metric.toLowerCase() === "oui") {
            setMayorChange(false);
          }
        })
    }, [])

  return (
        <div className={className}>{typeof mayorChange !== 'undefined' && (
          <p>
            Changement de Maire lors des derni_res élections ? : {mayorChange ? 'oui' : 'non'}
          </p>
        )}</div>
    )
};

export default IsMayorChange;
