import { CheckIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import Loader from 'react-loader-spinner';
import React, { useEffect, useRef, useState } from 'react';
import { useModal } from '../../hooks/useModal';
import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import Api from '../../api/Api';
import _, { isEmpty } from 'lodash';
import classNames from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import UseAnimations from 'react-useanimations';
import alertCycle from 'react-useanimations/lib/alertCircle';
import useDebounce from '../../_helpers/useDebounce';
import axios from 'axios';
import centerIco from '../../../../assets/images/icons/center.ico';

export default function SearchAddress({
  setAddressCoordinates,
  setNearestParcels,
  addressCoordinates,
  onClose,
  setSearchedAddress,
  searchedAddress = '',
  selectedAddress,
  setSelectedAddress,
  setIsNewRatingHelpModalOpen,
  cityCode,
  setCityCode,
  onReset,
  isMobile,
  geolocationSearchMode,
  setGeoLocationSearchMode,
  onCityEqualsAddressName,
  onUseSelectAdress,
}) {
  const [isSearchEngineAvailable, setIsSearchEngineAvailable] = useState(true);
  const [hasSelected, setHasSelected] = useState(false);
  const [infosAdresse, setInfosAdresse] = useState({
    name: '',
    postcode: '',
    city: '',
    isStreet: false,
    label: '',
  });
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useDebounce(
    () => {
      if (selectedAddress) {
        setHasSelected(true);
        setInfosAdresse(selectedAddress);
      } else {
        setHasSelected(false);
      }
    },
    [selectedAddress],
    500,
    selectedAddress?.address ? true : false
  );

  const {
    mainParcelID,
    setMainParcelID,
    selectedParcelsIds,
    setSelectedParcelsIds,
  } = useSelectedParcels();
  const { setIsQuickCheckModalOpened, setCurrentRatingID, setRatingIds } =
    useModal();

  let input = useRef(null);

  useEffect(() => {
    setResults([]);
    if (!searchedAddress) {
      setRatingIds([]);
      setMainParcelID([]);
      setSelectedParcelsIds([]);
    }
  }, []);

  const handleSearch = (value) => {
    Api.get(`${process.env.API_GEO_URL}${value}`).then((res) => {
      if (res) {
        if (res?.status === 504) {
          setIsSearchEngineAvailable(false);
        } else {
          setResults(
            _.remove(res.data.features, (feature) => {
              return feature.properties.score > 0.4;
            })
          );
        }
      }
    });
  };

  const resetSearch = () => {
    onReset && onReset();
  };

  const handleClickBtnGeolocalisation = async () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      setIsLoading(true);
      // const coordTest = {
      //   lat: 45.75875835892878,
      //   lng: 4.835164546966553,
      // };
      const req = await axios.get(
        `https://api-adresse.data.gouv.fr/reverse/?lon=${position.coords.longitude}&lat=${position.coords.latitude}`
      );
      // const req = await axios.get(
      //   `https://api-adresse.data.gouv.fr/reverse/?lon=${coordTest.lng}&lat=${coordTest.lat}`
      // );
      if (req.status === 200) {
        setResults(
          req.data.features.map((item) => ({
            ...item,
            properties: {
              ...item.properties,
              lat: item.geometry.coordinates[1],
              lon: item.geometry.coordinates[0],
            },
          }))
        );
        setGeoLocationSearchMode && setGeoLocationSearchMode(true);
      }
      setIsLoading(false);
    });
  };

  // Set address coordinates & address data in order to populate create rating request and get parcels request ( lat/lon )
  const handleSelectAddress = (result) => {
    onUseSelectAdress && onUseSelectAdress();
    setHasSelected(true);
    setCityCode(result.properties.citycode);
    setInfosAdresse({
      postcode: result.properties.postcode,
      name: result.properties.name,
      city: result.properties.city,
      isStreet: !!result.properties.street || false,
      label: result.properties.label,
    });

    if (onCityEqualsAddressName) {
      if (
        result.properties.name.trim().toLowerCase() ===
        result.properties.city.trim().toLowerCase()
      ) {
        onCityEqualsAddressName(true);
      } else {
        onCityEqualsAddressName(false);
      }
    }

    if (window.innerWidth <= 768) {
      onClose && onClose();
    }
    setSearchedAddress(result.properties.label);
    setAddressCoordinates({
      lat: result.properties.lat,
      lng: result.properties.lon,
    });
    setSelectedAddress({
      address: result.properties.name,
      postcode: result.properties.postcode,
      evaluate_date: new Date(),
      town: result.properties.city,
      parcel_size: 0,
      plu: '',
    });
  };

  // Hook that handle search, refresh on user input
  useEffect(() => {
    let timeout = null;
    if (searchedAddress?.length > 3) {
      setIsLoading(true);
      timeout = setTimeout(() => {
        handleSearch(searchedAddress);
        setGeoLocationSearchMode && setGeoLocationSearchMode(true);
        setIsLoading(false);
      }, 400);
    } else {
      setGeoLocationSearchMode && setGeoLocationSearchMode(false);
    }
    return () => timeout && clearTimeout(timeout);
  }, [searchedAddress]);

  // Hook triggered on address selection
  useEffect(() => {
    if (
      addressCoordinates &&
      addressCoordinates.lng &&
      addressCoordinates.lat
    ) {
      Api.get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/ciblage/by_lon_lat_multiple_selection?lat=${addressCoordinates.lat}&lon=${addressCoordinates.lng}`
      ).then((r) => setNearestParcels(r.data));
    }
  }, [addressCoordinates]);

  const handleSubmit = () => {
    let data = {
      ratings: [
        {
          ...selectedAddress,
          pivot_parcelle_id: selectedParcelsIds[0],
          land: {
            parcels_ids: selectedParcelsIds,
          },
        },
      ],
    };

    Api.get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/zones/cecim_limits`
    ).then((res) => {
      setIsNewRatingHelpModalOpen(!res.data.includes(cityCode));

      if (res.data.includes(cityCode)) {
        Api.post('/ratings.json', data).then((res) => {
          if (res.status === 200) {
            setCurrentRatingID(res.data[0].id);
            setRatingIds(res.data.map((r) => r.id));
            setIsQuickCheckModalOpened(true);
          }
        });
      }
      onClose && onClose();
    });
  };

  return (
    <div className="p-4 md:p-0 relative">
      <p className={'text-sm leading-tight sm:leading-2 mb-1'}>
        Recherchez un terrain par adresse, en fonction des territoires
        accessibles pour votre compte.{' '}
      </p>
      <div className="flex bg-white items-center border border-2 overflow-hidden rounded-xl shadow-sm w-full justify-between">
        {!hasSelected ? (
          <input
            ref={input}
            className={classNames(
              {
                'border-red-300 text-red-900 placeholder-red-300 focus:outline-none':
                  !isSearchEngineAvailable,
              },
              'rounded-xl w-full h-12 text-sm cursor-pointer ri-close-line transform duration-500 ease-in-out transition p-3 font-semibold text-blue-700'
            )}
            onChange={(e) => {
              setSearchedAddress(e.target.value);
              setHasSelected(false);
            }}
            placeholder="Adresse de la parcelle recherchée..."
          />
        ) : (
          <div className="p-4 text-blue-700 font-semibold text-sm">
            <span className="block">{infosAdresse?.name}</span>
            {infosAdresse?.isStreet && (
              <span className="block">
                {infosAdresse.postcode} {infosAdresse.city}
              </span>
            )}
          </div>
        )}
        {isMobile && !geolocationSearchMode && (
          <span className="flex items-center justify-center px-4">
            <button
              onClick={handleClickBtnGeolocalisation}
              className="border-none"
            >
              <img src={centerIco} alt="center ico" width={32} height={32} />
            </button>
          </span>
        )}
        {((searchedAddress && !!results?.length) || geolocationSearchMode) && (
          <XIcon
            onClick={() => {
              if (selectedParcelsIds?.length) {
                resetSearch();
              } else {
                if (input.current) input.current.value = '';
                setHasSelected(false);
                setResults([]);
                setNearestParcels(null);
              }
              setHasSelected(false);
              setSearchedAddress('');
              setGeoLocationSearchMode && setGeoLocationSearchMode(false);
            }}
            className="w-12 h-12 cursor-pointer ri-close-line transform duration-500 ease-in-out hover:scale-150 transition p-3 text-gray-300"
          />
        )}
        {!isSearchEngineAvailable && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col h-60 xs:h-80 md:h-56">
        {isSearchEngineAvailable && isEmpty(selectedParcelsIds) && (
          <>
            {((searchedAddress && searchedAddress.length > 0) ||
              geolocationSearchMode) && (
              <>
                <h4 className="font-bold w-full text-left border-b-1 border-gray-300 text-black mt-5 text-base my-2 sticky top-0 bg-white">
                  Résultat{results.length > 1 && 's'}:
                </h4>
                <div className="overflow-y-auto flex-grow h-0">
                  {isLoading && (
                    <div className="w-full justify-center flex p-4">
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={48}
                        width={48}
                        timeout={6000}
                      />
                    </div>
                  )}
                  {!isLoading && (
                    <div className={'divide-y-1 divide-gray-500'}>
                      {results.map((result, index) => {
                        return (
                          <div
                            key={`${index}`}
                            className={classNames(
                              'py-2 text-sm cursor-pointer',
                              {
                                'font-bold bg-blue-300 pl-2 bg-opacity-25':
                                  result.properties.label ===
                                  infosAdresse?.label,
                              }
                            )}
                            onClick={() => handleSelectAddress(result)}
                          >
                            {result.properties.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {!isEmpty(selectedParcelsIds) && (
          <div className="p-2 flex flex-col h-full">
            <h4 className="text-gray-700">
              {selectedParcelsIds.length > 1
                ? 'Le terrain est composé des parcelles suivantes : '
                : 'Le terrain est composé de la parcelle suivante : '}
            </h4>
            <div className="flex-grow h-0 overflow-auto">
              {selectedParcelsIds?.map((selectedParcelsId, index) => {
                return (
                  <div key={`selected_ID__${selectedParcelsId}__${index}`}>
                    {selectedParcelsId}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {!isSearchEngineAvailable && (
        <div className={'flex flex-row mt-2'}>
          <div className={'flex flex-col justify-center'}>
            <UseAnimations
              animation={alertCycle}
              size={24}
              strokeColor={'red'}
            />
          </div>
          <div>
            <p className={'text-sm text-bold text-red-500 leading-none'}>
              {' '}
              Le service d'adresses de l'État semble temporairement
              indisponible.{' '}
            </p>
            <p className={'text-sm text-red-500'}>
              Réessayez dans quelques secondes{' '}
            </p>
          </div>
        </div>
      )}
      <div className="mb-6 md:mb-0 mt-8 bottom-0 w-full">
        {!isEmpty(searchedAddress) || selectedParcelsIds.length !== 0 ? (
          selectedAddress?.address || selectedParcelsIds.length !== 0 ? (
            mainParcelID.length > 0 && selectedParcelsIds.length > 0 ? (
              <button
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 items-center"
                onClick={() => handleSubmit()}
              >
                <CheckIcon className={'w-8 h-8 text-white'} />
                Lancer le Quick Check
              </button>
            ) : (
              <button
                onClick={() => input.current?.focus()}
                className="w-full flex justify-center py-2  px-1 sm:px-4 border border-2 border-blue-500 rounded-xl text-xs sm:text-sm font-medium text-blue-500 items-center"
              >
                <SearchIcon className={'w-8 h-8 text-blue-500'} />
                <span className={'text-sm'}>Selectionner une parcelle</span>
              </button>
            )
          ) : (
            <button
              onClick={() => input.current?.focus()}
              className="w-full flex justify-center py-2  px-1 sm:px-4 border border-2 border-blue-500 rounded-xl text-xs sm:text-sm font-medium text-blue-500 items-center"
            >
              <SearchIcon className={'w-8 h-8 text-blue-500'} />
              <span className={'text-sm'}>Selectionner une adresse</span>
            </button>
          )
        ) : (
          <button
            onClick={() => input.current.focus()}
            className="w-full flex justify-center py-2  px-1 sm:px-4 border border-2 border-blue-500 rounded-xl text-xs sm:text-sm font-medium text-blue-500 items-center"
          >
            <SearchIcon className={'w-8 h-8 text-blue-500'} />
            <span className={'text-sm'}>Rechercher une adresse </span>
          </button>
        )}
      </div>
    </div>
  );
}
