import { GeoJSON, Marker, useMap } from 'react-leaflet';
import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { useSelectedParcels } from '../../../../hooks/useSelectedParcels';
import IconAdd from '../../../../../../assets/images/icons/plus.png';
import IconRemove from '../../../../../../assets/images/icons/close.png';
import Api from '../../../../api/Api';
import polylabel from 'polylabel';
import _ from 'lodash';
import useSWR from 'swr';

let AddIcon = new L.icon({
  iconUrl: IconAdd,
  iconSize: [21.3, 21.3],
  // shadowUrl: iconShadow,
});

let RemoveIcon = new L.icon({
  iconUrl: IconRemove,
  iconSize: [21.3, 21.3],
  // shadowUrl: iconShadow,
});

const FieldBuilderLayer = () => {
  const { mainParcelID, fieldIds, selectedParcelsIds, setSelectedParcelsIds } =
    useSelectedParcels();
  const [parcelsCollection, setParcelsCollection] = useState([]);
  useEffect(() => {
    const geojsonLayer = L.geoJSON(parcelsCollection);
    if (geojsonLayer && geojsonLayer.getBounds().isValid()) {
      map.fitBounds(geojsonLayer.getBounds());
    }
  }, [parcelsCollection]);

  const geoJsonStyle = (props) => {
    let index = selectedParcelsIds.indexOf(props.properties?.parcelle_id);
    return {
      fillColor: index !== -1 ? '#0050E3' : '#63b3ed',
      weight: 2,
      opacity: 1,
      color: '#005080',
      dashArray: 0.8,
      fillOpacity: index !== -1 ? '0.6' : '0.3',
    };
  };

  const handleSelect = ({ properties }) => {
    let index = selectedParcelsIds.indexOf(properties.parcelle_id);

    if (index !== -1) {
      setSelectedParcelsIds((selectedParcelsIds) =>
        selectedParcelsIds
          .slice(0, index)
          .concat(selectedParcelsIds.slice(index + 1))
      );
    } else {
      setSelectedParcelsIds((selectedParcelsIds) =>
        [properties.parcelle_id].concat(selectedParcelsIds)
      );
    }
    Api.get(
      `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/${properties.parcelle_id}/geo`
    ).then((res) => {
      setParcelsCollection(
        _.uniqBy(
          _.concat(parcelsCollection, res.data.touching_parcels.features),
          (feature) => feature.properties.parcelle_id
        )
      );
    });
  };
  const map = useMap();
  if (!map) return;

  const fetcher = (url) => Api.get(url).then((res) => res.data);
  const { data, error } = useSWR(
    `${process.env.BASE_URL_API}/v4/eval_parcel/parcels/terrain/geo?parcelle_ids[]=` +
      _.join(_.uniq(_.concat(mainParcelID, fieldIds)), '&parcelle_ids[]='),
    fetcher
  );

  useEffect(() => {
    if (!!data) {
      setParcelsCollection(
        _.concat(data.field_parcels.features, data.touching_parcels.features)
      );
    }
  }, [data]);

  if (!data) return <div>Loading...</div>;
  if (error) {
    return <div>Loading failed ....</div>;
  }

  return (
    <>
      {parcelsCollection.map((field_parcel, index) => {
        return (
          <GeoJSON
            key={`${field_parcel.id}__${index}`}
            data={field_parcel}
            pathOptions={geoJsonStyle(field_parcel)}
            eventHandlers={{
              click: () =>
                mainParcelID !== field_parcel.properties.parcelle_id &&
                handleSelect(field_parcel),
            }}
          >
            {mainParcelID !== field_parcel.properties.parcelle_id && (
              <Marker
                key={`marker_quick_check__${index}`}
                position={polylabel(
                  field_parcel.geometry?.coordinates,
                  1.0
                ).reverse()}
                eventHandlers={{ click: () => handleSelect(field_parcel) }}
                icon={
                  selectedParcelsIds.includes(
                    field_parcel.properties.parcelle_id
                  )
                    ? RemoveIcon
                    : AddIcon
                }
              />
            )}
          </GeoJSON>
        );
      })}
    </>
  );
};

export default FieldBuilderLayer;
