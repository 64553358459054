import React, { createContext, useEffect, useState } from 'react';

export const marketSurveyContext = createContext({});

const MarketSurveyProvider = ({ children }) => {
  const [msPrograms, setMsPrograms] = useState([]);
  const [downloadLinksDocs, setDownloadLinksDocs] = useState(null);
  const [altDownloadLinkDoc, setAltDownloadLinkDoc] = useState(null);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [isShareStudy, setIsShareStudy] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setDownloadLinksDocs(false);
    }, 30000);
  }, []);

  return (
    <marketSurveyContext.Provider
      value={{
        msPrograms,
        setMsPrograms,
        setDownloadLinksDocs,
        setAltDownloadLinkDoc,
        altDownloadLinkDoc,
        downloadLinksDocs,
        setLoadingDocs,
        loadingDocs,
        setIsShareStudy,
        isShareStudy,
      }}
    >
      {children}
    </marketSurveyContext.Provider>
  );
};

export default MarketSurveyProvider;
