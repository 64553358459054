import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import MapData from './MapData';
import iconTileLayer from '../../../../../../assets/images/icons/tileLayerIcon.png';
import centerIco from '../../../../../../assets/images/icons/center.ico';
import { titleLayersEdm } from './tileLayer';
import classNames from 'classnames';
import {
  MAP_PROGRAMME,
  PARCEL_LAYER,
  SELECT_PROGRAM_MAP,
} from '../../../../_helpers/constant';
import { WMSTileLayer } from 'react-leaflet';
import useCenterMap from '../../../../hooks/useCenterMap';
import { useSelector } from 'react-redux';

export const TitleLayerSelecter = memo(
  ({ onChange, ciblage, defaultValue, personnalizedTop, study, value }) => {
    const [open, setOpen] = useState(false);
    const [localValue, setLocalValue] = useState(defaultValue || 'lumiere');

    const handleChange = (e) => {
      onChange && onChange(e.target.value);
      setOpen(false);
      setLocalValue(e.target.value);
    };

    useEffect(() => {
      setLocalValue(value || 'lumiere');
    }, [value]);

    const content = (
      <div className="relative">
        <div
          onClick={(e) => {
            setOpen(true);
            e.stopPropagation();
          }}
          className={classNames(
            'cursor-pointer flex justify-center items-center w-8 h-8 hover:bg-gray-500',
            { 'bg-gray-500': open }
          )}
          style={{ padding: '2px' }}
        >
          <img className="w-6 h-6" src={iconTileLayer} alt="icon" />
        </div>
        {open && (
          <div
            style={{ right: 'calc(100% + 16px)' }}
            className="p-2 rounded-md absolute top-0 z-10 bg-white shadow-xl"
            onClick={(e) => e.stopPropagation()}
          >
            {Object.entries(titleLayersEdm).map(([key, value], i) => (
              <div key={key} className={classNames({ 'mt-1': i !== 0 })}>
                <label className="flex cursor-pointer items-center text-xs gap-x-1">
                  <input
                    onChange={handleChange}
                    type="radio"
                    name="tilelayer"
                    checked={localValue === key}
                    value={key}
                  />
                  <span>{value.name}</span>
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    );

    return (
      <>
        {study ? (
          <>{content}</>
        ) : (
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute bg-white border-2 border-gray-500 rounded-md"
            style={{
              zIndex: 1000,
              top: personnalizedTop ? personnalizedTop : ciblage ? 16 : 64 + 16,
              right: 10,
            }}
          >
            {content}
          </div>
        )}
      </>
    );
  }
);

const MapCustomControlsLayer = memo(
  ({ childrens = [], personnalizedTop, ciblage }) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute flex justify-end flex-col gap-y-2"
        style={{
          zIndex: 1000,
          top: personnalizedTop ? personnalizedTop : ciblage ? 16 : 64 + 16,
          right: 10,
        }}
      >
        {childrens.map((item, index) => (
          <div
            key={index}
            className="bg-white border-2 border-gray-500 rounded-md"
          >
            {item}
          </div>
        ))}
      </div>
    );
  }
);

const CenterMapControl = memo(({ onCenterMap }) => {
  return (
    <div
      title="Centrer la carte"
      onClick={(e) => {
        e.stopPropagation();
        onCenterMap && onCenterMap();
      }}
      className=" cursor-pointer flex justify-center items-center w-8 h-8 hover:bg-gray-500"
      style={{ padding: '2px' }}
    >
      <img className="w-6 h-6" src={centerIco} alt="icon" />
    </div>
  );
});

export const fluxData = [
  {
    label: 'PLU',
    key: 'flux_plu',
    render: (
      <WMSTileLayer
        url="https://data.geopf.fr/wms-v/ows"
        layers="du,psmv"
        format="image/png"
        transparent
        version="1.1.1"
        opacity={0.25} // Adjust opacity for transparency
      />
    ),
  },
  {
    label: 'QPV 2024',
    key: 'flux_qpv',
    render: (
      <WMSTileLayer
        url="https://datacarto.sig.ville.gouv.fr/map/general"
        layers="layer34"
        format="image/png"
        transparent
        version="1.3.0"
        opacity={0.5} // Adjust opacity for transparency
      />
    ),
  },
  {
    label: 'QPV 2024 bande des 300m',
    key: 'flux_qpv_bande_300',
    render: (
      <WMSTileLayer
        url="https://datacarto.sig.ville.gouv.fr/map/general"
        layers="layer35"
        format="image/png"
        transparent
        version="1.3.0"
        opacity={0.5} // Adjust opacity for transparency
      />
    ),
  },
];

export const FluxControl = memo(
  ({ values = [], onChange, right = 64, top = 10, controlled = true }) => {
    const [open, setOpen] = useState(false);
    const [localValues, setLocalValues] = useState([]);

    const handleChange = (e) => {
      const currentValue = e.target.checked
        ? localValues.concat(e.target.value)
        : localValues.filter((item) => item !== e.target.value);
      onChange && onChange(currentValue);
      setLocalValues(currentValue);
    };

    useEffect(() => {
      window.addEventListener('click', () => setOpen(false));
    }, []);

    useEffect(() => {
      if (controlled) {
        setLocalValues(values);
      }
    }, [values]);

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 1000, right, top }}
        className="absolute"
      >
        <div onClick={(e) => e.stopPropagation()} className="relative">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setTimeout(() => {
                setOpen((curr) => !curr);
              }, 100);
            }}
            className={classNames(
              'z-0 flex border-2 border-gray-500 rounded-md px-2 py-1 text-xs whitespace-nowrap cursor-pointer  justify-center items-center h-8 hover:bg-gray-500',
              { 'bg-red-600 text-white font-bold': open, 'bg-white': !open }
            )}
          >
            <span>{open ? 'Fermer' : 'Affichage Zonages'}</span>
          </div>
          {open && (
            <div
              onClick={(e) => e.stopPropagation()}
              style={{ top: 40, right: 0 }}
              className="absolute z-10 bg-white border-2 border-gray-500 p-2 rounded-md"
            >
              {fluxData.map((item, key) => (
                <label key={key} className="flex items-center gap-x-2 mt-1">
                  <input
                    checked={
                      values.includes(item.key) ||
                      localValues.includes(item.key)
                    }
                    onChange={handleChange}
                    name={item.key}
                    value={item.key}
                    type="checkbox"
                  />
                  <span className="text-xs10 whitespace-nowrap">
                    {item.label}
                  </span>
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
);

const MapDataContainer = ({
  readOnlyMode,
  dataType,
  isScrollWheelZoom,
  dataURL,
  items,
  zoomLevel,
  noNeedMarker,
  needIsochrone,
  needIsochroneVoiture,
  parcelId,
  onLoadMap,
  isSelectedProgramsMap,
  programmesNeufs,
  eductionMap,
  onlySelected,
  loadingProgramme,
  refMapData,
  onChangeCurrentProgrammePopupDate,
  data,
  hFull = false,
  minHeight = 400,
  defaultTileLayer,
  enableZonageLayer = false,
  onChangeChangeFlux,
  defaultZonageFlux,
  noCluster = false,
  isMobility = false,
  name,
}) => {
  const [currentActiveFlux, setCurrentActiveFlux] = useState(
    enableZonageLayer ? [] : fluxData.map((item) => item.key)
  );
  const { isPdfMode } = useSelector((state) => state.others);
  const [currentTileLayer, setCurrentTileLayer] = useState(defaultTileLayer);
  const programMapRef = useRef(null);

  const handleChangeTileLayer = useCallback((value) => {
    setCurrentTileLayer(value);
  }, []);

  useEffect(() => {
    if (defaultZonageFlux?.length) {
      setCurrentActiveFlux(defaultZonageFlux);
    }
  }, [defaultZonageFlux]);

  const handleChangeActiveFlux = useCallback((values) => {
    setCurrentActiveFlux(values);
    onChangeChangeFlux && onChangeChangeFlux(values);
  }, []);

  const { mapRef, handleCenterMap } = useCenterMap({
    dataType,
    programMapRef,
    needIsochroneVoiture,
  });

  return (
    <div
      style={{ minHeight }}
      className={classNames('relative flex-grow', {
        'h-full': isPdfMode || hFull,
      })}
    >
      <MapData
        mapRef={mapRef}
        isMobility={isMobility}
        name={name}
        noCluster={noCluster}
        activeFlux={currentActiveFlux}
        minHeight={minHeight}
        data={data}
        ref={refMapData}
        onChangeCurrentProgrammePopupDate={onChangeCurrentProgrammePopupDate}
        loadingProgramme={loadingProgramme}
        isPdfMode={isPdfMode}
        noNeedMarker={noNeedMarker}
        zoomLevel={zoomLevel}
        readOnlyMode={readOnlyMode}
        dataType={dataType}
        isScrollWheelZoom={isScrollWheelZoom}
        dataURL={dataURL}
        items={items}
        needIsochrone={needIsochrone}
        parcelId={parcelId}
        onLoadMap={onLoadMap}
        isSelectedProgramsMap={isSelectedProgramsMap}
        programmesNeufs={programmesNeufs}
        needIsochroneVoiture={needIsochroneVoiture}
        eductionMap={eductionMap}
        currentTileLayer={currentTileLayer}
        onlySelected={onlySelected}
        onChangeTitleLayer={handleChangeTileLayer}
        programMapRef={programMapRef}
        enableZonageLayer={enableZonageLayer}
      />
      {!isPdfMode && (
        <MapCustomControlsLayer
          childrens={[
            <TitleLayerSelecter
              study
              value={currentTileLayer}
              onChange={handleChangeTileLayer}
            />,
            ...([MAP_PROGRAMME, SELECT_PROGRAM_MAP].includes(dataType)
              ? [<CenterMapControl onCenterMap={handleCenterMap} />]
              : []),
          ]}
        />
      )}
      {(dataType === PARCEL_LAYER || enableZonageLayer) && !isPdfMode && (
        <FluxControl
          values={currentActiveFlux}
          onChange={handleChangeActiveFlux}
        />
      )}
    </div>
  );
};

export default memo(MapDataContainer);
