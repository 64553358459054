import React, { useMemo } from 'react';
import 'rc-slider/assets/index.css';
import SwitchInput from '../../atoms/switch-input/SwitchInput';
import classNames from 'classnames';
import RangeInput from '../../molecules/range-input/RangeInput';
import SliderInput from '../../molecules/slider-input/SliderInput';
import { useDispatch, useSelector } from 'react-redux';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';
import {
  PRICE_NEW_RANGE_STEP,
  PRICE_NEW_RANGE_MIN,
  PRICE_NEW_RANGE_MAX,
  EVOLUTION_PRICE_NEW_STEP,
  EVOLUTION_PRICE_NEW_MIN,
  EVOLUTION_PRICE_NEW_MAX,
  PRICE_OLD_RANGE_STEP,
  PRICE_OLD_RANGE_MIN,
  PRICE_OLD_RANGE_MAX,
  EVOLUTION_PRICE_OLD_STEP,
  EVOLUTION_PRICE_OLD_MIN,
  EVOLUTION_PRICE_OLD_MAX,
} from '../../../constants/ciblageDefaultValues';

export const ThreeButtonsSwitch = ({ onChange, value }) => {
  return (
    <div className="three-button-switch flex items-center gap-x-2 text-xs xl:text-base">
      <button
        className={classNames({ active: value === true })}
        onClick={(e) => {
          e.stopPropagation();
          onChange && onChange(true);
        }}
      >
        Oui
      </button>
      <button
        className={classNames({ active: value === false })}
        onClick={(e) => {
          e.stopPropagation();
          onChange && onChange(false);
        }}
      >
        Non
      </button>
      <button
        className={classNames({ active: value === null })}
        onClick={(e) => {
          e.stopPropagation();
          onChange && onChange(null);
        }}
      >
        Indifférent
      </button>
    </div>
  );
};

export default function FilterMarcheImmobiler() {
  const { filtersCibler } = useSelector((state) => state.ciblageCreate);
  const { marcherImmobilier } = useMemo(() => filtersCibler, [filtersCibler]);
  const dispatch = useDispatch();

  const setValueAndEnableMarcheImmo = (key, value) => {
    dispatch(
      ciblageCreateActions.setFilterCibler({
        ...filtersCibler,
        isEnabledMarcheImmobilier: true,
        marcherImmobilier: { ...marcherImmobilier, [key]: value },
      })
    );
  };

  const handleChange = (key) => (value) => {
    if (['isOldEnabled', 'isNewEnabled'].includes(key) && value) {
      setValueAndEnableMarcheImmo(key, value);
    } else if (key === 'hasNewProjectsSinceFiveYear') {
      dispatch(
        ciblageCreateActions.setFilterCibler({
          ...filtersCibler,
          isEnabledMarcheImmobilier: true,
          marcherImmobilier: {
            ...marcherImmobilier,
            [key]: value,
            isNewEnabled: true,
          },
        })
      );
    } else {
      dispatch(
        ciblageCreateActions.setFilterCiblerMarcherImmobilier({
          ...(marcherImmobilier || {}),
          [key]: value,
        })
      );
    }
  };

  const handleClickElement = (key) => () => {
    setValueAndEnableMarcheImmo(key, true);
  };

  return (
    <div className="w-full" onClick={(e) => e.stopPropagation()}>
      <div className="flex justify-between items-center bg-gray-300 py-2 px-4 rounded-t-lg">
        <h3 className="font-semibold text-base">NEUF</h3>
        <SwitchInput
          onChange={handleChange('isNewEnabled')}
          value={marcherImmobilier?.isNewEnabled}
        />
      </div>
      <div
        className={classNames(
          'border-1 border-gray-300 py-4 px-2 rounded-b-lg bg-gray-100',
          {
            'opacity-50': !marcherImmobilier?.isNewEnabled,
          }
        )}
      >
        <div className="mt-2">
          <p className="text-sm">
            Les communes avec un projet immobilier neuf sur les 5 dernières
            années
          </p>
          <ThreeButtonsSwitch
            value={marcherImmobilier?.hasNewProjectsSinceFiveYear}
            onChange={handleChange('hasNewProjectsSinceFiveYear')}
            disabled={!marcherImmobilier?.isNewEnabled}
          />
        </div>
        <div className="mt-4">
          <p className="text-sm mb-2">
            Prix immobilier neuf / m² sur la dernière année
          </p>
          <RangeInput
            step={PRICE_NEW_RANGE_STEP}
            min={PRICE_NEW_RANGE_MIN}
            max={PRICE_NEW_RANGE_MAX}
            onChange={handleChange('newPriceRange')}
            value={marcherImmobilier?.newPriceRange}
            disabled={!marcherImmobilier?.isNewEnabled}
            onClick={handleClickElement('isNewEnabled')}
          />
        </div>
        <div className=" mt-4 text-sm">
          <p className="text-sm mb-2">
            Evolution du prix de l'immobilier neuf (dernière année)
          </p>
          <div className="w-full flex items-center">
            <div className="flex-grow">
              <SliderInput
                min={EVOLUTION_PRICE_NEW_MIN}
                max={EVOLUTION_PRICE_NEW_MAX}
                step={EVOLUTION_PRICE_NEW_STEP}
                onChange={handleChange('evolutionNewPrice')}
                value={marcherImmobilier?.evolutionNewPrice}
                disabled={!marcherImmobilier?.isNewEnabled}
                onClick={handleClickElement('isNewEnabled')}
              />
            </div>
            <p>% (limite inférieure)</p>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center bg-gray-300 py-2 px-4 rounded-t-lg mt-6">
        <h3 className="font-semibold text-base">ANCIEN</h3>
        <SwitchInput
          value={marcherImmobilier?.isOldEnabled}
          onChange={handleChange('isOldEnabled')}
        />
      </div>
      <div
        className={classNames(
          'border-1 border-gray-300 py-4 px-2 rounded-b-lg bg-gray-100',
          {
            'opacity-50': !marcherImmobilier?.isOldEnabled,
          }
        )}
      >
        <div className="mt-4">
          <p className="text-sm mb-2">
            Prix immobilier ancien / m² sur la dernière année
          </p>
          <RangeInput
            step={PRICE_OLD_RANGE_STEP}
            min={PRICE_OLD_RANGE_MIN}
            max={PRICE_OLD_RANGE_MAX}
            value={marcherImmobilier?.oldPriceRange}
            onChange={handleChange('oldPriceRange')}
            disabled={!marcherImmobilier?.isOldEnabled}
            onClick={handleClickElement('isOldEnabled')}
          />
        </div>
        <div className="mt-4 text-sm">
          <p className="text-sm mb-2">
            Evolution du prix de l'immo ancien (5 dernières années)
          </p>
          <div className="w-full flex items-center">
            <div className="flex-grow">
              <SliderInput
                min={EVOLUTION_PRICE_OLD_MIN}
                max={EVOLUTION_PRICE_OLD_MAX}
                step={EVOLUTION_PRICE_OLD_STEP}
                value={marcherImmobilier?.evolutionOldPrice}
                onChange={handleChange('evolutionOldPrice')}
                disabled={!marcherImmobilier?.isOldEnabled}
                onClick={handleClickElement('isOldEnabled')}
              />
            </div>
            <p>% (limite inférieure)</p>
          </div>
        </div>
      </div>
    </div>
  );
}
