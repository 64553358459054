import ReactSelect from 'react-select';
import React, { useEffect, useMemo, useState } from 'react';

const Option = (props) => {
  const { innerProps, innerRef, selectedAll } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
    >
      <input
        type="checkbox"
        checked={props.isSelected || selectedAll}
        onChange={() => null}
        className="mr-3 h-4 w-4 cursor-pointer"
      />
      <label className="cursor-pointer">{props.label}</label>
    </div>
  );
};

const handleChangeFunc = ({
  keyValue,
  value,
  actionMeta,
  onChange,
  setSelectedAll,
  setValueSelect,
  selectAllValue,
  options,
  selectedAll,
  optionsSelect,
}) => {
  const selectedAllFunc = () => {
    onChange && onChange(options);
    setSelectedAll(true);
    setValueSelect(selectAllValue);
  };

  const { action, option, removedValue } = actionMeta;
  if (
    (action === 'deselect-option' || action === 'remove-value') &&
    ((option && option[keyValue]) === '<ALL>' ||
      (removedValue && removedValue[keyValue]) === '<ALL>')
  ) {
    onChange && onChange([]);
    setSelectedAll(false);
    setValueSelect([]);
  } else if (action === 'select-option' && option[keyValue] === '<ALL>') {
    selectedAllFunc();
  } else if (action === 'clear') {
    onChange && onChange([]);
    setSelectedAll(false);
    setValueSelect([]);
  } else {
    let currentValue = [];
    if (selectedAll) {
      currentValue = optionsSelect.filter(
        (item) => !['<ALL>', option[keyValue]].includes(item[keyValue])
      );
      setValueSelect(currentValue);
      onChange && onChange(currentValue);
      setSelectedAll(false);
    } else {
      if (value.length === options.length) {
        selectedAllFunc();
      } else {
        currentValue = options.filter((item) =>
          value.map((el) => el[keyValue]).includes(item[keyValue])
        );
        setValueSelect(currentValue);
        onChange && onChange(currentValue);
        setSelectedAll(false);
      }
    }
  }
};

const selectedAllValue = ({ keyValue, keyLabel }) => {
  return [{ [keyLabel]: 'Toutes', [keyValue]: '<ALL>' }];
};

export default function SelectMultiple({
  placeholder,
  options = [],
  keyValue,
  keyLabel,
  value,
  onChange,
  disabled,
  onClick,
  onBlur,
}) {
  const [optionsSelect, setOptionSelect] = useState([]);
  const [valueSelect, setValueSelect] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const selectAllValue = useMemo(
    () => selectedAllValue({ keyValue, keyLabel }),
    [keyValue, keyLabel]
  );

  useEffect(() => {
    if (value && value.length) {
      if (value.length === options.length) {
        setSelectedAll(true);
        setValueSelect(selectAllValue);
      } else {
        setValueSelect(
          value.map((item) => ({
            label: item[keyLabel],
            value: item[keyValue],
          }))
        );
      }
    }
    setOptionSelect([
      { [keyLabel]: 'Sélectionner Tout', [keyValue]: '<ALL>' },
      ...options.map((item) => ({
        label: item[keyLabel],
        value: item[keyValue],
      })),
    ]);
  }, [value, options]);

  const handleChange = (value, actionMeta) => {
    handleChangeFunc({
      keyValue,
      value,
      actionMeta,
      onChange,
      setSelectedAll,
      setValueSelect,
      options,
      selectAllValue,
      selectedAll,
      optionsSelect,
    });
  };

  return (
    <div className="w-full h-full block" onClick={onClick}>
      <ReactSelect
        isSearchable
        value={valueSelect}
        options={optionsSelect}
        placeholder={placeholder}
        isMulti
        onChange={handleChange}
        components={{
          Option: (props) => <Option {...props} selectedAll={selectedAll} />,
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        captureMenuScroll={false}
        isDisabled={disabled}
        onBlur={onBlur}
      />
    </div>
  );
}
