import { createSlice } from '@reduxjs/toolkit';
import { defaultValueTerrain } from './ciblageCreate';

const initialState = {
  ciblages: [],
  ciblage: null,
  parcels: [],
  checkedParcels: [],
  nbPage: 1,
  nbParcel: null,
  currentPage: 1,
  loading: null,
  sortingParcels: null,
  communesParcels: [],
  selectedCommunes: [],
  filtersTerrain: defaultValueTerrain,
  nbParcelOnEdit: 0,
  allFiltersJson: null,
};

export const ciblageResultSlice = createSlice({
  name: 'ciblageResult',
  initialState,
  reducers: {
    setCiblages(state, action) {
      state.ciblage = null;
      state.communesParcels = [];
      state.selectedCommunes = [];
      state.ciblages = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCiblage(state, action) {
      state.ciblage = action.payload;
    },
    setParcels(state, action) {
      state.parcels = action.payload;
    },
    setNbPage(state, action) {
      state.nbPage = action.payload;
    },
    setNbParcel(state, action) {
      state.nbParcel = action.payload;
      state.nbParcelOnEdit = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setCheckedParcels(state, action) {
      state.checkedParcels = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSortingParcels(state, action) {
      state.parcels = [];
      state.currentPage = 1;
      state.sortingParcels = action.payload;
    },
    setCommunesParcels(state, action) {
      state.selectedCommunes = [];
      state.communesParcels = action.payload;
    },
    setSelectedCommunes(state, action) {
      state.selectedCommunes = action.payload;
    },
    setFiltersTerrain(state, action) {
      state.filtersTerrain = action.payload;
    },
    setNbPageOnEdit(state, action) {
      state.nbParcelOnEdit = action.payload;
    },
    setAllFiltersJson(state, action) {
      state.allFiltersJson = action.payload;
    },
    reset: () => initialState,
  },
});

export const ciblageResultActions = ciblageResultSlice.actions;

export default ciblageResultSlice.reducer;
