import useSWR from 'swr';
import { getFetcher } from '../../../api/fetcher';
import LoadingSection from './LoadingSection';
import React, { useMemo } from 'react';
import classNames from 'classnames';

const subtitlesNeufProgrammeLoc = ['Localisation des programmes', 'Prix moyen'];

const MsContent = ({ url, isPdfRenderMode, data = [], isNeufProgrammeLoc }) => {
  const res = url && useSWR(url, getFetcher);

  const arrayData = useMemo(() => {
    if (!url) {
      return data;
    }
    return res?.data || [];
  }, [data, res, url]);

  if (!arrayData?.length) return <LoadingSection />;

  return (
    <div
      className={classNames('flex items-center px-4', {
        'h-full': isPdfRenderMode,
      })}
    >
      <ul className="list-disc">
        {arrayData.map((p, index) => {
          return (
            <li
              className={classNames('mt-4', { 'text-base': isPdfRenderMode })}
              key={`part_${index}`}
            >
              {isNeufProgrammeLoc && (
                <h4
                  className={classNames(
                    'font-semibold underline',
                    { 'text-text-lg': isPdfRenderMode },
                    { 'text-base 2xl:text-lg': !isPdfRenderMode }
                  )}
                >
                  {subtitlesNeufProgrammeLoc[index]}
                </h4>
              )}
              <div dangerouslySetInnerHTML={{ __html: p }}></div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MsContent;
