import React, { memo } from 'react';
import MsVerticalBarChart from './MsVerticalBarChart';
import BoxesContainer from '../../BoxesContainer';
import LoadingSection from './LoadingSection';
import useSWR from 'swr';
import { getFetcherV5 } from '../../../api/fetcher';

const SynopticVisionAndDynamicCommune = ({ data }) => {
  const { data: dataPcs } = useSWR(
    `${process.env.BASE_URL_API}/v5/eval_parcel/pcs/latest_pcs_date_available`,
    getFetcherV5
  );

  return (
    <div className="flex-grow grid grid-cols-2 gap-x-5">
      <div>
        {data?.boxes && data?.boxes?.length ? (
          <BoxesContainer boxes={data.boxes} isEDM />
        ) : (
          <LoadingSection />
        )}
      </div>
      <div>
        <MsVerticalBarChart
          title={
            /* HTML */ `<span class="font-semibold"
                >Nombre de PC > à 5 logements accordés dans la commune</span
              >
              -
              <span class="font-normal"
                >${dataPcs?.latest_pc_data} (SITADEL 2)</span
              >`
          }
          data={data?.graphData}
        />
      </div>
    </div>
  );
};

export default memo(SynopticVisionAndDynamicCommune);
