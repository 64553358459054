import React from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import {
  YELLOW_THEME,
  BLUE_THEME,
  DARK_BLUE_THEME,
  GRAY_THEME,
} from '../../../_helpers/constant';
import { orderBy } from 'lodash';

export function shouldTextBeBlack(backgroundcolor) {
  return computeLuminence(backgroundcolor) > 0.2;
}

export function computeLuminence(backgroundcolor) {
  var colors = hexToRgb(backgroundcolor);

  var components = ['r', 'g', 'b'];
  for (var i in components) {
    var c = components[i];

    colors[c] = colors[c] / 255.0;

    if (colors[c] <= 0.03928) {
      colors[c] = colors[c] / 12.92;
    } else {
      colors[c] = Math.pow((colors[c] + 0.055) / 1.055, 2.4);
    }
  }

  var luminence = 0.2126 * colors.r + 0.7152 * colors.g + 0.0722 * colors.b;

  return luminence;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const TableData = ({
  data,
  loading,
  theme,
  isPdfRenderMode,
  highlightRow = [],
  inSwitch,
}) => {
  return (
    <div className="w-full h-full overflow-y-auto">
      <table
        className="w-full"
        style={inSwitch ? { tableLayout: 'fixed', width: '100%' } : {}}
      >
        <thead>
          <tr
            className={classNames(
              {
                'text-yellow-100 bg-green-700 ': theme === YELLOW_THEME,
                'text-blue-100 bg-blue-500 ': theme === BLUE_THEME,
                'text-blue-100 bg-blue-800 ': theme === DARK_BLUE_THEME,
                'text-white bg-gray-600': theme === GRAY_THEME,
              },
              { 'text-sm': isPdfRenderMode },
              { 'text-xs': !isPdfRenderMode },
              ' font-medium first-letter:uppercase tracking-wider h-14 text-center'
            )}
          >
            {data?.headers?.map((item) => (
              <th
                style={{ fontSize: 12 }}
                colSpan={item.colSpan}
                key={uniqid()}
                rowSpan={item.rowSpan}
                className="px-1"
              >
                <div className="th-content">
                  <div className="clamp-3">{item.name}</div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.body?.map((item, index) => {
            return (
              <tr
                key={uniqid()}
                className={classNames('border-b-1 border-gray-500', {
                  'bg-blue-100':
                    highlightRow &&
                    highlightRow.length > 0 &&
                    highlightRow.includes(index),
                })}
              >
                {orderBy(item, ['order'], ['asc']).map((content, i) => {
                  return (
                    <td
                      style={{
                        backgroundColor:
                          content.color ||
                          (index % 2 != 0 ? '#D8D7DC' : 'white'),
                        color: shouldTextBeBlack(content.color || '#ffffff')
                          ? 'black'
                          : 'white',
                      }}
                      className={classNames(
                        'tracking-wider font-medium text-gray-900 text-center',
                        {
                          'text-sm py-1.5 px-3': isPdfRenderMode,
                          'py-1 2xl:py-1.5 px-3 text-xs 2xl:text-sm':
                            !isPdfRenderMode,
                          'text-center': i !== 0,
                        }
                      )}
                      colSpan={content.colSpan}
                      rowSpan={content.rowSpan}
                      key={uniqid()}
                    >
                      {content.value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableData;
