import { useEffect, useState } from 'react';

export default () => {
  const [paddingTop, setPaddingTop] = useState(0);

  useEffect(() => {
    const fixPaddingTop = () => {
      const navbar = document.getElementById('navbar');
      const underNavbar = document.getElementById('bottom-navbar');
      if ((navbar, underNavbar)) {
        setPaddingTop(navbar.clientHeight + underNavbar.clientHeight);
      }
    };
    fixPaddingTop();
    window.addEventListener('resize', fixPaddingTop);
    return () => {
      window.removeEventListener('resize', fixPaddingTop);
    };
  }, []);

  return paddingTop;
};
