import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NAME } from '../../../constants/constants';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';

export default function SelectedSearchBy() {
  const { searchCommunesBy } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(ciblageCreateActions.setReturnSearchCommunesBy(true));
  };

  return (
    <>
      <div className="px-4">
        <label className="flex justify-between text-sm xl:text-base items-center">
          <span>
            Vous sélectionnez{' '}
            {searchCommunesBy?.code === NAME ? 'votre commune' : 'vos communes'}{' '}
            :
          </span>
          <span
            onClick={handleClick}
            className="font-medium hover:font-semibold text-teal-500 cursor-pointer"
          >
            Modifier
          </span>
        </label>
        <input
          type="text"
          className="w-full mt-2 text-sm xl:text-base h-12 rounded-md border-1 border-gray-500 font-semibold"
          readOnly
          value={searchCommunesBy?.label}
        />
      </div>
    </>
  );
}
