import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { MailIcon } from '@heroicons/react/solid';

export default function NewRatingHelpModal({ isOpen, setIsOpen }) {
  // useEffect(() => {
  //     if (localStorage.getItem('sessionCookie4NewRatingHelpModalState')) {
  //         setIsOpen(localStorage.getItem('sessionCookie4NewRatingHelpModalState') === 'true')
  //     }
  // }, [])

  function closeModal() {
    // localStorage.setItem('sessionCookie4NewRatingHelpModalState', "false");
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="hidden rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          style={{ zIndex: 1001 }}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames({
                'fixed inset-0 bg-black bg-opacity-25': isOpen,
              })}
            />
          </Transition.Child>

          <div
            className={classNames({ 'fixed inset-0 overflow-y-auto': isOpen })}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Pour consulter cette zone, étendez votre abonnement !
                  </Dialog.Title>
                  {/*<div className="mt-2">*/}
                  {/*    <div className="text-sm text-gray-800">*/}
                  {/*        <span className={'font-bold'}>Par adresse</span>*/}
                  {/*        <p>Dans la section "Nouvelle Etude", recherchez l'adresse du terrain à analyser.</p>*/}
                  {/*    </div>*/}
                  {/*    <div className="text-sm text-gray-800">*/}
                  {/*        <span className={'font-bold'}>Mode exploration - selection sur la carte</span>*/}
                  {/*        <p>En cliquant sur la carte vous pourrez selectionner le point de départ de votre terrain. </p>*/}
                  {/*    </div>*/}
                  {/*</div>*/}

                  <div className="mt-4 flex flex-row  space-x-3 content-end">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Plus tard
                    </button>
                    <a
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      href={'mailto:equipe@evalparcel.com'}
                    >
                      <MailIcon className={'w-6 h-6 mr-2 '} />
                      Nous contacter
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
