import React, { createContext, useRef, useState } from 'react';

export const MsMapContext = createContext(null);

const MsMapProvider = ({ children }) => {
  const mapProgramRef = useRef(null);
  const [currentHoverTrandId, setCurrentHoverTrandId] = useState(null);

  return (
    <MsMapContext.Provider
      value={{
        mapProgramRef,
        currentHoverTrandId,
        setCurrentHoverTrandId,
      }}
    >
      {children}
    </MsMapContext.Provider>
  );
};

export default MsMapProvider;
