import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const ListItem = ({ setShowSoon, value, onSelect, setOpen, id }) => {
  const [openList, setOpenList] = useState(false);

  let { logOut } = useAuth();
  const history = useHistory();
  return (
    <>
      <div
        // to={"/users/sign_out"}
        id={id}
        onMouseEnter={() =>
          !value.isFeatureAvailable ? setShowSoon(true) : null
        }
        onMouseLeave={() =>
          !value.isFeatureAvailable ? setShowSoon(false) : null
        }
        onClick={value.onClickCallback}
        className={classNames(
          'grid grid-cols-1 dropdown__item cursor-pointer hover:underline'
          // openList && value?.subItems?.length > 0
          //     ? 'border-b border-grey-light-1'
          //     : '',
          // value?.subItems?.length > 0 ? 'cursor-pointer' : ''
        )}
      >
        <p className={'w-full py-2 px-2 xl:py-3 text-xs xl:text-base'}>
          {value.name}
        </p>
      </div>
    </>
  );
};

const Dropdown = ({ onSelect, title, icon, setUrl, items, noUser, url }) => {
  const [open, setOpen] = useState(false);
  const [showSoon, setShowSoon] = useState(false);

  return (
    <div style={{ zIndex: 99999 }} onClick={setUrl} className="relative">
      <div>
        <button
          data-testid="handleClick"
          onClick={() => !noUser && setOpen(!open)}
          variant="secondary"
          className="dropdown__button text-gray-700 flex flex-row items-center gap-x-2 text-xs 2xl:text-base"
        >
          {title}
          {open ? (
            <ChevronUpIcon className={`w-5 h-5 `} />
          ) : (
            <ChevronDownIcon className={'w-5 h-5'} />
          )}
        </button>
      </div>

      {open && (
        <div className="z-50 bg-white rounded-tr-none rounded-tl-none origin-top-left absolute right-0 xl:left-0 rounded-md shadow-lg w-48">
          <div>
            {items
              ?.filter((el) => !el.hide)
              ?.map((item, i) => (
                <ListItem
                  id={`item_${i}`}
                  setShowSoon={setShowSoon}
                  setOpen={setOpen}
                  onSelect={onSelect}
                  value={item}
                  key={i}
                />
              ))}
          </div>
          {showSoon && (
            <div className="w-fit rounded-lg p-3 absolute top-0 left-10 bg-white shadow-lg text-blue-600 text-sm">
              Bientôt&nbsp;disponible
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
