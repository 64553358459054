import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import * as yup from 'yup';
import { Formik } from 'formik';
import useOffsetTop from '../../hooks/useOffsetTop';
import { TextField } from '../auth/FormUserInformations';
import { useAuth } from '../../hooks/useAuth';
import useDebounce from '../../_helpers/useDebounce';
import { activateTrial, checkTrialCode } from '../../store/slices/others';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { getBgLogin } from '../../store/actions/others';
import bg1 from '../../../../assets/images/paris_aerial2.jpg';
import final from '../../../../assets/images/final.mp4';
import { isImageUrl } from '../auth/AuthContainer';

const formSchema = yup.object({
  first_name: yup.string().required('Champ obligatoire'),
  last_name: yup.string().required('Champ obligatoire'),
  company: yup.string().required('Champ obligatoire'),
  trial_code: yup.string().required('Champ obligatoire'),
  phone_number: yup.string().required('Champ obligatoire'),
});

const initValue = {
  first_name: '',
  last_name: '',
  company: '',
  trial_code: '',
  phone_number: '',
};

const ActivateTrialPage = () => {
  const location = useLocation();
  const paddingTop = useOffsetTop();
  const { bgLogin } = useSelector((state) => state.others);
  const { checkSession, user, canActivateTrial } = useAuth();
  const formikRef = useRef(null);
  const [trialCode, setTrialCode] = useState('');
  const [errorTrialCode, setErrorTrialCode] = useState({
    error: false,
    message: '',
  });
  const dispatch = useDispatch();

  const background = useMemo(
    () => (bgLogin === 'paris_aerial2.jpg' ? bg1 : final),
    [bgLogin]
  );

  useEffect(() => {
    checkSession();
    dispatch(getBgLogin());
  }, []);

  useEffect(() => {
    !canActivateTrial && window.location.assign('/');
  }, [canActivateTrial]);

  useEffect(() => {
    if (user) {
      const { company, last_name, first_name } = user;
      if (formikRef.current) {
        formikRef.current.setValues({
          company: company || '',
          last_name: last_name || '',
          first_name: first_name || '',
          phone_number: '',
          trial_code: '',
        });
        if (location.search?.length) {
          const params = new URLSearchParams(location.search);
          if (params.get('code')) {
            const trialCode = params.get('code').trim();
            setTimeout(() => {
              formikRef.current.setFieldValue('trial_code', trialCode);
              setTimeout(() => {
                setTrialCode(trialCode);
              }, 200);
            }, 200);
          }
        }
      }
    }
  }, [user, location]);

  const handleSubmitForm = async (values, _helpers) => {
    if (user && !errorTrialCode?.error) {
      const resp = await activateTrial({ ...values, user_id: user.id });
      if (resp.status === 200) {
        window.location.assign('/');
      } else {
        alert('Error !!!, please try again !');
      }
    }
  };

  useDebounce(async () => {
    const resp = await checkTrialCode(trialCode);
    setErrorTrialCode(
      resp.status === 200
        ? { error: false, message: '' }
        : { error: true, message: resp.data.errors[0] }
    );
  }, [trialCode]);

  return (
    <div
      style={{
        paddingTop: paddingTop + 64,
      }}
      className="h-screen flex bg-gray-100 justify-center items-center max-h-screen pb-4 relative"
    >
      <div className="top-0 left-0 w-full h-full z-0 absolute overflow-hidden">
        {isImageUrl(background) ? (
          <img
            src={background}
            className="h-full w-full transform scale-125 object-cover object-center"
            alt="bg"
          />
        ) : (
          <video
            className="h-full w-full object-cover object-center"
            loop
            autoPlay
          >
            <source src={background} type="video/mp4" />
          </video>
        )}
      </div>
      <div className="max-w-2xl rounded-lg shadow-lg p-6 bg-white w-full relative z-10">
        <h1 className="font-bold text-lg lg:text-xl text-center">
          Afin d'activer votre période d'essai,
          <br /> complétez ces quelques informations !
        </h1>
        <Formik
          innerRef={formikRef}
          initialValues={initValue}
          validationSchema={formSchema}
          onSubmit={handleSubmitForm}
        >
          {({
            handleChange,
            handleSubmit,
            handleBlur,
            values,
            errors,
            isValid,
            touched,
          }) => (
            <form onSubmit={handleSubmit} className="mt-6">
              <div className="grid grid-cols-2 gap-x-6">
                <TextField
                  required
                  text="text"
                  label="Prénom"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  error={touched.first_name && errors.first_name}
                />
                <TextField
                  required
                  text="text"
                  label="Nom"
                  className="mt-6 md:mt-0"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  error={touched.last_name && errors.last_name}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-6 mt-6">
                <TextField
                  required
                  text="text"
                  label="Société"
                  name="company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company}
                  error={touched.company && errors.company}
                />
                <TextField
                  required
                  text="text"
                  label="Confirmez votre téléphone portable"
                  className="mt-6 md:mt-0"
                  name="phone_number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                  error={touched.phone_number && errors.phone_number}
                />
              </div>
              <div className="mt-6">
                <TextField
                  required
                  text="text"
                  label="Code d'essai"
                  name="trial_code"
                  onChange={(e) => {
                    handleChange(e);
                    setTrialCode(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.trial_code}
                  error={touched.trial_code && errors.trial_code}
                />
                {errorTrialCode?.error && (
                  <div className="text-red-500 text-xs12">
                    {errorTrialCode.message}
                  </div>
                )}
              </div>
              <div className="mt-6 flex justify-center">
                <button
                  type="submit"
                  disabled={!isValid || errorTrialCode?.error}
                  className={classNames(
                    'text-white  bg-blue-500 hover:bg-blue-700 w-full lg:w-1/2 py-3 px-8 rounded-md',
                    {
                      'opacity-50': !isValid || errorTrialCode?.error,
                    }
                  )}
                >
                  Valider
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ActivateTrialPage;
