import axios from 'axios';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import Api, { apiV5 } from '../../api/Api';

const ErrorHandler = () => {
  const [nbError, setNbError] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const errorStatus = [502, 503, 500];
    Api.interceptors.response.use(
      (response) => response,
      (error) => {
        const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

        if (!expectedError) {
          setNbError((state) => state + 1);
        }

        if (expectedError) {
          return Promise.reject(error);
        }

      }
    );
    apiV5.interceptors.response.use(
      (response) => response,
      (error) => {
        const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

        if (!expectedError) {
          setNbError((state) => state + 1);
        }

        if (expectedError) {
          return Promise.reject(error);
        }
      }
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

        if (!expectedError) {
          setNbError((state) => state + 1);
        }

        if (expectedError) {
          return Promise.reject(error);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (nbError >= 4 && !showMessage) {
      setNbError(0);
      setShowMessage(true);
      swal({
        icon: 'info',
        text: `Nous rencontrons des problèmes avec notre serveur de données. Veuillez réessayer dans quelques instants. N'hésitez pas à contacter le support via le chat en bas à droite !`,
        className: 'error-api',
      });
    }
  }, [nbError, showMessage]);

  useLayoutEffect(() => {
    setNbError(0);
    setShowMessage(false);
  }, [location?.pathname]);

  return null;
};

export default ErrorHandler;
