import React, {useEffect, useState} from 'react';
import axios from 'axios';

const MunicipalityAveragePrice = props => {
    const {
        national_code,
        className
    } = props;

    const [averagePrice, setAveragePrice] = useState()

    useEffect(() => {
        axios.get(
           `${process.env.BASE_URL_API}/v4/eval_parcel/marche_immobilier/${national_code}/prix_moyen_tva_normale`
        ).then(res => {
          setAveragePrice(res.data.metric_commune)
        })
    }, [])

  return (
        <div className={className}>{typeof averagePrice !== 'undefined' && (
          <p>
            Prix moyen / m² - toutes TVA - hors stationnement - logement neuf : {averagePrice}
          </p>
        )}</div>
    )
};

export default MunicipalityAveragePrice;
