import React, { memo } from 'react';
import { useTable } from 'react-table';
import classNames from 'classnames';
import _, { orderBy } from 'lodash';

const borderStyle = {
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
};

function useInstance(instance) {
  const { allColumns } = instance;

  let rowSpanHeaders = [];

  allColumns.forEach((column, i) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0 },
      ];
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}
const RowSpanningPart3Table = ({ origData, isPdfRenderMode }) => {
  const data = React.useMemo(() => {
    const newData = [];
    origData.forEach((program) => {
      const { infos, ...rest } = program;
      infos.forEach((info) => {
        newData.push({
          programme: JSON.stringify(rest, null, 2),
          info: {
            information_type: info.information_type,
            t1: info.t1,
            t2: info.t2,
            t3: info.t3,
            t4: info.t4,
            t5: info.t5,
            t6: info.t6,
            t6_plus: info.t6_plus,
          },
        });
      });
    });
    return newData;
  }, [origData]);

  const bgsData = React.useMemo(() => {
    let object = {};
    const programmesName = _.uniq(
      data.map((item) => {
        const json = JSON.parse(item.programme);
        return json.program_name;
      })
    );
    programmesName.forEach((key, index) => {
      object = {
        ...object,
        [key]: index % 2 === 0 ? true : false,
      };
    });
    return object;
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Programme',
        accessor: 'programme',
        enableRowSpan: true,
        Cell: ({ value }) => {
          const data = JSON.parse(value);

          return (
            <div className="inline-block px-7">
              <div className="flex flex-row gap-x-8 items-center">
                <div className="min-h-8 min-w-8 bg-greenMarker rounded-full text-white font-semibold flex items-center justify-center">
                  {data.number}
                </div>
                <div className="flex flex-col gap-y-1 text-gray-800">
                  <p className="text-lg font-semibold uppercase">
                    {data.program_name}
                  </p>
                  <p className="text-sm">{data.program_address}</p>
                  <p className="text-sm">
                    {data.program_code_postal} {data.program_commune}
                  </p>
                  <p className="text-sm font-medium">
                    {data.program_etat_avancement}
                  </p>
                  <p className="text-sm font-semibold">
                    {data.program_promoteur}
                  </p>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'information',
        accessor: 'info.information_type',
        Cell: ({ value }) => <div className="py-1 px-7">{value}</div>,
      },
      {
        Header: 'Ch/T1',
        accessor: 'info.t1',
        Cell: ({ value }) => <div className="py-1 px-7">{value}</div>,
      },
      {
        Header: 'T2',
        accessor: 'info.t2',
        Cell: ({ value }) => <div className="py-1 px-7">{value}</div>,
      },
      {
        Header: 'T3',
        accessor: 'info.t3',
        Cell: ({ value }) => <div className="py-1 px-7">{value}</div>,
      },
      {
        Header: 'T4',
        accessor: 'info.t4',
        Cell: ({ value }) => <div className="py-1 px-7">{value}</div>,
      },
      {
        Header: 'T5+',
        accessor: 'info.t5',
        Cell: ({ value }) => <div className="py-1 px-7">{value}</div>,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    rowSpanHeaders,
  } = useTable({ columns, data }, (hooks) => {
    hooks.useInstance.push(useInstance);
  });

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow h-0 overflow-auto">
        <table {...getTableProps()} className="w-full">
          <thead className="sticky top-0">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={classNames(
                      'text-xs 2xl:text-sm bg-green-700 text-white',
                      {
                        'border-1 border-white': isPdfRenderMode,
                      }
                    )}
                    {...column.getHeaderProps()}
                  >
                    <div className="th-content">{column.render('Header')}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              for (let j = 0; j < row.allCells.length; j++) {
                let cell = row.allCells[j];
                let rowSpanHeader = rowSpanHeaders.find(
                  (x) => x.id === cell.column.id
                );

                if (rowSpanHeader !== undefined) {
                  if (
                    rowSpanHeader.topCellValue === null ||
                    rowSpanHeader.topCellValue !== cell.value
                  ) {
                    cell.isRowSpanned = false;
                    rowSpanHeader.topCellValue = cell.value;
                    rowSpanHeader.topCellIndex = i;
                    cell.rowSpan = 1;
                  } else {
                    rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                    cell.isRowSpanned = true;
                  }
                }
              }
              return null;
            })}
            {rows.map((row) => {
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (cell.isRowSpanned) return null;
                    else
                      return (
                        <td
                          style={borderStyle}
                          className={classNames({
                            'text-center': index > 1,
                            'text-base font-medium text-gray-900':
                              isPdfRenderMode,
                            'text-xs 2xl:text-sm': !isPdfRenderMode,
                            'bg-green-700 bg-opacity-25':
                              !bgsData[
                                JSON.parse(cell.row.values.programme)
                                  .program_name
                              ],
                          })}
                          rowSpan={cell.rowSpan}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(RowSpanningPart3Table);
