import React, {useState} from 'react';
import {connect} from 'react-redux';
import {registerUser} from '@/store/actions/parcel';
import Button from '@components/Button';
import AuthContainer from './AuthContainer';
import LinksBottom from './LinksBottom';

const ResetLinkConfirmation = ({registerUser}) => {
    const [informations, setInformations] = useState({
        email: null,
        password: null,
        password_confirmation: null,
    });

    const handleSubmit = () => {
        const data = {user: informations};
        registerUser(data);
    };

    let color = {color: '#092c4b'};

    return (
        <AuthContainer hideText>
            <div className='z-50 w-full  shadow-2xl bg-white p-6 rounded-lg flex flex-col'>
                <h3 className='text-center' style={color}>
                    Renvoyez moi un lien de confirmation
                </h3>
                <span className='mb-2 text-gray-700'>Email</span>
                <input
                    onChange={(e) => {
                        setInformations((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                        }));
                    }}
                    name='email'
                    className='mb-4 border-2 border-gray-400 p-2 rounded-sm'
                ></input>
                <div className='flex flex-col mt-6 items-center'>
                    <div className='w-2/4'>
                        <Button
                            title='Renvoyer moi un lien de confirmation'
                            type='submit'
                            handleClick={() => handleSubmit()}
                        >
                            Renvoyer moi un lien de confirmation
                        </Button>
                    </div>
                    <LinksBottom/>
                </div>
            </div>
        </AuthContainer>
    );
};

export default connect(null, {registerUser})(ResetLinkConfirmation);
