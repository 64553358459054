import React from 'react';
import ReactDOM from 'react-dom';
import App from '../src/components/App';
import store from '../src/store';
import { Provider } from 'react-redux';
import ErrorBoundary from '../src/containers/ErrorBoundary';
import SelectedParcelsProvider from '../src/providers/SelectedParcelsProvider';
import ModalsProvider from '../src/providers/modalsProvider';
import DataVisualisationProvider from '../src/providers/dataVisualisationProvider';
import AuthProvider from '../src/providers/AuthProvider';
import MarketSurveyProvider from '../src/providers/marketSurveyProvider';
import ProgramsProvider from '../src/providers/programsProvider';
import { authInterceptor } from '../src/api/auth-interceptor';
import MsMapProvider from '../src/providers/MsMapProvider';
import ErrorHandler from '../src/components/error-handler/ErrorHandler';

console.log('Application started');
authInterceptor();
document.addEventListener('turbolinks:load', () => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <AuthProvider>
          <DataVisualisationProvider>
            <ModalsProvider>
              <SelectedParcelsProvider>
                <MarketSurveyProvider>
                  <ProgramsProvider>
                    <MsMapProvider>
                      <App />
                    </MsMapProvider>
                  </ProgramsProvider>
                </MarketSurveyProvider>
              </SelectedParcelsProvider>
            </ModalsProvider>
          </DataVisualisationProvider>
        </AuthProvider>
      </Provider>
    </ErrorBoundary>,
    document.body.appendChild(document.createElement('div'))
  );
});
