import React from 'react';
import {Link} from 'react-router-dom';

const Button = ({
                    type,
                    to,
                    title,
                    style,
                    className,
                    padding,
                    location,
                    disabled = false,
                    theme,
                    handleClick,
                }) => {
    const switchTheme = (theme) => {
        switch (theme) {
            case 'abandonned':
                return 'rounded-lg p-3 bg-red-400 text-white inline-block opacity-50 shadow-lg shadow-xl transform hover:-translate-y-1';
            case 'light':
                return 'rounded-lg p-3 bg-white border-2 border-gray-300 text-black inline-block shadow-lg shadow-xl transform hover:-translate-y-1';
            case 'primary':
                return 'text-white storybook-button bg-blue-400 hover:bg-blue-500 storybook-button--primary p-4 transform hover:-translate-y-1';
            case 'dark':
                return 'p-4 bg-gray-800 text-white storybook-button shadow-lg shadow-xl transform hover:-translate-y-1';
            case 'work-in-progress':
                return 'cursor-default p-4 bg-gray-600 opacity-75 text-white storybook-button shadow-lg shadow-xl';
            case 'disabled':
                return 'cursor-not-allowed p-4 bg-gray-600 opacity-75 hover:opacity-25 text-white storybook-button shadow-lg shadow-xl transform hover:-translate-y-1';
            default:
                return `text-white storybook-button bg-blue-400 hover:bg-blue-500 storybook-button--primary transform hover:-translate-y-1 ${
                    padding ? padding : 'p-4'
                }`;
        }
    };

    return (
        <>
            {type ? (
                <button
                    onClick={handleClick}
                    disabled={disabled}
                    className={`${switchTheme(
                        theme
                    )} w-full text-base font-normal rmy-3 transition duration-500 ease-in-out text-center`}
                    type={type}
                    style={style}
                >
                    {title ? title : 'bouton submit simple'}
                </button>
            ) : (
                <Link
                    disabled={disabled}
                    onClick={handleClick}
                    style={style}
                    to={to || ''}
                    className={`${switchTheme(
                        theme
                    )} w-full font-normal text-base rmy-3 transition duration-500 ease-in-out text-center`}
                >
                    {title}
                </Link>
            )}
        </>
    );
};

export default Button;
