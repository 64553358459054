import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import getOffsetTop from '../../../_helpers/getOffsetTop';

export default function StickyHeader({ children, container }) {
  const { step } = useSelector((state) => state.ciblageCreate);
  const ref = useRef(null);
  useEffect(() => {
    const fix = () => {
      const elContainer = document.getElementById(container);
      const myEl = ref.current;
      if (elContainer && myEl) {
        myEl.style.top = `${getOffsetTop(elContainer)}px`;
        myEl.style.left = `${elContainer.getBoundingClientRect().left}px`;
        myEl.style.width = `${elContainer.clientWidth}px`;
        elContainer.style.paddingTop = `${myEl.clientHeight}px`;
        //myEl.style.position = 'fixed';
      }
    };
    setTimeout(() => {
      fix();
    }, 50);
    window.addEventListener('resize', fix);
    return () => {
      window.addEventListener('resize', fix);
    };
  }, [step]);

  return (
    <div ref={ref} style={{ zIndex: 1000 }} className="bg-white w-full">
      {children}
    </div>
  );
}
