/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import onBoardingPicture from "../../../../assets/images/onboarding-modal.jpg";
import Button from '../../components/Button';
import {XIcon} from "@heroicons/react/solid";
import {useModal} from "../../hooks/useModal";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

export default function CookieModal({}) {
    const cancelButtonRef = useRef();
    const {isCookieModalOpen, setIsCookieModalOpen} = useModal()

    const history = useHistory()


    function acceptCookies() {
        localStorage.setItem('openCookieModal', true);
        setIsCookieModalOpen(false)
    }


    return (
        <Transition.Root show={isCookieModalOpen} as={Fragment}>
            <Dialog as="div" initialFocus={cancelButtonRef} className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={() => {
                        history.push('/ratings')
                        setIsCookieModalOpen(true)
                    }}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-bottom sm:h-screen"
                          aria-hidden="true">&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className={'absolute top-1 right-1 '} onClick={() => {
                                history.push('/ratings')
                                setIsCookieModalOpen(false)
                            }}>
                                <XIcon className={'w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer'}/>
                            </div>
                            <div className="grid col-span-12 w-full md:grid-cols-12">
                                <div
                                    className="grid grid-rows-3 grid-flow-col col-span-12 md:col-span-10 md:col-start-2 md:col-end-12 rounded-xl flex flex-col justify-between gap-5"
                                >
                                    <div className="col-span-12 row-span-2 text-center">
                                        <h2 className={'text-2xl text-bold'}>Cookies</h2>
                                        <p className={'text-bold col-span-12'}>Afin de vous garantir une expérience optimale, EvalparceL utilise des cookies essentiels.</p>
                                    </div>
                                    <button className={'col-span-6 bg-white border-2 border-blue-500 rounded-lg px-2 py-2 hover:bg-blue-900 hover:border-blue-900 hover:text-white text-xs'} onClick={() => window.location.href = 'https://www.evalparcel.com/'}>Je refuse et quitte l’application</button>
                                    <button className={'col-span-6 bg-blue-500 text-white border-2 border-blue-500 rounded-lg px-2 py-2 hover:bg-blue-700 text-xs'} onClick={acceptCookies}> J'accepte et poursuis ma navigation
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
