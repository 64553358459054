import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TRANSPORTMODE,
  TRANSPORT_DURATION_MAX,
  TRANSPORT_DURATION_MIN,
} from '../../../constants/ciblageDefaultValues';
import { getCommunesByIsochrone } from '../../../store/actions/ciblage/commune';
import { getAddressesByKeyword } from '../../../store/actions/ciblage/isochrone';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';
import useDebounce from '../../../_helpers/useDebounce';
import CustomAutocomplete from '../../molecules/custom-autocomplete/CustomAutocomplete';
import SliderInput from '../../molecules/slider-input/SliderInput';
import FormulaireCibler from '../formulaire-cibler/FormulaireCibler';
import ListCommunes from '../list-communes/ListCommunes';
import SelectAllOrClibler from '../select-all-or-cibler/SelectAllOrClibler';
import SelectedSearchBy from '../selelected-search-by/SelectedSearchBy';
import RecapIsochroneForm from '../recap-isochrone-form/RecapIsochroneForm';

const TransportMode = ({ value, onChange, name }) => {
  const handleChange = (e) => {
    onChange &&
      onChange(
        TRANSPORTMODE.find((item) => item.value === e.target.value),
        name
      );
  };

  return (
    <>
      <label className="text-sm xl:text-base">Mode de transport</label>
      <div className="mt-1 flex flex-col xl:flex-row justify-between">
        {TRANSPORTMODE.map(({ label, value: valueRadio }) => (
          <label key={valueRadio} className="block text-sm xl:text-base">
            <input
              id={value}
              name="transportMode"
              type="radio"
              checked={value?.value === valueRadio}
              value={valueRadio}
              onChange={handleChange}
            />
            <span className="ml-2">{label}</span>
          </label>
        ))}
      </div>
    </>
  );
};

const IsochroneForm = () => {
  const { addresses, isoChroneFilterValue } = useSelector(
    (state) => state.ciblageCreate
  );
  const dispatch = useDispatch();

  const handleChange = (value, name) => {
    dispatch(
      ciblageCreateActions.setValueIsochroneFilter({
        ...isoChroneFilterValue,
        [name]: value,
      })
    );
  };

  const handleChangeAddressKeyword = (keyword) => {
    dispatch(getAddressesByKeyword(keyword));
  };

  return (
    <>
      <div className="mt-3">
        <label className="text-sm xl:text-base">
          Isochrone autour d'une adresse
        </label>
        <CustomAutocomplete
          placeholder="Indiquez une adresse"
          keyLabel="label"
          keyValue="id"
          onChange={handleChange}
          onChangeInputValue={handleChangeAddressKeyword}
          options={addresses}
          value={isoChroneFilterValue?.isoChroneAddress}
          name="isoChroneAddress"
        />
      </div>
      <div className="mt-5">
        <TransportMode
          name="transportMode"
          onChange={handleChange}
          value={isoChroneFilterValue?.transportMode}
        />
      </div>
      <div className="mt-5">
        <label className="text-sm xl:text-base">
          Temps de transport maximum
        </label>
        <div className="flex items-center">
          <div className="flex-grow">
            <SliderInput
              min={TRANSPORT_DURATION_MIN}
              max={TRANSPORT_DURATION_MAX}
              step={1}
              onChange={handleChange}
              value={isoChroneFilterValue?.transportDuration}
              name="transportDuration"
            />
          </div>
          <div className="ml-2">min</div>
        </div>
      </div>
    </>
  );
};

export default function FormulaireIsochroneCommunes() {
  const {
    isoChroneFilterValue,
    communes,
    cibler,
    valueTerritoire: { selectedCommunes },
  } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const thereAreCommunes = useMemo(() => !!communes.length, [communes]);

  useDebounce(() => {
    dispatch(getCommunesByIsochrone());
  }, [isoChroneFilterValue]);

  return (
    <div className="px-4 mt-4">
      {!cibler ? (
        <>
          <div className="-mx-4">
            <SelectedSearchBy />
          </div>
          <IsochroneForm />
        </>
      ) : (
        <RecapIsochroneForm />
      )}
      {thereAreCommunes && !cibler && (
        <div className="mt-5">
          <SelectAllOrClibler />
        </div>
      )}
      {!cibler && (
        <div className="mt-5">
          {thereAreCommunes && (
            <p className="mb-1 text-sm">
              {selectedCommunes?.length || 0}/{communes?.length} commune(s)
              sélectionnée(s)
            </p>
          )}
          <ListCommunes />
        </div>
      )}
      {cibler && (
        <div className="mt-6">
          <FormulaireCibler />
        </div>
      )}
    </div>
  );
}
