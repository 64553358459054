import React, { Fragment, useEffect } from 'react';
import BoxComp1 from './molecules/boxes/BoxComp1';
import BoxComp2 from './molecules/boxes/BoxComp2';
import BoxSimple1 from './molecules/boxes/BoxSimple1';
import BoxSimple0 from './molecules/boxes/BoxSimple0';
import BoxSimple2 from './molecules/boxes/BoxSimple2';
import BoxSimple3 from './molecules/boxes/BoxSimple3';
import ReactTooltip from 'react-tooltip';

const BoxesContainer = ({ boxes, print_mode = false, isEDM = false }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [boxes]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
      {(boxes || []).map((box, index) => {
        if (!box) {
          return null;
        }
        return (
          <Fragment key={`sub_axe__${box.box_type}__${index}`}>
            {(box.box_type === 'BoxComp1' || box.type_box === 'BoxComp1') && (
              <BoxComp1
                url={box.api_url}
                data={box}
                print_mode={print_mode}
                isEDM={isEDM}
              />
            )}
            {(box.box_type === 'BoxComp2' || box.type_box === 'BoxComp2') && (
              <BoxComp2 url={box.api_url} data={box} print_mode={print_mode} />
            )}
            {(box.box_type === 'BoxSimple0' ||
              box.type_box === 'BoxSimple0') && (
              <BoxSimple0
                url={box.api_url}
                data={box}
                print_mode={print_mode}
                isEDM={isEDM}
              />
            )}
            {(box.box_type === 'BoxSimple1' ||
              box.type_box === 'BoxSimple1') && (
              <BoxSimple1
                url={box.api_url}
                data={box}
                print_mode={print_mode}
                isEDM={isEDM}
              />
            )}
            {(box.box_type === 'BoxSimple2' ||
              box.type_box === 'BoxSimple2') && (
              <BoxSimple2
                url={box.api_url}
                data={box}
                print_mode={print_mode}
                isEDM={isEDM}
              />
            )}
            {(box.box_type === 'BoxSimple3' ||
              box.type_box === 'BoxSimple3') && (
              <BoxSimple3
                url={box.api_url}
                data={box}
                print_mode={print_mode}
                isEDM={isEDM}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default BoxesContainer;
