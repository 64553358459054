import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../molecules/modal/Modal';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import moment from 'moment';
import InputText from '../atoms/text-input/InputText';
import SelectInput from '../atoms/select-input/SelectInput';
import RadioInput from '../atoms/radio-input/RadioInput';
import {
  checkIfMailExist,
  getAccounts,
  getCodesCommuneByDepartement,
  saveAccount,
} from '../../store/slices/others';
import uniqid from 'uniqid';
import { PencilIcon } from '@heroicons/react/outline';
import { uniqBy } from 'lodash';

const initInputValues = {
  company_name: '',
  default_end_validity_subscription: '',
};

export const SocieteForm = ({
  name,
  onBlur,
  onChange,
  value,
  error,
  setValueDateLimit,
}) => {
  // states
  const [currentValue, setCurrentValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [idToEdit, setIdToEdit] = useState(null);
  const [touched, setTouched] = useState({
    company_name: false,
    default_end_validity_subscription: false,
  });
  const [inputsValues, setInputsValues] = useState(initInputValues);

  // refs
  const elRef = useRef();
  const elList = useRef();
  const elForm = useRef();

  // redux hooks
  const dispatch = useDispatch();
  const { accounts } = useSelector((state) => state.others);

  const handleClickOpenForm = (e) => {
    e.stopPropagation();
    setOpenForm((state) => !state);
    setIsEdit(false);

    if (!isEdit) {
      setCurrentValue(null);
      setOpenList(false);
      setInputsValues(initInputValues);
    } else {
      setOpenList(true);
    }
  };

  const handleClickEl = (e) => {
    e.stopPropagation();
    onBlur && onBlur({ target: { name } });
    setOpenList((state) => !state);
    if (elRef.current && elList.current) {
      const height = elRef.current.clientHeight;
      elList.current.style.top = `${height + 4}px`;
    }
  };

  const errorLocal = useMemo(
    () => ({
      company_name:
        inputsValues.company_name.length === 0 ? 'Champ obligatoire.' : null,
      default_end_validity_subscription: !moment(
        inputsValues.default_end_validity_subscription
      ).isSameOrAfter(moment(new Date()))
        ? 'Cette Date doit être dans le future.'
        : null,
    }),
    [inputsValues]
  );

  const handleChangeInputs = (e) => {
    setTouched({
      company_name: e.target.name === 'company_name',
      default_end_validity_subscription:
        e.target.name === 'default_end_validity_subscription',
    });
    setInputsValues((curr) => ({ ...curr, [e.target.name]: e.target.value }));
  };

  const handleClickEdit = (id) => (e) => {
    e.stopPropagation();

    const toEdit = accounts.find((item) => item.id === id);
    if (toEdit) {
      setIdToEdit(id);
      setInputsValues({
        company_name: toEdit.attributes.company_name,
        default_end_validity_subscription: moment(
          toEdit.attributes.default_end_validity_subscription
        ).format('YYYY-MM-DD'),
      });

      setIsEdit(true);
      setOpenList(false);
      setOpenForm(true);
    }
  };

  const handleClickAccountItem = (id) => (e) => {
    e.stopPropagation();

    const current = accounts.find((item) => item.id === id);

    setCurrentValue(current);

    onChange && onChange({ target: { name, value: id } });
    setTimeout(() => {
      setValueDateLimit(current.attributes.default_end_validity_subscription);
    }, 500);

    setOpenList(false);
    setOpenForm(false);
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    setLoading(true);

    // save account
    const { payload } = await dispatch(
      saveAccount({ ...inputsValues, id: idToEdit })
    );

    // update lists after creating
    await dispatch(getAccounts());
    setLoading(false);

    // set value the created account
    if (!isEdit) {
      setCurrentValue(payload);
      onChange &&
        onChange({
          target: {
            name,
            value: payload.id,
          },
        });
      setValueDateLimit(payload.attributes.default_end_validity_subscription);
    }

    setOpenForm(false);
    if (isEdit) {
      setOpenList(true);
      setIsEdit(false);
      setIdToEdit(null);
    } else {
      setOpenList(false);
    }
  };

  // sort asc by company name
  const accountArray = useMemo(() => {
    return [...accounts].sort((a, b) => {
      if (a.attributes.company_name < b.attributes.company_name) {
        return -1;
      }
      if (a.attributes.company_name > b.attributes.company_name) {
        return 1;
      }
      return 0;
    });
  }, [accounts]);

  useEffect(() => {
    // get lists of account
    const getAccountFunc = async () => {
      await dispatch(getAccounts());
    };
    getAccountFunc();
  }, []);

  useEffect(() => {
    setCurrentValue(accounts.find((item) => item.id === value) || null);
  }, [value, accounts]);

  return (
    <div className="relative w-full">
      <div onClick={handleClickEl} ref={elRef} className="w-full flex flex-col">
        <label className="font-semibold">Société</label>
        <div className="w-full border-1 rounded-md px-4 py-2 border-gray-500 flex justify-between">
          {currentValue ? (
            <span>
              {
                accounts.find((item) => item.id === currentValue?.id)
                  ?.attributes.company_name
              }
            </span>
          ) : (
            <span className="text-gray-700 ">Choisir la société</span>
          )}

          {(openList || openForm) && (
            <button
              type="button"
              onClick={handleClickOpenForm}
              className={classNames(
                ' text-xs text-white rounded-md  px-2 py-1 items-center',
                { 'hover:bg-blue-700 bg-blue-500': !openForm },
                { 'hover:bg-red-700 bg-red-500': openForm }
              )}
            >
              {openForm ? 'Annuler' : 'Créer'}
            </button>
          )}
        </div>
      </div>
      <div
        ref={elList}
        style={{ zIndex: 100000, maxHeight: 300 }}
        className={classNames(
          'absolute w-full bg-white h-auto left-0 rounded-b-md shadow-md overflow- overflow-y-auto',
          { hidden: !openList },
          { block: openList }
        )}
      >
        {accountArray.map(
          ({
            id,
            attributes: { company_name, default_end_validity_subscription },
          }) => {
            const expired = moment(
              default_end_validity_subscription
            ).isSameOrBefore(new Date());

            return (
              <div
                onClick={!expired ? handleClickAccountItem(id) : undefined}
                className={classNames(
                  'item-account-modal-form-admin-user flex text-xs justify-between items-center py-2 px-4 border-b-1 border-gray-400 cursor-pointer hover:bg-gray-200',
                  {
                    'bg-gray-200': id === currentValue?.id,
                  },
                  {
                    'cursor-not-allowed': expired,
                  }
                )}
                key={uniqid()}
              >
                <span
                  className={classNames('flex items-center', {
                    'opacity-50': expired,
                  })}
                >
                  <span>{company_name}</span>
                  {expired && (
                    <span
                      style={{ fontSize: 10, padding: '2px 8px' }}
                      className="ml-4 inline-block text-white bg-red-500 rounded-full"
                    >
                      Accès expiré
                    </span>
                  )}
                </span>
                <span
                  onClick={handleClickEdit(id)}
                  className="inline-block cursor-pointer"
                >
                  <PencilIcon className="w-4 h-4 hover:text-blue-500" />
                </span>
              </div>
            );
          }
        )}
      </div>
      <div
        ref={elForm}
        style={{ zIndex: 100000 }}
        className={classNames(
          'absolute w-full bg-gray-300 h-auto left-0 p-4 rounded-b-md shadow-md',
          { hidden: !openForm },
          { block: openForm }
        )}
      >
        <div className="w-full flex flex-col">
          <label className="text-xs font-semibold">Nom de la Société</label>
          <input
            type="text"
            value={inputsValues.company_name}
            onChange={handleChangeInputs}
            name="company_name"
            className="w-full border-1 border-gray-500 rounded-md p-1 text-xs"
          />
          {touched.company_name && errorLocal.company_name && (
            <span className="text-xs text-red-500">
              {errorLocal.company_name}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col mt-4">
          <label className="text-xs font-semibold">Date de fin d’accès</label>
          <input
            type="date"
            value={inputsValues.default_end_validity_subscription}
            name="default_end_validity_subscription"
            onChange={handleChangeInputs}
            className="w-full border-1 border-gray-500 rounded-md p-1 text-xs"
          />
          {touched.default_end_validity_subscription &&
            errorLocal.default_end_validity_subscription && (
              <span className="text-xs text-red-500">
                {errorLocal.default_end_validity_subscription}
              </span>
            )}
        </div>
        <div className="w-full flex justify-end mt-2">
          <button
            disabled={
              errorLocal.company_name?.length ||
              errorLocal.default_end_validity_subscription?.length ||
              loading
            }
            type="button"
            onClick={handleSave}
            className={classNames(
              'text-xs text-white rounded-md  px-2 py-1 items-center hover:bg-blue-700 bg-blue-500',
              {
                'cursor-not-allowed opacity-75':
                  errorLocal.company_name?.length ||
                  errorLocal.default_end_validity_subscription?.length ||
                  loading,
              }
            )}
          >
            {loading ? 'Sauvegarde en cours...' : 'Enregistrer'}
          </button>
        </div>
      </div>
      {error && <div className="text-xs text-red-500">{error}</div>}
    </div>
  );
};

const validationSchema = (isEdit = false) =>
  Yup.object({
    nom: Yup.string().required('Champ obligatoire.').nullable(),
    prenom: Yup.string().required('Champ obligatoire.').nullable(),
    tel: Yup.string().nullable(),
    // remove mail and organizaton_id in edit mode
    ...(!isEdit
      ? {
          email: Yup.string()
            .email('Adresse email invalide.')
            .required('Champ obligatoire.')
            .nullable(),
          organization_id: Yup.object({
            id: Yup.number(),
            name: Yup.string(),
          })
            .nullable()
            .required('Champ obligatoire.'),
        }
      : {}),
    fonction: Yup.string().nullable(),
    date_echeance: Yup.date()
      .required('Champ obligatoire.')
      .min(new Date(), 'Cette date doit être dans le futur.')
      .nullable(),
    date_consent_gdpr_org: Yup.date().required('Champ obligatoire.').nullable(),
    blocked_at: Yup.mixed().nullable(),  // Allow null for blocked_at
    adhesion: Yup.string().nullable().required('Champ obligatoire.'),
    account_id: Yup.string().nullable().required('Champ obligatoire.'),
    paying_client_org: Yup.object({
      value: Yup.string(),
      label: Yup.string(),
    })
      .nullable()
      .required('Champ obligatoire.'),
    departements: Yup.array(
      Yup.object({
        value: Yup.string(),
        label: Yup.string(),
      })
    ).min(1, 'Champ obligatoire.'),
  });

const getInitialValues = (isEdit = false) => ({
  nom: null,
  prenom: null,
  // remove mail and organizaton_id in edit mode
  ...(!isEdit ? { email: null, organization_id: null } : {}),
  date_echeance: null,
  date_consent_gdpr_org: null,
  paying_client_org: null,
  adhesion: null,
  account_id: null,
  tel: null,
  fonction: null,
  departements: [],
  blocked_at: null, // Set blocked_at to null by default
});

const TestEmail = ({ email, currentUserEmail, setExists }) => {
  const [first, setFirst] = useState(false);
  useEffect(() => {
    const fn = setTimeout(async () => {
      if (first) {
        const exists = await checkIfMailExist(email);
        if (exists && email !== currentUserEmail) {
          setExists(true);
        } else {
          setExists(false);
        }
      }
      setFirst(true);
    }, 500);
    return () => clearTimeout(fn);
  }, [email]);

  return <></>;
};

export const idfOption = { label: 'Île-de-France', value: 'ile-de-france' };
export const idfDepartementsCodes = [
  '75',
  '77',
  '78',
  '91',
  '92',
  '93',
  '94',
  '95',
];

const FixTerritoireInput = () => {
  const { values, setFieldValue } = useFormikContext();
  const [prevValue, setPrevValue] = useState(null);

  useEffect(() => {
    // remove idf value if one of idf departement is selected
    const countIdfDepartementInValue = (values.departements || []).filter(
      (el) => idfDepartementsCodes.includes(el.value)
    )?.length;

    if (
      values.departements.find((el) => el.value === idfOption.value) &&
      countIdfDepartementInValue
    ) {
      let _current = [];

      if (prevValue) {
        if (
          !prevValue.find((el) => el.value === idfOption.value) &&
          values.departements.find((el) => el.value === idfOption.value)
        ) {
          _current = values.departements.filter(
            (el) => !idfDepartementsCodes.includes(el.value)
          );
        } else {
          _current = values.departements.filter(
            (el) => el.value !== idfOption.value
          );
        }
      } else {
        _current = values.departements.filter(
          (el) => el.value !== idfOption.value
        );
      }

      setFieldValue('departements', _current);
      setPrevValue(_current);
    }
  }, [values.departements]);

  return null;
};

const ModalForm = ({ open, onClose, id, onSave, user }) => {
  const [loading, setLoading] = useState(false);
  const { currentUserOrganizations, departements } = useSelector(
    (state) => state.others
  );
  const [initialValues, setInitialValues] = useState(getInitialValues(!!id));
  const dispatch = useDispatch();
  const [existsEmail, setExistsEmail] = useState(false);

  // build departement options
  const departementsOptions = useMemo(
    () =>
      uniqBy(
        [
          // add in first IDF option
          idfOption,
          ...departements.map(({ attributes: { national_code, name } }) => ({
            value: national_code,
            label: name,
          })),
        ],
        (el) => el.value
      ),
    [departements]
  );

  const handleSubmitFrom = async (values) => {
    // in mode edit existsEmail is false
    if (!existsEmail) {
      setLoading(true);

      const _departements = [
        // get all departements which are not in IDF
        ...values.departements.filter((el) => el.value !== idfOption.value),

        // if user choose IDF, we get all IDF Departements
        ...(values.departements.find((el) => el.value === idfOption.value)
          ? departementsOptions.filter((el) =>
              idfDepartementsCodes.includes(el.value)
            )
          : []),
      ];

      // get all code_insees of choseen departements
      const array = await Promise.all(
        _departements.map(async (el) => {
          const { payload: codes } = await dispatch(
            getCodesCommuneByDepartement(el.value)
          );
          return codes;
        })
      );

      const _values = {
        ...values,
        // I add !id because in edit we can't change mail
        email: !id && values.email,
        organization_id: !id && values.organization_id.id,
        ////////////////////////////////////////////////
        paying_client_org: values.paying_client_org.value,
        date_consent_gdpr_org: moment(values.date_consent_gdpr_org).format(
          'DD-MM-YYYY'
        ),
        date_echeance: moment(values.date_echeance).format('DD-MM-YYYY'),
        code_insees: [].concat(...array),
        departements: _departements.map((el) => el.value),
      };
      if (onSave) {
        await onSave(_values, id, !id && values.organization_id.id);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setInitialValues(user);
    } else {
      setInitialValues(getInitialValues(false));
    }
    setExistsEmail(false);
  }, [user]);

  return (
    <Modal open={open} onClose={onClose && onClose} maxWidth="max-w-2xl">
      <div>
        <h1 className="text-center font-semibold text-2xl">
          {!id ? "Création d'un utlisateur" : "Modification d'un utilisateur"}
        </h1>
        <Formik
          validationSchema={validationSchema(!!id)}
          onSubmit={handleSubmitFrom}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-x-6 gap-y-4 mt-8">
                <FixTerritoireInput />
                <TestEmail
                  currentUserEmail={user?.email || null}
                  email={values.email}
                  setExists={(value) => setExistsEmail(value)}
                />
                <InputText
                  label="Nom"
                  name="nom"
                  value={values.nom}
                  onChange={handleChange}
                  error={touched.nom && errors.nom}
                  onBlur={handleBlur}
                />
                <InputText
                  label="Prénom"
                  name="prenom"
                  value={values.prenom}
                  onChange={handleChange}
                  error={touched.prenom && errors.prenom}
                  onBlur={handleBlur}
                />

                {!id && (
                  <div className="flex flex-col">
                    <InputText
                      type="email"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && errors.email}
                      onBlur={handleBlur}
                    />
                    {existsEmail && (
                      <span className="text-xs text-red-600 mt-1">
                        Cette adresse email existe déjà.
                      </span>
                    )}
                  </div>
                )}
                <InputText
                  label="Téléphone"
                  name="tel"
                  value={values.tel}
                  onChange={handleChange}
                />
                <InputText
                  label="Fonction"
                  name="fonction"
                  value={values.fonction}
                  onChange={handleChange}
                />
                <SocieteForm
                  setValueDateLimit={(date) => {
                    setFieldValue('date_echeance', date);
                  }}
                  name="account_id"
                  value={values.account_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.account_id && errors.account_id}
                />
                {!id && (
                  <SelectInput
                    label="Organisation"
                    placeholder="Sélectionner l'organisation"
                    options={currentUserOrganizations.map(
                      ({ attributes }) => attributes
                    )}
                    name="organization_id"
                    value={values.organization_id}
                    keyLabel="name"
                    keyValue="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.organization_id && errors.organization_id}
                    required
                  />
                )}
                <div className="w-full flex flex-col">
                  <label className="font-semibold">Territoires</label>
                  <ReactSelect
                    options={departementsOptions}
                    onChange={(value) =>
                      handleChange({ target: { name: 'departements', value } })
                    }
                    value={values.departements}
                    onBlur={(e) =>
                      handleBlur({
                        ...e,
                        target: { ...e.target, name: 'departements' },
                      })
                    }
                    isMulti
                  />
                  {touched.departements && errors.departements && (
                    <span className="text-xs text-red-600 mt-1">
                      {errors.departements}
                    </span>
                  )}
                </div>
                <InputText
                  type="date"
                  label="Date de consentement RGPD"
                  name="date_consent_gdpr_org"
                  value={values.date_consent_gdpr_org}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.date_consent_gdpr_org &&
                    errors.date_consent_gdpr_org
                  }
                />
                <InputText
                  type="date"
                  label="Date fin accès"
                  name="date_echeance"
                  value={values.date_echeance}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.date_echeance && errors.date_echeance}
                />
                <InputText
                  type="date"
                  label="Date blocage compte"
                  name="blocked_at"
                  value={values.blocked_at ? moment(values.blocked_at).format('YYYY-MM-DD') : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.blocked_at && errors.blocked_at}
                />
                <SelectInput
                  label="Adhésion"
                  placeholder="Sélectionner l'adhésion"
                  options={['non', 'classique', 'premium']}
                  value={values.adhesion}
                  name="adhesion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.adhesion && errors.adhesion}
                />
                <RadioInput
                  label="Utilisateur payant"
                  options={[
                    {
                      value: 'true',
                      label: 'Oui',
                    },
                    {
                      value: 'false',
                      label: 'Non',
                    },
                  ]}
                  name="paying_client_org"
                  keyLabel="label"
                  keyValue="value"
                  value={values.paying_client_org}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.paying_client_org && errors.paying_client_org}
                />
              </div>
              <div className="mt-6 flex justify-center">
                <button
                  type="submit"
                  className={classNames(
                    'px-6 py-4 text-white rounded-md',
                    {
                      'bg-blue-500 hover:bg-blue-700': isValid && !existsEmail,
                    },
                    {
                      'bg-blue-300 cursor-not-allowed':
                        !isValid || isSubmitting || existsEmail || loading,
                    }
                  )}
                >
                  {loading
                    ? 'Enregistrement en cours...'
                    : !id
                    ? 'Créer un compte'
                    : 'Enregistrer les modifications'}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ModalForm;
