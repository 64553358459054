import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrUnSelectAllCommune } from '../../../store/actions/ciblage/commune';
import { selectCibler } from '../../../store/actions/ciblage/cibler';

export default function SelectAllOrClibler() {
  const {
    communes,
    valueTerritoire: { selectedCommunes },
  } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const canSelectAll = useMemo(() => {
    if (communes && selectedCommunes && communes.length) {
      return communes.length !== selectedCommunes.length;
    }
    return null;
  }, [communes, selectedCommunes]);

  const handleSelectAllOrNot = () => {
    dispatch(selectOrUnSelectAllCommune());
  };

  const handleClickOnCibler = () => {
    dispatch(selectCibler());
  };

  return (
    <div className="flex gap-x-4 h-16 w-full">
      <button
        className="select-all-or-clibler-btn"
        onClick={handleSelectAllOrNot}
      >
        {canSelectAll ? 'Tout sélectionner' : 'Tout Désélectionner'}
      </button>
      <button
        onClick={handleClickOnCibler}
        className="select-all-or-clibler-btn"
      >
        Cibler
      </button>
    </div>
  );
}
