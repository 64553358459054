import Api from "../api/Api";

export const updateRating = async (rating_id, data) => {
    const res = await Api.put(`/ratings/${rating_id}.json`, data)


    if (res.status === 500) {
        throw new Error(`Une erreur est survenue lors de la mise à jour de l'évaluation detaillée - ID  ${rating_id}`);
    }

    if (res.status === 404) {
        throw new Error(`l'évaluation detaillée - ID  ${rating_id} introuvable`);
    }

    return {
        rating: res.data
    }
}