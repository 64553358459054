import React from 'react'
import SectionTitle from "./SectionTitle";

const WIPSection = ({title}) => {

    return <div className={'flex flex-col justify-start'}>
        <SectionTitle title={title}/>
        En cours de developpement
    </div>
}

export default WIPSection