import * as React from 'react';
import { useTable } from 'react-table';
import { useEffect } from 'react';
import classNames from 'classnames';

const borderStyle = {
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  padding: '8px 15px',
};

function useInstance(instance) {
  const { allColumns } = instance;

  let rowSpanHeaders = [];

  allColumns.forEach((column, i) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0 },
      ];
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}

const RowSpanningTable = ({ origData, isPdfRenderMode }) => {
  const data = React.useMemo(() => {
    const array = [];
    (origData || []).forEach((buyer) => {
      buyer.infos.forEach((info) => {
        array.push({
          buyer_type: buyer.buyer_type,
          info: {
            information_type: info.information_type,
            t1: info.t1,
            t2: info.t2,
            t3: info.t3,
            t4: info.t4,
            t5: info.t5,
            total: info.total,
          },
        });
      });
    });
    return array;
  }, [origData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Type Acheteur',
        accessor: 'buyer_type',
        enableRowSpan: true,
      },
      {
        Header: 'information',
        accessor: 'info.information_type',
      },
      {
        Header: 'Ch/T1',
        accessor: 'info.t1',
      },
      {
        Header: 'T2',
        accessor: 'info.t2',
      },
      {
        Header: 'T3',
        accessor: 'info.t3',
      },
      {
        Header: 'T4',
        accessor: 'info.t4',
      },
      {
        Header: 'T5+',
        accessor: 'info.t5',
      },
      {
        Header: 'Total',
        accessor: 'info.total',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    rowSpanHeaders,
  } = useTable({ columns, data }, (hooks) => {
    hooks.useInstance.push(useInstance);
  });

  return (
    <table {...getTableProps()} className="w-full">
      <thead className={'bg-green-700 text-white'}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className={classNames(
                  { 'text-sm border-1 border-white': isPdfRenderMode },
                  { 'text-xs 2xl:text-sm': !isPdfRenderMode }
                )}
                {...column.getHeaderProps()}
                // style={borderStyle}
              >
                <div className="th-content">{column.render('Header')}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);

          for (let j = 0; j < row.allCells.length; j++) {
            let cell = row.allCells[j];
            let rowSpanHeader = rowSpanHeaders.find(
              (x) => x.id === cell.column.id
            );

            if (rowSpanHeader !== undefined) {
              if (
                rowSpanHeader.topCellValue === null ||
                rowSpanHeader.topCellValue !== cell.value
              ) {
                cell.isRowSpanned = false;
                rowSpanHeader.topCellValue = cell.value;
                rowSpanHeader.topCellIndex = i;
                cell.rowSpan = 1;
              } else {
                rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                cell.isRowSpanned = true;
              }
            }
          }
          return null;
        })}
        {rows.map((row) => {
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                if (cell.isRowSpanned) return null;
                else
                  return (
                    <td
                      style={borderStyle}
                      className={classNames(
                        'text-gray-900',
                        { 'text-base py-2 font-medium': isPdfRenderMode },
                        { 'text-xs 2xl:text-sm': !isPdfRenderMode },
                        { 'text-center': index > 1 },
                        {
                          'bg-green-700 bg-opacity-25':
                            cell.row.values.buyer_type === 'Investisseurs',
                          'border-l-2 border-r-2 border-green-700':
                            cell.column.Header === 'Total',
                        }
                      )}
                      rowSpan={cell.rowSpan}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default React.memo(RowSpanningTable);
