import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { PencilIcon, CheckIcon } from '@heroicons/react/outline';

export default function EditableTextfield({
  value,
  onSave,
  classFont,
  titleButton,
  maxlength = 100,
}) {
  const [text, setText] = useState('');
  const [modeEdit, setModeEdit] = useState(false);

  const handleClickButton = () => {
    setModeEdit((current) => (current ? save() : true));
  };

  const save = () => {
    onSave && onSave(text);
    return false;
  };

  useEffect(() => {
    setText(value || '');
  }, [value]);

  return (
    <div className="flex flex-col relative max-w-xs xl:max-w-sm 2xl:max-w-md">
      <div className="flex items-center w-full gap-x-2 relative z-10">
        {modeEdit ? (
          <input
            className="focus:ring-0 w-full uppercase border-1 rounded-md px-4 py-2 h-10"
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        ) : (
          <div className={classFont}>
            {text}{' '}
            <button
              className="opacity-50 px-2 py-0 inline-block hover:opacity-100 cursor-pointer"
              title={titleButton || 'Modifier'}
              onClick={handleClickButton}
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          </div>
        )}
        {modeEdit && (
          <button
            className={classNames(
              'rounded-md cursor-pointer bg-blue-500 hover:bg-blue-700 border-1 border-gray-300 px-2 h-10',
              {
                'opacity-50 cursor-not-allowed':
                  text?.length > maxlength || text?.length <= 1,
              }
            )}
            title="Sauvegarder"
            disabled={text?.length > maxlength || text?.length <= 1}
            onClick={handleClickButton}
          >
            <CheckIcon className="h-6 w-6 text-white" />
          </button>
        )}
      </div>
      {modeEdit && maxlength && (
        <span
          style={{ top: 42, fontSize: 9 }}
          className={classNames('absolute z-20 left-0', {
            'text-red-500 font-medium':
              text?.length > maxlength || text?.length <= 1,
            'text-green-400': text?.length <= maxlength && text?.length > 1,
          })}
        >
          <span>
            {text?.length} / {maxlength}
          </span>{' '}
          <span>{}</span>
        </span>
      )}
    </div>
  );
}
