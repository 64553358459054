import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ciblageResultActions } from '../../slices/ciblageResult';

export const getCiblages = createAsyncThunk(
  'getCiblages',
  async ({ headers }, { dispatch }) => {
    dispatch(ciblageResultActions.setLoading(true));
    return axios
      .get(`${process.env.BASE_URL}/targets.json`, {
        headers,
      })
      .then(({ data }) => {
        dispatch(ciblageResultActions.setLoading({ getCiblage: true }));
        dispatch(ciblageResultActions.setCiblages(data));
        dispatch(ciblageResultActions.setLoading(null));
        return data;
      })
      .catch((e) => {
        console.log(e);
        dispatch(ciblageResultActions.setLoading(null));
        dispatch(ciblageResultActions.setCiblages([]));
        return [];
      });
  }
);
