import React, {
  createRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { deserializeAttributesForMSGiven } from '../../../_helpers/jsonapi-deserializer';
import _, { uniqueId } from 'lodash';
import MapDataContainer from '../../molecules/DataVisualisations/Map/MapDataContainer';
import { useMaxHeight } from '../../../hooks/useMaxHeight';
import CommentMarketSurvey from './CommentMarketSurvey';
import { EDM_COVER_MAP, PROJECT_NAME } from '../../../_helpers/constant';
import { ParcelThumbComp } from '../../Parcels/ParcelThumb';
import logo from '../../../../../assets/images/logo.svg';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DownloadIcon,
} from '@heroicons/react/outline';
import { marketSurveyContext } from '../../../providers/marketSurveyProvider';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Modal from '../../molecules/modal/Modal';
import {
  ChevronLeftIcon,
  XIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid';
import Loading from '../../utils/Loading';

export const SwiperContainer = ({
  swiperContent,
  children,
  onChangeSlide,
  setSwiper,
  quickCheck,
}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const navigation2PrevRef = useRef(null);
  const navigation2NextRef = useRef(null);

  const handleSlideChange = (swiper) => {
    const nav = document.getElementById('slide-navigation');
    const currentSlide = document.getElementById(`slide_${swiper.activeIndex}`);
    const swiperWrapper = document.getElementsByClassName('swiper-wrapper');

    if (nav && currentSlide) {
      const height = currentSlide.clientHeight;
      const wrapper = swiperWrapper[0];
      const delta = wrapper.clientHeight - height;
      nav.style.marginTop = `-${delta}px`;
    }

    onChangeSlide && onChangeSlide(swiper);
  };

  return (
    <div className={classNames('relative', { 'px-4': quickCheck })}>
      {quickCheck && (
        <div
          ref={navigation2PrevRef}
          className="absolute my-nav-qc top-0 shadow-lg left-0 z-10"
        >
          <ChevronLeftIcon className="h-8 w-8" />
        </div>
      )}
      <Swiper
        navigation={
          !quickCheck
            ? {
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }
            : {
                prevEl: navigation2PrevRef.current,
                nextEl: navigation2NextRef.current,
              }
        }
        observer
        onSwiper={(swiper) => {
          setSwiper && setSwiper(swiper);
        }}
        onSlideChange={handleSlideChange}
        modules={[Navigation]}
        className="h-full bg-red-500"
      >
        {swiperContent || children}
      </Swiper>
      {!quickCheck && (
        <div className="flex justify-end">
          <div
            ref={navigationPrevRef}
            className={' text-blue-500 hover:text-blue-800 cursor-pointer'}
          >
            <ArrowLeftIcon className={'w-20 h-8'} />
          </div>
          <div
            ref={navigationNextRef}
            className={'text-blue-500  hover:text-blue-800 cursor-pointer'}
          >
            <ArrowRightIcon className={'w-20 h-8'} />
          </div>
        </div>
      )}
      {quickCheck && (
        <div
          ref={navigation2NextRef}
          className="absolute top-0 my-nav-qc right-0 shadow-lg  z-10 "
        >
          <ChevronRightIcon className="h-8 w-8" />
        </div>
      )}
    </div>
  );
};

const ParcelIDsList = ({ fieldIds, swiper, isPdfRenderMode, currentIndex }) => {
  return (
    <>
      <div
        className={classNames({
          'text-xs 2xl:text-base': !isPdfRenderMode,
          'text-sm': isPdfRenderMode,
        })}
      >
        {fieldIds?.length >= 2
          ? 'Parcelles sélectionnées'
          : 'Parcelle sélectionnée'}
      </div>
      <div className={classNames('w-full grid grid-cols-4 mt-2 gap-2')}>
        {fieldIds.map((fieldId, index) => {
          const displayParcelleId =
            fieldId.length >= 14 ? fieldId.slice(8) : fieldId;

          return (
            <span
              key={uniqueId('parcel_id--')}
              onClick={() => swiper?.slideTo(index, 500)}
              className={'col-span-1 flex flex-col justify-center items-center'}
            >
              <span
                className={classNames(
                  'bg-blue-700 text-blue-100 px-1 py-1 rounded-lg cursor-pointer hover:bg-blue-900 text-center  w-full',
                  { 'text-tiny 2xl:text-sm': !isPdfRenderMode },
                  { 'bg-blue-900': index === currentIndex && !isPdfRenderMode }
                )}
              >
                {displayParcelleId}
              </span>
            </span>
          );
        })}
      </div>
    </>
  );
};

export const ModalDownloadLink = ({ open, onClose }) => {
  const { altDownloadLinkDoc, downloadLinksDocs, loadingDocs } =
    useContext(marketSurveyContext);

  return (
    <Modal open={open} onClose={onClose} noNeedBtnClose paddingZero>
      <div className="flex justify-between items-center bg-black py-3 px-2">
        <div></div>
        <h1 className="text-white text-lg font-semibold">
          Documents d'urbanisme
        </h1>
        <div
          onClick={() => onClose && onClose()}
          className="hover:text-red-500 text-white cursor-pointer"
        >
          <XIcon className="w-6 h-6" />
        </div>
      </div>
      <div className="px-4 pb-8">
        <div className="py-8 text-center">
          <p className="font-semibold">
            Retrouvez l'ensemble des documents d'urbanisme
          </p>
          <p>
            dans votre répértoire{' '}
            <span className="inline-block px-1 bg-gray-500">
              /téléchargements
            </span>
          </p>
        </div>
        {loadingDocs ? (
          <div className="flex justify-center mt-4">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <a
              target="_blank"
              href={downloadLinksDocs ? downloadLinksDocs : ''}
              rel="noopener noreferrer"
              className="inline-block bg-blue-500 cursor-pointer hover:bg-blue-700 text-white py-2 px-6 rounded-lg"
            >
              Télécharger le dossier complet
            </a>
            <span className="text-center my-6">OU</span>
            <a
              target="_blank"
              href={altDownloadLinkDoc ? altDownloadLinkDoc : ''}
              rel="noopener noreferrer"
              className="text-center hover:underline text-blue-500"
            >
              Sélectionner les documents de votre choix sur Géoportail
            </a>
          </div>
        )}
      </div>
    </Modal>
  );
};

const CoverMarketSurveySection = ({
  msMetaData,
  isPdfRenderMode = false,
  surveyId,
  dateMs,
  parcelData = [],
  onChangeZonageFlux,
}) => {
  const { marketSurvey } = useSelector((state) => state.programme);
  const maxHeight = useMaxHeight(isPdfRenderMode);
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [town, setTown] = useState('');
  const [swiper, setSwiper] = useState(null);
  const [offset, setOffset] = useState(0);
  const [openModalDownloadLinks, setOpenModalDownloadLinks] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [surface, setSurface] = useState(null);
  const [epciName, setEpciName] = useState(null);

  useEffect(() => {
    if (marketSurvey && parcelData?.length) {
      const { related_rating } = !marketSurvey.real_estate_programs
        ? deserializeAttributesForMSGiven(marketSurvey)
        : marketSurvey;

      if (related_rating) {
        setAddress(related_rating.address);
        setPostcode(related_rating.postcode);
        setTown(related_rating.town);
        setEpciName(
          parcelData.find(
            (item) => item.data.id === related_rating.pivot_parcelle_id
          )?.data.attributes.epci_name
        );
        setSurface(
          parcelData.reduce((prev, current) => {
            return (
              prev +
              (current.data.attributes.superficie
                ? parseFloat(current.data.attributes.superficie)
                : 0)
            );
          }, 0)
        );
      }
    }
  }, [marketSurvey, parcelData]);

  useEffect(() => {
    const fix = () => {
      setOffset(
        document.getElementById('navbar')?.offsetHeight +
          document.getElementById('bottom-navbar')?.offsetHeight +
          document.getElementById('ms-page-header')?.offsetHeight
      );
    };
    fix();
    window.addEventListener('resize', fix);
    return () => {
      window.removeEventListener('resize', fix);
    };
  });

  const handleSlideChange = useCallback((swiper) => {
    setCurrentIndex(swiper.activeIndex);
  }, []);

  const TerrainAddress = () => {
    return (
      <div
        className={classNames({
          'py-2 2xl:py-6 border-b-1 border-gray-600': !isPdfRenderMode,
        })}
      >
        {marketSurvey?.data?.attributes?.name && isPdfRenderMode && (
          <h2
            style={{ wordBreak: 'break-word' }}
            className="border-b-1 border-gray-600 py-6 font-bold text-2xl mb-6 uppercase"
          >
            {marketSurvey?.data?.attributes?.name}
          </h2>
        )}
        <div
          className={classNames(
            'font-semibold flex flex-col',
            { 'text-xl': isPdfRenderMode },
            { 'text-sm 2xl:text-lg': !isPdfRenderMode }
          )}
        >
          <span>{address}</span>
          <span>
            {postcode} {town}
          </span>
        </div>
        {!epciName ? (
          <Loading />
        ) : (
          <div
            className={classNames(
              'text-gray-600  sm:tracking-tight ',
              { 'text-xs12 2xl:text-sm': !isPdfRenderMode },
              { 'text-base': isPdfRenderMode }
            )}
          >
            EPCI : {epciName}
          </div>
        )}
      </div>
    );
  };

  const { nom_promoteur } = marketSurvey?.data?.attributes || {};

  return (
    <div
      id="cover_page"
      className={classNames('flex flex-col space-y-16 w-full relative z-10', {
        'h-full': isPdfRenderMode,
      })}
      style={
        !isPdfRenderMode
          ? {
              minHeight: `calc(100vh - ${maxHeight}px)`,
              paddingTop: `${offset}px`,
            }
          : {}
      }
    >
      {isPdfRenderMode && (
        <div className="w-full grid grid-cols-3">
          <div className="col-span-1 flex justify-start">
            <TerrainAddress />
          </div>
          <div className="col-span-1 flex flex-col items-center">
            <h1 className="text-3xl text-gray-800 font-semibold uppercase text-center">
              Etude de Marché
            </h1>
            {nom_promoteur && (
              <h2 className="text-3xl text-gray-800 font-semibold uppercase text-center italic">
                {nom_promoteur}
              </h2>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-end space-y-2">
            <img src={logo} alt="logo Evalparcel" width={200} />
            <span className="text-sm">Actualisée le : {dateMs}</span>
          </div>
        </div>
      )}
      <div className="flex-grow flex flex-row w-full">
        <div
          className={classNames('w-1/3 flex flex-col', {
            'h-full pr-4': isPdfRenderMode,
            'px-4': !isPdfRenderMode,
          })}
        >
          <div>
            {!isPdfRenderMode && <TerrainAddress />}
            <div
              className={classNames('border-b-1 border-gray-600', {
                'py-2 2xl:py-6': !isPdfRenderMode,
                'py-6 border-t-1': isPdfRenderMode,
              })}
            >
              <h3
                className={classNames({
                  'text-sm 2xl:text-lg': !isPdfRenderMode,
                  'text-xl': isPdfRenderMode,
                })}
              >
                TERRAIN : {!surface ? <Loading /> : <span>{surface}m²</span>}
              </h3>
              {!!parcelData?.length && (
                <ParcelIDsList
                  isPdfRenderMode={isPdfRenderMode}
                  fieldIds={parcelData.map((item) => item.data.id)}
                  swiper={swiper}
                  currentIndex={currentIndex}
                />
              )}
              {!isPdfRenderMode && (
                <div className={'py-2'}>
                  <SwiperContainer
                    setSwiper={setSwiper}
                    onChangeSlide={handleSlideChange}
                  >
                    {parcelData.map((data, index) => {
                      const ref = createRef();
                      return (
                        <SwiperSlide
                          ref={ref}
                          key={uniqueId('parcel_slide')}
                          id={`slide_${index}`}
                          className="h-full block"
                        >
                          <ParcelThumbComp
                            isPdfRenderMode={isPdfRenderMode}
                            parcelData={data}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </SwiperContainer>
                </div>
              )}
            </div>
            {!isPdfRenderMode && (
              <div className="py-2 2xl:py-6 border-b-1 border-gray-600">
                <ModalDownloadLink
                  open={openModalDownloadLinks}
                  onClose={() => setOpenModalDownloadLinks(false)}
                />
                <h3 className="text-sm 2xl:text-lg">DOCUMENTS D’URBANISME</h3>
                <div className="flex gap-x-10 mt-2">
                  <span
                    onClick={() => setOpenModalDownloadLinks(true)}
                    rel="noopener noreferrer"
                    className="cursor-pointer flex items-center gap-x-1"
                    target="_blank"
                  >
                    <span className="text-xs 2xl:text-sm hover:underline">
                      Télécharger
                    </span>
                    <DownloadIcon className="h-4 2xl:h-8 w-4 2xl:w-8 text-blue-500 hover:text-blue-800" />
                  </span>
                </div>
              </div>
            )}
          </div>
          <div
            className={classNames(
              'flex-grow flex flex-col',
              { 'pt-6': isPdfRenderMode },
              { 'pt-4  2xl:pt-6 pb-4': !isPdfRenderMode }
            )}
          >
            <h3 className={'text-sm 2xl:text-lg uppercase'}>
              Points clés | Contextualisation
            </h3>
            <div className="flex-grow">
              <CommentMarketSurvey
                isPdfRenderMode={isPdfRenderMode}
                survey_id={surveyId}
                commentFieldName={PROJECT_NAME}
              />
            </div>
          </div>
        </div>
        <div
          className={classNames('w-2/3', { 'h-full': isPdfRenderMode })}
          style={
            !isPdfRenderMode ? { height: `calc(100vh - ${maxHeight}px)` } : {}
          }
        >
          <MapDataContainer
            defaultTileLayer="classique"
            zoomLevel={18}
            isScrollWheelZoom={false}
            dataType="marketSurvey"
            isPdfMode={isPdfRenderMode}
            hFull
            enableZonageLayer
            onChangeChangeFlux={(values) =>
              onChangeZonageFlux && onChangeZonageFlux(values, EDM_COVER_MAP)
            }
            name={EDM_COVER_MAP}
            defaultZonageFlux={msMetaData.edm_cover_map}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(CoverMarketSurveySection);
