import React from 'react';
import TravelTimeItem from './TravelTimeItem';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import LoadingSection from './LoadingSection';

const MsTimeTravelSection = ({ data = [] }) => {
  const { isPdfMode } = useSelector((state) => state.others);

  if (!data?.length) {
    return <LoadingSection />;
  }

  return (
    <div className={'flex flex-col justify-evenly'}>
      {data.map((section) => {
        return (
          <div key={_.uniqueId('ms__section__')} className={'flex flex-col'}>
            <h3
              className={classNames('font-bold underline', {
                'text-lg': isPdfMode,
                'text-base 2xl:text-lg': !isPdfMode,
              })}
            >
              {section.title}
            </h3>
            <ul className="list-disc ml-6">
              {section.dataSection.map(
                ({ title, dataURL, unit, data }, index) => {
                  return (
                    <TravelTimeItem
                      isPdfMode={isPdfMode}
                      title={title}
                      dataURL={dataURL}
                      data={data}
                      unit={unit}
                      key={index}
                    />
                  );
                }
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default MsTimeTravelSection;
