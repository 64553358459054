import { createAsyncThunk } from '@reduxjs/toolkit';
import { ciblageCreateActions } from '../../slices/ciblageCreate';
import axios from 'axios';

export const getEpcis = createAsyncThunk(
  'ciblage/getCommuesEpci',
  async (query, { dispatch }) => {
    dispatch(ciblageCreateActions.setLoading({ epci: true }));
    return axios
      .get(
        `${process.env.BASE_URL_API}/v4/eval_parcel/nom_zones_ciblages?q=${query.trim()}`
      )
      .then(({ data }) => {
        const mappedData = ((data.length && data) || [])
          .filter((item) => item[2] === 'epci')
          .map((item) => ({
            code: item[1],
            label: item[0],
          }));
        dispatch(ciblageCreateActions.setEpcis(mappedData));
        dispatch(ciblageCreateActions.setLoading(null));
        return mappedData;
      })
      .catch((e) => {
        console.log(e);
        dispatch(ciblageCreateActions.setEpcis([]));
        dispatch(ciblageCreateActions.setLoading(null));
        return [];
      });
  }
);
