import React from 'react';
import {connect} from 'react-redux';
import {signUpUser} from '@/store/actions/parcel';
// import 'remixicon/fonts/remixicon.css';
// import '@/stylesheets/page.css';
// import "@/stylesheets/button.css";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
// import Navbar from "@components/molecules/Navbar";
// import 'leaflet/dist/leaflet.css';
import Button from '@components/Button';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const Study = (props) => {
    const {
        primary,
        label,
        signUpUser,
        apiTest,
        size,
        mode,
        backgroundColor,
        Background,
    } = props;

    // useEffect(() => {
    //   signUpUser();
    // }, [signUpUser]);

    return (
        <div className='bg-white'>
            <div className='container mx-auto'>
                <div className='w-full grid md:grid-cols-11 col-span-12 gap-6'>
                    <div className='grid grid-cols-2 md:col-span-4 col-span-12 gap-6 mt-8 mb-8 min-content'>
                        <div
                            className='text-white shadow-xl bg-blue-darken grid col-span-2 bg-white shadow-lg rounded-xl p-8'>
                            <p className='mb-1 mt-0'>Commander, Sélectionner</p>
                            <p className='text-xs font-bold mt-0 mb-0'>Mon étude de marché</p>
                            <div
                                className={
                                    'flex bg-white mt-5 outline items-center ring-1 ring-gray-400 transition duration-300	transform outline-none rounded-sm shadow-sm w-full justify-between'
                                }
                            >
                                <input
                                    className={'outline-none p-3 w-full'}
                                    placeholder='Marché immobilier neuf...'
                                ></input>
                                <i className='text-lg cursor-pointer ri-close-line transform duration-500 ease-in-out hover:scale-150 transition p-3'></i>
                            </div>
                            <div
                                className={
                                    'flex bg-white mt-5 outline items-center ring-1 ring-gray-400 transition duration-300	transform outline-none rounded-sm shadow-sm w-full justify-between'
                                }
                            >
                                <input
                                    className={'outline-none p-3 w-full'}
                                    placeholder="Indiquez votre territoire d'étude..."
                                ></input>
                                <i className='text-lg cursor-pointer ri-close-line transform duration-500 ease-in-out hover:scale-150 transition p-3'></i>
                            </div>
                        </div>

                        <div className='bg-white shadow-2xl rounded-xl p-6 col-span-2'>
                            <h4 className='font-extrabold text-black'>Mes terrains</h4>
                            <div className='p-3'>
                                {new Array(4).fill().map((v) => (
                                    <>
                                        <p className='font-bold mb-0'>Etudes lorem ipsum</p>
                                        <span className='text-sm text-gray-500'>
                      10 Juin 2021 Etudes lorem ipsum
                    </span>
                                    </>
                                ))}
                                <div className='flex justify-center mt-4'>
                                    <Button title='Voir toutes Mes terrains'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='grid grid-cols-2 md:col-span-7 col-span-12 gap-6 mt-8 mb-8'
                        style={{height: 'min-content'}}
                    >
                        <div className='col-span-2 justify-between w-full'>
                            <h3 className='font-black'>Nos études</h3>
                        </div>
                        <div className='col-span-1' style={{height: 'min-content'}}>
                            <div
                                style={{
                                    background:
                                        'url(https://images.unsplash.com/photo-1498036882173-b41c28a8ba34?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80)',
                                    backgroundSize: 'cover',
                                }}
                                className='card_after relative text-white shadow-lg rounded-xl p-6'
                            >
                                <div className='z-50 relative'>
                                    <h3 className=''>Bien vivre en périurbain</h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                    </p>
                                    <div>
                                        <Button title='Aperçu'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1'>
                            <div className='h-full bg-blue-darken text-white shadow-lg rounded-xl p-6'>
                                <h3 className=''>Services proximité</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut labore et dolore
                                </p>
                                <div>
                                    <Button theme='light' title='Aperçu'/>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2' style={{height: 'min-content'}}>
                            <div className='card-secondary'>
                                <h3 className='font-extrabold'>Paris grande couronne</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut labore et dolore
                                </p>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className='card-secondary'>
                                <h3 className='font-extrabold'>Paris grande couronne</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut labore et dolore
                                </p>
                            </div>
                        </div>
                        {/* <Table /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        apiTest: state.apiTest,
    };
};

export default connect(mapStateToProps, {signUpUser})(Study);
