import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import classNames from "classnames";

export default function NewRatingHelpModal() {
    let [isOpen, setIsOpen] = useState(true)


    // useEffect(() => {
    //     if (localStorage.getItem('sessionCookie4NewRatingHelpModalState')) {
    //         setIsOpen(localStorage.getItem('sessionCookie4NewRatingHelpModalState') === 'true')
    //     }
    // }, [])


    function closeModal() {
        // localStorage.setItem('sessionCookie4NewRatingHelpModalState', "false");
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <> 
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className={classNames({"fixed inset-0 bg-black bg-opacity-25": isOpen})}/>
                    </Transition.Child>

                    <div className={classNames({"fixed inset-0 overflow-y-auto": isOpen})}>
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Comment sélectionner le terrain à étudier ?
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className="text-sm text-gray-800">
                                            <span className={'font-bold'}>Par adresse</span>
                                            <p>Dans la section "Nouvelle Recherche", recherchez l'adresse du terrain à analyser, puis sélectionnez les parcelles sur la carte.</p>
                                        </div>
                                        <div className="text-sm text-gray-800">
                                            <span className={'font-bold'}>Mode exploration - sélection sur la carte</span>
                                            <p>En cliquant directement sur la carte vous pourrez sélectionner la ou les parcelle(s) de votre terrain. </p>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            OK, merci !
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
