import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import {
  setFitBound,
  showGeoJsonInMap,
  removeAllLayers,
} from '../../../_helpers/_map-helper';

export default function GeoJsons({ selectedLayers, onEachlayer, layers }) {
  const map = useMap();
  const [geoJsons, setGeoJsons] = useState([]);

  useEffect(() => {
    removeAllLayers(geoJsons, map);
    setGeoJsons(
      showGeoJsonInMap({
        map,
        layers,
        onEachlayer,
        selectedLayers,
        fillColorSelected: 'gray',
      })
    );
  }, [layers, map, selectedLayers]);

  useEffect(() => {
    setFitBound(map, layers);
  }, [layers]);

  return <></>;
}
