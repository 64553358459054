import axios from 'axios';
import Rails from '@rails/ujs';

let authorizationInterceptor = undefined;
let notAuthorizedInterceptor = undefined;
export let params = {};

let config = {
  baseURL: process.env.BASE_URL,
  headers: {
    Accept: 'application/json',
    // TODO remove this hardcoded token
    Authorization: 'Token token=1XNk7ReTnVBCG7uLJIGQtAtt',
    'Access-Control-Allow-Origin': '*',
    'X-CSRF-Token': Rails.csrfToken(),
  },
};

let Api = axios.create(config);

export const apiV5 = axios.create({
  headers: { Authorization: `Token token=${process.env.API_V5_TOKEN}` },
});

export const addAutorization = (token) => {
  authorizationInterceptor = Api.interceptors.request.use((config) => {
    config.headers.Authorization = 'Token token=' + token;
    return config;
  });
  notAuthorizedInterceptor = Api.interceptors.response.use(
    (r) => r,
    (err) => {
      return Promise.reject(err);
    }
  );
};
//
// Api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         if (typeof error.response === 'undefined') {
//         }
//         return error.response;
//     }
// );

export const removeAuthorization = () => {
  Api.interceptors.request.eject(authorizationInterceptor);
  Api.interceptors.response.eject(notAuthorizedInterceptor);
};

export default Api;
