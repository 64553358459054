import React from 'react';
import classNames from 'classnames';
import {XIcon} from "@heroicons/react/solid";

const BannerAlert = ({setOpenNotice, color, children}) => {
    const classes = classNames({
        'bg-green-400': color?.green,
        'bg-red-400': color?.red,
        'bg-blue-400': color?.blue,
        'items-center': true,
        'text-xs': true,
        'text-white': true,
        'p-2': true,
        'justify-between': true,
        flex: true,
    });

    return (
        <div id={'banner-alert'} className={classes}>
            <span className='ml-2 text-sm'>{children}</span>
            <XIcon
                onClick={() => setOpenNotice(false)}
                className='cursor-pointer text-lg ri-close-circle-fill w-5 text-blue-100 h-5'
            />
        </div>
    );
};

export default BannerAlert;
