import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommune } from '../../../store/actions/ciblage/commune';
import inArray from '../../../_helpers/inArray';

export const CommunesItem = ({ name, id, onClick, active }) => {
  const handleClick = () => {
    onClick && onClick(id, !active);
  };

  return (
    <div
      onClick={handleClick}
      className="flex w-full px-4 py-2 cursor-pointer border-1 rounded-md items-center border-gray-400 mb-1 hover:bg-gray-200"
    >
      <div className="h-4 w-4 border-1 border-blue-500 mr-2 rounded-default flex justify-center items-center">
        {active && (
          <span className="h-3 w-3 bg-blue-500 rounded-default block"></span>
        )}
      </div>
      <div className="text-sm">{name}</div>
    </div>
  );
};

export default function ListCommunes({ orderBySelected = false }) {
  const {
    communes,
    valueTerritoire: { selectedCommunes },
    cibler,
  } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const handleClickCommuneItem = (id, checked) => {
    dispatch(selectCommune({ id, checked }));
  };

  const mappedCommunes = useMemo(
    () =>
      orderBySelected
        ? [
            ...communes.filter((item) =>
              inArray(selectedCommunes, item.id, 'id')
            ),
            ...communes.filter(
              (item) => !inArray(selectedCommunes, item.id, 'id')
            ),
          ].sort((a, b) => b.properties.name - a.properties.name)
        : communes,
    [orderBySelected, communes, selectedCommunes]
  );

  return (
    <div style={{ maxHeight: 215 }} className="overflow-y-scroll pr-4">
      {mappedCommunes.map(({ id, properties: { name } }) => (
        <CommunesItem
          key={id}
          id={id}
          name={name}
          onClick={!cibler && handleClickCommuneItem}
          active={inArray(selectedCommunes, id, 'id')}
        />
      ))}
    </div>
  );
}
