import React, { useState } from 'react';
import { connect } from 'react-redux';
import { registerUser } from '@/store/actions/parcel';
import AuthContainer from './AuthContainer';
import Button from '../../components/Button';
import { getUrlResetPassword } from '../../store/slices/others';
import useDebounce from '../../_helpers/useDebounce';
import { getFetcher } from '../../api/fetcher';
import classNames from 'classnames';

const ForgetPassword = ({ registerUser }) => {
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [sent, setSent] = useState(false);

  const handleSubmit = async () => {
    getUrlResetPassword(email);
    setSent(true);
  };

  const checkEmail = async (email = '') => {
    const { exists } = await getFetcher(
      `/api/v1/user_exists?email=${encodeURIComponent(email.trim())}`
    );
    return exists;
  };

  useDebounce(async () => {
    const exist = await checkEmail(email);
    setErrorEmail(!exist);
  }, [email]);

  return (
    <AuthContainer login>
      <div className="z-50 w-full  shadow-2xl bg-white p-6 rounded-lg flex flex-col">
        <h3 className="text-center text-lg">
          Vous avez oublié votre mot de passe ?
        </h3>

        {!sent && (
          <>
            <span className="text-gray-700 mt-6">Email</span>
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              className="border-2 border-gray-400 p-2 rounded-sm"
            />
            {errorEmail && !!email.length && (
              <span className="block text-xs10 text-red-500">
                Cette adresse email n'existe pas.
              </span>
            )}
            <div className="flex flex-col mt-6 items-center">
              <div
                className={classNames('w-2/4', {
                  'opacity-50': email.length === 0 || errorEmail,
                })}
              >
                <Button
                  disabled={email.length === 0 || errorEmail}
                  title="Réinitialiser mon mot de passe"
                  type="submit"
                  handleClick={() => email.length && handleSubmit()}
                >
                  Réinitialiser mon mot de passe
                </Button>
              </div>
            </div>
          </>
        )}
        {sent && (
          <div className="text-center mt-8">
            <p>
              <strong>Nous vous avons envoyé par email un lien</strong>
              <br />
              permettant de changer votre mot de passe !
            </p>
          </div>
        )}
      </div>
    </AuthContainer>
  );
};

export default connect(null, { registerUser })(ForgetPassword);
