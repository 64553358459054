import React, {createContext, useState} from "react";

export const dataVisualisationContext = createContext(null)

const DataVisualisationProvider = ({children}) => {
    const [urlMap, setUrlMap] = useState("")
    const [coordinatesParcels, setCoordinatesParcels] = useState([])
    const [tabIndexSelected, setTabIndexSelected] = useState(null)

    //selectors for maps
    const [selectorsArray, setSelectorsArray] = useState([])

    return <dataVisualisationContext.Provider value={{
        urlMap,
        setUrlMap,
        selectorsArray,
        setSelectorsArray,
        coordinatesParcels,
        setCoordinatesParcels,
        tabIndexSelected,
        setTabIndexSelected,
    }}>
        {children}
    </dataVisualisationContext.Provider>
}


export default DataVisualisationProvider
