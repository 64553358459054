import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

const InputText = ({
  onChange,
  value,
  label,
  placeholder,
  type = 'text',
  onBlur,
  name,
  error,
  disabled,
}) => {
  const handleChange = (e) => {
    if (type === 'date') {
      onChange &&
        onChange({ target: { name, value: new Date(e.target.value) } });
    } else {
      onChange && onChange(e);
    }
  };

  return (
    <div className="flex flex-col">
      <label className="font-semibold">{label}</label>
      <input
        disabled={disabled}
        type={type}
        value={
          (type === 'date' ? moment(value).format('YYYY-MM-DD') : value) || ''
        }
        placeholder={placeholder}
        onChange={handleChange}
        className={classNames(
          'w-full border-1 rounded-md px-4 py-2 border-gray-500 focus:outline-none',
          { 'opacity-50': disabled }
        )}
        onBlur={onBlur}
        name={name}
      />
      {error && <span className="text-xs text-red-600 mt-1">{error}</span>}
    </div>
  );
};

export default InputText;
