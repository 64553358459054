import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import parcelApi from '../actions/parcel';
import loader from '../actions/loader';
import project from '../actions/project';
import target from '../actions/target';
import table_rating from '../actions/table_rating';
import ciblageCreate from '../slices/ciblageCreate';
import ciblageResult from '../slices/ciblageResult';
import others from '../slices/others';
import programme from '../slices/programme';
import marketSurvey from '../slices/marketSurvey';
import { DB_NAME_INDEXED_DB } from '../../constants/constants';

localforage.config({
  name: DB_NAME_INDEXED_DB,
  driver: localforage.INDEXEDDB,
});

const persistConfigCiblageCreate = {
  key: 'ciblage_create',
  storage: localforage,
};

export default combineReducers({
  parcelApi,
  loader,
  project,
  target,
  table_rating,
  ciblageCreate: persistReducer(persistConfigCiblageCreate, ciblageCreate),
  ciblageResult,
  others,
  programme,
  marketSurvey,
});
