import React, { useEffect, useMemo, useState } from 'react';
import AuthContainer from './AuthContainer';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import classNames from 'classnames';
import RadioButtonGroup from '../../components/molecules/radio-group/RadioGroup';
import ReCAPTCHA from 'react-google-recaptcha';
import MessageCantCreateAccount from './MessageCantCreateAccount';
import messageIcon from '../../../../assets/images/message.png';
import useDebounce from '../../_helpers/useDebounce';
import { getFetcher } from '../../api/fetcher';
import SuccessMessageRegistration from './SuccessMessageRegistration';
import {
  checkCanCreateUser,
  checkTrialCode,
  registerUserV3,
  saveLead,
} from '../../store/slices/others';
import { getBgLogin } from '../../store/actions/others';
import ahoy from 'ahoy.js';

ahoy.configure({
  visitsUrl: '/steps/visits',
  eventsUrl: '/steps/events',
});


const formUserSchema = yup.object({
  first_name: yup.string().required('Champ obligatoire'),
  last_name: yup.string().required('Champ obligatoire'),
  phone_number: yup.string().required('Champ obligatoire'),
  email: yup.string().email('Email invalide.').required('Champ obligatoire'),
  date_consent_gdpr_org: yup
    .boolean()
    .required('Champ obligatoire')
    .oneOf([true], 'Champ obligatoire')
    .nullable(),
  organization: yup.string().required('Champ obligatoire'),
  company: yup.string().required('Champ obligatoire'),
  trial_code: yup.string(),
  isNotRobot: yup
    .boolean()
    .required('Champ obligatoire')
    .oneOf([true], 'Champ obligatoire')
    .nullable(),
});

export const TextField = ({
  className,
  type = 'text',
  value = '',
  name,
  onChange,
  onBlur,
  required = false,
  error,
  label,
  placeholder,
}) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      {label && (
        <label className="text-base text-gray-700">
          {label}&nbsp;
          {required && '*'}
        </label>
      )}
      <input
        className={classNames('rounded-sm border-2', {
          'border-gray-400': !error,
          'border-red-500': error || error?.length,
        })}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {error &&
        !!value.length &&
        (type === 'email' || name === 'trial_code' || type === 'password') && (
          <span className="text-xs12 text-red-500">{error}</span>
        )}
    </div>
  );
};

export const ReCAPTCHAInput = ({ name, onChange, className, error }) => {
  const handleChange = (_value) => {
    onChange && onChange({ target: { value: _value ? true : false, name } });
  };

  return (
    <div className={className}>
      <ReCAPTCHA
        sitekey="6LfptgYpAAAAANJbIrLHlIQn_5n--LVwcXYJByvM"
        onChange={handleChange}
      />
      {error && <div className="text-red-500 text-xs12">{error}</div>}
    </div>
  );
};

const initValuesInfoForm = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  date_consent_gdpr_org: null,
  organization: '',
  company: '',
  isNotRobot: null,
  trial_code: '',
};

const optionsAdherent = [
  {
    value: 'cecim',
    label: 'Adhérent CECIM',
  },
  {
    value: 'capem',
    label: 'Abonné CAPEM',
  },
  {
    value: 'autre',
    label: 'Autre',
  },
];

const FormInfo = ({ onChange }) => {
  const [errorEmail, setErrorEmail] = useState({ error: false, message: '' });
  const [email, setEmail] = useState('');
  const [trialCode, setTrialCode] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [errorCodetrial, setErrorCodeTrial] = useState({
    error: false,
    message: '',
  });

  const handleChangePhoneNumber = (val) => {
    setPhoneNumber(val); // Assuming you have a setter like this
  };

  const handleSubmitForm = async (values) => {
    if (!errorEmail.error && !errorCodetrial.error) {
      const checkCanCreateUserRes = await checkCanCreateUser(
        values.trial_code?.length
          ? { organization: values.organization, trial_code: values.trial_code }
          : { organization: values.organization }
      );
      if (checkCanCreateUserRes?.status === 200) {
        const allow = checkCanCreateUserRes.data?.allow;
        onChange &&
          onChange({ ...values, date_consent_gdpr_org: new Date(), allow });
      } else {
        alert('Error network !!!');
      }
    }
  };

  const checkEmail = async (email = '') => {
    let inOrg = true;

    // if (
    //   organization &&
    //   organization.length &&
    //   ['cecim', 'capem'].includes(organization)
    // ) {
    //   try {
    //     const { is_in_organization } = await getFetcher(
    //       `/api/v1/users/email/in_organization?organization=${organization.trim()}&email=${email.trim()}`
    //     );
    //     inOrg = is_in_organization;
    //   } catch (e) {
    //     inOrg = true;
    //   }
    // }

    const { exists } = await getFetcher(
      `/api/v1/user_exists?email=${encodeURIComponent(email.trim())}`
    );
    return {
      error: exists || !inOrg,
      message:
        exists && inOrg
          ? /* html */ `Un compte existe déjà avec cette adresse. <a class="text-blue-500 hover:underline" href="/users/sign_in">Se connecter</a>`
          : !inOrg
          ? "Vous devez utiliser votre adresse email d'abonné."
          : '',
    };
  };

  useDebounce(
    async () => {
      if (trialCode.length) {
        const resp = await checkTrialCode(trialCode);
        if (resp?.status === 422) {
          setErrorCodeTrial({ error: true, message: resp.data.errors[0] });
        } else {
          setErrorCodeTrial({ error: false, message: '' });
        }
      } else {
        setErrorCodeTrial({ error: false, message: '' });
      }
    },
    [trialCode],
    500
  );

  useDebounce(
    async () => {
      if (email.length) {
        const errors = await checkEmail(email);
        setErrorEmail(errors);
      } else {
        setErrorEmail({ error: false, message: '' });
      }
    },
    [email],
    500
  );

  // Add useEffect hook for tracking phone number changes with Ahoy
  useEffect(() => {
    if (phone_number.length >= 10) {
      console.log("hey")
      ahoy.track('$registration_step_1', { phone_number: phone_number, email: email });
    }
  }, [phone_number, email]); // This ensures Ahoy tracking is called only when phone_number changes


  return (
    <Formik
      validationSchema={formUserSchema}
      initialValues={initValuesInfoForm}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        touched,
        errors,
        handleBlur,
        isValid,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-6">
            <TextField
              text="text"
              label="Prénom"
              required
              className="w-full"
              name="first_name"
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.first_name && errors.first_name}
            />
            <TextField
              text="text"
              label="Nom"
              className="mt-6 md:mt-0"
              required
              name="last_name"
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.last_name && errors.last_name}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 md:mt-6">
            <TextField
              text="text"
              label="Société"
              className="mt-6 md:mt-0"
              required
              name="company"
              value={values.company}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.company && errors.company}
            />
            <TextField
              text="text"
              label="Téléphone portable"
              className="mt-6 md:mt-0"
              required
              name="phone_number"
              value={values.phone_number}
              onChange={handleChange}
              onChange={(e) => {
                handleChange(e);
                handleChangePhoneNumber(e.target.value);
              }}
              onBlur={handleBlur}
              error={touched.phone_number && errors.phone_number}
            />
          </div>
          <div className="mt-6">
            <TextField
              type="email"
              label="Email pro."
              required
              name="email"
              value={values.email}
              onChange={(e) => {
                handleChange(e);
               setEmail(e.target.value);
              }}
              onBlur={handleBlur}
              error={touched.email && errors.email}
            />
            {errorEmail.error && (
              <div
                className="text-red-500 text-xs12"
                dangerouslySetInnerHTML={{ __html: errorEmail.message }}
              ></div>
            )}
          </div>
          <div className="mt-6 flex flex-wrap md:flex-nowrap gap-x-6">
            <div>
              <label className="text-gray-700 mb-2 block">Vous êtes*</label>
              <RadioButtonGroup
                label
                options={optionsAdherent}
                keyLabel="label"
                keyValue="value"
                name="organization"
                value={values.organization}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.organization && errors.organization && (
                <div className="text-red-500 text-xs12">
                  {errors.organization}
                </div>
              )}
            </div>
            <TextField
              text="text"
              label="J’ai un code"
              className="mt-6 md:mt-0 flex-grow"
              name="trial_code"
              value={values.trial_code}
              onChange={(e) => {
                handleChange(e);
                setTrialCode(e.target.value);
              }}
              error={errorCodetrial.error && errorCodetrial.message}
            />
          </div>
          <div className="mt-6">
            <div>
              <input
                type="checkbox"
                id="date_consent_gdpr_org"
                name="date_consent_gdpr_org"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange({
                    target: {
                      value: e.target.checked,
                      name: 'date_consent_gdpr_org',
                    },
                  });
                }}
                value="checked"
              />
              &nbsp;
              <label
                htmlFor="date_consent_gdpr_org"
                className="text-gray-700 mb-2"
              >
                J'accepte{' '}
                <a
                  href="https://www.evalparcel.com/mentions-legales"
                  className="hover:underline text-blue-500"
                  target="_blank"
                >
                  les CGU{' '}
                </a>{' '}
                et{' '}
                <a
                  className="hover:underline text-blue-500"
                  href="https://www.evalparcel.com/politique-de-confidentialite-et-rgpd"
                  target="_blank"
                >
                  la politique de confidentialité *
                </a>
              </label>
            </div>
            {touched.date_consent_gdpr_org && errors.date_consent_gdpr_org && (
              <div className="text-red-500 text-xs12">
                {errors.date_consent_gdpr_org}
              </div>
            )}
          </div>
          <div className="mt-6 flex justify-center">
            <ReCAPTCHAInput
              className=""
              onChange={handleChange}
              name="isNotRobot"
              error={touched.isNotRobot && errors.isNotRobot}
            />
          </div>
          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              disabled={!isValid || errorEmail.error || errorCodetrial.error}
              className={classNames(
                'text-white  bg-blue-500 hover:bg-blue-700 w-full lg:w-1/2 py-3 px-8 rounded-md',
                {
                  'opacity-50':
                    !isValid || errorEmail.error || errorCodetrial.error,
                }
              )}
            >
              Valider
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const initValuesMdp = {
  password: '',
  password_confirmation: '',
};

const mdpSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Minimum 8 caractères')
    .max(64, 'Maximum 64 caractères')
    .required('Champ obligatoire'),
  password_confirmation: yup
    .string()
    .required('Champ obligatoire')
    .oneOf([yup.ref('password'), null], `La confirmation n'est pas identique`),
});

const MdpForm = ({ onChange }) => {
  const handleSubmit = (values) => {
    onChange && onChange(values);
  };

  return (
    <>
      <h3 className="font-semibold text-center">Créer votre compte</h3>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initValuesMdp}
        validationSchema={mdpSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              type="password"
              label="Choisir votre mot de passe"
              className="mt-6"
              required
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && errors.password}
            />
            <TextField
              type="password"
              label="Confirmer votre mot de passe"
              className="mt-6"
              required
              name="password_confirmation"
              value={values.password_confirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.password_confirmation && errors.password_confirmation
              }
            />
            <div className="mt-6">
              <button
                type="submit"
                disabled={!isValid}
                className={classNames(
                  'text-white bg-blue-500 hover:bg-blue-700 py-3 w-full rounded-md',
                  { 'opacity-50 cursor-not-allowed': !isValid }
                )}
              >
                Valider
              </button>
            </div>
          </form>
        )}
      </Formik>
      <p className="mt-6 text-center">
        Pour toutes questions vous pouvez nous joindre directement:
      </p>
      <div className="flex items-center justify-center">
        <img className="w-8 h-auto" src={messageIcon} alt="message" />
        <p>Chat en bas à droite de votre écran</p>
      </div>
      <p className="mt-6 text-center">
        ou par email:
        <br />
        <a
          href="mailto:support@evalparcel.com"
          className="hover:underline text-blue-500"
        >
          support@evalparcel.com
        </a>
      </p>
    </>
  );
};

const FormUserInformations = () => {
  const { bgLogin } = useSelector((state) => state.others);
  const dispatch = useDispatch();
  const [infosValues, setInfosValues] = useState(null);
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmitInfoForm = (values) => {
    setInfosValues(values);

    // save Lead
    if (!values.allow) {
      const {
        organization,
        isNotRobot,
        date_consent_gdpr_org,
        trial_code,
        allow,
        ...rest
      } = values;
      saveLead(rest);
    }
  };




  const handleSave = async (mdpValues) => {
    const allValues = { ...infosValues, ...mdpValues };
    delete allValues.isNotRobot;
    delete allValues.allow;
    const trialCodeRegex = /^[A-Za-z0-9]+_[A-Za-z0-9]+$/;

    setMessage(
      // Check if any organization is provided
      allValues.organization && 
      // Check if the trial code matches the "XXXXX_XXXX" format - enterprise code
      trialCodeRegex.test(allValues?.trial_code) 
        ? 'enterprise'
        : allValues.organization === 'cecim'  
          ? 'cecim'
          : allValues.organization === 'capem' && allValues?.trial_code?.length
          ? 'capem'
          : 'capem_no_trial_code'
    );
    try {
      const res = await registerUserV3(allValues);
      setSent(true);
    } catch (e) {
      alert('Error');
    }
  };

  useEffect(() => {
    dispatch(getBgLogin());
  }, []);

  const messages = useMemo(
    () => ({
      enterprise: 
      'Bienvenue ! Nous sommes ravis de vous compter parmi nos nouveaux abonnés.',
      cecim:
        'En tant qu’adhérent CECIM, nous sommes ravis de vous offrir un test gratuit pour une durée de 7 jours.',
      capem_no_trial_code:
        'En tant qu’abonné CAPEM, nous sommes ravis de vous offrir un test gratuit pour une durée de 7 jours.',
      capem: `En tant qu’abonné CAPEM${
        infosValues?.trial_code && infosValues?.trial_code?.length
          ? ` (${infosValues.trial_code})`
          : ''
      }, nous sommes ravis de vous offrir un test gratuit pour une durée d’un mois.`,
    }),
    [infosValues?.trial_code]
  );

  return (
    <AuthContainer mediaUrl={bgLogin} login registration>
      <div className="w-full py-6 px-4 bg-white rounded-lg shadow-xl">
        {!sent && (
          <>
            {!infosValues && (
              <>
                <h3 className="font-semibold text-center">
                  Créer votre compte
                </h3>
                <FormInfo onChange={handleSubmitInfoForm} />
              </>
            )}
            {infosValues && !infosValues.allow && <MessageCantCreateAccount />}
            {infosValues && infosValues.allow && (
              <MdpForm onChange={handleSave} />
            )}
          </>
        )}
        {sent && (
          <SuccessMessageRegistration
            messageType={message}
            messages={messages}
          />
        )}
      </div>
    </AuthContainer>
  );
};

export default FormUserInformations;
