import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCiblage,
  updateCiblageParams,
} from '../../../store/actions/ciblage/updateCiblage';
import EditableTextfield from '../../molecules/editable-textfield/EditableTextfield';
import moment from 'moment';
import { CommunesItem } from '../list-communes/ListCommunes';
import { ciblageResultActions } from '../../../store/slices/ciblageResult';
import {
  buildParamsByJson,
  getCiblageParcels,
  getNbParcelBeforeEdit,
} from '../../../store/actions/ciblage/parcel';
import Modal from '../../molecules/modal/Modal';
import FilterTerrain from '../filter-terrain/FilterTerrain';
import { PLUS_OPTIONS, PLU_ZONES } from '../../../constants/constants';
import useDebounce from '../../../_helpers/useDebounce';
import spacingThousands from '../../../_helpers/thousandShow';
import classNames from 'classnames';

const InfoContainer = ({ children }) => {
  return <p className="font-semibold text-xl text-gray-300">{children}</p>;
};

const ListCommunesParcels = () => {
  const { communesParcels, selectedCommunes } = useSelector(
    (state) => state.ciblageResult
  );
  const dispatch = useDispatch();

  const handleClickCommuneItem = (nationalCode) => {
    const communesNationalCodes = [
      ...selectedCommunes.filter((el) => el !== nationalCode),
      selectedCommunes.find((el) => el === nationalCode) ? null : nationalCode,
    ].filter((el) => !!el);
    dispatch(ciblageResultActions.setCheckedParcels([]));
    dispatch(ciblageResultActions.setSelectedCommunes(communesNationalCodes));
    if (communesNationalCodes.length) {
      dispatch(ciblageResultActions.setCurrentPage(1));
    }
    dispatch(getCiblageParcels({ communesNationalCodes }));
  };

  return (
    <>
      {!!communesParcels?.length && (
        <div className="mt-1 bg-white max-h-full h-full p-1 rounded-md overflow-y-auto">
          {communesParcels.map(
            ({ id, attributes: { name, national_code } }) => (
              <CommunesItem
                key={id}
                id={national_code}
                name={name}
                onClick={handleClickCommuneItem}
                active={selectedCommunes?.includes(national_code)}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

const FilterTerrainResume = () => {
  const {
    filtersTerrain: { surfaceRange, zonePlu, zoneBatie, zoneANRU, zac },
  } = useSelector((state) => state.ciblageResult);

  return (
    <div className="p-4 rounded-md bg-white max-h-full overflow-y-auto">
      <span>
        <span className="font-semibold">Surface</span> : {surfaceRange[0]} à{' '}
        {surfaceRange[1]} m²
      </span>
      {!!zonePlu?.length && (
        <span>
          , <span className="font-semibold">Zone plu :</span>{' '}
          <span>
            {zonePlu.length === PLUS_OPTIONS.length
              ? 'toutes les zones PLU'
              : zonePlu.map((item) => item.value).join(', ')}
          </span>
        </span>
      )}
      {zoneBatie !== null && (
        <span>
          , <span className="font-semibold">en zone bâtie</span> :{' '}
          {zoneBatie ? 'OUI' : 'NON'}
        </span>
      )}
      {zoneANRU !== null && (
        <span>
          , <span className="font-semibold">en zone ANRU</span> :{' '}
          {zoneANRU ? 'OUI' : 'NON'}
        </span>
      )}
      {zac !== null && (
        <span>
          , <span className="font-semibold">en zone ZAC</span> :{' '}
          {zac ? 'OUI' : 'NON'}
        </span>
      )}
    </div>
  );
};

const ModalFormFilterTerrain = ({ open, onClose }) => {
  const { filtersTerrain, nbParcelOnEdit, loading } = useSelector(
    (state) => state.ciblageResult
  );
  const [valueTerrain, setValueTerrain] = useState(null);

  const dispatch = useDispatch();

  const handleChange = useCallback((key, value) => {
    setValueTerrain((state) => ({ ...state, [key]: value }));
  }, []);

  const handleSubmit = () => {
    dispatch(updateCiblageParams(valueTerrain));
    onClose && onClose();
  };

  useEffect(() => {
    setValueTerrain(filtersTerrain);
  }, [filtersTerrain]);

  useDebounce(
    () => {
      if (valueTerrain && open) {
        dispatch(getNbParcelBeforeEdit(valueTerrain));
      }
    },
    [valueTerrain, open],
    0
  );

  const disableBtn = useMemo(
    () =>
      nbParcelOnEdit > 1000 ||
      nbParcelOnEdit <= 0 ||
      nbParcelOnEdit === 'Affinez les critères',
    [nbParcelOnEdit]
  );

  return (
    <Modal open={open} onClose={onClose}>
      <div className="mt-6">
        <FilterTerrain
          noKeys
          valueTerrain={valueTerrain}
          onChange={handleChange}
        />
        <div className="mt-6">
          <div className="border-2 border-gray-500 rounded-lg flex justify-center items-center flex-col p-4">
            <p className="text-lg font-semibold">Nombre de parcelles est.</p>
            <p
              className={classNames('text-2xl font-bold', {
                'text-red-500': disableBtn,
              })}
            >
              {loading?.nbParcelOnEdit
                ? '...'
                : !isNaN(nbParcelOnEdit)
                ? spacingThousands(nbParcelOnEdit)
                : nbParcelOnEdit}
            </p>
          </div>
          <button
            disabled={disableBtn || loading?.nbParcelOnEdit}
            onClick={handleSubmit}
            className={classNames(
              'w-full mt-4 text-white px-6 py-2 rounded-lg',
              { 'hover:bg-blue-700  bg-blue-500': !disableBtn },
              { 'bg-blue-300 cursor-not-allowed': disableBtn }
            )}
          >
            Sauvegarder votre modification
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default function HeaderListParcels() {
  const { ciblage, nbParcel, nbParcelOnEdit, communesParcels, allFiltersJson } =
    useSelector((state) => state.ciblageResult);
  const [openModalFilterTerrain, setOpenModalFilterTerrain] = useState(false);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    const communesNationalCodes = (communesParcels || []).map(
      ({ attributes: { national_code } }) => national_code
    );
    dispatch(ciblageResultActions.setSelectedCommunes(communesNationalCodes));
    dispatch(getCiblageParcels({ communesNationalCodes, noLoading: true }));
    setOpenModalFilterTerrain(true);
  };

  const handleSaveEditNameCiblage = useCallback((name) => {
    dispatch(updateCiblage({ name }));
  }, []);

  return (
    <>
      <ModalFormFilterTerrain
        open={openModalFilterTerrain}
        onClose={() => setOpenModalFilterTerrain(false)}
      />
      <div className="w-full p-4 bg-blue-800 flex flex-nowrap items-start gap-x-8 h-full justify-between">
        <div className="w-1/3 flex flex-col h-full">
          <h1 className="w-full">
            <EditableTextfield
              value={ciblage?.name || 'Nouveau ciblage'}
              classFont="font-semibold text-3xl max-w-full overflow-hidden overflow-ellipsis text-gray-300"
              onSave={handleSaveEditNameCiblage}
            />
          </h1>
          <div className="mt-2">
            <InfoContainer>
              Date :{' '}
              {ciblage && moment(ciblage.created_at).format('DD-MM-YYYY')}
            </InfoContainer>
            <InfoContainer>
              Communes ciblées : {communesParcels?.length}
            </InfoContainer>
            <InfoContainer>
              Parcelles ciblées : {nbParcelOnEdit || nbParcel}
            </InfoContainer>
          </div>
        </div>
        <div style={{ minWidth: 300 }} className="w-1/3 h-full flex flex-col">
          <h2 className="text-lg font-semibold text-gray-300">Communes</h2>
          <div className="flex-grow h-0 overflow-hidden rounded-md bg-white">
            <ListCommunesParcels />
          </div>
        </div>
        <div className="w-1/3 flex flex-col h-full">
          <h2 className="text-lg font-semibold text-gray-300 flex justify-between items-center">
            <span>Critères des terrains</span>
            <span
              onClick={handleOpenModal}
              className="text-base text-gray-500 hover:underline cursor-pointer"
            >
              Modifier
            </span>
          </h2>
          <div className="flex flex-col justify-between gap-2">
            <FilterTerrainResume />
            {allFiltersJson && (
              <a
                href={`${process.env.BASE_URL_API}/v5/eval_parcel/ciblage/parcels/csv${buildParamsByJson(
                  allFiltersJson
                )}`}
                download
                className="w-full hover:bg-blue-500 bg-blue-400 flex justify-center items-center py-3 text-white rounded-md"
              >
                Exporter les résultats
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
