import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { XCircleIcon } from '@heroicons/react/solid';
import cx from 'classnames';
import classNames from 'classnames';

const CustomAutocomplete = forwardRef(
  (
    {
      placeholder = '',
      options,
      value,
      keyValue,
      keyLabel,
      onChange,
      onChangeInputValue,
      readOnly = false,
      name,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [valueText, setValueText] = useState('');
    const [first, setFirst] = useState(false);

    useImperativeHandle(ref, () => ({
      setEmptyValueText() {
        setValueText('');
      },
    }));

    const handleChangeInputText = (e) => {
      setValueText(e.target.value);
      const keyword = e.target.value.trim().toLowerCase();
      if (keyword.length) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    const handleClick = () => {
      !readOnly &&
        setTimeout(() => {
          setOpen(!open);
        }, 100);
    };

    const handleClickRemove = () => {
      setValueText('');
      onChange && onChange(null, name);
      setTimeout(() => {
        setOpen(false);
      }, 101);
    };

    useEffect(() => {
      const closeSelect = () => {
        setOpen(false);
      };
      window.addEventListener('click', closeSelect);
      return () => {
        window.removeEventListener('click', closeSelect);
      };
    }, []);

    useEffect(() => {
      setValueText(value ? value[keyLabel] : '');
    }, [value]);

    useEffect(() => {
      const fnDebounce = setTimeout(() => {
        if (first) {
          onChangeInputValue && onChangeInputValue(valueText);
        }
        setFirst(true);
      }, 1000);
      return () => clearTimeout(fnDebounce);
    }, [valueText]);

    return (
      <div
        className="relative custom-autocomplete-container border-1 border-gray-500 rounded-md bg-white cursor-pointer mt-2"
        onClick={handleClick}
      >
        <div className="flex items-center justify-between h-full px-4">
          <input
            type="text"
            value={valueText}
            placeholder={placeholder}
            className={classNames(
              'w-full border-none text-sm xl:text-base bg-white p-0 font-medium',
              { 'font-semibold cursor-not-allowed': readOnly }
            )}
            onChange={handleChangeInputText}
            readOnly={readOnly}
          />
          {!!valueText?.length && !readOnly && (
            <XCircleIcon
              onClick={handleClickRemove}
              className="h-5 w-5 text-gray-400"
            />
          )}
        </div>
        <div
          className={cx(
            { hidden: !open },
            { block: open },
            'custom-autocomplete-item-container absolute z-50  left-0 border-1 border-gray-500 rounded-md overflow-hidden bg-white w-full'
          )}
          style={{ top: 52 }}
        >
          <div style={{ maxHeight: 250 }} className="overflow-y-auto">
            {(options || []).map((item) => (
              <div
                onClick={() => {
                  onChange && onChange(item, name);
                }}
                className="font-normal w-full text-xs xl:text-base  hover:bg-gray-100 p-3 border-b-1 border-gray-300"
                key={item[keyValue]}
              >
                {item[keyLabel]}
              </div>
            ))}
          </div>
          {options?.length === 0 && (
            <div className="font-normal italic text-xs xl:text-base text-gray-400 w-full p-3 border-b-1">
              ...
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default CustomAutocomplete;
