import {useEffect, useState} from 'react';

export default function useResponsive() {
    const [isMobileMode, setIsMobileMode] = useState(false);

    useEffect(() => {
        const match = window.matchMedia(`(max-width: 768px)`);
        if (match.matches) {
            setIsMobileMode(true);
        } else {
            setIsMobileMode(false);
        }
        const resize = () => {
            const match = window.matchMedia(`(max-width: 768px)`);
            if (match.matches) {
                setIsMobileMode(true);
            } else {
                setIsMobileMode(false);
            }
        };
        window.addEventListener('resize', resize);
    }, []);

    return isMobileMode;
}
