import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EPCI, ISOCHRONE, NAME } from '../../../constants/constants';
import { setSearchCommunes } from '../../../store/slices/ciblageCreate';

const searchBy = [
  {
    id: 1,
    label: 'Par leur nom',
    code: NAME,
    disabled: false,
  },
  {
    id: 2,
    label: 'Dans un EPCI (Intercommunalité)',
    code: EPCI,
    disabled: false,
  },
  {
    id: 3,
    label: 'Dans un isochrone (Accès en un temps donné autour d’une adresse)',
    code: ISOCHRONE,
    disabled: false,
  },
];

export default function SearchCommunesBy() {
  const { searchCommunesBy } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const handleClick = (code) => {
    dispatch(setSearchCommunes(searchBy.find((item) => item.code === code)));
  };

  return (
    <div className="px-4">
      <p className="rounded-lg bg-blue-200 text-blue-800 p-4 text-xs xl:text-sm">
        Il s’agit de sélectionner les communes sur lesquelles vous souhaitez
        ensuite rechercher un terrain.
      </p>
      <div className="mt-8">
        <p className="font-semibold mb-4">Sélectionnez vos communes :</p>
        {searchBy.map(({ id, label, code, disabled }) => (
          <button
            key={id}
            disabled={disabled}
            className={classNames(
              'font-medium block w-full text-sm xl:text-base text-left px-4 py-6 border-1 border-gray-300 rounded-md mb-2 cursor-pointer hover:bg-gray-200',
              { 'bg-gray-200': searchCommunesBy?.id === id },
              { 'opacity-50 cursor-not-allowed': disabled }
            )}
            onClick={() => handleClick(code)}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}
