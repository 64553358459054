import React, { useState, useEffect, useRef } from 'react';
import CamaieuLegendLayer from './CamaieuLegendLayer';
// import DefaultLegendLayer from './DefaultLegendLayer';
import BasicLegendLayer from './BasicLegendLayer';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

// TODO: make this fully legends layers not 'simple' div
const LegendLayer = ({ legendData, map }) => {
  const [open, setOpen] = useState(true);
  const isLg = useMediaQuery({ query: '(max-width: 1280px)' });
  const toogleBtnRef = useRef(null);

  useEffect(() => {
    let timeoutFunc = null;
    if (isLg) {
      timeoutFunc = setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
    return () => {
      timeoutFunc && clearTimeout(timeoutFunc);
    };
  }, [isLg]);

  const handleToogleDrowdown = () => {
    setOpen((curr) => !curr);
  };

  useEffect(() => {
    var openPopup = false;
    var closed = false;

    map.on('movestart', () => {
      !openPopup && !closed && setOpen(false);
    });
    map.on('moveend', () => {
      !openPopup && !closed && setOpen(true);
    });
    map.on('zoomstart', () => {
      !openPopup && !closed && setOpen(false);
    });
    map.on('zoomend', () => {
      !openPopup && !closed && setOpen(true);
    });
    map.on('popupopen', () => {
      setTimeout(() => {
        setOpen(false);
        openPopup = true;
      }, 500);
    });
    map.on('popupclose', () => {
      setTimeout(() => {
        setOpen(true);
        openPopup = false;
      }, 500);
    });
    if (toogleBtnRef.current) {
      toogleBtnRef.current.addEventListener('click', () => {
        closed = !closed;
      });
    }
  }, [map]);

  switch (legendData.type) {
    case 'camaieu':
      return (
        <div
          style={{ zIndex: 1000 }}
          className={'bg-white absolute ml-3 mt-3 rounded-lg p-2'}
        >
          <div
            ref={toogleBtnRef}
            onClick={handleToogleDrowdown}
            className={classNames(
              'p-2 hover:bg-gray-200 rounded-md text-sm font-semibold flex justify-between items-center gap-x-8',
              { 'border-b-1 border-gray-500': open }
            )}
          >
            <span>Légende</span>
            {open ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </div>
          {open && (
            <div className="mt-2">
              <CamaieuLegendLayer data={legendData} />
            </div>
          )}
        </div>
      );
    default:
      return (
        <div
          style={{ zIndex: 1000 }}
          className={'bg-white absolute ml-3 mt-3 rounded-lg p-2'}
        >
          <BasicLegendLayer data={legendData} />
        </div>
      );
  }
};
export default LegendLayer;
