import React, {useEffect, useRef} from 'react';
import {Marker, /* Popup, */ useMap} from "react-leaflet";
import L from "leaflet";
// import ProgramPopup from "./../ProgramPopup";
import {transformCoordinates} from "../../../../../_helpers/coordinate";
import SelectedProgram from '../../../../../../../assets/images/icons/programme-selectionne.svg';
// import CurrentField from '../../../../../../../assets/images/icons/terrain.svg';
// import ProgramOverSince2019 from '../../../../../../../assets/images/icons/programme-immobilier-termine-2019.svg';
// import ProgramInProgress from '../../../../../../../assets/images/icons/programme_en_cours.svg';
// import BuildingPermit from '../../../../../../../assets/images/icons/permis-de-construire.svg';

// import {DateTime} from "luxon";
import useSWR from "swr";
import _, {isEmpty, uniqueId} from "lodash";
import {getFetcher} from "../../../../../api/fetcher";
import {Error} from "../../../../utils/Error";
import Loading from "../../../../utils/Loading";
import ProgramPopupReadOnly from "./ProgramPopupReadOnly";
// import {useMsPrograms} from "../../../../../hooks/useMarketSurvey";

// let CurrentFieldIcon = new L.icon({
//     iconUrl: CurrentField,
// });
// let ProgramInProgressIcon = new L.icon({
//     iconUrl: ProgramInProgress,
// });

// let ProgramOverSince2019Icon = new L.icon({
//     iconUrl: ProgramOverSince2019,
// });

let SelectedProgramIcon = new L.icon({
    iconUrl: SelectedProgram,
    iconSize: [30, 40],
    iconAnchor: [15, 43],
});

// let BuildingPermitIcon = new L.icon({
//     iconUrl: BuildingPermit,
// });


function MSProgramCollectionMarker({programItem}) {
    const ref = useRef()

    return (
        <Marker
            ref={ref}
            position={transformCoordinates(programItem.geometry.coordinates)}
            icon={SelectedProgramIcon}
        >
            <ProgramPopupReadOnly
                ref={ref}
                tran_id={programItem.properties.tran_id}
                title={programItem.properties.nom_programme}
                subtitle={programItem.properties.promoteur}
                address={programItem.properties.address}
                housing_to_sale_count={programItem.properties.nb_logt_mis_en_vente}
                // free_to_buy_housing_count={program.properties.}
                average_price_init_per_square_meter={programItem.properties.pmmv}
                progress={programItem.properties.etat_avancement}
                release_date={programItem.properties.date_debut_commercialisation}
                is_selling_blocks={programItem.properties.comporte_vente_en_blocs}
                // vat_type={program.properties.}
            />
        </Marker>
    )
}

const MSProgramCollection = ({
                                 dataURL,
                                 setGeoJSONGlobal = () => {
                                     return true
                                 }
                             }) => {
    const map = useMap()
    if (!map) return

    const {
        data: programGeoData,
        error: programGeoDataFetchingError
    } = useSWR(dataURL, getFetcher)

    useEffect(() => {
        if (programGeoData) {
            setGeoJSONGlobal((geoJSONGlobal) => {
                return programGeoData.features.concat(geoJSONGlobal)
            })
        }
    }, [programGeoData])


    if (!programGeoData) return <Loading/>
    if (programGeoDataFetchingError) return <Error error={programGeoDataFetchingError}/>


    /*
     date_fin_commercialisation: null
     date_fondation: "2022-08-31"
     date_input_cecim_bi: "2022-01-01"
     date_livraison: "2023-12-31"
     tran_id: 14319
     */

    if (isEmpty(programGeoData)) return false
    return programGeoData.features.map((programItem, index) => {
        return (
            <MSProgramCollectionMarker key={uniqueId('ms__program_collection__marker--')} programItem={programItem}/>
        )
    })
}

export default MSProgramCollection
