import React, { memo, useMemo } from 'react';
import { Bubble } from 'react-chartjs-2';
import useSWR from 'swr';
import { getFetcher } from '../../../api/fetcher';
import LoadingSection from './LoadingSection';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const MsBubbleChart = ({ dataURL, isPdfMode, data: dataArray }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: 50,
    },
    scales: {
      y: {
        grid: {
          drawBorder: true,
          color: function (context) {
            return context.tick.value === 0 ? 'black' : '#7f7f7f';
          },
          lineWidth: function (context) {
            return context.tick.value === 0 ? 1.5 : 1;
          },
        },
        ticks: {
          color: function (context) {
            return context.tick.value === 0 ? 'black' : '#7f7f7f';
          },
          callback: function (value) {
            return value.toFixed(2) + '%';
          },
        },
        title: {
          text: 'Variation emploi',
          display: true,
          align: 'center',
          padding: 20,
        },
      },
      x: {
        grid: {
          drawBorder: true,
          color: function (context) {
            return context.tick.value === 0 ? 'black' : '#7f7f7f';
          },
          lineWidth: function (context) {
            return context.tick.value === 0 ? 1.5 : 1;
          },
        },
        ticks: {
          color: function (context) {
            return context.tick.value === 0 ? 'black' : '#7f7f7f';
          },
          callback: function (value) {
            return value.toFixed(2) + '%';
          },
        },
        title: {
          text: 'Variation population',
          display: true,
          align: 'center',
          padding: 20,
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'start',
        align: 'bottom',
        color: 'black',
        offset: -4,
        padding: 4,
        font: {
          weight: 'bold',
          size: 12,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            let xValue =
              tooltipItem.parsed.x > 0
                ? `+${tooltipItem.parsed.x}%`
                : `${tooltipItem.parsed.x}%`;
            let yValue =
              tooltipItem.parsed.y > 0
                ? `+${tooltipItem.parsed.y}%`
                : `${tooltipItem.parsed.y}%`;
            return `${tooltipItem.label}: var pop ${xValue}, var emploi ${yValue}`;
          },
        },
      },
      legend: {
        display: true,
      },
    },
  };

  const res = useSWR(!dataArray && dataURL, getFetcher);

  const data = useMemo(
    () => (dataArray ? dataArray : res?.data || null),
    [res, dataArray]
  );

  if (!data) return <LoadingSection />;

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Bubble
        plugins={isPdfMode ? [ChartDataLabels] : []}
        options={options}
        data={{
          datasets: data?.datasets.map((item) => ({
            ...item,
            data: item.data.map((el) => ({ ...el, label: item.label })),
          })),
        }}
      />
      <div className={'text-center'}>
        Variation population / variation emploi de 2014 à 2020
      </div>
    </div>
  );
};

export default memo(MsBubbleChart);
