import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';
import Api from '../../../api/Api';
import useSWR from 'swr';
import { useDataVisualisation } from '../../../hooks/useDataVisualisation';
import { useModal } from '../../../hooks/useModal';
import classNames from 'classnames';
import ahoy from 'ahoy.js';
import LoadingBox from '../../utils/LoadingBoxSimple';

const fetcher = (url) => Api.get(url).then((res) => res.data);

const BoxComp2 = ({ url, print_mode, data, isEDM = false }) => {
  const { isQuickCheckModalOpened } = useModal();

  const responseSWR = !isEDM && useSWR(url, fetcher);

  const _data = isEDM ? data : responseSWR?.data;

  const { urlMap, setUrlMap } = useDataVisualisation();

  if (responseSWR?.error) {
    return <div>Loading failed...</div>;
  }

  if (!_data)
    return (
      <div>
        <LoadingBox printMode={print_mode} boxSize={'large'} />
      </div>
    );

  const {
    bas_de_box,
    information,
    titre_box,
    metric_commune,
    metric_commune_2,
    metric_epci,
    metric_epci_2,
    // year,
    carto_link,
  } = _data;

  const style = {
    height: `${print_mode ? '110px' : '120px'}`,
  };

  return (
    <div
      style={style}
      className={classNames(
        ' flex flex-col px-4 py-3 bg-white shadow rounded-lg overflow-hidden sm:p-2 sm:px-3 justify-between border-gray-200 border-2',
        {
          'bg-blue-600': carto_link === urlMap,
          'cursor-pointer hover:bg-blue-100':
            !!carto_link && !isQuickCheckModalOpened,
        }
      )}
      onClick={(e) => {
        e.preventDefault();
        if (!isQuickCheckModalOpened) {
          ahoy.track('$click', { titre_box: titre_box, type_box: 'BoxComp2' });
          carto_link && setUrlMap(carto_link);
        }
        return;
      }}
    >
      <div className={'flex flex-row justify-between'}>
        <dt className="text-xs font-medium text-orange-600 leading-none">
          {titre_box}
        </dt>
        {!print_mode && (
          <dt
            className="text-base font-medium text-gray-600"
            data-tooltip-place="left"
            data-for="tooltip-global"
            data-tip={information}
            onMouseEnter={() => {
              ReactTooltip.rebuild();
            }}
          >
            <InformationCircleIcon className={'w-5'} />
          </dt>
        )}
      </div>
      <div className={'flex flex-row justify-between'}>
        <div className="flex flex-col items-start">
          <dd className="text-base font-semibold text-gray-900">
            {metric_commune}
          </dd>
          <dd className="text-xs font-semibold text-gray-900">
            commune&nbsp;
            <span
              className={`${
                metric_commune_2?.charAt(0) === '-'
                  ? 'text-red-400'
                  : 'text-green-600'
              }`}
            >
              {metric_commune_2}
            </span>
          </dd>
        </div>
        <div className="flex flex-col items-start">
          <dd className="text-base font-semibold text-gray-900">
            {metric_epci}
          </dd>
          <dd className="text-xs font-semibold text-gray-900">
            EPCI&nbsp;
            <span
              className={`${
                metric_epci_2?.charAt(0) === '-'
                  ? 'text-red-400'
                  : 'text-green-600'
              }`}
            >
              {metric_epci_2}
            </span>
          </dd>
        </div>
      </div>
      <dt className="text-xs font-medium text-gray-500">{bas_de_box}</dt>
    </div>
  );
};

export default BoxComp2;
