import React, { useEffect, useState } from 'react';
import Accordion from '../../src/components/Accordion';
import _ from 'lodash';
import BoxesContainer from './BoxesContainer';
// import { useDataVisualisation } from '../hooks/useDataVisualisation';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
// import { useSelectedParcels } from '../hooks/useSelectedParcels';

const Axe = ({
  quickCheck,
  sub_axes,
  axe_name,
  //   insee_code,
  //   parcel_id,
  forceOpen,
  //   sub_axe_init,
  boxes,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (forceOpen) setIsOpen(true);
    else setIsOpen(false);
  }, [forceOpen]);

  if (quickCheck) {
    return (
      <div className="w-full mt-4">
        <div
          className={
            'py-0 p-2 flex justify-between w-full items-center border-b-2 border-gray-500 cursor-pointer'
          }
        >
          <p
            className={` my-0 sm:ml-3 text-black font-extrabold text-sm tracking-tight  sm:text-lg`}
            dangerouslySetInnerHTML={{ __html: axe_name }}
          />
        </div>
        <BoxesContainer boxes={boxes} print_mode={false} />
      </div>
    );
  }

  return (
    <div className={`${!quickCheck ? 'bg-white shadow-md' : ''}`}>
      <div
        className={`${
          quickCheck ? 'py-0' : null
        } p-2 flex justify-between w-full items-center border-b-2 border-gray-500 cursor-pointer`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <p
          className={`${
            quickCheck ? 'my-0' : null
          } ml-3 text-black font-extrabold text-lg`}
        >
          {_.capitalize(axe_name)}
        </p>
        {!isOpen && <ChevronDownIcon className={'w-3 h-3'} />}
        {isOpen && <ChevronUpIcon className={'w-3 h-3 '} />}
      </div>
      {!quickCheck && isOpen && (
        <div
          style={{ zIndex: '9' }}
          className={`${
            !quickCheck ? 'bg-gray-200' : ''
          } transition  transform duration-500 overflow-x-hidden overflow-y-hidden flex w-full flex-wrap `}
        >
          {sub_axes.map((sub_axe, index) => {
            if (sub_axe.boxes.length > 0) {
              return (
                <Accordion
                  key={`${sub_axe.name
                    .toLowerCase()
                    .split(' ')
                    .join('_')}__${index}`}
                  title={sub_axe.name.split('_').join(' ')}
                  quickCheck={quickCheck}
                  forceOpen={true}
                >
                  <BoxesContainer boxes={sub_axe.boxes} print_mode={false} />
                </Accordion>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default Axe;
