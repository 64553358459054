import React, { useEffect, useState } from 'react';
import Axe from '../Axe';
import { useSelectedParcels } from '../../hooks/useSelectedParcels';
import { useDataVisualisation } from '../../hooks/useDataVisualisation';
import axios from 'axios';

const AxesContainer = ({ insee_code, is_quick_check }) => {
  const { mainParcelID } = useSelectedParcels();
  const { setUrlMap } = useDataVisualisation();
  const [initData, setInitData] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    const getDataAndInitData = async () => {
      if (!data[mainParcelID] && !initData[mainParcelID]) {
        const { data: _initData } = await axios.get(
          `${process.env.BASE_URL_API}/v4/eval_parcel/eval_detaillee/${mainParcelID}/init`
        );
        setInitData((state) => ({ ...state, [mainParcelID]: _initData }));
        console.log(mainParcelID);
        const dataUrl = mainParcelID
          ? is_quick_check
            ? `${process.env.BASE_URL_API}/v4/eval_parcel/quick_check/${mainParcelID}/boxes/simplified`
            : `${process.env.BASE_URL_API}/v4/eval_parcel/eval_detaillee/${mainParcelID}/boxes`
          : null;
        const { data: _data } = await axios.get(dataUrl);
        setData((state) => ({ ...state, [mainParcelID]: _data }));
      }
    };
    getDataAndInitData();
  }, [mainParcelID]);

  useEffect(() => {
    if (initData && mainParcelID) {
      setUrlMap(initData[mainParcelID]?.init_carto_link);
    }
  }, [initData, mainParcelID]);

  if (is_quick_check) {
    return (
      <div className={'mb-4'}>
        {(data[mainParcelID] || []).map(({ axe_name, boxes }, index) => (
          <Axe
            quickCheck={is_quick_check}
            key={`detailed__rating__axes__${index}`}
            axe_name={axe_name}
            boxes={boxes}
            insee_code={insee_code}
            forceOpen={true}
            parcel_id={mainParcelID}
            sub_axe_init={initData[mainParcelID]?.init_subaxe_name}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={'mb-4'}>
      {(data[mainParcelID] || [])?.map(({ axe_name, sub_axes }, index) => (
        <Axe
          quickCheck={is_quick_check}
          key={`detailed__rating__axes__${index}`}
          axe_name={axe_name}
          sub_axes={sub_axes}
          insee_code={insee_code}
          forceOpen={true}
          parcel_id={mainParcelID}
          sub_axe_init={initData[mainParcelID]?.init_subaxe_name}
        />
      ))}
    </div>
  );
};

export default AxesContainer;
