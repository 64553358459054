import React from 'react';
import { uniqueId } from 'lodash';
import LoadingSection from './LoadingSection';

const DefinitionSection = ({ data }) => {
  if (!data) {
    return <LoadingSection />;
  }

  return (
    <>
      <div className={'grid grid-cols-2 gap-1 col-span-2'}>
        {(data || []).map(({ information, name }) => {
          return (
            <div
              key={uniqueId('definition')}
              className={'col-span-1 flex flex-col'}
            >
              <p className={'font-bold text-sm'}>{name}</p>
              <p
                className={'text-sm leading-tight'}
                dangerouslySetInnerHTML={{ __html: information }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DefinitionSection;
