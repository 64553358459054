import React, { useEffect, useRef, useState } from 'react';
import Modal from '../molecules/modal/Modal';
import { useAuth } from '../../hooks/useAuth';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { updateDateConstent } from '../../store/slices/others';
import { useLocation } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ModalCgu = ({ openCgu, onClose, onAccepted, loading }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidthPage] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const fixWidthPage = () => {
      if (containerRef.current && numPages) {
        setWidthPage(containerRef.current.clientWidth);
      }
    };
    fixWidthPage();
    window.addEventListener('resize', fixWidthPage);
    return () => {
      window.removeEventListener('resize', fixWidthPage);
    };
  }, [numPages]);

  const handlePrev = () => {
    const current = pageNumber - 1;
    if (current >= 1) {
      setPageNumber(current);
    }
  };

  const handleNext = () => {
    const current = pageNumber + 1;
    if (current <= numPages) {
      setPageNumber(current);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      maxWidthPx={1024}
      onClose={onClose}
      open={openCgu}
      fullHeightScreen
      noNeedCloseInDialog
      noNeedBtnClose
      otherChildren={
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            left: '50%',
            transform: 'translate(-50%, 0% )',
            zIndex: 1000,
          }}
          className="bottom-2 btn-be fixed inline-block w-full max-w-sm p-4 bg-black bg-opacity-50 rounded-md"
        >
          <div className="flex justify-between text-white font-bold items-center gap-x-8">
            <div className="flex justify-between gap-x-6 whitespace-nowrap">
              <span
                onClick={handlePrev}
                className="cursor-pointer hover:text-blue-500"
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </span>
              <span>
                {pageNumber} sur {numPages}
              </span>
              <span
                onClick={handleNext}
                className="cursor-pointer hover:text-blue-500"
              >
                <ChevronRightIcon className="h-6 w-6" />
              </span>
            </div>
            <button
              onClick={onAccepted}
              disabled={loading}
              className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none outline-none"
            >
              {loading ? 'Enregistrement en cours...' : 'Accepter'}
            </button>
          </div>
        </div>
      }
    >
      <div className="w-full" ref={containerRef}>
        <Document file="/cgv_ep.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={width} />
        </Document>
      </div>
    </Modal>
  );
};

const CguModal = () => {
  const [open, setOpen] = useState(false);
  const [openCgu, setOpenCgu] = useState(false);
  const dispatch = useDispatch();
  const { needCguAcceptation, dateGguAcceptation, logOut } = useAuth();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handleShowCgu = (e) => {
    e.preventDefault();
    setOpen(false);
    setTimeout(() => {
      setOpenCgu(true);
    }, 100);
  };

  const handleCloseCguModal = () => {
    setOpenCgu(false);
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };

  const handleAccept = async () => {
    setLoading(true);
    await dispatch(updateDateConstent(new Date()));
    setLoading(false);
    setOpen(false);
    setOpenCgu(false);
    window.location.reload();
  };

  const handleClickNotAccept = () => {
    setOpen(false);
    logOut();
    setTimeout(() => {
      window.location.assign('/users/sign_in');
    }, 1000);
  };

  useEffect(() => {
    const currentpath = location.pathname;
    const notNeedCguSignPaths = [
      '/users/sign_in',
      '/users/password/new',
      '/users/confirmation/new',
      '/users/sign_up',
      '/capem/users/sign_up',
      '/users/new',
      '/mon-compte',
    ];

    if (
      needCguAcceptation &&
      !dateGguAcceptation &&
      !notNeedCguSignPaths.includes(currentpath) &&
      currentpath.indexOf('pdf') < 0
    ) {
      setOpen(true);
    }
  }, [needCguAcceptation, dateGguAcceptation, location.pathname]);

  return (
    <>
      <ModalCgu
        loading={loading}
        onAccepted={handleAccept}
        openCgu={openCgu}
        onClose={handleCloseCguModal}
      />
      <Modal
        noNeedBtnClose
        open={open}
        maxWidth="max-w-3xl"
        onClose={() => {}}
        noNeedCloseInDialog
      >
        <h1 className="text-3xl font-semibold">
          Acceptation des Conditions Générales de Vente
        </h1>
        <br />
        <p>
          Votre société est abonnée à EvalparceL mais n'a pas encore accepté nos
          Conditions Générales de Vente.
          <br />
          Pour continuer, veuillez les lire et les accepter.{' '}
        </p>
        <br />
        <div className="flex mt-6 justify-end gap-x-4">
          <button
            disabled={loading}
            onClick={handleClickNotAccept}
            className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-700 focus:outline-none outline-none"
          >
            Refuser et se déconnecter
          </button>
          <button
            disabled={loading}
            onClick={handleShowCgu}
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none outline-none"
          >
            {loading
              ? 'Enregistrement en cours...'
              : 'Lire et accepter les CGV'}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CguModal;
