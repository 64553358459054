import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Popup, useMap } from 'react-leaflet';
import styled from 'styled-components';
import { usePrograms } from '../../../../hooks/usePrograms';
import { XIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailsProgramme,
  programmeSliceActions,
} from '../../../../store/slices/programme';
import TableProgrammeDetail, {
  getCoordFromUrlStreet,
} from '../../../MarketSurveys/sections/TableProgrammeDetails';
import classNames from 'classnames';
import moment from 'moment';
import { transformCoordinates } from '../../../../_helpers/coordinate';
import programOverSince2019 from '../../../../../../assets/images/icons/programme-immobilier-termine-2019.svg';
import programInProgress from '../../../../../../assets/images/icons/programme_en_cours.svg';
import LoadingSection from '../../../MarketSurveys/sections/LoadingSection';
import Loader from 'react-loader-spinner';

const StyledPop = styled(Popup)`
  height: auto;
  z-index: 10000;

  .leaflet-popup-content-wrapper {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }

  .leaflet-popup-content {
    min-width: 600px !important;
    margin: 0px !important;
  }

  .leaflet-popup-tip-container {
    visibility: display;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;

const ProgramPopup = ({ data, readOnlyMode = true, onClose, noCluster }) => {
  const tran_id = useMemo(() => data?.properties.tran_id, [data]);
  const { activePrograms, tableProgramRef, setCurrentTranId } = usePrograms();
  const map = useMap();
  const [infos, setInfos] = useState(null);
  const dispatch = useDispatch();
  const [openDetailProgramme, setopenDetailProgramme] = useState(false);
  const [detailsProgramme, setDetailsProgramme] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (tran_id) {
      dispatch(getDetailsProgramme(tran_id)).then((res) => {
        if (res.payload) {
          setDetailsProgramme(res.payload.attributes);
        }
      });
    }
  }, [tran_id]);

  useEffect(() => {
    if (openDetailProgramme) {
      if (ref.current) {
        const px = map.project(ref.current._latlng);
        setTimeout(() => {
          const details = document.getElementById('details-popup');
          if (details) {
            const clientHeightOfDetails = details.clientHeight;
            const popupCLientHeight = ref.current._container.clientHeight;
            px.y -= (popupCLientHeight - clientHeightOfDetails + 40) / 1.5;
            map.panTo(map.unproject(px), { animate: true });
          }
        }, 1000);
      }
    }
  }, [openDetailProgramme]);

  const handleAddOrRemovePrograms = () => {
    if (tableProgramRef.current) {
      tableProgramRef.current.clickItem(tran_id, map);
    }
  };

  const handleOpenDetailProgramme = () => {
    dispatch(programmeSliceActions.setOpenDetailProgramme(true));
    setopenDetailProgramme(true);
  };

  const handleClose = () => {
    map.closePopup();
    setCurrentTranId(null);
    onClose && onClose();
  };

  useEffect(() => {
    if (data) {
      const properties = data.properties;
      const _infos = {
        // coord: transformCoordinates(data.geometry.coordinates),
        Typologie: properties.typologie,
        TVA: properties.tva,
        'Date de mise en vente': moment(
          properties.date_debut_commercialisation
        ).format('DD-MM-YYYY'),
        'Nbre de logements mises en vente': properties.nb_logt_mis_en_vente,
        'Nbre de logements réservés': properties.nbres,
        'Vente en blocs (Oui/Non)': properties.comporte_vente_en_blocs
          ? 'Oui'
          : 'Non',
        'Prix moyen de mise en vente':
          properties.pmmv && `${properties.pmmv.toLocaleString('fr-FR') + '€'}`,
        'Prix moyen réservé':
          properties.pmr && `${properties.pmr.toLocaleString('fr-FR') + '€'}`,
        'Rythme moyen résas': properties.rythme_moyen_resas,
        Livraison: moment(properties.date_livraison).format('DD-MM-YYYY'),
      };
      setInfos(_infos);
    }
  }, [data]);

  return (
    <StyledPop
      position={transformCoordinates(data.geometry.coordinates)}
      ref={ref}
      offset={[0, noCluster ? -8 : -32]}
      onClose={onClose}
      id={`popup-program-${tran_id}`}
    >
      <div className="w-full relative bg-white rounded-lg">
        <div style={{ top: '6px', right: '6px' }} className={'absolute'}>
          <button onClick={handleClose}>
            <XIcon
              className={
                'w-3 xl:w-5 h-3 xl:h-5 text-blue-800 hover:text-blue-900'
              }
            />
          </button>
        </div>
        <div className="w-full rounded-lg overflow-hidden">
          <div className="flex items-center gap-x-2 bg-blue-300 px-4 py-2">
            <div style={{ minWidth: 28, height: 28, marginTop: -6 }}>
              {data?.properties?.date_fin_commercialisation === null ? (
                <img src={programInProgress} className="h-auto w-7" alt="old" />
              ) : (
                <img
                  src={programOverSince2019}
                  className="h-auto w-7"
                  alt="new"
                />
              )}
            </div>
            <div>
              <h2 className="text-sm">
                <span className="font-semibold">
                  {data?.properties?.nom_programme}
                </span>{' '}
                | {data?.properties?.commune}
              </h2>
              <h3 className="text-xs">
                <span className="font-normal">Promoteur</span> :{' '}
                <span className="font-semibold">
                  {data?.properties?.promoteur}
                </span>
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4 p-4">
            <div className="col-span-1">
              <div>
                {infos &&
                  Object.entries(infos)
                    .filter(([_key, value]) => _key !== 'coord' && value)
                    .map(([key, value]) => (
                      <div
                        key={key}
                        className="flex items-baseline gap-x-2 justify-between mt-1 text-xs12"
                      >
                        <span>{key} : </span>
                        <span className="font-bold">{value}</span>
                      </div>
                    ))}
              </div>
              <div className="flex flex-row justify-between mt-6">
                <button
                  disabled={!detailsProgramme}
                  onClick={handleOpenDetailProgramme}
                  type="button"
                  className={classNames(
                    'text-xs12 text-white rounded-lg px-4 py-1.5 hover:bg-gray-600 bg-gray-500',
                    { 'opacity-50': !detailsProgramme }
                  )}
                >
                  Voir detail
                </button>

                {!readOnlyMode && (
                  <button
                    onClick={handleAddOrRemovePrograms}
                    type="button"
                    className="text-xs12 text-white rounded-lg px-4 py-1.5 hover:bg-blue-700 bg-blue-500"
                  >
                    {!activePrograms.includes(tran_id)
                      ? 'Sélectionner'
                      : 'Retirer'}
                  </button>
                )}
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              {detailsProgramme ? (
                getCoordFromUrlStreet(detailsProgramme.google_street_view_url)
                  ?.length && (
                  <iframe
                    width="100%"
                    height="100%"
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyCAZDEpFS3e9gjfQxkgIgGhwNZvK37FB00&source=outdoor&radius=200&location=${getCoordFromUrlStreet(
                      detailsProgramme.google_street_view_url
                    ).join(',')}`}
                  />
                )
              ) : (
                <Loader type="TailSpin" color="#2C5282" />
              )}
            </div>
          </div>
        </div>
        <div
          id="details-popup"
          style={{ top: 'calc(100% + 60px)' }}
          className={classNames(
            'absolute left-0 bg-white w-full shadow-lg rounded-lg',
            { hidden: !openDetailProgramme }
          )}
        >
          <TableProgrammeDetail
            programme={detailsProgramme}
            currentTranId={tran_id}
            onClose={() => {
              dispatch(programmeSliceActions.setOpenDetailProgramme(false));
              setopenDetailProgramme(false);
            }}
          />
        </div>
      </div>
    </StyledPop>
  );
};

export default memo(ProgramPopup);
