import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';

export default function RecapIsochroneForm() {
  const {
    isoChroneFilterValue: {
      isoChroneAddress,
      transportMode,
      transportDuration,
    },
  } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(ciblageCreateActions.setCibler(false));
  };

  return (
    <div className="mt-4">
      <label className="flex items-center justify-between">
        <span className="font-semibold">Isochrone</span>
        <span
          onClick={handleClick}
          className="font-medium hover:font-semibold text-teal-500 cursor-pointer"
        >
          Modifier
        </span>
      </label>
      <div className="p-2 border-1 mt-1 border-gray-500 rounded-md text-sm">
        <div>
          <span className="font-semibold">Adresse:</span>{' '}
          {isoChroneAddress.label}
        </div>
        <div>
          <span className="font-semibold">Mode de transport:</span>{' '}
          {transportMode.label}
        </div>
        <div>
          <span className="font-semibold">Temps de transport maximum:</span>{' '}
          {transportDuration} min
        </div>
      </div>
    </div>
  );
}
