import React, { useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import { ciblageCreateActions } from '../../../store/slices/ciblageCreate';
import { STEP_1 } from '../../../constants/ciblageDefaultValues';
import useDebounce from '../../../_helpers/useDebounce';
import { getNbParcelByTerrainFilter } from '../../../store/actions/ciblage/terrain';
import StickyHeader from '../sticky-header/StickyHeader';
import FilterTerrain from '../filter-terrain/FilterTerrain';

export default function FormulaireVotreTerrain() {
  const { step, valueTerrain } = useSelector((state) => state.ciblageCreate);
  const dispatch = useDispatch();

  const handlePrev = () => {
    dispatch(
      ciblageCreateActions.setStep({
        current: STEP_1,
        next: step.current,
      })
    );
  };

  const handleChange = (key, value) => {
    dispatch(
      ciblageCreateActions.setValueTerrain({ ...valueTerrain, [key]: value })
    );
  };

  useDebounce(
    () => {
      dispatch(getNbParcelByTerrainFilter());
    },
    [valueTerrain],
    0
  );

  useEffect(() => {
    dispatch(getNbParcelByTerrainFilter());
  }, [step]);

  return (
    <div id="formulaire-votre-terrain" className="w-full h-full">
      <StickyHeader container="formulaire-votre-terrain">
        <div className="flex justify-between items-center px-4 py-6 cursor-pointer border-b-1 border-gray-500">
          <h2 className="text-base xl:text-xl font-semibold">
            Etape 1 / 2 : VOTRE TERRITOIRE
          </h2>
          <div className="flex justify-between">
            <span
              onClick={handlePrev}
              className="font-medium hover:font-semibold text-teal-500 cursor-pointer mr-8"
            >
              Modifier
            </span>
            <ChevronDownIcon className="w-6 h-6 font-semibold" />
          </div>
        </div>
        <div className="flex justify-between items-center px-4 py-6 cursor-pointer">
          <h2 className="text-base xl:text-xl font-semibold">
            Etape 2 / 2 : VOS TERRAINS
          </h2>
          <div className="flex justify-between">
            <ChevronUpIcon className="w-6 h-6 font-semibold" />
          </div>
        </div>
      </StickyHeader>
      <div className="px-4 mt-4">
        <FilterTerrain valueTerrain={valueTerrain} onChange={handleChange} />
      </div>
    </div>
  );
}
