import React, { createContext, useRef, useState } from 'react';

export const programsContext = createContext(null);

const ProgramsProvider = ({ children }) => {
  const tableProgramRef = useRef(null);
  const programmesPageRef = useRef(null);
  const programmeLayerRef = useRef(null);
  const [programs, setPrograms] = useState([]);
  const [activePrograms, setActivePrograms] = useState([]);
  const [programsRefs, setProgramsRefs] = useState([]);
  const [currentTranId, setCurrentTranId] = useState(null);
  const [currentCenterCoordinates, setCurrentCenterCoordinates] =
    useState(null);
  const [programToShowDetail, setProgramToShowDetail] = useState(null);
  const [changedActiveProgrammes, setChangedActiveProgrammes] = useState([]);
  const [closedProgrammeId, setCloseProgrammeId] = useState(null);
  const [detailsProgrammeContext, setDetailsProgrammeContext] = useState(null);

  const handleChangeActiveProgramme = (currentActiveProgramme) => {
    setChangedActiveProgrammes(currentActiveProgramme);
  };

  return (
    <programsContext.Provider
      value={{
        tableProgramRef,
        programmesPageRef,
        activePrograms,
        setActivePrograms,
        programs,
        setPrograms,
        programsRefs,
        setProgramsRefs,
        setCloseProgrammeId,
        closedProgrammeId,
        currentTranId,
        setCurrentTranId,
        setCurrentCenterCoordinates,
        currentCenterCoordinates,
        setProgramToShowDetail,
        programToShowDetail,
        changedActiveProgrammes,
        handleChangeActiveProgramme,
        setDetailsProgrammeContext,
        detailsProgrammeContext,
        programmeLayerRef,
      }}
    >
      {children}
    </programsContext.Provider>
  );
};

export default ProgramsProvider;
