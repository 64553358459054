import React, { memo } from 'react';
import SurfaceLayer from './SurfaceLayer';
import DefaultLayer from './DefaultLayer';
import MarketSurveysLayer from './MarketSurveysLayer';
import MSProgramsHighlightLayer from './MarketSurvey/MSProgramsHighlightLayer';
import MSDefaultLayer from './MSDefaultLayer';
import {
  DEFAULT_DATATYPE,
  MAP_PROGRAMME,
  MS_SHOW_SELECTED_PROGRAM,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI,
  PARCEL_LAYER,
  POI_MAP,
  SELECT_PROGRAM_MAP,
} from '../../../../_helpers/constant';
import NearestShopsAndPublicEquipmentPOILayer from './NearestShopsAndPublicEquipmentPOILayer';
import MsPOILayer from './MsPOILayer';
import ParcelLayer from './ParcelLayer';

const SwitchLayerContainer = ({
  readOnlyMode,
  isMobility,
  name,
  dataType,
  dataUrl,
  items,
  onLoadLegendMarketSurvey,
  needIsochrone,
  programmesNeufs,
  needIsochroneVoiture,
  eductionMap,
  isPdfMode,
  onlySelected,
  onChangeCurrentProgrammePopupDate,
  currentProgrammePopupData,
  data,
  programMapRef,
  noCluster,
}) => {
  switch (dataType) {
    case PARCEL_LAYER:
      return <ParcelLayer name={name} isPdfMode={isPdfMode} />;

    case 'surface':
      return <SurfaceLayer />;

    case MAP_PROGRAMME:
      return (
        <MarketSurveysLayer
          currentProgrammePopupData={currentProgrammePopupData}
          onChangeCurrentProgrammePopupDate={onChangeCurrentProgrammePopupDate}
          ref={programMapRef}
          onLoadLegendMarketSurvey={onLoadLegendMarketSurvey}
          readOnlyMode={readOnlyMode}
          programmesNeufs={programmesNeufs}
          needIsochroneVoiture={needIsochroneVoiture}
          isPdfMode={isPdfMode}
          onlySelected={onlySelected}
          noCluster={noCluster}
          name={name}
        />
      );

    case SELECT_PROGRAM_MAP:
      return (
        <MarketSurveysLayer
          currentProgrammePopupData={currentProgrammePopupData}
          onChangeCurrentProgrammePopupDate={onChangeCurrentProgrammePopupDate}
          ref={programMapRef}
          onLoadLegendMarketSurvey={onLoadLegendMarketSurvey}
          isProgrammeMap
          readOnlyMode={false}
          isPdfMode={isPdfMode}
          onlySelected={onlySelected}
          noCluster={noCluster}
        />
      );

    case MS_SHOW_SELECTED_PROGRAM:
      return <MSProgramsHighlightLayer dataURL={dataUrl} />;

    case DEFAULT_DATATYPE:
      return (
        <MSDefaultLayer
          urlMap={dataUrl}
          needIsochrone={needIsochrone}
          eductionMap={eductionMap}
          data={data}
          isPdfMode={isPdfMode}
          name={name}
          isMobility={isMobility}
        />
      );

    case POI_MAP:
      return (
        <MsPOILayer
          isPdfMode={isPdfMode}
          needIsochrone={needIsochrone}
          name={name}
        />
      );

    case NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI:
      return (
        <NearestShopsAndPublicEquipmentPOILayer
          items={items}
          urlMap={dataUrl}
          dataArray={data}
          isPdfMode={isPdfMode}
          name={name}
        />
      );

    default:
      return <DefaultLayer />;
  }
};

export default memo(SwitchLayerContainer);
